import { FC } from 'react'
import { ArrowCircleSVG, LockSVG } from '../../../../utils/getIcons'
import { Col, Row } from 'react-bootstrap'
import {
    PpalTitle,
    ArrowBlack,
    SpacerContent,
    Wrapper,
    Content,
    TextP,
    ButtonsContainer,
    ModalCancelButton,
    ModalButton,
} from '../../KeyChange-styles'
import { BreadcrumbApp } from '../../../../components'

interface StepProps {
    userName: string
    handleNextStep: (value: string) => void
    redirection: (value: string) => void
}

const breadcrumbs = [
    {
        text: 'Inicio',
        active: false,
        onClick: () => {
            window.location.reload()
        },
    },
    {
        text: 'Servicios en linea',
        active: false,
        onClick: () => {
            window.location.reload()
        },
    },
    {
        text: 'Servicios',
        active: false,
        onClick: () => {
            window.location.reload()
        },
    },
    {
        text: 'Cambia tu clave',
        active: false,
        onClick: () => {
            window.location.reload()
        },
    },
]

const KeyChangeInfo: FC<StepProps> = ({ userName, handleNextStep, redirection }): JSX.Element => {
    return (
        <SpacerContent>
            <BreadcrumbApp breadcrumbs={breadcrumbs} />
            <ArrowBlack onClick={() => redirection('/')}>
                <img src={ArrowCircleSVG} alt="black" />
                <p>Volver</p>
            </ArrowBlack>
            <PpalTitle>
                Cambia tu <span>clave</span>
            </PpalTitle>

            <Wrapper>
                <Content>
                    <PpalTitle>
                        Señor(a): <span>{userName}</span>
                    </PpalTitle>
                    <TextP>
                        Está opción le permitirá cambiar su clave de ingreso a la página
                        transaccional
                    </TextP>
                    <TextP>Tenga en cuenta las siguientes recomendaciones:</TextP>

                    <Row>
                        <Col md={1}>
                            <img src={LockSVG} alt="" />
                        </Col>
                        <Col md={11}>
                            <TextP>
                                La clave debe tener entre cuatro (4) y ocho (8) caracteres
                            </TextP>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}>
                            <img src={LockSVG} alt="" />
                        </Col>
                        <Col md={11}>
                            <TextP>
                                La clave puede contener números, letras mayúsculas y minúsculas
                            </TextP>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}>
                            <img src={LockSVG} alt="" />
                        </Col>
                        <Col md={11}>
                            <TextP>Combine letras y números para que su clave sea más segura</TextP>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}>
                            <img src={LockSVG} alt="" />
                        </Col>
                        <Col md={11}>
                            <TextP>
                                Deberá ingresar la clave actual, la nueva y repetir la nueva para
                                finalizar
                            </TextP>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}>
                            <img src={LockSVG} alt="" />
                        </Col>
                        <Col md={11}>
                            <TextP>Deberá ingresar las claves con el teclado virtual.</TextP>
                        </Col>
                    </Row>

                    <ButtonsContainer>
                        <ModalCancelButton
                            variant="primary"
                            onClick={() => redirection('/home-wallet')}
                        >
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton type="button" onClick={() => handleNextStep('info')}>
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </Content>
            </Wrapper>
        </SpacerContent>
    )
}

export default KeyChangeInfo
