import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'
import { CheckInput } from '../../components'

export const Wrapper = styled.section`
    ${tw`mb-[20px] py-[15px] px-0 min-h-[50vh] flex justify-center items-center py-2`}

    .highlight-text {
        ${tw`text-sub-dominant`}
    }

    .hide-number-arrows::-webkit-inner-spin-button,
    .hide-number-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .hide-number-arrows {
        -moz-appearance: textfield;
    }
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[100%]`}

    .row-container {
        margin-top: 10px;
    }

    @media screen and (max-width: 450px) {
        ${tw`flex overflow-x-scroll`}
    }
`

export const TermsContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[90%]`}

    .terms-note {
        padding: 25px;
        text-align: center;
    }
`

export const RejectedContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[60%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ContentInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const PpalTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }

    .user-terms {
        font-size: 25px;
    }

    @media (max-width: 460px) {
        ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}
        text-align:left;

        span {
            color: #f5a50b;
        }

        .user-terms {
            font-size: 23px;
        }
    }
`
export const YellowTitle = styled.h2`
    ${tw`font-montserrat text-base my-[20px]`}

    color: #F5A50B;
`

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}
`

export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}
`
export const SpanBold = styled.span`
    ${tw`font-montserrat font-bold text-base`}
`

export const CodeText = styled.span`
    ${tw`font-montserrat font-bold text-base`}

    color: #512f8b;
`

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`

export const Image = styled.img`
    width: 20%;
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;
    /* margin-right: 20px; */

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[300px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    }
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[300px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    /* justify-content: space-between; */
    align-items: center;
`

export const ArrowBlack = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    @media (max-width: 500px) {
        ${tw`w-40`}
        & p {
            ${tw`text-sm`}
        }
    }

    @media (min-width: 1024px) {
        ${tw`w-12`}
    }
`

export const ArrowYellow = styled.a`
    display: flex;
    gap: 12px;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        transform: rotate(-90deg);
    }

    span {
        color: #f2a510;
        font-weight: bold;
    }
`

export const AlignContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const TermsAndCondContainer = styled.div`
    color: #000;
    background-color: #ffffff;
    padding: 30px;
    max-height: 400px;
    overflow-y: auto;

    /* Estilo del contenido dentro de TermsAndCondContainer */
    & > * {
        margin-bottom: 20px;
    }
`

export const ContainerCheck = styled.div`
    ${tw`flex justify-start mt-4`}
`

export const CheckInputTdc = styled(CheckInput)`
    ${tw`block !w-6 !h-6 mr-3 cursor-pointer`}
`

export const CheckTermsCond = styled(CheckInput)`
    ${tw`block !w-6 !h-6 mr-3 cursor-pointer`}
`
export const TermText = styled.p`
    font-family: 'Helvetica', sans-serif;
    color: #53595a;
`
export const StyledCheckbox = styled.input`
    width: 16px;
    height: 16px;
`

export const CancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    /* margin-left: 20px; */
    background-color: #ffffff;
    border-color: #000000 !important;

    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[340px] h-[60px] color[#000000] rounded-[5px] font-normal font-montserrat`}

        &:hover {
            background-color: #ffffff;
            color: #000;
        }

        /* margin-left: 20px; */
        background-color: #ffffff;
        border-color: #000000 !important;
    }
`

export const AceptButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border: 2px solid transparent !important;
    color: #ffffff;

    &:hover {
        background-color: var(--sub-dominant-color);
        border: 2px solid transparent;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[340px] h-[50px] rounded-[5px] font-normal font-montserrat`}
        background-color: var(--sub-dominant-color) !important;
        border: 2px solid transparent !important;
        color: #ffffff;

        &:hover {
            background-color: var(--sub-dominant-color);
            border: 2px solid transparent;
        }

        &:focus {
            outline: none;
        }

        &:active {
            border: 2px solid transparent;
        }
    }
`
export const TableContainer = styled.table`
    ${tw`w-full h-16`}
`

export const THead = styled.thead`
    ${tw`border-b border-solid border-[#00000029] w-full`}

    & tr {
        ${tw`h-16`}

        & th {
            & h4 {
                ${tw`m-0 font-helvetica font-normal text-base text-gray-custom-500`}
            }
        }
    }
`
export const TBody = styled.tbody`
    ${tw`w-full`}

    & tr {
        ${tw`h-[52px] border-b border-solid border-[#00000029]`}

        & td {
            &:first-child {
                & p {
                    ${tw`font-normal font-helvetica py-1`}
                }
                & span {
                    ${tw`font-bold font-montserrat py-1`}
                }
            }
            & p {
                ${tw`m-0 font-montserrat font-bold text-base text-gray-custom-500`}
            }
        }
    }

    .wrapText {
        max-width: 200px;
        word-wrap: break-word;
    }
`
export const RowTableTitle = styled.tr`
    background-color: var(--dominant-color-dark);
    ${tw`font-montserrat text-white text-center `}

    .realEstateOffer {
        width: 15% !important;
    }

    .realEstateType {
        width: 20% !important;
    }
`

export const RowTable = styled.tr`
    ${tw`font-montserrat text-center text-base text-black`}
`

export const CancelButtonRejected = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    /* margin-left: 20px; */
    background-color: #ffffff;
    border-color: #000000 !important;

    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[90%] h-[60px] color[#000000] rounded-[5px] font-normal font-montserrat`}

        &:hover {
            background-color: #ffffff;
            color: #000;
        }

        /* margin-left: 20px; */
        background-color: #ffffff;
        border-color: #000000 !important;
    }
`
