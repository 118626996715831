import { FC, useEffect, useState } from 'react'
import { Form, Container, Row, Col, OverlayTrigger } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ArrowCircleSVG } from '../../../../utils/getIcons'
import {
    ArrowBlack,
    ButtonsContainer,
    Content,
    ModalButton,
    ModalCancelButton,
    PpalTitle,
    TextP,
    TextPBold,
    Wrapper,
} from '../../UserCreation-styles'
import ModalLoading from '../../../ActivateInternationalCard/inc/ModalInfo/ModalLoading'
import { useCreateUser, useGetSecurityCode } from '../../hooks/useUserCreation'
import SuccessfulForm from '../SuccessfulForm'
import RejectedForm from '../RejectedForm'
import { useMediaQuery } from 'usehooks-ts'
import { Popover } from '../../../../components'
import { DigitKeyboardTDC } from '../../../MultiPaymentsTDC/inc'
import PasswordFieldConfirm from '../PasswordConfirm/PasswordFieldConfirm'
import PasswordField from '../PasswordFields/PasswordField'

interface FormProps {
    redirection: (value: string) => void
}

const UserCreationForm: FC<FormProps> = ({ redirection }): JSX.Element => {
    const methods = useForm()
    const { handleSubmit, getValues, control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    const [document, setDocument] = useState('')
    const [userName, setUserName] = useState('')
    const [passIVR, setPassIVR] = useState('')
    const [passNet, setPassNet] = useState('')
    const [passConfirmation, setPassConfirmation] = useState('')
    const [secVal1, setSecVal1] = useState(0)
    const [secVal2, setSecVal2] = useState(0)
    const [tempSecurity, setTempSecurity] = useState(0)
    const [security, setSecurity] = useState('')
    const [voucherNumber, setVoucherNumber] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [passMessage, setPassMessage] = useState('')
    const [wsMessage, setWSMessage] = useState('')

    const [showForm, setShowForm] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isRejected, setIsRejected] = useState(false)

    const [currentDateTime, setCurrentDateTime] = useState<string>('')

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)

    const onFocusInput = (): void => {
        setShowKeyboard(true)
    }

    const handleSeeKeyboard = (): void => {
        setShowKeyboard(false)
    }

    const handleInputDigit = (inputChanged: string): void => {
        setPassIVR(inputChanged)
    }

    const dataToSend = {
        document: '',
        User: '',
        PasswordIvr: '',
        Password: '',
        PasswordConfirmation: '',
        SecurityData: {
            Value1: 0,
            Value2: 0,
            Security: 0,
        },
    }

    useEffect(() => {
        GetSecurityCode()
    }, [])

    const passValidation = (contraseña: string): string => {
        const longitud = contraseña.length
        const tieneNumeros = /\d/.test(contraseña)
        const tieneMayusculas = /[A-Z]/.test(contraseña)
        const tieneMinusculas = /[a-z]/.test(contraseña)

        const condicionesCumplidas = [
            longitud >= 5 && longitud <= 8,
            tieneNumeros,
            tieneMayusculas,
            tieneMinusculas,
        ].filter(Boolean).length

        const mensajes = [
            'Débil',
            'Media',
            'Fuerte',
            'La contraseña debe tener mínimo 5 y un máximo de 8 caracteres',
        ]

        const mensaje = mensajes[condicionesCumplidas]

        return mensaje
    }

    const GetSecurityCode = (): void => {
        setIsLoading(true)
        useGetSecurityCode()
            .then((res: any) => {
                console.log(res)
                setIsLoading(false)
                if (res.Response === '200') {
                    dataToSend.SecurityData.Value1 = res.Data.Value1
                    dataToSend.SecurityData.Value2 = res.Data.Value2
                    dataToSend.SecurityData.Security = res.Data.Security

                    setSecVal1(res.Data.Value1)
                    setSecVal2(res.Data.Value2)
                    setTempSecurity(res.Data.Security)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const handlePassNet = (passValue: string): void => {
        setPassNet(passValue)
    }

    const handlePassConfirm = (passConfirmValue: string): void => {
        setPassConfirmation(passConfirmValue)
        const validationMessage = passValidation(passConfirmValue)
        setPassMessage(validationMessage)
    }

    let isValid = false
    const HandleFormSubmit = (): void => {
        if (passConfirmation === passNet) {
            isValid = true
            clearErrors('PassConfirmation')
        } else {
            isValid = false
            setError('PassConfirmation', {
                message: 'La clave de internet y de confirmación no coinciden!',
            })
        }

        if (security === tempSecurity.toString()) {
            isValid = true
        } else {
            isValid = false
            setError('Security', {
                message: 'Número de seguridad incorrecto',
            })
        }

        if (isValid) {
            GetCurrentDateTime()

            dataToSend.document = document
            dataToSend.User = userName
            dataToSend.PasswordIvr = passIVR
            dataToSend.Password = passNet
            dataToSend.PasswordConfirmation = passConfirmation
            dataToSend.SecurityData.Value1 = secVal1
            dataToSend.SecurityData.Value2 = secVal2
            dataToSend.SecurityData.Security = parseInt(security)

            SendData(dataToSend)
        }
    }

    const GetCurrentDateTime = (): void => {
        const now = new Date()
        const formattedDateTime = now.toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        })

        setCurrentDateTime(formattedDateTime)
    }

    const TryAgain = (): void => {
        setShowForm(true)
        setIsRejected(false)
    }

    const SendData = (data: any): void => {
        setIsLoading(true)
        useCreateUser(data)
            .then((res: any) => {
                setIsLoading(false)
                console.log(res.data)
                if (res.data !== undefined) {
                    console.log('success', res)
                    setWSMessage(res.data.Message)
                    setVoucherNumber(res.data.Data)

                    setShowForm(false)
                    setIsSuccess(true)
                } else {
                    console.log('false: ', res)
                    setWSMessage(res.Message)

                    setShowForm(false)
                    setIsRejected(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowForm(false)
                setIsRejected(true)
            })
    }

    const containsSpecialCharacters = (text: string): boolean => {
        const patron = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
        return patron.test(text)
    }

    useEffect(() => {
        if (passIVR.length === 0) {
            setError('PassIVR', {
                message: 'Este campo es requerido',
            })
        } else if (passIVR.length < 4) {
            setError('PassIVR', {
                message: 'Mínimo 4 caracteres',
            })
        } else {
            clearErrors('PassIVR')
        }
    }, [passIVR])

    const handleOnChangeDigitMobile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        setPassIVR(value)
    }
    return (
        <Wrapper>
            {showForm && (
                <>
                    <FormProvider {...methods}>
                        <Content>
                            <ArrowBlack onClick={() => redirection('/login')}>
                                <img src={ArrowCircleSVG} alt="black" />
                                <p>Volver</p>
                            </ArrowBlack>
                            <PpalTitle>
                                <span>¿Eres nuevo? Regístrate</span>
                            </PpalTitle>
                            <br></br>
                            <TextP>
                                Para crear su usuario y clave, es necesario proporcionar su número
                                de cédula y la clave del IVR (Sist. de Audio Respuesta).
                            </TextP>
                            <TextPBold>
                                En caso de no recordarla o no haberla reclamado, es necesario que se
                                acerque a una de nuestras oficinas de CAVIPETROL a nivel nacional.
                            </TextPBold>
                            <TextPBold>
                                Señor(a), debe tener en cuenta las siguientes recomendaciones:
                            </TextPBold>
                            <TextP>
                                ‣ Para su nombre de usuario podrá utilizar hasta 16 caracteres entre
                                números y letras.
                            </TextP>
                            <TextP>
                                ‣ Su clave de Internet puede tener de 4 a 8 caracteres combinando
                                letras y números.
                            </TextP>
                            <TextP>‣ Recuerde diferenciar mayúsculas de minúsculas.</TextP>
                            <TextP>
                                ‣ Utilice el teclado virtual para ingresar la clave IVR (Sist. de
                                Audio Respuesta).
                            </TextP>
                            <TextP>‣ La clave de Internet debe ser diferente a la clave IVR.</TextP>
                            <TextP>
                                ‣ Para mayor seguridad utilice el teclado virtual para ingresar la
                                clave de Internet
                            </TextP>
                            <br></br>
                            <Container>
                                <PpalTitle>Formulario creación de usuario</PpalTitle>
                                <br></br>
                                <Row>
                                    <Col md={3}>
                                        <TextPBold>Cédula</TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'Document'}
                                                rules={{ required: 'Este campo es requerido' }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="hide-number-arrows"
                                                        type="number"
                                                        {...register('Document', {
                                                            required: true,
                                                        })}
                                                        maxLength={16}
                                                        min={5}
                                                        max={16}
                                                        value={document}
                                                        required
                                                        isInvalid={!!errors['Document']}
                                                        onChange={(e) => {
                                                            if (e.target.value !== '') {
                                                                clearErrors('Document')
                                                                if (e.target.value.length <= 10) {
                                                                    setDocument(e.target.value)
                                                                }
                                                            } else {
                                                                setDocument(e.target.value)
                                                            }
                                                            onChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value === '' ||
                                                                e.target.value === null
                                                            ) {
                                                                setError('Document', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            } else if (e.target.value.length < 4) {
                                                                setError('Document', {
                                                                    message: 'Mínimo 4 caracteres',
                                                                })
                                                            } else {
                                                                clearErrors('Document')
                                                            }
                                                            onBlur()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors['Document'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['Document']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <TextPBold>Nombre de Usuario</TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'UserName'}
                                                rules={{ required: 'Este campo es requerido' }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="hide-number-arrows"
                                                        type="text"
                                                        {...register('UserName', {
                                                            required: true,
                                                        })}
                                                        maxLength={16}
                                                        max={16}
                                                        value={userName}
                                                        required
                                                        isInvalid={!!errors['UserName']}
                                                        onChange={(e) => {
                                                            if (e.target.value !== '') {
                                                                clearErrors('UserName')
                                                            }
                                                            setUserName(e.target.value)
                                                            onChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value === '' ||
                                                                e.target.value === null
                                                            ) {
                                                                setError('UserName', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            } else if (
                                                                containsSpecialCharacters(
                                                                    e.target.value
                                                                )
                                                            ) {
                                                                setError('UserName', {
                                                                    message:
                                                                        'Digite solamente letras o números',
                                                                })
                                                            } else {
                                                                clearErrors('UserName')
                                                            }
                                                            onBlur()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors['UserName'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['UserName']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row className="row-container">
                                    <Col md={3}>
                                        <TextPBold>Clave IVR</TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        <OverlayTrigger
                                            show={showKeyboard}
                                            placement="right"
                                            overlay={
                                                <Popover noSpace>
                                                    <DigitKeyboardTDC
                                                        onMouseLeave={handleSeeKeyboard}
                                                        onChange={handleInputDigit}
                                                        defaultValue={getValues('PassIVR')}
                                                    />
                                                </Popover>
                                            }
                                        >
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    className="hide-number-arrows"
                                                    type="password"
                                                    {...register('PassIVR', {
                                                        required: true,
                                                    })}
                                                    max={4}
                                                    maxLength={4}
                                                    value={passIVR}
                                                    required
                                                    isInvalid={!!errors['PassIVR']}
                                                    onFocus={
                                                        matchMedia ? onFocusInput : () => void 0
                                                    }
                                                    onChange={handleOnChangeDigitMobile}
                                                />
                                                {errors['PassIVR'] && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors['PassIVR']?.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col md={3}>
                                        <TextPBold>Clave Internet</TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        {matchMedia ? (
                                            <>
                                                <PasswordField handlePass={handlePassNet} />
                                            </>
                                        ) : (
                                            <Form.Group className="mb-3">
                                                <Controller
                                                    control={control}
                                                    name={'password'}
                                                    rules={{ required: 'Este campo es requerido' }}
                                                    render={({ field: { onChange, onBlur } }) => (
                                                        <Form.Control
                                                            className="hide-number-arrows"
                                                            type="password"
                                                            maxLength={8}
                                                            required
                                                            isInvalid={!!errors['password']}
                                                            onChange={(e) => {
                                                                if (e.target.value !== '') {
                                                                    clearErrors('password')
                                                                }
                                                                setPassNet(e.target.value)
                                                                onChange(e)
                                                                const validationMessage =
                                                                    passValidation(e.target.value)
                                                                setPassMessage(validationMessage)
                                                            }}
                                                            onBlur={(e) => {
                                                                if (
                                                                    e.target.value === '' ||
                                                                    e.target.value === null
                                                                ) {
                                                                    setError('password', {
                                                                        message:
                                                                            'Este campo es obligatorio',
                                                                    })
                                                                } else {
                                                                    clearErrors('password')
                                                                }
                                                                onBlur()
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors['password'] && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors['password']?.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="row-container">
                                    <Col md={3}>
                                        <TextPBold>Confirmación</TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        {matchMedia ? (
                                            <>
                                                <PasswordFieldConfirm
                                                    handlePassConfirm={handlePassConfirm}
                                                />
                                                {errors['PassConfirmation'] && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors['PassConfirmation']?.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </>
                                        ) : (
                                            <Form.Group className="mb-3">
                                                <Controller
                                                    control={control}
                                                    name={'passwordConfirm'}
                                                    rules={{ required: 'Este campo es requerido' }}
                                                    render={({ field: { onChange, onBlur } }) => (
                                                        <Form.Control
                                                            className="hide-number-arrows"
                                                            type="password"
                                                            maxLength={8}
                                                            required
                                                            isInvalid={!!errors['passwordConfirm']}
                                                            onChange={(e) => {
                                                                if (e.target.value !== '') {
                                                                    clearErrors('passwordConfirm')
                                                                }
                                                                setPassConfirmation(e.target.value)
                                                                onChange(e)
                                                            }}
                                                            onBlur={(e) => {
                                                                if (
                                                                    e.target.value === '' ||
                                                                    e.target.value === null
                                                                ) {
                                                                    setError('passwordConfirm', {
                                                                        message:
                                                                            'Este campo es obligatorio',
                                                                    })
                                                                } else {
                                                                    clearErrors('passwordConfirm')
                                                                }
                                                                onBlur()
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors['passwordConfirm'] && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors['passwordConfirm']?.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        <TextPBold>
                                            Seguridad ({secVal1} + {secVal2})
                                        </TextPBold>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'Security'}
                                                rules={{ required: 'Este campo es requerido' }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="hide-number-arrows"
                                                        type="number"
                                                        {...register('Security', {
                                                            required: true,
                                                        })}
                                                        value={security}
                                                        required
                                                        isInvalid={!!errors['Security']}
                                                        onChange={(e) => {
                                                            if (e.target.value !== '') {
                                                                clearErrors('Security')
                                                            }
                                                            setSecurity(e.target.value)
                                                            onChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value === '' ||
                                                                e.target.value === null
                                                            ) {
                                                                setError('Security', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            } else {
                                                                clearErrors('Security')
                                                            }
                                                            onBlur()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors['Security'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['Security']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                            {passMessage !== '' && (
                                <Row>
                                    <TextPBold>Nivel de seguridad: {passMessage}</TextPBold>
                                </Row>
                            )}

                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(HandleFormSubmit)}>
                                    <ButtonsContainer>
                                        <ModalButton
                                            id="btnSubmit"
                                            className="button-block"
                                            variant="sub-dominant"
                                            type="button"
                                            onClick={handleSubmit(HandleFormSubmit)}
                                        >
                                            Continuar
                                        </ModalButton>
                                        <ModalCancelButton
                                            variant="primary"
                                            onClick={() => redirection('/login')}
                                        >
                                            Cancelar
                                        </ModalCancelButton>
                                    </ButtonsContainer>
                                </form>
                            </FormProvider>
                        </Content>
                    </FormProvider>
                    <ModalLoading isLoading={isLoading} />
                </>
            )}
            {isSuccess && (
                <SuccessfulForm
                    currentDateTime={currentDateTime}
                    document={document}
                    userName={userName}
                    wsMessage={wsMessage}
                    voucherNumber={voucherNumber}
                    redirection={redirection}
                />
            )}
            {isRejected && <RejectedForm wsMessage={wsMessage} tryAgain={TryAgain} />}
        </Wrapper>
    )
}

export default UserCreationForm
