import { AxiosResponse } from 'axios'
import { certificateService, faiAccountTransfer } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetAgreementTemplateCertificateClient(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetAgreementTemplateCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function fetchGenerateCertificatesPdfLogs(
    tokenSave: string,
    body: any
): Promise<Result> {
    try {
        return await faiAccountTransfer
            .postTransactionFai('/api/PdfGenerate/GenerateCertificatesPdfLogs', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function generatePdfLogs(
    nameCertificate: string,
    response: boolean,
    tokenSave: string
): Promise<void> {
    const body = {
        NameCertificate: nameCertificate,
        Successful: response,
    }

    await fetchGenerateCertificatesPdfLogs(tokenSave, body)
}
