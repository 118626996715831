import { Content, Wrapper } from '../../InterbankTransfer-Styled'
import { TextInfo, TextP, UserDate } from './InitialInfo-Styled'

interface InitialInfoProps {
    associatedName: string
}
const InitialInfo: React.FC<InitialInfoProps> = ({ associatedName }) => {
    return (
        <Wrapper>
            <Content>
                <UserDate>
                    {' '}
                    Apreciado(a): <span>{associatedName}</span>
                </UserDate>
                <TextInfo>
                    Este servicio te permitirá realizar transferencias, por favor ten en cuenta las
                    siguientes recomendaciones:
                </TextInfo>
                <ul>
                    <li>
                        <TextP>El monto máximo es de $10’000.000 diarios.</TextP>
                    </li>
                    <li>
                        <TextP>El monto mínimo es de $50.000 diarios.</TextP>
                    </li>
                    <li>
                        <TextP>El monto máximo en el mes es de $50’000.000.</TextP>
                    </li>
                    <li>
                        <TextP>Verifica los datos antes de enviar.</TextP>
                    </li>
                    <li>
                        <TextP>Utiliza el teclado virtual para ingresar la clave de IVR.</TextP>
                    </li>
                </ul>
            </Content>
        </Wrapper>
    )
}

export default InitialInfo
