import { Col, Row } from 'react-bootstrap'

import {
    ButtonContinue,
    ContainerButtons,
    Content,
    TextInfo,
    TextSimple,
    TextSimpleDate,
    TextSimpleInfo,
    TextTitle,
    UserDate,
    ButtonCancel,
} from './TransactionResponse-Styled'
import { useHistory } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/es'
import { ContainerTitle, Title, Wrapper } from '../../InterbankTransfer-Styled'
import './TransactionConfirmation.css'

interface TransactionResponseProps {
    associatedName: string
    dateTransaction: string
    accountNumberAddressee: string
    documentNumberAddressee: string
    nameAddressee: string
    amountTransfer: string
    bank: string
    voucher: string
    messageTransaction: string
    responseTransaction: string
}
const TransactionResponse: React.FC<TransactionResponseProps> = ({
    associatedName,
    dateTransaction,
    accountNumberAddressee,
    documentNumberAddressee,
    nameAddressee,
    amountTransfer,
    bank,
    voucher,
    messageTransaction,
    responseTransaction,
}) => {
    const history = useHistory()
    const date = moment().format('YYYY-MM-DD')

    const print = (): void => {
        const originalTitle = document.title
        document.title = 'Comprobante_transferencias_interbancarias' + date
        window.print()

        document.title = originalTitle
    }

    const value = parseFloat(amountTransfer)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    return (
        <>
            <ContainerTitle>
                <Title>
                    <span>Respuesta</span>
                </Title>
            </ContainerTitle>
            <Wrapper>
                <Content>
                    <UserDate>
                        {' '}
                        Sr(a): <span>{associatedName}</span>
                    </UserDate>
                    <TextInfo>
                        A continuación, te presentamos la información de tu transferencia
                    </TextInfo>
                </Content>
            </Wrapper>
            <div id="section-print">
                <Wrapper className="printable">
                    <Content className="second-content">
                        {messageTransaction.includes('insuficiente') ||
                        responseTransaction !== '200' ? (
                            <TextTitle className="text-danger">{messageTransaction}</TextTitle>
                        ) : (
                            <TextTitle>{messageTransaction}</TextTitle>
                        )}

                        <TextSimple>Comprobante: {voucher}</TextSimple>

                        <Row className="row-transaction">
                            <Col md={3}>
                                {' '}
                                <TextSimpleInfo>
                                    {moment(dateTransaction).format('DD/MM/YYYY hh:mm:ss a')}
                                </TextSimpleInfo>
                                <TextSimpleDate>Fecha y Hora</TextSimpleDate>
                            </Col>
                            <Col md={3}>
                                <TextSimpleInfo>{bank}</TextSimpleInfo>
                                <TextSimpleDate>Banco</TextSimpleDate>
                            </Col>

                            <Col md={3}>
                                <TextSimpleInfo>{documentNumberAddressee}</TextSimpleInfo>
                                <TextSimpleDate>Identificación del Destinatario</TextSimpleDate>
                            </Col>
                            <Col md={3}>
                                <TextSimpleInfo>{nameAddressee}</TextSimpleInfo>
                                <TextSimpleDate>Nombre del Destinatario</TextSimpleDate>
                            </Col>
                        </Row>

                        <Row className="row-transaction">
                            <Col md={3}>
                                <TextSimpleInfo>{accountNumberAddressee}</TextSimpleInfo>
                                <TextSimpleDate>Cuenta Destinatario</TextSimpleDate>
                            </Col>
                            <Col md={3}>
                                <TextSimpleInfo>{formatter.format(value)}</TextSimpleInfo>
                                <TextSimpleDate>Valor de la Transacción</TextSimpleDate>
                            </Col>
                        </Row>

                        <ContainerButtons className="print-w">
                            <ButtonContinue onClick={print}>Imprimir</ButtonContinue>
                            <ButtonCancel
                                variant="sub-dominant"
                                type="submit"
                                extend
                                onClick={() => history.push('/home-wallet')}
                            >
                                Terminar
                            </ButtonCancel>
                        </ContainerButtons>
                    </Content>
                </Wrapper>
            </div>
        </>
    )
}

export default TransactionResponse
