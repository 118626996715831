import { StyleSheet, Font } from '@react-pdf/renderer'

import FontMontserratRegular from '../../../../assets/sources/Montserrat-Regular.ttf'
import FontHelveticaRegular from '../../../../assets/sources/Helvetica.ttf'
import FontMontserratMedium from '../../../../assets/sources/Montserrat-Medium.ttf'

Font.register({
    family: 'Montserrat-Regular',
    format: 'truetype',
    src: FontMontserratRegular,
    weight: '400',
})
Font.register({
    family: 'Helvetica-Regular',
    format: 'truetype',
    src: FontHelveticaRegular,
    weight: '800',
})
Font.register({
    family: 'Montserrat-Medium',
    format: 'truetype',
    src: FontMontserratMedium,
    weight: '500',
})

export const styles = StyleSheet.create({
    contentResultPay: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginRight: 'auto',
        marginTop: '30px',
        justifyContent: 'center',
    },
    textItemFailed: { fontSize: '18px' },
    contentItemFailed: { marginTop: '28px' },
    contentResultPayImg: { width: '31px', height: '26px', marginRight: '10px' },
    itemTitle: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginTop: '20px',
        // gap: '30px',
    },
    itemRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
        border: '1px solid gray',
        borderRadius: '10px',
        paddingTop: '15px',
        paddingLeft: '15px',
    },
    rowSubResult: { marginTop: '10px', marginBottom: '5px' },
    rowSubResultEsp: {
        // display: 'grid',
        gridTemplateColumns: '22% 22% 22% 27%',
        marginTop: '30px',
        gap: '25px',
        marginBottom: '30px',
    },
    sectTitleDctoPayments: { marginBottom: '45px' },
    subTitle: { fontSize: '12px', color: '#707070' },
    contentItem: { marginLeft: '28px', display: 'flex', flexDirection: 'column' },
    textPayDeclined: { fontFamily: 'Helvetica-Regular', fontSize: '16px', color: '#000000' },
    textPayResult: {
        fontSize: '12px',
        fontFamily: 'Montserrat-Medium',
        color: '#000000',
        marginTop: '20px',
        marginBottom: '60px',
    },
    textPayItem: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#000000',
        marginTop: '10px',
        marginBottom: '30px',
    },
    textPayItemFirts: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#000000',
        marginTop: '10px',
    },
    textPayItemSecond: {
        marginTop: '5px',
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#000000',
    },
    textPayResultGreen: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#55B948',
        marginLeft: '5px',
        marginTop: '10px',
    },
    textPayResultRed: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#D5342F',
        marginLeft: '5px',
        marginTop: '10px',
    },
    textPayResultBlue: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#07539F',
        marginLeft: '5px',
        marginTop: '10px',
    },
    textPayResultYellow: {
        fontSize: '10px',
        fontFamily: 'Montserrat-Medium',
        color: '#F5A50B',
        marginLeft: '5px',
        marginTop: '10px',
    },
    textSectionResult: {
        color: '#000000',
        fontSize: '13px',
        marginLeft: '5%',
        marginTop: '10px',
        marginRight: '5%',
    },
    titleSectionResult: { fontSize: '14px', color: '#07539F', marginLeft: '5%' },
    titleTable: { fontSize: '14px', color: '#F5A50B', marginTop: '5px', marginLeft: '10px' },
    contentDetailCtoPayments: {
        width: '94%',
        border: '1px solid gray',
        borderRadius: '10px',
        borderTopColor: '#707070',
        margin: '20px 3%',
        height: '250px',
        paddingTop: '10px',
    },
    contentItemResult: { width: '100%', padding: '30px' },
    itemResultBasic: {
        display: 'flex',
        flexDirection: 'column',
        // gap: '25px',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    itemResultBasicArea4: {
        display: 'flex',
        flexDirection: 'column',
        // gap: '25px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gridArea: 'area4',
    },
    itemWhitImage: {
        display: 'flex',
        flexDirection: 'row',
        // gap: '5px',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    itemResultComp: { display: 'flex', justifyContent: 'center', alignItems: 'flex-start' },
    itemResultCompImg: { margin: 'auto 8px auto 0px', width: '25px', height: '25px' },
    itemResultState: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    itemResultStateImg: { marginTop: '13px', width: '5px', height: '5px' },
    transactionDeclined: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '10px',
        marginBottom: '20px',
        width: '533px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '30px',
    },
    subTitlePrimary: {},
})
