/* eslint-disable prettier/prettier */
import { createReducerCtx, ReducerCtxType } from '../../../hooks'

// types declaration
export type StateShape = {
    currentStep: number
    lastStep: boolean
    totalSteps: number
    feedback: {
        isEditBuyWallet: boolean
        idPurchase: number | null
        code: number | string
        message: string
        minRange: number | null
        maxRange: number | null
    }
    firstStep: {
        amountOfFees: string
        buyWallet: string | null
    }
    secondStep: {
        nameBank: string
        otherNameBank: string
        creditCardNumber: string
        amountBuy: string
        formatFile: File | null
    }
    isEdit: boolean
    idPurchase: number
}

export type ActionShape =
    | {
        type: 'NEXT_STEP'
    }
    | {
        type: 'PREV_STEP'
    }
    | {
        type: 'GO_TO_STEP'
        payload: {
            step: number
        }
    }
    | {
        type: 'SET_FEEDBACK'
        payload: {
            code: number | string
            message: string
            isEditBuyWallet?: boolean
            idPurchase?: number
            minRange?: number
            maxRange?: number
        }
    }
    | {
        type: 'SET_IS_EDIT'
        payload: {
            isEdit: boolean
        }
    }
    | {
        type: 'SET_ID_PORTFOLIO'
        payload: {
            idPurchase: number
        }
    }
    | {
        type: 'SET_FIRST_STEP'
        payload: {
            amountOfFees: string
        }
    }
    | {
        type: 'SET_SECOND_STEP'
        payload: {
            nameBank: string
            otherNameBank: string
            creditCardNumber: string
            amountBuy: string
            formatFile: File | null
        }
    }

// define initial
const initialState: StateShape = {
    currentStep: 1,
    lastStep: false,
    totalSteps: 11,
    feedback: {
        isEditBuyWallet: false,
        idPurchase: null,
        code: '',
        message: '',
        minRange: null,
        maxRange: null,
    },
    firstStep: {
        amountOfFees: '',
        buyWallet: '',
    },
    secondStep: {
        nameBank: '',
        otherNameBank: '',
        creditCardNumber: '',
        amountBuy: '',
        formatFile: null,
    },
    isEdit: false,
    idPurchase: 0,
}

const reducer = (state: StateShape, action: ActionShape): StateShape => {
    switch (action.type) {
        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1,
                lastStep: state.currentStep + 1 >= state.totalSteps,
            }
        case 'PREV_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1,
                lastStep: state.currentStep - 1 >= state.totalSteps,
            }
        case 'GO_TO_STEP':
            return {
                ...state,
                currentStep: action.payload.step,
                lastStep: action.payload.step >= state.totalSteps,
            }
        case 'SET_FEEDBACK':
            return {
                ...state,
                feedback: {
                    code: action.payload.code,
                    message: action.payload.message,
                    isEditBuyWallet: action.payload.isEditBuyWallet ?? false,
                    idPurchase: action.payload.idPurchase ?? null,
                    minRange: action.payload.minRange ?? null,
                    maxRange: action.payload.maxRange ?? null,
                },
            }
        case 'SET_IS_EDIT':
            return {
                ...state,
                isEdit: action.payload.isEdit,
            }
        case 'SET_ID_PORTFOLIO':
            return {
                ...state,
                idPurchase: action.payload.idPurchase,
            }
        case 'SET_FIRST_STEP':
            return {
                ...state,
                firstStep: {
                    ...state.firstStep,
                    amountOfFees: action.payload.amountOfFees,
                },
            }
        case 'SET_SECOND_STEP':
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    nameBank: action.payload.nameBank,
                    otherNameBank: action.payload.otherNameBank,
                    creditCardNumber: action.payload.creditCardNumber,
                    amountBuy: action.payload.amountBuy,
                    formatFile: action.payload.formatFile,
                },
            }
        default:
            return state
    }
}

export let StepContext: React.Context<ReducerCtxType<StateShape, ActionShape>>

const createStepProvider = (): React.FunctionComponent<Partial<StateShape>> => {
    const [ctx, StepProvider] = createReducerCtx(reducer, initialState)
    StepContext = ctx

    return StepProvider
}

export default createStepProvider
