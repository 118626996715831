import { CreditCardSVG, DotsMenu } from '../../../../../utils/getIcons'
import {
    CardHeader,
    CardsProductsContent,
    CreditCardContainer,
    Dropdown,
} from '../../CardsProducts/cardsProducts-styles'

export const AdminCardProducts = (): JSX.Element => {
    return (
        <CardsProductsContent data-tour="credit-cards">
            <p className="">Tarjetas de crédito</p>
            <CreditCardContainer>
                <div>
                    <CardHeader>
                        <div>
                            <img src={CreditCardSVG} alt="tarjeta de crédito" />
                            <div></div>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <img className="dots-menu" src={DotsMenu} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu></Dropdown.Menu>
                        </Dropdown>
                    </CardHeader>
                </div>

                <div className="link-section">
                    <p className="link-btn" style={{ color: '#E70000' }}>
                        No se encontraron datos para mostrar
                    </p>
                </div>
            </CreditCardContainer>
        </CardsProductsContent>
    )
}
