import { FC } from 'react'
import {
    ButtonsContainer,
    Content,
    ContentInfo,
    ModalCancelButton,
    TextPBold,
    Wrapper,
} from '../../Codebtors-styles'
import { ConnectionErrorSVG } from '../../../../utils/getIcons'
// styles

interface ProblemProps {
    redirection: (value: string) => void
}

const CodebtorProblem: FC<ProblemProps> = ({ redirection }): JSX.Element => {
    return (
        <Wrapper>
            <Content>
                <ContentInfo>
                    <img src={ConnectionErrorSVG} alt="black" />
                    <TextPBold>
                        En este momento no podemos procesar tu consulta, por favor intenta más
                        tarde.
                    </TextPBold>
                </ContentInfo>
                <ButtonsContainer>
                    <ModalCancelButton
                        variant="primary"
                        onClick={() => redirection('/home-wallet')}
                    >
                        Regresar al home
                    </ModalCancelButton>
                </ButtonsContainer>
            </Content>
        </Wrapper>
    )
}

export default CodebtorProblem
