import {
    DocumentContent,
    HeaderText,
    PagePdf,
    RemittentText,
    TextInfo,
    SubHeaderText,
} from './PDFViewerMobile-Styled'

interface PDFViewerMobileProps {
    Header: string
    Body: string
    Footer: string
    Valediction: string
    CardNumber: string
    DateExpiredCard: string
    TotalLimit: string
    QuotaAvailable: string
    pageWidth: number
    pageHeight: number
}
const PDFViewerMobile: React.FC<PDFViewerMobileProps> = ({
    Header,
    Body,
    Footer,
    Valediction,
    CardNumber,
    DateExpiredCard,
    TotalLimit,
    QuotaAvailable,
    pageWidth,
    pageHeight,
}) => {
    const tableStyle = {
        width: '100%',
        marginTop: '20px',
        marginBottom: '20px',
    }

    const cellStyle = {
        border: '1px solid #000',
        padding: '8px',
    }
    return (
        <PagePdf>
            <DocumentContent>
                <div>
                    <HeaderText>{Header}</HeaderText>
                    <SubHeaderText>CERTIFICA</SubHeaderText>
                    <TextInfo>
                        Certifica que el señor(a) {Body} presenta la siguiente información:
                    </TextInfo>
                    <table style={tableStyle}>
                        <tbody>
                            <tr>
                                <td style={cellStyle}>Tarjeta N°</td>
                                <td style={cellStyle}>XXXX XXXX XXXX {CardNumber}</td>
                            </tr>
                            <tr>
                                <td style={cellStyle}>Vencimiento</td>
                                <td style={cellStyle}>{DateExpiredCard}</td>
                            </tr>
                            <tr>
                                <td style={cellStyle}>Cupo</td>
                                <td style={cellStyle}>$ {TotalLimit}</td>
                            </tr>
                            <tr>
                                <td style={cellStyle}>Saldo Actual</td>
                                <td style={cellStyle}>$ {QuotaAvailable}</td>
                            </tr>
                        </tbody>
                    </table>
                    <TextInfo>{Footer}</TextInfo>
                    <TextInfo>
                        <p>Atentamente</p>
                    </TextInfo>
                    <RemittentText>{Valediction}</RemittentText>
                </div>
            </DocumentContent>
        </PagePdf>
    )
}

export default PDFViewerMobile
