import tw, { styled } from 'twin.macro'

export const SixteenthStepWrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const SixteenthStepContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[550px] lg:max-w-[924px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const CtrRowField = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-center lg:justify-between lg:gap-3`}

    & > div {
        ${tw`w-full lg:w-1/2`}
    }
`

export const UploadLabel = styled.label`
    ${tw`!bg-[var(--approved-color-dark)] h-[50px] border-0 w-52 relative overflow-hidden`}
    ${tw`rounded cursor-pointer flex justify-center items-center`}
`

export const UploadInput = styled.input`
    ${tw`absolute top-0 left-0 w-0`}
    ${tw`hidden`}
`

export const UploadSpan = styled.span`
    ${tw`text-white`}
`

export const StyledFile = styled.ul`
    ${tw`px-3 py-1.5 rounded-[8px] my-1 list-none flex justify-between items-center shadow-[0px 3px 6px #00000029]`}
`

export const StyledFileItem = styled.li`
    ${tw`flex justify-between w-full`}
`

export const StyledFileAnchor = styled.a`
    ${tw``}
`

export const StyledFileText = styled.p`
    ${tw``}
`

export const StyledCardFileIcon = styled.img`
    ${tw`cursor-pointer`}
`
export const UploadFileContainer = styled.div`
    ${tw`my-10 flex flex-col lg:flex-row lg:items-center`}
`

export const UploadFileRight = styled.div`
    ${tw`py-4 px-2 w-full flex flex-col items-center lg:w-1/2`}
    ${tw`bg-[#FAFBFD] lg:bg-transparent`}
`

export const TextInstruction = styled.p`
    ${tw`text-center`}
`

export const ButtonsCtr = styled.div`
    ${tw`flex lg:justify-end my-5 w-full`}

    & button {
        ${tw`w-full lg:w-[200px]`}
    }
`

export const UploaderFileImg = styled.img`
    ${tw`w-[100px] mb-3`}
`

export const UploaderFileH4 = styled.h4`
    ${tw`font-semibold text-base text-[#425453] text-center`}
`

export const UploaderFileText = styled.p`
    ${tw`font-normal text-sm text-[#42545347]`}
`

export const FileUploadContainer = styled.div`
    ${tw`border-4 p-4 border-dashed  border-[#42545347] w-[420px] rounded-md space-y-4 hover:bg-gray-200 mb-8`}
    ${tw`cursor-pointer flex flex-col items-center justify-between py-6 px-6`}
    ${tw`hidden p-0 lg:flex lg:w-1/2`}
`

export const FileUploadPrompt = styled.div`
    ${tw`text-center text-gray-700`}
`
