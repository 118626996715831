import { Modal } from 'react-bootstrap'

// icons

// styles
import { ModalInsert, ModalTitle, ModalText } from './InsertModal-Styled'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    textTitle?: string
    textBody?: string
}

const InsertModal: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    textTitle,
    textBody,
}): JSX.Element => {
    return (
        <ModalInsert
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <button
                    onClick={handleClose}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                ></button>
            </Modal.Header>
            <Modal.Body>
                {textTitle && <ModalTitle>{textTitle}</ModalTitle>}
                {textBody && <ModalText>{textBody}</ModalText>}
            </Modal.Body>
        </ModalInsert>
    )
}

export default InsertModal
