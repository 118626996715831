import tw, { styled } from 'twin.macro'

export const DynamicKeyInputWrap = styled.div`
    text-align: center;
    justify-content: center;
    ${tw`flex gap-[15px] p-0`}
`
export const DynamicKeyInputStyle = styled.input`
    ${tw`w-12 h-12 border-4 rounded bg-transparent outline-none text-center font-bold text-xl border-solid border-gray-custom-300 opacity-50 focus:border[1px solid #f5a50b] focus:text-gray-700 text-gray-400 transition`}

    border-width: 1px;
    margin: 10px;
    font-weight: 700 !important;
    margin-right: '10px';
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        ${tw`gap-5`}
    }

    @media (min-width: 352px) and (max-width: 400px) {
        ${tw`w-8 h-9 m-0.5 p-0 text-xl`}
    }

    @media (min-width: 400px) and (max-width: 550px) {
        ${tw`w-10 h-10 m-0.5 p-0 text-xl`}
    }

    @media (max-width: 352px) {
        ${tw`w-6 h-9 m-0.5 p-0 text-xl`}
    }

    input:focus {
        box-shadow: 0 0 0 0.25rem var(--sub-dominant-color) !important;
    }
`

export const LinkService = styled.a`
    ${tw`mt-4 mb-6`}
    text-decoration: none;
    color: var(--dominant-color-dark) !important;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;

    a:focus {
        color: var(--dominant-color-dark) !important;
    }
`
