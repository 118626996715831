import { ButtonContent, ButtonsContainer, ModalButton, ModalForm } from './ModalForm-styles'
import { Modal, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { ContainerTitle, SpaceStep, Title } from '../../ManageableContent-styled'
import { Button } from '../../../../components'

interface ModalFormAssignRolProps {
    showModal?: boolean
    isLoading: boolean
    titleText: string
    handleCloseModal: () => void
    valueDataChange: string
    updateOnSubmitted: (value: string) => void
}

const ModalFormAssignRol: React.FC<ModalFormAssignRolProps> = ({
    showModal,
    isLoading,
    titleText,
    handleCloseModal,
    valueDataChange,
    updateOnSubmitted,
}): JSX.Element => {
    const [dataValueUpdate, setDataValueUpdate] = useState<string>('')
    const methods = useForm()
    const { control, formState, setError, clearErrors, register, getValues, handleSubmit } = methods
    const { errors } = formState

    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    const onCancel = (): void => {
        handleCloseModal()
        setDataValueUpdate(valueDataChange)
    }
    const handleFormSubmit = (): void => {
        const formData = getValues()
        console.log(formData)
        updateOnSubmitted(dataValueUpdate)
    }

    useEffect(() => {
        setDataValueUpdate(valueDataChange)
    }, [valueDataChange])

    return (
        <>
            <ModalForm
                show={showModal}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Title>
                    <ContainerTitle>
                        <Title>{titleText}</Title>
                    </ContainerTitle>
                </Modal.Title>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Controller
                            control={control}
                            name={'dataValueUpdate'}
                            rules={{
                                required:
                                    dataValueUpdate === '' ? 'Este campo es requerido' : false,
                            }}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <Form.Control
                                    type="textarea"
                                    as={'textarea'}
                                    rows={valueDataChange.length > 20 ? 5 : 1}
                                    {...register('dataValueUpdate', {
                                        required: dataValueUpdate === '',
                                    })}
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                        setDataValueUpdate(e.target.value)
                                        onChange(e)
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value === '' || e.target.value === null) {
                                            setError('dataValueUpdate', {
                                                message: 'Este campo es obligatorio',
                                            })
                                        } else {
                                            clearErrors('dataValueUpdate')
                                        }
                                        onBlur()
                                    }}
                                    isInvalid={!!errors['dataValueUpdate']}
                                    value={dataValueUpdate}
                                />
                            )}
                        />
                        {errors['dataValueUpdate'] && (
                            <Form.Control.Feedback type="invalid">
                                {errors['dataValueUpdate']?.message}
                            </Form.Control.Feedback>
                        )}
                        <SpaceStep />
                        <ButtonsContainer>
                            <ModalButton variant="primary" onClick={onCancel}>
                                Cancelar
                            </ModalButton>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <ButtonContent>
                                    <Button
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleFormSubmit}
                                        isLoading={isLoading}
                                        extend
                                        disabled={isLoading || isDisabled}
                                    >
                                        Guardar
                                    </Button>
                                </ButtonContent>
                            </form>
                        </ButtonsContainer>
                    </FormProvider>
                </Modal.Body>
            </ModalForm>
        </>
    )
}

export default ModalFormAssignRol
