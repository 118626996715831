import tw, { styled } from 'twin.macro'
import { FormLabel as FormLabelApp, FormGroup as FormGroupApp } from '../../../../components'
import { Button } from 'react-bootstrap'

export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}

    span {
        color: #000000;
    }
`

export const TextP = styled.p`
    ${tw`font-helvetica text-base`}

    span {
        color: #f5a50b;
    }
`

export const FromFai = styled.div`
    ${tw`font-montserrat text-base`}

    .input-icon-user {
        ${tw`font-helvetica`}
        text-transform: capitalize;
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/user-alt.svg);
        background-size: 13px;
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
        color: #53595a;
    }

    input.input-icon-user.form-control:disabled {
        background-color: transparent;
    }

    input.input-icon-associated-id.form-control:disabled {
        background-color: transparent;
    }

    .input-icon-asociate-id {
        background-image: var(--url-mini-identification);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .input-icon-destination-id {
        background-image: var(--url-mini-identification);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-destination-id.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-identification), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .input-icon-amount-transfer {
        background-image: var(--url-dollar-currency-gray-icon);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-amount-transfer.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-dollar-currency-gray-icon), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .input-icon-destination-typeID {
        background-image: var(--url-mini-identification), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    select.input-icon-destination-typeID.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-identification), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);
        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        background-size: 16px;
    }

    .input-icon-state {
        background-image: var(--url-state-gray-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    select.input-icon-state.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-state-gray-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);
        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        background-size: 16px;
    }

    .input-icon-city {
        background-image: var(--url-city-gray-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    select.input-icon-city.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-city-gray-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);
        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        background-size: 16px;
    }

    .input-icon-building {
        background-image: var(--url-building-gray-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    select.input-icon-building.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-building-gray-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);
        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        background-size: 16px;
    }

    .input-icon-piggy-bank {
        background-image: var(--url-piggy-bank-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-piggy-bank.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-piggy-bank-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);
        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        background-size: 16px;
    }

    .input-icon-briefcase {
        background-image: var(--url-briefcase-gray-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-briefcase.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-briefcase-gray-icon), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
`

export const ContainerButtons = styled.div`
    .buttonContinue {
        ${tw`my-2.5 mx-5 lg:w-[250px] h-[50px] color[#000000] rounded-[6px] font-normal font-montserrat`}

        @media (min-width: 320px) and (max-width: 1020px) {
            ${tw`w-[300px]`}
        }
    }

    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: right;
    align-items: center;
`

interface FormOptionProps {
    show?: boolean
}

export const FormOption = styled.option<FormOptionProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-5 lg:w-[250px] h-[50px] color[#000000] rounded-[6px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color) !important;
    }

    background-color: #ffffff;
    border-color: #000000 !important;

    @media (min-width: 320px) and (max-width: 1020px) {
        ${tw`w-[300px]`}
    }
`

export const ModalContinueButton = styled(Button)`
    ${tw`my-2.5 mx-5 lg:w-[250px] h-[50px] color[#000000] rounded-[6px] font-normal font-montserrat`}

    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    @media (min-width: 320px) and (max-width: 1020px) {
        ${tw`w-[300px]`}
    }
`
