import { ReactElement } from 'react'
// styled components
import { ImgContent, StepContent, StepTitle } from '../../appTour-styles'
import { CheckOperationSVG } from '../../../../utils/getIcons'

const DetailCardOpeningStep = (): ReactElement => {
    return (
        <StepContent>
            <StepTitle tw="text-center">
                <span className="sub-title">Felicidades ya puedes usar tu tarjeta de crédito</span>
            </StepTitle>

            <ImgContent>
                <img src={CheckOperationSVG} alt="Tour TC" height="100" />
            </ImgContent>

            <p>Conoce cómo funcionará tu tarjeta de crédito y cómo gestionar sus funciones.</p>
        </StepContent>
    )
}

export default DetailCardOpeningStep
