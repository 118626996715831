import { ReactElement } from 'react'
// styled components
import { StepContent, StepTitle } from '../../appTour-styles'

const DetailCardPaymentStep = (): ReactElement => {
    return (
        <StepContent>
            <StepTitle>
                Pagar tu tarjeta <span className="sub-title">es muy sencillo</span>
            </StepTitle>

            <p>Solo debes elegir qué concepto deseas pagar y agregarlo al apartado de pagos.</p>
        </StepContent>
    )
}

export default DetailCardPaymentStep
