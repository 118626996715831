import { AxiosResponse } from 'axios'
import {
    certificateService,
    interbankTransfer,
    keyChangeService,
} from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetInfoAssociate(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetAgreementCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetInfoFai(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetFaiAccountClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateIVR(tokenSave: string, body: string): Promise<Result> {
    try {
        return await keyChangeService
            .validateIVR('api/Authorization/ValidIVR', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetDepartment(tokenSave: string): Promise<Result> {
    try {
        return await interbankTransfer
            .getData('/api/User/GetDepartment', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetCities(tokenSave: string, nameState: string): Promise<Result> {
    try {
        return await interbankTransfer
            .getData(`/api/User/GetCities?departmentName=${nameState}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useTransaction(tokenSave: string, body: any): Promise<Result> {
    try {
        return await interbankTransfer
            .postTransaction('/api/Transaction/SendACH/SendACH', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidacionACHTransfer(
    tokenSave: string,
    valueTransfer: number
): Promise<Result> {
    try {
        return await interbankTransfer
            .getData(
                `/api/Transaction/ValidationsACHTransfers/ValidationsACHTransfers?valueTransfer=${valueTransfer}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetTypeDocumentClient(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetDocumentTypeClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
