import { Modal } from 'react-bootstrap'
// icons
import { CreditCardFlowSVG } from '../../../../utils/getIcons'
// styles
import { InfoModal, ModalTitle, ModalText } from './ModalInfo-Styled'

interface CreditDetailModalProps {
    showModalAlert: boolean
    handleClose: () => void
}

const ModalAlert: React.FC<CreditDetailModalProps> = ({
    showModalAlert,
    handleClose,
}): JSX.Element => {
    return (
        <InfoModal
            show={showModalAlert}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <img src={CreditCardFlowSVG} alt="" className="CheckOperationSVG" />
                <ModalTitle>¡Ha ocurrido un error!</ModalTitle>
                <ModalText>
                    No se ha podido activar la tarjeta fuera de Colombia, por favor intente de nuevo
                    o comunícate con servicio al asociado'
                </ModalText>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalAlert
