import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    useGetTemplateCertificate,
    useUpdateTemplateCertificate,
} from '../../hooks/useManageContent'
import { Loading, ModalGeneric } from '../../../../components'
import {
    ButtonPagination,
    ButtonUnstyled,
    ContainerTable,
    ContentInfo,
    PaginationContent,
    SectionTd,
    SectionTh,
} from '../../ManageableContent-styled'
import { CancelWhite, EditWhite, NotProductSVG } from '../../../../utils/getIcons'
import { Form, PageItem, Pagination } from 'react-bootstrap'
import ModalForm from '../ModalForm'

interface TemplateCertificate {
    Id: number
    ProductName: string
    Header: string
    Body: string
    Footer: string
    Valediction: string
}

const TableTemplateCertificates: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const [templateCertificates, setTemplateCertificate] = useState<TemplateCertificate[]>([])
    const [isEditable, setIsEditable] = useState<boolean>(false)

    const currentItems = templateCertificates.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(templateCertificates.length / itemsPerPage)
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    let keyCounter = 0

    const [showModalForm, setShowModalForm] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
    const [titleModal, setTitleModal] = useState<string>('')
    const [valueConfiguration, setValueEdit] = useState<string>('')
    const [idCertProduct, setIdCertProduct] = useState<number>(0)
    const [header, setHeader] = useState<string>('')
    const [body, setBody] = useState<string>('')
    const [productName, setProductName] = useState<string>('')
    const [footer, setFooter] = useState<string>('')
    const [valediction, setValediction] = useState<string>('')

    const GetTemplateCertificate = (): void => {
        setLoading(true)
        useGetTemplateCertificate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setTemplateCertificate(res['data']['Data'])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    useEffect(() => {
        GetTemplateCertificate()
    }, [])

    const [editableColumn, setEditableColumn] = useState<string | null>(null)

    const updateRegister = (columnName: string): void => {
        setIsEditable(true)
        setEditableColumn(columnName)
        const thElement = document.querySelector(`#${columnName}`)
        if (thElement) {
            const txtTh = thElement.textContent ? thElement.textContent : ''
            setTitleModal(txtTh)
        }
    }

    const cancelEdit = (): void => {
        setIsEditable(false)
    }

    const [checkValue, setCheckValue] = useState<number>()

    const handleOnChange = (
        info: TemplateCertificate,
        newValue: string,
        { target }: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = target
        setCheckValue(parseInt(value))
        setIdCertProduct(info.Id)
        setHeader(info.Header)
        setFooter(info.Footer)
        setValediction(info.Valediction)
        setProductName(info.ProductName)
        setBody(info.Body)
        setValueEdit(newValue)
        handleShowModalForm()
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    const handleShowModalForm = (): void => {
        setShowModalForm(true)
    }

    const handleCloseModalForm = (): void => {
        setShowModalForm(false)
        setValueEdit('')
        setCheckValue(0)
    }

    const HandleFormSubmit = (value: string): void => {
        setIsLoadingUpdate(true)
        setIsEditable(false)
        const dataBody = {
            Id: idCertProduct,
            ProductName: productName,
            Header: header,
            Body: body,
            Footer: footer,
            Valediction: valediction,
        }
        let updatedDataBody = {}
        const updatedField = editableColumn

        if (updatedField) {
            updatedDataBody = {
                ...dataBody,
                [updatedField]: value,
            }
        }

        useUpdateTemplateCertificate(tokenSave, updatedDataBody)
            .then((res: any) => {
                setIsLoadingUpdate(false)
                if (res['status'] === 200) {
                    GetTemplateCertificate()
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                handleCloseModalForm()
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorMessage('No se ha podido conectar con el servidor')
                handleCloseModalForm()
            })
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <ContainerTable striped bordered responsive>
                    <thead>
                        <tr>
                            <th>
                                <SectionTh>ID</SectionTh>
                            </th>
                            <th id="ProductName">
                                <SectionTh showThirdButton={isEditable}>Product Name</SectionTh>
                            </th>
                            <th id="Header">
                                <SectionTh showThirdButton={isEditable}>
                                    Header
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Header')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Header' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>

                            <th id="Body">
                                <SectionTh showThirdButton={isEditable}>
                                    Body
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Body')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Body' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th id="Footer">
                                <SectionTh showThirdButton={isEditable}>
                                    Footer
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Footer')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Footer' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th id="Valediction">
                                <SectionTh showThirdButton={isEditable}>
                                    Valediction
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Valediction')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Valediction' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((info: TemplateCertificate) => (
                            <tr key={info.Id}>
                                <td className="center">{info.Id}</td>
                                <td>
                                    <SectionTd>{info.ProductName}</SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Header}{' '}
                                        {isEditable && editableColumn === 'Header' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.Id}
                                                checked={checkValue === info.Id}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.Header, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Body}
                                        {isEditable && editableColumn === 'Body' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.Id}
                                                checked={checkValue === info.Id}
                                                onChange={(e) => handleOnChange(info, info.Body, e)}
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Footer}
                                        {isEditable && editableColumn === 'Footer' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.Id}
                                                checked={checkValue === info.Id}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.Footer, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Valediction}
                                        {isEditable && editableColumn === 'Valediction' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.Id}
                                                checked={checkValue === info.Id}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.Valediction, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </ContainerTable>
            )}
            <PaginationContent>
                <Pagination>
                    {currentPage !== 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`prev_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                {'<'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                    {[...Array(totalPages)].map((_, page) => (
                        <PageItem
                            key={`page_${page}`}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageClick(page + 1)}
                        >
                            {page + 1}
                        </PageItem>
                    ))}
                    {currentPage !== totalPages && totalPages > 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`next_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                {'>'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                </Pagination>
                <ContentInfo>
                    {templateCertificates.length === 0 && showError && (
                        <div style={{ display: 'flex' }}>
                            <img src={NotProductSVG} alt="IconEdit" width={50} />
                            <span style={{ alignContent: 'center' }}>
                                No hay información disponible
                            </span>
                        </div>
                    )}
                </ContentInfo>
            </PaginationContent>

            <ModalForm
                showModal={showModalForm}
                handleCloseModal={handleCloseModalForm}
                isLoading={isLoadingUpdate}
                titleText={titleModal}
                updateOnSubmitted={HandleFormSubmit}
                valueDataChange={valueConfiguration}
            />

            <ModalGeneric
                show={showError}
                handleClose={handleClose}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessage}
                handleButton={handleClose}
                textButton="Aceptar"
            />
        </>
    )
}

export default TableTemplateCertificates
