import { FC, useState, useEffect } from 'react'
import { ExpirationPayment } from './BlockCardTheft-Styled'
import { useSendOTP } from '../../hooks/useCreditDetail'
import { useTimer } from '../../../../hooks'
import { Button, ProgressBar } from '../../../../components'
import { calPercentage } from '../../../../utils/misc'
import ModalInfo from '../ModalInfo/ModalInfo'
import { useHistory } from 'react-router-dom'

interface TimerProps {
    tokenSave: any
}

const CountdownTimer: FC<TimerProps> = ({ tokenSave }): JSX.Element => {
    const startMinutes = 5
    const history = useHistory()

    const { minutes, seconds, startTimer, resetTimer } = useTimer(startMinutes)
    const [percentage, setPercentage] = useState<number>(90)
    const [showRetrySendOtp, setShowRetrySendOtp] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    const handleClose = () => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const refreshPercentage = (minsPerc: number, secsPerc: number): void => {
        setPercentage(calPercentage(startMinutes, minsPerc + secsPerc / 60))
    }

    const refreshTimer = (): void => {
        if (minutes === 4 && seconds === 0) {
            setShowRetrySendOtp(false)
        }
        refreshPercentage(minutes, seconds)
    }

    // listeners
    useEffect(() => {
        startTimer()
    })

    useEffect(() => {
        refreshTimer()
    }, [minutes, seconds])

    const ResetTime = () => {
        setIsLoading(true)
        useSendOTP(tokenSave).then((res: any) => {
            setIsLoading(false)
            resetTimer()
            setShowRetrySendOtp(true)
            if (res['status'] === 200) {
                console.log('otp enviado')
            } else if (res['Response'] === '401') {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(res['Message'])
            } else {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                )
            }
        })
    }

    return (
        <div>
            <ExpirationPayment>
                <ProgressBar variant="warning" now={percentage} />
                {percentage === 0 ? (
                    <p>La clave ha expirado, debes solicitar otra</p>
                ) : (
                    <p>
                        Tu código expirará en {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                )}
            </ExpirationPayment>

            {showRetrySendOtp ? (
                <p>Espera 1:00 minuto para solicitar una nueva clave dinámica</p>
            ) : (
                <Button
                    onClick={ResetTime}
                    className="link-dynamic-passw"
                    variant="link"
                    disabled={isLoading}
                >
                    {isLoading ? 'Solicitando clave dinámica...' : 'No recibí mi clave dinámica'}
                </Button>
            )}
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleClose}
            />
        </div>
    )
}

export default CountdownTimer
