import { Button } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const TextP = styled.p`
    ${tw`font-helvetica opacity-100`}
    color: #53595a;

    span {
        color: #f5a50b;
    }
`

export const TextInfo = styled.p`
    ${tw`font-helvetica pl-4 opacity-100`}
    color: #53595A;

    span {
        color: #f5a50b;
    }
`

export const UserDate = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900 pl-4`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }
`
export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-12 w-full rounded-[10px] shadow-[0px 3px 6px #00000029] mt-5 justify-center`}

    .second-content {
        ${tw`mt-10`}
    }

    .row-transaction {
        margin-top: 50px;
    }

    .download {
        ${tw`w-[75%] justify-center`}
    }
    .button-download {
        background: transparent;
        border: 0;
        color: #f5a50b;
        text-decoration-line: underline;
    }

    .row-pdf {
        justify-content: center;
    }
`

export const TextTitle = styled.h2`
    ${tw`font-montserrat font-bold text-dominant-dark text-center text-lg lg:text-xl mb-0`}

    .text-danger {
        ${tw`text-danger`}
    }
`

export const TextSimple = styled.p`
    ${tw`font-montserrat text-center text-base text-black`}
    font-size:15px;
`

export const TextSimpleInfo = styled.p`
    ${tw`font-montserrat text-left font-bold text-lg mb-0`}
`

export const TextSimpleDate = styled.p`
    ${tw`font-montserrat text-left text-black`}
    font-size:14px;
`

export const ContainerButtons = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: right;
    align-items: center;
    & button {
        ${tw`lg:!w-[25%]`}
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[5px] font-normal font-montserrat`}
    background-color: transparent !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 320px) and (max-width: 650px) {
        ${tw`w-[300px]`}
    }
`

export const ButtonCancel = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[5px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 320px) and (max-width: 650px) {
        ${tw`w-[300px]`}
    }
`
