import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp, Loading } from '../../components'
import { useSelector } from 'react-redux'
import { useGetRoles, useGetUsers } from './hooks/useAssignRoles'
import { SpaceStep } from './AssignRoles-styles'
import ModalError from './inc/ModalError'
import UpdateRolUser from './inc/UpdateRolUser/UpdateRolUser'
import TableRoles from './inc/TableRoles/TableRoles'
import ModalFormAssignRol from './inc/ModalFormAssignRol/ModalFormAssignRol'

interface Users {
    FullName: string
    DocNumber: string
    UserName: string
    RoleName: string
}

const AssignRoles = (): JSX.Element => {
    const data = [
        {
            Id: 0,
            RoleName: '',
            Part: '',
        },
    ]
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const history = useHistory()

    const [roles, setRoles] = useState<any>(data)
    const [loading, setLoading] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [rolSelect, setRolSelect] = useState<string>('')
    const [step, setStep] = useState<number>(1)

    const [userDocument, setUserDocument] = useState<string>('')
    const [idRol, setIdRol] = useState<number>(0)
    const [rolNameUser, setRolNameUser] = useState<string>('')
    const [showModalForm, setShowModalForm] = useState<boolean>(false)

    const [infoUserManagement, setInfoUserManagement] = useState<Users | null>(null)

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Administración',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Roles',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    const GetRoles = (): void => {
        setLoading(true)
        useGetRoles(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setRoles(res['data']['Data'])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const GetUsers = (rol: string): void => {
        setLoading(true)
        useGetUsers(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    const userList =
                        res['data']['Data'].filter((user: Users) => user.RoleName === rol) || []
                    setInfoUserManagement(userList)
                    setRolSelect(rol)
                    setLoading(false)
                } else {
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
                setLoading(false)
            })
    }

    useEffect(() => {
        GetRoles()
    }, [])

    const handleClose = (): void => {
        setShowError(false)
    }

    const handleCloseModalForm = (): void => {
        setShowModalForm(false)
    }

    const handleRolSelected = (rol: string): void => {
        GetUsers(rol)
        setRolSelect(rol)
        setStep(2)
    }

    const assignRolUser = (idRol: number, documentUser: string, rolName: string): void => {
        setUserDocument(documentUser)
        setIdRol(idRol)
        setRolNameUser(rolName)
        setShowModalForm(true)
    }

    const refreshListUser = (): void => {
        GetUsers(rolNameUser)
    }

    return (
        <LayoutContent>
            <SpaceStep />
            <BreadcrumbApp
                breadcrumbs={breadcrumbs}
                onBack={() => {
                    if (step === 2) {
                        setStep(1)
                    } else {
                        history.push('/home-wallet')
                    }
                }}
            />
            {step === 1 && (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        <TableRoles roles={roles} rolSelect={handleRolSelected} />
                    )}
                </>
            )}
            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
            {infoUserManagement != null && step === 2 && (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        <UpdateRolUser
                            usersData={infoUserManagement}
                            rolName={rolSelect}
                            userSelect={assignRolUser}
                        ></UpdateRolUser>
                    )}
                    <ModalFormAssignRol
                        documentUser={userDocument}
                        idRol={idRol}
                        roles={roles}
                        rolName={rolSelect}
                        showModal={showModalForm}
                        handleCloseModal={handleCloseModalForm}
                        handleRefreshUser={refreshListUser}
                    />
                </>
            )}
        </LayoutContent>
    )
}

export default AssignRoles
