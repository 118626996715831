import { useHistory } from 'react-router-dom'
import { InputSelectGroup } from '../../../../../../components'
import { TextInfo, TextSteps, Wrapper } from '../DynamicKeyContainer/DynamicKeyContainer-Styled'
import { useState } from 'react'

import {
    FormGroup,
    FormLabel,
    FormSelectTC,
    FormOption,
    Image,
    ButtonsContainer,
    CancelButton,
    ButtonContinue,
    CardContainer,
    ImgCardContent,
    Content,
} from './SelectCard-Styled'
import { MiniCardSVG } from '../../../../../../utils/getIcons'
import { Col, Row } from 'react-bootstrap'
import { CreditCard } from '../../../../../../../../domain/models/tc/detailTC'
import ImageCard from '../ImageCard/ImageCard'

interface SelectCardProps {
    stepNumber: string
    nextStepNumber: string
    onNextStep: (
        lastForDigit: string,
        numberCard: string,
        lastFourDigitsEncryptedCC: string
    ) => void
    activeCards: CreditCard[]
}

const SelectCard: React.FC<SelectCardProps> = ({
    stepNumber,
    onNextStep,
    nextStepNumber,
    activeCards,
}) => {
    const history = useHistory()
    const [isSelectTouched, setIsSelectTouched] = useState<boolean>(false)
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(true)
    const [lastsFourNumCard, setLastsFourNumCard] = useState<string>('')
    const [numberCard, setNumberCard] = useState<string>('')
    const [lastFourDigitsEncryptedCC, setLastFourDigitsEncryptedCC] = useState('')

    const cards: string[] = activeCards.map((card) => card.typeCard + ' - *' + card.lastFourDigits)

    const [selectedOption, setSelectedOption] = useState<string>('')
    const [selectedCardData, setSelectedCardData] = useState<{
        nameCard: string | undefined
        idProduct: string | undefined
        cardNumber: string | undefined
        dateExpiredCard: string | undefined
        typeCard: string | undefined
        lastFourDigits: string | undefined
        lastFourDigitsEncryptedCC: string | undefined
        lockType: string | undefined
        lockTypeCoopCentral: string | undefined
    } | null>(null)

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedType = event.target.value
        setSelectedOption(selectedType)
        setIsButtonEnabled(false)

        // Busca y guarda los datos de la tarjeta seleccionada
        const selectedCard = activeCards.find(
            (card) => card.typeCard + ' - *' + card.lastFourDigits === selectedType
        )
        setSelectedCardData(selectedCard || null)
        setLastsFourNumCard(selectedCard?.lastFourDigits ?? '')
        setNumberCard(selectedCard?.cardNumber ?? '')
        setLastFourDigitsEncryptedCC(selectedCard?.lastFourDigitsEncryptedCC ?? '')
    }

    const handleBlur = (event: React.FocusEvent<HTMLSelectElement>): void => {
        if (event.target.value === '' || event.target.value === null) {
            setIsSelectTouched(true)
        } else {
            setIsSelectTouched(false)
        }
    }

    const handleNext = (): void => {
        onNextStep(lastsFourNumCard, numberCard, lastFourDigitsEncryptedCC)
    }

    return (
        <Wrapper>
            <CardContainer>
                <Content>
                    <TextSteps>
                        Paso {stepNumber} de {nextStepNumber}
                    </TextSteps>
                    <TextInfo>
                        Selecciona la tarjeta en la cual necesitas restablecer la clave para avances
                    </TextInfo>
                    <FormGroup>
                        <FormLabel>Tarjeta</FormLabel>
                        <InputSelectGroup
                            style={{
                                borderColor: isSelectTouched && !selectedOption ? '#dc3545' : '',
                            }}
                        >
                            <Image src={MiniCardSVG} alt="clave" />
                            <FormSelectTC
                                value={selectedOption}
                                onChange={handleSelectChange}
                                onBlur={handleBlur}
                            >
                                <FormOption disabled value="">
                                    Selecciona una opción
                                </FormOption>
                                {cards.map((card: string, index: number) => (
                                    <FormOption key={index} value={card}>
                                        {card}
                                    </FormOption>
                                ))}
                            </FormSelectTC>
                        </InputSelectGroup>
                    </FormGroup>
                    {isSelectTouched && !selectedOption && (
                        <p style={{ color: '#dc3545' }}>Debes seleccionar una opción</p>
                    )}
                </Content>
                {selectedCardData && (
                    <Row>
                        <Col md={7} xs={12}>
                            <ImgCardContent>
                                <ImageCard
                                    lastFourDigits={selectedCardData.lastFourDigits}
                                    nameCard={selectedCardData.nameCard}
                                />
                            </ImgCardContent>
                        </Col>
                        <Col md={5} xs={12}>
                            <FormLabel>
                                Tipo de tarjeta: <span>{selectedCardData.typeCard}</span>{' '}
                            </FormLabel>
                            <FormLabel>
                                Número de tarjeta:
                                <span> *{selectedCardData.lastFourDigits}</span>
                            </FormLabel>
                        </Col>
                    </Row>
                )}
                <Content>
                    <ButtonsContainer>
                        <CancelButton onClick={() => history.push('/home-wallet')}>
                            Cancelar
                        </CancelButton>
                        <ButtonContinue disabled={isButtonEnabled} onClick={handleNext}>
                            Continuar
                        </ButtonContinue>
                    </ButtonsContainer>
                </Content>
            </CardContainer>
        </Wrapper>
    )
}

export default SelectCard
