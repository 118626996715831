import tw, { styled } from 'twin.macro'
import { Modal, Button } from 'react-bootstrap'

export const Wrapper = styled.section`
    ${tw`mb-[20px] py-[15px] px-0 min-h-[50vh] flex justify-center items-center py-2`}

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[80%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    .vertical-hr {
        height: 100%;
        width: 1px;
        background-color: #ccc;
        margin: 0 10px;
    }
`

export const PassContainer = styled.div`
    div#content-password {
        padding: 0 !important;
    }

    div#content-password-confirm {
        padding: 0;
    }
`

export const SpacerContent = styled.div`
    ${tw`mt-[10%]`}

    .hide-number-arrows::-webkit-inner-spin-button,
    .hide-number-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .hide-number-arrows {
        -moz-appearance: textfield;
    }

    @media (max-width: 500px) {
        ${tw`mt-[27%]`}
    }
`

export const PpalTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }
`
export const YellowTitle = styled.h2`
    ${tw`font-montserrat text-base my-[20px]`}

    color: #F5A50B;
`

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}

    span {
        color: #f5a50b;
    }
`

export const TextP1 = styled.p`
    ${tw`font-montserrat text-base`}
    margin-top:8px;
`

export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}
`
export const CodeText = styled.span`
    ${tw`font-montserrat font-bold text-base`}

    color: #512f8b;
`

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`

export const CreditModal = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }

        .modal-body {
            ${tw`flex flex-col items-center`}

            img {
                ${tw`m-2.5`}
            }

            h6 {
                ${tw`font-montserrat text-center text-base font-semibold color[var(--header-botton-color)]`}
            }

            p {
                ${tw`my-2.5 mx-0 text-center text-sm font-helvetica font-normal color[var(--header-botton-color)]`}
            }
        }
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 30px;
`

export const ArrowBlack = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    @media (max-width: 500px) {
        ${tw`w-40`}
        & p {
            ${tw`text-sm`}
        }
    }

    @media (min-width: 1024px) {
        ${tw`w-12`}
    }
`

export const ArrowYellow = styled.a`
    display: flex;
    gap: 12px;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        transform: rotate(-90deg);
    }

    span {
        color: #f2a510;
        font-weight: bold;
    }
`

export const AlignContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`
