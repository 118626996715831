import { useEffect, useState } from 'react'

// components
import { LayoutContent } from '../../transverse'

// context
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../components'
import { ContainerSimulator, ContainerSimulator1 } from './CertificateProduct-styles'
import TitleModule from './inc/TitleModule/index'
import Certificate from './inc/Certificate'
import PDFGenerator from './inc/PDFGenerator'
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer'
import { CreditCardSelector } from '../../../selectors'
import useQueryId from '../../hooks/useQueryId'
import { StoreApp } from '../../../redux/store/store.interface'
import { DownloadSVG, PrintSVG } from '../../utils/getIcons'
import BreadcrumbCertificate from './inc/BreadcrumbCertificate'
import { portfolioPurchaseAction } from '../../../redux/portfolioPurchaseTC'
import { LoadingContainer } from '../CreditDetail/creditDetail-styles'
import { useMediaQuery } from 'usehooks-ts'
import PDFViewerMobile from './inc/PDFViewerMobile'

const CertificateProduct = (): JSX.Element => {
    const { id } = useQueryId()
    const dispatch = useDispatch()
    const { cardData: infoCard } = useSelector((store: StoreApp) =>
        CreditCardSelector(store, String(id))
    )

    const matchMedia = useMediaQuery('(min-width: 700px)')
    useEffect(() => {
        dispatch(portfolioPurchaseAction())
    }, [])
    const [step, setStep] = useState<number>(1)
    const [showPDF, setShowPDF] = useState(false) // Agregar estado para controlar la visualización del PDF
    const [body, setBody] = useState('')
    const [header, setHeader] = useState('')
    const [footer, setFooter] = useState('')
    const [valediction, setValediction] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [dateExpiredCard, setDateExpiredCard] = useState('')
    const [totalLimit, setTotalLimit] = useState('')
    const [quotaAvailable, setQuotaAvailable] = useState('')

    const handleData = (
        body: string,
        header: string,
        footer: string,
        valediction: string,
        cardNumber: string,
        dateExpiredCard: string,
        totalLimit: string,
        quotaAvailable: string,
        showPDF: boolean
    ) => {
        setBody(body)
        setHeader(header)
        setFooter(footer)
        setValediction(valediction)
        setCardNumber(cardNumber)
        setDateExpiredCard(dateExpiredCard)
        setTotalLimit(totalLimit)
        setQuotaAvailable(quotaAvailable)
        setStep(2)
        setShowPDF(showPDF)
    }

    const handlePrintPDF = async () => {
        const pdfBlob = await pdf(
            <PDFGenerator
                Body={body}
                Header={header}
                Footer={footer}
                Valediction={valediction}
                CardNumber={cardNumber}
                DateExpiredCard={dateExpiredCard}
                TotalLimit={totalLimit}
                QuotaAvailable={quotaAvailable}
                pageWidth={pageWidth}
                pageHeight={pageHeight}
            />
        ).toBlob()
        const url = URL.createObjectURL(pdfBlob)

        const printWindow = window.open(url)

        // Esperar a que la ventana de impresión esté cargada y luego imprimir
        printWindow?.addEventListener('load', () => {
            printWindow.document.title = 'Certificado Producto'
            printWindow?.print()
        })
    }
    // Calcular el tamaño de la página basado en el contenido
    const contentWidth = 750
    const contentHeight = 800
    const pageWidth = contentWidth - 50
    const pageHeight = contentHeight - 50

    return (
        <LayoutContent>
            <ContainerSimulator>
                <br />
                <BreadcrumbCertificate
                    lastFourDigits={infoCard?.lastFourDigits}
                ></BreadcrumbCertificate>
                <TitleModule />
                {step === 1 && (
                    <Certificate
                        onNextStep={handleData}
                        cardNumber={infoCard?.cardNumber}
                    ></Certificate>
                )}

                {/* Utiliza la variable matchMedia para condicionar el renderizado */}
                {showPDF && (
                    <>
                        <ContainerSimulator1>
                            {/* Toolbar */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginBottom: '5px',
                                    height: '40px',
                                    background: '#F2F2F2',
                                    borderRadius: '5px',
                                }}
                            >
                                <PDFDownloadLink
                                    document={
                                        <PDFGenerator
                                            Body={body}
                                            Header={header}
                                            Footer={footer}
                                            Valediction={valediction}
                                            CardNumber={cardNumber}
                                            DateExpiredCard={dateExpiredCard}
                                            TotalLimit={totalLimit}
                                            QuotaAvailable={quotaAvailable}
                                            pageWidth={pageWidth}
                                            pageHeight={pageHeight}
                                        />
                                    }
                                    fileName="CertificadoProducto.pdf"
                                >
                                    <img
                                        src={DownloadSVG}
                                        className="options-img"
                                        style={{
                                            width: '26px',
                                            height: '20px',
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginRight: '10px',
                                            fontFamily: 'Montserrat',
                                            background: 'transparent',
                                            border: 'none',
                                            color: '#512F8B',
                                            margin: '8px',
                                        }}
                                    >
                                        Descargar
                                    </button>
                                </PDFDownloadLink>

                                {/* Condición para renderizar el botón de imprimir solo cuando matchMedia es verdadero */}
                                {matchMedia && (
                                    <>
                                        <img
                                            src={PrintSVG}
                                            className="options-img"
                                            style={{ width: '26px', height: '21px' }}
                                        />
                                        <button
                                            onClick={handlePrintPDF}
                                            style={{
                                                fontFamily: 'Montserrat-Regular',
                                                background: 'transparent',
                                                border: 'none',
                                                color: '#512F8B',
                                                margin: '9px',
                                            }}
                                        >
                                            Imprimir
                                        </button>
                                    </>
                                )}
                            </div>

                            {/* Condición para renderizar PDFViewerMobile si matchMedia es verdadero */}
                            {step === 2 && !matchMedia ? (
                                <PDFViewerMobile
                                    Body={body}
                                    Header={header}
                                    Footer={footer}
                                    Valediction={valediction}
                                    CardNumber={cardNumber}
                                    DateExpiredCard={dateExpiredCard}
                                    TotalLimit={totalLimit}
                                    QuotaAvailable={quotaAvailable}
                                    pageWidth={pageWidth}
                                    pageHeight={pageHeight}
                                />
                            ) : (
                                // Renderiza PDFGenerator si matchMedia es falso o step no es 2
                                <>
                                    {body == '' ? (
                                        <LoadingContainer>
                                            <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                                        </LoadingContainer>
                                    ) : (
                                        <PDFViewer
                                            width={pageWidth - 29}
                                            height={pageHeight - 29}
                                            showToolbar={false}
                                            style={{ marginLeft: '250px' }}
                                        >
                                            <PDFGenerator
                                                Body={body}
                                                Header={header}
                                                Footer={footer}
                                                Valediction={valediction}
                                                CardNumber={cardNumber}
                                                DateExpiredCard={dateExpiredCard}
                                                TotalLimit={totalLimit}
                                                QuotaAvailable={quotaAvailable}
                                                pageWidth={pageWidth}
                                                pageHeight={pageHeight}
                                            />
                                        </PDFViewer>
                                    )}
                                </>
                            )}
                        </ContainerSimulator1>
                    </>
                )}
            </ContainerSimulator>
        </LayoutContent>
    )
}

export default CertificateProduct
