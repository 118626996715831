// services
import { productsServices } from '../../../domain/services'

// models & interfaces
import { AppDispatch } from '../store/store'
import {
    BenefitsClientResponse,
    BenefitsClientType,
    CampaignClientResponse,
    CampaignClientType,
    Detail,
    DetailTypes,
    MinimumPaymentObject,
    MinimumPaymentTypes,
    RatesTypes,
    RatesTypesObject,
    RedirectedObject,
    RequestMinumimPayment,
    RequestRedirected,
    RequestRedirectedTypes,
    SeveranceQuoteClientResponse,
    SeveranceQuoteClientType,
} from '../../../domain/models'

// redux types
import {
    CARDS_CONSOLIDATED,
    CREDITS_CONSOLIDATED,
    CREDITS_AVAILABLE,
    SAVING_AVAILABLE,
    DETAIL,
    FAI_ACCOUNT_BALANCE,
    MOVEMENTS,
    MOVEMENTS_DETAIL,
} from './products.types'

export const getCardsConsolidatedAction = () => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getCardsConsolidated([
            CARDS_CONSOLIDATED.REQUEST,
            CARDS_CONSOLIDATED.SUCCESS,
            CARDS_CONSOLIDATED.FAILURE,
        ])()
    )
}

export const getCreditsConsolidatedAction = () => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getCreditsConsolidated([
            CREDITS_CONSOLIDATED.REQUEST,
            CREDITS_CONSOLIDATED.SUCCESS,
            CREDITS_CONSOLIDATED.FAILURE,
        ])()
    )
}

export const getCreditsAvailableAction = () => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getCreditsAvailable([
            CREDITS_AVAILABLE.REQUEST,
            CREDITS_AVAILABLE.SUCCESS,
            CREDITS_AVAILABLE.FAILURE,
        ])()
    )
}

export const getSavingAvailableAction = () => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getSavingAvailable([
            SAVING_AVAILABLE.REQUEST,
            SAVING_AVAILABLE.SUCCESS,
            SAVING_AVAILABLE.FAILURE,
        ])()
    )
}

export const getDetailAction =
    (
        data: DetailTypes,
        onSuccess?: (response: {
            data: Detail[]
            message: string | null
            response: string | null
        }) => void,
        onError?: (e: unknown) => void
    ) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getDetail([DETAIL.REQUEST, DETAIL.SUCCESS, DETAIL.FAILURE])(data)
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        data: Detail[]
                        message: string | null
                        response: string | null
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getFaiAccountBalanceAction = () => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getFaiAccountBalance([
            FAI_ACCOUNT_BALANCE.REQUEST,
            FAI_ACCOUNT_BALANCE.SUCCESS,
            FAI_ACCOUNT_BALANCE.FAILURE,
        ])()
    )
}

export const getMovementsAction = (data: DetailTypes) => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getMovements([MOVEMENTS.REQUEST, MOVEMENTS.SUCCESS, MOVEMENTS.FAILURE])(
            data
        )
    )
}

export const getMovementsDetailAction = (data: DetailTypes) => (dispatch: AppDispatch) => {
    dispatch(
        productsServices.getMovementDetail([
            MOVEMENTS_DETAIL.REQUEST,
            MOVEMENTS_DETAIL.SUCCESS,
            MOVEMENTS_DETAIL.FAILURE,
        ])(data)
    )
}

export const getRates =
    (onSuccess?: (res: RatesTypes) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getRates([DETAIL.REQUEST, DETAIL.SUCCESS, DETAIL.FAILURE])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        stateCode: string | null
                        message: string | null
                        objectValue: RatesTypesObject[]
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const minimumPayment =
    (
        data: RequestMinumimPayment,
        onSuccess?: (res: MinimumPaymentTypes) => void,
        onError?: (e: unknown) => void
    ) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.minimumPayment([DETAIL.REQUEST, DETAIL.SUCCESS, DETAIL.FAILURE])(
                    data
                )
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        stateCode: string | null
                        message: string | null
                        objectValue: MinimumPaymentObject
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getMortgageClient =
    (onSuccess?: (res: any) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getMortgageClient([
                    DETAIL.REQUEST,
                    DETAIL.SUCCESS,
                    DETAIL.FAILURE,
                ])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        isSuccess: string | null
                        response: string | null
                        message: string | null
                        data: any
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const createReditected =
    (
        data: RequestRedirected,
        onSuccess?: (res: RequestRedirectedTypes) => void,
        onError?: (e: unknown) => void
    ) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.createRedirected([DETAIL.REQUEST, DETAIL.SUCCESS, DETAIL.FAILURE])(
                    data
                )
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        stateCode: string | null
                        message: string | null
                        objectValue: RedirectedObject
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getCampaignClientProduct =
    (onSuccess?: (res: CampaignClientType) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getCampaignClientProduct([
                    DETAIL.REQUEST,
                    DETAIL.SUCCESS,
                    DETAIL.FAILURE,
                ])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        isSuccess: string | null
                        response: string | null
                        message: string | null
                        data: CampaignClientResponse
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getBenefitsClient =
    (onSuccess?: (res: BenefitsClientType) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getBenefitsClient([
                    DETAIL.REQUEST,
                    DETAIL.SUCCESS,
                    DETAIL.FAILURE,
                ])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        isSuccess: string | null
                        response: string | null
                        message: string | null
                        data: BenefitsClientResponse[]
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getSeveranceQuoteClient =
    (onSuccess?: (res: SeveranceQuoteClientType) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getSeveranceQuoteClient([
                    DETAIL.REQUEST,
                    DETAIL.SUCCESS,
                    DETAIL.FAILURE,
                ])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        isSuccess: string | null
                        response: string | null
                        message: string | null
                        data: SeveranceQuoteClientResponse
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }

export const getReportACH =
    (onSuccess?: (res: any) => void, onError?: (e: unknown) => void) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                productsServices.getReportACH([DETAIL.REQUEST, DETAIL.SUCCESS, DETAIL.FAILURE])()
            )

            if (onSuccess)
                onSuccess(
                    response as {
                        isSuccess: string | null
                        response: string | null
                        message: string | null
                        data: any
                    }
                )
        } catch (error) {
            if (onError) onError(error)
        }
    }
