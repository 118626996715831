import React from 'react'
import {
    ButtonContinue,
    ButtonsContainer,
    CancelButton,
    Content,
    TextData,
    TextSteps,
    TextSubtitle2,
    Title,
    Wrapper,
    WrapperTransaction,
} from '../../AdvanceToFAIAccount-Styled'
import { Col, Row } from 'react-bootstrap'
import { CheckTransactionSVG } from '../../../../utils/getIcons'
import './TransactionConfirmation.css'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

interface TransactionConfirmationProps {
    amountAdvance: string
    lastsFourNumCard: string
    faiAccountNumber: string
}

const TransactionConfirmation: React.FC<TransactionConfirmationProps> = ({
    amountAdvance,
    lastsFourNumCard,
    faiAccountNumber,
}) => {
    const history = useHistory()

    const print = (): void => {
        const date = moment().format('YYYY-MM-DD HH:mm:ss')
        const originalTitle = document.title
        document.title = 'Comprobante_Avance_cuenta_FAI' + date
        window.print()

        document.title = originalTitle
    }

    const finish = (): void => {
        history.push('/home-wallet')
    }

    const value = parseFloat(amountAdvance)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    return (
        <div id="section-print">
            <Wrapper className="printable">
                <Content className="printable">
                    <WrapperTransaction className="printable print-w">
                        <img
                            className="image"
                            style={{ marginRight: '10px' }}
                            src={CheckTransactionSVG}
                            alt=""
                        />
                        <Title className="printable">
                            Tu avance <span>ha sido exitoso</span>
                        </Title>
                    </WrapperTransaction>
                </Content>
            </Wrapper>
            <Wrapper className="printable wrapper">
                <Content className="printable">
                    <TextSteps className="printable">Resumen de la transacción</TextSteps>

                    <Row className="printable" style={{ paddingTop: '30px' }}>
                        <Col md={6} xs={12} className="printable">
                            <TextSubtitle2 className="printable">Tarjeta de Crédito</TextSubtitle2>
                            <TextData className="printable">
                                <strong>*{lastsFourNumCard}</strong>
                            </TextData>
                        </Col>
                        <Col md={6} xs={12} className="printable">
                            <TextSubtitle2 className="printable">Monto del Avance</TextSubtitle2>
                            <TextData className="printable">
                                <strong>{formatter.format(value)}</strong>
                            </TextData>
                        </Col>
                    </Row>
                    <Row className="printable">
                        <Col md={12} xs={12} className="printable">
                            <TextSubtitle2 className="printable">Cuenta destino</TextSubtitle2>
                            <TextData>
                                <strong>{faiAccountNumber}</strong>
                            </TextData>
                        </Col>
                    </Row>

                    <ButtonsContainer className="print-w">
                        <CancelButton onClick={finish}>Finalizar</CancelButton>
                        <ButtonContinue onClick={print}>Imprimir</ButtonContinue>
                    </ButtonsContainer>
                </Content>
            </Wrapper>
        </div>
    )
}

export default TransactionConfirmation
