import tw, { styled } from 'twin.macro'

export const InputContainer = styled.div`
    ${tw`w-72 h-24`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`
export const SectionKeyboard = styled.div`
    ${tw`w-56`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`
export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
    @media (max-width: 460px) {
        ${tw`my-auto mx-0 w-[270px] h-[50px] rounded-[5px] font-normal font-montserrat`}
    }
`

export const MessageError = styled.div`
    p {
        color: #dc3545 !important;
        font-size: 0.875em;
        padding: 0;
        margin: 0;
    }
`
