import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormState } from 'react-hook-form'

// components
import { Form, InputSelectGroup } from '../../../../components'
import {
    BankCard,
    HeadStep,
    ControlsButtons,
    DeleteBankModal,
} from '../../../OpeningCreditCard/inc'
import AlertBuy from '../AlertBuy'
import NavigationStep from '../NavigationStep'

// styles
import {
    FirstStepWrapper,
    FirstStepContent,
    AddCreditCardBtn,
    ImageAdd,
    FieldCtr,
    BankCardList,
    InputGroupCheck,
    FormCheck,
} from './fifteenthStep-styles'
import {
    TitleStep,
    FormGroup,
    FormLabel,
    ParagraphStep,
    Image,
    FormSelect,
    FormOption,
    FormMessageSelect,
    SpaceStep,
} from '../../campaignCard-styles'

// icons
import { MiniAddPlusSVG, ExclusionSVG } from '../../../../utils/getIcons'

// actions
import { getAllBanksAction } from '../../../../../redux/tc'
import {
    deleteBuyWalletByIdAction,
    getAllBuyWalletAction,
    getCreditCardFeesAction,
    getQuotesFeesSavedAction,
    getCleanPortfolioPurchaseAction,
} from '../../../../../redux/portfolioPurchaseTC'

// hooks
import {
    useFirstStepForm,
    firstStepSchema,
    FirstStepType,
    useGetCampaignClient,
} from '../../hooks/useFifteenthStepForm'
import { useReducerStep } from '../../hooks'

// selectors
import {
    getAllBuyWalletSelector,
    getCreditCardFeesSelector,
    deleteBuyWalletByIdSelector,
    currentQuotesFeesSaveSelector,
} from '../../../../../selectors'

// models
import { BuyWallet } from '../../../../../../domain/models'
import EditConfirmationModal from '../EditConfirmationModal'
import ModalLoading from '../../../ActivateInternationalCard/inc/ModalInfo/ModalLoading'

const FifteenthStep = (): ReactElement => {
    const dispatch = useDispatch()
    const history = useHistory()
    //const state = useLocation().state as { lastFrom: string; quotaAvailable: number }
    const [{ currentStep, firstStep }, dispatchStep] = useReducerStep()
    const [isLoading, setIsLoading] = useState(false)

    const [quotaAvailable, setQuotaAvailable] = useState(0)
    const QUOTE_AVAILABLE = quotaAvailable
    console.log(QUOTE_AVAILABLE, 'monto disponible')
    //const QUOTE_AVAILABLE = state?.quotaAvailable ? state?.quotaAvailable : 0

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [bankSelect, setBankSelect] = useState<BuyWallet | null>(null)

    // selectors
    const { data: quoteNumberSelect } = useSelector(getCreditCardFeesSelector)
    const { data: quoteFeesSaved } = useSelector(currentQuotesFeesSaveSelector)
    const { buyWallet, count } = useSelector(getAllBuyWalletSelector)
    const { buyWalletDeleted } = useSelector(deleteBuyWalletByIdSelector)

    // definitions
    const defaultValuesRef = useRef({
        buyWallet: firstStep.buyWallet ?? 'not',
        amountOfFees: firstStep.amountOfFees ?? '',
    })

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        preparedDataToSend,
        preparedDataQuotes,
        preparedToSave,
        preparedToEditBuyWallet,
        isSavingLoading,
    } = useFirstStepForm({
        defaultValues: defaultValuesRef.current,
        validationSchema: yupResolver(firstStepSchema),
    })
    const { isValid, errors } = useFormState({ control })
    watch()

    // listeners
    useEffect(() => {
        dispatch(getQuotesFeesSavedAction())
        dispatch(getCreditCardFeesAction())
        GetCampaignClient()
    }, [])

    const GetCampaignClient = (): void => {
        setIsLoading(true)
        useGetCampaignClient(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    setIsLoading(false)
                    setQuotaAvailable(res.data.Data.AssignedFee)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (quoteFeesSaved !== null) {
            setValue(
                'amountOfFees',
                quoteNumberSelect
                    ?.find((quote) => quote.id === quoteFeesSaved.installmentId)
                    ?.installmentsCount.toString() ?? '',
                { shouldValidate: true }
            )
        }
    }, [quoteFeesSaved, quoteNumberSelect, setValue])

    useEffect(() => {
        dispatch(getAllBanksAction())
    }, [currentStep])

    useEffect(() => {
        dispatch(getAllBuyWalletAction())
    }, [buyWalletDeleted])

    // handlers
    const onSubmit = (dataToSend: FirstStepType): void => {
        preparedDataToSend(dataToSend)
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        preparedDataQuotes(e.target.value)
    }

    const onSave = (): void => {
        preparedToSave()
    }

    const onCancel = (): void => {
        dispatch(getCleanPortfolioPurchaseAction())
        history.push('/home-wallet')
    }

    const onAddCreditCard = (): void => {
        if (QUOTE_AVAILABLE - count > 1) {
            dispatchStep({
                type: 'GO_TO_STEP',
                payload: {
                    step: 9,
                },
            })
        }
    }

    const onConfirmationFinish = (): void => {
        if (QUOTE_AVAILABLE - count > 1) {
            dispatchStep({
                type: 'GO_TO_STEP',
                payload: {
                    step: 10,
                },
            })
        }
    }

    const onDeleteBuyWallet = (bank: BuyWallet): void => {
        setShowDeleteModal(true)
        setBankSelect(bank)
    }

    const onEditBuyWallet = (bank: BuyWallet): void => {
        //preparedToEditBuyWallet(bank)
        setShowEditModal(true)
        setBankSelect(bank)
    }

    const isValidControl = (): boolean => {
        if (getValues('buyWallet') === 'not') {
            dispatchStep({
                type: 'GO_TO_STEP',
                payload: {
                    step: 10,
                },
            })
            return isValid
        }
        if (buyWallet) {
            return !(isValid && buyWallet.length > 0) ? true : false
        }
        return false
    }

    return (
        <>
            <NavigationStep saveAndExit={true} onSave={onCancel} />
            <FirstStepWrapper>
                <FirstStepContent>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <HeadStep
                            title="Solicitud"
                            paragraph="¿Desea comprar cartera? puedes agregar una o más tarjetas de crédito para realizar la compra de cartera."
                        />
                        <FormGroup>
                            <InputGroupCheck>
                                <FormCheck
                                    label="Si"
                                    value="yes"
                                    type="radio"
                                    id="buyWallet-yes"
                                    {...register('buyWallet')}
                                />
                                <FormCheck
                                    label="No"
                                    value="not"
                                    type="radio"
                                    id="buyWallet-not"
                                    {...register('buyWallet')}
                                />
                            </InputGroupCheck>
                        </FormGroup>
                        <SpaceStep />
                        {buyWallet && buyWallet.length > 0 && getValues('buyWallet') === 'yes' && (
                            <BankCardList>
                                {buyWallet.map((bank) => (
                                    <BankCard
                                        key={bank.id}
                                        bank={bank}
                                        onDelete={onDeleteBuyWallet}
                                        onEdit={onEditBuyWallet}
                                    />
                                ))}
                            </BankCardList>
                        )}
                        {getValues('buyWallet') === 'yes' && (
                            <AddCreditCardBtn
                                active={QUOTE_AVAILABLE - count > 1}
                                onClick={onAddCreditCard}
                            >
                                <ImageAdd src={MiniAddPlusSVG} alt="Logo" />
                                <TitleStep>Agregar tarjeta</TitleStep>
                            </AddCreditCardBtn>
                        )}
                        {QUOTE_AVAILABLE - count < 1 && <AlertBuy />}

                        {buyWallet && buyWallet.length > 0 && getValues('buyWallet') === 'yes' && (
                            <>
                                {QUOTE_AVAILABLE - count < 1 && <AlertBuy />}
                                <ParagraphStep>
                                    Tu compra de cartera será definida como una sola compra,
                                    especifica la cantidad de cuotas a la que deseas diferir tu
                                    compra de cartera.
                                </ParagraphStep>

                                <FieldCtr>
                                    <FormGroup $heightSize="105px">
                                        <FormLabel>Cantidad de cuotas</FormLabel>
                                        <InputSelectGroup $isError={!!errors.amountOfFees} $haveImg>
                                            <Image src={ExclusionSVG} alt="clave" />
                                            <FormSelect
                                                size="lg"
                                                {...register('amountOfFees')}
                                                onChange={onChange}
                                            >
                                                <FormOption disabled value="" show>
                                                    Selecciona una opción
                                                </FormOption>
                                                {quoteNumberSelect?.map((quote) => (
                                                    <FormOption key={quote.id} value={quote.id}>
                                                        {quote.installmentsCount}
                                                    </FormOption>
                                                ))}
                                            </FormSelect>
                                        </InputSelectGroup>
                                        <FormMessageSelect>
                                            {errors.amountOfFees && errors.amountOfFees.message}
                                        </FormMessageSelect>
                                    </FormGroup>
                                </FieldCtr>
                            </>
                        )}
                        <ControlsButtons
                            disable={isValidControl()}
                            isLoadingSave={isSavingLoading}
                            onSave={onSave}
                            onNext={onConfirmationFinish}
                            onCancel={onCancel}
                        />
                    </Form>
                </FirstStepContent>
                <ModalLoading isLoading={isLoading} />
            </FirstStepWrapper>
            {bankSelect && (
                <>
                    <DeleteBankModal
                        show={showDeleteModal}
                        onClose={() => {
                            setShowDeleteModal(false)
                        }}
                        onDelete={() => {
                            if (bankSelect) {
                                dispatch(deleteBuyWalletByIdAction(bankSelect.id))
                                setShowDeleteModal(false)
                            }
                        }}
                        bank={bankSelect}
                    />
                    <EditConfirmationModal
                        show={showEditModal}
                        onClose={() => {
                            setShowEditModal(false)
                        }}
                        onEdit={() => {
                            if (bankSelect) {
                                dispatch(preparedToEditBuyWallet(bankSelect))
                                setShowEditModal(false)
                            }
                        }}
                        bank={bankSelect}
                    />
                </>
            )}
        </>
    )
}

export default FifteenthStep
