import { Button } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}
    margin-bottom: 50px;
    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`
export const Paragraph = styled.h6`
    ${tw`m-0 font-montserrat  text-black`}
    margin-bottom: 20px;
    font-size: 18px;

    span {
        ${tw`m-0 font-montserrat font-bold text-black`}
        font-size:20px;
        margin-right: 5px;
    }
`
export const OptionsContainerDetail = styled.div`
    ${tw`rounded-[10px] h-[100%] w-[100%]`}
    span {
        color: #f5a50b;
    }

    .text {
        ${tw`font-montserrat font-bold lg:text-base text-[20px] color[var(--dominant-color-dark)]`}
    }
    table {
        margin: 0 auto;
        width: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
        justify-content: center;
    }

    .text1 {
        ${tw`font-montserrat font-bold`}
        text-align: right;
    }
    .text2 {
        ${tw`font-montserrat font-bold `}
        text-align:center;
    }
    th,
    td {
        padding: 20px;
        height: 60px;
        width: 22px;
        border: 1px solid black;
    }
    /* Estilos normales */
    .print-hidden {
        display: flex;
    }

    /* Estilos para impresión */
    @media print {
        .print-hidden {
            display: none !important;
        }
    }

    @media (max-width: 460px) {
        table {
            margin: 0 auto;
            width: 100%;
            justify-content: center;
        }
        .text {
            font-size: 1rem;
            color: var(--dominant-color-dark);
            border-right-color: black;
            border-left-color: white;
        }
        td {
            padding: 5px;
            height: 20px;
            border: 1px solid rgba(128, 128, 128, 0.3);
            width: 2%;
        }
        .text1 {
            border-right-color: white;
        }
    }
`
export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: center;
`

export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[300px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
`
export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;
    justify-content: center;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (max-width: 460px) {
        ${tw`my-auto mx-0 w-[300px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    }
`
