import { useEffect, useMemo, useState } from 'react'

const useFilter = (filters: any, jsonlist: any) => {
    const jsondata = useMemo(() => [...jsonlist], [jsonlist])
    const [tableData, setTableData] = useState(jsonlist)
    const filterKeys = Object.keys(filters)

    useEffect(() => {
        /* console.log(JSON.stringify(jsondata))
        console.log(filterKeys)
        console.log('filtros', filters) */
        if (filterKeys.length === 0) {
            setTableData(jsondata)

            return
        }
        const normalizeFilters = filterKeys.reduce((acc: any, key: string) => {
            acc[key] = (filters[key] ?? '').toString().toLowerCase()
            return acc
        }, {})
        console.log('normalizeFilters' + normalizeFilters)

        const result = jsondata.filter((item) => {
            for (const key of filterKeys) {
                if (!item[key].toString().toLowerCase().includes(normalizeFilters[key])) {
                    return false
                }
            }
            return true
        })
        console.log(result)
        setTableData(result)
    }, [filters])
    return { tableData }
}

export default useFilter
