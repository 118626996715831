import { Button, Form, Modal } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const SectionTitle = styled.div`
    ${tw`my-8`}
`

export const TitlePage = styled.h1`
    ${tw`font-montserrat font-bold text-dominant-dark sm:text-2xl text-[1.3em]`}

    span {
        ${tw`pl-1 text-dominant-dark`}
        margin: 5px;
    }
`

export const SpaceHr = styled.div`
    ${tw`w-full h-20 mt-10`}
`

interface FormOptionsProps {
    show?: boolean
}

export const FormOptions = styled.option<FormOptionsProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`

export const Label = styled(Form.Label)`
    ${tw`font-montserrat font-semibold text-[14px] mb-[0.7rem] mt-2.5`}
    color: #F5A50B;
`

export const DivContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center justify-end p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    .start-content {
        ${tw`justify-start `}
    }
`

export const ButtonCanceled = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ContainerBtn = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat lg:ml-6`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const LinkSearchUser = styled(Button)`
    ${tw`my-2.5 mx-0 h-[50px] rounded-[10px] font-montserrat lg:mr-6 font-semibold`}
    background-color: transparent !important;
    border-color: transparent !important;
    color: #f5a50b !important;
    font-family: var(--font-main-regular);
    font-size: 14px;
    text-decoration: underline !important;

    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const DivContainerStart = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center justify-start p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}
`

export const ContentForm = styled.div`
    ${tw`w-[80%] justify-center pb-40 pt-12`}
    margin: 0 auto;
`

export const ModalResult = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        @media (min-width: 1024px) {
            min-width: 650px;
        }

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }
        .modal-title {
            ${tw`flex flex-col items-start pt-2.5 pl-8 pb-0`}
        }
        .modal-body {
            ${tw`flex flex-col pt-2.5 pl-8 pb-4`}
        }
    }
`

export const ButtonFinish = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;
`
