import tw, { styled } from 'twin.macro'

export const OptionsContainerDetail = styled.table`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] w-full flex`}
    span {
        color: #f5a50b;
    }

    .text {
        ${tw`font-montserrat font-bold lg:text-base text-[20px] color[var(--dominant-color-dark)]`}
        text-align:center;
    }
    table {
        margin: 0 auto;
        width: 900px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .text1 {
        ${tw`font-montserrat font-bold`}
    }
    .text5 {
        ${tw`font-montserrat font-bold`}
    }
    .text2 {
        ${tw`font-montserrat font-bold color[var(--dominant-color-dark)]`}
        text-align:center;
    }
    th,
    td {
        padding: 20px;
        height: 60px;
        border: 1px solid black;
    }

    /* Estilos para dispositivos móviles (ancho de 352px a 550px) */
    @media (max-width: 460px) {
        table {
            margin: 0 auto;
            width: 100%;
            justify-content: center;
        }
        .text {
            font-size: 13px;
            color: var(--dominant-color-dark);
            border-right-color: black;
            border-left-color: white;
        }
        td {
            padding: 5px;
            height: 10px;
            border: 1px solid rgba(128, 128, 128, 0.1);
            width: 35%;
        }
        .text1 {
            border-right-color: black;
            font-size: 12px;
            text-align: center;
            font-weight: 100;
        }
        .text2 {
            color: black;
            font-weight: 100;
            font-family: 'Montserrat';
            border-right-color: black;
            font-size: 13px;
            text-align: center;
            text-decoration: underline;
        }
        .text5 {
            border-right-color: black;
            font-size: 12px;
            text-align: left;
            font-weight: 100;
        }
    }
`

export const ContainerPossessions = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
