// components
import { Button } from '../../../../components'
import {
    FirstStepContent,
    FirstStepWrapper,
} from '../../../CampaignCard/inc/FirstStep/firstStep-styles'
import {
    ButtonContent,
    ButtonsContainer,
    CancelButton,
    OptionsContainerDetail,
} from './Table-styles'

interface DynamicKeyContainerProps {
    selectedPagoIntereses: string
    plazoValue: string
    cuotaValue: string
    RateEM: string
    TotalSaving: string
    Retention: string
    goBackStep: () => void
}

const TableTerm: React.FC<DynamicKeyContainerProps> = ({
    selectedPagoIntereses,
    plazoValue,
    cuotaValue,
    RateEM,
    TotalSaving,
    Retention,
    goBackStep,
}) => {
    if (selectedPagoIntereses === '12') {
        selectedPagoIntereses = 'Mensual'
    } else {
        selectedPagoIntereses = 'Quincenal'
    }

    const printTable = (): void => {
        console.log('entro')
        const fileName = 'CavPrint'
        document.title = fileName

        // 1. Crear un nuevo elemento iframe
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'

        // 2. Agregar estilos generales
        const styles = `
            @media print {
                body {
                    font-size: 12pt;
                }
                .hide-on-print {
                    display: none;
                }
                .printed-container {
                    padding: 5.5px;
                    border-radius: 10px;
                    box-shadow: 0px 3px 6px var(--shadow-color);
                    width: 100%;
                    justify-content: center;
                }
                .printed-container .text {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 20px;
                    color: #f5a50b;
                    text-align: center;
                }
                .printed-container .text3 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 20px;
                    color: #0a0a0a;
                    text-align: center;
                }
                .printed-container table {
                    margin: 0 auto;
                    width: 400px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    justify-content: center;
                }
                .printed-container .text1 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    text-align: right;
                }
                .printed-container .text2 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 14px;
                }
                .printed-container th, .printed-container td {
                    padding: 20px;
                    height: 60px;
                    width: 22px;
                    border: 1px solid black;
                }
                .printed-container .span {
                    color: #f5a50b;
                }
            }
        `

        const styleElement = document.createElement('style')
        styleElement.textContent = styles

        // 3. Imprimir el contenido de la tabla
        const originalTable = document.getElementById('table-container')
        if (originalTable) {
            // Ocultar botones en la versión impresa
            const buttonsContainer = document.querySelector('.hide-on-print') as HTMLElement | null

            if (buttonsContainer) {
                buttonsContainer.style.display = 'none'
            }

            // Imprimir el título y el contenido de la tabla
            const content = `
            <head>
                    <title>${fileName}</title>
                </head>
                <div class="printed-container">
                    <h1 class="text3">Simulador <span class="span">de ahorro</span></h1>
                    <h6 class="text2">
                        NOTA: Los valores que arroja el simulador son aproximados y de carácter informativo;
                        todos los montos son calculados con base en la actual tasa de interés fijada para el producto.
                        La tasa de interés cambia dependiendo de las condiciones de mercado y las cuotas no incluyen
                        los montos por seguro de vida.
                    </h6>
                    <h6 class="text2">
                        Esta información no compromete ni obliga a Cavipetrol frente a sus potenciales usuarios.
                    </h6>
                    ${originalTable.outerHTML}
                </div>
            `

            iframe.onload = () => {
                // Adjuntar estilos al iframe
                iframe.contentDocument!.head.appendChild(styleElement)

                // Adjuntar contenido al cuerpo del iframe
                iframe.contentDocument!.body.innerHTML = content

                // Lanzar el diálogo de impresión
                iframe.contentWindow!.onafterprint = () => {
                    // Eliminar el iframe después de imprimir
                    iframe.remove()
                }
                iframe.contentWindow!.print()
            }

            // Adjuntar el iframe al documento actual
            document.body.appendChild(iframe)
        } else {
            console.error('No se pudo encontrar el elemento para imprimir.')
        }
    }

    return (
        <>
            <FirstStepWrapper>
                <FirstStepContent>
                    <OptionsContainerDetail id="table-container">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="text">Periocidad:</td>
                                    <td className="text1">{selectedPagoIntereses}</td>
                                </tr>
                                <tr>
                                    <td className="text">Cuota</td>
                                    <td className="text1">{cuotaValue}</td>
                                </tr>
                                <tr>
                                    <td className="text">Plazo en meses:</td>
                                    <td className="text1">{plazoValue}</td>
                                </tr>
                                <tr>
                                    <td className="text">Tasa de interés efectivo anual (%):</td>
                                    <td className="text1">{RateEM}</td>
                                </tr>
                                <tr>
                                    <td className="text">Retención</td>
                                    <td className="text1">{Retention}</td>
                                </tr>
                                <tr>
                                    <td className="text">Ahorro Total:</td>
                                    <td className="text1">{TotalSaving}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ButtonsContainer className="print-hidden hide-on-print">
                            <CancelButton onClick={goBackStep}>Regresar</CancelButton>
                            <ButtonContent>
                                <Button
                                    id="btnSubmit"
                                    className="button-block"
                                    variant="sub-dominant"
                                    type="button"
                                    onClick={printTable}
                                    extend
                                >
                                    Imprimir
                                </Button>
                            </ButtonContent>
                        </ButtonsContainer>
                    </OptionsContainerDetail>
                </FirstStepContent>
            </FirstStepWrapper>
        </>
    )
}

export default TableTerm
