import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import TitleModule from './inc/TitleModule'
import { OptionsContainerDetail, ContainerPossessions } from './ScoresQuotas-styles'
import { useSelector } from 'react-redux'
import { useGetScorequotaData } from './hooks/useScorequota'
import ModalLoading from '../ActivateInternationalCard/inc/ModalInfo/ModalLoading'
import ModalInfo from '../CreditDetail/inc/ModalInfo/ModalInfo'

const Scorequotas = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const [pointhousing, setPointHousing] = useState('')
    const [quotaconsumption, setQuotaConsumption] = useState('')
    const [pointconsumption, setPointConsumption] = useState('')
    const [quotahousing, setQuotaHousing] = useState('')
    const [pointaddconsumption, setPointAddConsumption] = useState('')
    const [quotaaddconsumption, setQuotaAddConsumption] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }

    useEffect(() => {
        GetScorequotaData()
    }, [])

    const GetScorequotaData = (): void => {
        setIsLoading(true)
        useGetScorequotaData(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    setPointHousing(formatter1.format(res.data.Data[0].Points))
                    setQuotaHousing(formatter.format(res.data.Data[0].Quota))
                    setQuotaConsumption(formatter.format(res.data.Data[1].Quota))
                    setPointConsumption(formatter1.format(res.data.Data[1].Points))
                    setPointAddConsumption(formatter1.format(res.data.Data[2].Points))
                    setQuotaAddConsumption(formatter.format(res.data.Data[2].Quota))
                } else if (res.data.Response === '400' || res.data.Response === '500') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'En este momento no podemos procesar tu consulta por favor intenta más tarde.'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const formatter1 = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Puntajes y cupos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]
    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <>
            <LayoutContent>
                <ContainerPossessions>
                    <br />
                    <BreadcrumbApp
                        breadcrumbs={breadcrumbs}
                        onBack={() => {
                            redirection('/home-wallet')
                        }}
                    />
                    <TitleModule />

                    <OptionsContainerDetail>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="text">Producto</td>
                                    <td className="text">Puntos acumulados</td>
                                    <td className="text">Cupo $</td>
                                </tr>
                                <tr>
                                    <td className="text2">Vivienda</td>
                                    <td className="text1">{pointhousing}</td>
                                    <td className="text5">{quotahousing}</td>
                                </tr>
                                <tr>
                                    <td className="text2">Consumo</td>
                                    <td className="text1">{pointconsumption}</td>
                                    <td className="text5">{quotaconsumption}</td>
                                </tr>
                                <tr>
                                    <td className="text2">Adicional de consumo</td>
                                    <td className="text1">{pointaddconsumption}</td>
                                    <td className="text5">{quotaaddconsumption}</td>
                                </tr>
                            </tbody>
                        </table>
                    </OptionsContainerDetail>
                </ContainerPossessions>
                <ModalLoading isLoading={isLoading} />
            </LayoutContent>
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleClose}
            />
        </>
    )
}

export default Scorequotas
