import React from 'react'

import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { CheckOperationSVG } from '../../../../utils/getIcons'
import { ButtonsContainer } from '../../PaymentScheduleCancellationTC-Styled'
import { ConfirmationModal, ModalButtonConfirmation } from './ModalConfirmation-Styled'

interface ModalConfirmationAppProps {
    showModalOpenConfirmation?: boolean
    closeModalConfirmation: () => void
    lastsFourNumCard?: string
}

const ModalConfirmation: React.FC<ModalConfirmationAppProps> = ({
    showModalOpenConfirmation,
    closeModalConfirmation,
    lastsFourNumCard,
}): JSX.Element => {
    const history = useHistory()

    const handleCloseModal = (): void => {
        closeModalConfirmation()
        history.push('/home-wallet')
    }
    return (
        <>
            <ConfirmationModal
                show={showModalOpenConfirmation}
                onHide={closeModalConfirmation}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img src={CheckOperationSVG} alt="" className="" />
                    <h6 className="title-modal-login">Todo listo</h6>
                    <p>
                        Cancelamos el pago de tu tarjeta de crédito *{lastsFourNumCard} podrás
                        activarlo nuevamente en cualquier momento.
                    </p>
                    <ButtonsContainer>
                        <ModalButtonConfirmation variant="primary" onClick={handleCloseModal}>
                            Finalizar
                        </ModalButtonConfirmation>
                    </ButtonsContainer>
                </Modal.Body>
            </ConfirmationModal>
        </>
    )
}

export default ModalConfirmation
