import { Col, Form, Modal, Row } from 'react-bootstrap'
import {
    ModalResult,
    TitlePage,
    SectionTitle,
    DivContainer,
    ButtonFinish,
    SpaceHr,
    Label,
} from '../../UserOperations-styled'

interface ModalOperationProps {
    label: string
    labelInput: string
    userName: string
    identificationCard: string
    state: string
    receipt?: string
    showModal: boolean
    shouldInitializeUser: boolean
    handleCloseModal: () => void
}

const ModalOperation: React.FC<ModalOperationProps> = ({
    label,
    labelInput,
    userName,
    identificationCard,
    state,
    receipt,
    showModal,
    shouldInitializeUser,
    handleCloseModal,
}): JSX.Element => {
    return (
        <ModalResult
            show={showModal}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Title>
                <SectionTitle>
                    <TitlePage>{label}</TitlePage>
                </SectionTitle>
            </Modal.Title>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Label>{labelInput}</Label>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" value={userName} disabled />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Label>Cédula</Label>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" value={identificationCard} disabled />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label>Estado</Label>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="textarea"
                                as={'textarea'}
                                rows={state.length > 20 ? 3 : 1}
                                value={state}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        {shouldInitializeUser && (
                            <>
                                <Label>Comprobante</Label>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" value={receipt} disabled />
                                </Form.Group>
                            </>
                        )}
                    </Col>
                </Row>
                <SpaceHr />
                <DivContainer>
                    <ButtonFinish className="finish" variant="primary" onClick={handleCloseModal}>
                        Terminar
                    </ButtonFinish>
                </DivContainer>
            </Modal.Body>
        </ModalResult>
    )
}

export default ModalOperation
