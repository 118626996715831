import { FC, useEffect, useMemo, useState } from 'react'
import { Form, Row, Col, Container, Pagination, PageItem } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { ContainerForm, PaginationContent } from '../PersonalInformation/PersonalInformation-styles'
import { ButtonPagination, TitleSectionForm } from './FinancialInformation-styles'
import {
    Country,
    ListDataCrm,
    ListDataMoneyList,
    ListDataOptionsCommons,
    UserData,
} from '../../../../../../domain/models/crm'
import { yupResolver } from '@hookform/resolvers/yup'
import { financialInformationSchema } from '../../validations/ValidationShema'
import Select from 'react-select'
import { normalizeText } from '../../../../utils/misc'
interface ExtendedUserData extends UserData {
    HaveOtherIncome: boolean
}
interface FinancialInformationProps {
    userData: UserData
    onUpdate: (data: Partial<UserData>) => void
    handleNextStep: (value: string) => void
    activitiesList: ListDataOptionsCommons[]
    countriesList: Country[]
    moneyList: ListDataMoneyList[]
    occupationList: ListDataCrm[]
}

const FinancialInformation: FC<FinancialInformationProps> = ({
    userData,
    onUpdate,
    handleNextStep,
    countriesList,
    moneyList,
}): JSX.Element => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<ExtendedUserData>({
        resolver: yupResolver(financialInformationSchema),
        mode: 'onChange',
        defaultValues: {
            ...userData,
            HaveOtherIncome: userData.ValueOtherIncome > 0 ? true : false,
        },
    })

    const isBusinessman = watch('YouEntrepreneurOrOwnCompany')
    const isConductForeignCurrencyTransactions = watch('ConductForeignCurrencyTransactions')

    const onSubmit = (data: Partial<UserData>): void => {
        const processedData = {
            ...data,
            BasicSalaryOrPension: Number(data.BasicSalaryOrPension),
            ValueOtherIncome: Number(data.ValueOtherIncome),
            TotalExpenditures: Number(data.TotalExpenditures),
            TotalAssets: Number(data.TotalAssets),
            TotalLiabilities: Number(data.TotalLiabilities),
            Amount: Number(data.Amount),
            CompanyNIT: data.CompanyNIT ? Number(data.CompanyNIT) : null,
        }
        onUpdate(processedData)
        handleNextStep('declarationOfPoliticalExposed')
    }

    const formatterCurrency = useMemo(() => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        })
    }, [])

    const [formattedValueOtherIncome, setFormattedValueOtherIncome] = useState(
        formatterCurrency.format(userData.ValueOtherIncome || 0)
    )
    const [formattedBasicSalaryOrPension, setFormattedBasicSalaryOrPension] = useState(
        formatterCurrency.format(userData.BasicSalaryOrPension || 0)
    )
    const [formattedTotalExpenditures, setFormattedTotalExpenditures] = useState(
        formatterCurrency.format(userData.TotalExpenditures || 0)
    )
    const [formattedTotalAssets, setFormattedTotalAssets] = useState(
        formatterCurrency.format(userData.TotalAssets || 0)
    )
    const [formattedTotalLiabilities, setFormattedTotalLiabilities] = useState(
        formatterCurrency.format(userData.TotalLiabilities || 0)
    )
    const [formattedAmount, setFormattedAmount] = useState(
        formatterCurrency.format(userData.Amount || 0)
    )

    const isHasOtherIncome = watch('HaveOtherIncome')

    useEffect(() => {
        if (!isConductForeignCurrencyTransactions) {
            setValue('HasFinancialProductsAbroad', null)
            setValue('HasAccountsForeignCurrency', null)
            setValue('ProductType', '')
            setValue('Entity', '')
            setValue('InternationalCity', '')
            setValue('InternationalCountry', null)
            setValue('InternationalMoney', '')
            setValue('Amount', 0)
            setValue('InternationalIdentificationProductNumber', '')
            setValue('InternationalCountryName', '')
            setFormattedAmount(formatterCurrency.format(0))
        }

        if (!isBusinessman) {
            setValue('CompanyActivity', '')
            setValue('CompanyNIT', null)
        }
    }, [
        formatterCurrency,
        isBusinessman,
        isConductForeignCurrencyTransactions,
        isHasOtherIncome,
        setValue,
    ])

    const handleCurrencyChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any,
        setFormattedValue: (value: string) => void
    ): void => {
        const { value } = event.target
        const cleanedValue = value.replace(/[^0-9]/g, '').substring(0, 20)

        setFormattedValue(formatterCurrency.format(parseInt(cleanedValue, 10) || 0))

        // Converting cleaned value to number and setting it to form value
        setValue(field.name, cleanedValue ? parseInt(cleanedValue, 10) : '')
        field.onChange(cleanedValue ? parseInt(cleanedValue, 10) : '')
    }

    const handleValueOtherIncomeChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedValueOtherIncome)

    const handleBasicSalaryOrPensionChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedBasicSalaryOrPension)

    const handleTotalAssetsChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedTotalAssets)

    const handleTotalExpendituresChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedTotalExpenditures)

    const handleTotalLiabilitiesChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedTotalLiabilities)

    const handleAmount = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: any
    ): void => handleCurrencyChange(event, field, setFormattedAmount)

    const handleOnChangeHaveOtherIncome = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('HaveOtherIncome', value)

        if (!value) {
            setValue('ValueOtherIncome', 0)
            setFormattedValueOtherIncome(formatterCurrency.format(0))
        }
    }

    const handleOnChangeYouEntrepreneurOrOwnCompany = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('YouEntrepreneurOrOwnCompany', value)
    }

    const handleOnChangeConductForeignCurrencyTransactions = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('ConductForeignCurrencyTransactions', value)
    }
    const handleOnChangeHasAccountsForeignCurrency = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('HasAccountsForeignCurrency', value)
    }
    const handleOnChangeHasFinancialProductsAbroad = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('HasFinancialProductsAbroad', value)
    }

    const countryOptions = countriesList
        .map((country) => ({
            value: country.Country,
            label: country.Country,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    const moneyOptions = moneyList
        .map((money) => ({
            value: money.Nombre,
            label: money.Nombre,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    return (
        <Container>
            <TitleSectionForm>Información financiera</TitleSectionForm>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ContainerForm>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Salario Básico, Pensión</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'BasicSalaryOrPension'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedBasicSalaryOrPension}
                                            maxLength={50}
                                            isInvalid={!!errors.BasicSalaryOrPension}
                                            onChange={(event) =>
                                                handleBasicSalaryOrPensionChange(event, field)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.BasicSalaryOrPension?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>¿Posee otros ingresos?</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="HaveOtherIncome"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.HaveOtherIncome}
                                            onChange={(e) => {
                                                handleOnChangeHaveOtherIncome(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.HaveOtherIncome?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Valor</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'ValueOtherIncome'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedValueOtherIncome}
                                            maxLength={20}
                                            isInvalid={!!errors.ValueOtherIncome}
                                            disabled={!isHasOtherIncome}
                                            onChange={(event) =>
                                                handleValueOtherIncomeChange(event, field)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ValueOtherIncome?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Egresos Totales</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'TotalExpenditures'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedTotalExpenditures}
                                            maxLength={20}
                                            isInvalid={!!errors.TotalExpenditures}
                                            onChange={(event) =>
                                                handleTotalExpendituresChange(event, field)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.TotalExpenditures?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Activos Totales</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'TotalAssets'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedTotalAssets}
                                            maxLength={20}
                                            isInvalid={!!errors.TotalAssets}
                                            onChange={(event) =>
                                                handleTotalAssetsChange(event, field)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.TotalAssets?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Pasivos Totales</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'TotalLiabilities'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedTotalLiabilities}
                                            maxLength={20}
                                            isInvalid={!!errors.TotalLiabilities}
                                            onChange={(event) =>
                                                handleTotalLiabilitiesChange(event, field)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.TotalLiabilities?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        <Col md={2}>
                            <Form.Label>Código CIIU</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'CIIUCode'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={20}
                                            {...field}
                                            isInvalid={!!errors.CIIUCode}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.CIIUCode?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Donde consigna su nómina o mesada pensional</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="WherePutSalaryOrPension"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={90}
                                            {...field}
                                            isInvalid={!!errors.WherePutSalaryOrPension}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.WherePutSalaryOrPension?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>
                                En que entidades bancarias tiene productos financieros actualmente
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="BankingInstitutionsFinancialProducts"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={90}
                                            {...field}
                                            isInvalid={
                                                !!errors.BankingInstitutionsFinancialProducts
                                            }
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.BankingInstitutionsFinancialProducts?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Es empresario o posee empresa</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="YouEntrepreneurOrOwnCompany"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.YouEntrepreneurOrOwnCompany}
                                            value={field.value?.toString() ?? ''}
                                            onChange={(e) => {
                                                handleOnChangeYouEntrepreneurOrOwnCompany(e)
                                            }}
                                        >
                                            <option value="">Seleccione...</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.YouEntrepreneurOrOwnCompany?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>NIT de la empresa</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="CompanyNIT"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.CompanyNIT}
                                            disabled={!isBusinessman}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.CompanyNIT?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Cuál es la actividad de la empresa</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'CompanyActivity'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            maxLength={50}
                                            isInvalid={!!errors.CompanyActivity}
                                            disabled={!isBusinessman}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.CompanyActivity?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <TitleSectionForm>Actividades operaciones internacionales</TitleSectionForm>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Realiza Transacciones en Moneda Extranjera</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="ConductForeignCurrencyTransactions"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.ConductForeignCurrencyTransactions}
                                            onChange={(e) => {
                                                handleOnChangeConductForeignCurrencyTransactions(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ConductForeignCurrencyTransactions?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Posee productos financieros en el Exterior</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="HasFinancialProductsAbroad"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.HasFinancialProductsAbroad}
                                            disabled={!isConductForeignCurrencyTransactions}
                                            onChange={(e) => {
                                                handleOnChangeHasFinancialProductsAbroad(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.HasFinancialProductsAbroad?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Posee cuentas en moneda Extranjera</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="HasAccountsForeignCurrency"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.HasAccountsForeignCurrency}
                                            disabled={!isConductForeignCurrencyTransactions}
                                            onChange={(e) => {
                                                handleOnChangeHasAccountsForeignCurrency(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.HasAccountsForeignCurrency?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Tipo de Producto</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="ProductType"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            isInvalid={!!errors.ProductType}
                                            disabled={!isConductForeignCurrencyTransactions}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ProductType?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Entidad</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Entity"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            isInvalid={!!errors.Entity}
                                            disabled={!isConductForeignCurrencyTransactions}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Entity?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Monto</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'Amount'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            value={formattedAmount}
                                            maxLength={20}
                                            isInvalid={!!errors.Amount}
                                            onChange={(event) => handleAmount(event, field)}
                                            disabled={!isConductForeignCurrencyTransactions}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Amount?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>País</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="InternationalCountry"
                                control={control}
                                render={({ field }) => {
                                    const matchedCity = countryOptions.find(
                                        (option) =>
                                            normalizeText(option.label) ===
                                            normalizeText(field.value?.NewName ?? '')
                                    )

                                    if (!matchedCity && field.value?.NewName) {
                                        setTimeout(() => {
                                            field.onChange({ NewName: '' })
                                        }, 0)
                                    }

                                    return (
                                        <Form.Group>
                                            <Select
                                                className="selectFromControl"
                                                placeholder="Seleccione"
                                                options={countryOptions}
                                                value={matchedCity || undefined}
                                                isDisabled={!isConductForeignCurrencyTransactions}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        field.onChange({
                                                            NewName: selectedOption.value,
                                                        })
                                                        setValue(
                                                            'InternationalCountryName',
                                                            selectedOption.value
                                                        )
                                                    } else {
                                                        field.onChange({ NewName: '' })
                                                        setValue('InternationalCountryName', '')
                                                    }
                                                }}
                                                isClearable
                                            />

                                            <Form.Control.Feedback
                                                type="invalid"
                                                style={{ display: 'block' }}
                                            >
                                                {errors.InternationalCountry?.NewName?.message ||
                                                    errors.InternationalCountry?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )
                                }}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Ciudad</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="InternationalCity"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            isInvalid={!!errors.InternationalCity}
                                            disabled={!isConductForeignCurrencyTransactions}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.InternationalCity?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Moneda</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="InternationalMoney"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Select
                                            className={'selectFromControl'}
                                            placeholder="Seleccione"
                                            options={moneyOptions}
                                            value={
                                                moneyOptions.find(
                                                    (option) => option.value === (field.value ?? '')
                                                ) || null
                                            }
                                            isDisabled={!isConductForeignCurrencyTransactions}
                                            onChange={(selectedOption) => {
                                                field.onChange(
                                                    selectedOption ? selectedOption.value : null
                                                )
                                            }}
                                            isClearable
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.InternationalMoney?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Identificación, Número Producto</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="InternationalIdentificationProductNumber"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            {...field}
                                            isInvalid={
                                                !!errors.InternationalIdentificationProductNumber
                                            }
                                            disabled={!isConductForeignCurrencyTransactions}
                                            value={field.value ?? ''}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {
                                                errors.InternationalIdentificationProductNumber
                                                    ?.message
                                            }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                </ContainerForm>
                <PaginationContent>
                    <Pagination>
                        <ButtonPagination>
                            <PageItem
                                className="disableFm"
                                onClick={() => handleNextStep('contactInfo')}
                            >
                                {'<'} Anterior
                            </PageItem>
                        </ButtonPagination>
                        <p className="symbol"> {'/'} </p>
                        <ButtonPagination>
                            <PageItem className="disableFm" onClick={handleSubmit(onSubmit)}>
                                Continuar {'>'}
                            </PageItem>
                        </ButtonPagination>
                    </Pagination>
                </PaginationContent>
            </form>
        </Container>
    )
}

export default FinancialInformation
