import { Modal } from 'react-bootstrap'

import {
    ButtonContent,
    ButtonsContainer,
    ModalButton,
    ModalForm,
} from '../ModalForm/ModalForm-styles'
import { Button } from '../../../../components'

interface ModalConfirmationAppProps {
    showModalValidation?: boolean
    handledModalInfo: () => void
    messageInfoModal: string
    titleModal: string
    handleCloseModal: () => void
}

const ModalConfirmation: React.FC<ModalConfirmationAppProps> = ({
    showModalValidation,
    handledModalInfo,
    messageInfoModal,
    titleModal,
    handleCloseModal,
}): JSX.Element => {
    return (
        <>
            <ModalForm
                show={showModalValidation}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <h6 className="title-modal-login">{titleModal}</h6>
                    <p>{messageInfoModal}</p>
                    <ButtonsContainer>
                        <ModalButton variant="primary" onClick={handleCloseModal}>
                            No
                        </ModalButton>
                        <ButtonContent>
                            <Button
                                id="btnSubmit"
                                className="button-block"
                                variant="sub-dominant"
                                type="button"
                                onClick={handledModalInfo}
                                isLoading={false}
                                extend
                                disabled={false}
                            >
                                Sí
                            </Button>
                        </ButtonContent>
                    </ButtonsContainer>
                </Modal.Body>
            </ModalForm>
        </>
    )
}

export default ModalConfirmation
