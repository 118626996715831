import React, { useEffect, useState } from 'react'
import { Form, PageItem, Pagination } from 'react-bootstrap'
import { Loading, ModalGeneric } from '../../../../components'
import { useSelector } from 'react-redux'
import { useGetConfiguration, useUpdateConfiguration } from '../../hooks/useManageContent'
import {
    ButtonPagination,
    ButtonUnstyled,
    ContainerTable,
    ContentInfo,
    PaginationContent,
    SectionTd,
    SectionTh,
} from '../../ManageableContent-styled'
import { CancelWhite, EditWhite, NotProductSVG } from '../../../../utils/getIcons'
import ModalForm from '../ModalForm/ModalForm'

interface ConfigurationData {
    Id: number
    Name: string
    Value: string
    IsDeleted: boolean
    CreateDate: Date
    UserIdentification: string | null
    IpAddress: string | null
    Ubication: string
    Header: string | null
    Method: string | null
}

const TableConfiguration: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const [configurations, setConfigurations] = useState<ConfigurationData[]>([])
    const [isEditable, setIsEditable] = useState<boolean>(false)

    const currentItems = configurations.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(configurations.length / itemsPerPage)
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    let keyCounter = 0

    const [showModalForm, setShowModalForm] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
    const [titleModal, setTitleModal] = useState<string>('')
    const [valueConfiguration, setValueConfiguration] = useState<string>('')
    const [idConfiguration, setIdConfiguration] = useState<number>(0)

    const GetConfiguration = (): void => {
        setLoading(true)
        useGetConfiguration(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setConfigurations(res['data']['Data'])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    useEffect(() => {
        GetConfiguration()
    }, [])

    const updateRegister = (): void => {
        setIsEditable((prevIsEditable) => !prevIsEditable)
        const thElement = document.querySelector('#titleModal')
        if (thElement) {
            const txtTh = thElement.textContent ? thElement.textContent : ''
            setTitleModal(txtTh)
        }
    }

    const [checkValue, setCheckValue] = useState<number>()

    const handleOnChange = (
        info: ConfigurationData,
        { target }: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = target
        setCheckValue(parseInt(value))
        setIdConfiguration(info.Id)
        setValueConfiguration(info.Value)
        handleShowModalForm()
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    const handleShowModalForm = (): void => {
        setShowModalForm(true)
    }

    const handleCloseModalForm = (): void => {
        setShowModalForm(false)
        setValueConfiguration('')
        setCheckValue(0)
    }

    const HandleFormSubmit = (value: string): void => {
        setIsLoadingUpdate(true)
        setIsEditable(false)
        useUpdateConfiguration(tokenSave, idConfiguration, value)
            .then((res: any) => {
                setIsLoadingUpdate(false)
                if (res['status'] === 200) {
                    GetConfiguration()
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                handleCloseModalForm()
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorMessage('No se ha podido conectar con el servidor')
                handleCloseModalForm()
            })
    }

    const cancelEdit = (): void => {
        setIsEditable(false)
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <ContainerTable striped bordered responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th id="titleModal">
                                {' '}
                                <SectionTh showThirdButton={isEditable}>
                                    Value
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister()}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th>Is deleted</th>
                            <th>Create date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((info: ConfigurationData) => (
                            <tr key={info.Id}>
                                <td className="center">{info.Id}</td>
                                <td>{info.Name}</td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Value}{' '}
                                        {isEditable && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.Id}
                                                checked={checkValue === info.Id}
                                                onChange={(e) => handleOnChange(info, e)}
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td className="center">{info.IsDeleted.toString()}</td>
                                <td className="center">{info.CreateDate.toString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </ContainerTable>
            )}
            <PaginationContent>
                <Pagination>
                    {currentPage !== 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`prev_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                {'<'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                    {[...Array(totalPages)].map((_, page) => (
                        <PageItem
                            key={`page_${page}`}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageClick(page + 1)}
                        >
                            {page + 1}
                        </PageItem>
                    ))}
                    {currentPage !== totalPages && totalPages > 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`next_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                {'>'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                </Pagination>
                <ContentInfo>
                    {configurations.length === 0 && showError && (
                        <div style={{ display: 'flex' }}>
                            <img src={NotProductSVG} alt="IconEdit" width={50} />
                            <span style={{ alignContent: 'center' }}>
                                No hay información disponible
                            </span>
                        </div>
                    )}
                </ContentInfo>
            </PaginationContent>

            <ModalForm
                showModal={showModalForm}
                handleCloseModal={handleCloseModalForm}
                isLoading={isLoadingUpdate}
                titleText={titleModal}
                updateOnSubmitted={HandleFormSubmit}
                valueDataChange={valueConfiguration}
            />

            <ModalGeneric
                show={showError}
                handleClose={handleClose}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessage}
                handleButton={handleClose}
                textButton="Aceptar"
            />
        </>
    )
}

export default TableConfiguration
