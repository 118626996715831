import { keyChangeService } from '../../../../../domain/services/User.service'

export async function useValidateIVR(tokenSave: string, body: string) {
    try {
        return await keyChangeService
            .validateIVR('api/Authorization/ValidIVR', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidatePassword(tokenSave: string, body: string) {
    try {
        return await keyChangeService
            .validatePassword('api/Authorization/ValidatedPassword', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useChangePassword(tokenSave: string, body: string) {
    try {
        return await keyChangeService
            .changePassword('api/Authorization/ChangePassword', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
