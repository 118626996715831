import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'

import { ContainerPossessions } from './PossessionsInsert-styles'
import PossessionsForm from './inc/PossessionsForm/PossessionsForm'

const PossessionsInsert = (): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [buildingName, setBuildingName] = useState('')
    const [actionType, setActiongType] = useState('')

    const handleBuildingName = (typeName: string) => {
        setBuildingName(typeName)
    }

    const handleActiongType = (typeName: string) => {
        setActiongType(typeName)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Inserción de inmueble',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: buildingName,
            active: false,
            onClick: () => {
                console.log('buildingName', buildingName)
            },
        },
        {
            text: actionType,
            active: false,
            onClick: () => {
                console.log('actionType', actionType)
            },
        },
    ]

    return (
        <LayoutContent>
            <ContainerPossessions>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                {/* <TitleModule /> */}
                <PossessionsForm
                    handleBuildingName={handleBuildingName}
                    handleActiongType={handleActiongType}
                ></PossessionsForm>
            </ContainerPossessions>
        </LayoutContent>
    )
}

export default PossessionsInsert
