import React from 'react'

import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { NotProductSVG } from '../../../../utils/getIcons'
import { ButtonsContainer } from '../../PaymentScheduleCancellationTC-Styled'
import {
    ConfirmationModal,
    ModalButtonConfirmation,
} from '../ModalConfirmation/ModalConfirmation-Styled'

interface ModalErrorAppProps {
    showModalOpenError?: boolean
    closeModalError: () => void
    titleError?: string
    textError?: string
}

const ModalError: React.FC<ModalErrorAppProps> = ({
    showModalOpenError,
    closeModalError,
    titleError,
    textError,
}): JSX.Element => {
    const history = useHistory()

    const handleCloseModal = (): void => {
        closeModalError()
        history.push('/home-wallet')
    }
    return (
        <>
            <ConfirmationModal
                show={showModalOpenError}
                onHide={closeModalError}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img src={NotProductSVG} alt="" className="" />
                    {titleError && <h6>{titleError}</h6>}
                    {textError && <p>{textError}</p>}
                    <ButtonsContainer>
                        <ModalButtonConfirmation variant="primary" onClick={handleCloseModal}>
                            Finalizar
                        </ModalButtonConfirmation>
                    </ButtonsContainer>
                </Modal.Body>
            </ConfirmationModal>
        </>
    )
}

export default ModalError
