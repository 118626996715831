import {
    ContainerTable,
    ContainerTitle,
    Title,
    Image,
    ButtonUnstyled,
} from './../../AssignRoles-styles'
import { CardId } from '../../../../utils/getIcons'

interface Rol {
    Id: number
    RoleName: string
    Part: string
}

interface TableRolesProps {
    rolSelect: (rol: string) => void
    roles: Rol[]
}

const TableRoles: React.FC<TableRolesProps> = ({ rolSelect, roles }) => {
    const handleClick = (rol: string): void => {
        rolSelect(rol)
    }

    return (
        <>
            <ContainerTitle>
                <Title>Roles</Title>
            </ContainerTitle>

            <ContainerTable striped bordered responsive>
                <thead>
                    <tr>
                        <th>Nombre rol</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((rol: Rol) => (
                        <tr key={rol.Id}>
                            <td>{rol.RoleName}</td>
                            <td>
                                <ButtonUnstyled
                                    variant="primary"
                                    onClick={() => handleClick(rol.RoleName)}
                                >
                                    <Image src={CardId} alt="icono" />
                                </ButtonUnstyled>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </ContainerTable>
        </>
    )
}

export default TableRoles
