import tw, { styled } from 'twin.macro'

export const OptionsContainerDetail = styled.table`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] w-full flex`}
    span {
        color: #f5a50b;
    }

    .text {
        ${tw`font-montserrat font-bold lg:text-base text-[20px] color[var(--dominant-color-dark)]`}
    }
    table {
        margin: 0 auto;
        width: 400px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .text1 {
        ${tw`font-montserrat font-bold`}
        text-align:right;
    }
    .text2 {
        ${tw`font-montserrat font-bold `}
        text-align:center;
    }
    th,
    td {
        padding: 20px;
        height: 60px;
        width: 22px;
        border: 1px solid black;
    }
`

export const ContainerPossessions = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
