import { Controller, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import {
    ContainerButtons,
    FormLabel,
    FormOption,
    FromFai,
    TextP,
    ModalCancelButton,
    ModalContinueButton,
} from './FirsStep-Styled'
import ModalLoading from '../Modals/ModalLoading'
import { useSelector } from 'react-redux'
import ModalError from '../Modals/ModalError'
import { Content, Wrapper } from '../../InterbankTransfer-Styled'
import {
    useGetCities,
    useGetDepartment,
    useValidacionACHTransfer,
} from '../../hooks/useInterbankTransfer'
import { useHistory } from 'react-router-dom'

interface Option {
    label: string
    value: string
}

interface Bank {
    tcbId: number
    tcbConsecutivo: string
    tcbNombre: string
    tcbIdInterno: string
    tcbCiudad: string
    tcbPais: string
}

interface State {
    Id: string
    Nombre: string
}

interface TransactionData {
    amountTransfer: string
    nameAddreess: string
    typeDocumentId: string
    destinationIdentification: string
    stateAddressId: string
    stateAddressName: string
    cityAddressId: string
    cityAddressName: string
    bankAddressId: string
    bankAddressName: string
    typeAccountAddress: string
    accountNumberAddressee: string
    description: string
}

interface FirsStepProps {
    associatedName: string
    associatedDocumentNumber: string
    onNextStep: () => void
    saveDataTransfer: (transactionData: TransactionData) => void
    banks: Bank[]
    savedTransactions: TransactionData | null
    isEdit: boolean
}

const FirsStep: React.FC<FirsStepProps> = ({
    associatedName,
    associatedDocumentNumber,
    onNextStep,
    saveDataTransfer,
    banks,
    savedTransactions,
    isEdit,
}) => {
    const history = useHistory()
    const methods = useForm()
    const { control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    const [amountTransfer, setAmountTransfer] = useState<string>('')
    const [amountTransferFormat, setAmountTransferFormat] = useState<string>('')

    const [nameAddreess, setNameAddreess] = useState<string>('')
    const [destinationIdentification, setDestinationIdentification] = useState<string>('')
    const [typeDocumentId, setTypeDocumentId] = useState<string>('')

    const [stateAddressId, setStateAddressId] = useState<string>('')
    const [stateAddressName, setStateAddressName] = useState<string>('')

    const [cityAddressId, setCityAddressId] = useState<string>('')
    const [cityAddressName, setCityAddressName] = useState<string>('')

    const [bankAddressId, setBankAddressId] = useState<string>('')
    const [bankAddressName, setBankAddressName] = useState<string>('')
    const [typeAccountAddress, setTypeAccountAddress] = useState<string>('')
    const [accountNumberAddressee, setAccountNumberAddressee] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false)

    const [departaments, setDepartaments] = useState<State[]>([])
    const [cities, setCities] = useState<State[]>([])
    const [isEditFrom, setIsEditForm] = useState<boolean>(false)

    const optionsTypeIdentification: Option[] = [
        { label: 'Cédula Ciudadanía', value: 'C' },
        { label: 'Cédula Extranjería', value: 'E' },
        { label: 'NIT', value: 'N' },
        { label: 'Tarjeta Identidad', value: 'T' },
        { label: 'NIT Persona Natural', value: 'L' },
    ]

    const optionsTypeAccount: Option[] = [
        { label: 'CTA. Corriente ', value: 'CTA. CORRIENTE' },
        { label: 'CTA. De Ahorros', value: 'CTA. DE AHORROS' },
    ]

    useEffect(() => {
        GetDepartment()
    }, [tokenSave])

    useEffect(() => {
        if (savedTransactions && isEdit && !isEditFrom) {
            const value = parseFloat(savedTransactions.amountTransfer)

            GetCities(savedTransactions.stateAddressName || '')
            setNameAddreess(savedTransactions.nameAddreess || '')
            setBankAddressId(savedTransactions.bankAddressId || '')
            setBankAddressName(savedTransactions.bankAddressName || '')
            setTypeDocumentId(savedTransactions.typeDocumentId)
            setTypeAccountAddress(savedTransactions.typeAccountAddress || '')
            setDestinationIdentification(savedTransactions.destinationIdentification || '')
            setAmountTransferFormat(savedTransactions.amountTransfer || '')
            setAmountTransferFormat(formatter.format(value))
            setAmountTransfer(savedTransactions.amountTransfer || '')
            setCityAddressId(savedTransactions.cityAddressId || '')
            setStateAddressId(savedTransactions.stateAddressId || '')
            setAccountNumberAddressee(savedTransactions.accountNumberAddressee || '')
            setDescription(savedTransactions.description || '')
            setStateAddressName(savedTransactions.stateAddressName || '')
            setCityAddressName(savedTransactions.cityAddressName || '')
        }
    }, [savedTransactions, formatter, isEdit])

    const handleOnSubmit = (): void => {
        onNextStep()
        setIsEditForm(false)
        const transactionData: TransactionData = {
            amountTransfer: amountTransfer,
            nameAddreess: nameAddreess,
            typeDocumentId: typeDocumentId,
            destinationIdentification: destinationIdentification,
            stateAddressId: stateAddressId,
            stateAddressName: stateAddressName,
            cityAddressId: cityAddressId,
            cityAddressName: cityAddressName,
            bankAddressId: bankAddressId,
            bankAddressName: bankAddressName,
            typeAccountAddress: typeAccountAddress,
            accountNumberAddressee: accountNumberAddressee,
            description: description,
        }

        saveDataTransfer(transactionData)
    }

    const ValidationTransfer = (): void => {
        setIsLoading(true)
        const value = parseFloat(amountTransfer)
        useValidacionACHTransfer(tokenSave, value)
            .then((res: any) => {
                setIsLoading(false)
                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                const responseStatus = res.data?.Response || res.Response

                if (responseIsSuccess) {
                    if (res.data?.Data.Validation) {
                        handleOnSubmit()
                    } else if (!res.data?.Data.ValidationDay) {
                        setError('amountTransferFormat', {
                            message: 'El monto máximo es de $10’000.000 diarios',
                        })
                    } else if (!res.data?.Data.ValidationMonth) {
                        setError('amountTransferFormat', {
                            message: 'El monto máximo en el mes es de $50’000.000',
                        })
                    }
                } else if (responseStatus === '500') {
                    setErrorMessage(
                        'No ha sido posible validar la información ingresada, por favor intenta más tarde'
                    )
                    setShowError(true)
                } else {
                    setErrorMessage(res.Data.Message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const GetDepartment = (): void => {
        useGetDepartment(tokenSave)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response
                if (responseCode === '200') {
                    setDepartaments(res.data.Data)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const GetCities = (stateAddressName: string): void => {
        useGetCities(tokenSave, stateAddressName)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response
                if (responseCode === '200') {
                    setCities(res.data.Data)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    const handleIdentificationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        const isValidValue = (value: string): boolean => /^[0-9]*$/.test(value) || value === ''

        const updateValue = (newValue: string, maxLength: number, errorMessage: string): void => {
            setDestinationIdentification(newValue)
            if (newValue.length <= maxLength) {
                clearErrors('destinationIdentification')
            } else {
                setError('destinationIdentification', { message: errorMessage })
            }
        }

        if (value !== '') {
            const newValue = value.replace(/[^0-9.]/g, '')
            if (['N', 'L'].includes(typeDocumentId)) {
                if (isValidValue(value)) {
                    updateValue(newValue, 10, 'Nit inválido, debe contener 10 caracteres')
                }
            } else if (['C', 'E', 'T'].includes(typeDocumentId)) {
                if (isValidValue(value)) {
                    updateValue(newValue, 20, 'Ingrese máximo 20 caracteres')
                }
            }
        } else {
            setDestinationIdentification('')
            setError('destinationIdentification', { message: 'Este campo es obligatorio' })
        }

        setIsButtonEnabled(isFormValid())
    }

    const isFormValid = (): boolean => {
        setIsEditForm(true)
        const basicValidation =
            nameAddreess.trim() !== '' &&
            destinationIdentification.trim() !== '' &&
            typeDocumentId.trim() !== '' &&
            amountTransfer.trim() !== '' &&
            stateAddressName.trim() !== '' &&
            cityAddressName.trim() !== '' &&
            bankAddressId.trim() !== '' &&
            typeAccountAddress.trim() !== '' &&
            accountNumberAddressee.trim() !== '' &&
            description.trim() !== ''

        return basicValidation
    }

    return (
        <Wrapper>
            <Content>
                <FormLabel>Solicitud</FormLabel>
                <TextP>
                    Te invitamos a diligenciar el siguiente formulario para continuar con la
                    solicitud.
                </TextP>
                <FromFai>
                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Nombre Asociado</FormLabel>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="input-icon-user"
                                    type="text"
                                    value={associatedName.toLocaleLowerCase()}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Cédula de Asociado</FormLabel>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="input-icon-associated-id"
                                    type="text"
                                    value={associatedDocumentNumber}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Nombre del Destinatario</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'nameAddreess'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-user"
                                            type="text"
                                            {...register('nameAddreess', {
                                                required: true,
                                            })}
                                            value={nameAddreess}
                                            placeholder="Escribe el nombre del destinatario"
                                            required
                                            isInvalid={!!errors['nameAddreess']}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    const alphanumericRegex = /^[a-zA-Z0-9 ]*$/
                                                    const isValid = alphanumericRegex.test(
                                                        e.target.value
                                                    )
                                                    if (isValid) {
                                                        if (e.target.value.length > 40) {
                                                            setError('nameAddreess', {
                                                                message:
                                                                    'Este campo debe contener un máximo de 40 caracteres',
                                                            })
                                                        } else {
                                                            clearErrors('nameAddreess')
                                                        }
                                                    } else {
                                                        setError('nameAddreess', {
                                                            message:
                                                                'Solo se permiten números y letras',
                                                        })
                                                    }
                                                } else {
                                                    setNameAddreess('')
                                                    setError('nameAddreess', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                setNameAddreess(e.target.value)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('nameAddreess', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    /* clearErrors('nameAddreess') */
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['nameAddreess'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['nameAddreess']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Tipo de documento del Destinatario</FormLabel>

                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'typeDocumentId'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Select
                                            className="input-icon-destination-typeID"
                                            {...register('typeDocumentId', {
                                                required: true,
                                            })}
                                            value={typeDocumentId}
                                            required
                                            isInvalid={!!errors['typeDocumentId']}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value !== '' ||
                                                    e.target.value === null
                                                ) {
                                                    clearErrors('typeDocumentId')
                                                }
                                                setTypeDocumentId(e.target.value)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('typeDocumentId', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    clearErrors('typeDocumentId')
                                                }
                                                onBlur()
                                            }}
                                        >
                                            <FormOption disabled value="">
                                                Selecciona una opción
                                            </FormOption>
                                            {optionsTypeIdentification.map((option) => (
                                                <FormOption key={option.value} value={option.value}>
                                                    {option.label}
                                                </FormOption>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                {errors['typeDocumentId'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['typeDocumentId']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Identificación Destinatario</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'destinationIdentification'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-destination-id"
                                            type="text"
                                            {...register('destinationIdentification', {
                                                required: true,
                                            })}
                                            disabled={typeDocumentId === ''}
                                            value={destinationIdentification}
                                            placeholder="Ej. 123123123"
                                            required
                                            isInvalid={!!errors['destinationIdentification']}
                                            onChange={handleIdentificationChange}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('destinationIdentification', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    /* clearErrors('destinationIdentification') */
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['destinationIdentification'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['destinationIdentification']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Departamento Destinatario</FormLabel>

                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'stateAddressName'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Select
                                            className="input-icon-state"
                                            {...register('stateAddressName', {
                                                required: true,
                                            })}
                                            value={stateAddressName}
                                            required
                                            isInvalid={!!errors['stateAddressName']}
                                            onChange={(e) => {
                                                const selectedName = e.target.value

                                                const selectedDepartment = departaments.find(
                                                    (department) =>
                                                        department.Nombre === selectedName
                                                )

                                                if (selectedDepartment) {
                                                    clearErrors('stateAddressName')
                                                    const selectedId = selectedDepartment.Id
                                                    setStateAddressId(selectedId)
                                                }

                                                setStateAddressName(selectedName)
                                                setCityAddressId('')
                                                setCityAddressName('')
                                                GetCities(selectedName)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('stateAddressName', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    clearErrors('stateAddressName')
                                                }
                                                onBlur()
                                            }}
                                        >
                                            <FormOption disabled value="">
                                                Selecciona una opción
                                            </FormOption>
                                            {departaments.length > 0 ? (
                                                departaments.map((option: State) => (
                                                    <FormOption
                                                        key={option.Id}
                                                        value={option.Nombre}
                                                    >
                                                        {option.Nombre}
                                                    </FormOption>
                                                ))
                                            ) : (
                                                <FormOption value="noData" disabled>
                                                    No Data
                                                </FormOption>
                                            )}
                                        </Form.Select>
                                    )}
                                />
                                {errors['stateAddressName'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['stateAddressName']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Ciudad Destinatario</FormLabel>

                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'cityAddressName'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Select
                                            className="input-icon-city"
                                            {...register('cityAddressName', {
                                                required: true,
                                            })}
                                            value={cityAddressName}
                                            required
                                            isInvalid={!!errors['cityAddressName']}
                                            disabled={stateAddressName === ''}
                                            onChange={(e) => {
                                                const selectedName = e.target.value

                                                const selectedCity = cities.find(
                                                    (city) => city.Nombre === selectedName
                                                )
                                                if (selectedCity) {
                                                    clearErrors('cityAddressName')
                                                    const selectedId = selectedCity.Id
                                                    setCityAddressId(selectedId)
                                                }

                                                setCityAddressName(selectedName)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('cityAddressName', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    clearErrors('cityAddressName')
                                                }
                                                onBlur()
                                            }}
                                        >
                                            <FormOption disabled value="">
                                                Selecciona una opción
                                            </FormOption>
                                            {cities.length > 0 ? (
                                                cities.map((option: State) => (
                                                    <FormOption
                                                        key={option.Id}
                                                        value={option.Nombre}
                                                    >
                                                        {option.Nombre}
                                                    </FormOption>
                                                ))
                                            ) : (
                                                <FormOption value="noData" disabled>
                                                    No Data
                                                </FormOption>
                                            )}
                                        </Form.Select>
                                    )}
                                />
                                {errors['cityAddressId'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['cityAddressId']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Banco Destinatario</FormLabel>

                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'bankAddressId'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Select
                                            className="input-icon-building"
                                            {...register('bankAddressId', {
                                                required: true,
                                            })}
                                            value={bankAddressId}
                                            required
                                            isInvalid={!!errors['bankAddressId']}
                                            onChange={(e) => {
                                                const selectedId = e.target.value
                                                const selectedName =
                                                    e.target.options[e.target.selectedIndex]
                                                        ?.text || ''

                                                if (
                                                    e.target.value !== '' ||
                                                    e.target.value === null
                                                ) {
                                                    clearErrors('bankAddressId')
                                                }
                                                setBankAddressId(selectedId)
                                                setBankAddressName(selectedName)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('bankAddressId', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    clearErrors('bankAddressId')
                                                }
                                                onBlur()
                                            }}
                                        >
                                            <FormOption disabled value="">
                                                Selecciona una opción
                                            </FormOption>
                                            {banks.map((option: Bank) => (
                                                <FormOption
                                                    key={option.tcbIdInterno}
                                                    value={option.tcbIdInterno}
                                                >
                                                    {option.tcbNombre}
                                                </FormOption>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                {errors['bankAddressId'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['bankAddressId']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Tipo de Cuenta Destinatario</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'typeAccountAddress'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Select
                                            className="input-icon-piggy-bank "
                                            {...register('typeAccountAddress', {
                                                required: true,
                                            })}
                                            value={typeAccountAddress}
                                            required
                                            isInvalid={!!errors['typeAccountAddress']}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value !== '' ||
                                                    e.target.value === null
                                                ) {
                                                    clearErrors('typeAccountAddress')
                                                }
                                                setTypeAccountAddress(e.target.value)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('typeAccountAddress', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    clearErrors('typeAccountAddress')
                                                }
                                                onBlur()
                                            }}
                                        >
                                            <FormOption disabled value="">
                                                Selecciona una opción
                                            </FormOption>
                                            {optionsTypeAccount.map((option) => (
                                                <FormOption key={option.value} value={option.value}>
                                                    {option.label}
                                                </FormOption>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                {errors['typeAccountAddress'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['typeAccountAddress']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Número de Cuenta Destinatario</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'accountNumberAddressee'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-briefcase"
                                            type="text"
                                            {...register('accountNumberAddressee', {
                                                required: true,
                                            })}
                                            value={accountNumberAddressee}
                                            placeholder="Escribe el número de cuenta del destinatario"
                                            required
                                            isInvalid={!!errors['accountNumberAddressee']}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9.]/g, '')
                                                if (e.target.value !== '') {
                                                    if (value.length > 20) {
                                                        setError('accountNumberAddressee', {
                                                            message:
                                                                'Este campo debe contener un máximo de 20 caracteres',
                                                        })
                                                    } else {
                                                        clearErrors('accountNumberAddressee')
                                                    }

                                                    setAccountNumberAddressee(value)
                                                } else {
                                                    setAccountNumberAddressee('')
                                                    setError('accountNumberAddressee', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                setAccountNumberAddressee(value)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('accountNumberAddressee', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['accountNumberAddressee'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['accountNumberAddressee']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} xs={12}>
                            <FormLabel>Descripción o Concepto del giro</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={description}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Form.Control
                                            type="textarea"
                                            as={'textarea'}
                                            maxLength={500}
                                            rows={3}
                                            value={description}
                                            {...register('description', {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    if (e.target.value.length > 80) {
                                                        setError('description', {
                                                            message:
                                                                'Este campo debe contener un máximo de 80 caracteres',
                                                        })
                                                    } else {
                                                        clearErrors('description')
                                                    }
                                                } else {
                                                    setError('description', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                setDescription(e.target.value)
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('description', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } /* else {
                                                    clearErrors('description')
                                                } */
                                                onBlur()
                                            }}
                                            isInvalid={!!errors[description]}
                                            required
                                        />
                                    )}
                                />
                                {errors[description] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors[description]?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Valor del giro</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'amountTransferFormat'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-amount-transfer"
                                            type="text"
                                            {...register('amountTransferFormat', {
                                                required: true,
                                            })}
                                            value={amountTransferFormat}
                                            placeholder="Ingresa el valor que deseas girar"
                                            required
                                            isInvalid={!!errors['amountTransferFormat']}
                                            onChange={(e) => {
                                                const min = 50000
                                                const numericValue = e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ''
                                                )
                                                setAmountTransfer(numericValue)
                                                if (e.target.value !== '') {
                                                    setAmountTransferFormat(e.target.value)
                                                    clearErrors('amountTransferFormat')
                                                    const value = parseFloat(numericValue)
                                                    const regex = /^[0-9,\b$]+$/
                                                    const isValid = regex.test(e.target.value)
                                                    if (!isValid) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'Por favor ingresar solo dígitos numéricos',
                                                        })
                                                    } else if (value < min) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'El monto mínimo es de $50.000 diarios',
                                                        })
                                                    }
                                                    if (value.toString().length > 8) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'Este campo debe contener un  máximo de 8 caracteres',
                                                        })
                                                    }
                                                    setAmountTransferFormat(formatter.format(value))
                                                } else {
                                                    setAmountTransferFormat('')
                                                    setError('amountTransferFormat', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                setIsButtonEnabled(isFormValid())
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('amountTransferFormat', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else {
                                                    setIsButtonEnabled(isFormValid())
                                                }

                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['amountTransferFormat'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['amountTransferFormat']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </FromFai>

                <ContainerButtons>
                    <ModalCancelButton
                        variant="primary"
                        onClick={() => history.push('/home-wallet')}
                    >
                        Cancelar
                    </ModalCancelButton>
                    <ModalContinueButton
                        variant="primary"
                        disabled={!isButtonEnabled}
                        onClick={ValidationTransfer}
                    >
                        Siguiente
                    </ModalContinueButton>
                </ContainerButtons>
            </Content>
            <ModalLoading
                isLoading={isLoading}
                textInfo="Aguarda un momento, estamos validando la información"
                textLoading={'Estamos procesando tu solicitud'}
            />

            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
        </Wrapper>
    )
}

export default FirsStep
