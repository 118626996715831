import { FC, useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
    PpalTitle,
    Wrapper,
    Content,
    ModalButton,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
    FormSelect,
    CancelButton,
} from '../../CreditSimulation-styles'
import { MessageContent } from '../../../PossessionsInsert/inc/CommonFields/CommonFields-Styles'
import { TextP } from '../../../ActivateInternationalCard/activateInternationalCard-Styles'
import {
    useGetCreditSimulator,
    usePostPaymentPlanSimulator,
    usePostRateFeeSimulator,
} from '../../hooks/useCreditSimulation'
import ModalLoading from '../../../ActivateInternationalCard/inc/ModalInfo/ModalLoading'

interface Producto {
    MontoMinimo: number
    MontoMaximo: number
    PlazoMaximo: number
    ProductoNombre: string
    TasaEfectiva: number
}

interface RowData {
    periodicity: string
    limit: string
    amount: string
}

interface CalculatedValue {
    monthlyRate: number
    quota: number
}

interface DataToCalculateProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
}

interface CalculateProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
    rowId: number
}

interface LoanDataValuesProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
    valor_prestamo: number
    tasaN: number
}

interface PaymentPlanProps {
    Cuota: number
    ValorCuota: number
    AbonoCapital: number
    Interes: number
    Saldo: number
}

interface StepProps {
    tokenSave: string
    redirection: (value: string) => void
    formatter: (value: number) => string
    goToPaymentPlan: (
        loanValues: LoanDataValuesProps[],
        planValues: PaymentPlanProps[],
        rowsCount: number
    ) => void
}

const InitialRowsData: RowData[] = [
    { periodicity: '', limit: '', amount: '' },
    { periodicity: '', limit: '', amount: '' },
    { periodicity: '', limit: '', amount: '' },
    { periodicity: '', limit: '', amount: '' },
]

const CalculateCredit: FC<StepProps> = ({
    tokenSave,
    redirection,
    formatter,
    goToPaymentPlan,
}): JSX.Element => {
    const methods = useForm()
    const { handleSubmit, control, formState, setError, clearErrors, register, setValue } = methods
    const { errors } = formState

    const [isLoading, setIsLoading] = useState(false)
    const { postRateFeeSimulator } = usePostRateFeeSimulator(tokenSave)
    const { postPaymentPlanSimulator } = usePostPaymentPlanSimulator(tokenSave)
    const [validTotal, setValidTotal] = useState(true)
    const [totalErrorMessage, setTotalErrorMessage] = useState('')

    const [allProducts, setAllProducts] = useState<Producto[]>([])
    const [productOptions, setProductOptions] = useState<string[]>([])
    const [product, setProduct] = useState('')
    const [effectiveRate, setEffectiveRate] = useState('')
    const [minLoan, setMinLoan] = useState('')

    const [loanValue, setLoanValue] = useState('')

    const [isCalculated, setIsCalculated] = useState(false)
    const [validatePlan, setValidatePlan] = useState(false)

    const [rowsData, setRowsData] = useState<RowData[]>(InitialRowsData)
    const [calculatedValues, setCalculatedValues] = useState<CalculatedValue[]>([])
    const [paymentPlanValues, setPaymentPlanValues] = useState<PaymentPlanProps[]>([])
    const [loanDataValues, setLoanDataValues] = useState<LoanDataValuesProps[]>([])

    const handleRowInputChange = (index: number, fieldName: string, value: string): void => {
        const updatedRowsData = [...rowsData]
        updatedRowsData[index] = { ...updatedRowsData[index], [fieldName]: value }
        setRowsData(updatedRowsData)
    }

    useEffect(() => {
        GetCreditSimulator()
    }, [])

    useEffect(() => {
        const productsName: string[] = allProducts.map((product) => product.ProductoNombre)
        setProductOptions(productsName)
    }, [allProducts])

    const handleProductSelection = (selectedProduct: string): void => {
        const selectedProductInfo = allProducts.find(
            (product) => product.ProductoNombre === selectedProduct
        )

        if (selectedProductInfo) {
            const { TasaEfectiva, MontoMinimo } = selectedProductInfo
            setEffectiveRate(TasaEfectiva.toString())
            setMinLoan(MontoMinimo.toString())
        }
    }

    const HandleFormSubmit = async (): Promise<void> => {
        const validRows = rowsData.filter((row) => {
            return row.periodicity !== '' && row.limit !== '' && row.amount !== ''
        })

        let montoTotal = 0
        validRows.forEach((row) => {
            montoTotal = montoTotal + parseInt(row.amount)
        })

        if (parseInt(loanValue) >= parseInt(minLoan)) {
            setValidTotal(true)
            if (montoTotal === parseInt(loanValue)) {
                setCalculatedValues([])
                setIsLoading(true)

                const apiCalls = validRows.map((row) => {
                    const newData: any = {
                        tasaEM: parseFloat(effectiveRate),
                        periocidad: parseInt(row.periodicity),
                        numero_coutas: parseInt(row.limit),
                        monto: parseInt(row.amount),
                    }
                    console.log('NEW DATA: ', newData)
                    return postRateFeeSimulator(newData)
                })

                try {
                    console.log('apiCalls: ', apiCalls)
                    const responses = await Promise.all(apiCalls)

                    const newCalculatedValues = responses.map((response: any) => ({
                        monthlyRate: response.data.Data.TasaMensual,
                        quota: response.data.Data.Cuota,
                    }))

                    console.log('newCalculatedValues', newCalculatedValues)
                    setCalculatedValues(newCalculatedValues)
                    setIsLoading(false)
                    setValidTotal(true)
                    setIsCalculated(true)
                } catch (error) {
                    console.error(error)
                    setIsLoading(false)
                    setValidTotal(false)
                    setTotalErrorMessage('Error al calcular el préstamo, inténtalo de nuevo')
                }
            } else {
                setValidTotal(false)
                setTotalErrorMessage('La suma de los "monto" debe ser igual al valor de préstamo')
            }
        } else {
            setValidTotal(false)
            setTotalErrorMessage(
                `El valor de préstamo debe ser mayor de ${formatter(parseInt(minLoan))}`
            )
        }
    }

    const HandlePaymentPlan = async () => {
        setPaymentPlanValues([])
        setLoanDataValues([])
        setIsLoading(true)

        const validRows = rowsData.filter((row) => {
            return row.periodicity !== '' && row.limit !== '' && row.amount !== ''
        })

        try {
            const newDataArray: DataToCalculateProps[] = validRows.map((row, index) => {
                return {
                    tasaEM: parseFloat(effectiveRate),
                    periocidad: parseInt(row.periodicity),
                    numero_coutas: parseInt(row.limit),
                    monto: parseInt(row.amount),
                }
            })

            console.log('newDataArray', newDataArray)

            const response = await postPaymentPlanSimulator(newDataArray)

            if ((response as any)?.data?.Response === '200') {
                const allPaymentsPlan = (response as any).data.Data

                const newLoanDataArray: LoanDataValuesProps[] = validRows.map((row, index) => ({
                    tasaEM: parseFloat(effectiveRate),
                    periocidad: parseInt(row.periodicity),
                    numero_coutas: parseInt(row.limit),
                    monto: parseInt(row.amount),
                    valor_prestamo: parseInt(loanValue),
                    tasaN: calculatedValues[index]?.monthlyRate || 0,
                }))

                setIsLoading(false)
                setLoanDataValues(newLoanDataArray)
                setPaymentPlanValues(allPaymentsPlan)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error en la llamada al endpoint:', error)
        }

        setValidatePlan(true)
    }

    useEffect(() => {
        if (validatePlan) {
            console.log(validatePlan)
            const validRows = rowsData.filter((row) => {
                return row.periodicity !== '' && row.limit !== '' && row.amount !== ''
            })

            const rowsCount = validRows.length
            goToPaymentPlan(loanDataValues, paymentPlanValues, rowsCount)
        }
    }, [validatePlan])

    const GetCreditSimulator = (): void => {
        setIsLoading(true)
        useGetCreditSimulator(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    setAllProducts(res.data.Data)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <>
            <PpalTitle>
                Simulador <span>crédito</span>
            </PpalTitle>
            <Wrapper>
                <FormProvider {...methods}>
                    <Content>
                        <MessageContent>
                            <p>
                                Ingrese los valores en los campos, cuando termine haga clic en
                                "Calcular".
                            </p>
                        </MessageContent>

                        <TableContent>
                            <div>
                                <Row>
                                    <Col md={4}>
                                        <TextP>Producto</TextP>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'Product'}
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <FormSelect>
                                                        <Form.Select
                                                            className="selectFromControl"
                                                            onChange={(e) => {
                                                                if (e.target.value !== '') {
                                                                    clearErrors('Product')
                                                                    handleProductSelection(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                setProduct(e.target.value)
                                                                onChange(e)
                                                            }}
                                                            onBlur={(e) => {
                                                                if (
                                                                    e.target.value === '' ||
                                                                    e.target.value === null
                                                                ) {
                                                                    setError('Product', {
                                                                        message:
                                                                            'Este campo es obligatorio',
                                                                    })
                                                                } else {
                                                                    clearErrors('Product')
                                                                }
                                                                onBlur()
                                                            }}
                                                            value={value}
                                                            isInvalid={!!errors['Product']}
                                                        >
                                                            <option value="">Selecciona...</option>
                                                            {productOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </FormSelect>
                                                )}
                                            />
                                            {errors['Product'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['Product']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <TextP>Tasa efectiva %</TextP>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'EffectiveRate'}
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="hide-number-arrows"
                                                        type="number"
                                                        {...register('EffectiveRate', {
                                                            required: false,
                                                        })}
                                                        maxLength={10}
                                                        value={effectiveRate}
                                                        readOnly={true}
                                                        required
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <TextP>Valor de préstamo $</TextP>
                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'LoanValue'}
                                                rules={{ required: 'Este campo es requerido' }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="hide-number-arrows"
                                                        type="number"
                                                        {...register('LoanValue', {
                                                            required: true,
                                                        })}
                                                        max={10}
                                                        maxLength={10}
                                                        value={loanValue}
                                                        required
                                                        isInvalid={!!errors['LoanValue']}
                                                        onChange={(e) => {
                                                            if (e.target.value !== '') {
                                                                clearErrors('LoanValue')
                                                            }
                                                            setLoanValue(e.target.value)
                                                            onChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value === '' ||
                                                                e.target.value === null
                                                            ) {
                                                                setError('LoanValue', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            } else {
                                                                clearErrors('LoanValue')
                                                            }
                                                            onBlur()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors['LoanValue'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['LoanValue']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <TableContainer>
                                <THead>
                                    <RowTableTitle>
                                        <th>Periodicidad</th>
                                        <th>Plazo</th>
                                        <th>Monto $</th>
                                        {isCalculated && (
                                            <>
                                                <th>Tasa nominal</th>
                                                <th>Cuota $</th>
                                            </>
                                        )}
                                    </RowTableTitle>
                                </THead>
                                <TBody>
                                    {rowsData.map((rowData, index) => (
                                        <RowTable key={index}>
                                            <td className="td-field">
                                                <Form.Group>
                                                    <Controller
                                                        control={control}
                                                        name={'Periodicity'}
                                                        rules={{ required: false }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <FormSelect>
                                                                <Form.Select
                                                                    className="selectFromControl"
                                                                    onChange={(e) =>
                                                                        handleRowInputChange(
                                                                            index,
                                                                            'periodicity',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={rowData.periodicity}
                                                                    isInvalid={
                                                                        !!errors['Periodicity']
                                                                    }
                                                                >
                                                                    <option value={''}>
                                                                        Seleccione...
                                                                    </option>
                                                                    <option value={'24'}>
                                                                        Quincenal
                                                                    </option>
                                                                    <option value={'12'}>
                                                                        Mensual
                                                                    </option>
                                                                </Form.Select>
                                                            </FormSelect>
                                                        )}
                                                    />
                                                    {errors['Periodicity'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['Periodicity']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </td>
                                            <td className="td-field">
                                                <Form.Group>
                                                    <Controller
                                                        control={control}
                                                        name={'Limit'}
                                                        rules={{ required: false }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <FormSelect>
                                                                <Form.Select
                                                                    className="selectFromControl"
                                                                    onChange={(e) =>
                                                                        handleRowInputChange(
                                                                            index,
                                                                            'limit',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={rowData.limit}
                                                                    isInvalid={!!errors['Limit']}
                                                                >
                                                                    <option value={''}>
                                                                        Seleccione...
                                                                    </option>
                                                                    {Array.from(
                                                                        { length: 240 },
                                                                        (_, i) => i + 1
                                                                    ).map((value) => (
                                                                        <option
                                                                            key={value}
                                                                            value={value}
                                                                        >
                                                                            {value}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </FormSelect>
                                                        )}
                                                    />
                                                    {errors['Limit'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['Limit']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </td>
                                            <td className="td-field">
                                                <Form.Group>
                                                    <Controller
                                                        control={control}
                                                        name={`Amount[${index}]`}
                                                        rules={{
                                                            required: false,
                                                        }}
                                                        render={({ field }) => (
                                                            <Form.Control
                                                                className="hide-number-arrows"
                                                                type="number"
                                                                {...register(`Amount[${index}]`, {
                                                                    required: false,
                                                                })}
                                                                max={14}
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    handleRowInputChange(
                                                                        index,
                                                                        'amount',
                                                                        e.target.value
                                                                    )
                                                                    setValue(
                                                                        `Amount[${index}]`,
                                                                        e.target.value
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>
                                            </td>
                                            {calculatedValues.length > 0 &&
                                                index < calculatedValues.length &&
                                                isCalculated && (
                                                    <>
                                                        <td className="td-field">
                                                            {calculatedValues[index].monthlyRate}%
                                                        </td>
                                                        <td className="td-field">
                                                            {formatter(
                                                                calculatedValues[index].quota
                                                            )}
                                                        </td>
                                                    </>
                                                )}
                                        </RowTable>
                                    ))}
                                </TBody>
                            </TableContainer>
                            {validTotal === false && (
                                <>
                                    <br></br>
                                    <TextP className="invalid">{totalErrorMessage}</TextP>
                                </>
                            )}
                        </TableContent>

                        <FormProvider {...methods}>
                            <form
                                onSubmit={handleSubmit(HandleFormSubmit)}
                                className="fom-simulator"
                            >
                                <Row>
                                    <Col md={4}>
                                        <ModalButton
                                            type="button"
                                            onClick={handleSubmit(HandleFormSubmit)}
                                        >
                                            Calcular
                                        </ModalButton>
                                    </Col>
                                    {isCalculated && (
                                        <Col md={4}>
                                            <ModalButton type="button" onClick={HandlePaymentPlan}>
                                                Plan de pagos
                                            </ModalButton>
                                        </Col>
                                    )}
                                </Row>
                                <Col>
                                    <CancelButton
                                        type="button"
                                        onClick={() => redirection('/home-wallet')}
                                    >
                                        Regresar
                                    </CancelButton>
                                </Col>
                            </form>
                        </FormProvider>

                        <MessageContent>
                            <p>
                                Nota: Los valores que arroja el simulador son aproximados y de
                                caracter informativo; todos los montos son calculados con base en la
                                actual tasa de interés fijada para el producto. La tasa de interés
                                cambia dependiendo de las condiciones del mercado y las cuotas no
                                incluyen los montos por seguro de vida. Recuerde que usted puede
                                consultar el cupo de sus créditos a través de la opción de Servicios
                                en Línea. Esta información no compromete ni obliga a Cavipetrol
                                frente a sus potenciales usuarios.
                            </p>
                        </MessageContent>
                    </Content>
                </FormProvider>
            </Wrapper>
            <ModalLoading isLoading={isLoading} />
        </>
    )
}

export default CalculateCredit
