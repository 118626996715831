import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { useSelector } from 'react-redux'
import InitialInfo from './inc/InitialInfo/InitialInfo'
import FirstStep from './inc/FirstStep/FirstStep'
import SecondStep from './inc/SecondStep/SecondStep'
import ThirdStep from './inc/ThirdStep/ThirdStep'
import { SpacerContent } from './activateInternationalCard-Styles'
import { BreadcrumbApp } from '../../components'

const ActivateInternationalCard = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [step, setStep] = useState(0)
    const [data, setData] = useState<{ [key: string]: any }>({})
    const [lastFourNumbers, setLastFourNumbers] = useState('')

    const saveLastFourNumbers = (value: string) => {
        setLastFourNumbers(value)
    }

    const saveCurrentData = (receivedData: any) => {
        for (const key in receivedData) {
            setData((prevState) => ({
                ...prevState,
                [key.toString()]: receivedData[key],
            }))
        }
    }

    const handleNextStep = () => {
        setStep(step + 1)
    }

    const history = useHistory()
    const redirections = (url: string): void => {
        history.push(url)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirections('/home-wallet')
            },
        },
        {
            text: 'Productos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Tarjetas de crédito',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Activar tarjeta fuera de Colombia',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <SpacerContent>
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirections('/home-wallet')
                    }}
                />
                <br></br>
                {step === 0 && (
                    <InitialInfo
                        tokenSave={tokenSave}
                        handleNextStep={handleNextStep}
                        redirections={redirections}
                    />
                )}
                {step === 1 && (
                    <FirstStep
                        tokenSave={tokenSave}
                        handleNextStep={handleNextStep}
                        redirections={redirections}
                        saveCurrentData={saveCurrentData}
                        saveLastFourNumbers={saveLastFourNumbers}
                    />
                )}
                {step === 2 && (
                    <SecondStep
                        tokenSave={tokenSave}
                        handleNextStep={handleNextStep}
                        redirections={redirections}
                        saveCurrentData={saveCurrentData}
                    />
                )}
                {step === 3 && (
                    <ThirdStep
                        tokenSave={tokenSave}
                        redirections={redirections}
                        saveCurrentData={saveCurrentData}
                        lastFourNumbers={lastFourNumbers}
                        dataToSend={data}
                    />
                )}
            </SpacerContent>
        </LayoutContent>
    )
}

export default ActivateInternationalCard
