import { FC } from 'react'
// styles
import {
    PpalTitle,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
    TextDecorationTable,
} from '../../BalancesExtracts-styles'

type Products = {
    Acount: string
    Balance: number
    Concept: string
    Description: string
    FinishedNumber: number
    ProductDocumentNumber: number
    ProductDocumentType: string
}

type CreditDataType = {
    Description: string
    ProductDocumentNumber: number
    Products: Products[]
    TotalBalance: number
}

interface CreditProps {
    creditData: CreditDataType[]
    formatter: (value: number) => string
    showCreditDetail: (products: Products[], description: string) => void
}

const CreditTable: FC<CreditProps> = ({ creditData, formatter, showCreditDetail }): JSX.Element => {
    const handleClick = (products: Products[], description: string): void => {
        showCreditDetail(products, description)
    }

    const groupedProducts = creditData
        .flatMap((saving) => saving.Products)
        .reduce(
            (acc, product) => {
                const { ProductDocumentNumber, FinishedNumber, Description, Balance } = product
                const key = `${ProductDocumentNumber}-${FinishedNumber}`

                if (!acc[key]) {
                    acc[key] = {
                        Description,
                        ProductDocumentNumber,
                        FinishedNumber,
                        Balance: 0,
                        Products: [],
                    }
                }

                acc[key].Balance += Balance
                acc[key].Products.push(product)

                return acc
            },
            {} as Record<
                string,
                {
                    Description: string
                    ProductDocumentNumber: number
                    FinishedNumber: number
                    Balance: number
                    Products: Products[]
                }
            >
        )

    const groupedProductsArray = Object.values(groupedProducts)

    return (
        <TableContent>
            <PpalTitle>Créditos</PpalTitle>
            <TableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Producto</th>
                        <th>Número</th>
                        <th>Saldo</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    {groupedProductsArray.map((group, index) => (
                        <RowTable key={index}>
                            <TextDecorationTable>
                                <a onClick={() => handleClick(group.Products, group.Description)}>
                                    {group.Description}
                                </a>
                            </TextDecorationTable>
                            <td>
                                {group.ProductDocumentNumber}-{group.FinishedNumber}
                            </td>
                            <td>{formatter(group.Balance)}</td>
                        </RowTable>
                    ))}
                </TBody>
            </TableContainer>
        </TableContent>
    )
}

export default CreditTable
