/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useHistory } from 'react-router'
import { useForm, FieldValues, DeepPartial, Resolver } from 'react-hook-form'
import * as yup from 'yup'
import { CampaignFormService, avanceKeyCard } from '../../../../../domain/services/User.service'

export async function useCampaignUpdateFiles(files: File[], tokenSave: string) {
    try {
        return await CampaignFormService.postUploadFilesCampaign(
            'api/DebtPurchase/CampaignUploadFiles',
            files,
            tokenSave
        ).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function useGetCoreData(tokenSave: string) {
    try {
        const CoreData = { RequestSourceType: 'Campaign' }
        return await CampaignFormService.getCoreData(
            `api/DebtPurchaseProcess/CreateDebtPurchaseCore?RequestSourceType=${CoreData.RequestSourceType}`,
            tokenSave
        ).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function useServiceTerm(
    tokenSave: string,
    IdCreditCardRequest: number,
    RequestStep: number
) {
    try {
        return await avanceKeyCard
            .postSendCodeOtpTCForgot(
                `/api/CreditCardRequest/SaveComplementationInfoCampaign?IdCreditCardRequest=${IdCreditCardRequest}&RequestStep=${RequestStep}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
// validation schema
export const thirteenthStepSchema = yup
    .object({
        formatFile: yup.mixed().required('Archivo requerido'),
    })
    .required()

// type definitions
export type ThirteenthStepType = yup.InferType<typeof thirteenthStepSchema>

interface IUseThirteenthStepArgs<F extends FieldValues = ThirteenthStepType> {
    defaultValues?: DeepPartial<F | ThirteenthStepType>
    validationSchema?: Resolver<F | ThirteenthStepType>
}

export const useThirteenthStepForm = <TFieldValues extends FieldValues = ThirteenthStepType>({
    defaultValues,
    validationSchema,
}: IUseThirteenthStepArgs<TFieldValues>) => {
    const history = useHistory()

    const { register, handleSubmit, getValues, setValue, setError, clearErrors, control, watch } =
        useForm<TFieldValues | ThirteenthStepType>({
            mode: 'all',
            resolver: validationSchema,
            defaultValues,
        })

    const redirection = (url: string, state?: Record<string, string | number>): void => {
        history.push(url, state)
    }

    return {
        handleSubmit,
        register,
        getValues,
        setValue,
        setError,
        clearErrors,
        control,
        watch,
        redirection,
    }
}
