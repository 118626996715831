import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp, Loading, Modal } from '../../components'

import {
    FirstStepContent,
    SpaceStep,
    FormLabel2,
    ButtonsContainer,
    ButtonContinue,
    CancelButton,
    Title,
    ImgContent,
} from './Certificate-Styles'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useGenerateCertRetentionPdf } from './hooks/useCertificate'
import { Col, Form, ModalBody, Row } from 'react-bootstrap'
import ModalInfo from '../CreditDetail/inc/ModalInfo/ModalInfo'
import 'moment/locale/es'
import { IconoCertificado } from '../../utils/getImages'
import { ContainerPossessions } from '../CupsTermsQuotas/CupsTermsQuotas-styles'
import TitleModule from './inc/TitleModule/TitleModule'
import { useGetInfoAssociate } from '../InterbankTransfer/hooks/useInterbankTransfer'

const Certificate = (): JSX.Element => {
    moment.locale('es')
    const date = moment().format('YYYY-MM-DD')
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [textTitleModal, setTextTitleModal] = useState<string>('')
    const [textBodyModal, setTextBodyModal] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)

    const [base64String, setBase64String] = useState<string>('')
    const [errorService, setErrorService] = useState(false)
    const [associatedDocumentNumber, setAssociatedDocumentNumber] = useState<string>('')

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Servicios en Línea',
            active: false,
        },
        {
            text: 'Servicios',
            active: false,
        },
        {
            text: 'Certificado Retención',
            active: true,
        },
    ]

    useEffect(() => {
        GenerateCertRetentionPdf()
        GetInfoAssociate()
    }, [tokenSave])

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }

    const GetInfoAssociate = (): void => {
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setAssociatedDocumentNumber(res.data.Data[0].Document)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const fileName = `Certificado_${associatedDocumentNumber}_${date}.pdf`

    const handlePrintWindow = (): void => {
        // Convertir base64 a Blob
        const byteCharacters = atob(base64String)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/pdf' })

        const url = URL.createObjectURL(blob)

        const printWindow = window.open(url)

        // Esperar a que la ventana de impresión esté cargada y luego imprimir
        printWindow?.addEventListener('load', () => {
            printWindow.document.title = 'Certificado Retención'
            printWindow?.print()
        })
    }

    const downloadPDF = (): void => {
        console.log('fileName', fileName)
        const byteCharacters = atob(base64String)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/pdf' })

        // Crear objeto de ventana
        const downloadWindow = window.open('')

        // Crear objeto enlace en la ventana
        const downloadLink = downloadWindow?.document.createElement('a')
        if (downloadLink) {
            downloadLink.href = URL.createObjectURL(blob)
            downloadLink.download = fileName

            // Agregar el enlace
            downloadWindow?.document.body.appendChild(downloadLink)
            downloadLink.click()

            // Eliminar el enlace  después de la descarga
            downloadWindow?.document.body.removeChild(downloadLink)

            // Cerrar la nueva ventana después de iniciar la descarga
            downloadWindow?.close()
        } else {
            console.error('No se pudo crear el enlace de descarga en la nueva ventana.')
        }
    }

    const GenerateCertRetentionPdf = (): void => {
        setIsLoading(true)
        useGenerateCertRetentionPdf(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setIsLoading(false)
                    const base64StringRes = res.data.Data
                    setBase64String(base64StringRes)
                    console.log(base64StringRes, 'base64 de respuesta imprimir')
                } else {
                    setIsLoading(false)
                    setShowModal(true)
                    setErrorService(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'En este momento no podemos procesar tu consulta, por favor intenta más tarde.'
                    )
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setShowModal(true)
                setErrorService(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'Ha ocurrido un error en el servidor, por favor intenta más tarde.'
                )
            })
    }

    return (
        <>
            <LayoutContent>
                <ContainerPossessions>
                    <br />
                    <BreadcrumbApp
                        breadcrumbs={breadcrumbs}
                        onBack={() => {
                            redirection('/home-wallet')
                        }}
                    />
                    <TitleModule />
                    <FirstStepContent>
                        <Form>
                            <Row>
                                <Col md={3}>
                                    <ImgContent>
                                        <img
                                            src={IconoCertificado}
                                            className="logo-table"
                                            alt="IconoCertificado"
                                        />
                                    </ImgContent>
                                </Col>
                                <Col md={9}>
                                    <Title>
                                        Certificado de <span>ingresos y retenciones</span>
                                    </Title>
                                    <FormLabel2>
                                        Apreciado asociado para abrir el certificado digita el
                                        número de tu cédula cuando se te solicite ingresar la
                                        contraseña.
                                    </FormLabel2>
                                </Col>
                            </Row>
                            <SpaceStep />
                            <SpaceStep />
                        </Form>
                        {!errorService && (
                            <ButtonsContainer>
                                <CancelButton onClick={() => handlePrintWindow()}>
                                    Imprimir certificado
                                </CancelButton>

                                <ButtonContinue onClick={() => downloadPDF()}>
                                    Descargar certificado
                                </ButtonContinue>
                            </ButtonsContainer>
                        )}
                    </FirstStepContent>
                </ContainerPossessions>
                <Modal
                    className="loading-screen"
                    show={isLoading}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <ModalBody>
                        <Loading text="Estamos creando tu documento" />
                        <p style={{ textAlign: 'center' }}>
                            Espera, no actualices o cierres esta página
                        </p>
                    </ModalBody>
                </Modal>
            </LayoutContent>
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleModalCloseInfo}
            />
        </>
    )
}

export default Certificate
