import tw, { styled } from 'twin.macro'

export const ContainerTableProduct = styled.table`
    ${tw`flex mb-[20px] py-[15px] px-0`}
    .right-text {
        text-align: end;
    }

    td {
        padding: 5px;
    }

    th {
        padding: 5px;
    }

    @media (max-width: 500px) {
        ${tw`flex overflow-x-scroll`}
    }
`
