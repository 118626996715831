import React from 'react'
import { components } from '@reactour/tour'

// styled components
import { CloseButton } from './close-styles'
import { useSelector } from 'react-redux'
import { useSaveOnboardingState } from '../../hooks/useOnboarding'
import { useLocation } from 'react-router-dom'

type CloseProps = React.ComponentProps<typeof components.Close>

const Close = ({ onClick, disabled }: CloseProps): React.ReactElement => {
    const location = useLocation()
    const locationText = location.pathname

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const handleClick = (): void => {
        let tourData = {}

        if (locationText === '/home-wallet') {
            tourData = {
                Tour: '1',
                State: true,
            }
        } else if (
            locationText === '/savings-group' ||
            locationText === '/savings-detail' ||
            locationText === '/payments'
        ) {
            tourData = {
                Tour: '2',
                State: true,
            }
        } else if (locationText === '/credits-group' || locationText === '/credits-detail') {
            tourData = {
                Tour: '3',
                State: true,
            }
        } else if (locationText === '/product-opening' || locationText === '/tdc-opening') {
            tourData = {
                Tour: '4',
                State: true,
            }
        } else if (locationText === '/paymentsfai' || locationText === '/multi-payments-tdc') {
            tourData = {
                Tour: '5',
                State: true,
            }
        } else if (locationText === '/credit-card-detail') {
            tourData = {
                Tour: '6',
                State: true,
            }
        }

        SaveOnboardingState(tourData)
        onClick?.()
    }

    const SaveOnboardingState = (tourData: any): void => {
        useSaveOnboardingState(tourData, tokenSave)
            .then((res: any) => {
                console.log(res)
                if (res['status'] === 200) {
                    console.log(res)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <CloseButton variant="link" onClick={handleClick} disabled={disabled}>
            Saltar tutorial
        </CloseButton>
    )
}

export default Close
