import { useState } from 'react'
import { ImagePreview } from '../../ManageableContent-styled'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { Col } from 'react-bootstrap'
import { TitleContent } from '../../../PossessionsInsert/inc/CommonFields/CommonFields-Styles'
import {
    ImageContainerWrapper,
    ImageUploadContainer,
    ImageUploadPrompt,
} from '../../../PossessionsInsert/inc/BuildingsForm/BuildingForm-Styles'
import { useUpdateImages } from '../../hooks/useManageContent'
import { useSelector } from 'react-redux'
import {
    ButtonCanceled,
    ContainerBtn,
    DivContainer,
} from '../../../UserOperations/UserOperations-styled'
import { Button, ModalGeneric } from '../../../../components'

interface DataUpdateImagesProps {
    Id: number
    FileBase64: string
    Name: string
}

interface UploadImageAppProps {
    id: number
    name: string
    closed: () => void
    successUpload: () => void
}

const UploadImage: React.FC<UploadImageAppProps> = ({
    id,
    name,
    closed,
    successUpload,
}): JSX.Element => {
    const [base64Image, setBase64Image] = useState<string>('')
    const [base64ImageWithoutHeader, setBase64ImageWithoutHeader] = useState<string>('')

    const [dataUpdateImage, setDataUpdateImage] = useState<DataUpdateImagesProps[]>([])
    const [showModalForm, setShowModalForm] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
    const [messageModal, setMessageModal] = useState<string>('')
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [isFinishProcess, setIsFinishProcess] = useState<boolean>(false)

    const handleDrop = async (acceptedFiles: FileWithPath[]) => {
        if (acceptedFiles.length !== 1) {
            setShowModalForm(true)
            setMessageModal('Solo se puede cargar una imagen a la vez.')
            return
        }

        try {
            const file = acceptedFiles[0]
            const reader = new FileReader()

            reader.onload = () => {
                const base64data = reader.result
                if (typeof base64data === 'string') {
                    setBase64Image(base64data)
                    const base64ImageWithoutHeader = removeBase64Header(base64data)
                    setBase64ImageWithoutHeader(base64ImageWithoutHeader)
                }
            }

            reader.readAsDataURL(file)
        } catch (error) {
            setShowModalForm(true)
            setMessageModal('Error al cargar la imagen')
        }
    }

    const removeBase64Header = (base64data: string): string => {
        const headerIndex = base64data.indexOf(';base64,')
        if (headerIndex !== -1) {
            return base64data.slice(headerIndex + 8)
        }
        return base64data
    }

    const imageDropzone = useDropzone({
        accept: 'image/*' as any,
        onDrop: handleDrop,
    })

    const pushDataVerifyName = (id: number, name: string, base64: string): void => {
        const newValue = { Id: id, FileBase64: base64, Name: name }

        setDataUpdateImage((previewData) => [...previewData, newValue])
        HandleFormSubmitUpdate([...dataUpdateImage, newValue])
    }

    const HandleFormSubmitUpdate = (data: DataUpdateImagesProps[]): void => {
        setIsLoadingUpdate(true)
        useUpdateImages(tokenSave, data)
            .then((res: any) => {
                setDataUpdateImage([])
                setIsLoadingUpdate(false)
                if (res['status'] === 200 && res['data']['Data'] === true) {
                    setShowModalForm(true)
                    setMessageModal('La imagen ha sido cargada éxitosamente.')
                    setIsFinishProcess(true)
                } else {
                    setShowModalForm(true)
                    setMessageModal(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModalForm(true)
                setDataUpdateImage([])
                setMessageModal('No se ha podido conectar con el servidor')
            })
    }

    const handleClose = (): void => {
        setShowModalForm(false)
        if (isFinishProcess) {
            successUpload()
            closed()
        }
    }

    return (
        <>
            <Col md={12}>
                <TitleContent>Carga tu imágen</TitleContent>
            </Col>
            <ImageUploadContainer {...imageDropzone.getRootProps()}>
                <input {...imageDropzone.getInputProps()} />
                <ImageUploadPrompt>
                    Arrastra aquí tu imagen o haz clic para seleccionar tu imagen
                </ImageUploadPrompt>
                <ImageContainerWrapper>
                    {base64Image && <ImagePreview src={base64Image} alt="Imagen" />}
                </ImageContainerWrapper>
            </ImageUploadContainer>
            <DivContainer>
                <ButtonCanceled variant="primary" onClick={closed}>
                    Cancelar
                </ButtonCanceled>

                <ContainerBtn>
                    <Button
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={() => {
                            pushDataVerifyName(id, name, base64ImageWithoutHeader)
                        }}
                        isLoading={isLoadingUpdate}
                        extend
                        disabled={base64Image === ''}
                    >
                        Subir Imagen
                    </Button>
                </ContainerBtn>
            </DivContainer>
            <ModalGeneric
                show={showModalForm}
                handleClose={handleClose}
                textTitle=""
                textBody={messageModal}
                handleButton={handleClose}
                textButton="Aceptar"
            />
        </>
    )
}

export default UploadImage
