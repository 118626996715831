import { ModalBody } from 'react-bootstrap'
import { Loading, Modal } from '../../../../components'

interface CreditDetailModalProps {
    isLoading: boolean
}

const ModalLoading: React.FC<CreditDetailModalProps> = ({ isLoading }): JSX.Element => {
    return (
        <Modal
            className="loading-screen"
            show={isLoading}
            backdrop="static"
            keyboard={false}
            centered
        >
            <ModalBody>
                <Loading text="Aguarda un momento" />
                <p style={{ textAlign: 'center' }}>No actualices o cierres esta página</p>
            </ModalBody>
        </Modal>
    )
}

export default ModalLoading
