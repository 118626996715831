import { useEffect, useState } from 'react'
import DynamicKeyInput from '../DynamicKeyInput/DynamicKeyInput'
import {
    Wrapper,
    Content,
    TextSteps,
    TextSubtitle,
    TextInfo,
    CancelButton,
    ButtonContinue,
    ButtonsContainer,
} from './DynamicKeyContainer-Styled'
import { useSelector } from 'react-redux'
import { useSendOTP } from '../../../../hooks/useCreditDetail'
import { useHistory } from 'react-router-dom'
import { Loading, ModalGeneric } from '../../../../../../components'
import { NotProductSVG } from '../../../../../../utils/getIcons'
import { useSendCodeOtpTCForgot } from '../../hooks/useAdvanceKeyCard'

interface DynamicKeyContainerProps {
    stepNumber: string
    nextStepNumber: string
    onNextStep: () => void
    cellphone: string
    email: string
    buttonText?: string
    IsForgetAdvanceKey: boolean
    lastFourDigits?: string
}

const DynamicKeyContainer: React.FC<DynamicKeyContainerProps> = ({
    stepNumber,
    onNextStep,
    nextStepNumber,
    cellphone,
    email,
    buttonText,
    IsForgetAdvanceKey,
    lastFourDigits = '',
}) => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [isLoadingTime, setIsLoadingTime] = useState(true)
    const [isValidateCode, setValidateCode] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const history = useHistory()

    useEffect(() => {
        if (lastFourDigits === '') {
            SendOtp()
        } else {
            SendOtpChangeKey()
        }
    }, [tokenSave])

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const handleNext = (): void => {
        onNextStep()
    }

    const SendOtp = (): void => {
        useSendOTP(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res['Message'])
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    const SendOtpChangeKey = (): void => {
        useSendCodeOtpTCForgot(lastFourDigits, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res['Message'])
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    const handleLoading = (value: boolean): void => {
        setIsLoadingTime(value)
    }

    const handleValidate = (value: boolean): void => {
        setValidateCode(value)
    }

    return (
        <Wrapper>
            <Content>
                <TextSteps>
                    Paso {stepNumber} de {nextStepNumber}
                </TextSteps>
                {!IsForgetAdvanceKey && (
                    <TextInfo>
                        Por seguridad debemos validar tu identidad, ingresa la clave dinámica que
                        recibiste al celular terminado en {cellphone} y a tu correo electrónico{' '}
                        {email}
                    </TextInfo>
                )}
                {IsForgetAdvanceKey && (
                    <TextInfo>
                        Por seguridad debemos validar tu identidad, ingresa el código de seguridad
                        que recibiste al celular SMS terminado en {cellphone} y a tu correo
                        electrónico {email}
                    </TextInfo>
                )}
                {!IsForgetAdvanceKey && <TextSubtitle>Clave dinámica</TextSubtitle>}
                {IsForgetAdvanceKey && <TextSubtitle>Código de seguridad</TextSubtitle>}
                <DynamicKeyInput
                    tw="text-left justify-start"
                    tokenSave={tokenSave}
                    handleLoading={handleLoading}
                    handleValidate={handleValidate}
                    IsForgetAdvanceKey={IsForgetAdvanceKey}
                    lastFourDigits={lastFourDigits}
                />
                {isValidateCode && (
                    <>
                        {!IsForgetAdvanceKey && <Loading text="Validando clave dinámica." />}
                        {IsForgetAdvanceKey && <Loading text="Validando código de seguridad." />}
                    </>
                )}
                <ButtonsContainer>
                    <CancelButton onClick={() => history.push('/home-wallet')}>
                        Cancelar
                    </CancelButton>
                    <ButtonContinue disabled={isLoadingTime} onClick={handleNext}>
                        {buttonText}
                    </ButtonContinue>
                </ButtonsContainer>
            </Content>

            <ModalGeneric
                show={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleButton={handleModalCloseInfo}
                handleClose={handleModalCloseInfo}
                textButton="Aceptar"
            />
        </Wrapper>
    )
}

export default DynamicKeyContainer
