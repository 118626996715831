import { FC, useState } from 'react'
import { ArrowCircleSVG } from '../../../../utils/getIcons'
import { Form, Row, Col, OverlayTrigger } from 'react-bootstrap'
import {
    PpalTitle,
    ArrowBlack,
    SpacerContent,
    Wrapper,
    Content,
    TextP,
    ButtonsContainer,
    ModalCancelButton,
    ModalButton,
} from '../../KeyChange-styles'
import { Popover } from '../../../../components'
import { DigitKeyboardTDC } from '../../../MultiPaymentsTDC/inc'
import { useMediaQuery } from 'usehooks-ts'
import { useForm } from 'react-hook-form'
import { useValidateIVR } from '../../hooks/useKeyChange'
import ModalLoading from '../Modals/ModalLoading'
import ModalError from '../Modals/ModalError'
// styles

interface StepProps {
    tokenSave: string
    handleNextStep: (value: string) => void
    redirection: (value: string) => void
}

const IvrPass: FC<StepProps> = ({ tokenSave, handleNextStep, redirection }): JSX.Element => {
    const methods = useForm()
    const { getValues, formState, setError, register } = methods
    const { errors } = formState

    const matchMedia = useMediaQuery('(min-width: 767px)')

    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)
    const [passIVR, setPassIVR] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onFocusInput = (): void => {
        setShowKeyboard(true)
    }

    const handleSeeKeyboard = (): void => {
        setShowKeyboard(false)
    }

    const handleInputDigit = (inputChanged: string): void => {
        setPassIVR(inputChanged)
    }

    const ValidateIVR = (): void => {
        setIsLoading(true)
        useValidateIVR(tokenSave, passIVR)
            .then((res: any) => {
                setIsLoading(false)
                const responseCode = res.data?.Response || res.Response

                if (responseCode === '200') {
                    const ivr = 'ivr'
                    handleNextStep(ivr)
                } else {
                    setError('PassIVR', {
                        message: res.Message,
                    })
                    setErrorMessage(res.Message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setError('PassIVR', {
                    message: 'Clave IVR inválida',
                })
                setErrorMessage(error)
                setShowError(true)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
    }
    const handleOnChangeDigitMobile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        setPassIVR(value)
    }

    return (
        <SpacerContent>
            <ArrowBlack onClick={() => redirection('/')}>
                <img src={ArrowCircleSVG} alt="black" />
                <p>Volver</p>
            </ArrowBlack>

            <Wrapper>
                <Content>
                    <PpalTitle>
                        Cambia tu <span>clave</span>
                    </PpalTitle>

                    <TextP>Ingresa tu clave IVR para continuar</TextP>

                    <Row>
                        <Col md={3}>
                            <TextP>
                                <span>Estás a punto de cambiar tu clave de acceso al portal</span>
                            </TextP>
                        </Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={8}>
                            <TextP>Clave IVR</TextP>
                            <OverlayTrigger
                                show={showKeyboard}
                                placement="right"
                                overlay={
                                    <Popover noSpace>
                                        <DigitKeyboardTDC
                                            onMouseLeave={handleSeeKeyboard}
                                            onChange={handleInputDigit}
                                            defaultValue={getValues('PassIVR')}
                                        />
                                    </Popover>
                                }
                            >
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="hide-number-arrows"
                                        type="password"
                                        {...register('PassIVR', {
                                            required: true,
                                        })}
                                        value={passIVR}
                                        required
                                        isInvalid={!!errors['PassIVR']}
                                        onFocus={matchMedia ? onFocusInput : () => void 0}
                                        onChange={handleOnChangeDigitMobile}
                                    />
                                </Form.Group>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <ButtonsContainer>
                        <ModalCancelButton
                            variant="primary"
                            onClick={() => redirection('/home-wallet')}
                        >
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton type="button" onClick={ValidateIVR}>
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </Content>
                <ModalLoading isLoading={isLoading} />
                <ModalError
                    showModal={showError}
                    handleClose={handleClose}
                    titleMessage={'Clave inválida'}
                    errorMessage={errorMessage}
                />
            </Wrapper>
        </SpacerContent>
    )
}

export default IvrPass
