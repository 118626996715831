import { LogoMetroCuadrado } from '../../../../utils/getImages'
import { ImgForm } from './ImageForm-styles'

const ImageForm = (): JSX.Element => {
    return (
        <>
            <ImgForm>
                <img src={LogoMetroCuadrado} className="logo-table" alt="LogoMetroCuadrado" />
            </ImgForm>
        </>
    )
}

export default ImageForm
