import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ContainerData, LayoutContent } from './DataUpdate-styles'
import PersonalInformation from './inc/PersonalInformation'
import { useHistory } from 'react-router-dom'
import ContactInformation from './inc/ContactInformation'
import FinancialInformation from './inc/FinancialInformation'
import TitleModule from './inc/TitleModule'
import { BreadcrumbApp } from '../../components'
import {
    citiesByDepartment,
    fetchCountryCrm,
    getListUpdateData,
    getUnicTablesCrm,
    getUpdateDataAssociate,
} from './hooks/useDataUpdate'
import Loading from './inc/Loading'
import DeclarationsAuthorizations from './inc/DeclarationsAuthorizations'
import DynamicKeyValidation from './inc/DynamicKeyValidation/DynamicKeyValidation'
import {
    Cities,
    CityAndDepartment,
    Country,
    ListDataCitiesList,
    ListDataCrm,
    ListDataMoneyList,
    ListDataOptionsCommons,
    Options,
    UserData,
} from '../../../../domain/models/crm'
import DeclarationOfPoliticalExposedPerson from './inc/DeclarationOfPoliticalExposedPerson'
import ModalError from './inc/ModalError'

const DataUpdate = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [step, setStep] = useState<number>(0)
    const [loadingAssociate, setLoadingAssociate] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')
    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }
    const [userData, setUserData] = useState<UserData | null>(null)
    const [listData, setListData] = useState<{
        MoneyList: ListDataMoneyList[]
        CitiesList: ListDataCitiesList[]
        ActivitiesList: ListDataOptionsCommons[]
        CountriesList: ListDataOptionsCommons[]
    }>({
        MoneyList: [],
        CitiesList: [],
        ActivitiesList: [],
        CountriesList: [],
    })

    const [listDataCrm, setListDataCrm] = useState<{
        LaborRegime: ListDataCrm[]
        ContractType: ListDataCrm[]
        Patronal: ListDataCrm[]
        MaritalStatus: ListDataCrm[]
        EducationLevel: ListDataCrm[]
        Occupation: ListDataCrm[]
        SocioeconomicStratum: ListDataCrm[]
        DocumentTypes: ListDataCrm[]
        IdentificationType: ListDataCrm[]
        TransferMode: ListDataCrm[]
        ROI: ListDataCrm[]
        HasBeenReAffiliated: ListDataCrm[]
        AffiliationType: ListDataCrm[]
        ContinuityType: ListDataCrm[]
        Substitute: ListDataCrm[]
        SubstituteType: ListDataCrm[]
        DeceasedPensionerDocumentType: ListDataCrm[]
        AssociateRole: ListDataCrm[]
        ServerCity: ListDataCrm[]
        PayrollType: ListDataCrm[]
        Gender: ListDataCrm[]
        HeadOfHouseholdMother: ListDataCrm[]
        PoliticallyExposedPerson: ListDataCrm[]
        StatementDelivery: ListDataCrm[]
        AssetZoning: ListDataCrm[]
    }>({
        LaborRegime: [],
        ContractType: [],
        Patronal: [],
        MaritalStatus: [],
        EducationLevel: [],
        Occupation: [],
        SocioeconomicStratum: [],
        DocumentTypes: [],
        IdentificationType: [],
        TransferMode: [],
        ROI: [],
        HasBeenReAffiliated: [],
        AffiliationType: [],
        ContinuityType: [],
        Substitute: [],
        SubstituteType: [],
        DeceasedPensionerDocumentType: [],
        AssociateRole: [],
        ServerCity: [],
        PayrollType: [],
        Gender: [],
        HeadOfHouseholdMother: [],
        PoliticallyExposedPerson: [],
        StatementDelivery: [],
        AssetZoning: [],
    })

    const [departments, setDepartments] = useState<Options[]>([])
    const [allData, setAllData] = useState<CityAndDepartment[]>([])
    const [countries, setCountries] = useState<Country[]>([])

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Actualización de datos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    const handleNextStep = (stepValue: string): void => {
        if (stepValue === 'dynamicKey') {
            setStep(0)
        } else if (stepValue === 'personalInfo') {
            setStep(1)
        } else if (stepValue === 'contactInfo') {
            setStep(2)
        } else if (stepValue === 'financialInfo') {
            setStep(3)
        } else if (stepValue === 'declarationOfPoliticalExposed') {
            setStep(4)
        } else if (stepValue === 'declarationsAuthorizations') {
            setStep(5)
        }
    }

    const GetAssociate = (): void => {
        setLoadingAssociate(true)
        getUpdateDataAssociate(tokenSave)
            .then((res: any) => {
                const message = res.data?.Message || res.Message
                if (res['status'] === 200) {
                    const userDataFromService = res.data.Data

                    setUserData(userDataFromService)

                    setLoadingAssociate(false)
                } else {
                    setLoadingAssociate(false)
                    setShowModal(true)
                    setMessageError(message)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoadingAssociate(false)
                setShowModal(true)
                setMessageError(
                    'No se ha podido conectar con el servidor, por favor intente más tarde.'
                )
            })
    }

    const GetListUpdateData = (): void => {
        setLoadingAssociate(true)
        getListUpdateData(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    const listDataFromService = res.data.Data

                    setListData(listDataFromService)

                    setLoadingAssociate(false)
                } else {
                    setLoadingAssociate(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoadingAssociate(false)
            })
    }

    const GetUnicTablesCrm = (): void => {
        setLoadingAssociate(true)
        getUnicTablesCrm(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    const listDataCrmFromService = res.data.Data

                    setListDataCrm((prevData) => ({
                        ...prevData,
                        LaborRegime: listDataCrmFromService['Regimen laboral'],
                        ContractType: listDataCrmFromService['Tipo de contrato'],
                        Patronal: listDataCrmFromService['Patronal'],
                        DocumentTypes: listDataCrmFromService['Tipo de documento'],
                        IdentificationType: listDataCrmFromService['Tipo de identificación'],
                        MaritalStatus: listDataCrmFromService['Estado Civil'],
                        EducationLevel: listDataCrmFromService['Nivel educativo'],
                        Occupation: listDataCrmFromService['Ocupación (new_ocpcn)'],
                        SocioeconomicStratum: listDataCrmFromService['Estrato (new_strt)'],
                        TransferMode: listDataCrmFromService['Modalidad de Transferencia'],
                        ROI: listDataCrmFromService['ROI'],
                        HasBeenReAffiliated:
                            listDataCrmFromService['¿Ha estado revinculado? (new_rvncld)'],
                        AffiliationType:
                            listDataCrmFromService['Tipo de vinculación-ha sido parte del fondo'],
                        ProcedureType: listDataCrmFromService['Tipo de trámite'],
                        ContinuityType: listDataCrmFromService['Tipo de continuidad'],
                        Substitute: listDataCrmFromService['Sustituto'],
                        SubstituteType: listDataCrmFromService['Tipo de sustituto'],
                        DeceasedPensionerDocumentType:
                            listDataCrmFromService['Tipo de documento del pensionado fallecido'],
                        AssociateRole: listDataCrmFromService['Papel del asociado'],
                        ServerCity: listDataCrmFromService['Ciudad del Servidor (new_cddsrvdr)'],
                        PayrollType: listDataCrmFromService['Tipo de nómina'],
                        Gender: listDataCrmFromService['Sexo'],
                        HeadOfHouseholdMother:
                            listDataCrmFromService['Madre cabeza de hogar (new_mdrcbzdhgr)'],
                        PoliticallyExposedPerson:
                            listDataCrmFromService['Persona públicamente expuesta'],
                        StatementDelivery:
                            listDataCrmFromService['Envío de extracto (new_envdxtrct)'],
                        AssetZoning:
                            listDataCrmFromService['Zonificación de activos (new_znfccnctvs)'],
                    }))

                    setLoadingAssociate(false)
                } else {
                    setLoadingAssociate(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoadingAssociate(false)
            })
    }

    const handlePersonalDataUpdate = (updatedData: Partial<UserData>): void => {
        setUserData((prevData) => (prevData ? { ...prevData, ...updatedData } : prevData))
    }

    const handleContactDataUpdate = (updatedData: Partial<UserData>): void => {
        setUserData((prevData) => (prevData ? { ...prevData, ...updatedData } : prevData))
    }

    const handleFinancialDataUpdate = (updatedData: Partial<UserData>): void => {
        setUserData((prevData) => (prevData ? { ...prevData, ...updatedData } : prevData))
    }

    useEffect(() => {
        GetAssociate()
        GetListUpdateData()
        GetUnicTablesCrm()
        GetDepartments()
        GetCountries()
    }, [])

    const handleClose = (): void => {
        history.push('/home-wallet')
        setShowModal(false)
    }

    const GetDepartments = (): void => {
        citiesByDepartment(tokenSave)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response
                if (responseCode === '200') {
                    const data: CityAndDepartment[] = res.data.Data

                    setAllData(data)

                    const uniqueDepartments: Options[] = Array.from(
                        new Set<string>(data.map((item) => item.Deparment))
                    )
                        .map((department: string) => ({ label: department, value: department }))
                        .sort((a, b) => a.label.localeCompare(b.label))

                    setDepartments(uniqueDepartments)
                } else {
                    console.log('Error')
                }
            })
            .catch((error: any) => {
                console.error(error)
            })
    }

    const fetchCitiesByDepartment = (departmentName: string): Cities[] => {
        return allData
            .filter((item) => item.Deparment === departmentName)
            .map((item) => ({
                label: item.City,
                value: item.City,
                department: item.Deparment,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    const GetCountries = (): void => {
        fetchCountryCrm(tokenSave)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response
                if (responseCode === '200') {
                    const data: Country[] = res.data.Data

                    setCountries(data)
                } else {
                    console.log('Error')
                }
            })
            .catch((error: any) => {
                console.error(error)
            })
    }

    return (
        <LayoutContent>
            <ContainerData>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (step > 0) {
                            setStep(step - 1)
                        } else {
                            redirection('/home-wallet')
                        }
                    }}
                />
                <TitleModule />
                {step === 0 && <DynamicKeyValidation handleNextStep={handleNextStep} />}
                {loadingAssociate && <Loading />}
                {userData && (
                    <>
                        {step === 1 && (
                            <PersonalInformation
                                userData={userData}
                                educationList={listDataCrm.EducationLevel}
                                maritalStatusList={listDataCrm.MaritalStatus}
                                occupationList={listDataCrm.Occupation}
                                contractTypesList={listDataCrm.ContractType}
                                laborRegimeList={listDataCrm.LaborRegime}
                                onUpdate={handlePersonalDataUpdate}
                                handleNextStep={handleNextStep}
                            />
                        )}

                        {step === 2 && (
                            <ContactInformation
                                userData={userData}
                                handleNextStep={handleNextStep}
                                onUpdate={handleContactDataUpdate}
                                countriesList={countries}
                                stratumList={listDataCrm.SocioeconomicStratum}
                                patronalList={listDataCrm.Patronal}
                                departmentList={departments}
                                fetchCities={fetchCitiesByDepartment}
                            />
                        )}
                        {step === 3 && (
                            <FinancialInformation
                                userData={userData}
                                handleNextStep={handleNextStep}
                                onUpdate={handleFinancialDataUpdate}
                                activitiesList={listData.ActivitiesList}
                                countriesList={countries}
                                moneyList={listData.MoneyList}
                                occupationList={listDataCrm.Occupation}
                            />
                        )}
                        {step === 4 && (
                            <DeclarationOfPoliticalExposedPerson
                                userData={userData}
                                handleNextStep={handleNextStep}
                                onUpdate={handleFinancialDataUpdate}
                            />
                        )}
                        {step === 5 && (
                            <DeclarationsAuthorizations
                                handleNextStep={handleNextStep}
                                userData={userData}
                            />
                        )}
                    </>
                )}
            </ContainerData>
            <ModalError
                showModalOpenError={showModal}
                titleError={'Error'}
                textError={messageError}
                closeModalError={handleClose}
            />
        </LayoutContent>
    )
}

export default DataUpdate
