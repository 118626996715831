import { FC } from 'react'
// styles
import {
    ButtonsContainer,
    CancelButton1,
    PDFButton,
    PDFMessageContainer,
    PDFText,
    PpalTitle,
    ProductTableContainer,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
} from '../../BalancesExtracts-styles'
import { Row } from 'react-bootstrap'
import { DownloadDoc } from '../../../../utils/getIcons'
import { ContainerTableProduct } from './ProductDetail-styles'

type ProductDataType = {
    ProductTypeDocument: string
    ProductDocumentNumber: number
    FinishedNumber: number
    Dateuse: string
    Valueuse: number
    CapitalBalance: number
    Fee: number
    MissingFee: number
    PaymentMethod: string
    Rate: number
}

interface MovementsProps {
    productData: ProductDataType[]
    formatter: (value: number) => string
    redirection: (value: string) => void
    handlePrintPDF: () => void
    changeStep: (value: string) => void
}

const ProductDetail: FC<MovementsProps> = ({
    productData,
    formatter,
    redirection,
    handlePrintPDF,
    changeStep,
}): JSX.Element => {
    const formatDate = (fecha: string) => {
        const fechaOriginal = new Date(fecha)
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0')
        const dia = fechaOriginal.getDate().toString().padStart(2, '0')
        const año = fechaOriginal.getFullYear().toString()
        return `${mes}/${dia}/${año}`
    }

    return (
        <>
            <PpalTitle>
                <span>Detalle del producto</span>
            </PpalTitle>
            <PpalTitle>Detalle préstamo</PpalTitle>
            <ContainerTableProduct>
                <ProductTableContainer className="table-responsive">
                    <THead>
                        <RowTableTitle>
                            <th>Fecha utilización</th>
                            <th>Valor utilizado</th>
                            <th>Saldo capital</th>
                            <th>Cuota</th>
                            <th>Cuotas faltantes</th>
                            <th>Tasa de interés % (EA)</th>
                            <th>Modalidad de pago</th>
                        </RowTableTitle>
                    </THead>
                    <TBody>
                        {productData.map((product, index) => (
                            <RowTable key={index}>
                                <td>{formatDate(product.Dateuse)}</td>
                                <td>{formatter(product.Valueuse)}</td>
                                <td>{formatter(product.CapitalBalance)}</td>
                                <td>{formatter(product.Fee)}</td>
                                <td>{product.MissingFee}</td>
                                <td>{product.Rate * 100}</td>
                                <td>{product.PaymentMethod}</td>
                            </RowTable>
                        ))}
                    </TBody>
                </ProductTableContainer>
            </ContainerTableProduct>
            <PDFMessageContainer>
                <PDFButton onClick={handlePrintPDF}>
                    <Row>
                        <PDFText>
                            <img src={DownloadDoc} alt="downloadDoc" className="icon_size" />{' '}
                            Consulte aquí el estado de cuenta resumido de sus productos financieros
                        </PDFText>
                    </Row>
                </PDFButton>
            </PDFMessageContainer>
            <ButtonsContainer>
                <CancelButton1 onClick={() => changeStep('BalanceByProduct')}>
                    Regresar
                </CancelButton1>
            </ButtonsContainer>
        </>
    )
}

export default ProductDetail
