import { AxiosResponse } from 'axios'
import { CertificateRetention } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGenerateCertRetentionPdf(tokenSave: string): Promise<Result> {
    try {
        return await CertificateRetention.getCertificateData(
            '/api/PdfGenerate/GenerateCertRetentionPdf',
            tokenSave
        ).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
