import tw, { styled } from 'twin.macro'

export const DivContainer = styled.div`
    ${tw`w-72`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }

    @media (max-width: 600px) {
        ${tw`h-26`}
    }

    popover-arrow {
        top: -72px !important;
    }
`

export const InputContainer = styled.div`
    ${tw`w-72 h-24`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`
export const SectionKeyboard = styled.div`
    ${tw`w-56`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`
export const ButtonRequest = styled.button`
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--sub-dominant-color) !important;

    button:disabled {
        color: red !important;
    }
`

export const SectionText = styled.div`
    ${tw` relative`}

    white-space: nowrap;
    display: -webkit-box !important;

    @media (max-width: 600px) {
        white-space: normal;
    }
`

export const CodeInputStyle = styled.input`
    ${tw`w-12 h-12 border-4 rounded bg-transparent outline-none text-center font-bold text-xl border-solid border-gray-custom-300 opacity-50 focus:border[1px solid #f5a50b] focus:text-gray-700 text-gray-400 transition`}

    border-width: 1px;
    margin: 10px;
    font-weight: 700 !important;
    margin-right: '10px';
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        ${tw`gap-5`}
    }

    @media (max-width: 500px) {
        ${tw`w-10 h-10 m-0.5 p-0 text-xl`}
    }

    input:focus {
        box-shadow: 0 0 0 0.25rem var(--sub-dominant-color) !important;
    }

    input:hover {
        box-shadow: 0 0 0 0.25rem var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color);
    }
`
