import tw, { styled } from 'twin.macro'

export const OptionsContainerDetail = styled.table`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] w-full flex`}
    span {
        color: #f5a50b;
    }

    .text {
        ${tw`font-montserrat font-bold lg:text-base text-[20px] color[var(--dominant-color-dark)]`}
        text-align:center;
    }
    table {
        margin: 0 auto;
        width: 900px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .text1 {
        ${tw`font-montserrat font-bold`}
    }
    .text2 {
        ${tw`font-montserrat font-bold `}
        text-align:center;
    }
    th,
    td {
        padding: 20px;
        height: 60px;
        border: 1px solid black;
    }

    /* Estilos para dispositivos móviles (ancho de 352px a 550px) */
    @media (max-width: 550px) {
        table {
            display: flex;
            margin-left: 50px;
        }

        /* Aplica estilos específicos para las filas (esto podría variar según tus necesidades) */
        table tr {
            display: block;
            margin-bottom: 10px;
        }

        /* Aplica estilos específicos para las celdas (esto podría variar según tus necesidades) */
        table td {
            display: block;
            text-align: center;
        }
        .text {
            ${tw`font-montserrat font-bold lg:text-base text-[18px] color[var(--dominant-color-dark)]`}
        }
    }
`

export const ContainerPossessions = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
