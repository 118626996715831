import tw, { styled } from 'twin.macro'
import {
    FormLabel as FormLabelApp,
    FormGroup as FormGroupApp,
    Button,
} from '../../../../components/includes'

export const ContainerPossessionsConsult = styled.section`
    ${tw`mt-11 py-6 px-10 `}
`
export const SpaceStep = styled.div`
    ${tw`w-full h-1.5`}
`
export const SpaceStep2 = styled.div`
    ${tw`w-full h-3.5`}
`
export const TitleForm = styled.p`
    .title {
        ${tw`m-0 text-dominant text-[22px] font-montserrat font-bold`}
    }

    @media (min-width: 1180px) {
        .title {
            ${tw`text-2xl`}
        }
    }
`

export const FirstStepContent = styled.div`
    ${tw`p-12.5 lg:px-10 w-full max-w-[550px] lg:max-w-[600px] rounded-[10px] shadow-[0px 3px 6px #00000029] `}
`

export const InputGroup2 = styled.div`
    p {
        ${tw`!m-0`}
    }

    .radio {
        ${tw`relative`}

        .form-check-input {
            ${tw`!w-[19px] !h-[19px] absolute bottom-[-12px]`}
            ${tw`md:rounded-[50%] md:float-left md:ml-[-1.5em] md:!bottom-[-60px]`}
        }
        .form-check-label {
            ${tw`m-0 !p-0 !pl-[12px] font-helvetica font-normal text-sm color[var(--text-opacity5)]`}
            ${tw`md:text-base md:!p-0 md:top-5 md:absolute md:color[var(--dominant-color-dark)]`}
        }
    }
`
export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0 my-2`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}
`

export const FormLabel2 = styled.p`
    ${tw`font-helvetica font-normal block text-black text-lg my-3`}
`
export const ContainerSimulator = styled.section`
    ${tw`lg:pt-0 lg:pb-0.5 lg:px-2 lg:min-h-[300px]
    flex justify-center items-center`}
`
export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: space-between !important;
`

export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (max-width: 460px) {
        ${tw`my-auto mx-0 w-[300px] h-[50px] rounded-[5px] font-normal font-montserrat`}
        background-color: #fff !important;
        border-color: var(--sub-dominant-color) !important;
        color: var(--sub-dominant-color) !important;

        &:hover {
            background-color: var(--sub-dominant-color) !important;
            border-color: transparent !important;
            color: #fff !important;
        }

        &:focus {
            outline: none;
        }

        &:active {
            border: 2px solid transparent;
        }
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`
export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }

    @media (max-width: 460px) {
        ${tw`my-2.5 mx-0 w-[300px] h-[50px] color[#000000] rounded-[5px] font-normal font-montserrat`}

        &:hover {
            background-color: #ffffff;
            color: #000;
        }

        /* margin-left: 20px; */
        background-color: #ffffff;
        border-color: #000000 !important;
    }
`
