import createSelector from '../createSelector'
import type { StoreApp } from '../../redux/store/store.interface'

export const loggedUserRoleSelector = createSelector(
    (state: StoreApp) => state.loggedUserRoleReducer,
    (role) => {
        return {
            loggedInUser: role.loggedUserRole,
            loadingRole: role.loading,
            errorRole: role.error,
        }
    }
)

export const loggedUserRoleExistSelector = createSelector(
    (state: StoreApp) => state.loggedUserRoleReducer,
    ({ loggedUserRole, loading, error }) => {
        return {
            loggedUserRole,
            loading,
            error,
        }
    }
)
