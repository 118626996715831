import { FC } from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext, Controller } from 'react-hook-form'
import { FormSelect } from '../CommonFields/CommonFields-Styles'

interface Field {
    amenity: string
    name: string
    restrictions: string
    required: boolean
}

interface FormFieldProps {
    field: Field
}

export const formatFieldName = (name: string): string => {
    if (name === undefined) {
        // Handle error, perhaps return a default value or throw an error
        return ''
    }

    const wordDictionary: Record<string, string> = {
        nroAscensores: 'N° Ascensores',
        nroCuartos: 'N° Cuartos',
        nroBanos: 'N° Baños',
        nroGarajes: 'N° Garajes',
        areaConstruida: 'Área Construida',
        area: 'Área',
        instalacionGas: 'Instalación Gas',
        terrazaBalcon: 'Terraza Balcón',
        areaTerraza: 'Área Terraza',
        conZonaLavanderia: 'Zona Lavandería',
        nroInterior: 'N° Interior',
        nroPiso: 'N° Piso',
        conCitofono: 'Citófono',
        nroApto: 'N° Apto',
        nroClosets: 'N° Closets',
        conVistaPanoramica: 'Vista Panorámica',
        conCanchaFutbol: 'Cancha Fútbol',
        conCalefaccion: 'Calefacción',
        matricula: 'Matrícula',
        conZonaNinos: 'Zona Para Niños',
        conZonaBbq: 'Zona BBQ',
        conSalonComunal: 'Salón Comunal',
        tipoDeposito: 'Tipo Depósito',
        sobreViaPrincipal: 'Sobre Vía Principal',
        sobreViaSecundaria: 'Sobre Vía Secundaria',
        cercaTransportePublico: 'Cerca Transporte Público',
        conPisoMadera_o: 'Piso de Madera',
        conPisoAlfombra_o: 'Piso de Alfombra',
        conPisoBaldosa_o: 'Piso de Baldosa',
        conPisoPorcelanato_o: 'Piso Porcelanato',
        conCocinaIntegral_o: 'Cocina Integral',
        conCocinaAmericana_o: 'Cocina Americana',
        conBanoServicio_o: 'Baño de Servicio',
        conParqueaderoVisitantes_o: 'Parqueadero Visitantes',
        conTerraza_o: 'Terraza',
        conJaulaGolf_o: 'Jaula de Golf',
        banosAuxiliar: 'Baño Auxiliar',
        caracteristicasParqueadero: 'Características Parqueadero',
        porteria: 'Portería',
        aptoParaNinos: 'Apto Para Niños',
        video: 'Vídeo',
        'conVigilancia-cb': 'Vigilancia',
        'conCuartoUtil-cb': 'Cuarto Útil',
        conCircuitoCerradoTV: 'Circuito Cerrado de TV',
        areaLote: 'Área Lote',
        nroLineasTelefonicas: 'N° Líneas Telefónicas',
        nroNiveles: 'N° Niveles',
        conJardin: 'Jardín',
        nroOficina: 'N° Oficina',
        conCocineta_o: 'Cocineta',
        calificacionOficina: 'Calificación Oficina',
        banosPublicos: 'Baños Públicos',
        conPlantaElectrica_o: 'Planta eléctrica',
        enLinderoRioQuebrada_o: 'En Lindero, Río o Quebrada',
        loteVacio: 'Lote Vacío',
        nroConsultorio: 'N° Consultorio',
        nroLocal: 'N° Local',
        restrictedHeight: 'Altura Restringida',
        areaRural: 'Área Rural',
        areaUrbana: 'Área Urbana',
        nroBodega: 'N° Bodega',
        conAccesoDiscapacitados: 'Acceso Para Discapacitados',
        ubicadoCetroComercial: 'Ubicado Centro Comercial',
        conTanquesAgua_o: 'Con Tanques de agua',
        cercaColegioUniversidad: 'Cerca Colegio/Universidad',
        conCuartoEscoltas: 'Cuarto Escoltas',
        conCanchaBasket: 'Cancha Basket',
        conEstudioBiblioteca: 'Estudio Biblioteca',
        conCortinas: 'Cortinas',
        conCuartoServicio: 'Cuarto Servicio',
        conChimenea: 'Chimenea',
        conComedorAuxiliar: 'Comedor Auxiliar',
        conAireAcondicionado: 'Aire Acondicionado',
        conHall: 'Hall',
        conJacuzzi: 'Jacuzzi',
        conAlarma: 'Alarma',
        conPiscina: 'Piscina',
        conPuertasSeguridad: 'Puertas Seguridad',
        conCanchaTenis: 'Cancha Tenis',
        conCanchaSquash: 'Cancha Squash',
        conSauna: 'Sauna',
        conZonasVerdes: 'Zonas verdes',
        conJardinInterior: 'Jardín Interior',
        conGarajeCubierto: 'Garaje Cubierto',
        conSenderoPeatonal: 'Sendero Peatonal',
        conGimnasio: 'Gimnasio',
        'conPuertaTractomulas-cb': 'Puerta Tractomulas',
    }

    let formattedName = wordDictionary[name] || name
    const blankSpaceValidator = /(\s{1,})/g

    if (!blankSpaceValidator.test(formattedName)) {
        formattedName = formattedName.replace(/([A-Z])/g, ' $1').trim()
        formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1)
    }
    return formattedName
}

const FieldForm: FC<FormFieldProps> = ({ field }) => {
    const { amenity, name, restrictions, required } = field
    const { control, formState, clearErrors } = useFormContext()
    const { errors } = formState
    const { setError } = useFormContext()
    console.log('amenity', amenity)
    console.log('control', control)
    console.log('required', required)
    let validateRule = {}

    if (
        !restrictions.startsWith('[') &&
        restrictions.includes('-') &&
        !restrictions.includes('|')
    ) {
        const [min] = restrictions.split('-')
        const [, max] = restrictions.split('-')
        // max = Math.floor(Number(max)).toString() // Redondeamos hacia abajo el valor máximo
        validateRule = {
            validate: (value: string) => {
                if ((!value || value.trim() === '') && !required) {
                    return true // Si el campo no es requerido y el valor es vacío, devolvemos true
                }
                return (
                    (Number(value) >= Number(min) && Number(value) <= Number(max)) ||
                    `solo valores entre ${min} y ${max}`
                )
            },
        }
    } else if (restrictions.startsWith('[') && restrictions.endsWith(']+')) {
        const regex = new RegExp(restrictions)
        validateRule = {
            validate: (value: string) => {
                if (
                    ((!value || value.trim() === '') && !required) ||
                    (value.trim() !== '' && !required)
                ) {
                    return true // Si el campo no es requerido y el valor es vacío, devolvemos true || Si no es requerido, el valor es dif de vacío y cumple con el regex
                }
                return regex.test(value) || 'El valor no cumple con el formato requerido'
            },
        }
    }

    if (restrictions === 'true|false') {
        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    defaultValue=""
                    rules={{ required: required ? 'Este campo es requerido' : false }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                            <Form.Check
                                inline
                                label="Sí"
                                name={amenity}
                                type="radio"
                                value="true"
                                checked={value === 'true'}
                                onChange={(e) => {
                                    if (required && e.target.value !== '') {
                                        clearErrors(amenity)
                                    }
                                    onChange(e)
                                }}
                                onBlur={(e) => {
                                    if (
                                        required &&
                                        (e.target.value === '' || e.target.value === null)
                                    ) {
                                        setError(amenity, { message: 'Este campo es obligatorio' })
                                    } else {
                                        clearErrors(amenity)
                                    }
                                    onBlur()
                                }}
                                ref={ref}
                            />
                            <Form.Check
                                inline
                                label="No"
                                name={amenity}
                                type="radio"
                                value="false"
                                checked={value === 'false'}
                                onChange={(e) => {
                                    if (required && e.target.value !== '') {
                                        clearErrors(amenity)
                                    }
                                    onChange(e)
                                }}
                                onBlur={(e) => {
                                    if (
                                        required &&
                                        (e.target.value === '' || e.target.value === null)
                                    ) {
                                        setError(amenity, { message: 'Este campo es obligatorio' })
                                    } else {
                                        clearErrors(amenity)
                                    }
                                    onBlur()
                                }}
                                ref={ref}
                            />
                        </>
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    }

    if (amenity.includes('description')) {
        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    rules={{ required }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                            type="textarea"
                            as={'textarea'}
                            maxLength={500}
                            rows={3}
                            value={value || ''}
                            onChange={(e) => {
                                if (required && e.target.value !== '') {
                                    clearErrors(amenity)
                                }
                                onChange(e)
                            }}
                            onBlur={(e) => {
                                if (
                                    required &&
                                    (e.target.value === '' || e.target.value === null)
                                ) {
                                    setError(amenity, { message: 'Este campo es obligatorio' })
                                } else {
                                    clearErrors(amenity)
                                }
                                onBlur()
                            }}
                            isInvalid={!!errors[amenity]}
                            required
                        />
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    }

    if (restrictions.includes('|')) {
        const options = restrictions.split('|')

        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    rules={{ required }}
                    render={({ field: { onChange, value, onBlur } }) => (
                        <FormSelect>
                            <Form.Select
                                className="selectFromControl"
                                onChange={(e) => {
                                    if (required && e.target.value !== '') {
                                        clearErrors(amenity)
                                    }
                                    onChange(e)
                                }}
                                onBlur={(e) => {
                                    if (
                                        required &&
                                        (e.target.value === '' || e.target.value === null)
                                    ) {
                                        setError(amenity, { message: 'Este campo es obligatorio' })
                                    } else {
                                        clearErrors(amenity)
                                    }
                                    onBlur()
                                }}
                                value={value}
                                isInvalid={!!errors[amenity]}
                            >
                                <option value="">Selecciona...</option>
                                {options.map((option: string, index: number) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </FormSelect>
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    } else if (
        restrictions.includes('1-3') ||
        restrictions.includes('1-4') ||
        restrictions.includes('1-5') ||
        restrictions.includes('1-6')
    ) {
        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    rules={{ required }}
                    render={({ field: { onChange, value, onBlur } }) => (
                        <FormSelect>
                            <Form.Select
                                className="selectFromControl"
                                onChange={(e) => {
                                    if (required && e.target.value !== '') {
                                        clearErrors(amenity)
                                    }
                                    onChange(e)
                                }}
                                onBlur={(e) => {
                                    if (
                                        required &&
                                        (e.target.value === '' || e.target.value === null)
                                    ) {
                                        setError(amenity, { message: 'Este campo es obligatorio' })
                                    } else {
                                        clearErrors(amenity)
                                    }
                                    onBlur()
                                }}
                                value={value}
                                isInvalid={!!errors[amenity]}
                            >
                                <option value="">Selecciona...</option>
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                {restrictions.includes('1-4') && <option value={'4'}>4</option>}
                                {restrictions.includes('1-5') && (
                                    <>
                                        <option value={'4'}>4</option>
                                        <option value={'5'}>5</option>
                                    </>
                                )}
                                {restrictions.includes('1-6') && (
                                    <>
                                        <option value={'4'}>4</option>
                                        <option value={'5'}>5</option>
                                        <option value={'6'}>6</option>
                                    </>
                                )}
                            </Form.Select>
                        </FormSelect>
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    } else if (amenity.includes('video')) {
        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    rules={{ required, ...validateRule }}
                    render={({ field: { onChange, value, onBlur } }) => (
                        <Form.Control
                            type="text"
                            value={value || ''}
                            onChange={(e) => {
                                if (required && e.target.value !== '') {
                                    clearErrors(amenity)
                                }
                                onChange(e)
                            }}
                            onBlur={(e) => {
                                if (
                                    required &&
                                    (e.target.value === '' || e.target.value === null)
                                ) {
                                    setError(amenity, { message: 'Este campo es obligatorio' })
                                } else {
                                    clearErrors(amenity)
                                }
                                onBlur()
                            }}
                            isInvalid={!!errors[amenity]}
                            required
                        />
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    } else {
        return (
            <Form.Group className="mb-3">
                <Controller
                    control={control}
                    name={amenity}
                    rules={{ required, ...validateRule }}
                    render={({ field: { onChange, value, onBlur } }) => (
                        <Form.Control
                            type="text"
                            maxLength={50}
                            value={value || ''}
                            onChange={(e) => {
                                if (required && e.target.value !== '') {
                                    clearErrors(amenity)
                                }
                                onChange(e)
                            }}
                            onBlur={(e) => {
                                if (
                                    required &&
                                    (e.target.value === '' || e.target.value === null)
                                ) {
                                    setError(amenity, { message: 'Este campo es obligatorio' })
                                } else {
                                    clearErrors(amenity)
                                }
                                onBlur()
                            }}
                            isInvalid={!!errors[amenity]}
                            required
                        />
                    )}
                />
                {errors[amenity] && (
                    <Form.Control.Feedback type="invalid">
                        {errors[amenity]?.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        )
    }
}

export default FieldForm
