import React from 'react'
import { PrintSVG } from '../../utils/getIcons'
import { postSecurity } from '../../../http/axiosHttp'
import { useSelector } from 'react-redux'
import { number } from 'yup'

interface ApiResponse {
    data: {
        Data: string
    }
}

interface ProductUserData {
    Cuenta: string
    Periodo: string
    Fecha: string
}

type UserDataInfo = [string, string, string]
type PreviousBalance = string

interface ButtonScreenshotProps {
    userData?: UserDataInfo
    ProductData?: ProductUserData
    PreviuosBalanceData?: PreviousBalance
    finalBalance?: any
    movementsDetail?: any[]
    movementsReceivable?: any[]
    productData?: any
    isProduct?: boolean // Default value set to false
    totalBalance?: number
}

const ButtonScreenshot: React.FC<ButtonScreenshotProps> = ({
    userData,
    ProductData,
    PreviuosBalanceData,
    finalBalance,
    movementsDetail,
    movementsReceivable,
    productData,
    isProduct = false,
    totalBalance,
}) => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const handleGeneratePDF = async () => {
        console.log('pdf', PreviuosBalanceData)
        if (!tokenSave) {
            console.error('Token no disponible')
            alert('No se pudo generar el PDF: Token no disponible.')
            return
        }
        const body = isProduct ? createProductBody() : createGeneralBody()

        try {
            const response = await postSecurity<ApiResponse>(
                'api/PdfGenerate/ExtractsByProduct',
                tokenSave,
                body
            )
            console.log('res', response)
            const base64Data = response?.data?.Data

            if (base64Data) {
                const byteCharacters = atob(base64Data)
                const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
                const byteArray = new Uint8Array(byteNumbers)
                const blob = new Blob([byteArray], { type: 'application/pdf' })

                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'ExtractsByProduct.pdf'
                document.body.appendChild(a)
                a.click()
                a.remove()

                setTimeout(() => window.URL.revokeObjectURL(url), 5000)
            } else {
                console.error('La respuesta no contiene datos válidos.')
            }
        } catch (error) {
            console.error('Error al generar el PDF:', error)
        }
    }

    const createProductBody = () => ({
        DataNoSubTitle: userData,
        ProductUserData: ProductData,
        TablesExtractsList: [
            {
                TitleTable: 'Detalle del producto',
                TitleColumTable: Object.keys(productData[0] || {}),
                DataTable: productData.map((row: any) => Object.values(row)),
            },
        ],
    })

    const formatCurrency = (value: number) => {
        const isNegative = value < 0
        const formattedValue = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Math.abs(value))

        return isNegative ? `-$${formattedValue.slice(1)}` : formattedValue
    }

    const formatDateWithoutSeconds = (dateString: string) => {
        if (!dateString) return 'Sin datos'
        return dateString.split(':').slice(0, 2).join(':') // Toma las partes antes de los segundos
    }

    const createGeneralBody = () => {
        if (!finalBalance || finalBalance.length === 0) {
            console.error('finalBalance está vacío o no definido')
            return
        }

        const filteredData = finalBalance.map((row: any) => {
            const saldo = row.Saldo
            const formattedSaldo = formatCurrency(saldo)
            return [row.Concepto, formattedSaldo]
        })

        return {
            DataNoSubTitle: userData,
            ProductUserData: ProductData,
            TablesExtractsList: [
                {
                    TitleTable: 'Información Saldo',
                    PreviousBalance: formatCurrency(Number(PreviuosBalanceData)),
                    TitleColumTable: ['Saldo final', formatCurrency(Number(totalBalance))],
                    DataTable: filteredData,
                },
                {
                    TitleTable: 'Detalle de Movimiento',
                    TitleColumTable: [
                        'Fecha',
                        'Oficina',
                        'Comprobante',
                        'Clase movimiento',
                        'Valor',
                    ],
                    DataTable: movementsDetail?.map((row: any) => {
                        return [
                            formatDateWithoutSeconds(row.FechaMovimiento || 'Sin datos'),
                            row.VariableDescripcion || 'Sin datos',
                            row.ConsecutiveOperacion?.toString() || 'Sin datos',
                            row.TypeOperation || 'Sin datos',
                            typeof row.Valor === 'number' ? formatCurrency(row.Valor) : 'Sin datos',
                        ]
                    }),
                },
                {
                    TitleTable: 'Movimientos cuenta por cobrar',
                    TitleColumTable: [
                        'Fecha',
                        'Operación',
                        'Operación consecutivo',
                        'Comprobante',
                        'Número Comprobante',
                        'Valor',
                    ],
                    DataTable: movementsReceivable?.map((row: any) => {
                        return [
                            row.DateCXC || 'Sin datos',
                            row.TypeOperation || 'Sin datos',
                            row.ConsecutiveOperacion?.toString() || 'Sin datos',
                            row.Support || 'Sin datos',
                            row.ConsSupport || 'Sin datos',
                            typeof row.ValueCXC === 'number'
                                ? formatCurrency(row.ValueCXC) // Aplica el formateo
                                : 'Sin datos',
                        ]
                    }),
                },
            ],
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '10px',
            }}
        >
            <img src={PrintSVG} alt="Print Icon" style={{ width: '24px', height: '24px' }} />
            <button
                onClick={handleGeneratePDF}
                style={{
                    color: 'blue',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Imprimir
            </button>
        </div>
    )
}

export default ButtonScreenshot
