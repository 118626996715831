import { useDispatch, useSelector } from 'react-redux'
import { CreditCardsSelector } from '../../../../../selectors'
import { getCreditCardsAction } from '../../../../../redux/tc'
import { useEffect, useState } from 'react'
import { TitleComponent, Wrapper } from '../../PaymentScheduleRegistrationTC-Styled'

import { AlertSVG, CheckOperationSVG, MiniCardSVG, NotProductSVG } from '../../../../utils/getIcons'
import { Button, InputSelectGroup, Loading, ModalGeneric } from '../../../../components'
import { Col, Form, Row } from 'react-bootstrap'
import ImageCard from '../ImageCard'
import { useHistory } from 'react-router-dom'
import {
    FormGroup,
    FormLabel,
    FormSelectTC,
    FormOption,
    Image,
    ButtonsContainer,
    CancelButton,
    ButtonContent,
    CardContainer,
    ImgCardContent,
    Content,
    TextInfo,
    FormLabelDateCard,
    TextInstruction,
    AlertContainer,
} from './SecondStep-Styled'
import { CreditCard, GetFaiAccountBalance } from '../../../../../../domain/models'
import { LoadingContainer } from '../../../CreditDetail/creditDetail-styles'
import { useScheduledPaymentFAI } from '../../hooks/usePaymentScheduleRegistrationTC'
import { useGetScheduledPaymentFAI } from '../../../PaymentScheduleCancellationTC/hooks/usePaymentScheduleCancellationTC'

interface SecondStepProps {
    activeFAI: GetFaiAccountBalance
}

const SecondStep: React.FC<SecondStepProps> = ({ activeFAI }) => {
    const history = useHistory()
    const [isSelectTouched, setIsSelectTouched] = useState<boolean>(false)
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(true)
    const dispatch = useDispatch()
    const { loading, data, error } = useSelector(CreditCardsSelector)
    const [checkValue, setCheckValue] = useState('')

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        dispatch(getCreditCardsAction())
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState<string>('')
    const [selectedCardData, setSelectedCardData] = useState<CreditCard | null>(null)
    const [dateNextPayment, setDateNextPayment] = useState<string>('')
    const [dateCutoffDate, setCutoffDate] = useState<string>('')
    const [haveScheduledPayment, setHaveScheduledPayment] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [titleModal, setTitleModal] = useState<string>('')
    const [textModal, setTextModal] = useState<string>('')
    const [statusChange, setStatusChange] = useState<string>('')
    const [cardNumber, setCardNumber] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingState, setIsLoadingState] = useState<boolean>(false)

    const activeCards =
        data?.cards?.filter((card) => card.lockType !== 'BLOQUEO PERDIDA O HURTO') ?? []
    const cards = activeCards.map(
        (card: CreditCard) => card.typeCard + ' - *' + card.lastFourDigits
    )

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedType = event.target.value
        setSelectedOption(selectedType)
        // Busca y guarda los datos de la tarjeta seleccionada
        const selectedCard = data?.cards.find(
            (card) => card.typeCard + ' - *' + card.lastFourDigits === selectedType
        )
        setSelectedCardData(selectedCard ?? null)

        const dateNextPay = new Date(selectedCard?.dateNextPayment ?? '')
        const cutOffDate = new Date(selectedCard?.cutoffDate ?? '')
        // Obtiene el día del mes
        const dayPay = dateNextPay.getDate()
        const dayCutOffDate = cutOffDate.getDate()
        setDateNextPayment(`${dayPay} de cada mes`)
        setCutoffDate(`${dayCutOffDate} de cada mes`)
        setCardNumber(selectedCard?.cardNumber ?? '')
    }

    const handleBlur = (event: React.FocusEvent<HTMLSelectElement>): void => {
        if (event.target.value === '' || event.target.value === null) {
            setIsSelectTouched(true)
        } else {
            setIsSelectTouched(false)
        }
    }

    const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target
        setCheckValue(value)
        if (value === 'true') {
            setIsLoadingState(true)
            GetScheduledPaymentFAIData()
        } else {
            setIsButtonEnabled(true)
        }
    }

    const PaymentScheduleRegistrationTC = (): void => {
        const dataBody = {
            FaiAccountNumber: activeFAI.productDocumentNumber,
            RegisteredAccount: 1,
            CardNumber: cardNumber,
        }
        setIsLoading(true)
        useScheduledPaymentFAI(tokenSave, dataBody)
            .then((res: any) => {
                console.log(JSON.stringify(res))
                if (res['status'] === 200) {
                    setShowModal(true)
                    setStatusChange('200')
                    setTitleModal('Todo listo')
                    setTextModal(
                        'El pago mínimo de tu tarjeta de crédito será automático, podrás desactivarlo en cualquier momento.'
                    )
                    setIsLoading(false)
                } else {
                    setShowModal(true)
                    setTitleModal('¡Ha ocurrido un error!')
                    setTextModal(
                        'Durante el proceso de inscripción de pago automático, por favor intente nuevamente.'
                    )
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTitleModal('¡Ha ocurrido un error!')
                setTextModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    const GetScheduledPaymentFAIData = (): void => {
        const process = true
        useGetScheduledPaymentFAI(activeFAI.productDocumentNumber, process, tokenSave)
            .then((res: any) => {
                if (res.data.Response === '200') {
                    const isMatch = res.data.Data.some((item: string) =>
                        selectedCardData?.cardNumber.includes(item)
                    )
                    if (isMatch) {
                        setHaveScheduledPayment(isMatch)
                        setIsButtonEnabled(true)
                    } else {
                        setIsButtonEnabled(false)
                    }
                    setIsLoadingState(false)
                } else {
                    console.log('Error')
                    setIsLoadingState(false)
                    setShowModal(true)
                    setTitleModal('¡Ha ocurrido un error!')
                    setTextModal(
                        'Al consultar el estado de tu tarjeta, por favor intenta de nuevo.'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoadingState(false)
                setShowModal(true)
                setTitleModal('¡Ha ocurrido un error!')
                setTextModal('No se ha podido conectar con el servidor.')
            })
    }

    const handleClose = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    return (
        <Wrapper>
            <>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {activeCards.length > 0 ? (
                            <CardContainer>
                                <Content>
                                    <div>
                                        <TitleComponent>Pago Automático</TitleComponent>
                                    </div>
                                    <TextInfo>
                                        Elige la tarjeta que deseas inscribir en pago automático:
                                    </TextInfo>
                                    <FormGroup>
                                        <FormLabel>Tarjeta</FormLabel>
                                        <InputSelectGroup
                                            style={{
                                                borderColor:
                                                    isSelectTouched && !selectedOption
                                                        ? '#dc3545'
                                                        : '',
                                            }}
                                        >
                                            <Image src={MiniCardSVG} alt="clave" />
                                            <FormSelectTC
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                                onBlur={handleBlur}
                                            >
                                                <FormOption disabled value="">
                                                    Selecciona una opción
                                                </FormOption>
                                                {cards?.map((card: string) => (
                                                    <FormOption key={card} value={card}>
                                                        {card}
                                                    </FormOption>
                                                ))}
                                            </FormSelectTC>
                                        </InputSelectGroup>
                                    </FormGroup>
                                    {isSelectTouched && !selectedOption && (
                                        <p style={{ color: '#dc3545' }}>
                                            Debes seleccionar una opción
                                        </p>
                                    )}
                                </Content>
                                {selectedCardData && (
                                    <>
                                        <Row>
                                            <Col md={7} xs={12}>
                                                <ImgCardContent>
                                                    <ImageCard
                                                        lastFourDigits={
                                                            selectedCardData.lastFourDigits
                                                        }
                                                        nameCard={selectedCardData.nameCard}
                                                    />
                                                </ImgCardContent>
                                            </Col>
                                            <Col md={5} xs={12}>
                                                <FormLabelDateCard>
                                                    Tipo de tarjeta:{' '}
                                                    <span>{selectedCardData.typeCard}</span>{' '}
                                                </FormLabelDateCard>
                                                <FormLabelDateCard>
                                                    Número de tarjeta:
                                                    <span> *{selectedCardData.lastFourDigits}</span>
                                                </FormLabelDateCard>
                                                <FormLabelDateCard>
                                                    Fecha de pago:
                                                    <span> {dateNextPayment}</span>
                                                </FormLabelDateCard>
                                                {haveScheduledPayment && (
                                                    <FormLabelDateCard>
                                                        Fecha máxima de pago:
                                                        <span> {dateCutoffDate}</span>
                                                    </FormLabelDateCard>
                                                )}
                                            </Col>
                                        </Row>
                                        {!haveScheduledPayment && (
                                            <>
                                                <Row>
                                                    <Col md={12} xs={12}>
                                                        <TextInstruction>
                                                            ¿Autorizo el débito automático a mi
                                                            cuenta FAI de la cuota mínima de esta
                                                            tarjeta?
                                                        </TextInstruction>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3 checkStyled">
                                                            <Row>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Check
                                                                        inline
                                                                        label="Si"
                                                                        type="radio"
                                                                        value="true"
                                                                        checked={
                                                                            checkValue === 'true'
                                                                        }
                                                                        onChange={handleOnChange}
                                                                    />
                                                                </Col>
                                                                <Col md={8} xs={12}>
                                                                    <Form.Check
                                                                        inline
                                                                        label="No"
                                                                        type="radio"
                                                                        value="false"
                                                                        checked={
                                                                            checkValue === 'false'
                                                                        }
                                                                        onChange={handleOnChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {isLoadingState && (
                                                    <Loading text="Por favor, aguarda un momento mientras validamos el estado de tu tarjeta." />
                                                )}
                                            </>
                                        )}
                                        {haveScheduledPayment && (
                                            <Row>
                                                <Col md={12} xs={12}>
                                                    <AlertContainer>
                                                        <Row style={{ display: 'flex' }}>
                                                            <Col
                                                                md={1}
                                                                xs={2}
                                                                style={{
                                                                    padding: '20px 0px 0px 15px',
                                                                }}
                                                            >
                                                                <img src={AlertSVG} alt="logo" />
                                                            </Col>
                                                            <Col
                                                                md={11}
                                                                xs={10}
                                                                style={{
                                                                    padding: '10px 0px',
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                        style={{
                                                                            paddingLeft: '5%',
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            Esta tarjeta ya está
                                                                            programada para el
                                                                            débito automático, si
                                                                            deseas cancelarlo
                                                                            <a href="/payment-schedule-cancellation">
                                                                                {' '}
                                                                                haz clic aquí
                                                                            </a>
                                                                            .
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </AlertContainer>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )}
                                <Content>
                                    <ModalGeneric
                                        show={showModal}
                                        textTitle={titleModal}
                                        textBody={textModal}
                                        img={
                                            statusChange === '200'
                                                ? CheckOperationSVG
                                                : NotProductSVG
                                        }
                                        handleButton={handleClose}
                                        textButton={
                                            statusChange === '200' ? 'Finalizar' : 'Aceptar'
                                        }
                                        handleClose={handleClose}
                                    ></ModalGeneric>
                                    <ButtonsContainer>
                                        <CancelButton onClick={() => history.push('/home-wallet')}>
                                            Cancelar
                                        </CancelButton>
                                        <ButtonContent>
                                            <Button
                                                id="btnSubmit"
                                                className="button-block"
                                                variant="sub-dominant"
                                                type="button"
                                                onClick={PaymentScheduleRegistrationTC}
                                                isLoading={isLoading}
                                                extend
                                                disabled={isButtonEnabled}
                                            >
                                                Continuar
                                            </Button>
                                        </ButtonContent>
                                    </ButtonsContainer>
                                </Content>
                            </CardContainer>
                        ) : (
                            <ModalGeneric
                                show={showModal}
                                img={NotProductSVG}
                                textTitle={'Lo sentimos'}
                                textBody={'No cuentas con tarjetas activas'}
                                handleButton={handleClose}
                                handleClose={handleClose}
                                textButton="Aceptar"
                            />
                        )}
                    </>
                )}

                {Object.keys(error).length > 0 && (
                    <ModalGeneric
                        show={true}
                        img={NotProductSVG}
                        textTitle={'¡Ha ocurrido un error!'}
                        textBody={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleButton={handleClose}
                        handleClose={handleClose}
                        textButton="Aceptar"
                    />
                )}
            </>
        </Wrapper>
    )
}

export default SecondStep
