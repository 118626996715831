import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useGetInfoAssociate, useGetTypeDocumentClient } from './hooks/useInterbankTransfer'
import { LayoutContent } from '../../transverse'
import { Container, ContainerTitle, Title } from './InterbankTransfer-Styled'
import InitialInfo from './inc/InitialInfo'
import FirsStep from './inc/FirsStep'
import { BreadcrumbApp } from '../../components'
import SecondStep from './inc/SecondStep'
import TransactionResponse from './inc/TransactionResponse'
import { getAllBanksAction } from '../../../redux/tc'
import { getAllBanksSelector } from '../../../selectors'
import ModalError from './inc/Modals/ModalError'

interface TransactionData {
    amountTransfer: string
    nameAddreess: string
    typeDocumentId: string
    destinationIdentification: string
    stateAddressId: string
    stateAddressName: string
    cityAddressId: string
    cityAddressName: string
    bankAddressId: string
    bankAddressName: string
    typeAccountAddress: string
    accountNumberAddressee: string
    description: string
}

const InterbankTransfer = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [associatedName, setAssociatedName] = useState<string>('')
    const [associatedDocumentNumber, setAssociatedDocumentNumber] = useState<string>('')
    const [associatedDocumentType, setAssociatedDocumentType] = useState<string>('')

    const [documentNumberAddressee, setDocumentNumberAddressee] = useState<string>('')

    const [dateTransaction, setDateTransaction] = useState<string>('')
    const [accountNumberAddressee, setAccountNumberAddressee] = useState<string>('')

    const [nameAddressee, setNameAddressee] = useState<string>('')
    const [amountTransfer, setAmountTransfer] = useState<string>('')
    const [bank, setBank] = useState<string>('')
    const [voucher, setVoucher] = useState<string>('')
    const [messageTransaction, setMessageTransaction] = useState<string>('')

    const [statusTransaction, setStatusTransaction] = useState<string>('')

    const [savedTransaction, setSavedTransaction] = useState<TransactionData | null>(null)

    const { banks } = useSelector(getAllBanksSelector)

    const [step, setStep] = useState<number>(1)

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Transacciones y Pagos',
            active: false,
        },
        {
            text: 'Pagos',
            active: false,
        },
        {
            text: 'Transferencias interbancarias',
            active: true,
        },
    ]

    const GetInfoAssociate = (): void => {
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setAssociatedName(res.data.Data[0].Name)
                    setAssociatedDocumentNumber(res.data.Data[0].Document)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const GetTypeDocumentClient = (): void => {
        useGetTypeDocumentClient(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setAssociatedDocumentType(res.data.Data)
                } else {
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    useEffect(() => {
        GetInfoAssociate()
        GetTypeDocumentClient()
        dispatch(getAllBanksAction())
    }, [tokenSave])

    const handleNextStepChange = (): void => {
        setStep(step + 1)
    }

    const handlePrevStepChange = (): void => {
        setStep(step - 1)
    }

    const saveDataTransfer = (
        dateTransaction: string,
        messageTransaction: string,
        voucher: string,
        responseTransaction: string
    ): void => {
        setDateTransaction(dateTransaction)
        setMessageTransaction(messageTransaction)
        setVoucher(voucher)
        setStatusTransaction(responseTransaction)
    }

    const handleSaveData = (transactionData: TransactionData): void => {
        setSavedTransaction(transactionData)
        setAmountTransfer(transactionData.amountTransfer)
        setNameAddressee(transactionData.nameAddreess)
        setDocumentNumberAddressee(transactionData.destinationIdentification)
        setBank(transactionData.bankAddressName)
        setAccountNumberAddressee(transactionData.accountNumberAddressee)
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (step > 1 && step <= 2) {
                            handlePrevStepChange()
                            setIsEdit(true)
                        } else if (step === 3) {
                            setStep(1)
                            setIsEdit(false)
                            const transactionData: TransactionData = {
                                amountTransfer: '',
                                nameAddreess: '',
                                typeDocumentId: '',
                                destinationIdentification: '',
                                stateAddressId: '',
                                stateAddressName: '',
                                cityAddressId: '',
                                cityAddressName: '',
                                bankAddressId: '',
                                bankAddressName: '',
                                typeAccountAddress: '',
                                accountNumberAddressee: '',
                                description: '',
                            }
                            setSavedTransaction(transactionData)
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />

                {step === 1 && (
                    <>
                        <ContainerTitle>
                            <Title>
                                Transferencias <span>interbancarias</span>
                            </Title>
                        </ContainerTitle>
                        <InitialInfo associatedName={associatedName} />
                        <FirsStep
                            isEdit={isEdit}
                            associatedName={associatedName}
                            associatedDocumentNumber={associatedDocumentNumber}
                            onNextStep={handleNextStepChange}
                            saveDataTransfer={handleSaveData}
                            banks={banks}
                            savedTransactions={savedTransaction}
                        />
                    </>
                )}
                {step === 2 && (
                    <SecondStep
                        onNextStep={handleNextStepChange}
                        handleData={saveDataTransfer}
                        amountTransfer={amountTransfer}
                        dataTransaction={savedTransaction}
                        associatedName={associatedName}
                        associatedDocumentNumber={associatedDocumentNumber}
                        associatedDocumentType={associatedDocumentType}
                    />
                )}
                {step === 3 && (
                    <TransactionResponse
                        associatedName={associatedName}
                        amountTransfer={amountTransfer}
                        dateTransaction={dateTransaction}
                        messageTransaction={messageTransaction}
                        responseTransaction={statusTransaction}
                        voucher={voucher}
                        accountNumberAddressee={accountNumberAddressee}
                        documentNumberAddressee={documentNumberAddressee}
                        nameAddressee={nameAddressee}
                        bank={bank}
                    />
                )}
            </Container>
            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
        </LayoutContent>
    )
}
export default InterbankTransfer
