import { CarGifSVG } from '../../../../../utils/getIcons'
import { SavingProductsContent } from '../../SavingProducts/savingProducts-styles'

export const AdminCardSavingProducts = (): JSX.Element => {
    return (
        <SavingProductsContent>
            <p className="title">Productos de Ahorros</p>
            <div className="card-content" data-tour="card-products-available">
                <div className="img-content">
                    <img src={CarGifSVG} alt="paquete" />
                    <p className="card-title">Paga tus productos de ahorro Cavipetrol.</p>
                </div>
                <p className="card-subtitle"></p>
                <p className="link-btn" style={{ color: '#E70000' }}>
                    No se encontraron datos para mostrar
                </p>
            </div>
        </SavingProductsContent>
    )
}
