import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    useGetTemplatesNotification,
    useUpdateTemplatesNotification,
} from '../../hooks/useManageContent'
import { Loading, ModalGeneric } from '../../../../components'
import {
    ButtonPagination,
    ButtonUnstyled,
    ContainerTable,
    ContentInfo,
    PaginationContent,
    SectionTd,
    SectionTh,
} from '../../ManageableContent-styled'
import { CancelWhite, EditWhite, NotProductSVG } from '../../../../utils/getIcons'
import { Form, PageItem, Pagination } from 'react-bootstrap'
import ModalForm from '../ModalForm'

interface TemplatesNotification {
    NotificationId: number
    Name: string
    HeaderHtml: string
    FooterHtml: string
    Message: string
    MessageSMS: string
    Subject: string
    Images: number | null
    UserIdentification: string | null
    IpAddress: string | null
    Ubication: string | null
    Header: string | null
    Method: string | null
}

const TableTemplatesNotifications: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const [TemplatesNotifications, setTemplatesNotification] = useState<TemplatesNotification[]>([])
    const [isEditable, setIsEditable] = useState<boolean>(false)

    const currentItems = TemplatesNotifications.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(TemplatesNotifications.length / itemsPerPage)
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    let keyCounter = 0

    const [showModalForm, setShowModalForm] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
    const [titleModal, setTitleModal] = useState<string>('')
    const [valueConfiguration, setValueEdit] = useState<string>('')
    const [idNotification, setIdNotification] = useState<number>(0)
    const [headerHtml, setHeaderHtml] = useState<string>('')
    const [footerHtml, setFooterHtml] = useState<string>('')
    const [messageHtml, setMessageHtml] = useState<string>('')
    const [messageSMS, setMessageSMS] = useState<string>('')
    const [subject, setSubject] = useState<string>('')

    const GetTemplatesNotification = (): void => {
        setLoading(true)
        useGetTemplatesNotification(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setTemplatesNotification(res['data']['Data'])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    useEffect(() => {
        GetTemplatesNotification()
    }, [])

    const [editableColumn, setEditableColumn] = useState<string | null>(null)

    const updateRegister = (columnName: string): void => {
        setIsEditable(true)
        setEditableColumn(columnName)
        const thElement = document.querySelector(`#${columnName}`)
        if (thElement) {
            const txtTh = thElement.textContent ? thElement.textContent : ''
            setTitleModal(txtTh)
        }
    }

    const cancelEdit = (): void => {
        setIsEditable(false)
    }

    const [checkValue, setCheckValue] = useState<number>()

    const handleOnChange = (
        info: TemplatesNotification,
        newValue: string,
        { target }: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = target
        setCheckValue(parseInt(value))
        setIdNotification(info.NotificationId)
        setHeaderHtml(info.HeaderHtml)
        setFooterHtml(info.FooterHtml)
        setMessageHtml(info.Message)
        setMessageSMS(info.MessageSMS)
        setSubject(info.Subject)
        setValueEdit(newValue)
        handleShowModalForm()
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    const handleShowModalForm = (): void => {
        setShowModalForm(true)
    }

    const handleCloseModalForm = (): void => {
        setShowModalForm(false)
        setValueEdit('')
        setCheckValue(0)
    }

    const HandleFormSubmit = (value: string): void => {
        setIsLoadingUpdate(true)
        setIsEditable(false)
        const dataBody = {
            NotificationId: idNotification,
            HeaderHtml: headerHtml,
            FooterHtml: footerHtml,
            Message: messageHtml,
            MessageSMS: messageSMS,
            Subject: subject,
        }
        let updatedDataBody = {}
        const updatedField = editableColumn

        if (updatedField) {
            updatedDataBody = {
                ...dataBody,
                [updatedField]: value,
            }
        }

        useUpdateTemplatesNotification(tokenSave, updatedDataBody)
            .then((res: any) => {
                setIsLoadingUpdate(false)
                if (res['status'] === 200) {
                    GetTemplatesNotification()
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                handleCloseModalForm()
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorMessage('No se ha podido conectar con el servidor')
                handleCloseModalForm()
            })
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <ContainerTable striped bordered responsive>
                    <thead>
                        <tr>
                            <th style={{ maxWidth: '80px' }}>
                                <SectionTh>Notification Id</SectionTh>
                            </th>
                            <th>
                                <SectionTh>Name</SectionTh>
                            </th>
                            <th id="HeaderHtml">
                                <SectionTh showThirdButton={isEditable}>
                                    Header Html
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('HeaderHtml')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'HeaderHtml' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th id="FooterHtml">
                                <SectionTh showThirdButton={isEditable}>
                                    Footer Html
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('FooterHtml')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'FooterHtml' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>

                            <th id="Message">
                                <SectionTh showThirdButton={isEditable}>
                                    Message
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Message')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Message' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th id="MessageSMS">
                                <SectionTh showThirdButton={isEditable}>
                                    Message SMS
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('MessageSMS')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'MessageSMS' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                            <th id="Subject">
                                <SectionTh showThirdButton={isEditable}>
                                    Subject
                                    <ButtonUnstyled
                                        variant="primary"
                                        onClick={() => updateRegister('Subject')}
                                    >
                                        <img src={EditWhite} alt="IconEdit" width={25} />
                                    </ButtonUnstyled>
                                    {isEditable && editableColumn === 'Subject' && (
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() => cancelEdit()}
                                        >
                                            <img src={CancelWhite} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    )}
                                </SectionTh>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((info: TemplatesNotification) => (
                            <tr key={info.NotificationId}>
                                <td className="center">{info.NotificationId}</td>
                                <td>{info.Name}</td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.HeaderHtml}{' '}
                                        {isEditable && editableColumn === 'HeaderHtml' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.NotificationId}
                                                checked={checkValue === info.NotificationId}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.HeaderHtml, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.FooterHtml}{' '}
                                        {isEditable && editableColumn === 'FooterHtml' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.NotificationId}
                                                checked={checkValue === info.NotificationId}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.FooterHtml, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Message}
                                        {isEditable && editableColumn === 'Message' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.NotificationId}
                                                checked={checkValue === info.NotificationId}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.Message, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.MessageSMS}
                                        {isEditable && editableColumn === 'MessageSMS' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.NotificationId}
                                                checked={checkValue === info.NotificationId}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.MessageSMS, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                                <td>
                                    <SectionTd showIsEditable={isEditable}>
                                        {info.Subject}
                                        {isEditable && editableColumn === 'Subject' && (
                                            <Form.Check
                                                className="radio-button"
                                                type="radio"
                                                value={info.NotificationId}
                                                checked={checkValue === info.NotificationId}
                                                onChange={(e) =>
                                                    handleOnChange(info, info.Subject, e)
                                                }
                                            />
                                        )}
                                    </SectionTd>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </ContainerTable>
            )}
            <PaginationContent>
                <Pagination>
                    {currentPage !== 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`prev_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                {'<'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                    {[...Array(totalPages)].map((_, page) => (
                        <PageItem
                            key={`page_${page}`}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageClick(page + 1)}
                        >
                            {page + 1}
                        </PageItem>
                    ))}
                    {currentPage !== totalPages && totalPages > 1 && (
                        <ButtonPagination>
                            <PageItem
                                key={`next_${keyCounter++}`}
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                {'>'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                </Pagination>
                <ContentInfo>
                    {TemplatesNotifications.length === 0 && showError && (
                        <div style={{ display: 'flex' }}>
                            <img src={NotProductSVG} alt="IconEdit" width={50} />
                            <span style={{ alignContent: 'center' }}>
                                No hay información disponible
                            </span>
                        </div>
                    )}
                </ContentInfo>
            </PaginationContent>

            <ModalForm
                showModal={showModalForm}
                handleCloseModal={handleCloseModalForm}
                isLoading={isLoadingUpdate}
                titleText={titleModal}
                updateOnSubmitted={HandleFormSubmit}
                valueDataChange={valueConfiguration}
            />

            <ModalGeneric
                show={showError}
                handleClose={handleClose}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessage}
                handleButton={handleClose}
                textButton="Aceptar"
            />
        </>
    )
}

export default TableTemplatesNotifications
