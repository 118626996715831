import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useUserInitializes } from '../../hooks/UseUserOperations'
import {
    ButtonCanceled,
    ContainerBtn,
    ContentForm,
    DivContainer,
    Label,
    SectionTitle,
    SpaceHr,
    TitlePage,
} from '../../UserOperations-styled'
import { LayoutContent } from '../../../../transverse'
import { Col, Form, Row } from 'react-bootstrap'
import { Button, ModalGeneric } from '../../../../components'
import ModalOperation from '../ModalOperation'
import { NotProductSVG } from '../../../../utils/getIcons'
import { useHistory } from 'react-router-dom'

interface InitializeUserProps {
    nameFull: string
    userName: string
    identificationNumber: string
    onPrevStep: (step: number) => void
}

const InitializeUser: React.FC<InitializeUserProps> = ({
    nameFull,
    userName,
    identificationNumber,
    onPrevStep,
}): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorMessageModal, setErrorMessageModal] = useState<string>('')
    const [messageTransaction, setMessageTransaction] = useState<string>('')
    const [receipt, setReceipt] = useState<string>('')
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [showModal, setShowModal] = useState<boolean>(false)
    const history = useHistory()

    const HandleOperationSubmit = (): void => {
        setIsLoading(true)
        useUserInitializes(tokenSave, userName, identificationNumber)
            .then((res: any) => {
                setIsLoading(false)

                const responseRes = res.data?.Response || res.Response
                if (responseRes === '200') {
                    setReceipt(res['data']['Data']['Consecutive'])
                    setShowModal(true)
                    setMessageTransaction(res['data']['Data']['Message'])
                } else {
                    const messageInfo = res.data?.Message || res.Message
                    setErrorMessageModal(messageInfo)
                    setShowErrorModal(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setErrorMessageModal('No se ha podido conectar con el servidor')
                setShowErrorModal(true)
            })
    }

    const closeModalSuccess = (): void => {
        setShowModal(false)
        onPrevStep(1)
    }

    const closeModalError = (): void => {
        setShowErrorModal(false)
        history.push('/home-wallet')
    }

    return (
        <LayoutContent>
            <SpaceHr />

            <SectionTitle>
                <TitlePage>Inicializar usuario</TitlePage>
                <p style={{ marginTop: '30px' }}>¿Está segur@ de inicializar usuario?</p>
            </SectionTitle>

            <ContentForm>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Login usuario</Label>

                            <Form.Control type="text" disabled value={userName} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Cédula</Label>
                            <Form.Control type="text" disabled value={identificationNumber} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Nombre</Label>

                            <Form.Control type="text" disabled value={nameFull} />
                        </Form.Group>
                    </Col>
                </Row>

                <SpaceHr />

                <DivContainer>
                    <ButtonCanceled variant="primary" onClick={() => onPrevStep(1)}>
                        Cancelar
                    </ButtonCanceled>

                    <ContainerBtn>
                        <Button
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={HandleOperationSubmit}
                            isLoading={isLoading}
                            extend
                            disabled={false}
                        >
                            Aplicar
                        </Button>
                    </ContainerBtn>
                </DivContainer>
            </ContentForm>

            <ModalOperation
                label="Resultado inicializar usuario"
                userName={userName}
                showModal={showModal}
                handleCloseModal={closeModalSuccess}
                labelInput="Usuario"
                identificationCard={identificationNumber}
                shouldInitializeUser={true}
                receipt={receipt}
                state={messageTransaction}
            />
            <ModalGeneric
                show={showErrorModal}
                handleClose={closeModalError}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessageModal}
                handleButton={closeModalError}
                textButton="Aceptar"
            />
        </LayoutContent>
    )
}

export default InitializeUser
