import { useHistory } from 'react-router-dom'
import { Loading, ModalGeneric } from '../../../../../../components'
import { Container, ContainerTitle, Title } from '../AssignAdvanceKey/AssignAdvanceKey-Styled'
import { LayoutContent } from '../../../../../../transverse'
import { CreditCardsSelector } from '../../../../../../../selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getCreditCardsAction } from '../../../../../../../redux/tc'

import { NotProductSVG } from '../../../../../../utils/getIcons'
import { LoadingContainer } from '../../../../creditDetail-styles'

import SelectCard from '../SelectCard/SelectCard'
import NewAdvanceKey from '../NewAdvanceKey'
import DynamicKeyContainer from '../DynamicKeyContainer'
import BreadcrumbAdvanceKey from '../BreadcrumbAdvanceKey'
import { useUserInfo } from '../../../../hooks/useCreditDetail'

const ForgetAdvanceKey: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    // selectors
    const { loading, data } = useSelector(CreditCardsSelector)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [step, setStep] = useState<number>(1)
    const [lastForDigit, setLastForDigit] = useState<string>('')
    const [numberCard, setNumberCard] = useState<string>('')
    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')
    const [urlToBack, setUrlToBack] = useState('')

    // listeners
    useEffect(() => {
        dispatch(getCreditCardsAction())
    }, [dispatch])

    useEffect(() => {
        GetUserInfo()
    }, [tokenSave])

    const activeCards = data?.cards?.filter((card) => card.lockType === 'ACTIVA') || []

    const [showModal, setShowModal] = useState<boolean>(true)
    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const handleStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handleData = (
        lastForDigit: string,
        numberCard: string,
        lastFourDigitsEncryptedCC: string
    ): void => {
        setStep(2)
        setLastForDigit(lastForDigit)
        setNumberCard(numberCard)
        setUrlToBack(`/credit-card-detail?id=${lastFourDigitsEncryptedCC}`)
    }

    const GetUserInfo = (): void => {
        useUserInfo(tokenSave)
            .then((res: any) => {
                if (res['Response'] === '200') {
                    setCellphone(res['Data']['CellPhone'])
                    setEmail(res['Data']['Email'])
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbAdvanceKey lastFourDigits={lastForDigit}></BreadcrumbAdvanceKey>
                <ContainerTitle>
                    <Title>
                        Restablece tu clave
                        <span>para avances</span>
                    </Title>
                </ContainerTitle>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {activeCards.length > 0 ? (
                            <>
                                {step === 1 && (
                                    <SelectCard
                                        activeCards={activeCards}
                                        stepNumber="1"
                                        nextStepNumber="3"
                                        onNextStep={handleData}
                                    ></SelectCard>
                                )}
                            </>
                        ) : (
                            <ModalGeneric
                                show={showModal}
                                img={NotProductSVG}
                                textTitle={'Lo sentimos'}
                                textBody={'No cuentas con tarjetas activas'}
                                handleButton={handleModalCloseInfo}
                                handleClose={handleModalCloseInfo}
                                textButton="Aceptar"
                            />
                        )}
                    </>
                )}
                {step === 2 && (
                    <DynamicKeyContainer
                        stepNumber="2"
                        nextStepNumber="3"
                        onNextStep={() => handleStepChange(3)}
                        cellphone={cellphone}
                        email={email}
                        buttonText="Continuar"
                        IsForgetAdvanceKey={true}
                        lastFourDigits={lastForDigit}
                    ></DynamicKeyContainer>
                )}
                {step === 3 && (
                    <NewAdvanceKey
                        stepNumber="3"
                        nextStepNumber="3"
                        showValidThru={true}
                        lastFourDigits={lastForDigit}
                        keyNew={false}
                        tokenSave={tokenSave}
                        numberCard={numberCard}
                        urlToBack={urlToBack}
                        buttonText="Continuar"
                    ></NewAdvanceKey>
                )}
            </Container>
        </LayoutContent>
    )
}

export default ForgetAdvanceKey
