/* eslint-disable prettier/prettier */
import { ReactElement, FC, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// components
import { Button } from '../../../../components'
import CardExceptionError from '../CardExceptionError'
import MoraCard from '../MoraCard'

// styles
import { TitleStep, SpaceStep } from '../../campaignCard-styles'
import {
    ThirdStepExceptionsContent,
    ExceptionCtr,
    ExceptionImg,
    ExceptionNavigateLink,
    ExceptionImage,
    ExceptionTitle,
    ExceptionParagraphM,
} from './thirdStepExceptions-styles'

// icons
import { ArrowSVG, Credit1SVG, CreditCardFlowSVG } from '../../../../utils/getIcons'

// selectors
import { useSelector } from '../../../../../selectors'
import { useGetTotalBalanceArrears } from '../../../CreditCardDetail/hooks/useCardRedirect'
import Loading from '../Loading'

export interface ThirdStepExceptionsProps {
    exceptionType?: number
}

const ThirdStepExceptions: FC<ThirdStepExceptionsProps> = ({ exceptionType }): ReactElement => {
    const history = useHistory()
    const [loadingValidateMora, setLoadingValidateMora] = useState<boolean>(false)
    const [delinquentBalanceCredit, setDelinquentBalanceCredit] = useState<number>(0)
    const [delinquentBalanceSaving, setDelinquentBalanceSaving] = useState<number>(0)
    const [delinquentBalanceTC, setDelinquentBalanceTC] = useState<number>(0)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const GetTotalBalanceArrears = (): void => {
        useGetTotalBalanceArrears(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    const delinquentCredit = res.data.Data.DelinquentBalanceCredit
                    const delinquentSaving = res.data.Data.DelinquentBalanceSaving
                    const delinquentTC = res.data.Data.DelinquentBalanceTC
                    const flag = delinquentCredit > 0 || delinquentSaving > 0 || delinquentTC > 0
                    if (flag) {
                        setDelinquentBalanceCredit(delinquentCredit)
                        setDelinquentBalanceSaving(delinquentSaving)
                        setDelinquentBalanceTC(delinquentTC)
                    }
                }
                setLoadingValidateMora(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    // listener
    useEffect(() => {
        setLoadingValidateMora(true)
        GetTotalBalanceArrears()
    }, [])

    const redirection = (url: string, state?: Record<string, string | number>): void => {
        history.push(url, state)
    }

    return (
        <>
            {exceptionType !== undefined && (
                <ThirdStepExceptionsContent>
                    <ExceptionCtr>
                        <ExceptionImg src={CreditCardFlowSVG} alt="Logo" />
                        {exceptionType === 1 && (
                            <CardExceptionError
                                text="Para mayor información visita nuestras oficinas"
                                title="En este momento no podemos continuar con tu solicitud"
                                txtAlign="center"
                            />
                        )}

                        {exceptionType === 2 && loadingValidateMora ? (
                            <Loading />
                        ) : (
                            <ExceptionCtr>
                                <ExceptionTitle>Estás en Mora con alguno de tus productos de Cavipetrol</ExceptionTitle>
                                <ExceptionParagraphM >Debes estar al día en tus productos para rediferir tus cuotas.</ExceptionParagraphM>

                                {delinquentBalanceSaving > 0 && (
                                    <MoraCard
                                        image={Credit1SVG}
                                        title="Saldo en mora en ahorros"
                                        value={delinquentBalanceSaving.toString()}
                                        url="/savings-group"
                                        type="saving"
                                    />)}
                                {delinquentBalanceCredit > 0 && (
                                    <MoraCard
                                        image={Credit1SVG}
                                        title="Saldo en mora en créditos"
                                        value={delinquentBalanceCredit.toString()}
                                        url="/credits-group"
                                        type="credit"
                                    />)}
                                {delinquentBalanceTC > 0 && (
                                    <MoraCard
                                        image={Credit1SVG}
                                        title="Saldo en mora en tarjeta de crédito"
                                        value={delinquentBalanceTC.toString()}
                                        url="/home-wallet"
                                        type="tc"
                                    />)}
                            </ExceptionCtr>
                        )}

                        {exceptionType === 3 && (
                            <CardExceptionError
                                text="Nos encontramos realizando una revisión interna, pronto te
estaremos notificando a tu correo electrónico el avance de tu
solicitud."
                                title="Necesitamos más información"
                                txtAlign="center"
                                children={
                                    <Button
                                        variant="sub-dominant"
                                        type="submit"
                                        disabled={false}
                                        onClick={() => {
                                            redirection('/product-opening')
                                        }}
                                    >
                                        Regresar
                                    </Button>
                                }
                            />
                        )}
                        {exceptionType === 4 && (
                            <CardExceptionError
                                text="Nos estamos comunicando para brindarte alternativas de
                                asegurabilidad"
                                title="Pronto un asesor te contactará"
                                txtAlign="start"
                                children={
                                    <Button
                                        variant="sub-dominant"
                                        type="submit"
                                        disabled={false}
                                        onClick={() => {
                                            redirection('/product-opening')
                                        }}
                                    >
                                        Regresar
                                    </Button>
                                }
                            />
                        )}
                        {exceptionType === 5 && (
                            <CardExceptionError
                                text="Comunícate con servicio al asociado para tener más información"
                                title="Tienes un proceso ejecutivo en curso"
                                txtAlign="center"
                                children={
                                    <>
                                        <SpaceStep />
                                        <ExceptionNavigateLink to="/support-private">
                                            <ExceptionImage src={ArrowSVG} alt="Arrow" />
                                            <TitleStep>
                                                Contactar a servicio al asociado
                                            </TitleStep>
                                        </ExceptionNavigateLink>
                                    </>
                                }
                            />
                        )}
                    </ExceptionCtr>
                </ThirdStepExceptionsContent>
            )}
        </>
    )
}

export default ThirdStepExceptions
