import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const ContainerKey = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[552px] lg:max-w-[600px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const TextInfo = styled.p`
    ${tw`mt-3 py-3 px-3 font-montserrat text-[16px]`}

    span {
        font-weight: 700;
    }
`
export const TextSteps = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[16px] font-montserrat text-dominant-dark`}
`

export const TextSubtitle = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[14px] font-montserrat text-dominant-dark`}
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: space-between !important;
`

export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`
export const FAIFormWrap = styled.div`
    ${tw`pb-6`}

    .label-floating {
        ${tw`hidden font-helvetica font-normal text-gray-custom-700 text-base`}
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        ${tw`ml-15 relative`}

        .label-floating {
            ${tw`block absolute mt-3 top-full left-0`}
        }
    }
`

export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ContainerInputs = styled.div`
    text-align: center;
    justify-content: center;
    ${tw`flex gap-[15px] p-0`}
`

export const InputKeyDynamic = styled.input`
    ${tw`w-12 h-12 border-4 rounded bg-transparent outline-none text-center font-bold text-xl border-solid border-gray-custom-300 opacity-50 focus:border[1px solid #f5a50b] focus:text-gray-700 text-gray-400 transition`}

    border-width: 1px;
    margin: 10px;
    font-weight: 700 !important;
    margin-right: '10px';
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        ${tw`gap-5`}
    }

    @media (min-width: 352px) and (max-width: 400px) {
        ${tw`w-8 h-9 m-0.5 p-0 text-xl`}
    }

    @media (min-width: 400px) and (max-width: 550px) {
        ${tw`w-10 h-10 m-0.5 p-0 text-xl`}
    }

    @media (max-width: 352px) {
        ${tw`w-6 h-9 m-0.5 p-0 text-xl`}
    }

    input:focus {
        box-shadow: 0 0 0 0.25rem var(--sub-dominant-color) !important;
    }
`

export const LinkServiceAssociate = styled.a`
    ${tw`mt-4 mb-6`}
    text-decoration: none;
    color: var(--dominant-color-dark) !important;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;

    a:focus {
        color: var(--dominant-color-dark) !important;
    }
`

export const ExpirationCode = styled.div`
    ${tw`flex justify-center items-center mt-[4%] py-2.5 px-0 mb-4`}
    ${tw`lg:mt-[4%] lg:justify-start lg:p-0`}

    p {
        ${tw`font-montserrat font-normal text-sm text-gray-custom-700 m-0`}
        ${tw`lg:text-base`}
    }

    .text-percentage {
        ${tw`flex`}
    }
`

export const TextDynamicCode = styled.p`
    margin-top: 4%;
    .link-dynamic-passw {
        ${tw`font-montserrat font-normal text-sm text-gray-custom-700 cursor-pointer`}

        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            ${tw`text-base`}
        }
    }
`

export const ButtonRequestDynamicKey = styled.button`
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--sub-dominant-color) !important;

    button:disabled {
        color: red !important;
    }
`
