import { FC, useRef, useState } from 'react'
import { ArrowCircleSVG, EyeCleanPNG, EyeSVG, LockSVG } from '../../../../utils/getIcons'
import { Row, Col, OverlayTrigger } from 'react-bootstrap'
import {
    PpalTitle,
    ArrowBlack,
    SpacerContent,
    Wrapper,
    Content,
    TextP,
    ButtonsContainer,
    ModalCancelButton,
    ModalButton,
} from '../../KeyChange-styles'
import {
    FormGroup,
    FormControl,
    InputGroup,
    InputGroupText,
    FormLabel,
    FormMessage,
    Popover,
} from '../../../../components'
import cn from 'classnames'
import { useMediaQuery } from 'usehooks-ts'
import { useFormState } from 'react-hook-form'
import { KeyboardLogin } from '../../../Login/inc'
import { useLoginForm } from '../../../Login/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useValidatePassword } from '../../hooks/useKeyChange'
import ModalLoading from '../Modals/ModalLoading'
import ModalError from '../Modals/ModalError'

interface StepProps {
    tokenSave: string
    handleNextStep: (value: string) => void
    redirection: (value: string) => void
}

// validation schema
const loginSchema = yup
    .object({
        password: yup
            .string()
            .required('Campo obligatorio')
            .min(4, 'Este campo debe contener un mínimo de 4 caracteres y un máximo de 8')
            .max(8, 'Este campo debe contener un máximo de 8 caracteres'),
    })
    .required()

// type definitions
type LoginInputsType = yup.InferType<typeof loginSchema>

const CurrentPass: FC<StepProps> = ({ tokenSave, handleNextStep, redirection }): JSX.Element => {
    const messagesRef = useRef({
        userNotExists: {
            type: 'not-exists',
            message: '',
        },
        pwdNotExits: {
            type: 'not-exists',
            message: '',
        },
        pwdTried: {
            type: 'pwd-tried',
            message: '',
        },
    })
    const defaultValuesRef = useRef({
        password: '',
    })

    const { control, register, getValues, setValue } = useLoginForm<LoginInputsType>({
        defaultValues: defaultValuesRef.current,
        validationSchema: yupResolver(loginSchema),
        messages: messagesRef.current,
    })

    const { errors } = useFormState({ control })

    const [seePassword, setPassword] = useState(false)

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handlePasswordInput = (inputChanged: string): void => {
        setValue('password', inputChanged, { shouldValidate: true })
    }

    const handleFocusPassword = (): void => {
        setShowKeyboard(true)
    }

    const handleHideKeyboard = (): void => {
        setShowKeyboard(false)
    }

    const ValidateCurrentPass = (): void => {
        const currentValue = getValues('password')
        setIsLoading(true)
        useValidatePassword(tokenSave, currentValue)
            .then((res: any) => {
                setIsLoading(false)
                const responseCode = res.data?.Response || res.Response

                if (responseCode === '200') {
                    const current = 'current-' + currentValue
                    handleNextStep(current)
                } else {
                    setErrorMessage(res.Message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage(error)
                setShowError(true)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    return (
        <SpacerContent>
            <ArrowBlack onClick={() => redirection('/')}>
                <img src={ArrowCircleSVG} alt="black" />
                <p>Volver</p>
            </ArrowBlack>

            <Wrapper>
                <Content>
                    <PpalTitle>
                        Cambia tu <span>clave</span>
                    </PpalTitle>

                    <TextP>Ingresa tu clave actual</TextP>

                    <Row>
                        <Col md={3}>
                            <TextP>
                                <span>Estás a punto de cambiar tu clave de acceso al portal</span>
                            </TextP>
                        </Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={8}>
                            <TextP>Ingresa tu clave actual</TextP>

                            <FormGroup id="content-password">
                                <FormLabel>Contraseña</FormLabel>
                                <OverlayTrigger
                                    show={showKeyboard}
                                    placement="auto-start"
                                    overlay={
                                        <Popover id="keyboard-popover" hasKeyboard>
                                            <KeyboardLogin
                                                onMouseLeave={handleHideKeyboard}
                                                onChange={handlePasswordInput}
                                                defaultValue={getValues('password')}
                                            />
                                        </Popover>
                                    }
                                >
                                    <InputGroup hasValidation>
                                        <InputGroupText $inputError={!!errors.password}>
                                            <img src={LockSVG} alt="clave" className="icon-input" />
                                        </InputGroupText>
                                        <FormControl
                                            {...register('password')}
                                            placeholder="Ingresa tu clave actual"
                                            className="has-suffix"
                                            type={seePassword ? 'input' : 'password'}
                                            onFocus={
                                                matchMedia ? handleFocusPassword : () => void 0
                                            }
                                            maxLength={8}
                                            isInvalid={!!errors.password}
                                        />

                                        <InputGroupText
                                            className={cn('icon-action', {
                                                seePassword: !seePassword,
                                                'not-seePassword': seePassword,
                                            })}
                                            onClick={() => {
                                                setPassword((sp) => !sp)
                                            }}
                                        >
                                            <img
                                                src={seePassword ? EyeCleanPNG : EyeSVG}
                                                alt="clave oculta y visible"
                                                className="icon-eye"
                                            />
                                        </InputGroupText>
                                        <FormMessage type="invalid">
                                            {errors.password && errors.password.message}
                                        </FormMessage>
                                    </InputGroup>
                                </OverlayTrigger>
                            </FormGroup>

                            <br></br>
                            {matchMedia && (
                                <TextP>Utiliza el teclado virtual para mayor seguridad</TextP>
                            )}
                        </Col>
                    </Row>

                    <ButtonsContainer>
                        <ModalCancelButton
                            variant="primary"
                            onClick={() => redirection('/home-wallet')}
                        >
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton type="button" onClick={ValidateCurrentPass}>
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </Content>
                <ModalLoading isLoading={isLoading} />
                <ModalError
                    showModal={showError}
                    handleClose={handleClose}
                    titleMessage={'Clave inválida'}
                    errorMessage={errorMessage}
                />
            </Wrapper>
        </SpacerContent>
    )
}

export default CurrentPass
