import tw, { styled } from 'twin.macro'
import { ButtonsProps } from '../ControlsButtons/controlsButtons-styles'

export const SixthStepWrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const SixthStepContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[550px] lg:max-w-[924px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export interface TextAlign {
    txtAlign: 'center' | 'start' | 'end'
}

export const WrapperTop = styled.div`
    ${tw`flex flex-col lg:flex-row lg:my-5 lg:gap-10`}
`
export const ButtonsCtr = styled.div`
    ${tw`flex flex-col-reverse gap-3 lg:flex-row items-center justify-between my-3`}
`

export const ButtonsRightCtr = styled.div<ButtonsProps>`
    ${tw`w-full flex items-center justify-between lg:w-[60%] gap-10`}

    ${({ isSave }) => (isSave ? tw`lg:w-[25%]` : tw`lg:w-[60%]`)}

    & .save {
        ${tw`hidden lg:block`}
    }
`

export const ButtonsLeftCtr = styled.div`
    ${tw`w-full flex items-center justify-start lg:w-[25%]`}
    .cancelar {
        background: transparent;
        border-color: transparent;
        color: var(--sub-dominant-color);
        text-decoration: underline;
    }
`
export const WrapperTopLeftImage = styled.div`
    ${tw`w-full lg:w-[35%] flex justify-center items-center my-5 lg:justify-start`}
`

export const WrapperTopRightSlide = styled.div`
    ${tw`flex flex-col justify-start w-full h-full mb-10 lg:my-5`}
    .title {
        ${tw`font-helvetica font-medium lg:text-base text-[14px] lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
    }
    .sub-title {
        ${tw`font-montserrat lg:font-bold lg:text-black lg:text-base font-medium text-[20px] color[var(--text-opacity8)]`}
    }
    .text {
        ${tw`font-helvetica font-normal text-base color[var(----text-opacity7)] lg:block hidden`}
    }
    .rowname {
        ${tw`py-3.5`}
    }
`

export const RangeCtr = styled.div`
    ${tw`relative mr-[10%]`}
`

export const TextCtr = styled.div`
    ${tw`mb-10`}

    & p {
        ${tw`m-0`}
    }

    & h4 {
        ${tw`my-1`}
    }
`

export const Image = styled.img`
    ${tw`w-[260px]`}
`

export const Sub = styled.sub`
    ${tw`absolute top-[40%]`}
`

export interface TooltipAmountProps {
    left: number
}

export const TooltipAmount = styled.div<TooltipAmountProps>`
    ${tw`absolute min-w-[80px] bottom-[-30px]`}

    left: ${({ left }) => `calc(${left}% - 20px)`};

    & p {
        ${tw`m-0 font-helvetica text-[#29D57F] text-[11px] font-normal`}
    }
`

export const Amount = styled.p<TextAlign>`
    ${tw`font-montserrat font-semibold text-base mb-7.5 relative`}

    text-align: ${({ txtAlign }) => txtAlign};
`

export const CtrValues = styled.div`
    ${tw`flex items-center justify-between`}

    & p {
        ${tw`m-0 font-helvetica text-[10px] font-normal text-[#425453]`}
        & sub {
            ${tw`top-[50%]`}
        }
    }

    & p:last-child {
        ${tw`mr-2`}
    }
`
