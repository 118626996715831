// styled components
import {
    ButtonCTF,
    CardHeader,
    CardsProductsContent,
    CreditCardContainer,
    Dropdown,
} from './cardsProducts-styles'

// icons
import { CreditCardSVG, DotsMenu } from '../../../../utils/getIcons'
import { CardStates, CreditCard } from '../../../../../../domain/models'
import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'
import { useHistory } from 'react-router-dom'
import { TagState } from '../../../../components'
import { useEffect, useState } from 'react'
import BlockCard from '../../../CreditDetail/inc/BlockCard/BlockCard'
import UnBlockCard from '../../../CreditDetail/inc/UnBlockCard/UnBlockCard'
import BlockCardTheftModal from '../../../CreditDetail/inc/BlockCardTheftModal/BlockCardTheftModal'
import { parseStringToBoolean } from '../../../../utils/misc'
import { featureEnableFunctionsSelector, useSelector } from '../../../../../selectors'

const enableOnOffFunctionTcRedeemQuotas = process.env.REACT_APP_ON_OFF_FUNCTION_TC_REDEEM_QUOTAS as
    | 'false'
    | 'true'
const enableOnOffFunctionAssignProgressKey = process.env
    .REACT_APP_ON_OFF_FUNCTION_ASSIGN_PROGRESS_KEY as 'false' | 'true'
const OnOffFunctionKeyChangeForward = process.env.REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD as
    | 'false'
    | 'true'
const enableOnOffFunctionTheftLock = process.env.REACT_APP_ON_OFF_FUNCTION_THEFT_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionTemporaryLock = process.env.REACT_APP_ON_OFF_FUNCTION_TEMPORARY_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionUnLock = process.env.REACT_APP_ON_OFF_FUNCTION_UNLOCK as 'false' | 'true'
const enableOnOffFunctionProductCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_PRODUCT_CERTIFICATE as 'false' | 'true'

export const CardsProducts: React.FC<{ info: CreditCard }> = ({ info }): JSX.Element => {
    const history = useHistory()
    const [showModalTheft, setModalTheft] = useState(false)
    const handleClose = () => {
        setModalTheft(false)
        console.log('handleClose: ' + showModalTheft)
    }
    const handleShow = () => {
        setModalTheft(true)
        console.log('handleShow: ' + showModalTheft)
    }
    // events handlers BlockCardTemp
    const [showModalLockTemp, setShowModalLockTemp] = useState(false)

    const handleCloseModalLockTemp = (): void => {
        setShowModalLockTemp(false)
    }

    const handleShowLockTemp = (): void => {
        setShowModalLockTemp(true)
    }
    const [showModalUnLock, setShowModalUnLockTemp] = useState(false)
    const handleCloseModalULockTemp = (): void => {
        setShowModalUnLockTemp(false)
    }

    const handleShowUnLockTemp = (): void => {
        setShowModalUnLockTemp(true)
    }

    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (functionalityQuery.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            functionalityQuery.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
        }
    }, [functionalityQuery])

    return (
        <CardsProductsContent data-tour="credit-cards">
            <p className="">Tarjetas de crédito</p>
            <CreditCardContainer>
                <div>
                    <CardHeader>
                        <div>
                            <img src={CreditCardSVG} alt="tarjeta de crédito" />
                            <div>
                                <h3>{info.nameCard}</h3>
                                <h4>**** {info.lastFourDigits}</h4>
                            </div>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <img className="dots-menu" src={DotsMenu} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {info.lockType !== CardStates.POR_ACTIVAR && (
                                    <>
                                        <Dropdown.Item
                                            href=" "
                                            onClick={() =>
                                                history.push(
                                                    `credit-card-detail?id=${info.lastFourDigitsEncryptedCC}`
                                                )
                                            }
                                        >
                                            Ver movimientos
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href=" ">Pagar cuota</Dropdown.Item>
                                        <Dropdown.Divider />
                                        {parseStringToBoolean(
                                            enableOnOffFunctionProductCertificate
                                        ) &&
                                            enableFuntions.OnOffFunctionProductCertificate && (
                                                <>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            history.push(
                                                                `Certificate-pdf?id=${info.lastFourDigitsEncryptedCC}`
                                                            )
                                                        }
                                                    >
                                                        Descargar Certificado
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}

                                        {parseStringToBoolean(enableOnOffFunctionTcRedeemQuotas) &&
                                            enableFuntions.OnOffFunctionTcRedeemQuotas && (
                                                <>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            history.push(
                                                                `/card-redirect?id=${info.lastFourDigitsEncryptedCC}`
                                                            )
                                                        }
                                                        disabled={
                                                            info.lockType !== CardStates.ACTIVA &&
                                                            info.lockType !== CardStates.EN_MORA
                                                        }
                                                    >
                                                        Rediferir cuota
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}
                                        {parseStringToBoolean(
                                            enableOnOffFunctionAssignProgressKey
                                        ) &&
                                            enableFuntions.OnOffFunctionAssignProgressKey && (
                                                <>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            if (
                                                                info.lockType === CardStates.ACTIVA
                                                            ) {
                                                                history.push(
                                                                    `/advance-key-steps?id=${info.lastFourDigitsEncryptedCC}`
                                                                )
                                                            }
                                                        }}
                                                        disabled={
                                                            info.lockType !== CardStates.ACTIVA &&
                                                            info.lockType !== CardStates.EN_MORA
                                                        }
                                                    >
                                                        Asignar clave avances
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}
                                        {parseStringToBoolean(OnOffFunctionKeyChangeForward) &&
                                            enableFuntions.OnOffFunctionKeyChangeForward && (
                                                <>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            if (
                                                                info.lockType === CardStates.ACTIVA
                                                            ) {
                                                                history.push(
                                                                    `/change-key-advances?id=${info.lastFourDigitsEncryptedCC}`
                                                                )
                                                            }
                                                        }}
                                                        disabled={
                                                            info.lockType !== CardStates.ACTIVA &&
                                                            info.lockType !== CardStates.EN_MORA
                                                        }
                                                    >
                                                        Cambiar clave avances
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}
                                        {parseStringToBoolean(enableOnOffFunctionTemporaryLock) &&
                                            enableFuntions.OnOffFunctionTemporaryLock && (
                                                <>
                                                    <Dropdown.Item
                                                        disabled={
                                                            info.lockType !== CardStates.ACTIVA &&
                                                            info.lockType !== CardStates.EN_MORA
                                                        }
                                                    >
                                                        <div onClick={handleShowLockTemp}>
                                                            Bloquear Temporalmente
                                                        </div>

                                                        <BlockCard
                                                            showModalLockTemp={showModalLockTemp}
                                                            handleCloseModalLockTemp={
                                                                handleCloseModalLockTemp
                                                            }
                                                            lastsFourNumCard={info.lastFourDigits}
                                                            dateExpiredCard={info.dateExpiredCard}
                                                            cardNumber={info.cardNumber}
                                                        />
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}
                                        {parseStringToBoolean(enableOnOffFunctionTheftLock) &&
                                            enableFuntions.OnOffFunctionTheftLock && (
                                                <>
                                                    <Dropdown.Item
                                                        disabled={
                                                            info.lockType !== CardStates.ACTIVA &&
                                                            info.lockType !==
                                                                CardStates.BLOQUEO_TEMPORAL &&
                                                            info.lockType !== CardStates.EN_MORA
                                                        }
                                                    >
                                                        <div onClick={handleShow}>
                                                            Bloqueo por hurto
                                                        </div>
                                                        <BlockCardTheftModal
                                                            showModal={showModalTheft}
                                                            handleClose={handleClose}
                                                            lastsFourEncrypted={info.lastFourDigits}
                                                        />
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )}
                                        {parseStringToBoolean(enableOnOffFunctionUnLock) &&
                                            enableFuntions.OnOffFunctionUnLock && (
                                                <Dropdown.Item
                                                    disabled={
                                                        info.lockType === CardStates.ACTIVA ||
                                                        info.lockType ===
                                                            CardStates.BLOQUEO_PERDIDA_O_HURTO
                                                    }
                                                >
                                                    <div onClick={handleShowUnLockTemp}>
                                                        Desbloqueo Temporal
                                                    </div>
                                                    <UnBlockCard
                                                        showModalUnLock={showModalUnLock}
                                                        handleCloseModalULockTemp={
                                                            handleCloseModalULockTemp
                                                        }
                                                        lastsFourNumCard={info.lastFourDigits}
                                                        dateExpiredCard={info.dateExpiredCard}
                                                        cardNumber={info.cardNumber}
                                                    />
                                                </Dropdown.Item>
                                            )}
                                    </>
                                )}
                                {info.lockType === CardStates.POR_ACTIVAR && (
                                    <Dropdown.Item
                                        href=" "
                                        disabled={info.lockType === CardStates.POR_ACTIVAR}
                                        onClick={() =>
                                            history.push(
                                                `/activate-card?id=${info.lastFourDigitsEncryptedCC}`
                                            )
                                        }
                                    >
                                        Activar tarjeta
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </CardHeader>
                    <p className="value">
                        $ {formatValue(info.quotaAvailable, 1)}
                        <sup>{formatDecimalValue(info.quotaAvailable, 1)}</sup>
                    </p>
                    <p className="subtitle">Cupo disponible</p>
                    {
                        <TagState
                            stateCard={info.lockType}
                            stateCardCoopcentral={info.lockTypeCoopCentral}
                        />
                    }
                </div>

                <div className="link-section">
                    {info.lockType !== CardStates.POR_ACTIVAR && (
                        <ButtonCTF
                            onClick={() =>
                                history.push(
                                    `/credit-card-detail?id=${info.lastFourDigitsEncryptedCC}`
                                )
                            }
                        >
                            Ver detalles
                        </ButtonCTF>
                    )}
                    {info.lockType === CardStates.POR_ACTIVAR && (
                        <ButtonCTF
                            disabled={info.lockType === CardStates.POR_ACTIVAR}
                            onClick={() =>
                                history.push(`/activate-card?id=${info.lastFourDigitsEncryptedCC}`)
                            }
                        >
                            Activar tarjeta
                        </ButtonCTF>
                    )}
                </div>
            </CreditCardContainer>
        </CardsProductsContent>
    )
}
