import { FC, useRef, useState } from 'react'
import { ArrowCircleSVG, EyeCleanPNG, EyeSVG, LockSVG } from '../../../../utils/getIcons'
import { Row, Col, OverlayTrigger } from 'react-bootstrap'
import {
    PpalTitle,
    ArrowBlack,
    SpacerContent,
    Wrapper,
    Content,
    TextP,
    ButtonsContainer,
    ModalCancelButton,
    ModalButton,
    TextP1,
} from '../../KeyChange-styles'
import {
    FormGroup,
    FormControl,
    InputGroup,
    InputGroupText,
    FormLabel,
    FormMessage,
    Popover,
} from '../../../../components'
import cn from 'classnames'
import { useMediaQuery } from 'usehooks-ts'
import { useFormState } from 'react-hook-form'
import { KeyboardLogin } from '../../../Login/inc'
import { useLoginForm } from '../../../Login/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useChangePassword } from '../../hooks/useKeyChange'
import ModalSuccess from '../Modals/ModalSuccess'
import ModalError from '../Modals/ModalError'
import ModalLoading from '../Modals/ModalLoading'

interface StepProps {
    currentPassValue: string
    tokenSave: string
    redirection: (value: string) => void
}

// validation schema
const loginSchema = yup
    .object({
        password: yup
            .string()
            .required('Campo obligatorio')
            .min(4, 'Este campo debe contener un mínimo de 4 caracteres y un máximo de 8')
            .max(8, 'Este campo debe contener un máximo de 8 caracteres'),
        passwordConfirm: yup
            .string()
            .required('Campo obligatorio')
            .min(4, 'Este campo debe contener un mínimo de 4 caracteres y un máximo de 8')
            .max(8, 'Este campo debe contener un máximo de 8 caracteres'),
    })
    .required()

// type definitions
type PassInputsType = yup.InferType<typeof loginSchema>

const NewPass: FC<StepProps> = ({ currentPassValue, tokenSave, redirection }): JSX.Element => {
    const messagesRef = useRef({
        userNotExists: {
            type: 'not-exists',
            message: '',
        },
        pwdNotExits: {
            type: 'not-exists',
            message: '',
        },
        pwdTried: {
            type: 'pwd-tried',
            message: '',
        },
    })
    const defaultValuesRef = useRef({
        password: '',
    })

    const { control, register, getValues, setValue } = useLoginForm<PassInputsType>({
        defaultValues: defaultValuesRef.current,
        validationSchema: yupResolver(loginSchema),
        messages: messagesRef.current,
    })

    const { errors } = useFormState({ control })

    const [seePassword, setPassword] = useState(false)
    const [seePasswordConfirm, setPasswordConfirm] = useState(false)

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)
    const [showKeyboardConfirm, setShowKeyboardConfirm] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [voucherNumber, setVoucherNumber] = useState('')

    //Nueva clave
    const handlePasswordInput = (inputChanged: string): void => {
        setValue('password', inputChanged, { shouldValidate: true })
    }

    const handleFocusPassword = (): void => {
        setShowKeyboard(true)
    }

    const handleHideKeyboard = (): void => {
        setShowKeyboard(false)
    }

    //Confirmar clave
    const handlePasswordInputConfirm = (inputChanged: string): void => {
        setValue('passwordConfirm', inputChanged, { shouldValidate: true })
    }

    const handleFocusPasswordConfirm = (): void => {
        setShowKeyboardConfirm(true)
    }

    const handleHideKeyboardConfirm = (): void => {
        setShowKeyboardConfirm(false)
    }

    const ChangePassword = (data: any): void => {
        setIsLoading(true)
        useChangePassword(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Code === '00') {
                    setVoucherNumber(res.data.VoucherNumber)
                    setShowSuccess(true)
                } else {
                    setErrorMessage(res.data.Message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage(error)
                setShowError(true)
            })
    }

    const handleSubmitPass = (): void => {
        const pass = getValues('password')
        const passConfirm = getValues('passwordConfirm')
        console.log(pass)
        if (pass === passConfirm) {
            const dataToSend = {
                CurrentPassword: currentPassValue,
                NewPassword: passConfirm,
            }
            ChangePassword(dataToSend)
        } else {
            setErrorMessage('Las dos claves deben ser iguales')
            setShowError(true)
        }
    }

    const handleClose = (): void => {
        setShowSuccess(false)
        setShowError(false)
        redirection('/home-wallet')
    }

    return (
        <SpacerContent>
            <ArrowBlack onClick={() => redirection('/')}>
                <img src={ArrowCircleSVG} alt="black" />
                <p>Volver</p>
            </ArrowBlack>

            <Wrapper>
                <Content>
                    <PpalTitle>
                        Cambia tu <span>clave</span>
                    </PpalTitle>

                    <TextP>Ingresa tu clave actual</TextP>

                    <Row>
                        <Col md={3}>
                            <TextP>
                                <span>Estás a punto de cambiar tu clave de acceso al portal</span>
                            </TextP>
                        </Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={8}>
                            <TextP>Ingresa tu clave nueva</TextP>
                            <FormGroup id="content-password">
                                <FormLabel>Contraseña</FormLabel>
                                <OverlayTrigger
                                    show={showKeyboard}
                                    placement="auto-start"
                                    overlay={
                                        <Popover id="keyboard-popover" hasKeyboard>
                                            <KeyboardLogin
                                                onMouseLeave={handleHideKeyboard}
                                                onChange={handlePasswordInput}
                                                defaultValue={getValues('password')}
                                            />
                                        </Popover>
                                    }
                                >
                                    <InputGroup hasValidation>
                                        <InputGroupText $inputError={!!errors.password}>
                                            <img src={LockSVG} alt="clave" className="icon-input" />
                                        </InputGroupText>
                                        <FormControl
                                            {...register('password')}
                                            placeholder="Ingresa tu clave nueva"
                                            className="has-suffix"
                                            type={seePassword ? 'input' : 'password'}
                                            onFocus={
                                                matchMedia ? handleFocusPassword : () => void 0
                                            }
                                            readOnly={matchMedia}
                                            maxLength={8}
                                            isInvalid={!!errors.password}
                                        />

                                        <InputGroupText
                                            className={cn('icon-action', {
                                                seePassword: !seePassword,
                                                'not-seePassword': seePassword,
                                            })}
                                            onClick={() => {
                                                setPassword((sp) => !sp)
                                            }}
                                        >
                                            <img
                                                src={seePassword ? EyeCleanPNG : EyeSVG}
                                                alt="clave oculta y visible"
                                                className="icon-eye"
                                            />
                                        </InputGroupText>
                                        <FormMessage type="invalid">
                                            {errors.password && errors.password.message}
                                        </FormMessage>
                                    </InputGroup>
                                </OverlayTrigger>
                            </FormGroup>
                        </Col>

                        <Col md={3}></Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={8}>
                            <br></br>
                            <TextP1>Repite tu clave nueva</TextP1>
                            <FormGroup id="content-password">
                                {/* className={cn({ 'not-default': !userVerified })} */}
                                <FormLabel>Contraseña</FormLabel>
                                <OverlayTrigger
                                    show={showKeyboardConfirm}
                                    placement="auto-start"
                                    overlay={
                                        <Popover id="keyboard-popover" hasKeyboard>
                                            <KeyboardLogin
                                                onMouseLeave={handleHideKeyboardConfirm}
                                                onChange={handlePasswordInputConfirm}
                                                defaultValue={getValues('passwordConfirm')}
                                            />
                                        </Popover>
                                    }
                                >
                                    <InputGroup hasValidation>
                                        <InputGroupText $inputError={!!errors.passwordConfirm}>
                                            <img src={LockSVG} alt="clave" className="icon-input" />
                                        </InputGroupText>
                                        <FormControl
                                            {...register('passwordConfirm')}
                                            placeholder="Repite tu clave nueva"
                                            className="has-suffix"
                                            type={seePasswordConfirm ? 'input' : 'password'}
                                            onFocus={
                                                matchMedia
                                                    ? handleFocusPasswordConfirm
                                                    : () => void 0
                                            }
                                            readOnly={matchMedia}
                                            maxLength={8}
                                            isInvalid={!!errors.passwordConfirm}
                                        />

                                        <InputGroupText
                                            className={cn('icon-action', {
                                                seePasswordConfirm: !seePasswordConfirm,
                                                'not-seePasswordConfirm': seePasswordConfirm,
                                            })}
                                            onClick={() => {
                                                setPasswordConfirm((sp) => !sp)
                                            }}
                                        >
                                            <img
                                                src={seePasswordConfirm ? EyeCleanPNG : EyeSVG}
                                                alt="clave oculta y visible"
                                                className="icon-eye"
                                            />
                                        </InputGroupText>
                                        <FormMessage type="invalid">
                                            {errors.passwordConfirm &&
                                                errors.passwordConfirm.message}
                                        </FormMessage>
                                    </InputGroup>
                                </OverlayTrigger>
                            </FormGroup>
                        </Col>
                    </Row>

                    <ButtonsContainer>
                        <ModalCancelButton
                            variant="primary"
                            onClick={() => redirection('/home-wallet')}
                        >
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton type="button" onClick={handleSubmitPass}>
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </Content>
                <ModalSuccess
                    showModal={showSuccess}
                    handleClose={handleClose}
                    voucherNumber={voucherNumber}
                />
                <ModalError
                    showModal={showError}
                    handleClose={handleClose}
                    titleMessage={'No hemos podido completar la solicitud'}
                    errorMessage={errorMessage}
                />
                <ModalLoading isLoading={isLoading} />
            </Wrapper>
        </SpacerContent>
    )
}

export default NewPass
