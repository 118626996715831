import { FC, useEffect, useState } from 'react'
import { ButtonRequestDynamicKey, ExpirationCode } from './ProgressTimer-Styled'
import { ModalGeneric, ProgressBar } from '../../../../../../components'
import { useTimer } from '../../../../../../hooks'
import { calPercentage } from '../../../../../../utils/misc'
import { useSendOTP } from '../../../../hooks/useCreditDetail'
import { NotProductSVG } from '../../../../../../utils/getIcons'
import { useSendCodeOtpTCForgot } from '../../hooks/useAdvanceKeyCard'
import { useHistory } from 'react-router-dom'

interface ProgressTimerProps {
    tokenSave?: any
    IsForgetAdvanceKey: boolean
    lastFourDigits?: string
}

const ProgressTimer: FC<ProgressTimerProps> = ({
    tokenSave,
    IsForgetAdvanceKey,
    lastFourDigits = '',
}): JSX.Element => {
    const startMinutes = 5
    const history = useHistory()

    const { minutes, seconds, startTimer, resetTimer } = useTimer(startMinutes)
    const [percentage, setPercentage] = useState<number>(90)
    const [showRetrySendCode, setShowRetrySendCode] = useState<boolean>(true)
    const [isLoadingCode, setIsLoadingCode] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    const refreshPercentage = (minsPerc: number, secsPerc: number): void => {
        setPercentage(calPercentage(startMinutes, minsPerc + secsPerc / 60))
    }

    const refreshTimer = (): void => {
        if (minutes === 4 && seconds === 0) {
            setShowRetrySendCode(false)
        }
        refreshPercentage(minutes, seconds)
    }

    useEffect(() => {
        startTimer()
    })

    useEffect(() => {
        refreshTimer()
    }, [minutes, seconds])

    const ResetTime = (): void => {
        setIsLoadingCode(true)
        if (lastFourDigits === '') {
            SendOtp()
        } else {
            SendOtpChangeKey()
        }
    }

    const SendOtp = (): void => {
        console.log('Enviando OPT')
        useSendOTP(tokenSave)
            .then((res: any) => {
                setIsLoadingCode(false)
                resetTimer()
                setShowRetrySendCode(true)
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res['Message'])
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    const SendOtpChangeKey = (): void => {
        console.log('Enviando OPT')
        useSendCodeOtpTCForgot(lastFourDigits, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res['Message'])
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    return (
        <div>
            <ExpirationCode>
                <ProgressBar variant="warning" now={percentage} />
                {percentage === 0 ? (
                    <p>La clave ha expirado, debes solicitar otra</p>
                ) : (
                    <p>
                        Tu código expirará en {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                )}
            </ExpirationCode>

            {showRetrySendCode ? (
                <div>
                    <>
                        {!IsForgetAdvanceKey && (
                            <p style={{ textAlign: 'justify' }}>
                                Espera 1:00 para solicitar una nueva clave dinámica
                            </p>
                        )}
                        {IsForgetAdvanceKey && (
                            <p style={{ textAlign: 'justify' }}>
                                Espera 1:00 para solicitar un nuevo código
                            </p>
                        )}
                    </>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonRequestDynamicKey onClick={ResetTime} disabled={isLoadingCode}>
                        <>
                            {!IsForgetAdvanceKey && (
                                <>
                                    {isLoadingCode
                                        ? 'Solicitando clave dinámica...'
                                        : 'No recibí mi clave dinámica'}
                                </>
                            )}
                            {IsForgetAdvanceKey && (
                                <>
                                    {isLoadingCode
                                        ? 'Solicitando código de seguridad...'
                                        : 'No recibí mi código'}
                                </>
                            )}
                        </>
                    </ButtonRequestDynamicKey>
                </div>
            )}

            <ModalGeneric
                show={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleButton={handleModalCloseInfo}
                handleClose={handleModalCloseInfo}
                textButton="Aceptar"
            />
        </div>
    )
}

export default ProgressTimer
