import { FC } from 'react'
import { ForgotSVG } from '../../../../utils/getIcons'
import {
    RejectedContent,
    TextPBold,
    ContentInfoRow,
    Image,
    CancelButtonRejected,
} from '../../UserCreation-styles'

interface RejectedProps {
    wsMessage: string
    tryAgain: (value: string) => void
}

const RejectedForm: FC<RejectedProps> = ({ wsMessage, tryAgain }): JSX.Element => {
    return (
        // <Wrapper>
        <RejectedContent>
            <ContentInfoRow>
                <Image src={ForgotSVG} alt="black" />
                <TextPBold>{wsMessage}</TextPBold>
            </ContentInfoRow>
            {/* <ButtonsContainer> */}
            <CancelButtonRejected variant="primary" onClick={tryAgain}>
                Intentar de nuevo
            </CancelButtonRejected>
            {/* </ButtonsContainer> */}
        </RejectedContent>
        // </Wrapper>
    )
}

export default RejectedForm
