/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { loggedInUserRoleAction } from '../../../../redux/rolUsers'
import { loggedUserRoleSelector } from '../../../../selectors/rolUsers'
import { encryptKey } from '../../../utils/misc'

export const useLoggedRole = () => {
    const dispatch = useDispatch()

    const { loggedInUser, errorRole, loadingRole } = useSelector(loggedUserRoleSelector)

    const history = useHistory()

    useEffect(() => {
        dispatch(
            loggedInUserRoleAction(({ response, data }) => {
                if (response === '200') {
                    const isUserRol = encryptKey(data)
                    localStorage.setItem('isUserRol', isUserRol)
                }
            })
        )
    }, [])

    const redirects = (url: string): void => {
        history.push(url)
    }

    return {
        redirects,
        loggedInUser,
        loadingRole,
        errorRole,
    }
}
