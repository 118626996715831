import { FC, useRef, useState } from 'react'
import { EyeCleanPNG, EyeSVG, LockSVG } from '../../../../utils/getIcons'
import { OverlayTrigger } from 'react-bootstrap'
import {
    FormGroup,
    FormControl,
    InputGroup,
    InputGroupText,
    FormMessage,
    Popover,
} from '../../../../components'
import cn from 'classnames'
import { useMediaQuery } from 'usehooks-ts'
import { useFormState } from 'react-hook-form'
import { KeyboardLogin } from '../../../Login/inc'
import { useLoginForm } from '../../../Login/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { PassContainer } from '../../../KeyChange/KeyChange-styles'

interface PassProps {
    handlePass: (value: string) => void
}

// validation schema
const loginSchema = yup
    .object({
        password: yup
            .string()
            .required('Campo obligatorio')
            .min(4, 'Este campo debe contener un mínimo de 4 caracteres y un máximo de 8')
            .max(8, 'Este campo debe contener un máximo de 8 caracteres'),
    })
    .required()

// type definitions
type PassInputsType = yup.InferType<typeof loginSchema>

const PasswordField: FC<PassProps> = ({ handlePass }): JSX.Element => {
    const messagesRef = useRef({
        userNotExists: {
            type: 'not-exists',
            message: '',
        },
        pwdNotExits: {
            type: 'not-exists',
            message: '',
        },
        pwdTried: {
            type: 'pwd-tried',
            message: '',
        },
    })
    const defaultValuesRef = useRef({
        password: '',
    })

    const { control, register, getValues, setValue } = useLoginForm<PassInputsType>({
        defaultValues: defaultValuesRef.current,
        validationSchema: yupResolver(loginSchema),
        messages: messagesRef.current,
    })

    const { errors } = useFormState({ control })

    const [seePassword, setPassword] = useState(false)

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState(false)

    //Nueva clave
    const handlePasswordInput = (inputChanged: string): void => {
        setValue('password', inputChanged, { shouldValidate: true })
        handlePass(inputChanged)
    }

    const handleFocusPassword = (): void => {
        setShowKeyboard(true)
    }

    const handleHideKeyboard = (): void => {
        setShowKeyboard(false)
    }

    return (
        <PassContainer>
            <FormGroup id="content-password">
                <OverlayTrigger
                    show={showKeyboard}
                    placement="auto-start"
                    overlay={
                        <Popover id="keyboard-popover" hasKeyboard>
                            <KeyboardLogin
                                onMouseLeave={handleHideKeyboard}
                                onChange={handlePasswordInput}
                                defaultValue={getValues('password')}
                            />
                        </Popover>
                    }
                >
                    <InputGroup hasValidation>
                        <InputGroupText $inputError={!!errors.password}>
                            <img src={LockSVG} alt="clave" className="icon-input" />
                        </InputGroupText>
                        <FormControl
                            {...register('password')}
                            placeholder="Ingresa tu clave actual"
                            className="has-suffix"
                            type={seePassword ? 'input' : 'password'}
                            onFocus={matchMedia ? handleFocusPassword : () => void 0}
                            readOnly={matchMedia}
                            maxLength={8}
                            isInvalid={!!errors.password}
                        />

                        <InputGroupText
                            className={cn('icon-action', {
                                seePassword: !seePassword,
                                'not-seePassword': seePassword,
                            })}
                            onClick={() => {
                                setPassword((sp) => !sp)
                            }}
                        >
                            <img
                                src={seePassword ? EyeCleanPNG : EyeSVG}
                                alt="clave oculta y visible"
                                className="icon-eye"
                            />
                        </InputGroupText>
                        <FormMessage type="invalid">
                            {errors.password && errors.password.message}
                        </FormMessage>
                    </InputGroup>
                </OverlayTrigger>
            </FormGroup>
        </PassContainer>
    )
}

export default PasswordField
