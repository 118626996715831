import tw, { styled } from 'twin.macro'

export const ContainerData = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const LayoutContent = styled.div`
    ${tw`px-5 md:px-4 xl:px-0 mx-auto`}
    max-width: ${(props) => props.theme['content-max-width']};
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-4 lg:px-1`}
`

export const NoteText = styled.p`
    font-family: 'Helvetica', sans-serif;
    color: var(--sub-dominant-color) !important;
    margin-top: auto;
    margin-bottom: auto;
`
