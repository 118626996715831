import { FC, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
// styles
import {
    Wrapper,
    Content,
    YellowTitle,
    TextP,
    ButtonsContainer,
    ModalButton,
    ModalCancelButton,
    ActivateInternationalTitle,
} from '../../activateInternationalCard-Styles'
import ModalInfo from '../ModalInfo/ModalInfo'
import { useActivateInternationalCard } from '../../hooks/useInternationalCard'
import ModalAlert from '../ModalInfo/ModalAlert'
import ModalLoading from '../ModalInfo/ModalLoading'
import { useHistory } from 'react-router-dom'

interface StepProps {
    tokenSave: string
    redirections: (value: string) => void
    saveCurrentData: (value: any) => void
    lastFourNumbers: string
    dataToSend: any
}

const ThirdStep: FC<StepProps> = ({
    tokenSave,
    redirections,
    saveCurrentData,
    lastFourNumbers,
    dataToSend,
}): JSX.Element => {
    const methods = useForm()
    const { handleSubmit, getValues, control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    const [MainContactPhoneNumber, setMainContactPhoneNumber] = useState('')
    const [ContacPersonColombia, setContacPersonColombia] = useState('')
    const [ContactPhoneColombia, setContactPhoneColombia] = useState('')
    const [ContactInformation, setContactInformation] = useState('')
    const [Observations, setObservations] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [showModalAlert, setShowModalAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    let tempData: any = dataToSend

    const handleClose = () => {
        setShowModal(false)
        history.push('/')
    }

    const handleCloseAlert = () => {
        setShowModalAlert(false)
    }

    let isValid = false
    const HandleFormSubmit = () => {
        const formData = getValues()

        if (MainContactPhoneNumber.length < 10 || ContactPhoneColombia.length < 10) {
            isValid = false

            if (MainContactPhoneNumber.length < 10) {
                setError('MainContactPhoneNumber', {
                    message: 'El número de teléfono no es válido',
                })
            }

            if (ContactPhoneColombia.length < 10) {
                setError('ContactPhoneColombia', {
                    message: 'El número de teléfono no es válido',
                })
            }
        } else {
            isValid = true
        }

        if (isValid) {
            setIsLoading(true)
            saveCurrentData(formData)
            const lengthData = Object.keys(dataToSend).length
            if (lengthData < 5) {
                tempData = { ...tempData, ...formData }
                SaveAllData(tempData)
            } else {
                SaveAllData(dataToSend)
            }
        }
    }

    const SaveAllData = (data: any) => {
        console.log(data)
        useActivateInternationalCard(tokenSave, data)
            .then((res: any) => {
                console.log(res)
                setIsLoading(false)
                if (res['status'] === 200) {
                    setShowModal(true)
                } else {
                    setShowModalAlert(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setShowModalAlert(true)
            })
    }

    return (
        <>
            <ActivateInternationalTitle>
                Solicita la activación de tu tarjeta de crédito <span>fuera de Colombia</span>
            </ActivateInternationalTitle>

            <Wrapper>
                <FormProvider {...methods}>
                    <Content>
                        <YellowTitle>Paso 3 de 3</YellowTitle>
                        <TextP>Queremos mantenernos en contacto contigo</TextP>
                        <YellowTitle>Teléfono de contacto principal</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'MainContactPhoneNumber'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        className="hide-number-arrows"
                                        type="number"
                                        {...register('MainContactPhoneNumber', { required: true })}
                                        maxLength={10}
                                        value={MainContactPhoneNumber}
                                        placeholder="Ej. 300 123 1234"
                                        required
                                        isInvalid={!!errors['MainContactPhoneNumber']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('MainContactPhoneNumber')
                                                if (e.target.value.length <= 10) {
                                                    setMainContactPhoneNumber(e.target.value)
                                                }
                                            } else {
                                                setMainContactPhoneNumber(e.target.value)
                                            }
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('MainContactPhoneNumber', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('MainContactPhoneNumber')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['MainContactPhoneNumber'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['MainContactPhoneNumber']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <YellowTitle>Persona de contacto en Colombia</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'ContacPersonColombia'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        type="text"
                                        {...register('ContacPersonColombia', { required: true })}
                                        maxLength={40}
                                        value={ContacPersonColombia}
                                        placeholder="Ej. John Doe (Hermano, Madre, persona de contacto)"
                                        required
                                        isInvalid={!!errors['ContacPersonColombia']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('ContacPersonColombia')
                                            }
                                            setContacPersonColombia(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('ContacPersonColombia', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('ContacPersonColombia')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['ContacPersonColombia'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['ContacPersonColombia']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <YellowTitle>Teléfono de contacto persona en Colombia</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'ContactPhoneColombia'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        className="hide-number-arrows"
                                        type="number"
                                        {...register('ContactPhoneColombia', { required: true })}
                                        maxLength={10}
                                        value={ContactPhoneColombia}
                                        placeholder="Ej. 300 123 1234"
                                        required
                                        isInvalid={!!errors['ContactPhoneColombia']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('ContactPhoneColombia')
                                                if (e.target.value.length <= 10) {
                                                    setContactPhoneColombia(e.target.value)
                                                }
                                            } else {
                                                setContactPhoneColombia(e.target.value)
                                            }
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('ContactPhoneColombia', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('ContactPhoneColombia')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['ContactPhoneColombia'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['ContactPhoneColombia']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <YellowTitle>¿Cómo podemos contactar a esta persona?</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'ContactInformation'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        type="text"
                                        {...register('ContactInformation', { required: true })}
                                        maxLength={100}
                                        value={ContactInformation}
                                        placeholder="Ej. Escribir a WhatsApp en la mañana"
                                        required
                                        isInvalid={!!errors['ContactInformation']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('ContactInformation')
                                            }
                                            setContactInformation(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('ContactInformation', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('ContactInformation')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['ContactInformation'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['ContactInformation']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <YellowTitle>Observaciones</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'Observations'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        type="textarea"
                                        {...register('Observations', { required: true })}
                                        maxLength={500}
                                        value={Observations}
                                        placeholder="Ej. ¿Algo que nos quieras contar sobre tu viaje?"
                                        required
                                        isInvalid={!!errors['Observations']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('Observations')
                                            }
                                            setObservations(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('Observations', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('Observations')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['Observations'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['Observations']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(HandleFormSubmit)}>
                                <ButtonsContainer>
                                    <ModalCancelButton
                                        variant="primary"
                                        onClick={() => redirections('/')}
                                    >
                                        Cancelar
                                    </ModalCancelButton>
                                    <ModalButton
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleSubmit(HandleFormSubmit)}
                                    >
                                        Finalizar
                                    </ModalButton>
                                </ButtonsContainer>
                            </form>
                        </FormProvider>
                    </Content>
                </FormProvider>
            </Wrapper>
            <ModalInfo
                showModal={showModal}
                handleClose={handleClose}
                lastFourNumbers={lastFourNumbers}
            />
            <ModalAlert showModalAlert={showModalAlert} handleClose={handleCloseAlert} />
            <ModalLoading isLoading={isLoading} />
        </>
    )
}

export default ThirdStep
