import { AxiosResponse } from 'axios'
import { certificateService } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetInfoAssociate(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetAgreementCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetInfoFai(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetFaiAccountClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
