import { Modal } from 'react-bootstrap'
// icons
import { NotProductSVG } from '../../../../utils/getIcons'
// styles
import { InfoModal, ModalTitle, ModalText, ButtonsContainer, ModalButton } from './Modal-Styled'

interface ModalProps {
    showModal: boolean
    handleClose: () => void
    titleMessage: string
    errorMessage: string
    img?: string
}

const ModalError: React.FC<ModalProps> = ({
    showModal,
    handleClose,
    titleMessage,
    errorMessage,
    img,
}): JSX.Element => {
    const handleClick = (): void => {
        handleClose()
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={img ?? NotProductSVG} alt="Editar logo" className="" />
                <ModalTitle>{titleMessage}</ModalTitle>
                <ModalText>{errorMessage}</ModalText>

                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Aceptar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalError
