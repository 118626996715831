import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import { ContainerPossessions } from '../PossessionsInsert/PossessionsInsert-styles'
import TitleModule from './inc/TitleModule'
import { useSelector, useDispatch } from 'react-redux'
import { Content, ContentInfo, TextPBold, Image, TextPCash, ContentInfo1 } from './Pledge-styles'
import { getSeveranceQuoteClient } from '../../../redux/products'
import { PignoracionCesantias } from '../../utils/getImages'
import { useGetInfoAssociate } from './hooks/usePledge'

const Pledge = (): JSX.Element => {
    const history = useHistory()
    const [fullName, setFullName] = useState('')
    const [quote, setQuote] = useState('')
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(
            getSeveranceQuoteClient((res) => {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
                setQuote(formatter.format(res.data.quote))
                console.log('res', res)
            })
        )
        GetInfoAssociate()
    }, [])

    const GetInfoAssociate = (): void => {
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                setFullName(res.data.Data[0].Name)
                console.log(JSON.stringify(res))
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Cuota por pignoración de cesantías',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <ContainerPossessions>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <br />
                <TitleModule />
                <Content>
                    <ContentInfo>
                        <Image src={PignoracionCesantias} />
                    </ContentInfo>
                    {quote !== '$0.00' ? (
                        <ContentInfo1>
                            <TextPBold>
                                <span>Sr(a)</span>
                                <br></br>
                                <span>Valor</span>
                            </TextPBold>
                            <TextPCash>
                                {fullName}
                                <br></br>
                                {quote}
                            </TextPCash>
                        </ContentInfo1>
                    ) : (
                        <ContentInfo1>
                            <TextPBold>
                                <span>No se encontraron datos para cuota anual</span>
                            </TextPBold>
                        </ContentInfo1>
                    )}
                </Content>
            </ContainerPossessions>
        </LayoutContent>
    )
}

export default Pledge
