import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { Container, ContainerTitle, Title } from './PaymentScheduleRegistrationTC-Styled'
import { BreadcrumbApp, Loading, ModalGeneric } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { faiAccountExistSelector } from '../../../selectors'
import { useEffect, useState } from 'react'
import { getFaiAccountBalanceAction } from '../../../redux/products'
import { LoadingContainer } from '../CreditDetail/creditDetail-styles'
import SecondStep from './inc/SecondStep/SecondStep'
import FirstStep from './inc/FirstStep'
import { NotProductSVG } from '../../utils/getIcons'

const PaymentScheduleRegistrationTC = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [step, setStep] = useState<number>(1)
    const { isAccountFai, faiAccount, loading, error } = useSelector(faiAccountExistSelector)

    useEffect(() => {
        dispatch(getFaiAccountBalanceAction())
    }, [dispatch])

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Solicitud de productos',
            active: true,
        },
    ]

    const handleStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (step > 1) {
                            setStep(step - 1)
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />
                <ContainerTitle>
                    <Title>
                        Inscribe el <span>pago automático</span> de tu tarjeta de crédito
                    </Title>
                </ContainerTitle>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {step === 1 && (
                            <FirstStep
                                activeFAI={faiAccount}
                                onNextStep={() => handleStepChange(2)}
                                isAccountFai={isAccountFai}
                            ></FirstStep>
                        )}
                        {step === 2 && <SecondStep activeFAI={faiAccount}></SecondStep>}
                    </>
                )}
                {Object.keys(error).length > 0 && (
                    <ModalGeneric
                        show={true}
                        img={NotProductSVG}
                        textTitle={'¡Ha ocurrido un error!'}
                        textBody={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleButton={handleClose}
                        handleClose={handleClose}
                        textButton="Aceptar"
                    />
                )}
            </Container>
        </LayoutContent>
    )
}

export default PaymentScheduleRegistrationTC
