import tw, { styled } from 'twin.macro'
import { Form } from 'react-bootstrap'

import {
    FormLabel as FormLabelApp,
    FormGroup as FormGroupApp,
} from '../../../../components/includes'

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: center;
`

export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
`
interface TopPosition {
    top?: string
}

export const Image = styled.img<TopPosition>`
    ${tw`z-[50]`}
    margin-top: ${(props) => (props.top ? props.top : '')};
`

export const CtrOpeningCreditCard = styled.section`
    ${tw`mt-0 py-[15px] px-0`}
    ${tw`lg:pt-5 lg:pb-10 lg:px-0 lg:min-h-[600px]`}
`

export const TitleStep = styled.h4`
    ${tw`font-helvetica font-normal block text-dominant-dark text-base my-3`}
`

export const ParagraphStep = styled.p`
    ${tw`font-helvetica font-normal block text-black text-base my-3`}
`

export const SpaceStep = styled.div`
    ${tw`w-full h-1.5`}
`

export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}

    @media screen and (max-width: 450px) {
        text-align: left !important;
        text-align-last: left;
    }
`

export interface FormGroupProps {
    $heightSize?: string
}

export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0 my-2`}

    height: ${(props) => props.$heightSize};
`

export const FormMessageSelect = styled.p`
    ${tw`font-montserrat font-normal text-sm text-alert mt-[5px] mb-0`}
    ${tw`absolute bottom-0`}
`

export const FormSelect = styled(Form.Select)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-10 border-none cursor-pointer`}
    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}

    &:disabled {
        ${tw`bg-transparent`}
    }
`

interface FormOptionProps {
    show?: boolean
}

export const FormOption = styled.option<FormOptionProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`
