import { AxiosResponse } from 'axios'
import { userManagement } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetRememberLogin(tokenSave: string, docNumber: string): Promise<Result> {
    try {
        return await userManagement
            .get(`/api/RolUsers/GetRememberLogin?cedula=${docNumber}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetBlocking(
    tokenSave: string,
    userName: string,
    docNumber: string
): Promise<Result> {
    try {
        return await userManagement
            .post(`/api/RolUsers/GetBlocking?user=${userName}&cedula=${docNumber}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useLockUnlock(
    tokenSave: string,
    userName: string,
    docNumber: string,
    block: string
): Promise<Result> {
    try {
        return await userManagement
            .post(
                `/api/RolUsers/LockUnlock?user=${userName}&cedula=${docNumber}&block=${block}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUserInitializes(
    tokenSave: string,
    userName: string,
    docNumber: string
): Promise<Result> {
    try {
        return await userManagement
            .post(`/api/RolUsers/UserInitializes?user=${userName}&cedula=${docNumber}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function fetchGetFullNameUser(
    tokenSave: string,
    docNumber: string,
    userName: string
): Promise<Result> {
    try {
        return await userManagement
            .get(
                `/api/RolUsers/GetFullNameUser?documentNumber=${docNumber}&userName=${userName}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
