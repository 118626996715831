import { TermTitle, possessionsService } from '../../../../../domain/services/User.service'

export async function useGetSecurity(tokenSave: string) {
    try {
        return await possessionsService
            .useGetSecurity('api/Installments/GetAll', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useServiceTerm(
    tokenSave: string,
    periodicity: string,
    quotaNumber: string,
    value: string
) {
    try {
        return await TermTitle.useGetSecurity(
            `api/Client/GetRecreationalTermTitleTarClient?periodicity=${periodicity}&quotaNumber=${quotaNumber}&value=${value}`,
            tokenSave
        ).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
