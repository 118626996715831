import {
    DocumentContent,
    HeaderText,
    PagePdf,
    RemittentText,
    SubHeaderText,
    Text,
} from './PDFViewerMobile-Styled'

interface PDFViewerMobileProps {
    Header: string
    Body: string
    Footer: string
    Remittent: string
}
const PDFViewerMobile: React.FC<PDFViewerMobileProps> = ({ Header, Body, Footer, Remittent }) => {
    return (
        <PagePdf>
            <DocumentContent>
                <HeaderText>
                    Fondo de Empleados de los Trabajadores <br />y Pensionados de Ecopetrol S.A
                    CAVIPETROL
                </HeaderText>
                <SubHeaderText>Identificado con NIT 860.006.773-2</SubHeaderText>
                <HeaderText>{Header}</HeaderText>
                <br></br>
                <Text>{Body}</Text>
                <Text>{Footer}</Text>
                <RemittentText>{Remittent.replace('Remitente: ', '')}</RemittentText>
            </DocumentContent>
        </PagePdf>
    )
}

export default PDFViewerMobile
