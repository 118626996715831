import { Modal } from 'react-bootstrap'
// icons
import { CheckOperationSVG } from '../../../../utils/getIcons'
// styles
import { InfoModal, ModalTitle, ModalText, ButtonsContainer, ModalButton } from './ModalInfo-Styled'
import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    lastFourNumbers: string
}

const ModalInfo: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    lastFourNumbers,
}): JSX.Element => {
    const history = useHistory()
    const handleClick = () => {
        history.push('/')
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CheckOperationSVG} alt="" className="CheckOperationSVG" />
                <ModalTitle>Todo listo</ModalTitle>
                <ModalText>
                    `Tu solicitud de uso de la tarjeta de crédito *{lastFourNumbers} ha sido
                    radicada, recibirás una respuesta de tu solicitud vía correo en 3 días hábiles`
                </ModalText>

                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Continuar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalInfo
