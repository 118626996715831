import { Controller, useForm } from 'react-hook-form'
import { Button } from '../../../../components'
import { Col, Form, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useSelector } from '../../../../../selectors'
import { useGetValidCaviFacilQuota } from '../../hooks/useCaviFacil'
import { Wrapper } from '../../CavifacilTransaction-Styled'
import {
    Content,
    ContainerButtons,
    FromContent,
    FormLabel,
    TextP,
    TextQuota,
    TextAmount,
} from './FirstStep-Styled'
import ModalLoading from '../Modals'
import ModalError from '../Modals/ModalError'
import { useHistory } from 'react-router-dom'
interface FirsStepProps {
    associatedName: string
    associatedDocumentNumber: string
    amountTransferValue: string
    onNextStep: () => void
    saveDataTransfer: (amountTransfer: string) => void
}
const FirstStep: React.FC<FirsStepProps> = ({
    associatedName,
    associatedDocumentNumber,
    amountTransferValue,
    onNextStep,
    saveDataTransfer,
}) => {
    const methods = useForm()
    const { control, formState, setError, clearErrors, register } = methods
    const { errors } = formState
    const history = useHistory()

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [amountTransfer, setAmountTransfer] = useState<string>('')
    const [amountTransferFormat, setAmountTransferFormat] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [quotaAvailable, setQuotaAvailable] = useState<string>('')
    const [quota, setQuota] = useState<string>('')
    const [balance, setBalance] = useState<string>('')
    const [amountMax, setAmountMax] = useState<number>(0)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    const GetValidCaviFacilQuota = (): void => {
        setIsLoading(true)
        useGetValidCaviFacilQuota(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                const responseStatus = res.data?.Response || res.Response

                if (responseIsSuccess) {
                    const quotaRes = parseFloat(res.data.Data.Quota)
                    const quotaAvailableRes = parseFloat(res.data.Data.QuotaAvailable)
                    const quotaBalanceRes = parseFloat(res.data.Data.Balance)
                    setAmountMax(res.data.Data.QuotaAvailable)

                    setQuotaAvailable(formatter.format(quotaAvailableRes))
                    setQuota(formatter.format(quotaRes))
                    setBalance(formatter.format(quotaBalanceRes))
                } else if (responseStatus === '500') {
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                } else {
                    setErrorMessage(res.Data.Message)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        GetValidCaviFacilQuota()
        if (amountTransferValue !== '') {
            setAmountTransferFormat(formatter.format(parseFloat(amountTransferValue ?? '0')))
            setAmountTransfer(amountTransferValue)
        }
    }, [tokenSave])

    const handleClose = (): void => {
        setShowError(false)
        history.push('/home-wallet')
    }

    const handleContinue = (): void => {
        saveDataTransfer(amountTransfer)
        onNextStep()
    }
    return (
        <Wrapper>
            <Content>
                <FormLabel>Solicitud</FormLabel>
                <TextP>
                    Te invitamos a diligenciar el siguiente formulario para continuar con la
                    solicitud.
                </TextP>
                <FromContent>
                    <Row>
                        <Col md={5} xs={10} lg={5}>
                            <FormLabel>Nombre</FormLabel>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="input-icon-user"
                                    type="text"
                                    value={associatedName.toLocaleLowerCase()}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Cédula de Ciudadanía/Extranjería</FormLabel>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="input-icon-destination-id"
                                    type="text"
                                    value={associatedDocumentNumber}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <TextQuota>Cupo Autorizado</TextQuota>
                            <TextAmount>{quota}</TextAmount>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <TextQuota>Cupo Utilizado</TextQuota>
                            <TextAmount>{balance}</TextAmount>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <TextQuota>Cupo Disponible</TextQuota>
                            <TextAmount>{quotaAvailable}</TextAmount>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} xs={10} lg={6}>
                            <FormLabel>
                                Valor a utilizar <span>(Obligatorio)</span>
                            </FormLabel>

                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'amountTransferFormat'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-amount-transfer"
                                            type="text"
                                            {...register('amountTransferFormat', {
                                                required: true,
                                            })}
                                            value={amountTransferFormat}
                                            placeholder="Ingresa el valor que deseas utilizar"
                                            required
                                            isInvalid={!!errors['amountTransferFormat']}
                                            onChange={(e) => {
                                                const min = 100000
                                                const max = amountMax

                                                const numericValue = e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ''
                                                )

                                                if (e.target.value !== '') {
                                                    setAmountTransferFormat(e.target.value)
                                                    clearErrors('amountTransferFormat')
                                                    const value = parseFloat(numericValue)
                                                    const numericValueRegex = /^[0-9,\b$]+$/
                                                    const isValid = numericValueRegex.test(
                                                        e.target.value
                                                    )
                                                    if (!isValid) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'Por favor ingresar solo dígitos numéricos',
                                                        })
                                                        setAmountTransfer('')
                                                    } else if (value < min) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'El valor a utilizar debe ser mayor a $ 100.000',
                                                        })
                                                        setAmountTransfer('')
                                                    } else if (value > max) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'El valor a utilizar es mayor que el cupo disponible',
                                                        })
                                                        setAmountTransfer('')
                                                    } else {
                                                        setAmountTransfer(numericValue)
                                                    }

                                                    setAmountTransferFormat(formatter.format(value))
                                                } else {
                                                    setAmountTransferFormat('')
                                                    setError('amountTransferFormat', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('amountTransferFormat', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['amountTransferFormat'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['amountTransferFormat']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        {/* <Col md={4} xs={2} lg={4} className="position-col">
                            <TextP className="infoInput">(Obligatorio)</TextP>
                        </Col> */}
                    </Row>
                </FromContent>
                <ContainerButtons>
                    <Button
                        variant="sub-dominant"
                        type="submit"
                        extend
                        disabled={amountTransfer === ''}
                        onClick={handleContinue}
                    >
                        Continuar
                    </Button>
                </ContainerButtons>
            </Content>
            <ModalLoading
                isLoading={isLoading}
                textInfo="Aguarda un momento"
                textLoading={'Estamos consultamos tu información.'}
            />

            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
        </Wrapper>
    )
}

export default FirstStep
