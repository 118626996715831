import { Modal } from 'react-bootstrap'
import {
    ButtonsContainer,
    ModalButtonConfirmation,
    ModalConfirmation,
} from './ConfirmationModal-Styled'
import { CheckOperationSVG } from '../../../../../../utils/getIcons'

interface ConfirmationModalAppProps {
    showModal?: boolean
    handleCloseModal: () => void
    lastsFourNumCard?: string
    textBodyConfirmation?: string
}

const ConfirmationModal: React.FC<ConfirmationModalAppProps> = ({
    showModal,
    handleCloseModal,
    lastsFourNumCard,
    textBodyConfirmation,
}): JSX.Element => {
    return (
        <>
            <ModalConfirmation
                show={showModal}
                onHide={handleCloseModal}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img src={CheckOperationSVG} alt="" className="" />
                    <h6 className="title-modal-login"> Todo listo</h6>
                    <p>
                        {textBodyConfirmation} *{lastsFourNumCard}
                    </p>
                    <ButtonsContainer>
                        <ModalButtonConfirmation variant="primary" onClick={handleCloseModal}>
                            Aceptar
                        </ModalButtonConfirmation>
                    </ButtonsContainer>
                </Modal.Body>
            </ModalConfirmation>
        </>
    )
}

export default ConfirmationModal
