import { AxiosResponse } from 'axios'
import { dataUpdateAssociate } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function getDepartment(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getDepartment('/api/User/GetDepartment', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getCities(tokenSave: string, departmentName: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getCities('/api/User/GetCities?departmentName=' + departmentName, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getListUpdateData(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate.getListUpdateData('/', tokenSave).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getListCitiesByCountriesUser(
    tokenSave: string,
    countryParam: string
): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getListCitiesByCountriesUser(
                '/api/UserLists/GetListCitiesByCountriesUser?countryParam=' + countryParam,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getUpdateDataAssociate(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getUpdateData('api/User/GetUserCrm', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function postUpdateDataAssociate(tokenSave: string, data: any): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .postUpdateData('/api/User/UpdateAssociate', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function postUpdateUserCrm(tokenSave: string, data: any): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .postUpdateData('/api/User/UpdateUserCrm', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getUnicTablesCrm(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getListUpdateData('/api/UserLists/UnicTablesCrm', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function getListCitiesByCodeDane(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getListUpdateData('/api/UserLists/GetListCitiesByCodeDane', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function citiesByDepartment(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getListUpdateData('/api/User/GetCityCrm', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function fetchCountryCrm(tokenSave: string): Promise<Result> {
    try {
        return await dataUpdateAssociate
            .getListUpdateData('api/User/GetCountryCrm', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
