import { AxiosResponse } from 'axios'
import { alertRequestTC } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetAlertsTC(tokenSave: string): Promise<Result> {
    try {
        return await alertRequestTC
            .getAlerts('/api/CreditCardRequest/GetAlertsTC', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetAlertsNotificationsTC(tokenSave: string): Promise<Result> {
    try {
        return await alertRequestTC
            .getAlerts('/api/CreditCardRequest/GetAlertsNotificationsTC', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetCreditCardRequest(tokenSave: string): Promise<Result> {
    try {
        return await alertRequestTC
            .getAlerts('/api/CreditCardRequest/GetCreditCardRequest', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useDeleteCreditCardRequest(tokenSave: string, id: number): Promise<Result> {
    try {
        return await alertRequestTC
            .deleteTransaction(`/api/CreditCardRequest/DeleteCreditCardRequest?id=${id}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
