import { Content, Wrapper } from '../../FaiAccountTransfer-Styled'
import { TextInfo, TextP, UserDate } from './CommonInfo-Styled'

interface CommonInfoProps {
    associatedName: string
}
const CommonInfo: React.FC<CommonInfoProps> = ({ associatedName }) => {
    return (
        <Wrapper>
            <Content>
                <UserDate>
                    {' '}
                    Apreciado(a): <span>{associatedName}</span>
                </UserDate>
                <TextInfo>
                    A través de esta opción podrás realizar transferencias de dinero a cuentas de
                    ahorro FAI de otros afiliados, por favor ten en cuenta las siguientes
                    recomendaciones.
                </TextInfo>
                <ul>
                    <li>
                        <TextP>El monto mínimo es de $10.000 por transacción.</TextP>
                    </li>
                    <li>
                        <TextP>El monto máximo son $12.000.000 por transacción.</TextP>
                    </li>
                    <li>
                        <TextP>El monto máximo diario de transferencias es de $12.000.000.</TextP>
                    </li>
                    <li>
                        <TextP>
                            Ingresa y verifica que el monto a transferir no incluye centavos.
                        </TextP>
                    </li>
                    <li>
                        <TextP>
                            Ingresa y verifica el número de cuenta FAI y cédula del afiliado al cual
                            deseas hacer la transferencia, ten en cuenta que al seleccionar
                            continuar podrás verificar la información del afiliado al cual le será
                            abonado el valor de la transferencia.
                        </TextP>
                    </li>
                    <li>
                        <TextP>El destinatario debe tener tarjeta asignada y activada.</TextP>
                    </li>
                </ul>
            </Content>
        </Wrapper>
    )
}

export default CommonInfo
