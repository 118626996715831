import { FC, useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import BuildingForm from '../BuildingsForm/BuildingForm'
import Title from '../TitleModule'

import {
    usePossessionsForm,
    PossessionsFormType,
    possessionsFormSchema,
} from '../../hooks/usePossessionsForm'

import { Form, InputSelectGroup } from '../../../../components'
import {
    PossesionsFormContent,
    PossesionsFormWrapper,
    FormGroup,
    FormLabel,
    FormSelect,
    FormOption,
    ButtonConsignar,
} from './PossessionsForm-Styles'
import TermsAndConditions from '../TermsAndConditions/index'
import { possessionsService } from '../../../../../../domain/services/User.service'
import useData from '../../hooks/useData'
import ImageForm from '../ImageForm/ImageForm'
import { useSelector } from 'react-redux'
import { MessageContent } from '../CommonFields/CommonFields-Styles'

interface FormData {
    tipoInmueble: string
    accion: string
    configuration: any
}

interface PossessionInsertProps {
    handleBuildingName: (params: string) => void
    handleActiongType: (params: string) => void
}

const PossessionsForm: FC<PossessionInsertProps> = ({
    handleBuildingName,
    handleActiongType,
}): JSX.Element => {
    const { typeProperty, actions } = useData()
    const { register, handleSubmit, errors } = usePossessionsForm<PossessionsFormType>({
        defaultValues: {
            tipoInmueble: '',
            accion: '',
        },
        validationSchema: yupResolver(possessionsFormSchema),
    })
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [formData, setFormData] = useState<FormData | null>(null)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const handleAcceptTerms = () => {
        setAcceptedTerms(true)
    }

    const handleRejectTerms = () => {
        console.log('User did not accept terms and conditions.')
    }

    useEffect(() => {
        console.log('aquí el formData' + formData)
    }, [formData])

    if (!acceptedTerms) {
        return <TermsAndConditions onAccept={handleAcceptTerms} onReject={handleRejectTerms} />
    }

    const jsonData = [{ id: 0, text: 'Error data' }]
    const jsonDataB = [{ id: 0, text: 'Error Data' }]

    interface ActionsInterface {
        Data: any[]
    }
    const TBusiness =
        actions && Array.isArray((actions as ActionsInterface).Data)
            ? (actions as ActionsInterface).Data
            : jsonDataB

    const typesProper =
        typeProperty && Array.isArray((typeProperty as ActionsInterface).Data)
            ? (typeProperty as ActionsInterface).Data
            : jsonData

    const onSubmit = async (data: FormData) => {
        setBreadcrumb(data.tipoInmueble, data.accion)
        data.configuration = await possessionsService
            .useGetSecurity(
                `api/RealEstateExchangeM2/GetAmenities?IdTypeProperty=${data.tipoInmueble}`,
                tokenSave
            )
            .then((response: any) => {
                if (response != undefined) {
                    const newArray = response.Data.map((item: any) => {
                        return {
                            amenity: item.Amenity,
                            required: item.Required,
                            restrictions: item.Restrictions,
                            name: item.Name,
                        }
                    })

                    data.configuration = newArray
                    return response.Data
                }
            })
        setFormData(data)
    }

    const setBreadcrumb = (typeBuilding: string, accionType: string): void => {
        const buildingOption = typesProper.find((option) => option.Id == typeBuilding)
        if (buildingOption) {
            handleBuildingName(buildingOption.Text)
        }

        const actionOption = TBusiness.find((option) => option.Id == accionType)
        if (actionOption) {
            handleActiongType(actionOption.Text)
        }
    }

    return (
        <>
            {!formData && (
                <PossesionsFormWrapper>
                    <PossesionsFormContent>
                        <ImageForm />
                        <Title />
                        <MessageContent>
                            <p>
                                Cuando usted consigne su propiedad en Cavipetrol, para un mejor
                                servicio, credibilidad y efectividad de su gestión inmobiliaria,
                                esta será publicada en el portal de finca raíz Metrocuadrado.com:
                                por lo tanto su propiedad podrá ser consultada tanto por asociados
                                como por el público en general a nivel nacional e internacional.
                            </p>
                        </MessageContent>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                                <FormLabel>Tipo de inmueble</FormLabel>
                                <InputSelectGroup $isError={!!errors.tipoInmueble}>
                                    <FormSelect size="lg" {...register('tipoInmueble')}>
                                        <FormOption disabled value="">
                                            Selecciona una opción
                                        </FormOption>
                                        {typesProper.map((option) => (
                                            <FormOption key={option.Id} value={option.Id}>
                                                {option.Text}
                                            </FormOption>
                                        ))}
                                    </FormSelect>
                                    {errors.tipoInmueble && (
                                        <div className="error-message">Campo obligatorio</div>
                                    )}
                                </InputSelectGroup>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Clase de negocio</FormLabel>
                                <InputSelectGroup $isError={!!errors.accion}>
                                    <FormSelect size="lg" {...register('accion')}>
                                        <FormOption disabled value="">
                                            Selecciona una opción
                                        </FormOption>
                                        {TBusiness.map((option) => (
                                            <FormOption key={option.Id} value={option.Id}>
                                                {option.Text}
                                            </FormOption>
                                        ))}
                                    </FormSelect>
                                    {errors.accion && (
                                        <div className="error-message">Campo obligatorio</div>
                                    )}
                                </InputSelectGroup>
                            </FormGroup>
                            <ButtonConsignar disabled={errors.accion} type="submit">
                                Consignar
                            </ButtonConsignar>
                        </Form>
                    </PossesionsFormContent>
                </PossesionsFormWrapper>
            )}
            {formData?.tipoInmueble != null && (
                <BuildingForm
                    tipoInmueble={formData.tipoInmueble}
                    accion={formData.accion}
                    configuration={formData.configuration}
                />
            )}
        </>
    )
}

export default PossessionsForm
