import { Modal } from 'react-bootstrap'

// icons
import { CreditCardFlowSVG } from '../../../../utils/getIcons'

// styles
import {
    ButtonsContainer,
    CreditModal,
    ModalButton,
    BLockCardTheftTitle,
    ModalButtonBack,
} from './BlockCardTheft-Styled'

import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    lastsFourEncrypted: string
}

const BlockCardTheft: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    lastsFourEncrypted,
}): JSX.Element => {
    const history = useHistory()

    const redirections = (url: string): void => {
        history.push(url)
    }

    return (
        <CreditModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <img src={CreditCardFlowSVG} alt="CreditCardFlowSVG" className="" />
                <BLockCardTheftTitle>¿Deseas bloquear tu tarjeta?</BLockCardTheftTitle>
                <p>
                    Al bloquear tu tarjeta por pérdida o hurto, inmediatamente la bloquearemos y
                    recibirás un nuevo plástico, migraremos tu información y saldos a tu nueva
                    tarjeta.
                </p>
                <ButtonsContainer>
                    <ModalButton
                        variant="primary"
                        onClick={() => redirections(`/block-card-steps?id=${lastsFourEncrypted}`)}
                    >
                        Bloquear
                    </ModalButton>
                    <ModalButtonBack variant="primary" onClick={handleClose}>
                        Mantener activa
                    </ModalButtonBack>
                </ButtonsContainer>
            </Modal.Body>
        </CreditModal>
    )
}

export default BlockCardTheft
