import React, { FC, useState, useEffect, useRef } from 'react'
import { InputOtp } from './BlockCardTheft-Styled'
import { useValidateOTP } from '../../hooks/useCreditDetail'
import ModalInfo from '../ModalInfo/ModalInfo'

interface OtpProps {
    tokenSave: any
    handleLoading: (value: boolean) => void
}

let currentOTPIndex = 0

const OtpInput: FC<OtpProps> = ({ tokenSave, handleLoading }): JSX.Element => {
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(''))
    const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0)
    const inputRef = useRef<HTMLInputElement>(null)

    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    const [failedAttempts, setFailedAttempts] = useState<number>(0)
    const maxFailedAttempts = 3 // Número máximo de intentos fallidos permitidos

    const handleClose = () => {
        setShowModal(false)
    }

    const HandleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        if (failedAttempts >= maxFailedAttempts) {
            // Si ya se superó el número máximo de intentos fallidos, no permitas más entradas
            return
        }
        const { value } = target
        const newOTP: string[] = [...otp]
        newOTP[currentOTPIndex] = value.substring(value.length - 1)

        if (!value) {
            setActiveOTPIndex(currentOTPIndex - 1)
        } else {
            setActiveOTPIndex(currentOTPIndex + 1)
        }

        setOtp(newOTP)
    }

    const OtpValidation = () => {
        const otpToValidate = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5]
        useValidateOTP(tokenSave, otpToValidate).then((res: any) => {
            console.log('validate status', res)
            if (res['status'] === 200) {
                handleLoading(false)
                console.log('código valido')
            } else {
                setFailedAttempts(failedAttempts + 1) // Incrementa el contador de intentos fallidos
                if (failedAttempts + 1 >= maxFailedAttempts) {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'Su código ha sido bloqueado por exceder intentos. Intenta más tarde o comunícate con servicio al asociado.'
                    )
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo ingresado es inválido, por favor intente de nuevo'
                    )
                }
            }
        })
    }

    useEffect(() => {
        if (otp.every((number) => number !== '')) {
            OtpValidation()
        }
    }, [otp])

    const handleOnKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (failedAttempts >= maxFailedAttempts) {
            // Si ya se superó el número máximo de intentos fallidos, no permitas más entradas
            return
        }
        currentOTPIndex = index
        if (key === 'Backspace') {
            setActiveOTPIndex(currentOTPIndex - 1)
        }
    }

    useEffect(() => {
        inputRef.current?.focus()
    }, [activeOTPIndex])

    return (
        <div>
            {otp.map((_, index) => {
                return (
                    <>
                        <React.Fragment key={index}>
                            <InputOtp
                                ref={index === activeOTPIndex ? inputRef : null}
                                type="text"
                                onChange={HandleOnChange}
                                onKeyDown={(e) => handleOnKeyDown(e, index)}
                                value={otp[index]}
                                disabled={failedAttempts >= maxFailedAttempts} // Deshabilita la entrada después de bloquear
                            />
                        </React.Fragment>
                        <ModalInfo
                            showModal={showModal}
                            textTitle={textTitleModal}
                            textBody={textBodyModal}
                            handleClose={handleClose}
                        />
                    </>
                )
            })}
        </div>
    )
}

export default OtpInput
