import { userRepository } from '../../infrastructure/repositories/user.repository'

export const userService = {
    getUsers: (endpoint: string) => {
        return userRepository.useGetUser(endpoint)
    },
    getCreateSecurity: (endpoint: string) => {
        return userRepository.useGet(endpoint)
    },
    createUser: (endpoint: string, body: any) => {
        return userRepository.usePost(endpoint, body)
    },
}

export const authenticationService = {
    getLogin: (endpoint: string, body: any) => {
        return userRepository.usePost(endpoint, body).then()
    },
    getRefresh: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body).then()
    },
    getCode: (endpoint: string) => {
        return userRepository.getCode(endpoint).then()
    },
}
export const paymentService = {
    getHomePay: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
}
export const transactionService = {
    getTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
    getStatus: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
    postTransaction: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
    postInfoTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
    postTransactionPublic: (endpoint: string, body: any) => {
        return userRepository.usePost(endpoint, body)
    },
    putTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.putTransaction(endpoint, token, body)
    },
    deleteTransaction: (endpoint: string, token: string) => {
        return userRepository.deleteTransaction(endpoint, token)
    },
}

export const possessionsService = {
    getTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
    useGetSecurity: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
    putTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.putTransaction(endpoint, token, body)
    },
    postImagesWithData: (endpoint: string, body: any, files: File[], token: string) => {
        return userRepository.postImagesWithData(endpoint, body, files, token)
    },
}

export const TermTitle = {
    useGetSecurity: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
}

export const blockCardService = {
    getUserInfo: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
    getOfficeInfo: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
    getReissueCard: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },
    sendOTP: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
    validateOTP: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
    postStatusTC: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
    postTransactionStatusTC: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const internationalCardService = {
    validateCardNumber: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },

    validateDate: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },

    activateCard: (endpoint: string, token: string, body: any) => {
        return userRepository.getTransaction(endpoint, token, body)
    },
}

export const avanceKeyCard = {
    getValidateExpirationDate: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    getValidateTriviality: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postPasswordChange: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    postPasswordChangeByForgot: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    postProductAvailability: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    postPasswordAssignment: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    postSendCodeOtpTCForgot: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
}

export const onboardingService = {
    getOnboardingState: (endpoint: string, token: string) => {
        return userRepository.useGetSecurity(endpoint, token)
    },

    saveOnboardingState: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const paymentScheduleRegistrationTC = {
    postInscriptionScheduledPayment: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const codebtorsService = {
    getCodebtorData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
}

export const scoresquotasService = {
    getScorequotasData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
}

export const balancesExtractsService = {
    getCreditSaving: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    getDataUser: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    getBenefits: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postDetails: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    getAgreementCertificateClient: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    getBalancesAndStatements: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
}

export const paymentScheduleCancellationTC = {
    getScheduledPaymentFAI: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postCancellationScheduledPayment: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const CampaignFormService = {
    postFileWithData: (endpoint: string, body: any, files: File[], token: string) => {
        return userRepository.postFileWithData(endpoint, body, files, token)
    },

    putFileWithData: (endpoint: string, body: any, files: File[], token: string) => {
        return userRepository.putFileWithData(endpoint, body, files, token)
    },
    getCampaignData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postUploadFilesCampaign: (endpoint: string, files: File[], token: string) => {
        return userRepository.postUploadFilesCampaign(endpoint, files, token)
    },

    getCoreData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
}
export const CertificateRetention = {
    getCertificateData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postPdf: (endpoint: string, token: string, body: string) => {
        return userRepository.postPdf(endpoint, token, body)
    },
}

export const advanceToFAIAccount = {
    getRates: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    creditAdvanceFai: (endpoint: string, token: string, body: string) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const keyChangeService = {
    validateIVR: (endpoint: string, token: string, body: string) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    validatePassword: (endpoint: string, token: string, body: string) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    changePassword: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const certificateService = {
    getData: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
}

export const creditSimulationService = {
    getCreditSimulator: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postRateFeeSimulator: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
    postPaymentPlanSimulator: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const faiAccountTransfer = {
    getGetFaiAccount: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postTransactionFai: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const dataUpdateAssociate = {
    getDepartment: (endpoint: string, token: string) => {
        return userRepository.getDepartment(endpoint, token)
    },
    getCities: (endpoint: string, token: string) => {
        return userRepository.getCities(endpoint, token)
    },
    getListUpdateData: (endpoint: string, token: string) => {
        return userRepository.getListUpdateData(endpoint, token)
    },
    getListCitiesByCountriesUser: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
    getUpdateData: (endpoint: string, token: string) => {
        return userRepository.getUpdateDataAssociate(endpoint, token)
    },
    postUpdateData: (endpoint: string, token: string, body: any) => {
        return userRepository.postUpdateDataAssociate(endpoint, token, body)
    },
    postWithoutBody: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
}

export const caviFacilTransfer = {
    getValidCaviFacilQuota: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    postCavifacilTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const interbankTransfer = {
    getData: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
    postTransaction: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}

export const alertRequestTC = {
    getAlerts: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    deleteTransaction: (endpoint: string, token: string) => {
        return userRepository.deleteTransaction(endpoint, token)
    },
}

export const userManagement = {
    get: (endpoint: string, token: string) => {
        return userRepository.getTransactionSecurityTC(endpoint, token)
    },
    post: (endpoint: string, token: string) => {
        return userRepository.usePostSecurity(endpoint, token)
    },
    put: (endpoint: string, token: string) => {
        return userRepository.putTransactionSecurityLight(endpoint, token)
    },
    patchLight: (endpoint: string, token: string) => {
        return userRepository.patchTransactionSecurityLight(endpoint, token)
    },
    patch: (endpoint: string, token: string, body: any) => {
        return userRepository.patchTransactionSecurity(endpoint, token, body)
    },
    postBody: (endpoint: string, token: string, body: any) => {
        return userRepository.postTransactionSecurity(endpoint, token, body)
    },
}
