import { FC, useEffect, useRef, useState } from 'react'
import {
    Wrapper,
    Content,
    TextSubtitle,
    TextInfo,
    InputKeyDynamic,
    ContainerInputs,
    LinkServiceAssociate,
    ButtonRequestDynamicKey,
    ExpirationCode,
    ButtonsContainer,
    CancelButton,
    ButtonContent,
} from './DynamicKeyValidation-Styled'

import { useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import {
    useSendOTP,
    useUserInfo,
    useValidateOTP,
} from '../../../CreditDetail/hooks/useCreditDetail'
import { Button, ModalGeneric, ProgressBar } from '../../../../components'
import { ArrowSVG, NotProductSVG } from '../../../../utils/getIcons'
import { Col, Container, Row } from 'react-bootstrap'
import { calPercentage } from '../../../../utils/misc'
import { useTimer } from '../../../../hooks'

interface DynamicKeyValidationProps {
    handleNextStep: (value: string) => void
}

const DynamicKeyValidation: FC<DynamicKeyValidationProps> = ({ handleNextStep }): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const token = state.auth.token
    const [showError, setShowError] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const [errorTextBody, setErrorTextBody] = useState('')
    const history = useHistory()

    const [inputs, setInputs] = useState<string[]>(['', '', '', '', '', ''])
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const [isFailedAttempt, setIsFailedAttempt] = useState<boolean>(false)

    const [error, setError] = useState<boolean>(false)
    const [messageError, setMessageError] = useState('')

    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')

    const startMinutes = 5

    const { minutes, seconds, startTimer, resetTimer } = useTimer(startMinutes)
    const [percentage, setPercentage] = useState<number>(90)
    const [showRetrySendCode, setShowRetrySendCode] = useState<boolean>(true)
    const [isLoadingCode, setIsLoadingCode] = useState<boolean>(false)
    const [isLoadingValidate, setIsLoadingValidate] = useState<boolean>(false)
    const [isValidate, setIsValidate] = useState<boolean>(false)

    const refreshPercentage = (minsPerc: number, secsPerc: number): void => {
        setPercentage(calPercentage(startMinutes, minsPerc + secsPerc / 60))
    }

    const refreshTimer = (): void => {
        if (minutes === 4 && seconds === 0) {
            setShowRetrySendCode(false)
        }
        refreshPercentage(minutes, seconds)
    }

    useEffect(() => {
        startTimer()
    })

    useEffect(() => {
        refreshTimer()
    }, [minutes, seconds])

    useEffect(() => {
        GetUserInfo()
    }, [])

    const ResetTime = (): void => {
        HandleOtp()
        resetTimer()
        setIsLoadingCode(true)
    }

    const GetUserInfo = (): void => {
        useUserInfo(token)
            .then((res: any) => {
                if (res['Response'] === '200') {
                    HandleOtp()
                    setCellphone(res['Data']['CellPhone'])
                    setEmail(res['Data']['Email'])
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleCloseModal = (): void => {
        setShowError(false)
        history.push('/home-wallet')
    }

    const HandleOtp = (): void => {
        useSendOTP(token)
            .then((res: any) => {
                setIsLoadingCode(false)
                resetTimer()
                setShowRetrySendCode(true)
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowError(true)
                    setErrorTitle('¡Ha ocurrido un error!')
                    setErrorTextBody(res['Message'])
                } else {
                    setShowError(true)
                    setErrorTitle('¡Ha ocurrido un error!')
                    setErrorTextBody(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorTitle('¡Ha ocurrido un error!')
                setErrorTextBody('No se ha podido conectar con el servidor')
            })
    }

    useEffect(() => {
        inputRefs.current[0]?.focus()
    }, [])

    const OtpValidation = (otpToValidate: string): void => {
        setIsLoadingValidate(true)
        useValidateOTP(token, otpToValidate)
            .then((res: any) => {
                setIsLoadingValidate(false)
                if (res['status'] === 200) {
                    setIsValidate(true)
                } else if (res['Response'] === '403' || res['Response'] === '429') {
                    setError(true)
                    setMessageError(res['Message'])
                    if (res['Message'].includes('exceder')) {
                        setIsFailedAttempt(true)
                        setMessageError(
                            'Su código ha sido bloqueado por exceder intentos. Intenta más tarde o comunícate con servicio al asociado.'
                        )
                    }
                } else {
                    setShowError(true)
                    setErrorTitle('¡Ha ocurrido un error!')
                    setErrorTextBody(
                        'Al validar la código de seguridad, por favor intente de nuevo.'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorTitle('¡Ha ocurrido un error!')
                setErrorTextBody('No se ha podido conectar con el servidor')
            })
    }

    const validateSequence = (value: string): boolean => {
        if (value.length !== 6) {
            return true
        } else {
            return false
        }
    }

    const handleInputChange = (index: number, value: string): void => {
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newInputs = [...inputs]
            newInputs[index] = value
            setInputs(newInputs)
            setError(false)
            if (value && index < inputs.length - 1) {
                inputRefs.current[index + 1]?.focus()
            }

            if (index === inputs.length - 1) {
                const enteredNumber = newInputs.join('')
                if (enteredNumber.length === 6) {
                    OtpValidation(enteredNumber)
                }
            }
            validateSequence(newInputs.join(''))
        } else if (value === '') {
            const newInputs = [...inputs]
            newInputs[index] = ''
            setInputs(newInputs)
            setError(false)

            if (index > 0) {
                inputRefs.current[index - 1]?.focus()
            }
            validateSequence(newInputs.join(''))
        } else {
            setError(true)
            setMessageError('Ingrese solo dígitos numéricos.')
        }
    }

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Backspace' && !inputs[index]) {
            const newInputs = [...inputs]
            newInputs[index] = ''
            setInputs(newInputs)
            setError(false)

            if (index > 0) {
                inputRefs.current[index - 1]?.focus()
            }
            validateSequence(newInputs.join(''))
        }
    }

    const handleFormSubmit = (): void => {
        handleNextStep('personalInfo')
    }

    return (
        <Wrapper>
            <Content>
                <TextInfo>
                    Por seguridad debemos validar tu identidad, ingresa el código de seguridad que
                    recibiste al celular terminado en {cellphone} y a tu correo electrónico {email}
                </TextInfo>

                <TextSubtitle>Código de seguridad</TextSubtitle>
                <ContainerInputs>
                    <Container>
                        <Row>
                            <Col md={12}>
                                {inputs.map((value, index) => (
                                    <InputKeyDynamic
                                        key={index}
                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        maxLength={1}
                                        style={{ borderColor: error ? '#dc3545' : '' }}
                                        disabled={isFailedAttempt}
                                    />
                                ))}
                            </Col>
                        </Row>
                        <Row style={{ textAlign: 'justify' }}>
                            <Col md={12}>
                                {error && (
                                    <p style={{ color: '#dc3545', fontSize: '14px' }}>
                                        {messageError}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        {isFailedAttempt ? (
                            <Row>
                                <Col style={{ textAlign: 'initial' }}>
                                    <LinkServiceAssociate
                                        onClick={() => history.push('/support-private')}
                                    >
                                        {' '}
                                        <img src={ArrowSVG} alt="ArrowSVG" />
                                        {'   '} Contactar con servicio al asociado
                                    </LinkServiceAssociate>
                                </Col>
                            </Row>
                        ) : (
                            <div>
                                <ExpirationCode>
                                    <ProgressBar variant="warning" now={percentage} />
                                    {percentage === 0 ? (
                                        <p>
                                            El código de seguridad ha expirado, debes solicitar otro
                                        </p>
                                    ) : (
                                        <p>
                                            Este código de seguridad expirará en {minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    )}
                                </ExpirationCode>

                                {showRetrySendCode ? (
                                    <div>
                                        <p style={{ textAlign: 'justify' }}>
                                            Espera 1:00 para solicitar un nuevo código
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <ButtonRequestDynamicKey
                                            onClick={ResetTime}
                                            disabled={isLoadingCode}
                                        >
                                            <>
                                                {isLoadingCode
                                                    ? 'Solicitando código...'
                                                    : 'No recibí mi código '}
                                            </>
                                        </ButtonRequestDynamicKey>
                                    </div>
                                )}
                            </div>
                        )}
                    </Container>
                </ContainerInputs>

                <ButtonsContainer>
                    <CancelButton onClick={() => history.push('/home-wallet')}>
                        Cancelar
                    </CancelButton>

                    <ButtonContent>
                        <Button
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={() => {
                                handleFormSubmit()
                            }}
                            isLoading={isLoadingValidate}
                            extend
                            disabled={!isValidate}
                        >
                            Continuar
                        </Button>
                    </ButtonContent>
                </ButtonsContainer>
            </Content>

            <ModalGeneric
                show={showError}
                img={NotProductSVG}
                textTitle={errorTitle}
                textBody={errorTextBody}
                handleButton={handleCloseModal}
                handleClose={handleCloseModal}
                textButton="Aceptar"
            />
        </Wrapper>
    )
}

export default DynamicKeyValidation
