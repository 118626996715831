const storageUrl = process.env.REACT_APP_STORAGE_URL as string
const storage = `${storageUrl}/assets`

export const PignoracionCesantias = `${storage}/pignoracion-cesantias.png`
export const NetworksPNG = `${storage}/networks.png`
export const WomenPNG = `${storage}/women.png`
export const SuperSolidaria = `${storage}/super2x.png`
export const Cavipetrol = `${storage}/cavipetrol2x.png`
export const Logo = `${storage}/logo.png`
export const UserPhonePNG = `${storage}/user-phone.png`
export const IconPsePNG = `${storage}/Logo-PSE.png`
export const LoginPNG = `${storage}/login-form.png`
export const CertifiedWatermark = `${storage}/CertifiedWatermark.png`
export const BannerCavipetrol = `${storage}/Banner-Cavipetrol.jpg`
export const LogoMetroCuadrado = `${storage}/logoM2.png`
export const tasas2 = `${storage}/tasas2.png`

export const PointsPNG = `${storage}/points.png`
export const PointsSVG = `${storage}/points.svg`
export const ElipsePNG = `${storage}/elipse.png`
export const ElipseDeskPNG = `${storage}/elipse-desk.png`
export const SemiCirclePNG = `${storage}/semi-circle.png`
export const SemiCircleSVG = `${storage}/semi-circle.svg`
export const ForgetPasswordSVG = `${storage}/forget-password.svg`
export const ForgetPasswordPNG = `${storage}/forget-password.png`
export const FondoCertificado = `${storage}/FondoCertificado.png`
export const IconoCertificado = `${storage}/IconoCertificado.png`
export const LogoCertificado = `${storage}/CavipetrolCert.png`
export const LogoGrande = `${storage}/Logo2.png`
export const CierreOperativo = `${storage}/Cierre_Operativo.jpg`
