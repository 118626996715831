import { FC, useEffect, useState } from 'react'
// styles
import {
    LabelBase,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    ButtonsContainer,
    ModalButton,
    FormSelect,
    ProductContent,
    ProductTableContainer,
    UserTextP,
    PpalTitleExtract,
    CancelButton,
} from '../../BalancesExtracts-styles'
import { Form, Row, Col } from 'react-bootstrap'
import { FormProvider, useForm, Controller } from 'react-hook-form'

interface ProductDetailType {
    productTypeDocument: string
    productDocumentNumber: number
    finishedNumber: number
    documentNumber: string
}

interface PreviousBalanceType {
    documentNumber: string
    productTypeDocument: string
    productDocumentNumber: number
    finishedNumber: number
    fechaInicio: Date
    fechaFin: Date
}

interface FinalBalanceType {
    document: string
    productDocumentName: string
    productDocumentNumber: number
    finishedNumber: number
    endDate: string
}
interface MovementDetailType {
    document: string
    productDocumentName: string
    productDocumentNumber: number
    finishedNumber: number
    startDate: string
    endDate: string
}
interface MovementCxCType {
    ProductDocumentName: string
    ProductDocumentNumber: number
    FinishedNumber: number
    StartDate: string
    EndDate: string
}

type ProductsDataType = {
    Acount: string
    Balance: number
    Concept: string
    Description: string
    FinishedNumber: string
    ProductDocumentNumber: string
    ProductDocumentType: string
}

interface ProductProps {
    formatter: (value: number) => string
    productData: ProductsDataType[]
    showDetailBtn: boolean
    document: string
    showLoanDetail: (value: any) => void
    showMovements: (
        previous: any,
        finalValue: any,
        movDValue: any,
        movCValue: any,
        periodDates: string,
        prodNumber: number,
        finishNumber: number
    ) => void
}

const BalanceExtractsByProduct: FC<ProductProps> = ({
    formatter,
    productData,
    showDetailBtn,
    document,
    showLoanDetail,
    showMovements,
}): JSX.Element => {
    const methods = useForm()
    const { getValues, control, formState, setError, clearErrors } = methods
    const { errors } = formState

    const [dateOptions, setDateOptions] = useState<string[]>([])
    const [fromDate, setFromDate] = useState('')
    const [untilDate, setUntilDate] = useState('')

    const firstProduct = productData[0]
    const totalBalance = productData.reduce((total, product) => total + product.Balance, 0)
    const [productToSend, setProductToSend] = useState<ProductDetailType>({
        productTypeDocument: '',
        productDocumentNumber: 0,
        finishedNumber: 0,
        documentNumber: '',
    })

    useEffect(() => {
        const currentDate = new Date()
        const newOptions: string[] = []

        for (let i = 0; i < 4; i++) {
            const year = currentDate.getFullYear()
            const month = currentDate.getMonth() + 1
            const value = `${year}${(month < 10 ? '0' : '') + month}`
            const text = getMonthName(month) + ' de ' + year

            newOptions.push(value + '|' + text)
            currentDate.setMonth(currentDate.getMonth() - 1)
        }

        setDateOptions(newOptions)
    }, [])

    useEffect(() => {
        if (firstProduct) {
            const updatedProductToSend: ProductDetailType = {
                productTypeDocument: firstProduct.ProductDocumentType,
                productDocumentNumber: parseInt(firstProduct.ProductDocumentNumber),
                finishedNumber: parseInt(firstProduct.FinishedNumber),
                documentNumber: document,
            }
            setProductToSend(updatedProductToSend)
        }
    }, [firstProduct])

    const getMonthName = (month: number) => {
        const monthNames = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ]
        return monthNames[month - 1]
    }

    const getLastDayOfMonth = (optionValue: string) => {
        const year = parseInt(optionValue.substring(0, 4), 10)
        const month = parseInt(optionValue.substring(4), 10)

        switch (month) {
            case 4: // Abril
            case 6: // Junio
            case 9: // Septiembre
            case 11: // Noviembre
                return 30
            case 2: // Febrero
                // Comprobar si el año es bisiesto
                if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                    return 29 // Febrero tiene 29 días en años bisiestos
                } else {
                    return 28 // Febrero tiene 28 días en años no bisiestos
                }
            default:
                return 31
        }
    }

    const handleClickExtract = (): void => {
        const formData = getValues()
        if (!formData.FromDate) {
            setError('FromDate', { message: 'Este campo es obligatorio' })
            return
        }
        if (!formData.UntilDate) {
            setError('UntilDate', { message: 'Este campo es obligatorio' })
            return
        }
        const lastDayOfMonth = getLastDayOfMonth(formData.UntilDate)
        const fromDate = convertToDateTime(formData.FromDate)
        const untilDate = convertToDateTime(formData.UntilDate)
        if (fromDate.getTime() <= untilDate.getTime()) {
            formData.FromDate = formData.FromDate + '01'
            formData.UntilDate = formData.UntilDate + lastDayOfMonth.toString()
            const previousBalance = savePreviousBalance(formData.FromDate, formData.UntilDate)
            const finalBalance = saveFinalBalance(formData.UntilDate)
            const movementDetail = saveMovementDetail(formData.FromDate, formData.UntilDate)
            const movementCxC = saveMovementCxC(formData.FromDate, formData.UntilDate)
            const prodNumber = parseInt(firstProduct.ProductDocumentNumber)
            const finishNumber = parseInt(firstProduct.FinishedNumber)
            const periodDates =
                convertToDateFormatted(formData.FromDate) +
                ' al ' +
                convertToDateFormatted(formData.UntilDate)
            showMovements(
                previousBalance,
                finalBalance,
                movementDetail,
                movementCxC,
                periodDates,
                prodNumber,
                finishNumber
            )
        } else {
            setError('FromDate', {
                message: 'El mes inicial debe ser menor o igual al mes final',
            })
        }
    }

    function convertToDateFormatted(dateString: string) {
        const year = dateString.slice(0, 4)
        const month = dateString.slice(4, 6)
        const day = dateString.slice(6, 8)

        return `${day}/${month}/${year}`
    }

    const convertToDateTime = (date: string) => {
        const dateString = date
        const year = parseInt(dateString.slice(0, 4), 10)
        const month = parseInt(dateString.slice(4, 6), 10) - 1
        const day = 1

        const dateObject = new Date(year, month, day, 0, 0, 0, 0)

        return dateObject
    }
    const savePreviousBalance = (startDate: string, endDate: string) => {
        const updatedPreviousBalance: PreviousBalanceType = {
            documentNumber: document,
            productTypeDocument: firstProduct.ProductDocumentType,
            productDocumentNumber: parseInt(firstProduct.ProductDocumentNumber),
            finishedNumber: parseInt(firstProduct.FinishedNumber),
            fechaInicio: convertToDateTime(startDate),
            fechaFin: convertToDateTime(endDate),
        }
        return updatedPreviousBalance
    }
    const saveFinalBalance = (endDate: string) => {
        const updatedFinalBalance: FinalBalanceType = {
            document: document,
            productDocumentName: firstProduct.ProductDocumentType,
            productDocumentNumber: parseInt(firstProduct.ProductDocumentNumber),
            finishedNumber: parseInt(firstProduct.FinishedNumber),
            endDate: endDate,
        }
        return updatedFinalBalance
    }
    const saveMovementDetail = (startDate: string, endDate: string) => {
        const updatedMovementDetail: MovementDetailType = {
            document: document,
            productDocumentName: firstProduct.ProductDocumentType,
            productDocumentNumber: parseInt(firstProduct.ProductDocumentNumber),
            finishedNumber: parseInt(firstProduct.FinishedNumber),
            startDate: startDate,
            endDate: endDate,
        }
        return updatedMovementDetail
    }
    const saveMovementCxC = (startDate: string, endDate: string) => {
        const updatedMovementCxC: MovementCxCType = {
            ProductDocumentName: firstProduct.ProductDocumentType,
            ProductDocumentNumber: parseInt(firstProduct.ProductDocumentNumber),
            FinishedNumber: parseInt(firstProduct.FinishedNumber),
            StartDate: startDate,
            EndDate: endDate,
        }
        return updatedMovementCxC
    }

    const handleClickDetail = (): void => {
        showLoanDetail(productToSend)
    }

    return (
        <ProductContent>
            <div>
                <Col className="justify-content-md-center">
                    <Row>
                        <UserTextP>
                            <span>Producto:</span> {firstProduct.Description}
                        </UserTextP>
                    </Row>
                    <Row>
                        <LabelBase></LabelBase>
                    </Row>
                    <Row>
                        <UserTextP>
                            <span>Número de documento:</span> {firstProduct.ProductDocumentNumber}
                        </UserTextP>
                    </Row>
                    <Row>
                        <UserTextP>
                            <span>Término:</span> {firstProduct.FinishedNumber}
                        </UserTextP>
                    </Row>
                </Col>
            </div>

            <br></br>

            <ProductTableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Número</th>
                        <th>Saldo</th>
                        <th>Descripción</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    {productData.map((product) => (
                        <RowTable>
                            <td>{product.Acount}</td>
                            <td>{formatter(product.Balance)}</td>
                            <td>{product.Description}</td>
                        </RowTable>
                    ))}
                    <RowTable>
                        <td className="text-bold">Total</td>
                        <td>{formatter(totalBalance)}</td>
                        <td></td>
                    </RowTable>
                </TBody>
            </ProductTableContainer>

            <br></br>

            <div>
                <PpalTitleExtract>Selección de extractos trimestrales</PpalTitleExtract>
            </div>

            <div className="form-container">
                <FormProvider {...methods}>
                    <Row>
                        <Col md={2} className="v-align">
                            <LabelBase>Desde</LabelBase>
                        </Col>
                        <Col md={4} className="v-align">
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'FromDate'}
                                    rules={{ required: false }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <FormSelect>
                                            <Form.Select
                                                className="selectFromControl"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value !== '' ||
                                                        e.target.value === null
                                                    ) {
                                                        clearErrors('FromDate')
                                                    }
                                                    setFromDate(e.target.value)
                                                    onChange(e)
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        e.target.value === null
                                                    ) {
                                                        setError('FromDate', {
                                                            message: 'Este campo es obligatorio',
                                                        })
                                                    } else {
                                                        clearErrors('FromDate')
                                                    }
                                                    onBlur()
                                                }}
                                                value={fromDate}
                                                isInvalid={!!errors['FromDate']}
                                            >
                                                <option value="">Selecciona...</option>
                                                {dateOptions.map((option) => {
                                                    const [value, text] = option.split('|')
                                                    return (
                                                        <option key={value} value={value}>
                                                            {text}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </FormSelect>
                                    )}
                                />
                                {errors['FromDate'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['FromDate']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col md={2} className="v-align">
                            <LabelBase>Hasta</LabelBase>
                        </Col>
                        <Col md={4} className="v-align">
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'UntilDate'}
                                    rules={{ required: false }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <FormSelect>
                                            <Form.Select
                                                className="selectFromControl"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value !== '' ||
                                                        e.target.value === null
                                                    ) {
                                                        clearErrors('UntilDate')
                                                    }
                                                    setUntilDate(e.target.value)
                                                    onChange(e)
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        e.target.value === null
                                                    ) {
                                                        setError('UntilDate', {
                                                            message: 'Este campo es obligatorio',
                                                        })
                                                    } else {
                                                        clearErrors('UntilDate')
                                                    }
                                                    onBlur()
                                                }}
                                                value={untilDate}
                                                isInvalid={!!errors['UntilDate']}
                                            >
                                                <option value="">Selecciona...</option>
                                                {dateOptions.map((option) => {
                                                    const [value, text] = option.split('|')
                                                    return (
                                                        <option key={value} value={value}>
                                                            {text}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </FormSelect>
                                    )}
                                />
                                {errors['UntilDate'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['UntilDate']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <br></br>

                    <ButtonsContainer>
                        <ModalButton onClick={handleClickExtract}>Extractos</ModalButton>
                        {showDetailBtn && (
                            <CancelButton onClick={handleClickDetail}>Detalles</CancelButton>
                        )}
                    </ButtonsContainer>
                </FormProvider>
            </div>
        </ProductContent>
    )
}

export default BalanceExtractsByProduct
