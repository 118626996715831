import React, { useEffect, useState } from 'react'
import { LoadingContainer } from '../../AdvanceToFAIAccount-Styled'
import SelectCreditCard from '../SelectCreditCard/SelectCreditCard'
import DelinquentProductShowcase from '../DelinquentProductShowcase'
import { useGetTotalBalanceArrears } from '../../hooks/useAdvanceToFAIAccount'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../components'
import { GetFaiAccountBalance } from '../../../../../../domain/models'

interface TransactionData {
    selectedCard: string
    valorAvance: string
    month: string
    year: string
    quota: string
    cvv: string
}
interface FirstStepProps {
    activeFAI: GetFaiAccountBalance
    isGoBack: boolean
    handleGoBack: (value: boolean) => void
    onNextStep: (step: number) => void
    handleIsBalanceArrears: (value: boolean) => void
    isAccountFai: boolean
    saveDataAdvanceFAI: (
        amountAdvance: string,
        cardNumber: string,
        nameCard: string,
        rateOfInterest: string,
        lastsFourNumCard: string,
        expiredDateCard: string,
        ccvValue: string,
        numberFee: string,
        transactionData: TransactionData
    ) => void
    dataTransaction: TransactionData | null
}
const FirstStep: React.FC<FirstStepProps> = ({
    onNextStep,
    handleIsBalanceArrears,
    isAccountFai,
    isGoBack,
    handleGoBack,
    saveDataAdvanceFAI,
    dataTransaction,
}) => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [delinquentBalanceCredit, setDelinquentBalanceCredit] = useState<number>(0)
    const [delinquentBalanceSaving, setDelinquentBalanceSaving] = useState<number>(0)
    const [delinquentBalanceTC, setDelinquentBalanceTC] = useState<number>(0)
    const [isBalanceArrears, setIsBalanceArrears] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const GetTotalBalanceArrears = (): void => {
        setIsLoading(true)
        useGetTotalBalanceArrears(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res['data']['Response'] === '200') {
                    const delinquentCredit = res.data.Data.DelinquentBalanceCredit
                    const delinquentSaving = res.data.Data.DelinquentBalanceSaving
                    const delinquentTC = res.data.Data.DelinquentBalanceTC
                    const flag = delinquentCredit > 0 || delinquentSaving > 0 || delinquentTC > 0
                    if (flag) {
                        setDelinquentBalanceCredit(delinquentCredit)
                        setDelinquentBalanceSaving(delinquentSaving)
                        setDelinquentBalanceTC(delinquentTC)
                        setIsBalanceArrears(true)
                        handleIsBalanceArrears(true)
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (isAccountFai && !dataTransaction) {
            GetTotalBalanceArrears()
        }
    }, [tokenSave])

    return (
        <>
            {isLoading ? (
                <LoadingContainer>
                    <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                </LoadingContainer>
            ) : (
                <>
                    {isBalanceArrears ? (
                        <DelinquentProductShowcase
                            delinquentBalanceCredit={delinquentBalanceCredit}
                            delinquentBalanceSaving={delinquentBalanceSaving}
                            delinquentBalanceTC={delinquentBalanceTC}
                        />
                    ) : (
                        <SelectCreditCard
                            isAccountFai={isAccountFai}
                            isGoBack={isGoBack}
                            handleGoBack={handleGoBack}
                            onNextStep={onNextStep}
                            saveDataAdvanceFAI={saveDataAdvanceFAI}
                            dataTransaction={dataTransaction}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default FirstStep
