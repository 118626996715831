import tw, { styled } from 'twin.macro'

export const Container = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`
