import React, { useState } from 'react'
import {
    ModalBlockCard,
    ButtonsContainer,
    ButtonContent,
    ModalButton,
    ModalButtonConfirmation,
} from './BlockCard-Styles'
import { CheckOperationSVG, CreditCardFlowSVG } from '../../../../utils/getIcons'
import { Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useStatusTC } from '../../hooks/useCreditDetail'
import { Button, LoadingScreen } from '../../../../components'
import { getCreditCardsAction } from '../../../../../redux/tc'
import { CreditCardsSelector } from '../../../../../selectors'
import ModalInfo from '../ModalInfo/ModalInfo'

interface BlockCardAppProps {
    showModalLockTemp?: boolean
    handleCloseModalLockTemp: () => void
    lastsFourNumCard?: string
    dateExpiredCard?: string
    cardNumber?: string
}

const BlockCard: React.FC<BlockCardAppProps> = ({
    showModalLockTemp,
    handleCloseModalLockTemp,
    lastsFourNumCard,
    dateExpiredCard,
    cardNumber,
}): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [modalIsOpenConfirmation, setModalIsOpenConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [showModal, setShowModal] = useState(false)
    // selectors
    const { loading } = useSelector(CreditCardsSelector)

    const openModalConfirmation = (): void => {
        setModalIsOpenConfirmation(true)
        handleCloseModalLockTemp()
    }

    const closeModalConfirmation = (): void => {
        setModalIsOpenConfirmation(false)
        dispatch(getCreditCardsAction())
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }

    const HandleFormSubmit = (): void => {
        setIsLoading(true)

        const dataSend = {
            TransactionDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            TransactionTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            EffectiveDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            Consecutive: '10',
            NumberCard: cardNumber,
            Filler2: dateExpiredCard,
            Status: '4', //4 Bloqueo Temporal
        }

        // Construye el FormData
        const formDataToSend = new FormData()
        formDataToSend.append('body', JSON.stringify(dataSend))

        useStatusTC(formDataToSend, tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res['status'] === 200 && res.data.IsSuccess) {
                    openModalConfirmation()
                } else {
                    handleCloseModalLockTemp()
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'No se ha podido bloquear temporalmente la tarjeta, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                handleCloseModalLockTemp()
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    return (
        <>
            <ModalBlockCard
                show={showModalLockTemp}
                onHide={handleCloseModalLockTemp}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={CreditCardFlowSVG} alt="CreditCardFlowSVG" />
                    <h6 className="title-modal-login">
                        ¿Deseas bloquear temporalmente tu tarjeta?
                    </h6>
                    <p>
                        Al bloquear temporalmente tu tarjeta no podrás utilizarla o comprar en
                        ningún establecimiento, sin embargo, deberás continuar con el pago mensual.
                    </p>
                    <ButtonsContainer>
                        <ModalButton variant="primary" onClick={handleCloseModalLockTemp}>
                            Mantener Activa
                        </ModalButton>

                        <ButtonContent>
                            <Button
                                id="btnSubmit"
                                className="button-block"
                                variant="sub-dominant"
                                type="button"
                                onClick={HandleFormSubmit}
                                isLoading={isLoading}
                                extend
                                disabled={isLoading}
                            >
                                Bloquear
                            </Button>
                        </ButtonContent>
                    </ButtonsContainer>
                </Modal.Body>
            </ModalBlockCard>

            <ModalBlockCard
                show={modalIsOpenConfirmation}
                onHide={closeModalConfirmation}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img src={CheckOperationSVG} alt="" className="" />
                    <h6 className="title-modal-login"> Todo listo</h6>
                    <p>
                        Tu tarjeta de crédito *{lastsFourNumCard} ha sido bloqueada temporalmente.
                    </p>
                    <ButtonsContainer>
                        <ModalButtonConfirmation variant="primary" onClick={closeModalConfirmation}>
                            Aceptar
                        </ModalButtonConfirmation>
                    </ButtonsContainer>
                </Modal.Body>
            </ModalBlockCard>

            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleModalCloseInfo}
            />

            {loading && (
                <>
                    <LoadingScreen></LoadingScreen>
                </>
            )}
        </>
    )
}

export default BlockCard
