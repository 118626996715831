import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { BreadcrumbApp } from '../../../../components'
import { LayoutContent } from '../../../../transverse'
import { Container, ContainerTitle, Title } from '../../AlertCreditCardRequests-Styled'
import { ContentTable, TextDetail } from './ListOfRequest-Styled'
import {
    ArrowNextPurpleSVG,
    CircleBlueSVG,
    CircleGreenSVG,
    CircleRedSVG,
    CircleYellowSVG,
    CreditCardSVG,
    FileSignatureSVG,
    TruckBlackSVG,
} from '../../../../utils/getIcons'
import { useEffect } from 'react'

interface RequestData {
    Id: number
    RequestSourceType: string
    RequestDate: string
    Type: string
    State: string
    Details: {
        RequestCreated: boolean
        RequestDate: string | null
        PendingDelivery: boolean
        PendingActivation: boolean
        LastFourDigits: string | null
    }
}

const ListOfRequest: React.FC<RouteComponentProps> = (props) => {
    const location = useLocation()
    const requestData = location.state ? (location.state.requestData as RequestData) : null
    const isNewRequest = location.state ? location.state.isNewRequest : false

    const history = useHistory()
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Solicitud productos',
            active: true,
        },
    ]

    const handleDetailsView = (requestData: RequestData): void => {
        history.push('/details-credit-card-request', { requestData })
    }

    useEffect(() => {
        if (!requestData) {
            history.push('/home-wallet')
        }
    }, [history, requestData])

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        history.push('/home-wallet')
                    }}
                ></BreadcrumbApp>
                <ContainerTitle>
                    <Title>Tus solicitudes</Title>
                </ContainerTitle>
                {requestData && (
                    <ContentTable className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Tipo de solicitud</th>
                                    <th scope="col">Fecha de la solicitud</th>
                                    <th scope="col">Canal</th>
                                    <th scope="col">Estado de la solicitud</th>
                                </tr>
                            </thead>
                            <tbody className="tbodyStyled">
                                <tr key={requestData.Id}>
                                    <td className="header">Tipo de solicitud</td>
                                    <td data-header="Tipo de solicitud" className="tdStyled">
                                        <div style={{ display: 'flex' }}>
                                            <img
                                                src={CreditCardSVG}
                                                alt="CreditCardSVG"
                                                style={{ paddingRight: '3%' }}
                                            />
                                            <div style={{ paddingLeft: '3%' }}>
                                                Tarjeta de crédito <br />
                                                ID: {requestData.Id}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="header">Fecha de la solicitud</td>
                                    <td data-header="Fecha de la solicitud" className="tdStyled">
                                        {getDateFormat(requestData.RequestDate)}
                                    </td>
                                    <td className="header">Canal</td>
                                    <td data-header="Canal" className="tdStyled">
                                        {requestData.Type}
                                    </td>
                                    <td className="header">Estado de la solicitud</td>
                                    <td
                                        data-header="Estado"
                                        className="stateRequest"
                                        style={{
                                            color: getStateColor(requestData.State),
                                        }}
                                    >
                                        {getStateImage(requestData.State) !== '' && (
                                            <img
                                                className="imageStateRequest"
                                                width={25}
                                                src={getStateImage(requestData.State)}
                                                alt={requestData.State}
                                            />
                                        )}
                                        {correctStateSpelling(requestData.State)}
                                    </td>
                                    <td className="header">Ver detalles</td>
                                    <td
                                        className="tdStyled"
                                        onClick={() => handleDetailsView(requestData)}
                                    >
                                        <div className="section">
                                            <TextDetail className="hover-text">
                                                Ver detalles
                                            </TextDetail>
                                            <img
                                                src={ArrowNextPurpleSVG}
                                                alt="ArrowNextPurple"
                                                width={25}
                                            />
                                            {isNewRequest && <div className="tag">1</div>}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ContentTable>
                )}
            </Container>
        </LayoutContent>
    )
}

const getStateColor = (estado: string): string => {
    switch (estado) {
        case 'Pendiente activacion':
            return '#07539F'
        case 'Pendiente activación':
            return '#07539F'
        case 'Pendiente entrega':
            return '#000000'
        case 'Pendiente firma pagare':
            return '#FE6E00'
        case 'En revision':
            return '#F5A50B'
        case 'Rechazado':
            return '#FB2F3D'
        case 'Rechazada':
            return '#FB2F3D'
        case 'Solicitud en curso':
            return '#55B948'
        default:
            return '#000000'
    }
}

const getStateImage = (estado: string): string => {
    switch (estado) {
        case 'Pendiente activacion':
            return CircleBlueSVG
        case 'Pendiente activación':
            return CircleBlueSVG
        case 'Pendiente entrega':
            return TruckBlackSVG
        case 'Pendiente firma pagare':
            return FileSignatureSVG
        case 'En revision':
            return CircleYellowSVG
        case 'Rechazado':
            return CircleRedSVG
        case 'Rechazada':
            return CircleRedSVG
        case 'Solicitud en curso':
            return CircleGreenSVG
        default:
            return ''
    }
}

const correctStateSpelling = (state: string): string => {
    switch (state.toLowerCase()) {
        case 'pendiente activacion':
            return 'Pendiente activación'
        case 'Pendiente firma pagare':
            return 'Pendiente firma pagaré'
        case 'En revision':
            return 'En revisión'
        case 'Rechazado':
            return 'Rechazada'
        default:
            return state
    }
}

const getDateFormat = (date: string): string => {
    const monthTranslations: Record<string, string> = {
        january: 'enero',
        february: 'febrero',
        march: 'marzo',
        april: 'abril',
        may: 'mayo',
        june: 'junio',
        july: 'julio',
        august: 'agosto',
        september: 'septiembre',
        october: 'octubre',
        november: 'noviembre',
        december: 'diciembre',
    }

    const dateParts = date.toLowerCase().split(' de ')
    const day = dateParts[0]
    const monthYear = dateParts[1].split(' del ')
    const month = monthTranslations[monthYear[0]] || monthYear[0]
    const year = monthYear[1]

    return `${day} de ${month} del ${year}`
}

export default ListOfRequest
