// styles
import { HomeSVG } from '../../../../utils/getIcons'
import { ContainerTitle, Title } from './titleModule-styles'

const TitleModule = (): JSX.Element => {
    return (
        <ContainerTitle>
            <Title>
                <img width="50" alt="home" src={HomeSVG} />
                <span>Hipotecas</span>
            </Title>
        </ContainerTitle>
    )
}

export default TitleModule
