import { useDispatch, useSelector } from 'react-redux'
import { CreditCardsSelector } from '../../../../../selectors'
import { getCreditCardsAction } from '../../../../../redux/tc'
import { useEffect, useState } from 'react'

import { NotProductSVG } from '../../../../utils/getIcons'
import { Loading, ModalGeneric } from '../../../../components'
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import {
    FormLabel,
    FormOption,
    ButtonsContainer,
    CancelButton,
    CardContainer,
    Content,
    TextInfo,
    ButtonContinue,
} from './SelectCreditCard-Styled'
import { CreditCard } from '../../../../../../domain/models'
import { LoadingContainer } from '../../../CreditDetail/creditDetail-styles'
import { TitleComponent, Wrapper } from '../../AdvanceToFAIAccount-Styled'
import { Controller, useForm } from 'react-hook-form'
import { useGetRates } from '../../hooks/useAdvanceToFAIAccount'

interface SelectCreditCardProps {
    isGoBack: boolean
    handleGoBack: (value: boolean) => void
    isAccountFai: boolean
    onNextStep: (step: number) => void
    saveDataAdvanceFAI: (
        amountAdvance: string,
        cardNumber: string,
        nameCard: string,
        rateOfInterest: string,
        lastsFourNumCard: string,
        expiredDateCard: string,
        ccvValue: string,
        numberFee: string,
        transactionData: TransactionData
    ) => void
    dataTransaction: TransactionData | null
}

interface TransactionData {
    selectedCard: string
    valorAvance: string
    month: string
    year: string
    quota: string
    cvv: string
}

const SelectCreditCard: React.FC<SelectCreditCardProps> = ({
    onNextStep,
    isGoBack,
    handleGoBack,
    isAccountFai,
    saveDataAdvanceFAI,
    dataTransaction,
}) => {
    const history = useHistory()
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { loading, data, error } = useSelector(CreditCardsSelector)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const methods = useForm()
    const { control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    useEffect(() => {
        if (isAccountFai && !dataTransaction) {
            dispatch(getCreditCardsAction())
        }
    }, [dispatch, isAccountFai])

    const [selectedOption, setSelectedOption] = useState<string>('')
    const [selectedCardData, setSelectedCardData] = useState<CreditCard | null>(null)

    const [cardNumber, setCardNumber] = useState<string>('')
    const [lastsFourNumCard, setLastsFourNumCard] = useState<string>('')

    const [advanceValue, setAdvanceValue] = useState<string>('')
    const [advanceValueFormat, setAdvanceValueFormat] = useState<string>('')
    const [selectedCard, setSelectedCard] = useState<string>('')

    const [rateInterest, setRateInterest] = useState<string>('')

    const [month, setMonth] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [cvv, setCvv] = useState<string>('')
    const [quota, setQuota] = useState<string>('')

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    const activeCards =
        data?.cards?.filter((card) => card.lockType !== 'BLOQUEO PERDIDA O HURTO') ?? []
    const cards = activeCards.map(
        (card: CreditCard) => card.typeCard + ' - *' + card.lastFourDigits
    )

    const getCurrentYear = (): number => {
        const currentYear = new Date().getFullYear()
        return currentYear % 100
    }

    const generateYearOptions = (): JSX.Element[] => {
        const currentYear = getCurrentYear()
        const years = []

        for (let i = 0; i <= 10; i++) {
            const year = (currentYear + i) % 100
            years.push(
                <option key={i} value={year}>
                    {year}
                </option>
            )
        }

        return years
    }

    const months = Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
        <option key={month} value={month.toString().padStart(2, '0')}>
            {month.toString().padStart(2, '0')}
        </option>
    ))

    const quotas = Array.from({ length: 36 }, (_, index) => index + 1).map((quotas) => (
        <option key={quotas} value={quotas}>
            {quotas}
        </option>
    ))

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        if (e.target.value !== '' || e.target.value === null) {
            clearErrors('selectedOption')
        }
        const selectedType = e.target.value
        setSelectedOption(selectedType)
        // Busca y guarda los datos de la tarjeta seleccionada
        const selectedCard = data?.cards.find(
            (card) => card.typeCard + ' - *' + card.lastFourDigits === selectedType
        )
        setSelectedCardData(selectedCard ?? null)

        setCardNumber(selectedCard?.cardNumber ?? '')
        setLastsFourNumCard(selectedCard?.lastFourDigits ?? '')
        handleGoBack(true)
        if (selectedCard) {
            setSelectedCard(selectedCard.typeCard + ' *' + selectedCard.lastFourDigits || '')
        }
        setAdvanceValueFormat('')
        clearErrors('advanceValueFormat')
        setIsButtonEnabled(isFormValid())
    }

    useEffect(() => {
        if (isGoBack) {
            setSelectedOption('')
            setAdvanceValueFormat('')
            setMonth('')
            setYear('')
            setQuota('')
            setCvv('')
            handleGoBack(false)
        }
    }, [isGoBack, handleGoBack])

    useEffect(() => {
        if (dataTransaction) {
            const value = parseFloat(dataTransaction.valorAvance)

            setSelectedCard(dataTransaction.selectedCard || '')
            setSelectedOption(dataTransaction.selectedCard || '')
            const selectedCard = data?.cards.find(
                (card) =>
                    card.typeCard + ' - *' + card.lastFourDigits === dataTransaction.selectedCard
            )
            setCardNumber(selectedCard?.cardNumber ?? '')
            setLastsFourNumCard(selectedCard?.lastFourDigits ?? '')

            setSelectedCardData(selectedCard ?? null)
            setAdvanceValueFormat(formatter.format(value) || '')
            setAdvanceValue(dataTransaction.valorAvance || '')
            setMonth(dataTransaction.month || '')
            setYear(dataTransaction.year || '')
            setQuota(dataTransaction.quota || '')
            setCvv(dataTransaction.cvv || '')
            setIsButtonEnabled(isFormValid())
        }
    }, [dataTransaction])

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    const handleContinue = (): void => {
        onNextStep(2)
        const dateExpiration = year + month
        const transactionData: TransactionData = {
            selectedCard: selectedOption,
            valorAvance: advanceValue,
            month: month,
            year: year,
            quota: quota,
            cvv: cvv,
        }

        saveDataAdvanceFAI(
            advanceValue,
            cardNumber,
            selectedCard,
            rateInterest,
            lastsFourNumCard,
            dateExpiration,
            cvv,
            quota,
            transactionData
        )
    }

    const GetRates = (): void => {
        useGetRates(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    setRateInterest(res.data.ObjectValue[0].RateEA + '% EA')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        GetRates()
    }, [])

    const isFormValid = (): boolean => {
        const value = parseFloat(advanceValue)
        const min = 50000

        const basicValidation =
            cardNumber.trim() !== '' &&
            lastsFourNumCard.trim() !== '' &&
            advanceValue.trim() !== '' &&
            advanceValueFormat.trim() !== '' &&
            selectedCard.trim() !== '' &&
            rateInterest.trim() !== '' &&
            month.trim() !== '' &&
            year.trim() !== '' &&
            cvv.trim() !== '' &&
            quota.trim() !== ''

        if (selectedCardData) {
            const max = selectedCardData.availableShopping
            const prevMax = selectedCardData.availablePreviews

            if (value > max || value > prevMax) {
                return false
            }
        } else if (value < min) {
            return false
        } else if (cvv.length !== 3) {
            return false
        }

        return basicValidation
    }

    return (
        <Wrapper>
            {loading ? (
                <LoadingContainer>
                    <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                </LoadingContainer>
            ) : (
                <>
                    {activeCards && activeCards.length > 0 ? (
                        <CardContainer>
                            <Content>
                                <div>
                                    <TitleComponent>Paso 1 de 3</TitleComponent>
                                </div>
                                <TextInfo>
                                    Realiza un avance de tu tarjeta de crédito y recibe el dinero a
                                    tu cuenta FAI en minutos.{' '}
                                    <strong>Los avances a tu cuenta FAI no tienen costo.</strong>
                                </TextInfo>

                                <FormLabel>Tarjeta</FormLabel>
                                <Form.Group className="mb-3">
                                    <Controller
                                        control={control}
                                        name={'selectedOption'}
                                        rules={{ required: 'Este campo es requerido' }}
                                        render={({ field: { onChange, value, onBlur } }) => (
                                            <Form.Select
                                                className="input-with-icon-card"
                                                {...register('selectedOption', {
                                                    required: true,
                                                })}
                                                value={selectedOption}
                                                required
                                                isInvalid={!!errors['selectedOption']}
                                                onChange={handleSelectChange}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        e.target.value === null
                                                    ) {
                                                        setError('selectedOption', {
                                                            message: 'Este campo es obligatorio',
                                                        })
                                                    } else {
                                                        clearErrors('selectedOption')
                                                    }
                                                    onBlur()
                                                }}
                                            >
                                                <FormOption disabled value="">
                                                    Selecciona una opción
                                                </FormOption>
                                                {cards?.map((card: string) => (
                                                    <FormOption key={card} value={card}>
                                                        {card}
                                                    </FormOption>
                                                ))}
                                            </Form.Select>
                                        )}
                                    />
                                    {errors['selectedOption'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['selectedOption']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                {selectedCardData && selectedOption !== '' && (
                                    <>
                                        <FormLabel>Valor de avance</FormLabel>

                                        <Form.Group className="mb-3">
                                            <Controller
                                                control={control}
                                                name={'advanceValueFormat'}
                                                rules={{
                                                    required: 'Este campo es requerido',
                                                }}
                                                render={({
                                                    field: { onChange, value, onBlur },
                                                }) => (
                                                    <Form.Control
                                                        className="input-with-icon"
                                                        type="text"
                                                        {...register('advanceValueFormat', {
                                                            required: true,
                                                        })}
                                                        value={advanceValueFormat}
                                                        placeholder="Ingresa un valor Min $50.000 - Max Cupo Total"
                                                        required
                                                        isInvalid={!!errors['advanceValueFormat']}
                                                        onChange={(e) => {
                                                            const min = 50000
                                                            const max =
                                                                selectedCardData.availableShopping

                                                            const prevMax =
                                                                selectedCardData.availablePreviews

                                                            const numericValue =
                                                                e.target.value.replace(
                                                                    /[^0-9.]/g,
                                                                    ''
                                                                )

                                                            if (e.target.value !== '') {
                                                                setAdvanceValue(numericValue)
                                                                clearErrors('advanceValueFormat')
                                                                const value =
                                                                    parseFloat(numericValue)

                                                                const numericValueRegex =
                                                                    /^[0-9,\b$]+$/
                                                                const isValid =
                                                                    numericValueRegex.test(
                                                                        e.target.value
                                                                    )
                                                                if (!isValid) {
                                                                    setError('advanceValueFormat', {
                                                                        message:
                                                                            'Por favor ingresar solo dígitos numéricos',
                                                                    })
                                                                } else if (value < min) {
                                                                    setError('advanceValueFormat', {
                                                                        message:
                                                                            'El valor ingresado no es permitido. El monto mínimo es $50.000',
                                                                    })
                                                                } else if (
                                                                    value > max ||
                                                                    value > prevMax
                                                                ) {
                                                                    const amount = Math.min(
                                                                        max,
                                                                        prevMax
                                                                    )
                                                                    setError('advanceValueFormat', {
                                                                        message: `El valor ingresado supera el monto máximo de ${formatter.format(
                                                                            amount
                                                                        )}`,
                                                                    })
                                                                } else {
                                                                    setIsButtonEnabled(
                                                                        isFormValid()
                                                                    )
                                                                }
                                                                setAdvanceValueFormat(
                                                                    formatter.format(value)
                                                                )
                                                            } else {
                                                                setAdvanceValue('')
                                                                setError('advanceValueFormat', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            }

                                                            onChange(e)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value === '' ||
                                                                e.target.value === null
                                                            ) {
                                                                setError('advanceValueFormat', {
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                })
                                                            } else {
                                                                /* clearErrors('advanceValueFormat') */
                                                            }
                                                            onBlur()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors['advanceValueFormat'] && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors['advanceValueFormat']?.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>

                                        <FormLabel>
                                            Fecha de vencimiento de tu tarjeta o Valid thru
                                        </FormLabel>
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Controller
                                                        control={control}
                                                        name={'month'}
                                                        rules={{
                                                            required: 'Este campo es requerido',
                                                        }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <Form.Select
                                                                {...register('month', {
                                                                    required: true,
                                                                })}
                                                                value={month}
                                                                required
                                                                isInvalid={!!errors['month']}
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value !== '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        clearErrors('month')
                                                                        setIsButtonEnabled(
                                                                            isFormValid()
                                                                        )
                                                                    }
                                                                    setMonth(e.target.value)

                                                                    onChange(e)
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (
                                                                        e.target.value === '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        setError('month', {
                                                                            message:
                                                                                'Este campo es obligatorio',
                                                                        })
                                                                    } else {
                                                                        clearErrors('month')
                                                                    }
                                                                    onBlur()
                                                                }}
                                                            >
                                                                <FormOption disabled value="">
                                                                    Mes
                                                                </FormOption>
                                                                {months}
                                                            </Form.Select>
                                                        )}
                                                    />
                                                    {errors['month'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['month']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Controller
                                                        control={control}
                                                        name={'year'}
                                                        rules={{
                                                            required: 'Este campo es requerido',
                                                        }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <Form.Select
                                                                {...register('year', {
                                                                    required: true,
                                                                })}
                                                                value={year}
                                                                required
                                                                isInvalid={!!errors['year']}
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value !== '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        clearErrors('year')
                                                                        setIsButtonEnabled(
                                                                            isFormValid()
                                                                        )
                                                                    }
                                                                    setYear(e.target.value)

                                                                    onChange(e)
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (
                                                                        e.target.value === '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        setError('year', {
                                                                            message:
                                                                                'Este campo es obligatorio',
                                                                        })
                                                                    } else {
                                                                        clearErrors('year')
                                                                    }
                                                                    onBlur()
                                                                }}
                                                            >
                                                                <FormOption disabled value="">
                                                                    Año
                                                                </FormOption>
                                                                {generateYearOptions()}
                                                            </Form.Select>
                                                        )}
                                                    />
                                                    {errors['year'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['year']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6} xs={12}>
                                                <FormLabel>Número de cuotas</FormLabel>
                                                <Form.Group className="mb-3">
                                                    <Controller
                                                        control={control}
                                                        name={'quota'}
                                                        rules={{
                                                            required: 'Este campo es requerido',
                                                        }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <Form.Select
                                                                className="input-with-icon-quota"
                                                                {...register('quota', {
                                                                    required: true,
                                                                })}
                                                                value={quota}
                                                                required
                                                                isInvalid={!!errors['quota']}
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value !== '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        clearErrors('quota')
                                                                    }
                                                                    setQuota(e.target.value)
                                                                    setIsButtonEnabled(
                                                                        isFormValid()
                                                                    )
                                                                    onChange(e)
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (
                                                                        e.target.value === '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        setError('quota', {
                                                                            message:
                                                                                'Este campo es obligatorio',
                                                                        })
                                                                    } else {
                                                                        clearErrors('quota')
                                                                    }
                                                                    onBlur()
                                                                }}
                                                            >
                                                                <FormOption disabled value="">
                                                                    Seleccione una cuota
                                                                </FormOption>
                                                                {quotas}
                                                            </Form.Select>
                                                        )}
                                                    />
                                                    {errors['quota'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['quota']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <FormLabel>CVV</FormLabel>

                                                <Form.Group className="mb-3">
                                                    <Controller
                                                        control={control}
                                                        name={'cvv'}
                                                        rules={{
                                                            required: 'Este campo es requerido',
                                                        }}
                                                        render={({
                                                            field: { onChange, value, onBlur },
                                                        }) => (
                                                            <Form.Control
                                                                className="input-with-icon-cvv"
                                                                type="text"
                                                                {...register('cvv', {
                                                                    required: true,
                                                                })}
                                                                value={cvv}
                                                                placeholder=""
                                                                required
                                                                isInvalid={!!errors['cvv']}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        if (
                                                                            e.target.value
                                                                                .length !== 3
                                                                        ) {
                                                                            setError('cvv', {
                                                                                message:
                                                                                    'Este campo debe contener 3 caracteres',
                                                                            })
                                                                        } else {
                                                                            clearErrors('cvv')
                                                                            setCvv(e.target.value)
                                                                            setIsButtonEnabled(
                                                                                isFormValid()
                                                                            )
                                                                        }
                                                                    } else {
                                                                        setCvv(
                                                                            'Ingrese el CVV, de tu tarjeta'
                                                                        )
                                                                        setError('cvv', {
                                                                            message:
                                                                                'Este campo es obligatorio',
                                                                        })
                                                                    }
                                                                    setCvv(e.target.value)

                                                                    onChange(e)
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (
                                                                        e.target.value === '' ||
                                                                        e.target.value === null
                                                                    ) {
                                                                        setError('cvv', {
                                                                            message:
                                                                                'Este campo es obligatorio',
                                                                        })
                                                                    } else {
                                                                        /* clearErrors('cvv') */
                                                                    }
                                                                    onBlur()
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors['cvv'] && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors['cvv']?.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                <ButtonsContainer>
                                    <CancelButton onClick={() => history.push('/home-wallet')}>
                                        Cancelar
                                    </CancelButton>

                                    <ButtonContinue
                                        onClick={handleContinue}
                                        disabled={!isButtonEnabled}
                                    >
                                        Continuar
                                    </ButtonContinue>
                                </ButtonsContainer>
                            </Content>
                        </CardContainer>
                    ) : (
                        <ModalGeneric
                            show={true}
                            img={NotProductSVG}
                            textTitle={'Lo sentimos'}
                            textBody={'No cuentas con tarjetas activas.'}
                            handleButton={handleClose}
                            handleClose={handleClose}
                            textButton="Aceptar"
                        />
                    )}
                </>
            )}

            {Object.keys(error).length > 0 && (
                <ModalGeneric
                    show={true}
                    img={NotProductSVG}
                    textTitle={'¡Ha ocurrido un error!'}
                    textBody={
                        'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                    }
                    handleButton={handleClose}
                    handleClose={handleClose}
                    textButton="Aceptar"
                />
            )}
        </Wrapper>
    )
}

export default SelectCreditCard
