import tw, { styled } from 'twin.macro'

export const Wrapper = styled.section`
    ${tw`h-[100%]`}
`

export const ContentTable = styled.div`
    ${tw`flex justify-center items-center py-2 p-5 lg:px-10 w-full max-w-[1112px] lg:max-w-[1200px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    .header {
        display: none;
    }

    .tbodyStyled {
        color: #00000029;
    }

    .tdStyled {
        color: #000;
        vertical-align: middle;
        font-weight: 500;
    }

    .stateRequest {
        font-size: '16px';
        font-weight: 600;
        vertical-align: middle;
    }

    .imageStateRequest {
        ${tw`pr-1.5`}
    }

    th {
        font-weight: 500;
        color: #707070;
        font-size: 15px;
    }

    .tag {
        ${tw`text-[12px] pt-[3px] pb-0 px-[4%] right-[5%] text-white bg-danger w-10 h-6 text-center`}
        border-radius: 15px;
    }
    .section {
        ${tw`flex`}
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }

    @media (max-width: 600px) {
        /* Cambiar el diseño de las filas de datos a columnas */
        tbody tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            border-bottom-width: medium;
        }
        thead {
            display: none;
        }
        tbody td.header {
            display: block;
            font-weight: bold;
        }
        tbody td:not(.header) {
            display: block;
        }
    }

    .tdStyled:hover .hover-text,
    .tdStyled:hover .arrow-icon {
        display: inline-block;
    }

    .hover-text,
    .arrow-icon {
        display: none;
    }
`
export const TextDetail = styled.p`
    ${tw`pt-[10%]`}
    color: var(--sub-dominant-color) !important;
`

export const Image = styled.img`
    width: 60px;
`

export const TextPBold = styled.p`
    ${tw`text-base justify-between`}
    padding: 0;
    margin: 60px;
    margin-right: 525px;

    span {
        ${tw`text-base w-full`}
        font-weight: 600;
        color: var(---text-opacity10);
    }
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`
