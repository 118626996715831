import tw, { styled } from 'twin.macro'
import { FormLabel as FormLabelApp, FormGroup as FormGroupApp } from '../../../../components'

export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-8 w-full rounded-[10px] shadow-[0px 3px 6px #00000029] mt-10`}
`

export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}

    span {
        ${tw`font-helvetica text-base text-gray-500`}
        margin-left:5px;
    }
`

export const TextP = styled.p`
    ${tw`font-helvetica text-base`}

    span {
        color: #f5a50b;
    }
`

export const TextQuota = styled.p`
    ${tw`font-montserrat text-lg font-bold`}
    color: #f5a50b;
    margin-bottom: 1px;
`

export const TextAmount = styled.p`
    ${tw`font-helvetica text-base`}
    color: #000000;
    opacity: 1;
`

export const FromContent = styled.div`
    ${tw`font-montserrat text-base`}

    .input-icon-user {
        ${tw`font-helvetica`}
        text-transform: capitalize;
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/user-alt.svg);
        background-size: 13px;
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
        color: #53595a;
    }

    input.input-icon-user.form-control:disabled {
        background-color: transparent;
    }

    input.input-icon-destination-id.form-control:disabled {
        background-color: transparent;
    }

    .input-icon-account-fai {
        background-image: var(--url-mini-payment-grey);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-account-fai.form-control.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-payment-grey), var(--url-exclamation-mark-icon);

        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .input-icon-destination-id {
        background-image: var(--url-mini-identification);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-destination-id.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-identification), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .input-icon-amount-transfer {
        background-image: var(--url-money-bill);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-amount-transfer.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-money-bill), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid-feedback {
        color: #ff0909;
    }

    .position-col {
        position: relative;
    }

    .infoInput {
        position: absolute;
        bottom: 0px;
        text-align: left;
        color: #a0a9a9;
        opacity: 1;
    }
`

export const ContainerButtons = styled.div`
    ${tw`gap-1 my-5 flex flex-col justify-end items-end`}

    & button {
        ${tw`lg:!w-[25%]`}
    }
`
