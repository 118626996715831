import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const ContainerKey = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[552px] lg:max-w-[600px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const TextInfo = styled.p`
    ${tw`mt-3 py-3 px-3 font-montserrat text-[16px]`}

    span {
        font-weight: 700;
    }
`
export const TextSteps = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[16px] font-montserrat text-dominant-dark`}

    @media (max-width: 460px) {
        ${tw`text-[25px] font-montserrat text-dominant-dark`}
        font-weight:bold;
    }
`

export const TextSubtitle = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[14px] font-montserrat text-dominant-dark`}
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: space-between !important;
`

export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (max-width: 460px) {
        ${tw`my-auto mx-0 w-[270px] h-[50px] rounded-[5px] font-normal font-montserrat`}
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (max-width: 460px) {
        ${tw`my-auto mx-0 w-[270px] h-[50px] rounded-[5px] font-normal font-montserrat`}
    }
`
export const FAIFormWrap = styled.div`
    ${tw`pb-6`}

    .label-floating {
        ${tw`hidden font-helvetica font-normal text-gray-custom-700 text-base`}
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        ${tw`ml-15 relative`}

        .label-floating {
            ${tw`block absolute mt-3 top-full left-0`}
        }
    }
`
