import { CreditDetailContext, CreditDetailStateTypes } from '../context/CreditDetailState'
import { blockCardService } from '../../../../../domain/services/User.service'

// custom hooks
import { useStateCtx } from '../../../hooks'

export const useCreditDetailState = (): [
    CreditDetailStateTypes,
    React.Dispatch<React.SetStateAction<CreditDetailStateTypes>>
] => useStateCtx(CreditDetailContext)

export async function useUserInfo(tokenSave: string) {
    try {
        return await blockCardService
            .getUserInfo('api/User/GetUserDataInfo', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useOfficeInfo(tokenSave: string) {
    try {
        return await blockCardService
            .getOfficeInfo('api/Client/GetOfficeClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useReissueCard(tokenSave: string) {
    try {
        return await blockCardService
            .getReissueCard('api/CreditCardProcess/GetReissueCard/GetReissueCard', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useSendOTP(tokenSave: string) {
    try {
        return await blockCardService
            .sendOTP('api/CreditCardTransaction/SendCodeOtpTC/SendCodeOtpTC', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateOTP(tokenSave: string, body: any) {
    try {
        return await blockCardService
            .validateOTP(
                'api/CreditCardTransaction/ValidateCodeOtpTC/ValidateCodeOtpTC',
                tokenSave,
                body
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useStatusTC(dataToSend: any, tokenSave: string) {
    try {
        const formData = new FormData()
        formData.append('body', JSON.stringify(dataToSend))

        const sendData = dataToSend.get('body')
        const dataToSendService = sendData

        return await blockCardService
            .postTransactionStatusTC(
                '/api/CreditCardAdditional/StatusTC',
                tokenSave,
                dataToSendService
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
