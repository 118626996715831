import tw, { styled } from 'twin.macro'

export const TextP = styled.p`
    ${tw`font-helvetica opacity-100`}
    color: #53595a;

    span {
        color: #f5a50b;
    }
`

export const TextInfo = styled.p`
    ${tw`font-helvetica pl-4 opacity-100`}
    color: #53595A;

    span {
        color: #f5a50b;
    }
`

export const UserDate = styled.h2`
    ${tw`font-montserrat font-bold text-2xl lg:text-xl text-gray-custom-900 pl-4`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }
`
