import { Controller, FormProvider, useForm } from 'react-hook-form'
import { LayoutContent } from '../../../../transverse'
import {
    ButtonCanceled,
    ContainerBtn,
    ContentForm,
    DivContainerStart,
    Label,
    SectionTitle,
    SpaceHr,
    TitlePage,
} from '../../UserOperations-styled'
import { Col, Form, Row } from 'react-bootstrap'
import { useState } from 'react'
import { Button } from '../../../../components'

interface UserNameSearchProps {
    filter: (value: string) => void
    loading: boolean
    onPrevStep: (step: number) => void
    onNextStep: (step: number) => void
}

const UserNameSearch: React.FC<UserNameSearchProps> = ({
    filter,
    loading,
    onPrevStep,
    onNextStep,
}): JSX.Element => {
    const [identificationCardUser, setIdentificationCardUser] = useState<string>('')
    const requiredField = 'Este campo es requerido'
    const methodsFormSearch = useForm()
    const { control, formState, setError, clearErrors, register, handleSubmit } = methodsFormSearch
    const { errors } = formState

    const handleSearch = (): void => {
        onNextStep(3)
        filter(identificationCardUser)
    }

    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    return (
        <LayoutContent>
            <SpaceHr />

            <SectionTitle>
                <TitlePage>Búsqueda de usuario por cédula</TitlePage>
            </SectionTitle>
            <FormProvider {...methodsFormSearch}>
                <ContentForm>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Cédula asociado</Label>
                                <Controller
                                    control={control}
                                    name={'identificationCardUser'}
                                    rules={{
                                        required:
                                            identificationCardUser === '' ? requiredField : false,
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            type="number"
                                            {...register('identificationCardUser', {
                                                required: identificationCardUser === '',
                                            })}
                                            maxLength={16}
                                            minLength={1}
                                            onChange={(e) => {
                                                setIdentificationCardUser(e.target.value)
                                                setIsDisabled(false)
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('identificationCardUser', {
                                                        message: requiredField,
                                                    })
                                                } else {
                                                    clearErrors('identificationCardUser')
                                                }
                                                onBlur()
                                            }}
                                            isInvalid={!!errors['identificationCardUser']}
                                            value={identificationCardUser}
                                        />
                                    )}
                                />
                                {errors['identificationCardUser'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['identificationCardUser']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <DivContainerStart>
                        <ButtonCanceled variant="primary" onClick={() => onPrevStep(1)}>
                            Volver
                        </ButtonCanceled>
                        <FormProvider {...methodsFormSearch}>
                            <form onSubmit={handleSubmit(handleSearch)}>
                                <ContainerBtn>
                                    <Button
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleSearch}
                                        isLoading={loading}
                                        extend
                                        disabled={loading || isDisabled}
                                    >
                                        Buscar
                                    </Button>
                                </ContainerBtn>
                            </form>
                        </FormProvider>
                    </DivContainerStart>
                </ContentForm>
            </FormProvider>
        </LayoutContent>
    )
}

export default UserNameSearch
