import { ChangeEvent, ReactElement, useState, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormState } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { Form, Modal } from '../../../../components'
import { ControlsButtons, HeadStep, NavigationStep } from '../../../OpeningCreditCard/inc'

// styles
import {
    ThirteenthStepWrapper,
    ThirteenthStepContent,
    UploadInput,
    UploadSpan,
    UploadLabel,
} from './thirteenthStep-styles'
import { TitleStep } from '../../campaignCard-styles'

// hooks
import {
    useThirteenthStepForm,
    ThirteenthStepType,
    thirteenthStepSchema,
    useCampaignUpdateFiles,
    useGetCoreData,
    useServiceTerm,
} from '../../hooks/useThirteenthStepForm'
import { useReducerStep } from '../../hooks'

// selectors
import {
    getClientValidationCCRequestSelector,
    validateUserStateSelector,
    useSelector,
} from '../../../../../selectors'

// models
import FileAndProgressBar from '../FileAndProgress/FileAndProgressBar'
import {
    FileUploadContainer,
    FileUploadPrompt,
    TextInstruction,
    UploadFileContainer,
    UploadFileRight,
    UploaderFileH4,
    UploaderFileImg,
    UploaderFileText,
} from '../SixteenthStep/sixteenthStep-styles'
import { UploadFileSVG } from '../../../../utils/getIcons'
import { useNotificationNotComplete } from '../../hooks/useNotificationNotCompleted'
import ModalInfo from '../../../CreditDetail/inc/ModalInfo/ModalInfo'
import { ModalBody } from 'react-bootstrap'

const ThirteenthStep = (): ReactElement => {
    const dispatchStep = useReducerStep()[1]
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [isLoadingContinue, setIsLoadingContinue] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [showModal, setShowModal] = useState(false)
    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }

    const MAX_SIZE = 15000000

    // Selectors
    const { clientValidation } = useSelector(getClientValidationCCRequestSelector)
    const { validateUser } = useSelector(validateUserStateSelector)

    const defaultValuesRef = useRef({
        formatFile: null,
    })

    const { handleSubmit, watch, control, setValue, getValues, redirection } =
        useThirteenthStepForm({
            validationSchema: yupResolver(thirteenthStepSchema),
            defaultValues: defaultValuesRef.current,
        })

    const { isValid } = useFormState({ control })
    watch()

    // handlers
    const onSubmit = async ({ formatFile }: ThirteenthStepType): Promise<void> => {
        setIsLoadingContinue(true)
        UpdateFile(files)
        CoreCampaign()
        HandleFormSubmit()
        dispatchStep({
            type: 'GO_TO_STEP',
            payload: {
                step: 11,
            },
        })
    }
    const setIdRequest = (): number => {
        if (clientValidation?.validationData) {
            return 'id' in clientValidation.validationData ? clientValidation.validationData.id : 0
        }
        return validateUser?.id ?? 0
    }
    const CoreCampaign = (): void => {
        console.log('Metodo Core')
        useGetCoreData(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setIsLoadingContinue(false)
                    console.log('NO FUNCIONA')
                } else {
                    console.log('NO FUNCIONA')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const HandleFormSubmit = (): void => {
        const IdCreditCardRequest = setIdRequest()

        useServiceTerm(tokenSave, IdCreditCardRequest, 20)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    console.log('nueva funcionalidad')
                } else {
                    setIsLoading(false)
                    console.log('NO FUNCIONA')
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('No se pudo generar el pdf, intente de nuevo')
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se pudo generar el pdf, intente de nuevo')
            })
    }

    const NotificationNotComplete = (): void => {
        useNotificationNotComplete(tokenSave)
            .then((res: any) => {
                console.log(JSON.stringify(res))
                if (res['data']['Data'] === 'OK') {
                    console.log('Notificación enviada')
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const onSave = async ({ formatFile }: ThirteenthStepType): Promise<void> => {
        setIsLoadingSave(true)
        dispatchStep({
            type: 'GO_TO_STEP',
            payload: {
                step: 10,
            },
        })
        UpdateFile(files)
        NotificationNotComplete()
        redirection('/home-wallet')
    }

    const [files, setFiles] = useState<File[]>([])

    const fileDropzone = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png', '.bmp'],
            'application/pdf': ['.pdf'],
        },
        multiple: false,
        maxSize: MAX_SIZE,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles)
            setValue('formatFile', acceptedFiles, {
                shouldValidate: true,
            })
        },
    })

    const onDeleteFile = (nameToDelete: string): void => {
        const updatedFiles = files.filter((files) => files.name !== nameToDelete)
        setFiles(updatedFiles)
    }
    const state1 = useSelector((stateRef: any) => stateRef)
    const tokenSave = state1.auth.token
    const UpdateFile = (newFile: File[]): void => {
        useCampaignUpdateFiles(newFile, tokenSave)
            .then((res: any) => {
                if (res && res['status'] === 200) {
                    const data = res['data']['Data'] // Accede al objeto 'Data' de la respuesta

                    const fileName = data['Files'][0]['FileName'] // Obtiene el valor de 'FileName'
                    const urlFiles = data['UrlFiles'][0] // Obtiene el valor de 'UrlFiles'

                    console.log('FileName:', fileName)
                    console.log('UrlFiles:', urlFiles)
                    console.log('Uploader file')
                } else {
                    console.log('Fail')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <>
            <NavigationStep />
            <ThirteenthStepWrapper>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ThirteenthStepContent>
                        <HeadStep
                            title="Documentos"
                            paragraph="¡Ya casi todo está listo! Necesitamos algunos documentos para terminar tu solicitud"
                        />
                        <TitleStep>
                            Por favor adjunta la fotocopia de tú cédula por los dos lados
                        </TitleStep>

                        {files.length > 0 && (
                            <>
                                {files.map((file) => (
                                    <FileAndProgressBar
                                        key={file.name}
                                        fileName={file.name}
                                        onDelete={onDeleteFile}
                                    />
                                ))}
                            </>
                        )}
                        <UploadFileContainer>
                            <FileUploadContainer {...fileDropzone.getRootProps()}>
                                <input {...fileDropzone.getInputProps()} />
                                <FileUploadPrompt>
                                    <UploaderFileImg src={UploadFileSVG} alt="Icon" />
                                    <UploaderFileH4>
                                        Arrastra y suelta los archivos sobre esta área delimitada
                                    </UploaderFileH4>
                                    <UploaderFileText>
                                        JPG, PNG, PDF de máximo 15mb
                                    </UploaderFileText>
                                </FileUploadPrompt>
                            </FileUploadContainer>
                            <UploadFileRight>
                                <TextInstruction>
                                    O si prefieres busca tus archivos desde tu dispositivo
                                </TextInstruction>
                                <UploadLabel htmlFor="upload">
                                    <UploadInput
                                        type="file"
                                        id="upload"
                                        accept=".pdf,.jpeg,.jpg,.png,.bmp,.svg"
                                        name="file"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const { files } = e.target
                                            const accFiles = files as unknown as File[]

                                            const newFiles = Array.from(accFiles)

                                            if (newFiles[0].size < MAX_SIZE) {
                                                setFiles(newFiles)
                                                setValue('formatFile', accFiles, {
                                                    shouldValidate: true,
                                                })
                                            }
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    <UploadSpan>Elegir archivo</UploadSpan>
                                </UploadLabel>
                            </UploadFileRight>
                        </UploadFileContainer>

                        <ControlsButtons
                            disable={
                                getValues('formatFile')
                                    ? getValues('formatFile').length <= 0
                                    : !isValid
                            }
                            isLoading={isLoadingContinue}
                            isLoadingSave={isLoadingSave}
                            onSave={() => {
                                onSave(getValues())
                            }}
                            nextText="Enviar"
                        />
                    </ThirteenthStepContent>
                </Form>
                <Modal
                    className="loading-screen"
                    show={isLoading}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <ModalBody>
                        <p style={{ textAlign: 'center' }}>
                            Espera, no actualices o cierres esta página
                        </p>
                    </ModalBody>
                </Modal>
                <ModalInfo
                    showModal={showModal}
                    textTitle={textTitleModal}
                    textBody={textBodyModal}
                    handleClose={handleModalCloseInfo}
                />
            </ThirteenthStepWrapper>
        </>
    )
}

export default ThirteenthStep
