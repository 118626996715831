import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbApp } from '../../components'
import { Row, Col } from 'react-bootstrap'

import {
    Title,
    AlignContent,
    TextP,
    Content,
    Image,
    LayoutContent,
    ContainerPossessions,
    Wrapper,
    ContentInfo,
    TextPBold,
    ContentW,
    ImageStar,
} from './Benefits-styles'
import { getBenefitsClient } from '../../../redux/products'
import { useDispatch } from 'react-redux'
import { CoinHadsSVG, IconUserStar } from '../../utils/getIcons'
import { BenefitsClientResponse } from '../../../../domain/models/products'
import Loading from './inc/Loading'
import useMediaQuery from 'usehooks-ts/dist/esm/useMediaQuery/useMediaQuery'

const Benefits = (): JSX.Element => {
    const history = useHistory()
    const [listBenefits, setListBenefits] = useState<BenefitsClientResponse[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<boolean>(false)
    const [bonusName, setBonusName] = useState<string>()
    const [document, setDocument] = useState<string>()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })
    const matchMedia = useMediaQuery('(max-width: 460px)')
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true)
        dispatch(
            getBenefitsClient(
                (res) => {
                    setBonusName(res.data[0].bonusName)
                    setDocument(res.data[0].document)
                    setListBenefits(res.data)
                    if (res.response !== '204') {
                        setData(true)
                    }
                    setLoading(false)
                },
                (e) => {
                    setLoading(false)
                }
            )
        )
    }, [])

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Beneficios',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <ContainerPossessions>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <br />
                <Title>
                    <span>Beneficios</span>
                </Title>
                <br />
                {data && (
                    <Content>
                        <AlignContent>
                            <br />
                            {loading ? (
                                <Loading />
                            ) : (
                                <Title>
                                    Apreciado(a):{' '}
                                    <span>
                                        {bonusName} - {document}
                                    </span>
                                </Title>
                            )}
                            <TextP className="pInfo-center">
                                A continuación relacionamos los beneficios que cavipetrol le está
                                entregando:
                            </TextP>
                        </AlignContent>
                    </Content>
                )}
                <br />
                {loading ? (
                    <Loading />
                ) : (
                    listBenefits?.map((benefit: any, index) => (
                        <div className="benefit" key={index}>
                            {data && (
                                <Content>
                                    {matchMedia ? (
                                        <div>
                                            <Row>
                                                <Col>
                                                    <AlignContent>
                                                        <Image src={CoinHadsSVG} alt="black" />
                                                    </AlignContent>
                                                </Col>
                                                <Col>
                                                    <AlignContent>
                                                        <Title style={{ fontSize: '14px' }}>
                                                            Bono de lealtad
                                                        </Title>
                                                        <TextP
                                                            className="pInfo-center"
                                                            style={{ fontSize: '12px' }}
                                                        >
                                                            {benefit.bonusType}
                                                        </TextP>
                                                    </AlignContent>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AlignContent>
                                                        <Title style={{ fontSize: '14px' }}>
                                                            Valor
                                                        </Title>
                                                        <TextP
                                                            className="pInfo-center"
                                                            style={{ fontSize: '12px' }}
                                                        >
                                                            {formatter.format(benefit.bonusValue)}
                                                        </TextP>
                                                    </AlignContent>
                                                </Col>
                                                <Col style={{ textAlign: 'center' }}>
                                                    <AlignContent>
                                                        <Title style={{ fontSize: '14px' }}>
                                                            Ciudad
                                                        </Title>
                                                        <TextP
                                                            className="pInfo-center"
                                                            style={{ fontSize: '12px' }}
                                                        >
                                                            {benefit.bonusTransferCity}
                                                        </TextP>
                                                    </AlignContent>
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : (
                                        <Row>
                                            <Image src={CoinHadsSVG} alt="black" />
                                            <Col md={3}>
                                                <AlignContent>
                                                    <Title>Bono de lealtad</Title>
                                                    <TextP className="pInfo-center">
                                                        {benefit.bonusType}
                                                    </TextP>
                                                </AlignContent>
                                            </Col>
                                            <Col md={3}>
                                                <AlignContent>
                                                    <Title>Valor</Title>
                                                    <TextP className="pInfo-center">
                                                        {formatter.format(benefit.bonusValue)}
                                                    </TextP>
                                                </AlignContent>
                                            </Col>
                                            <Col md={3}>
                                                <AlignContent>
                                                    <Title>Ciudad</Title>
                                                    <TextP className="pInfo-center">
                                                        {benefit.bonusTransferCity}
                                                    </TextP>
                                                </AlignContent>
                                            </Col>
                                        </Row>
                                    )}
                                </Content>
                            )}
                        </div>
                    ))
                )}
            </ContainerPossessions>
            <Wrapper>
                <ContentW>
                    <ContentInfo>
                        <ImageStar src={IconUserStar} alt="black" />
                        <TextPBold>
                            Haz clic{' '}
                            <a href="https://www.cavipetrol.com/cavibonos/">
                                <span>aquí</span>{' '}
                            </a>
                            para ver los
                            <span>términos y condiciones </span>
                            de tus beneficios.
                        </TextPBold>
                    </ContentInfo>
                </ContentW>
            </Wrapper>
        </LayoutContent>
    )
}

export default Benefits
