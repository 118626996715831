import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import TitleModule from './inc/TitleModule'
import { ContainerPossessions } from './RecreationalTermTitle-styles'
import FormTerm from './inc/FormTerm'
import TableTerm from './inc/TableTerm'

const RecreationalTermTitle = (): JSX.Element => {
    const history = useHistory()
    const [selectedPagoIntereses, setSelectedPagoIntereses] = useState('')
    const [cuotaValue, setCuotaValue] = useState('')
    const [plazoValue, setPlazoValue] = useState('')
    const [rateEM, setRateEm] = useState('')
    const [totalSaving, setTotalSaving] = useState('')
    const [retention, setRetention] = useState('')
    const [step, setStep] = useState<number>(1)

    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Simuladores',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Titulo a termino recreativo',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    const handleData = (
        selectedPagoIntereses: string,
        plazoValue: string,
        cuotaValue: string,
        RateEM: string,
        TotalSaving: string,
        Retention: string
    ): void => {
        setSelectedPagoIntereses(selectedPagoIntereses)
        setPlazoValue(plazoValue)
        setCuotaValue(cuotaValue)
        setRateEm(RateEM)
        setTotalSaving(TotalSaving)
        setRetention(Retention)
        setStep(2)
    }

    const goBackStep = (): void => {
        setStep(1)
    }

    return (
        <>
            <LayoutContent>
                <ContainerPossessions>
                    <br />
                    <BreadcrumbApp
                        breadcrumbs={breadcrumbs}
                        onBack={() => {
                            redirection('/home-wallet')
                        }}
                    />

                    {step === 1 && (
                        <>
                            <TitleModule showParagraph={true} />
                            <FormTerm onNextStep={handleData} />
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <TitleModule customTitle="de ahorro" showParagraph={false} />
                            <TableTerm
                                selectedPagoIntereses={selectedPagoIntereses}
                                plazoValue={plazoValue}
                                cuotaValue={cuotaValue}
                                RateEM={rateEM}
                                TotalSaving={totalSaving}
                                Retention={retention}
                                goBackStep={goBackStep}
                            />
                        </>
                    )}
                </ContainerPossessions>
            </LayoutContent>
        </>
    )
}

export default RecreationalTermTitle
