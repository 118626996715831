import { ReactElement } from 'react'
// styled components
import { StepContent, StepTitle } from '../../appTour-styles'

const DetailCardMovementStep = (): ReactElement => {
    return (
        <StepContent>
            <StepTitle>
                Tus <span className="sub-title">movimientos</span> y transacciones
            </StepTitle>

            <p>
                Todas tus compras aparecerán aquí, al igual que los pagos de tu tarjeta de crédito.
            </p>
        </StepContent>
    )
}

export default DetailCardMovementStep
