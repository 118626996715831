import {
    Wrapper,
    Content,
    TextSteps,
    TextSubtitle,
    TextInfo,
    CancelButton,
    ButtonContinue,
    ButtonsContainer,
} from '../DynamicKeyContainer/DynamicKeyContainer-Styled'

import React, { useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import {
    SectionKeyboard,
    InputContainer,
    DivContainer,
    ButtonRequest,
    SectionText,
    CodeInputStyle,
} from './CurrentAdvanceKey-Styled'
import { useMediaQuery } from 'usehooks-ts'
import { OverlayTrigger } from 'react-bootstrap'
import { Popover } from '../../../../../../components'
import { useHistory } from 'react-router-dom'
import { shuffleArrayKeyboard } from '../../../../../../utils/misc'

interface CurrentAdvanceKeyProps {
    stepNumber: string
    nextStepNumber: string
    onNextStep: (actuallyPin: string) => void
    urlToBack?: any
}

const CurrentAdvanceKey: React.FC<CurrentAdvanceKeyProps> = ({
    stepNumber,
    nextStepNumber,
    onNextStep,
    urlToBack,
}) => {
    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [inputs, setInputs] = useState<string[]>(['', '', '', ''])
    const [activeInputIndex, setActiveInputIndex] = useState<number>(-1)
    const [sequenceValue, setSequenceValue] = useState<string>('')
    const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false)
    const [isLocked, setIsLocked] = useState<boolean>(false)
    const [isFirstInputFilled, setIsFirstInputFilled] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [messageError, setMessageError] = useState('')
    const [keyboardLayout, setKeyboardLayout] = useState<string[]>([])
    const history = useHistory()
    const [isUpdatingLayout, setIsUpdatingLayout] = useState(false)

    const redirection = (url: string): void => {
        history.push(url)
    }

    //Método Handle cuando la entrada de los datos es por teclado físico
    const handleInput = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if ((/^[0-9]$/.test(value) || value === '') && value.length <= 1) {
            const newInputs = [...inputs]
            newInputs[index] = value
            setInputs(newInputs)

            if (value === '' && index > 0) {
                setActiveInputIndex(index - 1)
                setError(false)
            } else if (value.length === 1 && index < 3) {
                setActiveInputIndex(index + 1)
            } else if (value.length === 0 && index > 0) {
                setActiveInputIndex(index - 1)
            } else if (value.length === 1 && index === 3) {
                setIsKeyboardVisible(false)
                const fullSequence = newInputs.join('')
                setSequenceValue(fullSequence)
            }
            if (index === 0 && !isNaN(Number(value))) {
                setIsFirstInputFilled(true)
            }
        } else {
            setError(true)
            setMessageError('Ingrese solo dígitos numéricos.')
        }
    }

    //Método Handle cuando la entrada de los datos es por teclado virtual
    const handleKeyboardInput = (input: string): void => {
        const newSequenceValue = input
        if (newSequenceValue.length >= 1 && !isNaN(Number(newSequenceValue[0]))) {
            setIsFirstInputFilled(true)
            if (newSequenceValue.length >= 4) {
                setSequenceValue(newSequenceValue)
            }
        } else {
            setIsFirstInputFilled(false)
        }
    }

    const handleInputFocus = (index: number): void => {
        setActiveInputIndex(index)
        updateKeyboardLayout()
        if (matchMedia) {
            setIsKeyboardVisible(true)
        }
    }

    const handleInputBlur = (index: number): void => {
        if (inputs[index]) {
            setActiveInputIndex(-1)
        }
    }

    const updateKeyboardLayout = (): void => {
        if (isUpdatingLayout) return
        setIsUpdatingLayout(true)

        const basicLayout = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const layoutWithAsterisks = ['* * *', '* * *', '* * *', ' * ', '{bksp}']
        const shuffledLayout = shuffleArrayKeyboard([...basicLayout])

        const layout = [
            `${shuffledLayout[0]} ${shuffledLayout[1]} ${shuffledLayout[2]}`,
            `${shuffledLayout[3]} ${shuffledLayout[4]} ${shuffledLayout[5]}`,
            `${shuffledLayout[6]} ${shuffledLayout[7]} ${shuffledLayout[8]}`,
            ` ${shuffledLayout[9]} `,
            '{bksp}',
        ]

        setIsLocked(true)
        setKeyboardLayout(layoutWithAsterisks)
        setTimeout(() => {
            setIsLocked(false)
            setKeyboardLayout(layout)
            setIsUpdatingLayout(false)
        }, 600)
    }

    const handleKeyboardKeyPress = (button: string): void => {
        if (button === '{bksp}') {
            const newInputs = [...inputs]
            setError(false)
            if (newInputs.every((value) => value !== '')) {
                newInputs.fill('')
                setInputs(newInputs)
                setSequenceValue('')
                setActiveInputIndex(0)
                setIsKeyboardVisible(true)
                setIsFirstInputFilled(false)
            } else {
                for (let i = newInputs.length - 1; i >= 0; i--) {
                    if (newInputs[i] !== '') {
                        newInputs[i] = ''
                        setInputs(newInputs)
                        setActiveInputIndex(i)
                        break
                    }
                }
            }
        } else if (!isNaN(Number(button)) && button !== '') {
            const emptyIndex = inputs.findIndex((input) => input === '')

            if (emptyIndex !== -1) {
                const newInputs = [...inputs]
                newInputs[emptyIndex] = button
                setInputs(newInputs)
                const nextEmptyIndex = newInputs.findIndex((input) => input === '')

                if (nextEmptyIndex !== -1) {
                    setActiveInputIndex(nextEmptyIndex)

                    if (nextEmptyIndex === inputs.length) {
                        setIsKeyboardVisible(false)
                    }
                }
            }
        }
    }

    const handleNext = (): void => {
        onNextStep(sequenceValue)
    }

    return (
        <Wrapper>
            <Content>
                <TextSteps>
                    Paso {stepNumber} de {nextStepNumber}
                </TextSteps>
                <TextInfo>
                    Para cambiar tu clave, debes ingresar la clave anterior, la misma que utilizas
                    en el cajero.
                </TextInfo>
                <TextSubtitle>Clave para avances</TextSubtitle>
                <DivContainer>
                    <OverlayTrigger
                        show={isKeyboardVisible && activeInputIndex >= 0}
                        placement="right"
                        overlay={
                            <Popover noSpace>
                                <SectionKeyboard>
                                    <Keyboard
                                        inputName="keyboardInput"
                                        onChange={handleKeyboardInput}
                                        onKeyPress={handleKeyboardKeyPress}
                                        display={{ '{bksp}': 'BORRAR' }}
                                        stopMouseDownPropagation={isLocked}
                                        stopMouseUpPropagation={isLocked}
                                        stopKeyPressPropagation={isLocked}
                                        layout={{
                                            default: keyboardLayout,
                                        }}
                                        theme={'hg-theme-default myTheme'}
                                        inputPattern={/^[^*]+$/}
                                    />
                                </SectionKeyboard>
                            </Popover>
                        }
                    >
                        <InputContainer style={{ marginBottom: '10px' }}>
                            {inputs.map((input, index) => (
                                <CodeInputStyle
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={input ? '*' : ''}
                                    onFocus={() => handleInputFocus(index)}
                                    onBlur={() => handleInputBlur(index)}
                                    onChange={handleInput(index)}
                                    style={{
                                        borderColor: error ? '#dc3545' : '',
                                    }}
                                    ref={(inputRef) => {
                                        if (index === activeInputIndex && inputRef) {
                                            inputRef.focus()
                                        }
                                    }}
                                    disabled={index > 0 && !isFirstInputFilled}
                                    readOnly={matchMedia}
                                />
                            ))}

                            <SectionText>
                                {error && (
                                    <TextInfo style={{ color: '#dc3545' }}>{messageError}</TextInfo>
                                )}
                            </SectionText>
                        </InputContainer>
                    </OverlayTrigger>
                </DivContainer>
                <div style={{ textAlign: 'initial', margin: '0px 0px 130px 0px' }}>
                    <ButtonRequest onClick={() => redirection('/reset-key-advances')}>
                        Olvidé mi clave para avances
                    </ButtonRequest>
                </div>

                <ButtonsContainer>
                    <CancelButton onClick={() => redirection(urlToBack)}>Cancelar</CancelButton>
                    <ButtonContinue onClick={handleNext} disabled={!sequenceValue}>
                        Validar
                    </ButtonContinue>
                </ButtonsContainer>
            </Content>
        </Wrapper>
    )
}

export default CurrentAdvanceKey
