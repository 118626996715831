import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { CertifiedWatermark } from '../../../../utils/getImages'
interface PDFGeneratorProps {
    Header: string
    Body: string
    Footer: string
    Valediction: string
}

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ Header, Body, Footer, Valediction }) => {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            padding: 30,
            fontWeight: 'bold',
            position: 'relative',
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 600, // Usar el ancho de página proporcionado
            height: 840, // Usar la altura de página proporcionada
            zIndex: -1, // Coloca la imagen de fondo detrás de otros elementos
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        header: {
            fontFamily: 'Helvetica-Bold',
            marginBottom: 5,
            marginTop: 90,
            textAlign: 'justify',
            fontSize: 15,
        },
        header2: {
            fontFamily: 'Helvetica-Bold',
            marginBottom: 10,
            marginTop: 20,
            textAlign: 'justify',
            fontSize: 13,
        },
        subHeader: {
            marginBottom: 20,
            textAlign: 'justify',
            fontSize: 15,
        },
        body: {
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },
        footer: {
            marginTop: 5,
            marginBottom: 20,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },
        Valediction: {
            marginTop: 20,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },

        certifica: {
            marginTop: 20,
        },
    })

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <Image src={CertifiedWatermark} style={styles.backgroundImage} fixed />
                <View style={styles.section} wrap={false}>
                    <Text style={styles.header}>
                        {
                            'Fondo de Empleados de los Trabajadores \ny Pensionados de Ecopetrol S.A CAVIPETROL'
                        }
                    </Text>
                    <Text style={styles.subHeader}>{'Identificado con NIT 860.006.773-2'}</Text>
                    <Text style={styles.header2}>{Header}</Text>
                    <View style={styles.body}>
                        {/* <Text style={styles.certifica}></Text> */}
                        <Text>{Body}</Text>
                    </View>

                    <Text style={styles.footer}>{Footer}</Text>
                    <Text style={styles.Valediction}>{Valediction.replace('Remitente: ', '')}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default PDFGenerator
