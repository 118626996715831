import { ReactElement, FC } from 'react'
import { useHistory } from 'react-router-dom'

// styles
import {
    MoraCardBottom,
    MoraCardTop,
    MoraCardTopRight,
    MoraCard as Card,
    ExceptionCtrImage,
    ExceptionImage,
    TitleStep,
    ParagraphStep,
} from './moraCard-styles'
import {
    formatDecimalValue,
    formatValue,
} from '../../../../../../components/GlobalFuntions/globalFunction'

export interface MoraCardProps {
    image: string
    title: string
    value: string
    url: string
    type: 'credit' | 'saving' | 'tc'
}

const MoraCard: FC<MoraCardProps> = ({ image, title, value, url, type }): ReactElement => {
    const history = useHistory()

    const redirection = (path: string, data?: Record<string, string | number>): void => {
        history.push(path, data)
    }
    return (
        <Card>
            <MoraCardTop>
                <ExceptionCtrImage>
                    <ExceptionImage src={image} alt="image" />
                </ExceptionCtrImage>
                <MoraCardTopRight>
                    <TitleStep>{title}</TitleStep>
                    <ParagraphStep>
                        $ {formatValue(value, 1)}
                        <sup>{formatDecimalValue(value, 1)}</sup>
                    </ParagraphStep>
                </MoraCardTopRight>
            </MoraCardTop>
            <MoraCardBottom
                onClick={() => {
                    redirection(url)
                }}
            >
                <ParagraphStep>
                    {type === 'credit' && 'Ir a productos de créditos'}
                    {type === 'saving' && 'Ir a productos de ahorros'}
                    {type === 'tc' && 'Ir a tarjeta de crédito'}
                    {/* Ir a productos de {type === 'credit' ? 'créditos' : 'ahorros'} */}
                </ParagraphStep>
            </MoraCardBottom>
        </Card>
    )
}

export default MoraCard
