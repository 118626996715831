import { Modal } from 'react-bootstrap'
import { CreditCardFlowSVG } from '../../../../utils/getIcons'
import {
    ButtonContent,
    ButtonsContainer,
    ModalButton,
    ModalInfoRequest,
} from './../../AlertCreditCardRequests-Styled'
import { Button } from '../../../../components'

interface ModalRequestTCAppProps {
    showModal?: boolean
    handleNewRequest: () => void
    handleContinueRequest: () => void
    onHide: () => void
}

const ModalRequestTC: React.FC<ModalRequestTCAppProps> = ({
    showModal,
    handleNewRequest,
    handleContinueRequest,
    onHide,
}): JSX.Element => {
    return (
        <ModalInfoRequest
            show={showModal}
            onHide={handleNewRequest}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CreditCardFlowSVG} alt="CreditCardFlowSVG" />
                <h6 className="title-modal-login">Tienes una solicitud pendiente</h6>
                <p>Puedes continuar donde lo dejaste o iniciar una desde cero.</p>
                <ButtonsContainer>
                    <ModalButton variant="primary" onClick={handleNewRequest}>
                        Iniciar nuevo formulario
                    </ModalButton>

                    <ButtonContent>
                        <Button
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={handleContinueRequest}
                            extend
                        >
                            Continuar
                        </Button>
                    </ButtonContent>
                </ButtonsContainer>
            </Modal.Body>
        </ModalInfoRequest>
    )
}

export default ModalRequestTC
