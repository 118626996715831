import { possessionsService } from '../../../../../domain/services/User.service'

export const setCommonValues = (data: any, tipoInmueble: string, accion: string) => {
    const dataToSend = {
        RealEstateType: parseInt(tipoInmueble),
        RealEstateOffer: parseInt(accion),
        Price: parseInt(data['price']),
        City: parseInt(data['city']),
        Administration: parseInt(data['administration']),
        Images: [],
        Stratum: parseInt(data['stratum']),
        Address: data['address'],
        Neighborhood: data['neighborhood'],
        AgentId: 22,
        Latitude: 0,
        Longitude: 0,
        Video: data['video'],
        Comments: data['description'],
        ResponseUrl:
            'https://5kpfbdi70i.execute-api.us-east-1.amazonaws.com/default/callback-express',
        Amenities: {},
    }

    return dataToSend
}

export async function useInsertData(dataToSend: any, tokenSave: string, images: File[]) {
    try {
        return await possessionsService
            .postImagesWithData(
                'api/RealEstateExchangeM2/PostRealEstate',
                dataToSend,
                images,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
