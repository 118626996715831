import { Modal } from 'react-bootstrap'
// icons

// styles

import { useHistory } from 'react-router-dom'
import { InfoModal, ModalTitle, ModalText, ButtonsContainer, ModalButton } from './ModalInfo-Styled'
import { NotProductSVG } from '../../../../../../utils/getIcons'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    textTitle?: string
    textBody?: string
}

const ModalError: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    textBody,
    textTitle,
}): JSX.Element => {
    const history = useHistory()
    const handleClick = () => {
        history.push('/home-wallet')
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={NotProductSVG} alt="Editar logo" className="" />
                <ModalTitle>{textTitle}</ModalTitle>
                <ModalText>{textBody}</ModalText>

                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Aceptar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalError
