import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import UserNameSearch from './inc/UserNameSearch/UserNameSearch'
import SearchResult from './inc/SearchResult/SearchResult'
import UserLockToggle from './inc/UserLockToggle/UserLockToggle'
import OperationManager from './OperationManager/OperationManager'
import { useSelector } from 'react-redux'
import { Loading, LoadingScreen, ModalGeneric } from '../../components'
import { NotProductSVG } from '../../utils/getIcons'
import {
    fetchGetFullNameUser,
    useGetBlocking,
    useGetRememberLogin,
} from './hooks/UseUserOperations'
import { LoadingContainer } from '../CreditDetail/creditDetail-styles'
import InitializeUser from './inc/InitializeUser'

interface UserBlocking {
    Code: string
    Description: string
    FechaControl: string
    FailTry: string
    Message: string
}

const UserOperations = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [step, setStep] = useState<number>(1)

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [userBlockStatus, setUserBlockStatus] = useState<UserBlocking | null>(null)
    const [isSearch, setIsSearch] = useState<boolean>(false)
    const [service, setService] = useState<string>('')

    const [codeVoucher, setCodeVoucher] = useState<string>('')
    const [userRememberLogin, setUserRememberLogin] = useState<string>('')
    const [id, setID] = useState<string>('')
    const [userNameFullName, setUserNameFullName] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory()

    const GetDataUsers = (document: string, userName: string, service: string): void => {
        if (document !== '' || userName !== '') {
            setIsLoading(true)
            fetchGetFullNameUser(tokenSave, document, userName)
                .then((res: any) => {
                    setIsLoading(false)
                    const responseRes = res.data?.Response || res.Response
                    if (responseRes === '200') {
                        setUserNameFullName(res['data']['Data'])
                        if (service === 'Bloqueo/Desbloqueo') {
                            GetBlocking(userName, document)
                        }
                    } else {
                        const messageInfo = res.data?.Message || res.Message
                        setErrorMessage(messageInfo)
                        setShowError(true)
                    }
                })
                .catch((error) => {
                    console.error(error)
                    setErrorMessage('No se ha podido conectar con el servidor')
                    setShowError(true)
                })
        }
    }

    const GetBlocking = (userName: string, docNumber: string): void => {
        setLoading(true)
        useGetBlocking(tokenSave, userName, docNumber)
            .then((res: any) => {
                setLoading(false)
                const responseRes = res.data?.Response || res.Response
                if (responseRes === '200') {
                    if (res['data']['Data']['Code'] !== '00') {
                        setErrorMessage('Lo sentimos, ' + res['data']['Data']['Description'])
                        setShowError(true)
                    } else {
                        setUserBlockStatus(res['data']['Data'])
                    }
                } else {
                    const messageInfo = res.data?.Message || res.Message
                    setErrorMessage(messageInfo)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const cleanDocNumber = (docNumber: string): string => {
        return docNumber.replace(/\s/g, '').replace(/[^\w\s]/gi, '')
    }

    const searchUser = (key: string): void => {
        GetRememberLogin(key)
    }

    const handleClose = (): void => {
        setShowError(false)
        history.push('/home-wallet')
    }

    const handleNextStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handlePrevStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handleStep1 = (
        value: boolean,
        userName: string,
        documentNumber: string,
        service: string
    ): void => {
        setService(service)
        setIsSearch(value)
        setUserRememberLogin(userName)
        setID(documentNumber)
        GetDataUsers(documentNumber, userName, service)
    }

    const GetRememberLogin = (docNumber: string): void => {
        setLoading(true)
        const cleanedDocNumber = cleanDocNumber(docNumber)
        useGetRememberLogin(tokenSave, cleanedDocNumber)
            .then((res: any) => {
                setLoading(false)
                const responseRes = res.data?.Response || res.Response
                if (responseRes === '200') {
                    if (res['data']['Data']['Code'] !== '00') {
                        setErrorMessage('Lo sentimos, ' + res['data']['Data']['Message'])
                        setShowError(true)
                    } else {
                        setUserRememberLogin(res['data']['Data']['Response'])
                        setCodeVoucher(res['data']['Data']['Description'])
                        setID(docNumber)
                    }
                } else {
                    const messageInfo = res.data?.Message || res.Message
                    setErrorMessage(messageInfo)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    return (
        <>
            {' '}
            {step === 1 && (
                <OperationManager onNextStep={handleNextStepChange} handleSubmitted={handleStep1} />
            )}
            {step === 2 && isSearch && (
                <UserNameSearch
                    filter={searchUser}
                    loading={loading}
                    onPrevStep={handlePrevStepChange}
                    onNextStep={handleNextStepChange}
                />
            )}
            {step === 3 &&
                (loading ? (
                    <LoadingContainer>
                        {' '}
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        <SearchResult
                            identificationCard={id}
                            receipt={codeVoucher || '00'}
                            userName={userRememberLogin}
                            onPrevStep={handlePrevStepChange}
                            onNextStep={handleNextStepChange}
                        />
                    </>
                ))}
            {step === 4 &&
                (loading ? (
                    <LoadingContainer>
                        {' '}
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {service === 'Bloqueo/Desbloqueo' ? (
                            <>
                                {userBlockStatus && (
                                    <UserLockToggle
                                        controlDate={userBlockStatus?.FechaControl}
                                        controlDescription={userBlockStatus.Description}
                                        failedAttempts={userBlockStatus.FailTry}
                                        identificationNumber={id}
                                        lockStatus={userBlockStatus.Message}
                                        name={userNameFullName}
                                        userLogin={userRememberLogin}
                                        onPrevStep={handlePrevStepChange}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <InitializeUser
                                    identificationNumber={id}
                                    nameFull={userNameFullName}
                                    userName={userRememberLogin}
                                    onPrevStep={handlePrevStepChange}
                                />
                            </>
                        )}
                    </>
                ))}
            {isLoading && (
                <>
                    <LoadingScreen></LoadingScreen>
                </>
            )}
            <ModalGeneric
                show={showError}
                handleClose={handleClose}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessage}
                handleButton={handleClose}
                textButton="Aceptar"
            />
        </>
    )
}

export default UserOperations
