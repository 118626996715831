import tw, { styled } from 'twin.macro'

export const FormSelect = styled.div`
    ${tw``}
    select.selectFromControl {
        background-image: var(--url-arrow-down-yellow-icon) !important;
    }
`

export const ButtonPagination = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    color: var(--sub-dominant-color) !important;
    width: auto !important;

    .button-pagination {
        color: var(--sub-dominant-color) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }
`

export const TitleSectionForm = styled.h1`
    ${tw`mt-[3em] mb-[1.5em] font-montserrat text-[#626262] text-[24px]`}
    text-align:start;

    @media (max-width: 460px) {
        ${tw`mt-[3em] mb-[0.5em] ml-[0] font-montserrat text-[#626262] text-[22px]`}
        text-align:left;
    }
`
