import { Col, Row, Form, OverlayTrigger } from 'react-bootstrap'
import {
    ButtonsContainer,
    HeadTransactionStep,
    ModalButton,
    ModalCancelButton,
    ParagraphStep,
    SectionKeyboard,
    SubtitleTransaction,
    TextP,
    TitleTransaction,
} from './SecondStep-Styled'
import { ModalGeneric, Popover } from '../../../../components'
import { useMediaQuery } from 'usehooks-ts'
import { useEffect, useState } from 'react'
import { useCavifacilTransaction, useValidateIVR } from '../../hooks/useCaviFacil'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ExclamationShieldSVG, NotProductSVG } from '../../../../utils/getIcons'
import { useHistory } from 'react-router-dom'

import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import ModalLoading from '../Modals/ModalLoading'
import { Content, Wrapper } from '../../CavifacilTransaction-Styled'

import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'

import { getCreditsAvailableAction } from '../../../../../redux/products'
import { CreditsAvailable } from '../../../../../../domain/models'
import { creditGroupSelector } from '../../../../../selectors'
import { shuffleArrayKeyboard } from '../../../../utils/misc'

interface SecondStepProps {
    onNextStep: () => void
    handleData: (
        dateTransaction: string,
        creditNumber: string,
        documentNumber: string,
        value: string
    ) => void
    amountTransfer: string
}

const SecondStep: React.FC<SecondStepProps> = ({ onNextStep, handleData, amountTransfer }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const methods = useForm()
    const { formState, setError, clearErrors, register, getValues } = methods
    const { errors } = formState

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const isMobile = useMediaQuery('(max-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)
    const [passIVR, setPassIVR] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isNotProduct, setIsNotProduct] = useState(false)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [keyboardLayout, setKeyboardLayout] = useState<string[]>([])
    const [isUpdatingLayout, setIsUpdatingLayout] = useState(false)
    const [isLocked, setIsLocked] = useState<boolean>(false)

    const { listCards } = useSelector(creditGroupSelector)

    const targetCreditLineName = 'CaviFacil'

    const filteredCreditNumbers = listCards
        .filter((item: CreditsAvailable) => item.creditLineName === targetCreditLineName)
        .map((item) => item.creditNumber)

    useEffect(() => {
        dispatch(getCreditsAvailableAction())
    }, [dispatch])

    const updateKeyboardLayout = (): void => {
        if (isUpdatingLayout) return
        setIsUpdatingLayout(true)

        const basicLayout = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const layoutWithAsterisks = ['* * *', '* * *', '* * *', ' * ', '{bksp}']
        const shuffledLayout = shuffleArrayKeyboard([...basicLayout])

        const layout = [
            `${shuffledLayout[0]} ${shuffledLayout[1]} ${shuffledLayout[2]}`,
            `${shuffledLayout[3]} ${shuffledLayout[4]} ${shuffledLayout[5]}`,
            `${shuffledLayout[6]} ${shuffledLayout[7]} ${shuffledLayout[8]}`,
            ` ${shuffledLayout[9]} `,
            '{bksp}',
        ]

        setIsLocked(true)
        setKeyboardLayout(layoutWithAsterisks)
        setTimeout(() => {
            setIsLocked(false)
            setKeyboardLayout(layout)
            setIsUpdatingLayout(false)
        }, 600)
    }

    const handleKeyPress = (button: string): void => {
        if (button === '{bksp}') {
            setPassIVR((prevPass) => prevPass.slice(0, -1))
        } else {
            updateKeyboardLayout()
        }
    }

    const onFocusInput = (): void => {
        if (matchMedia) {
            setShowKeyboard(true)
        }
        updateKeyboardLayout()
        console.log(amountTransfer)
    }

    const handleContainerMouseLeave = (): void => {
        setShowKeyboard(false)
    }

    const handleSeeKeyboard = (event: React.MouseEvent<HTMLDivElement>): void => {
        setShowKeyboard(false)
    }

    const handleOnChangeDigit = (inputChanged: string): void => {
        setPassIVR(inputChanged)
        ValidateIVRInputs(inputChanged)
    }

    const handleOnChangeDigitMobile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        if (value.length === 0) {
            setError('PassIVR', {
                message: 'Este campo es obligatorio',
            })
        } else if (/^[0-9\b]*$/.test(value)) {
            setPassIVR(value)
        } else {
            setError('PassIVR', {
                message: 'Ingrese solo dígitos numéricos.',
            })
        }
    }

    const ValidateIVR = (): void => {
        setIsLoading(true)
        handleContainerMouseLeave()
        useValidateIVR(tokenSave, passIVR)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response

                if (responseCode === '200') {
                    PostTransactionCavifacil()
                } else {
                    setIsLoading(false)
                    setError('PassIVR', {
                        message: res.Message,
                    })
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setError('PassIVR', {
                    message: 'Error al validar clave IVR',
                })
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const PostTransactionCavifacil = (): void => {
        setIsLoading(true)
        const dataSend = {
            Value: parseInt(amountTransfer),
        }
        useCavifacilTransaction(tokenSave, dataSend)
            .then((res: any) => {
                setIsLoading(false)

                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                const dateTransaction = res.data?.Data.Date || ''
                const documentNumber = res.data?.Data.DocumentNumber || ''
                const value = res.data?.Data.Value || ''
                const message = res.data?.Message || res.Message

                const creditNumber =
                    res.data?.Data.CreditNumber !== null
                        ? res.data?.Data.CreditNumber
                        : filteredCreditNumbers[0]

                handleData(dateTransaction, creditNumber, documentNumber, value)
                console.log('creditNumber: ' + creditNumber)
                if (responseIsSuccess) {
                    onNextStep()
                } else {
                    setErrorMessage(message)
                    setIsNotProduct(true)
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage(
                    'No se ha podido conectar con el servidor, por favor intente más tarde.'
                )
                setShowError(true)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
        if (isNotProduct) {
            history.push('/home-wallet')
        }
    }

    const ValidateIVRInputs = (passIVRValue: string): void => {
        if (passIVRValue.length === 0) {
            setError('PassIVR', {
                message: 'Este campo es obligatorio',
            })
        } else if (passIVRValue.length < 4) {
            setError('PassIVR', {
                message: 'Ingresa cuatro caracteres',
            })
        } else {
            clearErrors('PassIVR')
        }
    }

    return (
        <Wrapper onMouseLeave={handleContainerMouseLeave}>
            <Content>
                <HeadTransactionStep>
                    <TitleTransaction>
                        <strong>&nbsp;CaviFácil&nbsp;</strong>
                    </TitleTransaction>
                    <SubtitleTransaction>Ingresa tu Clave IVR para continuar</SubtitleTransaction>
                </HeadTransactionStep>

                <div style={{ height: '210px', marginTop: '40px' }}>
                    <Row>
                        <Col md={4} className="border-option-header">
                            <TextP>
                                <span>Valor a utilizar de tu CaviFácil</span>
                            </TextP>
                            <ParagraphStep>
                                $ {formatValue(amountTransfer, 1)}
                                <sup>{formatDecimalValue(amountTransfer, 1)}</sup>
                            </ParagraphStep>
                        </Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={5}>
                            <TextP>Clave IVR</TextP>
                            <OverlayTrigger
                                show={showKeyboard}
                                placement="right"
                                overlay={
                                    <Popover noSpace>
                                        <SectionKeyboard>
                                            <Keyboard
                                                onChange={(input) => handleOnChangeDigit(input)}
                                                onMouseLeave={handleSeeKeyboard}
                                                onKeyPress={handleKeyPress}
                                                display={{ '{bksp}': 'BORRAR' }}
                                                stopMouseDownPropagation={isLocked}
                                                stopMouseUpPropagation={isLocked}
                                                stopKeyPressPropagation={isLocked}
                                                layout={{
                                                    default: keyboardLayout,
                                                }}
                                                theme={'hg-theme-default myTheme'}
                                                inputPattern={/^[^*]+$/}
                                                defaultValue={getValues('PassIVR')}
                                            />
                                        </SectionKeyboard>
                                    </Popover>
                                }
                            >
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="input-ivr-icon"
                                        type="password"
                                        {...register('PassIVR', {
                                            required: true,
                                        })}
                                        readOnly={matchMedia && !isMobile}
                                        onChange={handleOnChangeDigitMobile}
                                        value={passIVR}
                                        required
                                        isInvalid={!!errors['PassIVR']}
                                        onFocus={onFocusInput}
                                    />

                                    {errors['PassIVR'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['PassIVR']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </OverlayTrigger>
                            {showKeyboard && (
                                <div className="label-floating">
                                    Por tu seguridad utiliza el teclado virtual
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
                <ButtonsContainer>
                    <ModalCancelButton
                        variant="primary"
                        onClick={() => history.push('/home-wallet')}
                    >
                        Cancelar
                    </ModalCancelButton>
                    <ModalButton
                        disabled={passIVR === '' || passIVR.length < 4}
                        type="button"
                        onClick={ValidateIVR}
                    >
                        Siguiente
                    </ModalButton>
                </ButtonsContainer>
            </Content>

            <ModalGeneric
                show={showError}
                img={isNotProduct ? NotProductSVG : ExclamationShieldSVG}
                textTitle={'Ha ocurrido un error'}
                textBody={errorMessage}
                handleButton={handleClose}
                handleClose={handleClose}
                textButton="Aceptar"
            />
            <ModalLoading
                isLoading={isLoading}
                textInfo="Aguarda un momento, estamos validando la información"
                textLoading={'Estamos procesando tu solicitud'}
            />
        </Wrapper>
    )
}

export default SecondStep
