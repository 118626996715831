import { Button } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import tw, { styled } from 'twin.macro'

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}

    span {
        color: #f5a50b;
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 lg:w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 400px) and (max-width: 800px) {
        ${tw`w-[300px]`}
    }
    @media (max-width: 399px) {
        ${tw`w-[270px]`}
    }
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-5 lg:w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color) !important;
    }

    background-color: #ffffff;
    border-color: #000000 !important;

    @media (min-width: 400px) and (max-width: 800px) {
        ${tw`w-[300px]`}
    }
    @media (max-width: 399px) {
        ${tw`w-[270px]`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: right;
    align-items: center;
`

export const FormMessage = styled(Feedback)`
    ${tw`font-montserrat font-normal text-sm text-alert mt-[5px]`}
`

export const DivContainer = styled.div`
    ${tw`w-72`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }

    @media (max-width: 600px) {
        ${tw`h-26`}
    }

    popover-arrow {
        top: -72px !important;
    }
`

export const InputContainer = styled.div`
    ${tw`w-72 h-24`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`

export const SectionKeyboard = styled.div`
    ${tw`w-56`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`

export const ParagraphStep = styled.p`
    ${tw`font-montserrat font-normal block text-base my-3`}
    color: #444444;
    opacity: 1;
`

export const HeadTransactionStep = styled.div`
    ${tw`m-[0px 0 20px] `}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        ${tw`m-[0px 0 40px]`}
    }
`

export const TitleStep = styled.p`
    ${tw`font-helvetica font-normal text-base text-dominant-dark block`}
`

export const TitleTransaction = styled.h3`
    ${tw`flex font-montserrat font-bold text-lg text-dark-custom-700 m-0`}
    color: #F5A50B;
    strong {
        ${tw`text-dominant-dark`}
        color: #F5A50B;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        ${tw`text-2xl`}
    }
`

export const SubtitleTransaction = styled.p`
    ${tw`font-helvetica font-normal text-dark-custom-700 text-base mt-5`}
`
