import React, { useEffect } from 'react'
import { http } from '../http/axiosHttp'
import { APIResponse } from '../../domain/models/User'

const useGetUser = (endpoint: string) => {
    const [data, setData] = React.useState(<APIResponse | undefined>{})
    const [loading, setLoading] = React.useState(false)

    const getUsers = async (url: string): Promise<APIResponse | undefined> => {
        return http.get(url)
    }

    useEffect(() => {
        setLoading(true)

        getUsers(endpoint).then((info) => {
            setData(info)
            setLoading(false)
        })
    }, [])

    return {
        data,
        loading,
    }
}

const useGet = async (endpoint: string) => {
    return http
        .get(endpoint)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const getCode = async (endpoint: string) => {
    return http
        .postLight(endpoint)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const useGetSecurity = (endpoint: string, token: string) => {
    return http
        .getSecurity(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const useGetSecurityC = (endpoint: string, token: string, body: any) => {
    return http
        .getSecurityC(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const getTransaction = (endpoint: string, token: string, body: any) => {
    return http
        .postSecurity(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const usePost = (endpoint: string, body: any) => {
    return http
        .post(endpoint, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const usePostSecurity = (endpoint: string, token: string) => {
    return http
        .postSecurityLigth(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const putTransaction = (endpoint: string, token: string, body: any) => {
    return http
        .putSecurity(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const deleteTransaction = (endpoint: string, token: string) => {
    return http
        .deleteSecurity(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const postImagesWithData = (endpoint: string, body: any, files: File[], token: string) => {
    return http
        .postWithFiles(endpoint, body, files, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const postTransactionSecurity = (endpoint: string, token: string, body: any) => {
    return http
        .postSecurity(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const getTransactionSecurityTC = (endpoint: string, token: string) => {
    return http
        .getSecurityTC(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const postFileWithData = (endpoint: string, body: any, files: File[], token: string) => {
    return http
        .postWithFilesCampaign(endpoint, body, files, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const putFileWithData = (endpoint: string, body: any, files: File[], token: string) => {
    return http
        .putWithFilesCampaign(endpoint, body, files, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const postUploadFilesCampaign = (endpoint: string, files: File[], token: string) => {
    return http
        .postUploadFilesCampaign(endpoint, files, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const postPdf = (endpoint: string, token: string, body: any) => {
    return http
        .postSecurity(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const getDepartment = (endpoint: string, token: string) => {
    return http
        .getDepartment(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const getCities = (endpoint: string, token: string) => {
    return http
        .getCities(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const getListUpdateData = (endpoint: string, token: string) => {
    return http
        .getListUpdateData(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const getListCitiesByCountriesUser = (endpoint: string, token: string, body: any) => {
    return http
        .getListCitiesByCountriesUser(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const getUpdateDataAssociate = (endpoint: string, token: string) => {
    return http
        .getUpdateDataAssociate(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}
const postUpdateDataAssociate = (endpoint: string, token: string, body: any) => {
    return http
        .postUpdateDataAssociate(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const putTransactionSecurityLight = (endpoint: string, token: string) => {
    return http
        .putSecurityLight(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const patchTransactionSecurityLight = (endpoint: string, token: string) => {
    return http
        .patchSecurityLight(endpoint, token)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

const patchTransactionSecurity = (endpoint: string, token: string, body: any) => {
    return http
        .patchSecurity(endpoint, token, body)
        .then((res) => {
            return res
        })
        .catch((error) => {
            return error
        })
}

export const userRepository = {
    useGetUser,
    useGet,
    useGetSecurity,
    useGetSecurityC,
    getTransaction,
    usePost,
    usePostSecurity,
    getCode,
    putTransaction,
    deleteTransaction,
    postImagesWithData,
    postTransactionSecurity,
    getTransactionSecurityTC,
    postFileWithData,
    putFileWithData,
    postUploadFilesCampaign,
    getDepartment,
    getCities,
    getListUpdateData,
    getListCitiesByCountriesUser,
    getUpdateDataAssociate,
    postUpdateDataAssociate,
    postPdf,
    putTransactionSecurityLight,
    patchTransactionSecurityLight,
    patchTransactionSecurity,
}
