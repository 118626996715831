import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    AceptButton,
    CancelButton,
    TermsAndCondContainer,
    PpalTitle,
    ArrowBlack,
    TextP,
    Wrapper,
    TermsContent,
    TextPBold,
    ButtonsContainer,
} from '../../UserCreation-styles'
import { ArrowCircleSVG } from '../../../../utils/getIcons'

interface UserCreationTermsProps {
    onAccept: () => void
    onReject: () => void
}

const UserCreationTerms: React.FC<UserCreationTermsProps> = ({ onAccept, onReject }) => {
    const history = useHistory()

    const redirection = (url: string): void => {
        history.push(url)
    }

    return (
        <Wrapper>
            <TermsContent>
                <ArrowBlack onClick={() => redirection('/login')}>
                    <img src={ArrowCircleSVG} alt="black" />
                    <p>Volver</p>
                </ArrowBlack>
                <br></br>
                <div className="terms-note">
                    <PpalTitle>
                        <span className="user-terms">
                            Términos y condiciones y reglamento de uso del web site cavipetrol
                        </span>
                    </PpalTitle>
                </div>
                <br></br>
                <TermsAndCondContainer>
                    Por favor lea cuidadosamente los Términos, Condiciones y reglamento de uso del
                    web site de Cavipetrol. Haciendo clic en "Acepto" usted estará aceptando los
                    Términos y Condiciones bajo los cuales se hará el registro, si presiona "No
                    Acepto" usted no podrá suscribirse, ni usar el servicio.
                </TermsAndCondContainer>
                <br></br>
                <TermsAndCondContainer>
                    <TextPBold>REGLAMENTO DE USO DEL WEB SITE DE CAVIPETROL - CAVINET</TextPBold>
                    <TextP style={{ textAlign: 'justify' }}>
                        Le solicitamos al asociado de CAVIPETROL que antes de utilizar los servicios
                        del sitio web de CAVIPETROL, lea previa, detallada y cuidadosamente el
                        presente documento que incorpora el REGLAMENTO DE USO DEL CANAL DE INTERNET-
                        CAVINET, cuya aceptación es requisito para usar validamente los mismos. El
                        incumplimiento PARCIAL O TOTAL de este Reglamento exonera a CAVIPETROL de
                        cualquier tipo de responsabilidad frente al asociado o terceros: CAVIPETROL,
                        con domicilio principal en la ciudad de Bogotá D.C. - Colombia, expide el
                        presente REGLAMENTO DE USO DEL CANAL DE INTERNET- CAVINET, el cual establece
                        las condiciones de acceso, confianza y seguridad, para utilizar la red de
                        Internet, como alterna o adicional a la red de oficinas y sucursales de
                        CAVIPETROL: PRIMERA.-OBJETO.- En virtud del presente Reglamento CAVIPETROL,
                        establece las condiciones y reglas, para colocar a disposición del asociado,
                        el canal de internet, como canal alterno para prestar los servicios de
                        consulta de los productos que posea EL asociado, a la fecha de aceptación de
                        este Reglamento. PARÁGRAFO.- Este Reglamento se aplica igualmente, a los
                        nuevos servicios o productos de banca virtual, que establezca CAVIPETROL a
                        través de internet, sin perjuicio de que se adopten reglas específicas para
                        el nuevo servicio o producto. SEGUNDA.-INCORPORACIÓN POR REMISIÓN.- El
                        contenido de este Reglamento hace parte integral de los contratos y
                        reglamentos convenidos o aceptados por EL asociado, para usar servicios y
                        productos de CAVIPETROL. Dichos contratos y reglamentos son conocidos por el
                        asociado y están incorporados por remisión a este Reglamento, conforme al
                        artículo 44 de la Ley 527 de 1999 (Por medio de la cual se define y
                        reglamenta el acceso y uso de los mensajes de datos, del comercio
                        electrónico y de las firmas digitales, y se establecen las entidades de
                        certificación y se dictan otras disposiciones). PARÁGRAFO.- Los Términos de
                        Uso y las Políticas de Privacidad del sitio web de CAVIPETROL, a través del
                        cual CAVIPETROL, presta sus servicios en internet, conforme a este
                        Reglamento, hacen parte integral del mismo y se incorporan por remisión a
                        este. TERCERA.- CANAL EN INTERNET.- CAVIPETROL presta sus servicios de
                        consulta en internet, a través del sitio web localizado en los Uniform
                        Resource Locators URL: https://www.CAVIPETROL.com y
                        http://www.CAVIPETROL.com o en otro URL, que CAVIPETROL designe en cualquier
                        tiempo. CUARTA.- ACEPTACIÓN DEL REGLAMENTO.- La aceptación de este acuerdo
                        se regulará como sigue: a) El presente Reglamento puede ser aceptado o no
                        por EL asociado; b) Si EL asociado lo acepta se aplicarán la totalidad de
                        las disposiciones en él consignadas; c) La aceptación por primera y única
                        vez de este Reglamento, la lectura y el conocimiento de su contenido por
                        parte del asociado, se realiza cuando EL asociado, oprime el botón aceptar,
                        que se encuentra al final de esta página web y su negación se realiza cuando
                        oprime el botón cancelar, d) El acceso del asociado, a los productos y
                        servicios de internet, en cualquier momento posterior a la aceptación de
                        este Reglamento, está sujeto a la determinación y aceptación final por parte
                        de CAVIPETROL; e) Al aceptar este Reglamento, EL asociado acepta cualquier
                        modificación posterior que se realice al mismo o a los procedimientos o
                        instrucciones que defina CAVIPETROL, para el proceso de acceso,
                        identificación o verificación de la identidad del asociado. QUINTA.-
                        PUBLICIDAD DEL REGLAMENTO.- CAVIPETROL puede en cualquier tiempo, revisar o
                        modificar los términos del presente Reglamento y avisará oportunamente al
                        asociado de los cambios o ajustes que se hagan al mismo. El texto de este
                        Reglamento y sus actualizaciones, para efectos de publicidad y divulgación,
                        se encuentra publicado en todas las oficinas de CAVIPETROL a nivel nacional.
                        SEXTA.- SERVICIOS Y PRODUCTOS.- Los siguientes son los servicios y productos
                        que CAVIPETROL prestará a sus asociadoS en y desde su sitio web: a)
                        Contenidos; b) Consultas: saldos, movimientos, extractos, puntajes,
                        hipotecas etc; c) Reservas de propiedades de CAVIPETROL; d) Sugerencias; e)
                        Actualizacion de datos; f) Simuladores y otros servicios y productos que se
                        establezcan por el desarrollo normal del negocio. SÉPTIMA.- CONTENIDOS.- La
                        información o contenidos del sitio web de CAVIPETROL, vinculados con el
                        presente Reglamento, serán definidos en cualquier tiempo de forma
                        unilateral, autónoma y discrecional por CAVIPETROL y en ningún caso este
                        acuerdo supone restricción o limitación de cualquier tipo a esta facultad de
                        CAVIPETROL. PARÁGRAFO.- CAVIPETROL en ningún caso y bajo ninguna
                        circunstancia, será responsable por cualquier daño o perjuicio, directo o
                        indirecto, real o potencial y generado a cualquier persona, por el alcance,
                        la veracidad o exactitud o de los contenidos o de cualquier información, que
                        este incorporada en cualquier momento, en el sitio web de CAVIPETROL o en
                        los sitios web vinculados al sitio. OCTAVA.- CONSULTAS.- EL asociado a
                        través del sitio web de CAVIPETROL, podrá consultar todos los productos que
                        esté consumiendo en CAVIPETROL NOVENA.- VALIDEZ DE LOS MENSAJES DE DATOS.-
                        Para efectos de este Reglamento, la validez de los mensajes de datos y
                        comunicaciones se sujeta a las siguientes reglas: a) Las partes acuerdan que
                        los mensajes de datos que se utilicen para soportar este acuerdo y las
                        operaciones realizadas por EL asociado, se entenderán como documentos
                        originales, escritos y firmados, conforme a lo dispuesto en la Ley 527 de
                        1999 (Por medio de la cual se define y reglamenta el acceso y uso de los
                        mensajes de datos, del comercio electrónico y de las firmas digitales, y se
                        establecen las entidades de certificación y se dictan otras disposiciones) y
                        tendrán el alcance probatorio establecido en la misma; b) El uso que realice
                        EL asociado, de su Número de Usuario y Clave Internet, en cualquier
                        transacción o comunicación, realizada a través de internet en desarrollo de
                        los servicios de banca virtual bajo este Reglamento, se entenderá como un
                        método confiable de firma y aceptación de su contenido, en los términos del
                        artículo 7 de la Ley 527 de 1999, constituyendo plena prueba de las mismas;
                        DÉCIMA .- CONDICIONES PARA LA PRESTACIÓN DE LOS SERVICIOS.- Las siguientes
                        son las condiciones mínimas que debe observar EL asociado, para utilizar los
                        servicios de CAVIPETROL, en internet: a) El asociado autoriza expresamente a
                        CAVIPETROL, por este Reglamento para realizar el tratamiento automatizado y
                        utilización de los datos personales y generales que suministre para el
                        acceso a los servicios establecidos en el sitio web de CAVIPETROL; b ) EL
                        asociado deberá a través de los formularios de registro en el sitio web,
                        suministrar a CAVIPETROL, información veraz, actual y completa y a informar
                        oportunamente a CAVIPETROL, sobre cualquier cambio en la misma, a través del
                        procedimiento en línea establecido por CAVIPETROL; c) EL asociado cada vez
                        que pretenda realizar una operación a través del sitio web CAVIPETROL, se
                        registrará de forma previa, conforme a los mecanismos de seguridad,
                        identificación y acceso a los servicios en internet, que CAVIPETROL ha
                        establecido o que establezca en cualquier tiempo; d) EL asociado se obliga,
                        en la oportunidad que utilice los servicios y productos objeto de este
                        contrato, a registrar previamente, su Documento de Identidad y Clave
                        Internet en las condiciones establecidas en este Reglamento y en los
                        términos de uso del sitio web de CAVIPETROL; e) EL asociado y ninguna
                        persona esta autorizada, para utilizar, copiar, reproducir, distribuir,
                        modificar o realizar cualquier actividad con respecto a los signos
                        distintivos de propiedad de CAVIPETROL o cualquier derecho de propiedad
                        intelectual incorporado en su sitio web - incluidos y sin limitación
                        derechos con respecto a bases de datos, gráficas, imágenes, videos, sonidos,
                        programas de computador, marcas, nombres comerciales, enseñas, nombres de
                        dominio, lemas, logo-simbolos, patentes, modelos de utilidad, diseños
                        industriales, etc. – o para realizar vínculos de hipertexto o cualquier otra
                        modalidad de enlace (links) al sitio web de CAVIPETROL, a menos que EL
                        asociado haya sido previamente autorizado por escrito por CAVIPETROL. DÉCIMA
                        PRIMERA.- MECANISMOS DE SEGURIDAD Y ACCESO.- Las siguientes serán las reglas
                        que observará EL asociado, para el uso y administración de los datos que
                        conformen su Documento de Identidad y Clave Internet y con respecto a
                        cualquier otro método de acceso que establezca CAVIPETROL a los servicios en
                        internet: a) Cada asociado de CAVIPETROL, para su acceso a los servicios,
                        tendrá unos datos únicos sobre Documento de Identidad y Clave Internet,
                        conforme al método y procedimientos de generación de los mismos, establecido
                        por CAVIPETROL; b) El asociado, asignará en forma confidencial su Clave
                        Internet conforme a las instrucciones indicadas en el sitio web, a este
                        Reglamento y a las disposiciones que defina CAVIPETROL para el proceso de
                        verificación; c) EL asociado utilizará los datos sobre Documento de
                        Identidad y Clave Internet, exclusivamente para el servicio de consulta d)
                        El asociado, garantizará que para efectos de este contrato, el Documento de
                        Identidad y Clave Internet que utiliza como mecanismo para acceder a los
                        servicios en Internet, son códigos únicos, secretos, confidenciales e
                        intransferibles a los cuales CAVIPETROL no tiene acceso y, por tanto, EL
                        asociado asume las consecuencias de su divulgación a terceros, liberando a
                        CAVIPETROL de toda responsabilidad, directa o indirecta, contractual o
                        extracontractual y en particular la que se derive de cualquier infracción a
                        cualquier norma constitucional o legal que se encuentre vigente y que
                        resulte aplicable; e) EL asociado declara que toda comunicación, consulta,
                        operación o transacción efectuada por medio del sitio web de CAVIPETROL, que
                        efectúe cualquier persona utilizando o digitando su Documento de Identidad y
                        Clave de Internet, o por cualquier otro mecanismo de seguridad,
                        identificación o acceso, se entenderá efectuada por EL asociado y en
                        consecuencia lo obligará plenamente. En tal circunstancia, CAVIPETROL podrá
                        considerar que tal comunicación u operación ha sido realizada válida,
                        legítima y de forma auténtica por EL asociado, sin necesidad de efectuar o
                        tomar otra medida de verificación y se hace responsable de cualquier
                        perjuicio que se cause a CAVIPETROL;f) EL asociado en caso de perdida o
                        amenaza a la confidencialidad de Documento de Identidad y/o Clave Internet,
                        deberá bloquear el acceso a los servicios de CAVIPETROL, informando a
                        Servicio al cliente en la línea 01 8000 912712 a nivel nacional; g) EL
                        asociado no compartirá su Documento de Identidad y/o Clave Internet con
                        ninguna persona, no los remitirá por correo electrónico u otro medio,
                        cerrará la sesión de consulta u operaciones cuando utilice los servicios y
                        productos en internet, utilizará el software más reciente para navegar en el
                        sitio web de CAVIPETROL y siempre usará una red segura y terminales de
                        computador mantenidas por personal de confianza; h) CAVIPETROL en ningún
                        caso y bajo ninguna circunstancia, almacena la Clave Internet de cada
                        asociado. DÉCIMA SEGUNDA.- MODIFICACIÓN O SUPRESIÓN DE LOS SERVICIOS O
                        PRODUCTOS.- Los servicios o productos de CAVIPETROL establecidos en su sitio
                        web, en cualquier tiempo, podrán ser modificados, suprimidos o adicionados
                        unilateralmente por CAVIPETROL sin necesidad de notificación previa al
                        asociado y sin que ello le implique a CAVIPETROL, responsabilidad alguna
                        frente a EL asociado o a terceros. DÉCIMA TERCERA.- RESPONSABILIDAD DEL
                        asociado.- La responsabilidad del asociado por el cumplimiento de sus
                        obligaciones, bajo este Reglamento, se sujetará a las siguientes reglas: a)
                        EL asociado es responsable ante CAVIPETROL hasta de la culpa leve (inciso 2,
                        articulo 63 código civil colombiano) por el mal uso que haga de los
                        servicios y productos suministrados por CAVIPETROL en Internet; b) Deberá
                        solicitar y utilizar los servicios y productos disponibles a través de
                        Internet, bajo su voluntad y cuidado, observando en todo caso cualquier
                        instrucción o procedimiento técnico u operativo establecido o recomendado
                        por CAVIPETROL; c) Conservará de forma confidencial y con especial cuidado y
                        diligencia, su Documento de Identidad y Clave Internet; d) Usará los
                        servicios y productos en debida forma y según su naturaleza; d) Informará
                        oportunamente y por escrito a CAVIPETROL, de cualquier dificultad que
                        encuentre para el uso de los servicios y productos en internet; e) Se hará
                        responsable de cualquier uso indebido, anormal o abusivo que hiciere de los
                        mismos; f) No atentará de manera alguna, directa o indirectamente, contra el
                        sitio web o sistema de información de CAVIPETROL o interferirá en su normal
                        funcionamiento g) En caso de que el asociado, advierta fraude o anomalías en
                        el uso de los servicios y productos que CAVIPETROL le ofrece a través de
                        internet o que exista exposición o pérdida de control del uso de su
                        Documento de Identidad y/o Clave Internet, deberá bloquear el acceso a sus
                        productos y cuentas, avisando de forma inmediata y efectiva a CAVIPETROL; h)
                        EL asociado será único responsable frente a CAVIPETROL o terceros, de
                        cualquier acción ilícita o hecho punible que realice o pretenda hacer,
                        directa o indirectamente, a través de los servicios establecidos en el sitio
                        web de CAVIPETROL; i) EL asociado deberá mantener excluido a CAVIPETROL, por
                        cualquier perjuicio, que se derive del incumplimiento de sus obligaciones
                        bajo este Reglamento. DÉCIMA CUARTA.- RESPONSABILIDAD DE CAVIPETROL.- La
                        responsabilidad de CAVIPETROL, por el cumplimiento de sus obligaciones, bajo
                        este Reglamento se sujetará, a las siguientes reglas: a) CAVIPETROL no asume
                        ninguna responsabilidad, ni otorga garantía alguna al asociado, en el caso
                        de que EL asociado no pueda efectuar las operaciones por defectos que se
                        puedan presentar en el funcionamiento del servicio, por cualquier atraso,
                        interrupción, errores, fallas técnicas, no disponibilidad o falla en el
                        sitio web y tampoco por cualquier perjuicio directo o indirecto que se
                        derive de tales circunstancias; b) CAVIPETROL no es responsable de los actos
                        o amenazas a los cuales está expuesto EL asociado, durante el manejo de su
                        Documento de Identidad y Clave Internet o la realización de sus operaciones,
                        transacciones o consultas en el sitio web de CAVIPETROL; c) CAVIPETROL no
                        responde de forma alguna por ataques de fuerza bruta contra la seguridad de
                        su sitio web, prácticas de spooting, virus, programas maliciosos, o
                        cualquier exposición no autorizada, fraudulenta o ilícita de su sitio web y
                        que pueda afectar la confidencialidad o integridad de la información en el
                        contenida; d) CAVIPETROL no asume ningún tipo de responsabilidad que pueda
                        generarse por el uso indebido o pérdida de control del Documento de
                        Identidad y Clave Internet, hasta tanto no se produzca el bloqueo de los
                        productos y el aviso previsto en la cláusula anterior; e) Sin perjuicio de
                        cualquier juicio de responsabilidad civil o penal que se adelante contra EL
                        asociado, por el uso indebido o pérdida de control del Documento de
                        Identidad y Clave Internet, la responsabilidad de EL asociado con respecto a
                        este reglamento, cesa a partir de las veinticuatro (24) horas hábiles
                        siguientes a la debida notificación de los hechos a CAVIPETROL. DÉCIMA
                        QUINTA.-COSTO DEL SERVICIO.- CAVIPETROL determinará los costos y las tarifas
                        mensuales de los servicios prestados a través de su sitio web, valores que
                        podrán ser debitados automáticamente de cualquiera de las cuentas que EL
                        asociado tuviere o llegare a tener en CAVIPETROL. CAVIPETROL se reserva el
                        derecho de modificar de manera unilateral y cualquier momento los costos y
                        tarifas aplicables a los servicios, los cuales EL asociado declara aceptar
                        expresamente. DÉCIMA SEXTA. VIGENCIA DEL REGLAMENTO.- Este reglamento rige a
                        partir de su fecha de expedición y su vigencia será de carácter indefinido.
                        DÉCIMA SÉPTIMA.- LUGAR Y TIEMPO DE NOTIFICACIONES.- El lugar y tiempo de las
                        comunicaciones bajo este Reglamento, se sujetarán a las siguientes reglas:
                        a) EL asociado acepta que el lugar de notificación o remisión de información
                        que deba hacerle CAVIPETROL será el señalado por EL asociado, al momento de
                        adquirir los productos con CAVIPETROL; b) La dirección de notificaciones,
                        puede ser la dirección física o la dirección de correo electrónico (e-mail),
                        que EL asociado suministre a CAVIPETROL; c) EL asociado informará por
                        escrito a CAVIPETROL, oportunamente sobre el cambio de su domicilio o
                        dirección física o virtual, asumiendo la total responsabilidad que pueda
                        surgir del hecho de no informar oportunamente a CAVIPETROL de esta
                        circunstancia; d) CAVIPETROL realizará notificaciones o remisión de
                        información al asociado, por cualesquiera medios que considere viable,
                        pertinente y que tenga a su disposición. DÉCIMA OCTAVA.- MODIFICACIÓN DEL
                        REGLAMENTO.- CAVIPETROL puede dejar sin efecto, limitar, modificar, suprimir
                        o adicionar los términos y condiciones de este reglamento, lo mismo que las
                        condiciones del uso de los servicios a través de Internet. Cualquier
                        modificación a este reglamento se publicará en el sitio web de CAVIPETROL y
                        se divulgará conforme a lo dispuesto en la cláusula quinta del presente
                        reglamento. DÉCIMA NOVENA.- DERECHO APLICABLE Y JURISDICCION.- Este
                        Reglamento, los términos de uso y las políticas de privacidad del sitio web
                        de CAVIPETROL, regulan el uso del canal internet por EL asociado y
                        prevalecen sobre cualquier acuerdo previo relativo a la misma materia. EL
                        asociado no podrá alegar o aducir, la aplicación de norma o convenio que no
                        esté expresamente incorporado en este Reglamento, en los términos de uso y
                        en las políticas de privacidad del sitio web de CAVIPETROL. En todo caso
                        este Reglamento, se somete para su aplicación e interpretación a las
                        disposiciones vigentes y aquellas que con posterioridad expidan las leyes
                        colombianas. VIGÉSIMA – PROTECCIÓN DE DATOS PERSONALES. - De conformidad con
                        lo dispuesto por la Ley Estatutaria 1581 del año 2012 de Protección de datos
                        personales, EL asociado presta su consentimiento para que sus datos sean
                        incorporados a una base de datos responsabilidad de CAVIPETROL, siendo
                        tratados con la finalidad de gestionar la relación de afiliación que les une
                        así como para la gestión de los servicios y beneficios vinculados a la
                        afiliación. Los datos serán tratados teniendo en cuenta los criterios de
                        seguridad definidos en la política de tratamiento desarrollada por
                        CAVIPETROL cuya consulta puede llevarse a cabo a través de la web:
                        www.cavipetrol.com Del mismo modo, autoriza la transferencia de sus datos a
                        terceras entidades, cuya comunicación sea necesaria para atender los
                        servicios o beneficios que el asociado desee contratar. Igualmente, el
                        asociado puede ejercer los derechos de consulta y reclamo sobre sus datos
                        mediante escrito dirigido a CAVIPETROL en la siguiente dirección
                        servicio.cliente@cavipetrol.com Cualquier diferencia que surja entre EL
                        asociado y CAVIPETROL será sometida a la ley y la jurisdicción colombiana.
                    </TextP>
                </TermsAndCondContainer>
                <br />
                <ButtonsContainer>
                    <AceptButton
                        type="button"
                        onClick={() => {
                            onAccept()
                        }}
                    >
                        Acepto
                    </AceptButton>

                    <CancelButton
                        type="button"
                        variant="primary"
                        onClick={() => redirection('/login')}
                    >
                        No acepto
                    </CancelButton>
                </ButtonsContainer>
            </TermsContent>
        </Wrapper>
    )
}

export default UserCreationTerms
