import React from 'react'
import {
    ButtonContinue,
    ButtonsContainer,
    CancelButton,
    Content,
    TextData,
    TextInfo,
    TextSteps,
    TextSubtitle,
    Wrapper,
} from '../../AdvanceToFAIAccount-Styled'

interface SecondStepProps {
    onNextStep: (step: number) => void
    onPrevStep: (step: number) => void
    amountAdvance: string
    nameCard: string
    rateOfInterest: string
}

const SecondStep: React.FC<SecondStepProps> = ({
    onNextStep,
    onPrevStep,
    amountAdvance,
    nameCard,
    rateOfInterest,
}) => {
    const value = parseFloat(amountAdvance)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })
    return (
        <Wrapper>
            <Content>
                <TextSteps>Paso 2 de 3</TextSteps>

                <TextInfo>Confirma la información antes de continuar</TextInfo>

                <TextSubtitle>Tarjeta donde se realizará el avance</TextSubtitle>
                <TextData>{nameCard}</TextData>
                <TextSubtitle>Valor del avance</TextSubtitle>
                <TextData>{formatter.format(value)}</TextData>
                <TextSubtitle>Tasa de interés</TextSubtitle>
                <TextData>{rateOfInterest}</TextData>

                <ButtonsContainer>
                    <CancelButton onClick={() => onPrevStep(1)}>Anterior</CancelButton>
                    <ButtonContinue onClick={() => onNextStep(3)}>Continuar</ButtonContinue>
                </ButtonsContainer>
            </Content>
        </Wrapper>
    )
}

export default SecondStep
