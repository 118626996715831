import tw, { styled } from 'twin.macro'
import { Modal, Button } from 'react-bootstrap'

export const ModalForm = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }
        .modal-title {
            ${tw`flex flex-col items-start pt-2.5 pl-8 pb-0`}
        }
        .modal-body {
            ${tw`flex flex-col items-center pt-2.5 pl-8 pb-4`}
        }
    }
`
export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ModalButtonConfirmation = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`
export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}
`
