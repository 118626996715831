import { FC } from 'react'
import { ForgotSVG } from '../../../../utils/getIcons'
// styles
import {
    ButtonsContainer,
    Content,
    ContentInfoRow,
    Image,
    CancelButton,
    TextPBold,
    Wrapper,
} from '../../BalancesExtracts-styles'

interface NoProductProps {
    redirection: (value: string) => void
}

const NoProduct: FC<NoProductProps> = ({ redirection }): JSX.Element => {
    return (
        <Wrapper>
            <Content>
                <ContentInfoRow>
                    <Image src={ForgotSVG} alt="black" />
                    <TextPBold>
                        No hay información disponible de <span>ahorro/crédito o beneficios.</span>
                    </TextPBold>
                </ContentInfoRow>
                <ButtonsContainer>
                    <CancelButton variant="primary" onClick={() => redirection('/home-wallet')}>
                        Regresar al home
                    </CancelButton>
                </ButtonsContainer>
            </Content>
        </Wrapper>
    )
}

export default NoProduct
