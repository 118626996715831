import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import {
    ContainerTab,
    ContainerTitle,
    SectionContent,
    SpaceStep,
    Title,
} from './ManageableContent-styled'
import { BreadcrumbApp } from '../../components'
import TableConfiguration from './inc/TableConfiguration/TableConfiguration'
import TableTemplateCertificates from './inc/TableTemplateCertificates'
import TableMessages from './inc/TableMessages'
import TableTemplatesNotifications from './inc/TableTemplatesNotification'
import TableImages from './inc/TableImages'

const ManageableContent = (): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Administración',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Usuarios',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]
    return (
        <LayoutContent>
            <SpaceStep />
            <SectionContent>
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <ContainerTitle>
                    <Title>Contenido Administrable</Title>
                </ContainerTitle>
                <ContainerTab>
                    <Tabs defaultActiveKey="profile" id="justify-tab-example" className="mb-3">
                        <Tab eventKey="configuration" title="Configuration">
                            <TableConfiguration />
                        </Tab>
                        <Tab eventKey="images" title="Images">
                            <TableImages />
                        </Tab>
                        <Tab eventKey="messages" title="Messages">
                            <TableMessages />
                        </Tab>
                        <Tab eventKey="templatesCertificates" title="Templates certificates">
                            <TableTemplateCertificates />
                        </Tab>
                        <Tab eventKey="TemplatesNotification" title="Templates notification">
                            <TableTemplatesNotifications />
                        </Tab>
                    </Tabs>
                </ContainerTab>
            </SectionContent>
        </LayoutContent>
    )
}

export default ManageableContent
