import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { Cavifacil } from '../../../../utils/getIcons'
import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'

interface PDFGeneratorProps {
    associatedName: string
    dateTransaction: string
    creditNumber: string
    documentNumber: string
    value: string
}

const PDFGenerator: React.FC<PDFGeneratorProps> = ({
    associatedName,
    dateTransaction,
    creditNumber,
    documentNumber,
    value,
}) => {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            padding: 15,
            fontWeight: 'bold',
            position: 'relative',
        },
        backgroundImage: {
            width: 30,
            top: 0,
            left: 0,
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        content: {
            padding: 10,
            border: 0.7,
            borderRadius: 10,
            borderColor: '#F0F0F0',
        },
        header: {
            fontFamily: 'Helvetica-Bold',
            marginBottom: 5,
            marginTop: 20,
            textAlign: 'center',
            fontSize: 15,
            color: '#F5A50B',
        },
        Valediction: {
            textAlign: 'center',
            fontSize: 12,
        },
        row: {
            flexDirection: 'row',
            marginBottom: 10,
            marginTop: -10,
        },
        row1: {
            flexDirection: 'row',
            marginBottom: 10,
            marginTop: 50,
        },
        column: {
            flexDirection: 'column',
            marginRight: 20,
        },
        label: {
            marginRight: 10,
            fontSize: 12,
        },
        value: {
            flexGrow: 1,
            fontWeight: 'bold',
            fontFamily: 'Helvetica-Bold',
            fontSize: 15,
        },
        valueCavi: {
            flexGrow: 1,
            fontWeight: 'bold',
            fontFamily: 'Helvetica-Bold',
            fontSize: 15,
        },
    })

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View style={styles.section} wrap={false}>
                    <View style={styles.content}>
                        <Text style={styles.header}>{'Transacción exitosa'}</Text>
                        <Text style={styles.Valediction}>{'Estado'}</Text>

                        <View style={styles.row1}>
                            <Image src={Cavifacil} style={styles.backgroundImage} />
                            <View style={styles.column}>
                                <Text style={styles.valueCavi}>CaviFácil</Text>
                                <Text style={styles.label}>{creditNumber}</Text>
                            </View>
                            <View style={styles.column}>
                                <Text style={styles.value}>{documentNumber}</Text>
                                <Text style={styles.label}>Cédula</Text>
                            </View>
                            <View style={styles.column}>
                                <Text style={styles.value}>
                                    $ {formatValue(value, 1)}
                                    <sup>{formatDecimalValue(value, 1)}</sup>
                                </Text>
                                <Text style={styles.label}>Valor</Text>
                            </View>
                            <View style={styles.column}>
                                <Text style={styles.value}>{dateTransaction}</Text>
                                <Text style={styles.label}>Fecha y Hora</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PDFGenerator
