import { LoggedUserRoleActionShape, LoggedUserRoleState } from '../../../domain/models/rolUsers'
import { LOGGED_USER_ROLES } from './rolUsers.types'

const initialSendCodeOtpState: LoggedUserRoleState = {
    loggedUserRole: {
        response: '',
        message: '',
        data: '',
    },
    loading: false,
    error: {},
}

export const loggedUserRoleReducer = (
    state = initialSendCodeOtpState,
    action: LoggedUserRoleActionShape
): LoggedUserRoleState => {
    switch (action.type) {
        case LOGGED_USER_ROLES.SUCCESS:
            return {
                ...state,
                loggedUserRole: {
                    response: action.payload.response,
                    message: action.payload.message,
                    data: action.payload.data,
                },
                loading: false,
                error: {},
            }
        case LOGGED_USER_ROLES.REQUEST:
            return { ...state, loading: true, error: {} }
        case LOGGED_USER_ROLES.FAILURE:
            return {
                ...state,
                loading: false,
                loggedUserRole: {
                    response: '',
                    message: '',
                    data: '',
                },
                error: action.payload,
            }
        default:
            return state
    }
}
