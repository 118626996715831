import { AxiosResponse } from 'axios'
import { avanceKeyCard, blockCardService } from '../../../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function usePasswordChange(data: any, tokenSave: string): Promise<Result> {
    try {
        return await avanceKeyCard
            .postPasswordChange(
                '/api/CreditCardTransaction/PasswordChange/PasswordChange',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function usePasswordChangeByForgot(data: any, tokenSave: string): Promise<Result> {
    try {
        return await avanceKeyCard
            .postPasswordChangeByForgot(
                '/api/CreditCardTransaction/PasswordChangeByForgot/PasswordChangeByForgot',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateExpirationDate(
    expirationMonth: string,
    expirationYear: string,
    cardNumber: string,
    tokenSave: string
): Promise<Result> {
    const encodedCardNumber = encodeURIComponent(cardNumber)
    try {
        return await avanceKeyCard
            .getValidateExpirationDate(
                `/api/CreditCardProcess/ValidateExpirationDate/ValidateExpirationDate?expirationMonth=${expirationMonth}&expirationYear=${expirationYear}&cardNumber=${encodedCardNumber}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useProductAvailability(data: any, tokenSave: string): Promise<Result> {
    try {
        return await avanceKeyCard
            .postProductAvailability(
                '/api/CreditCardProcess/ProductAvailabilty/ProductAvailabilty',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateTriviality(sequence: string, tokenSave: string): Promise<Result> {
    try {
        return await avanceKeyCard
            .getValidateTriviality(
                `/api/CreditCardProcess/ValidateTriviality/ValidateTriviality?passwordToValidate=${sequence}`,
                tokenSave
            )
            .then((response: any) => {
                console.log(response)
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function usePasswordAssignment(data: any, tokenSave: string): Promise<Result> {
    try {
        return await avanceKeyCard
            .postPasswordAssignment(
                '/api/CreditCardTransaction/PasswordAssignment/PasswordAssignment',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useSendCodeOtpTCForgot(
    lastFourDigits: string,
    tokenSave: string
): Promise<Result> {
    try {
        return await avanceKeyCard
            .postSendCodeOtpTCForgot(
                `/api/CreditCardTransaction/SendCodeOtpTCForgot/SendCodeOtpTCForgot?CardNumber=${lastFourDigits}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateOtpTCForgot(tokenSave: string, body: any): Promise<Result> {
    try {
        return await blockCardService
            .validateOTP(
                '/api/CreditCardTransaction/ValidateCodeOtpCreditCard/ValidateCodeOtpCreditCard?TypeCode=5',
                tokenSave,
                body
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
