import { FC } from 'react'
// styles
import {
    PpalTitle,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
} from '../../BalancesExtracts-styles'

type BenefitDataType = {
    Documento: string
    Beneficio: string
    Saldo: number
}

interface BenefitProps {
    benefitData: BenefitDataType[]
    formatter: (value: number) => string
}

const BenefitsTable: FC<BenefitProps> = ({ benefitData, formatter }): JSX.Element => {
    return (
        <TableContent>
            <PpalTitle>Beneficios</PpalTitle>
            <TableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Cédula</th>
                        <th>Beneficio</th>
                        <th>Valor</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    {benefitData.map((benefit, index) => (
                        <RowTable key={index}>
                            <td>{benefit.Documento}</td>
                            <td>{benefit.Beneficio}</td>
                            <td>{formatter(benefit.Saldo)}</td>
                        </RowTable>
                    ))}
                </TBody>
            </TableContainer>
        </TableContent>
    )
}

export default BenefitsTable
