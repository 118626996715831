import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { Container, ContainerTitle, LoadingContainer, Title } from './AdvanceToFAIAccount-Styled'
import { BreadcrumbApp, Loading, ModalGeneric } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { faiAccountExistSelector } from '../../../selectors'
import { getFaiAccountBalanceAction } from '../../../redux/products'
import FirstStep from './inc/FirstStep'
import SecondStep from './inc/SecondStep'
import { NotProductSVG } from '../../utils/getIcons'
import TransactionConfirmation from './inc/TransactionConfirmation/TransactionConfirmation'
import { useUserInfo } from '../CreditDetail/hooks/useCreditDetail'
import ThirdStep from './inc/ThirdStep'
import NoFAIAccountAlert from './inc/NoFAIAccountAlert'

interface TransactionData {
    selectedCard: string
    valorAvance: string
    month: string
    year: string
    quota: string
    cvv: string
}

const AdvanceToFAIAccount: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [step, setStep] = useState<number>(1)
    const { isAccountFai, faiAccount, loading, error } = useSelector(faiAccountExistSelector)

    const [isGoBack, setIsGoBack] = useState<boolean>(false)
    const [isTcSelect, setIsTcSelect] = useState<boolean>(false)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')

    const [amountAdvance, setAmountAdvance] = useState<string>('')
    const [cardNumber, setCardNumber] = useState<string>('')
    const [cardName, setCardName] = useState<string>('')
    const [rateOfInterest, setRateOfInterest] = useState<string>('')
    const [lastsFourNumCard, setLastsFourNumCard] = useState<string>('')

    const [expiredDateCard, setExpiredDateCard] = useState<string>('')
    const [ccvValue, setCvvValue] = useState<string>('')
    const [numberFee, setNumberFee] = useState<string>('')
    const [isBalanceArrears, setIsBalanceArrears] = useState<boolean>(false)

    const [savedTransaction, setSavedTransaction] = useState<TransactionData | null>(null)

    useEffect(() => {
        if (!savedTransaction) {
            dispatch(getFaiAccountBalanceAction())
        }
    }, [dispatch])

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Tarjeta de crédito',
            active: false,
        },
        {
            text: 'Realizar avance a tu cuenta FAI',
            active: true,
        },
    ]

    const handleNextStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handlePrevStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    const handleGoBack = (value: boolean): void => {
        setIsTcSelect(value)
        if (value) {
            setIsGoBack(false)
        }
    }

    const saveDataAdvanceFAI = (
        amountAdvance: string,
        cardNumber: string,
        nameCard: string,
        rateInterest: string,
        lastsFourNum: string,
        expiredDateCard: string,
        ccvValue: string,
        numberFee: string,
        transactionData: TransactionData
    ): void => {
        setRateOfInterest(rateInterest)
        setLastsFourNumCard(lastsFourNum)
        setCardNumber(cardNumber)
        setCardName(nameCard)
        setExpiredDateCard(expiredDateCard)
        setCvvValue(ccvValue)
        setNumberFee(numberFee)
        setAmountAdvance(amountAdvance)
        setSavedTransaction(transactionData)
    }

    const GetUserInfo = (): void => {
        useUserInfo(tokenSave)
            .then((res: any) => {
                if (res['Response'] === '200') {
                    setCellphone(res['Data']['CellPhone'])
                    setEmail(res['Data']['Email'])
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (!isBalanceArrears) {
            GetUserInfo()
        }
    }, [tokenSave])

    const handleIsBalanceArrears = (value: boolean): void => {
        setIsBalanceArrears(value)
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (isTcSelect && step === 1) {
                            setIsGoBack(true)
                        } else if (step > 1 && step <= 3) {
                            setStep(step - 1)
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />
                <ContainerTitle>
                    <Title>
                        Avance <span>a tu cuenta FAI</span>
                    </Title>
                </ContainerTitle>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {isAccountFai ? (
                            <>
                                {step === 1 && (
                                    <FirstStep
                                        activeFAI={faiAccount}
                                        onNextStep={handleNextStepChange}
                                        handleIsBalanceArrears={handleIsBalanceArrears}
                                        isAccountFai={isAccountFai}
                                        isGoBack={isGoBack}
                                        handleGoBack={handleGoBack}
                                        saveDataAdvanceFAI={saveDataAdvanceFAI}
                                        dataTransaction={savedTransaction}
                                    ></FirstStep>
                                )}
                                {step === 2 && (
                                    <SecondStep
                                        onPrevStep={handlePrevStepChange}
                                        onNextStep={handleNextStepChange}
                                        amountAdvance={amountAdvance}
                                        nameCard={cardName}
                                        rateOfInterest={rateOfInterest}
                                    ></SecondStep>
                                )}
                                {step === 3 && (
                                    <ThirdStep
                                        cellphone={cellphone}
                                        email={email}
                                        onNextStep={handleNextStepChange}
                                        cardNumber={cardNumber}
                                        expiredDateCard={expiredDateCard}
                                        cvvValue={ccvValue}
                                        numberFee={numberFee}
                                        transactionValue={amountAdvance}
                                    />
                                )}

                                {step === 4 && (
                                    <TransactionConfirmation
                                        amountAdvance={amountAdvance}
                                        lastsFourNumCard={lastsFourNumCard}
                                        faiAccountNumber={faiAccount.productDocumentNumber}
                                    />
                                )}
                            </>
                        ) : (
                            <NoFAIAccountAlert />
                        )}
                    </>
                )}

                {Object.keys(error).length > 0 && (
                    <ModalGeneric
                        show={true}
                        img={NotProductSVG}
                        textTitle={'¡Ha ocurrido un error!'}
                        textBody={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleButton={handleClose}
                        handleClose={handleClose}
                        textButton="Aceptar"
                    />
                )}
            </Container>
        </LayoutContent>
    )
}

export default AdvanceToFAIAccount
