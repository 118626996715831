import { ReactElement, useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormState } from 'react-hook-form'

// components
import ControlsButtons from '../ControlsButtons'
import HeadStep from '../HeadStep'
import NavigationStep from '../NavigationStep'
import { Button, Form, LoadingScreen, Range } from '../../../../components'

// styles
import {
    SixthStepWrapper,
    SixthStepContent,
    Amount,
    Sub,
    WrapperTop,
    WrapperTopLeftImage,
    Image,
    WrapperTopRightSlide,
    TextCtr,
    CtrValues,
    RangeCtr,
    TooltipAmount,
    ButtonsCtr,
    ButtonsLeftCtr,
    ButtonsRightCtr,
} from './sixthStep-styles'
import { TitleStep } from '../../campaignCard-styles'

// hooks
import { sixthStepSchema, SixthStepType, useSixthStepForm } from '../../hooks/useSixthStepForm'
import { useReducerStep } from '../../hooks'

// utils
import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'

// selectors
import {
    getCreditCardDataPerStepSelector,
    validateUserStateSelector,
} from '../../../../../selectors'
import {
    CreditCardGoldenPNG,
    CreditCardPlatinumPNG,
    CreditCardPNG,
} from '../../../../utils/getIcons'
import { getCampaignClientProduct } from '../../../../../redux/products/products.actions'
import { Col, Row } from 'react-bootstrap'
import { defaultTcCampaignParameters } from '../../../../../redux/openingTC'
import { useHistory } from 'react-router-dom'

const SixthStep = (): ReactElement => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [{ feedback }] = useReducerStep()
    const [cardType, setCardType] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [sliderStep, setSliderStep] = useState<number>()
    const [next, setNext] = useState<boolean>(false)
    const [ranges, setRanges] = useState({
        min: 1,
        max: 2000000,
    })

    const { validateUser } = useSelector(validateUserStateSelector)
    const { createCreditCard } = useSelector(getCreditCardDataPerStepSelector)
    const { sixthStep } = createCreditCard

    const setInitialValue = (): number => {
        if (sixthStep.cardQuote) {
            return sixthStep.cardQuote < ranges.min ? ranges.min - 1 : sixthStep.cardQuote
        }
        if (validateUser?.minLimitCredit) {
            return validateUser.minLimitCredit - 1
        }

        return ranges.min - 1
    }

    const defaultValuesRef = useRef({
        cardQuote: setInitialValue(),
    })

    const {
        handleSubmit,
        register,
        watch,
        control,
        getValues,
        setValue,
        initPercentage,
        preparedToSend,
        preparedToSave,
        isLoading,
        isLoadingContinue,
    } = useSixthStepForm({
        validationSchema: yupResolver(sixthStepSchema),
        defaultValues: defaultValuesRef.current,
        rangeMax: ranges.max,
        rangeMin: ranges.min,
    })

    const { isValid } = useFormState({ control })
    watch()

    // listeners
    useEffect(() => {
        if (validateUser) {
            return onUpdateRanges(
                feedback.minRange ?? validateUser?.minLimitCredit,
                feedback.maxRange ?? validateUser?.maxLimitCredit
            )
        } else if (feedback.minRange && feedback.maxRange) {
            return onUpdateRanges(feedback.minRange, feedback.maxRange)
        }
    }, [validateUser])

    useEffect(() => {
        setValue('cardQuote', setInitialValue(), { shouldValidate: true })
    }, [ranges.min])

    useEffect(() => {
        setLoading(true)
        dispatch(
            defaultTcCampaignParameters((res) => {
                setSliderStep(res.data.defaultSliderStep)
            })
        )
        dispatch(
            getCampaignClientProduct((res) => {
                if (res.response === '200') {
                    setCardType(res.data.cardType)
                    setRanges({ min: 1000001, max: res.data.quota })
                    setLoading(false)
                }
            })
        )
    }, [])

    // handlers
    const onSubmit = ({ cardQuote }: SixthStepType): void => {
        preparedToSend({ cardQuote })
    }

    const onSave = (): void => {
        preparedToSave()
    }

    const onUpdateRanges = (min: number, max: number): void => {
        if (min && max) {
            setRanges((prevState) => ({
                ...prevState,
                min,
                max,
            }))
        }
    }

    const continuar = (): void => {
        setNext(true)
    }

    return (
        <>
            <NavigationStep saveAndExit onSave={onSave} />
            <SixthStepWrapper>
                <SixthStepContent>
                    <HeadStep
                        title="Solicitud"
                        paragraph="Felicidades, esta será tu proxima tarjeta, elige el cupo que deseas tener en tu tarjeta"
                    />

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <WrapperTop>
                            <WrapperTopLeftImage>
                                {loading ? (
                                    <>
                                        <LoadingScreen></LoadingScreen>
                                    </>
                                ) : (
                                    <Image
                                        className="imageStateRequest"
                                        width={25}
                                        src={obtenerImagenEstado(cardType)}
                                        alt={cardType}
                                    />
                                )}
                            </WrapperTopLeftImage>
                            {!next && (
                                <WrapperTopRightSlide>
                                    <TextCtr>
                                        <TitleStep>Tu cupo será de:</TitleStep>
                                        <Amount txtAlign="start">
                                            ${formatValue(getValues('cardQuote'), 1)}
                                            <Sub>
                                                {formatDecimalValue(getValues('cardQuote'), 1)}
                                            </Sub>
                                        </Amount>
                                    </TextCtr>
                                    {loading ? (
                                        <>
                                            <LoadingScreen></LoadingScreen>
                                        </>
                                    ) : (
                                        <RangeCtr>
                                            <CtrValues>
                                                <Amount txtAlign="start">
                                                    ${formatValue(ranges.min - 1, 1)}
                                                    <Sub>{formatDecimalValue(ranges.min, 1)}</Sub>
                                                </Amount>
                                                <Amount txtAlign="start">
                                                    ${formatValue(ranges.max, 1)}
                                                    <Sub>{formatDecimalValue(ranges.max, 1)}</Sub>
                                                </Amount>
                                            </CtrValues>
                                            <Range
                                                $bgSize={initPercentage}
                                                step={sliderStep}
                                                value={getValues('cardQuote')}
                                                min={ranges.min - 1}
                                                max={ranges.max}
                                                {...register('cardQuote')}
                                            />
                                            <TooltipAmount left={initPercentage}>
                                                <Amount txtAlign="start">
                                                    ${formatValue(getValues('cardQuote'), 1)}
                                                    <Sub>
                                                        {formatDecimalValue(
                                                            getValues('cardQuote'),
                                                            1
                                                        )}
                                                    </Sub>
                                                </Amount>
                                            </TooltipAmount>
                                        </RangeCtr>
                                    )}
                                </WrapperTopRightSlide>
                            )}
                            {next && (
                                <WrapperTopRightSlide>
                                    <Row className="rowname">
                                        <Col md={6}>
                                            <div className="title">Valor aprobado</div>
                                            <div className="text">
                                                ${formatValue(getValues('cardQuote'), 1)}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="title">Valor disponible </div>
                                            <div className="text">
                                                ${formatValue(getValues('cardQuote'), 1)}{' '}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rowname">
                                        <Col md={6}>
                                            <div className="title">Tipo de tarjeta</div>
                                            <div className="text">{cardType}</div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="title">Tasa de interés </div>
                                            <div className="text">1.89% </div>
                                        </Col>
                                    </Row>
                                </WrapperTopRightSlide>
                            )}
                        </WrapperTop>
                        {!next && (
                            <ButtonsCtr>
                                <ButtonsLeftCtr>
                                    <Button
                                        className="cancelar"
                                        extend
                                        disabled={false}
                                        onClick={() => {
                                            history.push('/product-opening')
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </ButtonsLeftCtr>
                                <ButtonsRightCtr>
                                    {
                                        <Button
                                            className="save"
                                            variant="outline-cancel"
                                            type="button"
                                            extend
                                            disabled={false}
                                            onClick={onSave}
                                        >
                                            Guardar y salir
                                        </Button>
                                    }
                                    <Button
                                        variant="sub-dominant"
                                        type="submit"
                                        extend
                                        isLoading={isLoading}
                                        onClick={continuar}
                                    >
                                        Continuar
                                    </Button>
                                </ButtonsRightCtr>
                            </ButtonsCtr>
                        )}
                        {next && (
                            <ControlsButtons
                                disable={!isValid || isLoadingContinue}
                                isLoadingSave={isLoading}
                                onSave={onSave}
                                isLoading={isLoadingContinue}
                            />
                        )}
                    </Form>
                </SixthStepContent>
            </SixthStepWrapper>
        </>
    )
}

function obtenerImagenEstado(estado?: string): string {
    console.log('estado', estado)
    switch (estado) {
        case 'GOLD CAVIPETROL':
            return CreditCardGoldenPNG
        case 'CLASSIC CAVIPETROL':
            return CreditCardPNG
        case 'PLATINUM CAVIPETROL':
            return CreditCardPlatinumPNG
        default:
            return ''
    }
}

export default SixthStep
