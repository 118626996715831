import { tasas2 } from '../../../../utils/getImages'
import { ImgContent } from './ImageImageCupsTermsQuotas-styles'

const ImageConsult = (): JSX.Element => {
    return (
        <>
            <ImgContent>
                <img src={tasas2} className="logo-table" alt="tasas2" />
            </ImgContent>
        </>
    )
}

export default ImageConsult
