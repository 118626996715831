import { Modal } from 'react-bootstrap'
// icons
import { CheckOperationSVG } from '../../../../../../utils/getIcons'
// styles
import { InfoModal, ModalTitle, ModalText, ButtonsContainer, ModalButton } from './ModalInfo-Styled'
import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    textTitle?: string
    textBody?: string
}

const ModalInfo: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    textTitle,
    textBody,
}): JSX.Element => {
    const history = useHistory()
    const handleClick = () => {
        history.push('/home-wallet')
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CheckOperationSVG} alt="" className="CheckOperationSVG" />
                {textTitle && <ModalTitle>{textTitle}</ModalTitle>}
                {textBody && <ModalText>{textBody}</ModalText>}
                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Finalizar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalInfo
