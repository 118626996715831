import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { CertifiedWatermark } from '../../../../utils/getImages'
interface PDFGeneratorProps {
    Header: string
    Body: string
    Footer: string
    Valediction: string
    CardNumber: string
    DateExpiredCard: string
    TotalLimit: string
    QuotaAvailable: string
    pageWidth: number
    pageHeight: number
}

const PDFGenerator: React.FC<PDFGeneratorProps> = ({
    Header,
    Body,
    Footer,
    Valediction,
    CardNumber,
    DateExpiredCard,
    TotalLimit,
    QuotaAvailable,
    pageWidth,
    pageHeight,
}) => {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            padding: 30,
            fontWeight: 'bold',
            position: 'relative',
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 600, // Usar el ancho de página proporcionado
            height: 840, // Usar la altura de página proporcionada
            zIndex: -1, // Coloca la imagen de fondo detrás de otros elementos
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        header: {
            fontFamily: 'Helvetica-Bold',
            marginBottom: 20,
            textAlign: 'center',
            marginTop: 60,
        },
        body: {
            marginTop: 60,
            marginBottom: 20,
            textAlign: 'justify',
        },
        footer: {
            marginTop: 40,
            marginBottom: 5,
            textAlign: 'justify',
        },
        table: {
            width: '100%',
            borderStyle: 'solid',
            marginLeft: 100,
        },
        tableRow: {
            width: '70%',
            flexDirection: 'row',
        },
        tableCol: {
            width: '50%',
            borderStyle: 'solid',
            borderWidth: 1,
            padding: 5,
            paddingLeft: 15,
        },
        tableCell: {
            fontSize: 10,
        },
        Valediction: {
            marginTop: 20,
            fontWeight: 'bold',
            textDecorationColor: 'black',
            textAlign: 'justify',
            fontFamily: 'Helvetica-Bold',
        },

        certifica: {
            fontSize: 28,
            fontFamily: 'Helvetica-Bold',
            marginBottom: 40,
            textAlign: 'center',
        },
    })

    const tableData = [
        { column1: 'Tarjeta N°', column2: ` XXXX XXXX XXXX ${CardNumber}` },
        { column1: 'Vencimiento', column2: DateExpiredCard },
        { column1: 'Cupo', column2: ` $ ${TotalLimit}` },
        { column1: 'Saldo Actual', column2: ` $ ${QuotaAvailable}` },
    ]

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <Image src={CertifiedWatermark} style={styles.backgroundImage} fixed />
                <View style={styles.section} wrap={false}>
                    <Text style={styles.header}>{Header}</Text>
                    <View style={styles.body} wrap={false}>
                        <Text style={styles.certifica}>CERTIFICA</Text>
                        <Text>{Body.replace('CERTIFICA\n', '')}</Text>
                    </View>
                    <View style={styles.table} wrap={false}>
                        {tableData.map((row, index) => (
                            <View key={index} style={styles.tableRow} wrap={false}>
                                <View style={styles.tableCol} wrap={false}>
                                    <Text style={styles.tableCell}>{row.column1}</Text>
                                </View>
                                <View style={styles.tableCol} wrap={false}>
                                    <Text style={styles.tableCell}>{row.column2}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <Text style={styles.footer}>{Footer}</Text>
                    <Text style={styles.footer}>Atentamente</Text>
                    <Text style={styles.Valediction}>{Valediction}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default PDFGenerator
