import { FC, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {
    ArrowYellow,
    ButtonsContainer,
    LockConfirmationContent,
    LockConfirmationWrapper,
    LockInfo,
    LockSubTitle,
    LockText,
    LockTextInfo,
    LockTitle,
    ModalButton,
} from './BlockCardTheft-Styled'
import { useHistory } from 'react-router-dom'
import {
    CheckOperationSVG,
    ArrowYellowSVG,
    TicketMoneySVG,
    PickerMapSVG,
    TimeSVG,
    MiniPhoneSVG,
} from '../../../../utils/getIcons'
import ConfirmationModal from './ConfirmationModal'

interface LockConfirmationProps {
    lastsFourNumCard?: string
    residenceAddress: string
    officeName: string
    officeAddress: string
    officePhone: string
    officeDay: string
    officeHour: string
    valorTcVariablePortal: string
}

const LockConfirmation: FC<LockConfirmationProps> = ({
    lastsFourNumCard,
    residenceAddress,
    officeName,
    officeAddress,
    officePhone,
    officeDay,
    officeHour,
    valorTcVariablePortal,
}): JSX.Element => {
    const [checkValue, setCheckValue] = useState('1')
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        setShowModal(false)
    }

    const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target
        setCheckValue(value)
    }

    const history = useHistory()
    const redirections = (url: string): void => {
        history.push(url)
    }

    const handleOnClick = () => {
        setShowModal(true)
    }

    return (
        <LockConfirmationWrapper>
            <LockConfirmationContent>
                <img src={CheckOperationSVG} alt="" className="CheckOperationSVG" />
                <br></br>
                <LockTextInfo>
                    Tu tarjeta de crédito *{lastsFourNumCard} ha sido bloqueada por reporte de
                    pérdida o hurto. Por favor confírmanos{' '}
                    <strong>¿Dónde deseas reclamar tu nueva tarjeta de crédito?</strong>
                </LockTextInfo>
                <br></br>
                <Form.Group className="mb-3 address-field" style={{ marginLeft: '-340px' }}>
                    <LockTitle>
                        {checkValue === '1' ? 'Lugar de entrega' : 'Dirección de entrega'}
                    </LockTitle>

                    <Form.Check
                        label="Oficina Cavipetrol"
                        type="radio"
                        value="1"
                        checked={checkValue === '1'}
                        onChange={handleOnChange}
                    />
                    <br />
                    <Form.Check
                        inline
                        label="Dirección de residencia"
                        type="radio"
                        value="2"
                        checked={checkValue === '2'}
                        onChange={handleOnChange}
                    />
                </Form.Group>
                {checkValue === '1' ? (
                    <div>
                        {officeName == null ? (
                            <LockInfo>
                                No tienes oficinas asignadas, por favor contactar a servicio al
                                asociado
                            </LockInfo>
                        ) : (
                            <>
                                <Row>
                                    <Col md={1}>
                                        <img src={PickerMapSVG} alt="" />
                                    </Col>
                                    <Col md={5}>
                                        <LockInfo>{officeAddress}</LockInfo>
                                    </Col>
                                    <Col md={1}>
                                        <img src={TimeSVG} alt="" />
                                    </Col>
                                    <Col md={5}>
                                        <LockInfo>{officeDay}</LockInfo>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={1}>
                                        <img src={MiniPhoneSVG} alt="" />
                                    </Col>
                                    <Col md={5}>
                                        <LockInfo>{officePhone}</LockInfo>
                                    </Col>
                                    <Col md={1}>
                                        <img src={TimeSVG} alt="" />
                                    </Col>
                                    <Col md={5}>
                                        <LockInfo>{officeHour}</LockInfo>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <br></br>
                        <div>
                            <Row>
                                <Col md={2}>
                                    <img src={TicketMoneySVG} alt="" className="" />
                                </Col>
                                <Col md={10}>
                                    <LockSubTitle>
                                        El costo de reexpedición del nuevo plástico y la entrega
                                        tendrán un costo de {valorTcVariablePortal}
                                    </LockSubTitle>
                                </Col>
                            </Row>
                        </div>

                        <LockText>
                            Si tienes alguna duda por favor comunícate con servicio al asociado
                        </LockText>
                        <ArrowYellow
                            onClick={() => redirections('/support-private')}
                            data-tour="support"
                        >
                            <img
                                src={ArrowYellowSVG}
                                alt="Servicio al asociado"
                                className="options-img"
                            />
                            <span>Servicio al asociado</span>
                        </ArrowYellow>
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col md={1}>
                                <img src={PickerMapSVG} alt="Ícono de localización" />
                            </Col>
                            <Col md={11}>
                                <LockInfo>{residenceAddress}</LockInfo>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <img src={TicketMoneySVG} alt="Ícono de costo" />
                            </Col>
                            <Col md={10}>
                                <LockSubTitle>
                                    El costo de reexpedición del nuevo plástico y la entrega tendrán
                                    un costo de {valorTcVariablePortal}.
                                </LockSubTitle>
                            </Col>
                        </Row>
                        <LockText>
                            Si tienes alguna duda, por favor comunícate con servicio al asociado.
                        </LockText>
                        <ArrowYellow
                            onClick={() => redirections('/support-private')}
                            data-tour="support"
                        >
                            <img
                                src={ArrowYellowSVG}
                                alt="Servicio al asociado"
                                className="options-img"
                            />
                            <span>Servicio al asociado</span>
                        </ArrowYellow>
                    </div>
                )}
                <ButtonsContainer>
                    <ModalButton variant="primary" onClick={handleOnClick}>
                        Finalizar
                    </ModalButton>
                </ButtonsContainer>
            </LockConfirmationContent>
            <ConfirmationModal showModal={showModal} handleClose={handleClose} />
        </LockConfirmationWrapper>
    )
}

export default LockConfirmation
