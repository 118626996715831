/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Bootstrap
import { Accordion, Button, Offcanvas } from 'react-bootstrap'

import { logout } from '../../../redux/actions/loginActions'
import { authenticationService } from '../../../../domain/services/User.service'

// Icons
import {
    HeadsetColorSVG,
    LogOutSVG,
    PaymentSideSVG,
    PaymentTransactionSideSVG,
    ProductSideSVG,
    ServiceSideBarBell,
    AdminPanel2Icon,
    SupervisorSideBar,
} from '../../utils/getIcons'

// Styles
import './header.scss'

import eventBus from '../../utils/eventBus';

//hooks
import { useAppTour } from '../../hooks'
import { decryptKey, parseStringToBoolean } from '../../utils/misc'
import { useFetchRegulations } from '../../pages/RegulationsContainer/hooks/GetListRegulation'
import { CreditCardsSelector, featureEnableFunctionsSelector } from '../../../selectors'
import { useFetchListPolicies } from '../../pages/Policies/hooks/GetListPolicies'
import { Loading } from '../../components'

const enableCreditCard = process.env.REACT_APP_CREDIT_CARD_ENABLE as 'false' | 'true'
const enableTDC = process.env.REACT_APP_TDC_ENABLE as 'false' | 'true'
const enableOpeningCreditCard = process.env.REACT_APP_OPENING_CREDIT_CARD_ENABLE as 'false' | 'true'

/* Manejo para apagado de funcionalidades */
// consulta estado de la funcionalidad en el archivo .env
const enableM2GetRealStates = process.env.REACT_APP_M2_GET_REAL_STATES_ENABLE as 'false' | 'true'
const enableM2PublishRealState = process.env.REACT_APP_M2_PUBLISH_REAL_STATE_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionScoresAndQuotas = process.env.REACT_APP_SCORES_AND_QUOTAS_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionCodebtor = process.env.REACT_APP_ON_OFF_FUNCTION_CODEBTOR as
    | 'false'
    | 'true'
const enableOnOffFunctionMortgages = process.env.REACT_APP_ON_OFF_FUNCTION_MORTGAGES as
    | 'false'
    | 'true'
const enableOnOffFunctionPledge = process.env.REACT_APP_ON_OFF_FUNCTION_PLEDGE as 'false' | 'true'

const enableOnOffFunctionPaymentRegistration = process.env.REACT_APP_ON_OFF_PAYMENT_REGISTRATION as
    | 'false'
    | 'true'
const enableOnOffFunctionPaymentCancellation = process.env.REACT_APP_ON_OFF_PAYMENT_CANCELLATION as
    | 'false'
    | 'true'
const enableOnOffFunctionBenefits = process.env.REACT_APP_ON_OFF_FUNCTION_BENEFITS as
    | 'false'
    | 'true'
const enableOnOffFunctionAgreementCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_AGREEMENT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountOpenCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_OPEN_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionBondingCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_BONDING_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionCupsTermsQuotas = process.env
    .REACT_APP_ON_OFF_FUNCTION_CUPS_TERMS_QUOTAS as 'false' | 'true'
const enableOnOffFunctionChangePassword = process.env.REACT_APP_ON_OFF_FUNCTION_CHANGE_PASSWORD as
    | 'false'
    | 'true'
const enableOnOffFunctionEasterBonusRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_EASTER_BONUS_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionChristmasBonusRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_CHRISTMAS_BONUS_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionGeneralRegulationsCatchments = process.env
    .REACT_APP_ON_OFF_FUNCTION_GENERAL_REGULATIONS_CATCHMENTS as 'false' | 'true'
const enableOnOffFunctionPartialReturnPermanentSavings = process.env
    .REACT_APP_ON_OFF_FUNCTION_PARTIAL_RETURN_PERMANENT_SAVINGS as 'false' | 'true'
const enableOnOffFunctionReturnAuthorization = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETURN_AUTHORIZATION as 'false' | 'true'
const enableOnOffFunctionReturnAuthorizationRequest = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETURN_AUTHORIZATION_REQUEST as 'false' | 'true'
const enableOnOffFunctionExclusionRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_EXCLUSION_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionKeyChangeForwardtoForget = process.env
    .REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD_TO_FORGET as 'false' | 'true'
const enableOnOffFunctionTravelMemos = process.env.REACT_APP_ON_OFF_FUNCTION_TRAVEL_MEMOS as
    | 'false'
    | 'true'
const enableOnOffFunctionAdvanceFaiAccount = process.env
    .REACT_APP_ON_OFF_FUNCTION_ADVANCE_FAI_ACCOUNT as 'false' | 'true'
const enableOnOffFunctionCreditRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_CREDIT_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionCreditRegulationsConditions = process.env
    .REACT_APP_ON_OFF_FUNCTION_CREDIT_REGULATIONS_CONDITIONS as 'false' | 'true'
const enableOnOffFunctionRetentionCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETENTION_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionWelfareRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_WELFARE_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionBetweenAccountsFAI = process.env
    .REACT_APP_ON_OFF_FUNCTION_BETWEEN_ACCOUNTS_FAI as 'false' | 'true'
const enableOnOffFunctionACHReport = process.env.REACT_APP_ON_OFF_FUNCTION_ACH_REPORT as
    | 'false'
    | 'true'
const enableOnOffFunctionCaviFacil = process.env.REACT_APP_ON_OFF_FUNCTION_CAVIFACIL as
    | 'false'
    | 'true'
const enableOnOffFunctionACH = process.env.REACT_APP_ON_OFF_FUNCTION_ACH as 'false' | 'true'
const enableOnOffFunctionRecreationalTermTitleTar = process.env
    .REACT_APP_ON_OFF_FUNCTION_RECREATIONAL_TERM_TITLE as 'false' | 'true'
const enableOnOffFunctionUpdateAssociate = process.env
    .REACT_APP_ON_OFF_FUNCTION_UPDATE_ASSOCIATE as 'false' | 'true'
const enableOnOffFunctionNonContributoryGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_NON_CONTRIBUTORY_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionCavipetrolDebtorsGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_CAVIPETROL_DOBTORS_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionEcoptrolDebtorsGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_ECOPETROL_DEBTORS_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionDebtorsFireGroupPolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_DEBTORS_FIRE_GROUP_POLICY as 'false' | 'true'
const enableOnOffFunctionGetCreditSimulator = process.env
    .REACT_APP_ON_OFF_FUNCTION_GET_CREDIT_SIMULATOR as 'false' | 'true'

const SidebarStart = (): JSX.Element => {
    const dispatch = useDispatch()
    const state = useSelector((stateRef: any) => stateRef)
    const history = useHistory()
    const [show, setShow] = useState(false)
    const { isOpen, setCurrentStep, setIsOpen: setIsOpenTour } = useAppTour()

    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (functionalityQuery.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            functionalityQuery.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
        }
    }, [functionalityQuery])

    const [urlsByTag, setUrlsByTag] = useState<Record<string, string | null>>({});
    const { data: dataUrlRegulation, fetchRegulations, loadingRegulations, errorRegulations } = useFetchRegulations();

    useEffect(() => {
        if (dataUrlRegulation && dataUrlRegulation.Data) {
            const newUrlsByTag: Record<string, string | null> = {};

            const getUrlByTag = (tag: string): string | null => {
                const matchingItem = dataUrlRegulation.Data.find((item) => item.Tag === tag);
                return matchingItem ? matchingItem.Url : null;
            };

            const tagsToRetrieve = [
                'CreditRegulationsConditions',
                'ReturnAuthorization',
                'ReturnAuthorizationRequest',
                'PartialReturnPermanentSavings',
                'GeneralRegulationsCatchments',
                'EasterBonusRegulations',
                'ChristmasBonusRegulations',
                'CreditRegulations',
                'WelfareRegulations',
                'ExclusionRegulations',
            ];

            tagsToRetrieve.forEach((tag) => {
                newUrlsByTag[tag] = getUrlByTag(tag);
            });

            setUrlsByTag(newUrlsByTag);
        }
    }, [dataUrlRegulation]);

    const { data: dataUrlPolicies, fetchListPolicies, loadingPolicies, errorPolicies } = useFetchListPolicies()
    const [urlsByTagPolicies, setUrlsByTagPolicies] = useState<Record<string, string | null>>({});

    useEffect(() => {
        if (dataUrlPolicies && dataUrlPolicies.Data) {
            const newUrlsByTagPolicies: Record<string, string | null> = {};

            const getUrlByTagPolicies = (tag: string): string | null => {
                const matchingItem = dataUrlPolicies.Data.find((item) => item.Tag === tag)
                return matchingItem ? matchingItem.Url : null
            }

            const tagsToRetrievePolicies = [
                'DebtorsFireGroupPolicy',
                'CavipetrolDebtorsGroupLifePolicy',
                'EcoptrolDebtorsGroupLifePolicy',
                'NonContributoryGroupLifePolicy',
            ]

            tagsToRetrievePolicies.forEach((tag) => {
                newUrlsByTagPolicies[tag] = getUrlByTagPolicies(tag)
            })

            setUrlsByTagPolicies(newUrlsByTagPolicies);
        }
    }, [dataUrlPolicies])

    const handleOptionClick = (): void => {
        fetchRegulations();
        fetchListPolicies();
    }

    const rideStep = (step: number): void => {
        if (isOpen) setCurrentStep(step)
    }

    const handleClose = (): void => {
        setShow(false)
    }
    const handleShow = (): void => {
        setShow(true)
        rideStep(2)
    }

    const redirections = (url: string): void => {
        history.push(url)
    }

    const loggout = async (): Promise<void> => {
        const tokenSave = state.auth.token
        await authenticationService
            .getLogin('/api/Authorization/LogOut', tokenSave)
            .then((response) => {
                if (response) {
                    setIsOpenTour(false)
                    dispatch(logout())
                    window.location.href = '/login'
                }
            })
    }

    /* Precondición: Solicitudes Alertas TC  */
    const { data } = useSelector(CreditCardsSelector)
    const [isCreditCardHolder, setIsCreditCardHolder] = useState<boolean>(false)

    useEffect(() => {
        if (data !== undefined && data?.cards !== undefined) {
            if (data?.cards?.length > 0) {
                setIsCreditCardHolder(true)
            }
        } else {
            setIsCreditCardHolder(false)
        }
    }, [data, data?.cards])

    const encryptedKey = localStorage.getItem('isUserRol')
    let loggedUser = ''

    if (encryptedKey) {
        loggedUser = decryptKey(encryptedKey)
    }

    const onPaymentCard = (): void => {
        eventBus.emit('scrollToDestination', { id: 'section-cards' });
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                className="only-mobile btn-sidebar-start"
                data-tour="menu-options-mob"
            ></Button>

            <Offcanvas show={show} onHide={handleClose} data-tour="menu-options-mob-cls">
                <Offcanvas.Header closeButton>
                    <div className="header-sidebar-start">
                        {state.auth.gender === 'Hombre' ? (
                            <p className="text-welcome-white">Bienvenido</p>
                        ) : (
                            <p className="text-welcome-white">Bienvenida</p>
                        )}
                        <p className="text-name">{state.auth.name}</p>
                        {state.auth.lastconection === '' ? (
                            <div className="last-connect"></div>
                        ) : (
                            <div className="last-connect">
                                <p>La fecha de tu último ingreso fue el:</p>
                                <p>{state.auth.lastconection}</p>
                            </div>
                        )}
                    </div>
                </Offcanvas.Header>
                <div className="content-sidebar-start">
                    <Offcanvas.Body className="conetnt-sidebar-start">
                        <div className="section1-sidebar-start">
                            <Accordion>
                                {parseStringToBoolean(enableTDC) && (loggedUser === 'Administrador' || loggedUser === 'Asociado') && (
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className="gap"
                                            data-tour="products-opt-mob"
                                        >
                                            <img
                                                src={ProductSideSVG}
                                                alt="Productos"
                                                className="options-img"
                                            />
                                            Productos
                                        </Accordion.Header>
                                        <Accordion.Body className="content-opton-sidebar-start">
                                            {parseStringToBoolean(enableOpeningCreditCard) && (
                                                <>
                                                    <p className="title-sidebar-start">
                                                        Tarjeta de crédito
                                                    </p>
                                                    {isCreditCardHolder && (
                                                        <a
                                                            className="item-sidebar-start"
                                                            onClick={() =>
                                                                redirections('/product-opening')
                                                            }
                                                        >
                                                            Solicitar tarjeta
                                                        </a>
                                                    )}
                                                    <a className="item-sidebar-start">
                                                        Activar tarjetas
                                                    </a>
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionPaymentRegistration
                                                    ) &&
                                                        enableFuntions.OnOffFunctionPaymentRegistration && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/payment-schedule-registration"
                                                            >
                                                                Programar Pago Automático
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionPaymentCancellation
                                                    ) &&
                                                        enableFuntions.OnOffFunctionPaymentCancellation && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/payment-schedule-cancellation"
                                                            >
                                                                Cancelar pago automático
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionKeyChangeForwardtoForget
                                                    ) &&
                                                        enableFuntions.OnOffFunctionKeyChangeForwardtoForget && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() =>
                                                                    redirections('/reset-key-advances')
                                                                }
                                                            >
                                                                Olvidé mi clave para avances
                                                            </a>)}
                                                    {parseStringToBoolean(enableOnOffFunctionTravelMemos) &&
                                                        enableFuntions.OnOffFunctionTravelMemos && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() =>
                                                                    redirections(
                                                                        '/activate-international-card'
                                                                    )
                                                                }
                                                            >
                                                                Activar tarjeta fuera de Colombia
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionAdvanceFaiAccount
                                                    ) &&
                                                        enableFuntions.OnOffFunctionAdvanceFaiAccount && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/advance-to-fai-account"
                                                            >
                                                                Avance a Tu Cuenta FAI
                                                            </a>)}
                                                    <p className="title-sidebar-start">
                                                        Inversiones
                                                    </p>
                                                </>
                                            )}
                                            <a
                                                className="item-sidebar-start"
                                                onClick={() => redirections('/product-opening')}
                                            >
                                                Abre un TDC
                                            </a>
                                        </Accordion.Body>
                                        <hr className="separed-item-sidebar"></hr>
                                    </Accordion.Item>
                                )}
                                {loggedUser !== "ADMCall" && (
                                    <>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="gap" data-tour="payments-opt-mob">
                                                <img
                                                    src={PaymentSideSVG}
                                                    alt="Pagos"
                                                    className="options-img"
                                                />
                                                Pagos
                                            </Accordion.Header>
                                            <Accordion.Body className="content-opton-sidebar-start">
                                                {(loggedUser === 'Administrador' || loggedUser === 'Asociado') && (
                                                    <>
                                                        {parseStringToBoolean(enableCreditCard) && (
                                                            <>
                                                                <p className="title-sidebar-start">
                                                                    Tarjetas de crédito
                                                                </p>
                                                                <a onClick={onPaymentCard} className="item-sidebar-start">
                                                                    Pagar tarjeta crédito
                                                                </a>
                                                            </>
                                                        )}
                                                        <p className="title-sidebar-start">Productos de ahorro</p>
                                                        <a
                                                            className="item-sidebar-start"
                                                            onClick={() => redirections('/savings-group')}
                                                        >
                                                            Pagar aportes
                                                        </a>
                                                        <a
                                                            className="item-sidebar-start"
                                                            onClick={() => redirections('/savings-group')}
                                                        >
                                                            Pagar ahorro recreativo
                                                        </a>
                                                    </>
                                                )}
                                                <p className="title-sidebar-start">Créditos</p>
                                                <a
                                                    className="item-sidebar-start"
                                                    onClick={() => redirections('/credits-group')}
                                                >
                                                    Pagar créditos
                                                </a>
                                            </Accordion.Body>
                                            <hr className="separed-item-sidebar"></hr>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="gap" data-tour="services-opt-mob">
                                                <img
                                                    src={ServiceSideBarBell}
                                                    alt="Servicios en Línea"
                                                    className="options-img"
                                                />
                                                <div onClick={() => handleOptionClick()}>Servicios en Línea</div>

                                            </Accordion.Header>
                                            <Accordion.Body className="content-opton-sidebar-start">
                                                <>
                                                    {(enableFuntions.OnOffFunctionM2PublishRealState || enableFuntions.OnOffFunctionM2GetRealStates) && (<p className="title-sidebar-start">
                                                        Bolsa virtual inmobiliaria
                                                    </p>
                                                    )}
                                                    {/* Validación de estado de las variables para apagar la funcionalidad en el header */}
                                                    {parseStringToBoolean(enableM2PublishRealState) &&
                                                        enableFuntions.OnOffFunctionM2PublishRealState && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/possessions-insert"
                                                            >
                                                                Insertar Inmueble
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(enableM2GetRealStates) &&
                                                        enableFuntions.OnOffFunctionM2GetRealStates && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/possessions-consult"
                                                            >
                                                                Consultar Inmueble
                                                            </a>
                                                        )}
                                                    {(enableFuntions.OnOffFunctionMortgageClient || enableFuntions.OnOffFunctionCodebtor || enableFuntions.OnOffFunctionBenefits || enableFuntions.OnOffFunctionSeveranceQuote || enableFuntions.OnOffFunctionScoresAndQuotas || enableFuntions.OnOffFunctionBalancesStatements || enableFuntions.OnOffFunctionTermQuotaRate || enableFuntions.OnOffFunctionRetentionCertificate || enableFuntions.OnOffFunctionAgreementCertificate || enableFuntions.OnOffFunctionFaiAccountCertificate || enableFuntions.OnOffFunctionBondingCertificate || enableFuntions.OnOffFunctionFaiAcountOpenCertificate) && (
                                                        <p className="title-sidebar-start">Consultas</p>)}
                                                    {parseStringToBoolean(enableOnOffFunctionMortgages) &&
                                                        enableFuntions.OnOffFunctionMortgageClient && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/mortgages')}
                                                            >
                                                                Hipotecas
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(enableOnOffFunctionBenefits) &&
                                                        enableFuntions.OnOffFunctionBenefits && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/benefits')}
                                                            >
                                                                Beneficios
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(enableOnOffFunctionPledge) &&
                                                        enableFuntions.OnOffFunctionSeveranceQuote && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/pledge')}
                                                            >
                                                                Cuota por pignoración de cesantías
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionScoresAndQuotas
                                                    ) &&
                                                        enableFuntions.OnOffFunctionScoresAndQuotas && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/scorequotas')}
                                                            >
                                                                Puntajes y Cupos
                                                            </a>
                                                        )}
                                                    {enableFuntions.OnOffFunctionBalancesStatements && (
                                                        <a
                                                            className="item-sidebar-start"
                                                            onClick={() => redirections(`/balances-extracts`)}
                                                        >
                                                            Saldos y extractos
                                                        </a>
                                                    )}
                                                    {parseStringToBoolean(enableOnOffFunctionBenefits) &&
                                                        enableFuntions.onOffFunctionBenefits && (
                                                            <a onClick={() => redirections('/benefits')}>
                                                                Beneficios
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(enableOnOffFunctionCodebtor) &&
                                                        enableFuntions.OnOffFunctionCodebtor && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/codebtors')}
                                                            >
                                                                Codeudores
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionCupsTermsQuotas
                                                    ) &&
                                                        enableFuntions.OnOffFunctionTermQuotaRate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() =>
                                                                    redirections('/cups-terms-quotas')
                                                                }
                                                            >
                                                                Tasas, plazos y cupos
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionAgreementCertificate
                                                    ) &&
                                                        enableFuntions.OnOffFunctionAgreementCertificate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/certification-for-agreements"
                                                            >
                                                                Certificado Convenios
                                                            </a>
                                                        )}

                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionFaiAccountCertificate
                                                    ) &&
                                                        enableFuntions.OnOffFunctionFaiAccountCertificate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/certification-fai"
                                                            >
                                                                Certificado FAI
                                                            </a>
                                                        )}

                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionBondingCertificate
                                                    ) &&
                                                        enableFuntions.OnOffFunctionBondingCertificate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/certification-for-affiliation"
                                                            >
                                                                Certificado de Vinculación
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionFaiAccountOpenCertificate
                                                    ) &&
                                                        enableFuntions.OnOffFunctionFaiAcountOpenCertificate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/certification-fai-account-opening"
                                                            >
                                                                Certificado Apertura FAI
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionRetentionCertificate
                                                    ) &&
                                                        enableFuntions.OnOffFunctionRetentionCertificate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() =>
                                                                    redirections('/Certificate-retention')
                                                                }
                                                            >
                                                                Certificado Retención
                                                            </a>)}
                                                    {(enableFuntions.OnOffFunctionM2PublishRealState || enableFuntions.OnOffFunctionM2GetRealStates || enableFuntions.OnOffFunctionUpdateAssociate || enableFuntions.OnOffFunctionChangePassword) && (
                                                        <p className="title-sidebar-start">Servicios</p>)}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionChangePassword
                                                    ) &&
                                                        enableFuntions.OnOffFunctionChangePassword && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/key-change"
                                                            >
                                                                Cambio de clave
                                                            </a>
                                                        )}
                                                    {parseStringToBoolean(enableOnOffFunctionUpdateAssociate) &&
                                                        enableFuntions.OnOffFunctionUpdateAssociate && (

                                                            <a className="item-sidebar-start" href="/data-update">
                                                                Actualizacion de datos
                                                            </a>

                                                        )}

                                                    {parseStringToBoolean(enableOnOffFunctionCupsTermsQuotas) &&
                                                        enableFuntions.OnOffFunctionTermQuotaRate && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                onClick={() => redirections('/cups-terms-quotas')}
                                                            >
                                                                Tasas, plazos y cupos
                                                            </a>)}
                                                    {(enableFuntions.OnOffFunctionExclusionRegulations || enableFuntions.OnOffFunctionEasterBonusRegulations || enableFuntions.OnOffFunctionChristmasBonusRegulations || enableFuntions.OnOffFunctionWelfareRegulations || enableFuntions.OnOffFunctionGeneralRegulationsCatchments) && (
                                                        <p className="title-sidebar-start">Reglamentos</p>)}

                                                    {loadingRegulations && <p><Loading text="Aguarda un momento" /></p>}
                                                    {errorRegulations && <p>{errorRegulations}</p>}
                                                    {Object.keys(urlsByTag).length > 0 && (
                                                        <>
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionExclusionRegulations
                                                            ) &&
                                                                enableFuntions.OnOffFunctionExclusionRegulations && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTag['ExclusionRegulations'] ??
                                                                            '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Exclusión
                                                                    </a>
                                                                )}

                                                            {parseStringToBoolean(enableOnOffFunctionWelfareRegulations) &&
                                                                enableFuntions.OnOffFunctionWelfareRegulations && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTag['WelfareRegulations'] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        Reglamento Bienestar
                                                                    </a>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionEasterBonusRegulations
                                                            ) &&
                                                                enableFuntions.OnOffFunctionEasterBonusRegulations && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTag['EasterBonusRegulations'] ??
                                                                            '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Bono de Pascua
                                                                    </a>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionChristmasBonusRegulations
                                                            ) &&
                                                                enableFuntions.OnOffFunctionChristmasBonusRegulations && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTag[
                                                                            'ChristmasBonusRegulations'
                                                                            ] ?? '/not-found'
                                                                        }
                                                                        target="_blank" rel="noreferrer"

                                                                    >
                                                                        Bono de Navidad
                                                                    </a>
                                                                )}

                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionGeneralRegulationsCatchments
                                                            ) &&
                                                                enableFuntions.OnOffFunctionGeneralRegulationsCatchments && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTag['GeneralRegulationsCatchments'] ??
                                                                            '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Reglamento General de Captaciones
                                                                    </a>
                                                                )}
                                                            {(enableFuntions.OnOffFunctionCreditRegulations || enableFuntions.OnOffFunctionCreditRegulationsConditions) && (
                                                                <p className="title-sidebar-start-sub">Crédito</p>)}
                                                            {parseStringToBoolean(enableOnOffFunctionCreditRegulations) &&
                                                                enableFuntions.OnOffFunctionCreditRegulations && (
                                                                    <a
                                                                        className="item-sidebar-start-sub"
                                                                        href={
                                                                            urlsByTag['CreditRegulations'] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Crédito
                                                                    </a>
                                                                )}

                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCreditRegulationsConditions
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCreditRegulationsConditions && (
                                                                    <a
                                                                        className="item-sidebar-start-sub"
                                                                        href={
                                                                            urlsByTag['CreditRegulationsConditions'] ??
                                                                            '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Condiciones Línea Crédito
                                                                    </a>)}

                                                            {(enableFuntions.OnOffFunctionPartialReturnPermanentSavings || enableFuntions.OnOffFunctionReturnAuthorization || enableFuntions.OnOffFunctionReturnAuthorizationRequest) && (
                                                                <p className="title-sidebar-start-sub">
                                                                    Ahorros Permanentes
                                                                </p>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionPartialReturnPermanentSavings
                                                            ) &&
                                                                enableFuntions.OnOffFunctionPartialReturnPermanentSavings && (
                                                                    <a
                                                                        className="item-sidebar-start-sub"
                                                                        href={
                                                                            urlsByTag['PartialReturnPermanentSavings'] ??
                                                                            '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Devolución Parcial de Ahorros Permanentes
                                                                    </a>
                                                                )}
                                                            {parseStringToBoolean(enableOnOffFunctionReturnAuthorization) &&
                                                                enableFuntions.OnOffFunctionReturnAuthorization && (
                                                                    <a
                                                                        className="item-sidebar-start-sub"
                                                                        href={

                                                                            urlsByTag['ReturnAuthorization'] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Autorización para Solicitud de Cruce y/o <br />{' '}
                                                                        Devolución de ahorros permanentes
                                                                    </a>
                                                                )}
                                                            {parseStringToBoolean(enableOnOffFunctionReturnAuthorizationRequest) &&
                                                                enableFuntions.OnOffFunctionReturnAuthorizationRequest && (
                                                                    <a
                                                                        className="item-sidebar-start-sub"
                                                                        href={

                                                                            urlsByTag['ReturnAuthorizationRequest'] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Solicitud y Autorización de Cruce y/o Devolución de
                                                                        Ahorros Permanentes
                                                                    </a>
                                                                )}

                                                        </>)}
                                                    {(enableFuntions.OnOffFunctionRecreationalTermTitleTar || enableFuntions.OnOffFunctionGetCreditSimulator) && (
                                                        <p className="title-sidebar-start">Simuladores</p>)}
                                                    {parseStringToBoolean(
                                                        enableOnOffFunctionGetCreditSimulator
                                                    ) &&
                                                        enableFuntions.OnOffFunctionGetCreditSimulator && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/calculate-credit"
                                                            >
                                                                Créditos
                                                            </a>)}
                                                    {parseStringToBoolean(enableOnOffFunctionRecreationalTermTitleTar) &&
                                                        enableFuntions.OnOffFunctionRecreationalTermTitleTar && (
                                                            <a
                                                                className="item-sidebar-start"
                                                                href="/recreational-termtitle"
                                                            >
                                                                Título a termino recreativo
                                                            </a>
                                                        )}
                                                    {(enableFuntions.OnOffFunctionNonContributoryGroupLifePolicy || enableFuntions.OnOffFunctionDebtorsFireGroupPolicy || enableFuntions.OnOffFunctionCavipetrolDebtorsGroupLifePolicy || enableFuntions.OnOffFunctionEcoptrolDebtorsGroupLifePolicy) && (
                                                        <p className="title-sidebar-start">Polizas</p>)}

                                                    {loadingPolicies && <p><Loading text="Aguarda un momento" /></p>}
                                                    {errorPolicies && <p>{errorPolicies}</p>}
                                                    {Object.keys(urlsByTagPolicies).length > 0 && (
                                                        <>
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionNonContributoryGroupLifePolicy
                                                            ) &&
                                                                enableFuntions.OnOffFunctionNonContributoryGroupLifePolicy && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTagPolicies[
                                                                            'NonContributoryGroupLifePolicy'
                                                                            ] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Póliza Vida Aportes
                                                                    </a>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionDebtorsFireGroupPolicy
                                                            ) &&
                                                                enableFuntions.OnOffFunctionDebtorsFireGroupPolicy && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTagPolicies[
                                                                            'DebtorsFireGroupPolicy'
                                                                            ] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Póliza Incendio deudores
                                                                    </a>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCavipetrolDebtorsGroupLifePolicy
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCavipetrolDebtorsGroupLifePolicy && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTagPolicies[
                                                                            'CavipetrolDebtorsGroupLifePolicy'
                                                                            ] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Póliza Vida Grupo Deudores
                                                                        Cavipetrol
                                                                    </a>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionEcoptrolDebtorsGroupLifePolicy
                                                            ) &&
                                                                enableFuntions.OnOffFunctionEcoptrolDebtorsGroupLifePolicy && (
                                                                    <a
                                                                        className="item-sidebar-start"
                                                                        href={
                                                                            urlsByTagPolicies[
                                                                            'EcoptrolDebtorsGroupLifePolicy'
                                                                            ] ?? '/not-found'
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Póliza Vida Grupo Deudores
                                                                        Ecopetrol
                                                                    </a>)}
                                                        </>
                                                    )}
                                                </>
                                            </Accordion.Body>
                                            <hr className="separed-item-sidebar"></hr>
                                        </Accordion.Item>
                                    </>
                                )}
                                {(loggedUser === 'Administrador' || loggedUser === 'Asociado') && (
                                    <>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header className="gap" data-tour="">
                                                <img
                                                    src={PaymentTransactionSideSVG}
                                                    alt="PaymentTransactions"
                                                    className="options-img"
                                                    width={30}
                                                />
                                                Transacciones
                                            </Accordion.Header>
                                            <Accordion.Body className="content-opton-sidebar-start">
                                                {enableFuntions.OnOffFunctionBetweenAccountsFAI && (
                                                    <p className="title-sidebar-start">Transferencias</p>)}
                                                {parseStringToBoolean(enableOnOffFunctionBetweenAccountsFAI) &&
                                                    enableFuntions.OnOffFunctionBetweenAccountsFAI && (
                                                        <a
                                                            className="item-sidebar-start"
                                                            href="/fai-account-transfer"
                                                        >
                                                            Entre cuentas Fai
                                                        </a>
                                                    )}
                                                {(enableFuntions.OnOffFunctionACH || enableFuntions.OnOffFunctionACHReport) && (
                                                    <p className="title-sidebar-start">Pagos</p>)}
                                                {parseStringToBoolean(enableOnOffFunctionACH) && enableFuntions.OnOffFunctionACH && (
                                                    <a className="item-sidebar-start" href="/interbankTransfer-ACH">Transferencias interbancarias</a>
                                                )}
                                                {parseStringToBoolean(enableOnOffFunctionACHReport) &&
                                                    enableFuntions.OnOffFunctionACHReport && (
                                                        <a className="item-sidebar-start" href="/reportACH">
                                                            Reporte de transferencias interbancarias
                                                        </a>)}
                                                {enableFuntions.OnOffFunctionCaviFacil && (
                                                    <p className="title-sidebar-start">Desembolsos</p>)}
                                                {parseStringToBoolean(enableOnOffFunctionCaviFacil) &&
                                                    enableFuntions.OnOffFunctionCaviFacil && (
                                                        <a className="item-sidebar-start" href="/cavifacil-transaction">CaviFácil</a>
                                                    )}
                                            </Accordion.Body>
                                            <hr className="separed-item-sidebar"></hr>
                                        </Accordion.Item>
                                    </>
                                )}
                                {loggedUser === 'Administrador' &&
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header className="gap" data-tour="">
                                            <img
                                                src={AdminPanel2Icon}
                                                alt="PaymentTransactions"
                                                className="options-img"
                                                width={30}
                                            />
                                            Administración
                                        </Accordion.Header>
                                        <Accordion.Body className="content-opton-sidebar-start">
                                            <p className="title-sidebar-start">Usuarios</p>
                                            <a
                                                className="item-sidebar-start"
                                                href="/userManagement"
                                            >
                                                Usuarios
                                            </a>
                                            <p className="title-sidebar-start">Roles</p>
                                            <a
                                                className="item-sidebar-start"
                                                href="/rolManagement"
                                            >
                                                Roles
                                            </a>
                                            <p className="title-sidebar-start">Contenido Administrable</p>
                                            <a className="item-sidebar-start" href="/manageableContent">
                                                Contenido Administrable
                                            </a>
                                        </Accordion.Body>
                                        <hr className="separed-item-sidebar"></hr>
                                    </Accordion.Item>
                                }
                                {(loggedUser === 'Administrador' || loggedUser === 'ADMCall') &&
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header className="gap" data-tour="">
                                            <img
                                                src={SupervisorSideBar}
                                                alt="PaymentTransactions"
                                                className="options-img"
                                                width={30}
                                            />
                                            Manejo Usuarios
                                        </Accordion.Header>
                                        <Accordion.Body className="content-opton-sidebar-start">
                                            <p className="title-sidebar-start">Usuarios</p>
                                            <a
                                                className="item-sidebar-start"
                                                href="/userOperations"
                                            >
                                                Usuarios
                                            </a>
                                        </Accordion.Body>
                                        <hr className="separed-item-sidebar"></hr>
                                    </Accordion.Item>
                                }
                            </Accordion>
                            <a
                                className="options-sidebar"
                                onClick={() => redirections('/support-private')}
                                data-tour="support-mob"
                            >
                                <img
                                    src={HeadsetColorSVG}
                                    alt="servicio al cliente"
                                    className="options-img"
                                />
                                <p>Servicio al asociado</p>
                            </a>
                        </div>
                        <a className="options-sidebar botton" onClick={loggout}>
                            <img src={LogOutSVG} alt="Cerrar sesión" className="options-img" />
                            <p>Cerrar sesión</p>
                        </a>
                    </Offcanvas.Body>
                </div>
            </Offcanvas>
        </>
    )
}

export default SidebarStart
