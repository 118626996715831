import tw, { styled } from 'twin.macro'
import { Form } from 'react-bootstrap'
import {
    FormLabel as FormLabelApp,
    FormGroup as FormGroupApp,
} from '../../../../components/includes'

export const TitleStep = styled.h4`
    ${tw`font-helvetica font-normal block text-dominant-dark text-base my-3`}
`
export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export interface FormGroupProps {
    $heightSize?: string
}

export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0 my-2`}

    height: ${(props) => props.$heightSize};
`
export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}
`
export const ParagraphStep = styled.p`
    ${tw`font-helvetica font-normal block text-gray-custom-300 text-base my-3`}
`
export interface TopPosition {
    top?: string
}
export const Image = styled.img<TopPosition>`
    ${tw`z-[50]`}
    margin-top: ${(props) => (props.top ? props.top : '')};
`

export const FormMessageSelect = styled.p`
    ${tw`font-montserrat font-normal text-sm text-alert mt-[5px] mb-0`}
    ${tw`absolute bottom-0`}
`

export const FormSelect = styled(Form.Select)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-10 border-none cursor-pointer`}
    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}

    &:disabled {
        ${tw`bg-transparent`}
    }
`

export interface FormOptionProps {
    show?: boolean
}

export const FormOption = styled.option<FormOptionProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`
export const SpaceStep = styled.div`
    ${tw`w-full h-1.5`}
`
export const SubTitleStep = styled.h4`
    ${tw`font-helvetica font-normal block text-black text-base my-3`}
`

export const RedirectContainer = styled.div`
    ${tw`mt-2 mb-3 flex flex-col justify-center lg:flex-row gap-3`}
    & > div {
        ${tw`mt-5 flex flex-col gap-5`}
    }
`

export const container = styled.div`
    position: relative;
    right: 5px;
    text-align: end;
    p {
        color: var(--error-color);
        font-family: var(--font-secundary-regular);
        font-size: 13px;
        margin: 0;
    }
    @media (min-width: 670px) and (max-width: 1180px) {
        position: absolute;
        bottom: -25px;
        width: 150%;
    }

    @media (min-width: 1180px) and (max-width: 1450px) {
        position: absolute;
        bottom: -25px;
        width: 150%;
        p {
            font-size: 15px;
        }
    }
    @media (min-width: 1450px) {
        position: absolute;
        bottom: -25px;
        width: 150%;
        p {
            font-size: 15px;
        }
    }
`
