import tw, { styled } from 'twin.macro'

export const TextP = styled.p`
    ${tw`font-helvetica opacity-100`}
    color: #53595a;

    span {
        color: #f5a50b;
    }
`

export const TextInfo = styled.p`
    ${tw`font-helvetica pl-4 opacity-100`}
    color: #53595A;

    span {
        color: #f5a50b;
    }
`

export const UserDate = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900 pl-4`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }
`
export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-12 w-full rounded-[10px] shadow-[0px 3px 6px #00000029] mt-5 justify-center`}

    .second-content {
        ${tw`mt-10`}
    }

    .row-transaction {
        margin-top: 50px;
    }

    .download {
        ${tw`w-[75%] justify-center`}
    }
    .button-download {
        background: transparent;
        border: 0;
        color: #f5a50b;
        text-decoration-line: underline;
    }

    .row-pdf {
        justify-content: center;
    }
`

export const TextTitle = styled.h2`
    ${tw`font-montserrat font-bold text-dominant-dark text-center text-lg lg:text-xl mb-0`}

    .text-danger {
        ${tw`text-danger`}
    }
`

export const TextSimple = styled.p`
    ${tw`font-montserrat text-center text-base text-black`}
    font-size:15px;
`

export const TextSimpleInfo = styled.p`
    ${tw`font-montserrat text-left font-bold text-lg mb-0`}
`

export const TextSimpleDate = styled.p`
    ${tw`font-montserrat text-left text-black`}
    font-size:14px;
`

export const ContainerButtons = styled.div`
    ${tw`gap-1 my-5 flex flex-col justify-end items-end`}

    & button {
        ${tw`lg:!w-[25%]`}
    }
`

export const TextState = styled.p`
    ${tw`font-montserrat text-center text-base mb-0`}
`
