import tw, { styled } from 'twin.macro'

export const Wrapper = styled.section`
    background-color: #fafbfd;
    padding-top: 0 !important;
    margin-top: 15px;

    ${tw`flex justify-center items-center py-2`}

    form-select {
        background-image: var(--url-arrow-down-yellow-icon) !important;
    }
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[800px] lg:max-w-[1200px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const MessageContent = styled.div`
    color: #626262;
    background-color: #ffffff;
    padding: 30px;
    max-height: 400px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;

    ${tw` rounded-[10px]`}
`

export const TitleContent = styled.label`
    color: #626262;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
`
export const TextForm = styled.label`
    color: #000000;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
`

export const PaginationContent = styled.div`
    ul {
        justify-content: center;
    }

    p.symbol {
        color: var(--sub-dominant-color) !important;
        font-weight: bold;
        margin-top: revert !important;
    }
`

export const ButtonContent = styled.div`
    ${tw`mx-auto  w-[90%]`}

    &.default {
        ${tw`block`}
    }

    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }

    button {
        border-radius: 5px;
        border: 1px solid #dee2e6;
        background: #512f8b;
        color: #fff;
        height: 40px;
        width: 120px;
    }
`

export const ButtonPagination = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    color: var(--sub-dominant-color) !important;
    width: auto !important;

    .button-pagination {
        color: var(--sub-dominant-color) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }
`
export const FormSelect = styled.div`
    ${tw``}
    select.selectFromControl {
        background-image: var(--url-arrow-down-yellow-icon) !important;
    }
`
