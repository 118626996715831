import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useValidateExpirationDate } from '../../hooks/useAdvanceKeyCard'
import { Content } from './MonthYearSelector-Styled'
import { TextSubtitle } from '../DynamicKeyContainer/DynamicKeyContainer-Styled'
import { NotProductSVG } from '../../../../../../utils/getIcons'
import { Loading, ModalGeneric } from '../../../../../../components'

interface MonthYearSelectorProps {
    onMonthYearChange: (selectedMonth: string, selectedYear: string) => void
    numberCard: string
    tokenSave?: any
    handleValidDate: (value: boolean) => void
}

const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const monthValue = (i + 1).toString().padStart(2, '0')
    const monthLabel = new Date(2023, i, 1).toLocaleDateString(undefined, { month: 'long' })
    return {
        value: monthValue,
        label: monthLabel,
    }
})

const currentYear = new Date().getFullYear()
const maxYear = currentYear + 10

const yearOptions = Array.from({ length: maxYear - currentYear + 1 }, (_, i) => currentYear + i)

const MonthYearSelector: React.FC<MonthYearSelectorProps> = ({
    numberCard,
    tokenSave,
    onMonthYearChange,
    handleValidDate,
}) => {
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined)
    const [selectedYear, setSelectedYear] = useState<string | undefined>(undefined)
    const methods = useForm()
    const { control, formState, setError, clearErrors, register } = methods
    const { errors } = formState
    const [showModal, setShowModal] = useState<boolean>(false)
    const [textTitleModal, setTextTitleModal] = useState<string>('')
    const [textBodyModal, setTextBodyModal] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const handleCloseModal = (): void => {
        setShowModal(false)
    }

    const ValidateExpirationDate = (month: string, year: string, numberCard: string): void => {
        useValidateExpirationDate(month, year, numberCard, tokenSave)
            .then((res: any) => {
                const message = res.data?.Message || res.Message
                if (res['status'] === 200 && res['data']['Data']) {
                    setLoading(false)
                    handleValidDate(true)
                } else {
                    setLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(message)
                    handleValidDate(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor.')
                handleValidDate(false)
            })
    }

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        if (e.target.value !== '' || e.target.value === null) {
            clearErrors('selectedYear')
        }
        setSelectedYear(e.target.value)
        if (
            e.target.value !== undefined &&
            selectedMonth !== undefined &&
            e.target.value !== '' &&
            selectedMonth !== ''
        ) {
            setLoading(true)
            clearErrors('selectedMonth')
            onMonthYearChange(selectedMonth, e.target.value)
            ValidateExpirationDate(selectedMonth, e.target.value, numberCard)
        }
    }

    return (
        <Content>
            <FormProvider {...methods}>
                <Row>
                    <Col md={12} xs={12}>
                        <TextSubtitle>Fecha de vencimiento de tu tarjeta o Valid thru</TextSubtitle>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'selectedMonth'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Select
                                        {...register('selectedMonth', {
                                            required: true,
                                        })}
                                        value={selectedMonth}
                                        required
                                        isInvalid={!!errors['selectedMonth']}
                                        onChange={(e) => {
                                            if (e.target.value !== '' || e.target.value === null) {
                                                clearErrors('selectedMonth')
                                            }
                                            setSelectedMonth(e.target.value)
                                            setSelectedYear('')
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('selectedMonth', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('selectedMonth')
                                            }
                                            onBlur()
                                        }}
                                    >
                                        <option value="">Mes</option>
                                        {monthOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label.charAt(0).toUpperCase() +
                                                    option.label.slice(1)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                            {errors['selectedMonth'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['selectedMonth']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'selectedYear'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Select
                                        {...register('selectedYear', {
                                            required: true,
                                        })}
                                        value={selectedYear}
                                        required
                                        isInvalid={!!errors['selectedYear']}
                                        /* onChange={(e) => {
                                            if (e.target.value !== '' || e.target.value === null) {
                                                clearErrors('selectedYear')
                                            }
                                            setSelectedYear(e.target.value)
                                            onChange(e)
                                        }} */
                                        onChange={handleYearChange}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('selectedYear', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('selectedYear')
                                            }
                                            onBlur()
                                        }}
                                        disabled={!selectedMonth}
                                    >
                                        <option value="">Año</option>
                                        {yearOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            />
                            {errors['selectedYear'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['selectedYear']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </FormProvider>
            {loading && (
                <Row>
                    <Col md={12}>
                        <Loading text="Validando si la fecha de vencimiento ingresada es válida." />
                    </Col>
                </Row>
            )}

            <ModalGeneric
                show={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleButton={handleCloseModal}
                handleClose={handleCloseModal}
                textButton="Aceptar"
            />
        </Content>
    )
}

export default MonthYearSelector
