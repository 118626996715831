import { useDispatch, useSelector } from 'react-redux'
import { CreditCardsSelector } from '../../../../../selectors'
import { getCreditCardsAction } from '../../../../../redux/tc'
import { useEffect, useState } from 'react'

import { MiniCardSVG, NotProductSVG } from '../../../../utils/getIcons'
import { InputSelectGroup, Loading, ModalGeneric } from '../../../../components'
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import {
    FormGroup,
    FormLabel,
    FormSelectTC,
    FormOption,
    Image,
    ButtonsContainer,
    CancelButton,
    CardContainer,
    ImgCardContent,
    Content,
    TextInfo,
    FormLabelDateCard,
    TextInstruction,
    ButtonContent,
} from './FirstStep-Styled'
import { CreditCard } from '../../../../../../domain/models'
import { LoadingContainer } from '../../../CreditDetail/creditDetail-styles'
import { ButtonContinue, TitleComponent, Wrapper } from '../../PaymentScheduleCancellationTC-Styled'
import ImageCard from '../../../PaymentScheduleRegistrationTC/inc/ImageCard'
import SecondStep from '../SecondStep'

interface FirstStepProps {
    isGoBack: boolean
    handleGoBack: (value: boolean) => void
    activeCardsSchedule: string[]
    isScheduledPaymentTC: boolean
}

const FirstStep: React.FC<FirstStepProps> = ({
    isGoBack,
    handleGoBack,
    activeCardsSchedule,
    isScheduledPaymentTC,
}) => {
    const history = useHistory()
    const [isSelectTouched, setIsSelectTouched] = useState<boolean>(false)
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(true)
    const dispatch = useDispatch()
    const { loading, data, error } = useSelector(CreditCardsSelector)
    const [checkValue, setCheckValue] = useState('')

    useEffect(() => {
        if (isScheduledPaymentTC) {
            dispatch(getCreditCardsAction())
        }
    }, [dispatch])

    const [selectedOption, setSelectedOption] = useState<string>('')
    const [selectedCardData, setSelectedCardData] = useState<CreditCard | null>(null)
    const [dateNextPayment, setDateNextPayment] = useState<string>('')
    const [showModalCancellationPay, setShowModalCancellationPay] = useState<boolean>(false)

    const [cardNumber, setCardNumber] = useState<string>('')
    const [lastsFourNumCard, setLastsFourNumCard] = useState<string>('')

    const activeCards = data?.cards?.filter((card: CreditCard) => {
        return activeCardsSchedule?.includes(card.cardNumber) ?? []
    })

    const cards = activeCards?.map(
        (card: CreditCard) => card.typeCard + ' - *' + card.lastFourDigits
    )

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const selectedType = event.target.value
        setSelectedOption(selectedType)
        // Busca y guarda los datos de la tarjeta seleccionada
        const selectedCard = data?.cards.find(
            (card) => card.typeCard + ' - *' + card.lastFourDigits === selectedType
        )
        setSelectedCardData(selectedCard ?? null)

        const dateNextPay = new Date(selectedCard?.dateNextPayment ?? '')
        // Obtiene el día del mes
        const dayPay = dateNextPay.getDate()
        setDateNextPayment(`${dayPay} de cada mes`)
        setCardNumber(selectedCard?.cardNumber ?? '')
        setLastsFourNumCard(selectedCard?.lastFourDigits ?? '')
        handleGoBack(true)
    }

    const handleSubmitOpenModal = (): void => {
        setShowModalCancellationPay(true)
    }

    useEffect(() => {
        if (isGoBack) {
            setSelectedOption('')
            handleGoBack(false)
        }
    }, [isGoBack, handleGoBack])

    const handleBlur = (event: React.FocusEvent<HTMLSelectElement>): void => {
        if (event.target.value === '' || event.target.value === null) {
            setIsSelectTouched(true)
        } else {
            setIsSelectTouched(false)
        }
    }

    const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target
        setCheckValue(value)
        if (value === 'true') {
            setIsButtonEnabled(false)
        } else {
            setIsButtonEnabled(true)
        }
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    const handleCloseModalCancellationPay = (): void => {
        setShowModalCancellationPay(false)
    }

    return (
        <Wrapper>
            <>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {activeCards && activeCards.length > 0 ? (
                            <>
                                <CardContainer>
                                    <Content>
                                        <div>
                                            <TitleComponent>Cancela Pago Automático</TitleComponent>
                                        </div>
                                        <TextInfo>
                                            Elige la tarjeta en la que deseas desactivar el pago
                                            automático.
                                        </TextInfo>
                                        <FormGroup>
                                            <FormLabel>Tarjeta</FormLabel>
                                            <InputSelectGroup
                                                style={{
                                                    borderColor:
                                                        isSelectTouched && !selectedOption
                                                            ? '#dc3545'
                                                            : '',
                                                }}
                                            >
                                                <Image src={MiniCardSVG} alt="clave" />
                                                <FormSelectTC
                                                    value={selectedOption}
                                                    onChange={handleSelectChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <FormOption disabled value="">
                                                        Selecciona una opción
                                                    </FormOption>
                                                    {cards?.map((card: string) => (
                                                        <FormOption key={card} value={card}>
                                                            {card}
                                                        </FormOption>
                                                    ))}
                                                </FormSelectTC>
                                            </InputSelectGroup>
                                        </FormGroup>
                                        {isSelectTouched && !selectedOption && (
                                            <p style={{ color: '#dc3545' }}>
                                                Debes seleccionar una opción
                                            </p>
                                        )}
                                    </Content>
                                    {selectedCardData && selectedOption !== '' && (
                                        <>
                                            <Row>
                                                <Col md={6} xs={12}>
                                                    <ImgCardContent>
                                                        <ImageCard
                                                            lastFourDigits={
                                                                selectedCardData.lastFourDigits
                                                            }
                                                            nameCard={selectedCardData.nameCard}
                                                        />
                                                    </ImgCardContent>
                                                </Col>
                                                <Col md={4} xs={12}>
                                                    <FormLabelDateCard>
                                                        Tipo de tarjeta:{' '}
                                                        <span>{selectedCardData.typeCard}</span>{' '}
                                                    </FormLabelDateCard>
                                                    <FormLabelDateCard>
                                                        Número de tarjeta:
                                                        <span>
                                                            {' '}
                                                            *{selectedCardData.lastFourDigits}
                                                        </span>
                                                    </FormLabelDateCard>
                                                    <FormLabelDateCard>
                                                        Fecha de corte:
                                                        <span> {dateNextPayment}</span>
                                                    </FormLabelDateCard>
                                                    {/* <FormLabelDateCard>
                                                            Fecha de corte:
                                                            <span> {dateCutoffDate}</span>
                                                        </FormLabelDateCard> */}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} xs={12}>
                                                    <TextInstruction>
                                                        ¿Desactivar el débito automático de mi
                                                        cuenta FAI para el pago de la cuota mínima
                                                        de la tarjeta de crédito?
                                                    </TextInstruction>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3 checkStyled">
                                                        <Row>
                                                            <Col md={4} xs={12}>
                                                                <Form.Check
                                                                    inline
                                                                    label="Si"
                                                                    type="radio"
                                                                    value="true"
                                                                    checked={checkValue === 'true'}
                                                                    onChange={handleOnChange}
                                                                />
                                                            </Col>
                                                            <Col md={8} xs={12}>
                                                                <Form.Check
                                                                    inline
                                                                    label="No"
                                                                    type="radio"
                                                                    value="false"
                                                                    checked={checkValue === 'false'}
                                                                    onChange={handleOnChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    <Content>
                                        <ButtonsContainer>
                                            <CancelButton
                                                onClick={() => history.push('/home-wallet')}
                                            >
                                                Cancelar
                                            </CancelButton>
                                            <ButtonContent>
                                                <ButtonContinue
                                                    onClick={handleSubmitOpenModal}
                                                    disabled={isButtonEnabled}
                                                >
                                                    Desactivar
                                                </ButtonContinue>
                                            </ButtonContent>
                                        </ButtonsContainer>
                                    </Content>
                                </CardContainer>
                                <SecondStep
                                    showModalCancellationPay={showModalCancellationPay}
                                    handleCloseModalCancellationPay={
                                        handleCloseModalCancellationPay
                                    }
                                    lastsFourNumCard={lastsFourNumCard}
                                    cardNumber={cardNumber}
                                />
                            </>
                        ) : (
                            <ModalGeneric
                                show={true}
                                img={NotProductSVG}
                                textTitle={'Lo sentimos'}
                                textBody={
                                    'No ha sido posible consultar tu información, por favor intenta más tarde.'
                                }
                                handleButton={handleClose}
                                handleClose={handleClose}
                                textButton="Aceptar"
                            />
                        )}
                    </>
                )}

                {Object.keys(error).length > 0 && (
                    <ModalGeneric
                        show={true}
                        img={NotProductSVG}
                        textTitle={'¡Ha ocurrido un error!'}
                        textBody={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleButton={handleClose}
                        handleClose={handleClose}
                        textButton="Aceptar"
                    />
                )}
            </>
        </Wrapper>
    )
}

export default FirstStep
