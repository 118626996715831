import React, { useEffect, useState } from 'react'
import { Form, PageItem, Pagination } from 'react-bootstrap'
import { Loading, ModalGeneric } from '../../../../components'
import { useSelector } from 'react-redux'
import {
    useGetImages,
    useVerifyNamesStorage,
    useVerifyUrlStorage,
} from '../../hooks/useManageContent'
import {
    ButtonPagination,
    ButtonUnstyled,
    ContainerTable,
    ContentInfo,
    PaginationContent,
    SectionTd,
    SectionTh,
} from '../../ManageableContent-styled'
import { CancelWhite, EditWhite, NotProductSVG } from '../../../../utils/getIcons'
import ModalForm from '../ModalForm'
import ModalConfirmation from '../ModalConfirmation/ModalConfirmation'
import ValidateNameImage from '../ValidateNameImage'
import UploadImage from '../UploadImage'

interface ImagesData {
    IdImage: number
    Name: string
    Image: string
    Description: string
    TemplatesNotificationTransactionData: any
    UserIdentification: string | null
    IpAddress: string | null
    Ubication: string
    Header: string | null
    Method: string | null
}

interface DataVerifyUrlStorageProps {
    Url: string
}

interface DataVerifyNamesStorageProps {
    Name: string
    extension: string
}

const TableImages: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const [images, setImages] = useState<ImagesData[]>([])
    const [dataVerifyUrlStorage, setDataVerifyUrlStorage] = useState<DataVerifyUrlStorageProps[]>(
        []
    )
    const [dataVerifyNamesStorage, setDataVerifyNamesStorage] = useState<
        DataVerifyNamesStorageProps[]
    >([])

    const currentItems = images.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(images.length / itemsPerPage)
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    let keyCounter = 0
    const [isEditableImage, setIsEditableImage] = useState<boolean>(false)
    const [editableColumn, setEditableColumn] = useState<string | null>(null)
    const [titleModal, setTitleModal] = useState<string>('')
    const [checkValue, setCheckValue] = useState<number>()

    const [valueImage, setValueImage] = useState<string>('')
    const [idImage, setIdImage] = useState<number>(0)

    const [showModalForm, setShowModalForm] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)

    const [nameImage, setNameImage] = useState<string>('')

    const [messageModal, setMessageModal] = useState<string>('')
    const [showModalValidation, setShowModalValidation] = useState<boolean>(false)
    const [showModalValidateName, setShowModalValidateName] = useState<boolean>(false)

    const [showUploadImage, setShowUploadImage] = useState(false)

    const [fileNameTemp, setFileNameTemp] = useState<string>('')
    const [overwriteImage, setOVerwriteImage] = useState<boolean>(false)

    const UseGetImages = (): void => {
        setLoading(true)
        useGetImages(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setImages(res['data']['Data'])
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const pushDataVerifyUrl = (url: string): void => {
        const newValue = { Url: url }
        setDataVerifyUrlStorage((prevData) => [...prevData, newValue])
        HandleFormSubmit([...dataVerifyUrlStorage, newValue])
        const fileName = url.substring(url.lastIndexOf('/') + 1)
        setFileNameTemp(fileName)
    }

    useEffect(() => {
        UseGetImages()
    }, [])

    const handleClose = (): void => {
        setShowError(false)
    }

    const handledModalInfo = () => {
        setShowModalValidation(false)

        if (nameImage !== '') {
            setShowUploadImage(true)
        } else {
            setShowModalValidateName(true)
        }
    }

    const handleCloseModal = (): void => {
        setShowModalValidation(false)
    }
    const closed = (): void => {
        setShowModalValidateName(false)
    }

    const cancelEditImage = (): void => {
        setIsEditableImage(false)
    }

    const updateRegister = (columnName: string): void => {
        setNameImage('')
        setIsEditableImage(true)
        setEditableColumn(columnName)
        const thElement = document.querySelector(`#${columnName}`)
        if (thElement) {
            const txtTh = thElement.textContent ? thElement.textContent : ''
            setTitleModal(txtTh)
        }
    }

    const handleOnChange = (
        info: ImagesData,
        { target }: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = target
        setCheckValue(parseInt(value))
        setIdImage(info.IdImage)
        setValueImage(info.Image)
        handleShowModalForm()
    }

    const handleShowModalForm = (): void => {
        setShowModalForm(true)
    }

    const handleCloseModalForm = (): void => {
        setShowModalForm(false)
        setValueImage('')
        setCheckValue(0)
    }

    const HandleFormSubmit = (data: DataVerifyUrlStorageProps[]): void => {
        setIsLoadingUpdate(true)
        setIsEditableImage(false)
        useVerifyUrlStorage(tokenSave, data)
            .then((res: any) => {
                setDataVerifyUrlStorage([])
                setIsLoadingUpdate(false)
                if (res['status'] === 200) {
                    const value = res['data']['Data'][0].Value
                    setTitleModal('Confirmación de actualización de imagen')
                    setShowModalValidation(true)
                    if (value) {
                        setMessageModal(
                            'La URL que has ingresado ya existe en el almacenamiento de Azure Blob. ¿Estás seguro de que deseas sobreescribirla?'
                        )
                        setOVerwriteImage(true)
                    } else {
                        setOVerwriteImage(false)
                        setMessageModal(
                            'La URL ingresada no existe en el almacenamiento de Azure Blob. ¿Deseas cargar una imagen y crear la URL?'
                        )
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                handleCloseModalForm()
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setDataVerifyUrlStorage([])
                setErrorMessage('No se ha podido conectar con el servidor')
                handleCloseModalForm()
            })
    }

    const pushDataVerifyName = (name: string, extension: string): void => {
        const newValue = { Name: name, extension: extension }
        const nameFile = name + '.' + extension
        setNameImage(nameFile)
        setDataVerifyNamesStorage((previewData) => [...previewData, newValue])
        if (overwriteImage && nameFile === fileNameTemp) {
            setShowUploadImage(true)
        } else {
            HandleFormSubmitDataVerifyName([...dataVerifyNamesStorage, newValue])
        }
    }

    const HandleFormSubmitDataVerifyName = (data: DataVerifyNamesStorageProps[]): void => {
        setIsLoadingUpdate(true)
        setIsEditableImage(false)
        useVerifyNamesStorage(tokenSave, data)
            .then((res: any) => {
                setDataVerifyNamesStorage([])
                setIsLoadingUpdate(false)
                if (res['status'] === 200) {
                    const value = res['data']['Data'][0].Value
                    setTitleModal('¿Estás seguro de continuar con el proceso?')
                    setShowModalValidation(true)
                    if (value) {
                        setMessageModal(
                            'Hemos validado que el nombre de la imagen que has ingresado ya existe en el almacenamiento de Azure Blob.'
                        )
                    } else {
                        setShowUploadImage(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                closed()
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setDataVerifyNamesStorage([])
                setErrorMessage('No se ha podido conectar con el servidor')
                closed()
            })
    }

    const closeUploadImage = (): void => {
        setShowUploadImage(false)
        setShowModalValidateName(false)
    }

    const confirmModalUploadImage = (): void => {
        UseGetImages()
    }

    return (
        <>
            {!showUploadImage && (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        <ContainerTable striped bordered responsive>
                            <thead>
                                <tr>
                                    <th style={{ maxWidth: '20px' }}>Id Image</th>
                                    <th style={{ maxWidth: '20px' }}>Name</th>
                                    <th id="Image">
                                        <SectionTh showThirdButton={isEditableImage}>
                                            Image
                                            <ButtonUnstyled
                                                variant="primary"
                                                onClick={() => updateRegister('Image')}
                                            >
                                                <img src={EditWhite} alt="IconEdit" width={25} />
                                            </ButtonUnstyled>
                                            {isEditableImage && editableColumn === 'Image' && (
                                                <ButtonUnstyled
                                                    variant="primary"
                                                    onClick={() => cancelEditImage()}
                                                >
                                                    <img
                                                        src={CancelWhite}
                                                        alt="IconEdit"
                                                        width={25}
                                                    />
                                                </ButtonUnstyled>
                                            )}
                                        </SectionTh>
                                    </th>
                                    <th>Description</th>
                                    <th style={{ maxWidth: '72px' }}>
                                        FK templates notification transaction id
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((info: ImagesData) => (
                                    <tr key={info.IdImage}>
                                        <td className="center">{info.IdImage}</td>
                                        <td>{info.Name}</td>
                                        <td>
                                            <SectionTd showIsEditable={isEditableImage}>
                                                {info.Image}
                                                {isEditableImage && editableColumn === 'Image' && (
                                                    <Form.Check
                                                        className="radio-button"
                                                        type="radio"
                                                        value={info.IdImage}
                                                        checked={checkValue === info.IdImage}
                                                        onChange={(e) => handleOnChange(info, e)}
                                                    />
                                                )}
                                            </SectionTd>
                                        </td>
                                        <td>{info.Description}</td>
                                        <td>{info.TemplatesNotificationTransactionData}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </ContainerTable>
                    )}
                    <PaginationContent>
                        <Pagination>
                            {currentPage !== 1 && (
                                <ButtonPagination>
                                    <PageItem
                                        key={`prev_${keyCounter++}`}
                                        onClick={() => handlePageClick(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        {'<'}
                                    </PageItem>
                                </ButtonPagination>
                            )}
                            {[...Array(totalPages)].map((_, page) => (
                                <PageItem
                                    key={page + 1}
                                    active={page + 1 === currentPage}
                                    onClick={() => handlePageClick(page + 1)}
                                >
                                    {page + 1}
                                </PageItem>
                            ))}
                            {currentPage !== totalPages && totalPages > 1 && (
                                <ButtonPagination>
                                    <PageItem
                                        key={`next_${keyCounter++}`}
                                        onClick={() => handlePageClick(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        {'>'}
                                    </PageItem>
                                </ButtonPagination>
                            )}
                        </Pagination>
                        <ContentInfo>
                            {images.length === 0 && showError && (
                                <div style={{ display: 'flex' }}>
                                    <img src={NotProductSVG} alt="IconEdit" width={50} />
                                    <span style={{ alignContent: 'center' }}>
                                        No hay información disponible
                                    </span>
                                </div>
                            )}
                        </ContentInfo>
                    </PaginationContent>

                    <ModalForm
                        showModal={showModalForm}
                        handleCloseModal={handleCloseModalForm}
                        isLoading={isLoadingUpdate}
                        titleText={titleModal}
                        updateOnSubmitted={pushDataVerifyUrl}
                        valueDataChange={valueImage}
                    />

                    <ModalGeneric
                        show={showError}
                        handleClose={handleClose}
                        img={NotProductSVG}
                        textTitle=""
                        textBody={errorMessage}
                        handleButton={handleClose}
                        textButton="Aceptar"
                    />

                    <ModalConfirmation
                        messageInfoModal={messageModal}
                        showModalValidation={showModalValidation}
                        handleCloseModal={handleCloseModal}
                        handledModalInfo={handledModalInfo}
                        titleModal={titleModal}
                    />

                    <ValidateNameImage
                        isLoading={loading}
                        verifyNameStorage={pushDataVerifyName}
                        showModalValidateName={showModalValidateName}
                        closed={closed}
                    />
                </>
            )}
            {showUploadImage && (
                <UploadImage
                    id={idImage}
                    name={nameImage}
                    closed={closeUploadImage}
                    successUpload={confirmModalUploadImage}
                />
            )}
        </>
    )
}

export default TableImages
