import { AxiosResponse } from 'axios'
import {
    certificateService,
    keyChangeService,
    caviFacilTransfer,
} from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetInfoAssociate(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetAgreementCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateIVR(tokenSave: string, body: string): Promise<Result> {
    try {
        return await keyChangeService
            .validateIVR('api/Authorization/ValidIVR', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetValidCaviFacilQuota(tokenSave: string): Promise<Result> {
    try {
        return await caviFacilTransfer
            .getValidCaviFacilQuota(
                '/api/Products/GetValidCaviFacilQuota/GetValidCaviFacilQuota',
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useCavifacilTransaction(tokenSave: string, body: any): Promise<Result> {
    try {
        return await caviFacilTransfer
            .postCavifacilTransaction(
                '/api/Products/CavifacilTransaction/CavifacilTransaction',
                tokenSave,
                body
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
