/* eslint-disable prettier/prettier */
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

// Transverse
import { SidebarStart, SidebarEnd } from '..'

// Images
import { Logo } from '../../utils/getImages'

// Icons
import {
    IconHeadsetSVG,
    IconLogOutSVG,
    PaymentSVG,
    ProductSVG,
    ChartSVG,
    ServiceSVG,
    TransactionPaymentSVG,
    ElipseYellowPNG,
    AdminPanelIcon,
    SupervisorWhite,
} from '../../utils/getIcons'

import eventBus from '../../utils/eventBus';

import { logout } from '../../../redux/actions/loginActions'
import { cleanPayments } from '../../../redux/actions/paymentActions'
import { authenticationService } from '../../../../domain/services/User.service'
import { ThemeMenu } from '../../components'

// custom hooks
import { useAppTour } from '../../hooks'

import './header.scss'
import { parseStringToBoolean } from '../../utils/misc'
import { useEffect, useState } from 'react'
import { useFetchRegulations } from '../../pages/RegulationsContainer/hooks/GetListRegulation'
import { useFetchListPolicies } from '../../pages/Policies/hooks/GetListPolicies'
import { CreditCardsSelector, featureEnableFunctionsSelector } from '../../../selectors'
import { Loading } from '../../components'
import { useLoggedRole } from '../../pages/UserLoginRoleValidator/hooks/useUserLoginRoleValidator'

type ServiciosEnLineaOption = 'Consultas' | 'Servicios' | 'Simuladores' | 'Reglamentos' | 'Polizas'

type TransactionPaymentOption = 'Transferencias' | 'Pagos' | 'Desembolsos'

type PaymentsOption = 'Tarjetas de crédito' | 'Productos de ahorro' | 'Créditos'

type ProductsOption = 'Tarjetas de crédito' | 'Inversiones'

type UserManagementOption = 'Usuarios' | 'Roles' | 'Contenido Administrable'

type UserOperationsOption = 'Operations'

const enableCreditCard = process.env.REACT_APP_CREDIT_CARD_ENABLE as 'false' | 'true'
const enableTDC = process.env.REACT_APP_TDC_ENABLE as 'false' | 'true'
const enableOpeningCreditCard = process.env.REACT_APP_OPENING_CREDIT_CARD_ENABLE as 'false' | 'true'

/* Manejo para apagado de funcionalidades */
// consulta estado de la funcionalidad en el archivo .env
const enableM2GetRealStates = process.env.REACT_APP_M2_GET_REAL_STATES_ENABLE as 'false' | 'true'
const enableM2PublishRealState = process.env.REACT_APP_M2_PUBLISH_REAL_STATE_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionCodebtor = process.env.REACT_APP_ON_OFF_FUNCTION_CODEBTOR as
    | 'false'
    | 'true'
const enableOnOffFunctionMortgages = process.env.REACT_APP_ON_OFF_FUNCTION_MORTGAGES as
    | 'false'
    | 'true'
const enableOnOffFunctionBenefits = process.env.REACT_APP_ON_OFF_FUNCTION_BENEFITS as
    | 'false'
    | 'true'
const enableOnOffFunctionPledge = process.env.REACT_APP_ON_OFF_FUNCTION_PLEDGE as 'false' | 'true'
const enableOnOffFunctionScoresAndQuotas = process.env.REACT_APP_SCORES_AND_QUOTAS_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionPaymentRegistration = process.env.REACT_APP_ON_OFF_PAYMENT_REGISTRATION as
    | 'false'
    | 'true'
const enableOnOffFunctionPaymentCancellation = process.env.REACT_APP_ON_OFF_PAYMENT_CANCELLATION as
    | 'false'
    | 'true'
const enableOnOffFunctionAgreementCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_AGREEMENT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountOpenCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_OPEN_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionBondingCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_BONDING_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionCupsTermsQuotas = process.env
    .REACT_APP_ON_OFF_FUNCTION_CUPS_TERMS_QUOTAS as 'false' | 'true'
const enableOnOffFunctionChangePassword = process.env.REACT_APP_ON_OFF_FUNCTION_CHANGE_PASSWORD as
    | 'false'
    | 'true'
const enableOnOffFunctionEasterBonusRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_EASTER_BONUS_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionChristmasBonusRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_CHRISTMAS_BONUS_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionGeneralRegulationsCatchments = process.env
    .REACT_APP_ON_OFF_FUNCTION_GENERAL_REGULATIONS_CATCHMENTS as 'false' | 'true'
const enableOnOffFunctionPartialReturnPermanentSavings = process.env
    .REACT_APP_ON_OFF_FUNCTION_PARTIAL_RETURN_PERMANENT_SAVINGS as 'false' | 'true'
const enableOnOffFunctionReturnAuthorization = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETURN_AUTHORIZATION as 'false' | 'true'
const enableOnOffFunctionReturnAuthorizationRequest = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETURN_AUTHORIZATION_REQUEST as 'false' | 'true'
const enableOnOffFunctionExclusionRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_EXCLUSION_REGULATIONS as 'false' | 'true'

const enableOnOffFunctionKeyChangeForwardtoForget = process.env
    .REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD_TO_FORGET as 'false' | 'true'
const enableOnOffFunctionTravelMemos = process.env.REACT_APP_ON_OFF_FUNCTION_TRAVEL_MEMOS as
    | 'false'
    | 'true'
const enableOnOffFunctionAdvanceFaiAccount = process.env
    .REACT_APP_ON_OFF_FUNCTION_ADVANCE_FAI_ACCOUNT as 'false' | 'true'
const enableOnOffFunctionCreditRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_CREDIT_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionCreditRegulationsConditions = process.env
    .REACT_APP_ON_OFF_FUNCTION_CREDIT_REGULATIONS_CONDITIONS as 'false' | 'true'
const enableOnOffFunctionRetentionCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETENTION_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionWelfareRegulations = process.env
    .REACT_APP_ON_OFF_FUNCTION_WELFARE_REGULATIONS as 'false' | 'true'
const enableOnOffFunctionBetweenAccountsFAI = process.env
    .REACT_APP_ON_OFF_FUNCTION_BETWEEN_ACCOUNTS_FAI as 'false' | 'true'
const enableOnOffFunctionACHReport = process.env.REACT_APP_ON_OFF_FUNCTION_ACH_REPORT as
    | 'false'
    | 'true'
const enableOnOffFunctionCaviFacil = process.env.REACT_APP_ON_OFF_FUNCTION_CAVIFACIL as
    | 'false'
    | 'true'
const enableOnOffFunctionACH = process.env.REACT_APP_ON_OFF_FUNCTION_ACH as 'false' | 'true'
const enableOnOffFunctionRecreationalTermTitleTar = process.env
    .REACT_APP_ON_OFF_FUNCTION_RECREATIONAL_TERM_TITLE as 'false' | 'true'
const enableOnOffFunctionGetCreditSimulator = process.env
    .REACT_APP_ON_OFF_FUNCTION_GET_CREDIT_SIMULATOR as 'false' | 'true'
const enableOnOffFunctionNonContributoryGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_NON_CONTRIBUTORY_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionCavipetrolDebtorsGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_CAVIPETROL_DOBTORS_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionEcoptrolDebtorsGroupLifePolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_ECOPETROL_DEBTORS_GROUP_LIFE_POLICY as 'false' | 'true'
const enableOnOffFunctionDebtorsFireGroupPolicy = process.env
    .REACT_APP_ON_OFF_FUNCTION_DEBTORS_FIRE_GROUP_POLICY as 'false' | 'true'
const enableOnOffFunctionUpdateAssociate = process.env
    .REACT_APP_ON_OFF_FUNCTION_UPDATE_ASSOCIATE as 'false' | 'true'


const Header = (): JSX.Element => {
    const history = useHistory()

    const state = useSelector((stateRef: any) => stateRef)
    const { setIsOpen: setIsOpenTour, rideStep, isOpen: isOpenTour, currentStep } = useAppTour()

    const dispatch = useDispatch()

    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (functionalityQuery.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            functionalityQuery.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
        }
    }, [functionalityQuery])

    const [urlsByTag, setUrlsByTag] = useState<Record<string, string | null>>({});
    const { data: dataUrlRegulation, fetchRegulations, loadingRegulations, errorRegulations } = useFetchRegulations();

    useEffect(() => {
        if (dataUrlRegulation && dataUrlRegulation.Data) {
            const newUrlsByTag: Record<string, string | null> = {};

            const getUrlByTag = (tag: string): string | null => {
                const matchingItem = dataUrlRegulation.Data.find((item) => item.Tag === tag);
                return matchingItem ? matchingItem.Url : null;
            };

            const tagsToRetrieve = [
                'CreditRegulationsConditions',
                'ReturnAuthorization',
                'ReturnAuthorizationRequest',
                'PartialReturnPermanentSavings',
                'GeneralRegulationsCatchments',
                'EasterBonusRegulations',
                'ChristmasBonusRegulations',
                'CreditRegulations',
                'WelfareRegulations',
                'ExclusionRegulations',
            ];

            tagsToRetrieve.forEach((tag) => {
                newUrlsByTag[tag] = getUrlByTag(tag);
            });

            setUrlsByTag(newUrlsByTag);
        }
    }, [dataUrlRegulation])

    const { data: dataUrlPolicies, fetchListPolicies, loadingPolicies, errorPolicies } = useFetchListPolicies()
    const [urlsByTagPolicies, setUrlsByTagPolicies] = useState<Record<string, string | null>>({});

    useEffect(() => {
        if (dataUrlPolicies && dataUrlPolicies.Data) {
            const newUrlsByTagPolicies: Record<string, string | null> = {};

            const getUrlByTagPolicies = (tag: string): string | null => {
                const matchingItem = dataUrlPolicies.Data.find((item) => item.Tag === tag)
                return matchingItem ? matchingItem.Url : null
            }

            const tagsToRetrievePolicies = [
                'DebtorsFireGroupPolicy',
                'CavipetrolDebtorsGroupLifePolicy',
                'EcoptrolDebtorsGroupLifePolicy',
                'NonContributoryGroupLifePolicy',
            ]

            tagsToRetrievePolicies.forEach((tag) => {
                newUrlsByTagPolicies[tag] = getUrlByTagPolicies(tag)
            })

            setUrlsByTagPolicies(newUrlsByTagPolicies);
        }
    }, [dataUrlPolicies])

    const { loadingRole, loggedInUser } = useLoggedRole()

    const months = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ]
    const dateNow = new Date()
    const nowEntry =
        dateNow.getDate() + ' de ' + months[dateNow.getMonth()] + ' de ' + dateNow.getFullYear()
    let listProducts = []

    if (state.products.products !== undefined) {
        listProducts = state.products.products
    }

    const loggout = async (): Promise<void> => {
        const tokenSave = state.auth.token
        await authenticationService
            .getLogin('/api/Authorization/LogOut', tokenSave)
            .then((response) => {
                if (response) {
                    setIsOpenTour(false)
                    dispatch(logout())
                    dispatch(cleanPayments())
                    // window.location.href = '/login'
                }
            })
    }

    const seePayments = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
        navbar?.classList.remove('not-display')
        contentPayment?.classList.remove('not-display')
        contentProduct?.classList.add('not-display')
        contentService?.classList.add('not-display')
        contentTransactionPayment?.classList.add('not-display')
        textPayment?.classList.add('important-text')
        textProduct?.classList.remove('important-text')
        textService?.classList.remove('important-text')
        textPayment?.classList.remove('vertical-text-header')
        textProduct?.classList.add('vertical-text-header')
        textService?.classList.add('vertical-text-header')
        imgPayment?.classList.add('change-img-payment')
        imgProduct?.classList.remove('change-img-product')
        imgService?.classList.remove('change-img-service')
        imgTransactionPayment?.classList.remove('change-img-transaction-payment')
        textTransactionPayment?.classList.remove('important-text')
        textTransactionPayment?.classList.add('vertical-text-header')

        const textUserManagement = document.querySelector('#text-admin-header')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        contentUserManager?.classList.add('not-display')
        textUserManagement?.classList.remove('important-text')
        textUserManagement?.classList.add('vertical-text-header')
        imgUserManagement?.classList.remove('change-img-admin')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        contentUserManagerCall?.classList.add('not-display')
        textUserManagementCall?.classList.remove('important-text')
        textUserManagementCall?.classList.add('vertical-text-header')
        imgUserManagementCall?.classList.remove('change-img-admin-call')

        rideStep(2)
    }

    const seeProducts = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
        navbar?.classList.remove('not-display')
        contentPayment?.classList.add('not-display')
        contentProduct?.classList.remove('not-display')
        contentService?.classList.add('not-display')
        contentTransactionPayment?.classList.add('not-display')
        textPayment?.classList.remove('important-text')
        textProduct?.classList.add('important-text')
        textService?.classList.remove('important-text')
        textPayment?.classList.add('vertical-text-header')
        textProduct?.classList.remove('vertical-text-header')
        textService?.classList.add('vertical-text-header')
        imgPayment?.classList.remove('change-img-payment')
        imgProduct?.classList.add('change-img-product')
        imgService?.classList.remove('change-img-service')
        imgTransactionPayment?.classList.remove('change-img-transaction-payment')
        textTransactionPayment?.classList.remove('important-text')
        textTransactionPayment?.classList.add('vertical-text-header')

        const textUserManagement = document.querySelector('#text-admin-header')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        contentUserManager?.classList.add('not-display')
        textUserManagement?.classList.remove('important-text')
        textUserManagement?.classList.add('vertical-text-header')
        imgUserManagement?.classList.remove('change-img-admin')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        contentUserManagerCall?.classList.add('not-display')
        textUserManagementCall?.classList.remove('important-text')
        textUserManagementCall?.classList.add('vertical-text-header')
        imgUserManagementCall?.classList.remove('change-img-admin-call')

        rideStep(3)
    }

    const seeServices = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
        navbar?.classList.remove('not-display')
        contentPayment?.classList.add('not-display')
        contentProduct?.classList.add('not-display')
        contentService?.classList.remove('not-display')
        contentTransactionPayment?.classList.add('not-display')
        textPayment?.classList.remove('important-text')
        textProduct?.classList.remove('important-text')
        textService?.classList.add('important-text')
        textPayment?.classList.add('vertical-text-header')
        textProduct?.classList.add('vertical-text-header')
        textService?.classList.remove('vertical-text-header')
        imgPayment?.classList.remove('change-img-payment')
        imgProduct?.classList.remove('change-img-product')
        imgService?.classList.add('change-img-service')
        imgTransactionPayment?.classList.remove('change-img-transaction-payment')
        textTransactionPayment?.classList.remove('important-text')
        textTransactionPayment?.classList.add('vertical-text-header')

        const textUserManagement = document.querySelector('#text-admin-header')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        contentUserManager?.classList.add('not-display')
        textUserManagement?.classList.remove('important-text')
        textUserManagement?.classList.add('vertical-text-header')
        imgUserManagement?.classList.remove('change-img-admin')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        contentUserManagerCall?.classList.add('not-display')
        textUserManagementCall?.classList.remove('important-text')
        textUserManagementCall?.classList.add('vertical-text-header')
        imgUserManagementCall?.classList.remove('change-img-admin-call')

        rideStep(4)
    }

    const seeTransactionPayment = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
        navbar?.classList.remove('not-display')
        contentPayment?.classList.add('not-display')
        contentProduct?.classList.add('not-display')
        contentService?.classList.add('not-display')
        contentTransactionPayment?.classList.remove('not-display')
        textPayment?.classList.remove('important-text')
        textProduct?.classList.remove('important-text')
        textService?.classList.remove('important-text')
        textPayment?.classList.add('vertical-text-header')
        textProduct?.classList.add('vertical-text-header')
        textService?.classList.add('vertical-text-header')
        imgPayment?.classList.remove('change-img-payment')
        imgProduct?.classList.remove('change-img-product')
        imgService?.classList.remove('change-img-service')
        imgTransactionPayment?.classList.add('change-img-transaction-payment')
        textTransactionPayment?.classList.add('important-text')
        textTransactionPayment?.classList.remove('vertical-text-header')

        const textUserManagement = document.querySelector('#text-admin-header')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        contentUserManager?.classList.add('not-display')
        textUserManagement?.classList.remove('important-text')
        textUserManagement?.classList.add('vertical-text-header')
        imgUserManagement?.classList.remove('change-img-admin')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        contentUserManagerCall?.classList.add('not-display')
        textUserManagementCall?.classList.remove('important-text')
        textUserManagementCall?.classList.add('vertical-text-header')
        imgUserManagementCall?.classList.remove('change-img-admin-call')

        rideStep(5)
    }

    const seeUserManager = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
        const textUserManagement = document.querySelector('#text-admin-header')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        navbar?.classList.remove('not-display')
        contentPayment?.classList.add('not-display')
        contentProduct?.classList.add('not-display')
        contentService?.classList.add('not-display')
        contentUserManager?.classList.remove('not-display')
        contentTransactionPayment?.classList.add('not-display')
        textPayment?.classList.remove('important-text')
        textProduct?.classList.remove('important-text')
        textService?.classList.remove('important-text')
        textPayment?.classList.add('vertical-text-header')
        textProduct?.classList.add('vertical-text-header')
        textService?.classList.add('vertical-text-header')
        imgPayment?.classList.remove('change-img-payment')
        imgProduct?.classList.remove('change-img-product')
        imgService?.classList.remove('change-img-service')
        imgTransactionPayment?.classList.remove('change-img-transaction-payment')
        textTransactionPayment?.classList.remove('important-text')
        textTransactionPayment?.classList.add('vertical-text-header')
        textUserManagement?.classList.add('important-text')
        textUserManagement?.classList.remove('vertical-text-header')
        imgUserManagement?.classList.add('change-img-admin')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        contentUserManagerCall?.classList.add('not-display')
        textUserManagementCall?.classList.remove('important-text')
        textUserManagementCall?.classList.add('vertical-text-header')
        imgUserManagementCall?.classList.remove('change-img-admin-call')

        rideStep(6)
    }

    const seeUserManagement = async (): Promise<void> => {
        const navbar = document.querySelector('#theme-header-botton')
        const contentProduct = document.querySelector('#content-header-products')
        const contentPayment = document.querySelector('#content-header-payment')
        const contentService = document.querySelector('#content-header-services')
        const contentTransactionPayment = document.querySelector(
            '#content-header-transaction-payment'
        )
        const textPayment = document.querySelector('#text-pay-header')
        const imgPayment = document.querySelector('#img-pay-header')
        const textProduct = document.querySelector('#text-prod-header')
        const imgProduct = document.querySelector('#img-prod-header')
        const textService = document.querySelector('#text-serv-header')
        const imgService = document.querySelector('#img-serv-header')
        const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
        const textTransactionPayment = document.querySelector('#text-transaction-pay-header')

        navbar?.classList.remove('not-display')
        contentPayment?.classList.add('not-display')
        contentProduct?.classList.add('not-display')
        contentService?.classList.add('not-display')
        contentTransactionPayment?.classList.add('not-display')
        textPayment?.classList.remove('important-text')
        textProduct?.classList.remove('important-text')
        textService?.classList.remove('important-text')
        textPayment?.classList.add('vertical-text-header')
        textProduct?.classList.add('vertical-text-header')
        textService?.classList.add('vertical-text-header')
        imgPayment?.classList.remove('change-img-payment')
        imgProduct?.classList.remove('change-img-product')
        imgService?.classList.remove('change-img-service')
        imgTransactionPayment?.classList.remove('change-img-transaction-payment')
        textTransactionPayment?.classList.remove('important-text')
        textTransactionPayment?.classList.add('vertical-text-header')

        const contentUserManagerCall = document.querySelector('#content-header-user-manager-call')
        const textUserManagementCall = document.querySelector('#text-admin-call-header')
        const imgUserManagementCall = document.querySelector('#img-user-management-call-header')
        textUserManagementCall?.classList.add('important-text')
        textUserManagementCall?.classList.remove('vertical-text-header')
        imgUserManagementCall?.classList.add('change-img-admin-call')
        contentUserManagerCall?.classList.remove('not-display')

        const textUserManagement = document.querySelector('#text-admin-header')
        const contentUserManager = document.querySelector('#content-header-user-manager')
        const imgUserManagement = document.querySelector('#img-user-management-header')
        contentUserManager?.classList.add('not-display')
        textUserManagement?.classList.remove('important-text')
        textUserManagement?.classList.add('vertical-text-header')
        imgUserManagement?.classList.remove('change-img-admin')

        rideStep(7)
    }

    const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault()
        if (!isOpenTour || ![2, 3, 4].includes(currentStep)) {
            const navbar = document.querySelector('#theme-header-botton')
            const textPayment = document.querySelector('#text-pay-header')
            const imgPayment = document.querySelector('#img-pay-header')
            const textProduct = document.querySelector('#text-prod-header')
            const imgProduct = document.querySelector('#img-prod-header')
            const textService = document.querySelector('#text-serv-header')
            const textTransactionPayment = document.querySelector('#text-transaction-pay-header')
            const imgService = document.querySelector('#img-serv-header')
            const imgTransactionPayment = document.querySelector('#img-transaction-pay-header')
            const textUserManagement = document.querySelector('#text-admin-header')
            const imgUserManagement = document.querySelector('#img-user-management-header')
            const textUserManagementCall = document.querySelector('#text-admin-call-header')
            const imgUserManagementCall = document.querySelector('#img-user-management-call-header')

            navbar?.classList.add('not-display')
            textPayment?.classList.remove('important-text')
            textProduct?.classList.remove('important-text')
            textService?.classList.remove('important-text')
            textTransactionPayment?.classList.remove('important-text')
            textPayment?.classList.add('vertical-text-header')
            textProduct?.classList.add('vertical-text-header')
            textService?.classList.add('vertical-text-header')
            textTransactionPayment?.classList.add('vertical-text-header')
            imgPayment?.classList.remove('change-img-payment')
            imgProduct?.classList.remove('change-img-product')
            imgService?.classList.remove('change-img-service')
            imgTransactionPayment?.classList.remove('change-img-transaction-payment')
            textUserManagement?.classList.remove('important-text')
            textUserManagement?.classList.add('vertical-text-header')
            imgUserManagement?.classList.remove('change-img-admin')

            textUserManagementCall?.classList.remove('important-text')
            textUserManagementCall?.classList.add('vertical-text-header')
            imgUserManagementCall?.classList.remove('change-img-admin-call')
            // Do something
        }
    }

    const redirectInput = (): void => {
        const section = '#Aportes'
        history.push({
            pathname: '/savings-group',
            state: section,
        })
    }

    const redirectRecreational = (): void => {
        const section = '#Recreativo'
        history.push('/savings-group', section)
    }

    function intervale(): void {
        const myElement = document.body.contains(document.getElementById('timeWatch'))

        const element = (
            <p> {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
        )

        if (myElement) {
            ReactDOM.render(element, document.getElementById('timeWatch'))
        }
    }
    setInterval(intervale, 60000)
    setTimeout(() => {
        intervale()
    }, 500)

    const redirections = (url: string): void => {
        history.push(url)
    }
    window.addEventListener('themeMode', () => {
        // When local storage changes, dump the list to
        // the console.
        // (localStorage.getItem('themeMode'))
    })

    const tooltip = (
        <Tooltip id="tooltip">
            Debes diligenciar este formulario 8 días antes de salir del país
        </Tooltip>
    )

    //Services Menu
    const [selectedOption, setSelectedOption] = useState<ServiciosEnLineaOption | null>(null)
    const [subMenuOption, setSubMenuOption] = useState<string | null>(null)
    const [submenuClicked, setSubMenuClicked] = useState<boolean>(false)

    const handleOptionClick = (option: ServiciosEnLineaOption): void => {
        setSelectedOption(option)
        setSubMenuOption(null)

        if (option === "Reglamentos") {
            fetchRegulations();
        }

        if (option === "Polizas") {
            fetchListPolicies();
        }


    }
    const handleSubMenuClick = (subOption: string): void => {
        if (subOption === 'Bolsa inmobiliaria' || subOption === 'Créditos' || subOption === 'Ahorros Permanentes') {
            setSubMenuOption(subOption)
            setSubMenuClicked(true)
        } else {
            setSubMenuClicked(false)
        }
    }

    const handleSubMenuHover = (subOption: string): void => {
        if (subOption !== subMenuOption) {
            setSubMenuClicked(false)
        }
        setSubMenuOption(subOption)
    }

    const handleSubMenuLeave = (): void => {
        if (!submenuClicked) {
            setSubMenuOption(null)
        }
    }

    //Transaction Menu
    const [transactionSelectedOption, setTransactionSelectedOption] =
        useState<TransactionPaymentOption | null>(null)

    const handleTransactionOptionClick = (option: TransactionPaymentOption): void => {
        setTransactionSelectedOption(option)
    }

    //Payments Menu
    const [paymentsSelectedOption, setPaymentsSelectedOption] = useState<PaymentsOption | null>(
        null
    )

    const handlePaymentsOptionClick = (option: PaymentsOption): void => {
        setPaymentsSelectedOption(option)
    }

    //Products Menu
    const [productsSelectedOption, setProductsSelectedOption] = useState<ProductsOption | null>(
        null
    )

    const handleProductsOptionClick = (option: ProductsOption): void => {
        setProductsSelectedOption(option)
    }
    //UserManagement Menu
    const [userManagementSelectedOption, setUserManagementSelectedOption] =
        useState<UserManagementOption | null>(null)


    const handleUserManagementOptionClick = (option: UserManagementOption): void => {
        setUserManagementSelectedOption(option)
    }

    //UserOperation Menu
    const [userOperationsSelectedOption, setUserOperationsSelectedOption] =
        useState<UserOperationsOption | null>(null)

    const handleUserOperationsOptionClick = (option: UserOperationsOption): void => {
        setUserOperationsSelectedOption(option)
    }
    /* Precondición: Solicitudes Alertas TC  */
    const { data } = useSelector(CreditCardsSelector)
    const [isCreditCardHolder, setIsCreditCardHolder] = useState<boolean>(false)

    useEffect(() => {
        if (data !== undefined && data?.cards !== undefined) {
            if (data?.cards?.length > 0) {
                setIsCreditCardHolder(true)
            }
        } else {
            setIsCreditCardHolder(false)
        }
    }, [data, data?.cards])

    const onPaymentCard = (): void => {
        const section = '#section-cards'
        history.push('/home-wallet', section)
        setTimeout(() => {
            eventBus.emit('scrollToDestination', { id: 'section-cards' });
        }, 500)

    };

    return (
        <>
            {loadingRole ? (
                <Navbar className="theme-header2">
                    <Container className="content-header">
                    </Container>
                </Navbar>
            ) : (<>
                <Navbar className="theme-header2">
                    <Container className="content-header">
                        <SidebarStart />
                        <img
                            alt=""
                            src={Logo}
                            className="logo-header"
                            onClick={() => redirections('/home-wallet')}
                        />
                        <div className="only-mobile">
                            <SidebarEnd />
                        </div>

                        <div className="content-header-desk only-desk">
                            <a
                                className="options-top border-pipe-white"
                                onClick={() => redirections('/support-private')}
                                data-tour="support"
                            >
                                <img
                                    src={IconHeadsetSVG}
                                    alt="Servicio al asociado"
                                    className="options-img"
                                />
                                <p>Servicio al asociado</p>
                            </a>
                            <a className="options-top" onClick={loggout}>
                                <img src={IconLogOutSVG} alt="Cerrar sesión" className="options-img" />
                                <p>Cerrar sesión</p>
                            </a>
                            <ThemeMenu />
                        </div>
                    </Container>
                </Navbar>
                <div id="content-second-section" className="header-second-content">
                    <Navbar className="theme-header3">
                        <Container className="content-header-center">
                            <div className="content-welcome">
                                {state.auth.gender === 'Hombre' ? (
                                    <p className="text-welcome-header">
                                        Bienvenido,&nbsp;
                                        <strong className="text-name-header">{state.auth.name}</strong>
                                    </p>
                                ) : (
                                    <p className="text-welcome-header">
                                        Bienvenida,&nbsp;
                                        <strong className="text-name-header">{state.auth.name}</strong>
                                    </p>
                                )}
                                {state.auth.lastconection === '' ? (
                                    <div className="lastEntry-header"></div>
                                ) : (
                                    <div className="lastEntry-header">
                                        <p>Último ingreso fue el:&nbsp;</p>
                                        <p>{state.auth.lastconection}</p>
                                    </div>
                                )}
                                <div className="lastEntry-header color-domain">
                                    <p>{nowEntry},&nbsp;</p>
                                    <div id="timeWatch" className="text-date-header color-domain"></div>
                                </div>
                            </div>
                            <div className="content-header-options" data-tour="menu-options">
                                {(loggedInUser && (loggedInUser.data === "Administrador" || loggedInUser.data === 'ADMCall')) &&
                                    <a
                                        className="vertical-option-header"
                                        onClick={seeUserManagement}
                                        data-tour="admin-opt"
                                    >
                                        <img
                                            id="img-user-management-call-header"
                                            src={SupervisorWhite}
                                            alt="Manejo Usuarios"
                                            className="options-img"
                                            style={{ width: '26px' }}
                                        />
                                        <p className="vertical-text-header" id="text-admin-call-header">
                                            Manejo Usuarios
                                        </p>
                                    </a>
                                }
                                {(loggedInUser && (loggedInUser.data === "Administrador") &&
                                    <a
                                        className="vertical-option-header"
                                        onClick={seeUserManager}
                                        data-tour="admin-opt"
                                    >
                                        <img
                                            id="img-user-management-header"
                                            src={AdminPanelIcon}
                                            alt="Administración"
                                            className="options-img"
                                            style={{ width: '26px' }}
                                        />
                                        <p className="vertical-text-header" id="text-admin-header">
                                            Administración
                                        </p>
                                    </a>
                                )}
                                {(loggedInUser && (loggedInUser.data !== "ADMCall")) && (
                                    <a
                                        className="vertical-option-header"
                                        onClick={seeServices}
                                        data-tour="service-opt"
                                    >
                                        <img
                                            id="img-serv-header"
                                            src={ServiceSVG}
                                            alt="Servicios en Línea"
                                            className="options-img"
                                            style={{ width: '26px', height: '20px' }}
                                        />
                                        <p className="vertical-text-header" id="text-serv-header">
                                            Servicios en Línea
                                        </p>
                                    </a>
                                )}
                                {(loggedInUser && (loggedInUser.data === 'Administrador' || loggedInUser.data === 'Asociado')) && (
                                    <a
                                        className="vertical-option-header2"
                                        onClick={seeTransactionPayment}
                                        data-tour="payment-transaction-opt"
                                    >
                                        <img
                                            id="img-transaction-pay-header"
                                            src={TransactionPaymentSVG}
                                            alt="Transacciones y Pagos"
                                            className="options-img"
                                            style={{ width: '26px' }}
                                        />
                                        <p
                                            className="vertical-text-header"
                                            id="text-transaction-pay-header"
                                        >
                                            Transacciones
                                        </p>
                                    </a>
                                )}
                                {(loggedInUser && (loggedInUser.data !== "ADMCall")) && (
                                    <a
                                        className="vertical-option-header"
                                        onClick={seePayments}
                                        data-tour="payments-opt"
                                    >
                                        <img
                                            id="img-pay-header"
                                            src={PaymentSVG}
                                            alt="Pagos"
                                            className="options-img"
                                        />
                                        <p className="vertical-text-header" id="text-pay-header">
                                            Pagos
                                        </p>
                                    </a>)}
                                {parseStringToBoolean(enableTDC) && (loggedInUser && (loggedInUser.data === 'Administrador' || loggedInUser.data === 'Asociado')) && (
                                    <a
                                        className="vertical-option-header"
                                        onClick={seeProducts}
                                        data-tour="products-opt"
                                    >
                                        <img
                                            id="img-prod-header"
                                            src={ProductSVG}
                                            alt="Productos"
                                            className="options-img"
                                        />
                                        <p className="vertical-text-header" id="text-prod-header">
                                            Productos
                                        </p>
                                    </a>
                                )}
                            </div>
                            {(loggedInUser && (loggedInUser.data !== "ADMCall")) && (
                                <div className="content-wallet-header" data-tour="wallet-menu">
                                    <div className="content-text-wallet">
                                        <p>Pagos</p>
                                        {listProducts.length == 1 ? (
                                            <p>
                                                <strong>1</strong>&nbsp;concepto
                                            </p>
                                        ) : (
                                            <p>
                                                <strong>{listProducts.length}</strong>&nbsp;conceptos
                                            </p>
                                        )}
                                    </div>
                                    <div className="content-img-wallet">
                                        <SidebarEnd />
                                    </div>
                                </div>)}
                        </Container>
                    </Navbar>
                    <Navbar
                        id="theme-header-botton"
                        className="theme-header-botton not-display"
                        onMouseLeave={handleMouseEvent}
                        data-tour="nav-menu-content"
                    >
                        <Container className="content-header-botton">
                            {/* PAGOS */}
                            <div
                                id="content-header-payment"
                                className="content-header-payment not-display"
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '200px', borderRight: '1px solid #ccc' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            {(loggedInUser && (loggedInUser.data === 'Administrador' || loggedInUser.data === 'Asociado')) && (<>
                                                {parseStringToBoolean(enableCreditCard) && (
                                                    <li
                                                        onClick={() =>
                                                            handlePaymentsOptionClick('Tarjetas de crédito')
                                                        }
                                                    >
                                                        <div className="title-header-botton">
                                                            <img
                                                                alt="Tarjetas de crédito"
                                                                className="options-img img-option-payment img-header-botton"
                                                            />
                                                            <p className={paymentsSelectedOption === 'Tarjetas de crédito' ? 'active' : ''}>Tarjetas de crédito</p>
                                                        </div>
                                                    </li>
                                                )}
                                                <li
                                                    onClick={() =>
                                                        handlePaymentsOptionClick('Productos de ahorro')
                                                    }
                                                >
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Productos de ahorro"
                                                            className="options-img img-option-product img-header-botton"
                                                        />
                                                        <p className={paymentsSelectedOption === 'Productos de ahorro' ? 'active' : ''}>Productos de ahorro</p>
                                                    </div>
                                                </li>
                                            </>)}
                                            <li onClick={() => handlePaymentsOptionClick('Créditos')}>
                                                <div className="title-header-botton">
                                                    <img
                                                        alt="Créditos"
                                                        className="options-img img-option-credit img-header-botton"
                                                    />
                                                    <p className={paymentsSelectedOption === 'Créditos' ? 'active' : ''}>Créditos</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        {paymentsSelectedOption && (
                                            <div>
                                                {paymentsSelectedOption === 'Tarjetas de crédito' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a onClick={onPaymentCard}>Pagar tarjeta crédito</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {paymentsSelectedOption === 'Productos de ahorro' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a onClick={redirectInput}>Pagar aportes</a>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a onClick={redirectRecreational}>
                                                                    Pagar ahorro recreativo
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {paymentsSelectedOption === 'Créditos' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a
                                                                    onClick={() =>
                                                                        redirections('/credits-group')
                                                                    }
                                                                >
                                                                    Pagar créditos
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* PRODUCTOS */}
                            <div
                                id="content-header-products"
                                className="content-header-products not-display"
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '200px', borderRight: '1px solid #ccc' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            {parseStringToBoolean(enableOpeningCreditCard) && (
                                                <li
                                                    onClick={() =>
                                                        handleProductsOptionClick('Tarjetas de crédito')
                                                    }
                                                >
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Tarjetas de crédito"
                                                            className="options-img img-option-payment img-header-botton"
                                                        />
                                                        <p className={productsSelectedOption === 'Tarjetas de crédito' ? 'active' : ''}>Tarjetas de crédito</p>
                                                    </div>
                                                </li>
                                            )}
                                            <li
                                                onClick={() => handleProductsOptionClick('Inversiones')}
                                            >
                                                <div className="title-header-botton">
                                                    <img
                                                        src={ChartSVG}
                                                        alt="Inversiones"
                                                        className="img-header-botton icon-chart-header"
                                                    />
                                                    <p className={productsSelectedOption === 'Inversiones' ? 'active' : ''}>Inversiones</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        {productsSelectedOption && (
                                            <div>
                                                {productsSelectedOption === 'Tarjetas de crédito' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton two-columns">
                                                            {isCreditCardHolder && (
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a
                                                                        onClick={() =>
                                                                            redirections('/product-opening')
                                                                        }
                                                                    >
                                                                        Solicitar tarjeta
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionPaymentCancellation
                                                            ) &&
                                                                enableFuntions.OnOffFunctionPaymentCancellation && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/payment-schedule-cancellation">
                                                                            Cancelar pago automático
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionPaymentRegistration
                                                            ) &&
                                                                enableFuntions.OnOffFunctionPaymentRegistration && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/payment-schedule-registration">
                                                                            Programar pago automático
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {/* <div>
                                                            <img
                                                                className="img-option"
                                                                src={ElipseYellowPNG}
                                                                alt=""
                                                            />
                                                            <a>Activar tarjetas</a>
                                                        </div> */}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionKeyChangeForwardtoForget
                                                            ) &&
                                                                enableFuntions.OnOffFunctionKeyChangeForwardtoForget && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/reset-key-advances">
                                                                            Olvidé mi clave para avances
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionTravelMemos
                                                            ) &&
                                                                enableFuntions.OnOffFunctionTravelMemos && (
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={tooltip}
                                                                    >
                                                                        <div>
                                                                            <img
                                                                                className="img-option"
                                                                                src={ElipseYellowPNG}
                                                                                alt=""
                                                                            />
                                                                            <a
                                                                                onClick={() =>
                                                                                    redirections(
                                                                                        '/activate-international-card'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Activar tarjeta fuera de
                                                                                Colombia
                                                                            </a>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionAdvanceFaiAccount
                                                            ) &&
                                                                enableFuntions.OnOffFunctionAdvanceFaiAccount && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/advance-to-fai-account">
                                                                            Avance a Tu Cuenta FAI
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {productsSelectedOption === 'Inversiones' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a onClick={redirectInput}>Pagar aportes</a>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    className="img-option"
                                                                    src={ElipseYellowPNG}
                                                                    alt=""
                                                                />
                                                                <a
                                                                    onClick={() =>
                                                                        redirections('/product-opening')
                                                                    }
                                                                >
                                                                    Abre un TDC
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* SERVICIOS EN LINEA */}
                            <div
                                id="content-header-services"
                                className="content-header-services not-display"
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '150px', borderRight: '1px solid #ccc' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            {(enableFuntions.OnOffFunctionMortgageClient || enableFuntions.OnOffFunctionCodebtor || enableFuntions.OnOffFunctionBenefits || enableFuntions.OnOffFunctionSeveranceQuote || enableFuntions.OnOffFunctionScoresAndQuotas || enableFuntions.OnOffFunctionBalancesStatements || enableFuntions.OnOffFunctionTermQuotaRate || enableFuntions.OnOffFunctionRetentionCertificate || enableFuntions.OnOffFunctionAgreementCertificate || enableFuntions.OnOffFunctionFaiAccountCertificate || enableFuntions.OnOffFunctionBondingCertificate || enableFuntions.OnOffFunctionFaiAcountOpenCertificate) && (
                                                <li onClick={() => handleOptionClick('Consultas')} >
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="consultas"
                                                            className="options-img img-question img-header-botton"
                                                        />
                                                        <p className={selectedOption === 'Consultas' ? 'active' : ''}>Consultas</p>
                                                    </div>
                                                </li>)}
                                            {(enableFuntions.OnOffFunctionM2PublishRealState || enableFuntions.OnOffFunctionM2GetRealStates || enableFuntions.OnOffFunctionUpdateAssociate || enableFuntions.OnOffFunctionChangePassword) && (
                                                <li onClick={() => handleOptionClick('Servicios')}>
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Servicios"
                                                            className="options-img img-services-hands img-header-botton"
                                                        />
                                                        <p className={selectedOption === 'Servicios' ? 'active' : ''}>Servicios</p>
                                                    </div>
                                                </li>
                                            )}

                                            {(enableFuntions.OnOffFunctionRecreationalTermTitleTar || enableFuntions.OnOffFunctionGetCreditSimulator) && (
                                                <li onClick={() => handleOptionClick('Simuladores')}>
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Créditos"
                                                            className="options-img img-option-simulator img-header-botton"
                                                        />
                                                        <p className={selectedOption === 'Simuladores' ? 'active' : ''}>Simuladores</p>
                                                    </div>
                                                </li>
                                            )}
                                            {(enableFuntions.OnOffFunctionExclusionRegulations || enableFuntions.OnOffFunctionEasterBonusRegulations || enableFuntions.OnOffFunctionChristmasBonusRegulations || enableFuntions.OnOffFunctionWelfareRegulations || enableFuntions.OnOffFunctionGeneralRegulationsCatchments) && (
                                                <li onClick={() => handleOptionClick('Reglamentos')}>
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Servicios"
                                                            className="options-img img-regulations-hand img-header-botton"
                                                        />
                                                        <p className={selectedOption === 'Reglamentos' ? 'active' : ''}>Reglamentos</p>
                                                    </div>
                                                </li>
                                            )}
                                            {(enableFuntions.OnOffFunctionNonContributoryGroupLifePolicy || enableFuntions.OnOffFunctionDebtorsFireGroupPolicy || enableFuntions.OnOffFunctionCavipetrolDebtorsGroupLifePolicy || enableFuntions.OnOffFunctionEcoptrolDebtorsGroupLifePolicy) && (
                                                <li onClick={() => handleOptionClick('Polizas')}>
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Servicios"
                                                            className="options-img img-regulations-hand img-header-botton"
                                                        />
                                                        <p className={selectedOption === 'Polizas' ? 'active' : ''}>Polizas</p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div style={{ marginRight: '70px' }}>
                                        {selectedOption && (
                                            <div>
                                                {selectedOption === 'Consultas' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton three-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionMortgages
                                                            ) &&
                                                                enableFuntions.OnOffFunctionMortgageClient && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections('/mortgages')
                                                                            }
                                                                        >
                                                                            Hipotecas
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCodebtor
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCodebtor && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections('/codebtors')
                                                                            }
                                                                        >
                                                                            Codeudores
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionBenefits
                                                            ) &&
                                                                enableFuntions.OnOffFunctionBenefits && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections('/benefits')
                                                                            }
                                                                        >
                                                                            Beneficios
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionPledge
                                                            ) &&
                                                                enableFuntions.OnOffFunctionSeveranceQuote && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections('/pledge')
                                                                            }
                                                                        >
                                                                            Cuota por pignoración de
                                                                            cesantias
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionScoresAndQuotas
                                                            ) &&
                                                                enableFuntions.OnOffFunctionScoresAndQuotas && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections('/scorequotas')
                                                                            }
                                                                        >
                                                                            Puntajes y Cupos
                                                                        </a>
                                                                    </div>
                                                                )}


                                                            {
                                                                enableFuntions.OnOffFunctionBalancesStatements && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections(`/balances-extracts`)
                                                                            }
                                                                        >
                                                                            Saldos y extractos
                                                                        </a>
                                                                    </div>)}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCupsTermsQuotas
                                                            ) &&
                                                                enableFuntions.OnOffFunctionTermQuotaRate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections(
                                                                                    '/cups-terms-quotas'
                                                                                )
                                                                            }
                                                                        >
                                                                            Tasas, plazos y cupos
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionRetentionCertificate
                                                            ) &&
                                                                enableFuntions.OnOffFunctionRetentionCertificate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            onClick={() =>
                                                                                redirections(
                                                                                    '/Certificate-retention'
                                                                                )
                                                                            }
                                                                        >
                                                                            Certificado Retención
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionAgreementCertificate
                                                            ) &&
                                                                enableFuntions.OnOffFunctionAgreementCertificate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/certification-for-agreements">
                                                                            Certificado Convenios
                                                                        </a>
                                                                    </div>
                                                                )}

                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionFaiAccountCertificate
                                                            ) &&
                                                                enableFuntions.OnOffFunctionFaiAccountCertificate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/certification-fai">
                                                                            Certificado FAI
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionBondingCertificate
                                                            ) &&
                                                                enableFuntions.OnOffFunctionBondingCertificate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/certification-for-affiliation">
                                                                            Certificado de Vinculación
                                                                        </a>
                                                                    </div>
                                                                )}

                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionFaiAccountOpenCertificate
                                                            ) &&
                                                                enableFuntions.OnOffFunctionFaiAcountOpenCertificate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/certification-fai-account-opening">
                                                                            Certificado Apertura FAI
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedOption === 'Servicios' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {(enableFuntions.OnOffFunctionM2PublishRealState || enableFuntions.OnOffFunctionM2GetRealStates) && (
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a
                                                                        onMouseEnter={() => handleSubMenuHover('Bolsa inmobiliaria')}
                                                                        onMouseLeave={() => {
                                                                            if (!submenuClicked) {
                                                                                handleSubMenuLeave()
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            setSubMenuClicked(true);
                                                                            handleSubMenuClick('Bolsa inmobiliaria')
                                                                        }}
                                                                        className={subMenuOption === 'Bolsa inmobiliaria' ? 'active' : ''}
                                                                    >
                                                                        Bolsa virtual inmobiliaria
                                                                    </a>
                                                                </div>
                                                            )}

                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionChangePassword
                                                            ) &&
                                                                enableFuntions.OnOffFunctionChangePassword && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/key-change">
                                                                            Cambio de clave
                                                                        </a>
                                                                    </div>
                                                                )}


                                                            {parseStringToBoolean(enableOnOffFunctionUpdateAssociate) &&
                                                                enableFuntions.OnOffFunctionUpdateAssociate && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/data-update">
                                                                            Actualización de datos
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedOption === 'Simuladores' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionGetCreditSimulator
                                                            ) &&
                                                                enableFuntions.OnOffFunctionGetCreditSimulator && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/calculate-credit">
                                                                            Créditos
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionRecreationalTermTitleTar
                                                            ) &&
                                                                enableFuntions.OnOffFunctionRecreationalTermTitleTar && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/recreational-termtitle">
                                                                            Título a termino recreativo
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedOption === 'Reglamentos' && (
                                                    <>
                                                        {loadingRegulations && <p><Loading text="Aguarda un momento" /></p>}
                                                        {errorRegulations && <p>{errorRegulations}</p>}
                                                        {!errorRegulations && Object.keys(urlsByTag).length > 0 && !loadingRegulations && (
                                                            <div className="options-header-botton">
                                                                <div className="links-header-botton two-columns">
                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionExclusionRegulations
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionExclusionRegulations && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTag[
                                                                                        'ExclusionRegulations'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Exclusión
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    {(enableFuntions.OnOffFunctionPartialReturnPermanentSavings || enableFuntions.OnOffFunctionReturnAuthorization || enableFuntions.OnOffFunctionReturnAuthorizationRequest) && (
                                                                        <div>
                                                                            <img
                                                                                className="img-option"
                                                                                src={ElipseYellowPNG}
                                                                                alt=""
                                                                            />
                                                                            <a
                                                                                onMouseEnter={() => handleSubMenuHover('Ahorros Permanentes')}
                                                                                onMouseLeave={() => {
                                                                                    if (!submenuClicked) {
                                                                                        handleSubMenuLeave()
                                                                                    }
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSubMenuClicked(true);
                                                                                    handleSubMenuClick('Ahorros Permanentes')
                                                                                }}
                                                                                className={subMenuOption === 'Ahorros Permanentes' ? 'active' : ''}
                                                                            >
                                                                                Ahorros Permanentes
                                                                            </a>
                                                                        </div>
                                                                    )}

                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionEasterBonusRegulations
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionEasterBonusRegulations && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTag[
                                                                                        'EasterBonusRegulations'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Bono de Pascua
                                                                                </a>
                                                                            </div>
                                                                        )}

                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionChristmasBonusRegulations
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionChristmasBonusRegulations && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTag[
                                                                                        'ChristmasBonusRegulations'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Bono de Navidad
                                                                                </a>
                                                                            </div>
                                                                        )}

                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionWelfareRegulations
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionWelfareRegulations && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTag[
                                                                                        'WelfareRegulations'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    Reglamento Bienestar
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    {(enableFuntions.OnOffFunctionCreditRegulations || enableFuntions.OnOffFunctionCreditRegulationsConditions) && (
                                                                        <div>
                                                                            <img
                                                                                className="img-option"
                                                                                src={ElipseYellowPNG}
                                                                                alt=""
                                                                            />
                                                                            <a
                                                                                onMouseEnter={() => handleSubMenuHover('Créditos')}
                                                                                onMouseLeave={() => {
                                                                                    if (!submenuClicked) {
                                                                                        handleSubMenuLeave()
                                                                                    }
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSubMenuClicked(true);
                                                                                    handleSubMenuClick('Créditos')
                                                                                }}
                                                                                className={subMenuOption === 'Créditos' ? 'active' : ''}
                                                                            >
                                                                                Créditos
                                                                            </a>
                                                                        </div>
                                                                    )}

                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionGeneralRegulationsCatchments
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionGeneralRegulationsCatchments && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTag[
                                                                                        'GeneralRegulationsCatchments'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Reglamento General de
                                                                                    Captaciones
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {selectedOption === 'Polizas' && (
                                                    <>
                                                        {loadingPolicies && <p><Loading text="Aguarda un momento" /></p>}
                                                        {errorPolicies && <p>{errorPolicies}</p>}
                                                        {!errorPolicies && Object.keys(urlsByTagPolicies).length > 0 && !loadingPolicies && (
                                                            <div className="options-header-botton">
                                                                <div className="links-header-botton one-columns">
                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionNonContributoryGroupLifePolicy
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionNonContributoryGroupLifePolicy && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTagPolicies[
                                                                                        'NonContributoryGroupLifePolicy'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Póliza Vida Aportes
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionDebtorsFireGroupPolicy
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionDebtorsFireGroupPolicy && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTagPolicies[
                                                                                        'DebtorsFireGroupPolicy'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Póliza Incendio deudores
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionCavipetrolDebtorsGroupLifePolicy
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionCavipetrolDebtorsGroupLifePolicy && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTagPolicies[
                                                                                        'CavipetrolDebtorsGroupLifePolicy'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Póliza Vida Grupo Deudores
                                                                                    Cavipetrol
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    {parseStringToBoolean(
                                                                        enableOnOffFunctionEcoptrolDebtorsGroupLifePolicy
                                                                    ) &&
                                                                        enableFuntions.OnOffFunctionEcoptrolDebtorsGroupLifePolicy && (
                                                                            <div>
                                                                                <img
                                                                                    className="img-option"
                                                                                    src={ElipseYellowPNG}
                                                                                    alt=""
                                                                                />
                                                                                <a
                                                                                    href={
                                                                                        urlsByTagPolicies[
                                                                                        'EcoptrolDebtorsGroupLifePolicy'
                                                                                        ] ?? '/not-found'
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    Póliza Vida Grupo Deudores
                                                                                    Ecopetrol

                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {selectedOption && (
                                            <div style={{ borderLeft: '1px solid #ccc' }}>
                                                {subMenuOption === 'Bolsa inmobiliaria' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {/* Validación de estado de las variables para apagar la funcionalidad en el header */}
                                                            {parseStringToBoolean(
                                                                enableM2PublishRealState
                                                            ) &&
                                                                enableFuntions.OnOffFunctionM2PublishRealState && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/possessions-insert">
                                                                            Insertar Inmueble
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableM2GetRealStates
                                                            ) &&
                                                                enableFuntions.OnOffFunctionM2GetRealStates && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/possessions-consult">
                                                                            Consultar Inmueble
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {subMenuOption === 'Créditos' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCreditRegulations
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCreditRegulations && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            href={
                                                                                urlsByTag[
                                                                                'CreditRegulations'
                                                                                ] ?? '/not-found'
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Crédito
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCreditRegulationsConditions
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCreditRegulationsConditions && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            href={
                                                                                urlsByTag[
                                                                                'CreditRegulationsConditions'
                                                                                ] ?? '/not-found'
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Condiciones Línea Crédito
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {subMenuOption === 'Ahorros Permanentes' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionPartialReturnPermanentSavings
                                                            ) &&
                                                                enableFuntions.OnOffFunctionPartialReturnPermanentSavings && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            href={
                                                                                urlsByTag[
                                                                                'PartialReturnPermanentSavings'
                                                                                ] ?? '/not-found'
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Devolución Parcial de Ahorros
                                                                            Permanentes
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionReturnAuthorization
                                                            ) &&
                                                                enableFuntions.OnOffFunctionReturnAuthorization && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            href={
                                                                                urlsByTag[
                                                                                'ReturnAuthorization'
                                                                                ] ?? '/not-found'
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Autorización para Solicitud de
                                                                            Cruce y/o <br /> Devolución de ahorros
                                                                            permanentes
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionReturnAuthorizationRequest
                                                            ) &&
                                                                enableFuntions.OnOffFunctionReturnAuthorizationRequest && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a
                                                                            href={
                                                                                urlsByTag[
                                                                                'ReturnAuthorizationRequest'
                                                                                ] ?? '/not-found'
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Solicitud y Autorización de
                                                                            Cruce y/o <br /> Devolución de
                                                                            Ahorros Permanentes
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* TRANSACCIONES Y PAGOS */}
                            <div
                                id="content-header-transaction-payment"
                                className="content-header-transaction-payment not-display"
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '160px', borderRight: '1px solid #ccc' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            {enableFuntions.OnOffFunctionBetweenAccountsFAI && (
                                                <li
                                                    onClick={() =>
                                                        handleTransactionOptionClick('Transferencias')
                                                    }
                                                >
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Transferencias"
                                                            className="options-img img-option-transaction img-header-botton"
                                                        />
                                                        <p className={transactionSelectedOption === 'Transferencias' ? 'active' : ''}>Transferencias</p>
                                                    </div>
                                                </li>
                                            )}
                                            {(enableFuntions.OnOffFunctionACH || enableFuntions.OnOffFunctionACHReport) && (
                                                <li onClick={() => handleTransactionOptionClick('Pagos')}>
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Pagos"
                                                            className="options-img img-option-pay img-header-botton"
                                                        />
                                                        <p className={transactionSelectedOption === 'Pagos' ? 'active' : ''}>Pagos</p>
                                                    </div>
                                                </li>
                                            )}
                                            {enableFuntions.OnOffFunctionCaviFacil && (
                                                <li
                                                    onClick={() =>
                                                        handleTransactionOptionClick('Desembolsos')
                                                    }
                                                >
                                                    <div className="title-header-botton">
                                                        <img
                                                            alt="Desembolsos"
                                                            className="options-img img-option-outlay  img-header-botton"
                                                        />
                                                        <p className={transactionSelectedOption === 'Desembolsos' ? 'active' : ''}>Desembolsos</p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div>
                                        {transactionSelectedOption && (
                                            <div>
                                                {transactionSelectedOption === 'Transferencias' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionBetweenAccountsFAI
                                                            ) &&
                                                                enableFuntions.OnOffFunctionBetweenAccountsFAI && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/fai-account-transfer">
                                                                            Entre cuentas FAI
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {transactionSelectedOption === 'Pagos' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionACH
                                                            ) &&
                                                                enableFuntions.OnOffFunctionACH && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/interbankTransfer-ACH">
                                                                            Transferencias interbancarias
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionACHReport
                                                            ) &&
                                                                enableFuntions.OnOffFunctionACHReport && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/reportACH">
                                                                            Reporte de transferencias interbancarias
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                                {transactionSelectedOption === 'Desembolsos' && (
                                                    <div className="options-header-botton">
                                                        <div className="links-header-botton one-columns">
                                                            {parseStringToBoolean(
                                                                enableOnOffFunctionCaviFacil
                                                            ) &&
                                                                enableFuntions.OnOffFunctionCaviFacil && (
                                                                    <div>
                                                                        <img
                                                                            className="img-option"
                                                                            src={ElipseYellowPNG}
                                                                            alt=""
                                                                        />
                                                                        <a href="/cavifacil-transaction">
                                                                            CaviFácil
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Administración */}
                            {loggedInUser.data === "Administrador" &&
                                <div
                                    id="content-header-user-manager"
                                    className="content-header-user-manager not-display"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '200px', borderRight: '1px solid #ccc' }}>
                                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                                <li onClick={() =>
                                                    handleUserManagementOptionClick('Usuarios')
                                                }>
                                                    <div className="title-header-botton">
                                                        <p className={userManagementSelectedOption === 'Usuarios' ? 'active' : ''}>Usuarios</p>
                                                    </div>
                                                </li>
                                                <li onClick={() =>
                                                    handleUserManagementOptionClick('Roles')
                                                }>
                                                    <div className="title-header-botton">
                                                        <p className={userManagementSelectedOption === 'Roles' ? 'active' : ''}>Roles</p>
                                                    </div>
                                                </li>
                                                <li onClick={() =>
                                                    handleUserManagementOptionClick('Contenido Administrable')
                                                }>
                                                    <div className="title-header-botton">
                                                        <p style={{ wordWrap: 'break-word' }} className={userManagementSelectedOption === 'Contenido Administrable' ? 'active' : ''} >Contenido Administrable</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            {userManagementSelectedOption && (
                                                <div>
                                                    {userManagementSelectedOption === 'Usuarios' && (
                                                        <div className="options-header-botton">
                                                            <div className="links-header-botton one-columns">
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a href="/userManagement">
                                                                        Usuarios
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {userManagementSelectedOption === 'Roles' && (
                                                        <div className="options-header-botton">
                                                            <div className="links-header-botton one-columns">
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a href="/rolManagement">
                                                                        Roles
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {userManagementSelectedOption === 'Contenido Administrable' && (
                                                        <div className="options-header-botton">
                                                            <div className="links-header-botton one-columns">
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a href="/manageableContent">
                                                                        Contenido Administrable
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Administración */}
                            {(loggedInUser.data === "Administrador" || loggedInUser.data === "ADMCall") &&
                                <div
                                    id="content-header-user-manager-call"
                                    className="content-header-user-manager-call not-display"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '150px', borderRight: '1px solid #ccc' }}>
                                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                                <li onClick={() =>
                                                    handleUserOperationsOptionClick('Operations')
                                                }>
                                                    <div className="title-header-botton">
                                                        <p className={userOperationsSelectedOption === 'Operations' ? 'active' : ''}>Usuarios</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            {userOperationsSelectedOption && (
                                                <div>
                                                    {userOperationsSelectedOption === 'Operations' && (
                                                        <div className="options-header-botton">
                                                            <div className="links-header-botton one-columns">
                                                                <div>
                                                                    <img
                                                                        className="img-option"
                                                                        src={ElipseYellowPNG}
                                                                        alt=""
                                                                    />
                                                                    <a href="/userOperations">
                                                                        Usuarios
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </Container>
                    </Navbar>
                </div>
            </>)}
        </>

    )
}

export default Header
