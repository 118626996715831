import { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchFilesRegulations } from './useFilesRegulations'

interface DataItem {
    Name: string
    Url: string
    Tag: string
}

interface DataResponse {
    Data: DataItem[]
}

export const useFetchRegulations = () => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [data, setData] = useState<DataResponse>({ Data: [] })
    const [loadingRegulations, setLoading] = useState<boolean>(false)
    const [errorRegulations, setErrorRegulations] = useState<string | null>(null)

    const fetchRegulations = async () => {
        setLoading(true)
        setErrorRegulations(null)

        try {
            const res = await fetchFilesRegulations(tokenSave)
            if (res && res.status === 200 && res.data.IsSuccess) {
                setData(res.data)
            } else {
                setErrorRegulations('Error al consumir el servicio de reglamentos')
            }
        } catch (err) {
            setErrorRegulations('Problemas al conectar con el servidor')
        } finally {
            setLoading(false)
        }
    }

    return { data, fetchRegulations, loadingRegulations, errorRegulations }
}
