import { AxiosResponse } from 'axios'
import {
    certificateService,
    keyChangeService,
    faiAccountTransfer,
} from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetInfoAssociate(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Client/GetAgreementCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateIVR(tokenSave: string, body: string): Promise<Result> {
    try {
        return await keyChangeService
            .validateIVR('api/Authorization/ValidIVR', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetFaiAccountBalance(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/Products/GetFaiAccountBalance/GetFaiAccountBalance', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetValidateAccountFai(
    documentF: string,
    accountFai: string,
    tokenSave: string
): Promise<Result> {
    const encodedAccountFai = encodeURIComponent(accountFai)
    try {
        return await faiAccountTransfer
            .getGetFaiAccount(
                `/api/Products/GetValidateAccountFai/GetValidateAccountFai?documentF=${documentF}&account=${encodedAccountFai}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function usePostTransactionFai(tokenSave: string, body: any): Promise<Result> {
    try {
        return await faiAccountTransfer
            .postTransactionFai(
                '/api/Transaction/SendBetweenAccountsFAI/SendBetweenAccountsFAI',
                tokenSave,
                body
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
