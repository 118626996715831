import { Button, Table } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

interface SectionThProps {
    showThirdButton?: boolean
}

interface SectionTdProps {
    showIsEditable?: boolean
}

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`font-montserrat font-bold text-dominant-dark sm:text-2xl text-[1.3em]`}

    span {
        ${tw`pl-1 text-dominant-dark`}
        margin: 5px;
    }
`

export const SpaceStep = styled.div`
    ${tw`w-full h-2.5 mt-12`}
`

export const ContainerTab = styled.section`
    ${tw`w-full`}
    .nav-tabs .nav-link {
        margin-bottom: -1px;
        background: #e5e5e5;
        border: 2px solid #fff;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
        color: #707070;
        text-decoration: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #fff;
        background-color: #f5a50b;
        border-color: #fff;
    }

    .nav-link:hover,
    .nav-link:focus {
        color: #512f8b;
    }

    .nav-tabs {
        border-bottom: 1px solid #f9fafb;
    }
`

export const ButtonUnstyled = styled(Button)`
    ${tw`font-normal font-montserrat`}
    background-color: transparent !important;
    border-color: transparent !important;
    padding-bottom: 0;
    margin-left: -20%;
`

export const ContainerTable = styled(Table)`
    ${tw`font-montserrat w-full text-base`}

    thead {
        background: #f5a50b;
        color: #ffffff;
        text-align: center;
        border-width: 2px 0 !important;
    }

    .th {
        ${tw`lg:color[var(--dominant-color-dark)] color['#ffffff']`}
    }
    .radio-button {
        margin-left: 10px;
    }

    tbody td {
        max-width: 80px;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    tbody td > .d-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    tbody td > .d-flex > * {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (max-width: 600px) {
        border-width: 0px !important;
        thead {
            border-color: #f5a50b;
        }
    }
    thead {
        border-color: #f5a50b;
    }
`

export const PaginationContent = styled.section`
    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        color: var(--button-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: #000000 !important;
        background: none !important;
        font-weight: 500;
    }

    .pagination {
        justify-content: center;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        /* background-color: #0d6efd; */
        border-bottom-color: var(--dominant-color-dark);
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        /* border: 4px; */
        font-weight: 700;
    }
`

export const ButtonPagination = styled.div`
    ${tw`w-[200px] h-[40px] justify-center items-center text-center rounded-[10px] font-normal font-montserrat`}

    color: var(--dominant-color-dark) !important;
    width: auto !important;

    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    li.page-item.active {
        background-color: var(--dominant-color-dark) !important;
        color: #fff;
    }

    .page-link:focus {
        z-index: 3;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }

    .page-link:hover {
        z-index: 2;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 250px) and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`

export const SectionTh = styled.div<SectionThProps>`
    display: grid;
    /* grid-template-columns: 80% 20%; */
    grid-template-columns: ${({ showThirdButton }) =>
        showThirdButton ? '60% 20% 20%' : '80% 20%'};
    align-items: end;
`

export const SectionContent = styled.div`
    ${tw`px-5 md:px-4 xl:w-[125%] xl:ml-[-13%] w-full mb-15`}
    @media (min-width:1270px) and (max-width:1450px) {
        ${tw`mx-auto xl:w-[100%]`}
    }
`

export const SectionTd = styled.div<SectionTdProps>`
    display: grid;
    grid-template-columns: ${({ showIsEditable }) => (showIsEditable ? '80% 20%' : '100%')};
`

export const ImagePreview = tw.img`
    w-[300px]
    h-[300px]
    mt-2
    mr-2
    rounded-b-2xl
    border-2
`
