import React from 'react'
import {
    Content,
    Image,
    SectionImage,
    SectionText,
    SectionTextImage,
    TitleComponent,
    Wrapper,
} from '../../AdvanceToFAIAccount-Styled'
import { WalletFai } from '../../../../utils/getIcons'

const NoFAIAccountAlert: React.FC = () => {
    return (
        <Wrapper>
            <Content>
                <div>
                    <TitleComponent>Cuenta FAI</TitleComponent>
                </div>

                <SectionImage>
                    <Image src={WalletFai} alt="WalletFai" width={144} />
                </SectionImage>
                <SectionTextImage>
                    <p>Solicita tu cuenta FAI</p>
                </SectionTextImage>

                <SectionText>
                    <p>
                        Para realizar un avance, debes tener una cuenta FAI, para solicitarla visita
                        una de nuestras{' '}
                        <a id="Where-are-we" href="https://www.cavipetrol.com/web/donde-estamos/">
                            oficinas
                        </a>
                        .
                    </p>
                </SectionText>
            </Content>
        </Wrapper>
    )
}

export default NoFAIAccountAlert
