import tw, { styled } from 'twin.macro'
import { Form, Button } from 'react-bootstrap'
import { FormLabel as FormLabelApp, FormGroup as FormGroupApp } from '../../../../components'

export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}

    span {
        color: #000000;
    }
`

interface FormOptionProps {
    show?: boolean
}

export const FormOption = styled.option<FormOptionProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`

export const ButtonContinuar = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border: 2px solid transparent !important;
    color: #ffffff;

    &:hover {
        background-color: var(--sub-dominant-color);
        border: 2px solid transparent;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

ButtonContinuar.displayName = 'ButtonContinuar'

export const FormSelect = styled(Form.Select)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-7 border-none cursor-pointer`}
    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}
    background-image: var(--url-arrow-down-yellow-icon) !important;
    border-color: var(--url-arrow-down-yellow-icon) !important;
`

export const FormSelectTC = styled(Form.Select)`
    ${tw`font-helvetica font-normal lg:text-base text-[#425453] pl-9 border-none cursor-pointer`}

    @media (min-width: 200px) and (max-width: 500px) {
        ${tw`text-[12px]`}
    }

    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}
    background-image: var(--url-arrow-down-yellow-icon) !important;
    border-color: var(--url-arrow-down-yellow-icon) !important;
`

interface TopPosition {
    top?: string
}

export const Image = styled.img<TopPosition>`
    ${tw`z-[50]`}
    width: 19px;
    margin-top: ${(props) => (props.top ? props.top : '')};
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:px-1`}
    justify-content: space-between !important;
`

export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 350px) and (max-width: 550px) {
        ${tw`w-[110%]`}
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (min-width: 350px) and (max-width: 550px) {
        ${tw`w-[110%]`}
    }
`
export const ImgCardContent = styled.div`
    ${tw`self-center`}
    ${tw`lg:w-[250px] w-[230px] mb-4 lg:static top-[-105px] ml-[10%]`}
`

export const CardContainer = styled.div`
    ${tw`w-full relative mt-13 pt-[10px]`}
    ${tw`max-w-[600px] lg:max-w-[600px] lg:mt-0 rounded-[10px] shadow-[0px 3px 6px #00000029] lg:pt-[30px] pb-[15px] px-[15px]`}
    ${tw`flex gap-1 flex-col`}

    & > h3 {
        ${tw`font-helvetica font-medium text-base color[var(--dominant-color-dark)]`}
    }

    & > h2 {
        ${tw`font-montserrat font-normal text-[22px] text-black`}
    }

    & > p {
        ${tw`font-helvetica font-medium text-[14px] color[var(--text-opacity7)]`}
    }

    .checkStyled {
        ${tw`px-[30px] flex flex-col-reverse`}
    }

    .input-with-icon {
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/ios-cash.svg);
        background-size: 20px;
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .input-with-icon-card {
        background-image: var(--url-card-gray-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    select.input-with-icon-card.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-card-gray-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);

        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        /* background-size: 16px 12px, calc(0.75em + 0.375rem), calc(0.75em + 0.375rem); */
        background-size: 16px;
    }

    input.input-with-icon.form-control.is-invalid {
        padding-right: 4.125rem;
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/ios-cash.svg),
            var(--url-exclamation-mark-icon);

        background-position: 10px center, center right 0.75rem;
        /* background-size: 16px 12px, calc(0.75em + 0.375rem); */
        background-size: 16px;
    }

    .input-with-icon-cvv {
        background-image: var(--url-cvv-gray-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    select.input-with-icon-cvv.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-cvv-gray-icon), var(--url-exclamation-mark-icon);

        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        /* background-size: 16px 12px, calc(0.75em + 0.375rem), calc(0.75em + 0.375rem); */
        background-size: 16px;
    }

    .input-with-icon-quota {
        background-image: var(--url-quota-gray-icon), var(--url-arrow-down-yellow-icon);
        background-size: 16px;
        background-position: 10px center, center right 0.75rem;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    select.input-with-icon-quota.form-select.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-quota-gray-icon), var(--url-exclamation-mark-icon),
            var(--url-arrow-down-yellow-icon);

        background-position: 10px center, right 0.75rem center, center right 2.25rem;
        /* background-size: 16px 12px, calc(0.75em + 0.375rem), calc(0.75em + 0.375rem); */
        background-size: 16px;
    }
`
export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[552px] lg:max-w-[600px] rounded-[10px]`}
`

export const TextInfo = styled.p`
    ${tw`mt-3 py-3 px-3 font-montserrat text-[16px]`}

    span {
        font-weight: 700;
    }
`

export const FormLabelDateCard = styled(FormLabelApp)`
    ${tw`font-helvetica font-normal block text-black text-[14px] my-3`}
`

export const TextInstruction = styled.p`
    ${tw`font-helvetica font-normal block text-dominant-dark text-[14px] my-3 px-3`}
`

export const AlertContainer = styled.div`
    ${tw`my-1 mx-0 items-center py-0 px-[15px] rounded-[10px] min-h-[80px] gap-2.5`}
    background-color: var(--alert-second);

    p {
        ${tw`m-0 font-normal font-helvetica text-sm`}
        color: var(--text-modal1);
    }

    a {
        color: var(--text-modal1) !important;
    }

    @media (min-width: 1180px) {
        p {
            ${tw`lg:text-base`}
        }
        a {
            color: var(--text-modal1);
        }
    }
`

export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    &.default {
        ${tw`block`}
    }

    .button-block {
        border-radius: 10px !important;
    }
    @media (min-width: 571px) {
        ${tw`block w-52`}

        &.default {
            ${tw`hidden`}
        }
    }

    @media (min-width: 1180px) {
        ${tw`w-52`}
    }
`
