import { Modal } from 'react-bootstrap'
// icons
import { CheckOperationSVG } from '../../../../utils/getIcons'
// styles
import {
    InfoModal,
    ModalTitle,
    ModalText,
    ButtonsContainer,
    ModalButton,
    CodeText,
} from './Modal-styles'
import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    voucherNumber: string
}

const ModalSuccess: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    voucherNumber,
}): JSX.Element => {
    const history = useHistory()
    const handleClick = (): void => {
        history.push('/home-wallet')
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CheckOperationSVG} alt="" className="CheckOperationSVG" />
                <ModalTitle>Todo listo</ModalTitle>
                <ModalText>Tu clave web ha sido cambiada.</ModalText>
                <ModalText>
                    Número de comprobante: <CodeText>{voucherNumber}</CodeText>
                </ModalText>

                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Aceptar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ModalSuccess
