import { ReactElement, FC } from 'react'

// components
import { BreadcrumbApp } from '../../../../../../components'
import { SaveAndExit } from '../../../../../OpeningCreditCard/inc'

// styles
import { SpaceStep, NavigationCtr } from './navigationStep-styles'

// hooks
import { useNavigationStep } from '../../hooks/useNavigationStep'

export interface NavigationStepProps {
    saveAndExit?: boolean
    onSave?: () => void
    onBack?: () => void
}

const NavigationStep: FC<NavigationStepProps> = ({ saveAndExit, onSave, onBack }): ReactElement => {
    const { breadcrumbs, onBack: defaultOnBack } = useNavigationStep()
    const handleBack = onBack || defaultOnBack
    return (
        <>
            <NavigationCtr>
                <BreadcrumbApp breadcrumbs={breadcrumbs} onBack={handleBack} />
                {saveAndExit && <SaveAndExit onSave={onSave} />}
            </NavigationCtr>
            <SpaceStep />
            <SpaceStep />
        </>
    )
}

export default NavigationStep
