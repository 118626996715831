import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import TitleModule from './inc/TitleModule'
import {
    InputGroupText,
    ContainerFilters,
    PaginationContent,
    ButtonPagination,
    RefButton,
    ContainerACH,
    ContainerTableACH,
    TextPBold,
    ContentInfo,
    Image,
    ContentButton,
} from './ReportACH-styles'
import { getReportACH } from '../../../redux/products'
import { useDispatch } from 'react-redux'
import { Table, Pagination, PageItem } from 'react-bootstrap'
import { NotProductSVG } from '../../utils/getIcons'
import Loading from './inc/Loading'

const ReportACH = (): JSX.Element => {
    const data = [
        {
            id: 0,
            beneficiaryId: '',
            beneficiaryName: '',
            accountType: '',
            bank: '',
            beneficiaryAccount: '',
            amountDrawn: '',
            state: '',
            detailState: '',
            registrationDate: '',
            reportDate: '',
            resultDate: '',
        },
    ]
    const [loading, setLoading] = useState<boolean>(false)
    const [cantItems, setCantItems] = useState<number>(0)
    const [infoReportACH, setInfoReportACH] = useState<any>(data)
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true)
        dispatch(
            getReportACH((res) => {
                setInfoReportACH(res.data)
                setCantItems(res.data.length)
                console.log('infoReportACH', res.data.length)
                setLoading(false)
            })
        )
    }, [])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber)
    }
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [filters, setFilters] = useState<{ [key: string]: string }>({
        id: '',
        beneficiaryId: '',
        beneficiaryName: '',
        accountType: '',
        bank: '',
        beneficiaryAccount: '',
        amountDrawn: '',
        state: '',
        detailState: '',
        registrationDate: '',
        reportDate: '',
        resultDate: '',
    })

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('e', e.target)
        const { name, value } = e.target
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
        console.log('filters', filters)
    }

    const filteredData = infoReportACH.filter((infoReportACH: any) =>
        Object.keys(filters).every(
            (key) =>
                !filters[key] ||
                String(infoReportACH[key]).toLowerCase().includes(filters[key].toLowerCase())
        )
    )
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Transacciones y pagos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Pagos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Reporte ACH',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <ContainerACH>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <TitleModule />
                <ContainerFilters>
                    <tr>
                        <td>
                            <p className="text">Identificación Beneficiario</p>
                            <InputGroupText
                                type="text"
                                name="beneficiaryId"
                                value={filters.beneficiaryId}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Nombre Beneficiario</p>
                            <InputGroupText
                                type="text"
                                name="beneficiaryName"
                                value={filters.beneficiaryName}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Tipo de cuenta</p>
                            <InputGroupText
                                type="text"
                                name="accountType"
                                value={filters.accountType}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Banco</p>
                            <InputGroupText
                                type="text"
                                name="bank"
                                value={filters.bank}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Cuenta Beneficiario</p>
                            <InputGroupText
                                type="text"
                                name="beneficiaryAccount"
                                value={filters.beneficiaryAccount}
                                onChange={handleFilterChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="text">Estado</p>
                            <InputGroupText
                                type="text"
                                name="state"
                                value={filters.state}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Detalle estado</p>
                            <InputGroupText
                                type="text"
                                name="detailState"
                                value={filters.detailState}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Fecha solicitud</p>
                            <InputGroupText
                                type="text"
                                name="registrationDate"
                                value={filters.registrationDate}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Fecha envio</p>
                            <InputGroupText
                                type="text"
                                name="reportDate"
                                value={filters.reportDate}
                                onChange={handleFilterChange}
                            />
                        </td>
                        <td>
                            <p className="text">Fecha respuesta</p>
                            <InputGroupText
                                type="text"
                                name="resultDate"
                                value={filters.resultDate}
                                onChange={handleFilterChange}
                            />
                        </td>
                    </tr>
                </ContainerFilters>
            </ContainerACH>
            {loading ? (
                <Loading />
            ) : (
                <ContainerTableACH>
                    <Table striped bordered hover className="tableACH">
                        <thead>
                            <tr>
                                <th>Identificación Beneficiario</th>
                                <th>Nombre Beneficiario</th>
                                <th>Tipo Cuenta</th>
                                <th>Banco</th>
                                <th>Cuenta Beneficiario</th>
                                <th>Monto</th>
                                <th>Estado</th>
                                <th>Detalle Estado</th>
                                <th>Fecha Solicitud</th>
                                <th>Fecha Envio</th>
                                <th>Fecha Respuesta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((infoReportACH: any) => (
                                <tr key={infoReportACH.id}>
                                    <td>{infoReportACH.beneficiaryId}</td>
                                    <td>{infoReportACH.beneficiaryName}</td>
                                    <td>{infoReportACH.accountType}</td>
                                    <td>{infoReportACH.bank}</td>
                                    <td>{infoReportACH.beneficiaryAccount}</td>
                                    <td>{infoReportACH.amountDrawn}</td>
                                    <td>{infoReportACH.state}</td>
                                    <td>{infoReportACH.detailState}</td>
                                    <td>{infoReportACH.registrationDate}</td>
                                    <td>{infoReportACH.reportDate}</td>
                                    <td>{infoReportACH.resultDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ContainerTableACH>
            )}
            <PaginationContent>
                <Pagination>
                    <ButtonPagination>
                        <PageItem
                            onClick={() => handlePageClick(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            {'<'}
                        </PageItem>
                    </ButtonPagination>
                    {[...Array(totalPages)].map((_, index) => (
                        <PageItem
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageClick(index + 1)}
                        >
                            {index + 1}
                        </PageItem>
                    ))}
                    <ButtonPagination>
                        <PageItem
                            onClick={() => handlePageClick(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            {'>'}
                        </PageItem>
                    </ButtonPagination>
                </Pagination>
                <ContentInfo>
                    {cantItems === 0 && (
                        <div style={{ display: 'flex' }}>
                            <Image src={NotProductSVG} alt="icono" />
                            <TextPBold>
                                <span>NO hay registros</span>
                            </TextPBold>
                        </div>
                    )}
                    <ContentButton>
                        <RefButton variant="primary" onClick={() => window.location.reload()}>
                            Refrescar
                        </RefButton>
                    </ContentButton>
                </ContentInfo>
            </PaginationContent>
        </LayoutContent>
    )
}

export default ReportACH
