import { Button, Table } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const SpaceStep = styled.div`
    ${tw`w-full h-2.5 mt-10`}
`

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`font-montserrat font-bold text-dominant-dark sm:text-2xl text-[1.3em]`}

    span {
        ${tw`pl-1 text-dominant-dark`}
        margin: 5px;
    }
`

export const ContainerTable = styled(Table)`
    ${tw`font-montserrat w-full text-base`}
    thead {
        background: #f5a50b;
        color: #ffffff;
        text-align: center;
        border-width: 2px 0 !important;
    }

    .th {
        ${tw`lg:color[var(--dominant-color-dark)] color['#ffffff']`}
    }

    td {
        text-align: center;
    }
`

export const Image = styled.img`
    width: 25px;
`

export const ButtonUnstyled = styled(Button)`
    ${tw`font-normal font-montserrat`}
    background-color: transparent !important;
    border-color: transparent !important;
`
