import { Table } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const InputGroupText = styled.input`
    ${tw`font-montserrat text-base text-black`}
    border: 2px solid #DCDCDC;
    outline: none;
    width: 100%;
    color: #333;
    border-radius: 0.375rem;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.01);
    padding: 7px;
`

export const ContainerFilters = styled.div`
    ${tw`w-[100%] mb-10 font-montserrat`}
    h1,
    p {
        font-size: 16px;
        @media (max-width: 500px) {
            ${tw`pt-3.5`}
        }
    }

    .text {
        ${tw`color[var(--dominant-color-dark)] font-bold`}
    }

    @media (max-width: 500px) {
        ${tw`h-full`}
    }
`

export const PaginationContent = styled.section`
    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        color: var(--button-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: #000000 !important;
        background: none !important;
        font-weight: 500;
    }

    .pagination {
        justify-content: center;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        /* background-color: #0d6efd; */
        border-bottom-color: var(--dominant-color-dark);
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        /* border: 4px; */
        font-weight: 700;
    }
`

export const ButtonPagination = styled.div`
    ${tw`w-[200px] h-[40px] justify-center items-center text-center rounded-[10px] font-normal font-montserrat`}

    color: var(--dominant-color-dark) !important;
    width: auto !important;

    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    li.page-item.active {
        background-color: var(--dominant-color-dark) !important;
        color: #fff;
    }

    .page-link:focus {
        z-index: 3;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }

    .page-link:hover {
        z-index: 2;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }
`

export const ContainerTableUser = styled(Table)`
    ${tw`font-montserrat w-full text-base`}
    thead {
        background: #f5a50b;
        color: #ffffff;
        text-align: center;
        border-width: 2px 0 !important;
    }

    .th {
        ${tw`lg:color[var(--dominant-color-dark)] color['#ffffff']`}
    }
`
export const Image = styled.img`
    width: 20%;
`

export const TextPBoldD = styled.p`
    margin-right: 925px;
`

export const TextPBold = styled.p`
    ${tw`text-base justify-between`}
    padding: 0;
    margin: auto;

    span {
        ${tw`text-base w-full`}
        font-weight: 600;
        color: var(---text-opacity10);
    }
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 250px) and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`
export const ContentButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;

    @media (min-width: 250px) and (max-width: 500px) {
        justify-content: center;
    }
`
export const SpaceStep = styled.div`
    ${tw`w-full h-2.5 mt-10`}
`
