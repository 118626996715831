import tw, { styled } from 'twin.macro'
import { Button, Form } from 'react-bootstrap'

import { InputGroup as InputGroupApp } from '../../../../../../components/includes'

export const FirstStepWrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const FirstStepContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[550px] lg:max-w-[924px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const InputGroupCheck = styled(InputGroupApp)`
    ${tw`flex flex-col lg:flex-row lg:items-center lg:justify-start mb-6`}
`

export const FormCheck = styled(Form.Check)`
    ${tw`w-2/5 flex items-center mb-3 lg:mb-0`}

    .form-check-input {
        ${tw`!w-6 !h-6 mr-3 cursor-pointer`}
    }

    .form-check-label {
        ${tw`!p-0 mt-1 cursor-pointer`}
    }
`

export interface ActiveAddCreditCardBtn {
    active: boolean
}

export const AddCreditCardBtn = styled.div<ActiveAddCreditCardBtn>`
    ${tw`flex flex-row justify-center items-center p-2.5 my-3 cursor-pointer`}
    ${tw`border-2 border-dashed border-[#C6CCCE] bg-[#FAFBFD] rounded-[4px]`}
    ${tw`lg:my-10`}
    ${({ active }) => (active ? tw`opacity-100` : tw`opacity-40`)}

    & h4 {
        ${tw`font-montserrat`}
    }
`

export const ImageAdd = styled.img`
    ${tw`mr-4`}
`

export const FieldCtr = styled.div`
    ${tw`my-5 w-full lg:w-1/4`}
`

export const BankCardList = styled.div`
    ${tw`flex flex-col gap-1.5 my-5 lg:my-10`}
`

export const FootPaymentStep = styled.div`
    ${tw`flex flex-col-reverse gap-5 py-0`}
    ${tw`sm:flex sm:flex-row sm:gap-5 sm:justify-end`}
    ${tw`lg:flex lg:flex-row lg:justify-end lg:gap-5 lg:text-center lg:pr-0`}

    .btn-form-type-light {
        ${tw`border-transparent no-underline`}
        ${tw`hover:text-sub-dominant hover:border-sub-dominant`}
        ${tw`sm:border-gray-custom-600`}
    }

    button {
        ${tw`sm:w-48`}
    }

    button.secundary {
        ${tw`text-gray-custom-300 no-underline h-[3.125rem]`}
        ${tw`border border-solid border-gray-custom-300 rounded-lg`}
        ${tw`hover:border-sub-dominant hover:text-sub-dominant hover:underline`}

        ${tw`lg:w-56 lg:no-underline`}
        ${tw`lg:hover:border lg:hover:border-solid lg:hover:border-sub-dominant lg:hover:text-sub-dominant`}
    }

    button.main {
        ${tw`lg:ml-auto`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`w-full flex flex-col-reverse gap-[15px] pt-[10px]`}
    ${tw`lg:flex-row lg:gap-[35px]`}

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[250px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`w-[400px]`}
    }

    &:hover {
        background-color: #ffffff;
        color: var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color) !important;
    }
    background-color: #ffffff !important;
    border-color: #000;
    color: #000;
`
export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[250px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`w-[400px]`}
    }
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`
