import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LayoutContent } from '../../transverse'
import { ArrowBlack, PpalTitle, SpacerContent } from './Codebtors-styles'
import { ArrowCircleSVG, UserSVG } from '../../utils/getIcons'
import CodebtorInfo from './inc/CodeptorInfo/CodeptorInfo'
import CodebtorProblem from './inc/CodeptorProblem/CodebtorProblem'
import NoCodebtor from './inc/NoCodebtor'
import { useGetCodebtorData } from './hooks/useCodebtor'
import ModalLoading from '../ActivateInternationalCard/inc/ModalInfo/ModalLoading'
import { BreadcrumbApp } from '../../components'
import { Cobebtor } from './hooks/modelCodebtors'

const Codebtors = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [isLoading, setIsLoading] = useState(false)
    const [codeptorView, setCodeptorView] = useState(0)

    const [cobebtor, setCobebtor] = useState<Cobebtor[]>([])

    useEffect(() => {
        GetCodebtorData()
    }, [])

    const GetCodebtorData = (): void => {
        setIsLoading(true)
        useGetCodebtorData(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    setCobebtor(res.data.Data)

                    setCodeptorView(1)
                } else if (res.data.Response === '204') {
                    setCodeptorView(3)
                } else {
                    setCodeptorView(2)
                }
            })
            .catch((error) => {
                console.error(error)
                setCodeptorView(2)
            })
    }

    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Codeudores',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <SpacerContent>
                <BreadcrumbApp breadcrumbs={breadcrumbs} />
                <ArrowBlack onClick={() => redirection('/home-wallet')}>
                    <img src={ArrowCircleSVG} alt="black" />
                    <p>Volver</p>
                </ArrowBlack>

                <PpalTitle>
                    <img src={UserSVG} alt="usuario icono" /> <span>Codeudores</span>
                </PpalTitle>

                {codeptorView === 1 && <CodebtorInfo infoCodebtor={cobebtor} />}
                {codeptorView === 2 && <CodebtorProblem redirection={redirection} />}
                {codeptorView === 3 && <NoCodebtor redirection={redirection} />}
            </SpacerContent>
            <ModalLoading isLoading={isLoading} />
        </LayoutContent>
    )
}

export default Codebtors
