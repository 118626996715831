import { FC, useRef, useState } from 'react'
import { EyeCleanPNG, EyeSVG, LockSVG } from '../../../../utils/getIcons'
import { OverlayTrigger } from 'react-bootstrap'
import {
    FormGroup,
    FormControl,
    InputGroup,
    InputGroupText,
    FormMessage,
    Popover,
} from '../../../../components'
import cn from 'classnames'
import { useMediaQuery } from 'usehooks-ts'
import { useFormState } from 'react-hook-form'
import { KeyboardLogin } from '../../../Login/inc'
import { useLoginForm } from '../../../Login/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { PassContainer } from '../../../KeyChange/KeyChange-styles'

interface PassProps {
    handlePassConfirm: (value: string) => void
}

// validation schema
const loginSchema = yup
    .object({
        passwordConfirm: yup
            .string()
            .required('Campo obligatorio')
            .min(4, 'Este campo debe contener un mínimo de 4 caracteres y un máximo de 8')
            .max(8, 'Este campo debe contener un máximo de 8 caracteres'),
    })
    .required()

// type definitions
type PassInputsType = yup.InferType<typeof loginSchema>

const PasswordFieldConfirm: FC<PassProps> = ({ handlePassConfirm }): JSX.Element => {
    const messagesRef = useRef({
        userNotExists: {
            type: 'not-exists',
            message: '',
        },
        pwdNotExits: {
            type: 'not-exists',
            message: '',
        },
        pwdTried: {
            type: 'pwd-tried',
            message: '',
        },
    })
    const defaultValuesRef = useRef({
        password: '',
    })

    const { control, register, getValues, setValue } = useLoginForm<PassInputsType>({
        defaultValues: defaultValuesRef.current,
        validationSchema: yupResolver(loginSchema),
        messages: messagesRef.current,
    })

    const { errors } = useFormState({ control })

    const [seePasswordConfirm, setPasswordConfirm] = useState(false)

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [showKeyboardConfirm, setShowKeyboardConfirm] = useState<boolean>(false)

    //Confirmar clave
    const handlePasswordInputConfirm = (inputChanged: string): void => {
        setValue('passwordConfirm', inputChanged, { shouldValidate: true })
        handlePassConfirm(inputChanged)
    }

    const handleFocusPasswordConfirm = (): void => {
        setShowKeyboardConfirm(true)
    }

    const handleHideKeyboardConfirm = (): void => {
        setShowKeyboardConfirm(false)
    }

    return (
        <PassContainer>
            <FormGroup id="content-password-confirm">
                <OverlayTrigger
                    show={showKeyboardConfirm}
                    placement="auto-start"
                    overlay={
                        <Popover id="keyboard-popover" hasKeyboard>
                            <KeyboardLogin
                                onMouseLeave={handleHideKeyboardConfirm}
                                onChange={handlePasswordInputConfirm}
                                defaultValue={getValues('passwordConfirm')}
                            />
                        </Popover>
                    }
                >
                    <InputGroup hasValidation>
                        <InputGroupText $inputError={!!errors.passwordConfirm}>
                            <img src={LockSVG} alt="clave" className="icon-input" />
                        </InputGroupText>
                        <FormControl
                            {...register('passwordConfirm')}
                            placeholder="Repite tu clave actual"
                            className="has-suffix"
                            type={seePasswordConfirm ? 'input' : 'password'}
                            onFocus={matchMedia ? handleFocusPasswordConfirm : () => void 0}
                            readOnly={matchMedia}
                            maxLength={8}
                            isInvalid={!!errors.passwordConfirm}
                        />

                        <InputGroupText
                            className={cn('icon-action', {
                                seePasswordConfirm: !seePasswordConfirm,
                                'not-seePasswordConfirm': seePasswordConfirm,
                            })}
                            onClick={() => {
                                setPasswordConfirm((sp) => !sp)
                            }}
                        >
                            <img
                                src={seePasswordConfirm ? EyeCleanPNG : EyeSVG}
                                alt="clave oculta y visible"
                                className="icon-eye"
                            />
                        </InputGroupText>
                        <FormMessage type="invalid">
                            {errors.passwordConfirm && errors.passwordConfirm.message}
                        </FormMessage>
                    </InputGroup>
                </OverlayTrigger>
            </FormGroup>
        </PassContainer>
    )
}

export default PasswordFieldConfirm
