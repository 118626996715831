import { Button } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import tw, { styled } from 'twin.macro'

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}

    span {
        color: #f5a50b;
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color) !important;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: right;
    align-items: center;
`

export const FormMessage = styled(Feedback)`
    ${tw`font-montserrat font-normal text-sm text-alert mt-[5px]`}
`

export const DivContainer = styled.div`
    ${tw`w-72`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }

    @media (max-width: 600px) {
        ${tw`h-26`}
    }

    popover-arrow {
        top: -72px !important;
    }
`

export const InputContainer = styled.div`
    ${tw`w-72 h-24`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`

export const SectionKeyboard = styled.div`
    ${tw`w-56`}

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        .hg-theme-default.hg-layout-numeric .hg-button {
            ${tw`h-12`}
        }
    }
`
