import tw, { styled } from 'twin.macro'

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`
export const Content = styled.div`
    ${tw`p-5 lg:py-6 px-6 w-[60%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    @media (min-width: 200px) and (max-width: 550px) {
        ${tw`!w-[100%]`}
    }

    .title-content {
        ${tw`!py-6`}
    }
`

export const ProcessLine = styled.div`
    ${tw`p-5 lg:py-8 px-12 w-[60%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    @media (min-width: 200px) and (max-width: 550px) {
        ${tw`!w-[100%]`}
    }

    .process-line {
        position: relative;
        height: 100%;
    }

    .process-line::before {
        content: '';
        position: absolute;
        width: 2px;
        left: 4px;
        height: calc(100% + 21px);
        border-radius: 10px;
        background-color: #425453;
    }

    .process-line.progress-idle::before {
        background-color: #cccccc !important;
        width: 2px;
    }

    .state {
        position: relative;
    }

    .state.idle-state::after {
        background-color: #cccccc !important;
    }

    .state::after {
        content: '';
        position: absolute;
        top: 0;
        height: 10px;
        width: 10px;
        background-color: #f5a50b;
        border-radius: 50%;
    }

    .state h3 {
        ${tw`font-montserrat`}
        margin: 20px 40px 0px 40px;
        font-size: 16px;
        text-align: left;
        left: 20%;
        color: #f5a50b;
    }

    .state .description {
        ${tw`font-helvetica`}
        padding: 5px;
        line-height: 22px;
        font-size: 15px;
        padding-left: 40px;
        color: #000;
    }

    .idle-state h3,
    .idle-state .description,
    .state .idle {
        color: #425453bc;
        opacity: 1;
    }
`

export const TitleContent = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-[3vh]`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }

    @media (max-width: 460px) {
        ${tw`text-[16px]`}
    }
`

export const SubTitleContent = styled.h1`
    ${tw`m-0 font-helvetica text-black text-[15px]`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Text = styled.p`
    ${tw`m-0 font-montserrat text-dominant-dark text-lg`}
`

export const SubText = styled.p`
    ${tw`m-0 font-helvetica text-black text-base`}
`

export const SectionText = styled.div`
    ${tw`my-5 mx-0 w-[60%] justify-center items-center`}

    @media (min-width: 200px) and (max-width: 550px) {
        ${tw`!w-[100%]`}
    }
`

export const ContentTitle = styled.div`
    ${tw`mt-2.5 ml-7 w-[60%]`}

    @media (min-width: 200px) and (max-width: 550px) {
        ${tw`!w-[100%]`}
    }
`
