import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[100%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const Image = styled.img`
    width: 20%;
`
export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`
export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}

    margin: 10%;
    padding: 0;

    span {
        color: #f5a50b;
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`

export const OptionsContainer = styled.div`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] h-[100%] w-[100%]`}
    ${tw`flex gap-1 flex-wrap`}
    .option-mobile {
        ${tw`flex lg:hidden`}
    }
    .option-desk {
        ${tw`lg:flex hidden`}
    }
    .title {
        ${tw`font-montserrat lg:text-base text-[20px] lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
        width: 100%;
        margin: 10px auto;
        margin-left: 12px;
        padding: 10px;
    }
    .text {
        ${tw`font-montserrat lg:text-base text-[20px] lg:color[var( --place-holder-color)] color[var(--text-opacity7)]`}
    }

    @media (max-width: 460px) {
        .text1 {
            font-size: 1rem;
            line-height: 1.5rem;
            ${tw`font-montserrat font-bold`}
        }
        .text {
            font-size: 1rem;
            line-height: 1.5rem;
            ${tw`font-montserrat`}
        }
        .title {
            color: #f5a50b;
        }
    }
`

export const OptionsContainerDetail = styled.div`
    ${tw`rounded-[10px] h-[100%] w-[100%]`}
    span {
        color: #f5a50b;
    }
    .option-mobile {
        ${tw`flex lg:hidden`}
    }
    .option-desk {
        ${tw`lg:flex hidden`}
    }
    .title {
        ${tw`font-montserrat lg:text-base text-[20px] lg:color[var(--dominant-color-black)] color[var(--text-opacity7)]`}
        width: 100%;
        margin: 10px auto;
        margin-left: 12px;
        padding: 10px;
    }
    .text {
        ${tw`font-montserrat font-bold lg:text-base text-[20px] lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
    }
    table {
        margin-top: 5em;
        margin: 0 auto;
        width: 80%;
    }
    th,
    td {
        padding: 15px;
        height: 60px;
        border: 1px solid black;
    }
    @media (max-width: 460px) {
        .text {
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--dominant-color-dark);
            border-right-color: black;
            border-left-color: white;
        }
        td {
            padding: 15px;
            height: 60px;
            border: 1px solid rgba(128, 128, 128, 0.3);
        }
        td.left-colored {
            border-right-color: white;
        }
    }
`

export const OptionPayOr = styled.div`
    ${tw`lg:w-[350px] lg:relative`}
`

export const OptionType = styled(OptionPayOr)`
    ${tw` lg:w-[calc(20.7% - 4px)] h-[65px] lg:h-[90px] mx-[20px]`}
`

export const PaginationContent = styled.div`
    ul {
        justify-content: center;
    }

    p.symbol {
        color: var(--sub-dominant-color) !important;
        font-weight: bold;
        margin-top: revert !important;
    }
`
export const ButtonPagination = styled.div`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    color: var(--sub-dominant-color) !important;
    width: auto !important;

    .button-pagination {
        color: var(--sub-dominant-color) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--sub-dominant-color) !important;
        background: none !important;
        font-weight: bold;
    }
`
export const ButtonHome = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--shadow-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`
