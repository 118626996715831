import tw, { styled } from 'twin.macro'

export const ExpirationCode = styled.div`
    ${tw`flex justify-center items-center mt-[4%] py-2.5 px-0 mb-4`}
    ${tw`lg:mt-[4%] lg:justify-start lg:p-0`}

    p {
        ${tw`font-montserrat font-normal text-sm text-gray-custom-700 m-0`}
        ${tw`lg:text-base`}
    }

    .text-percentage {
        ${tw`flex`}
    }
`

export const TextDynamicCode = styled.p`
    margin-top: 4%;
    .link-dynamic-passw {
        ${tw`font-montserrat font-normal text-sm text-gray-custom-700 cursor-pointer`}

        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            ${tw`text-base`}
        }
    }
`

export const ButtonRequestDynamicKey = styled.button`
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: var(--sub-dominant-color) !important;

    button:disabled {
        color: red !important;
    }
`
