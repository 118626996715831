import { Col, Row, Form, OverlayTrigger } from 'react-bootstrap'
import {
    ButtonsContainer,
    HeadTransactionStep,
    ModalButton,
    ModalCancelButton,
    ParagraphStep,
    SectionKeyboard,
    SubtitleTransaction,
    TextP,
    TitleTransaction,
} from './SecondStep-Styled'
import { ModalGeneric, Popover } from '../../../../components'
import { useMediaQuery } from 'usehooks-ts'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ExclamationShieldSVG } from '../../../../utils/getIcons'
import { useHistory } from 'react-router-dom'

import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import ModalLoading from '../Modals/ModalLoading'

import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'
import { useTransaction, useValidateIVR } from '../../hooks/useInterbankTransfer'
import { Content, Wrapper } from '../../InterbankTransfer-Styled'
import moment from 'moment'
import { shuffleArrayKeyboard } from '../../../../utils/misc'

interface SecondStepProps {
    onNextStep: () => void
    handleData: (
        dateTransaction: string,
        messageTransaction: string,
        voucher: string,
        responseTransaction: string
    ) => void
    amountTransfer: string
    dataTransaction: TransactionData | null
    associatedName: string
    associatedDocumentNumber: string
    associatedDocumentType: string
}

interface TransactionData {
    amountTransfer: string
    nameAddreess: string
    typeDocumentId: string
    destinationIdentification: string
    stateAddressId: string
    stateAddressName: string
    cityAddressId: string
    cityAddressName: string
    bankAddressId: string
    bankAddressName: string
    typeAccountAddress: string
    accountNumberAddressee: string
    description: string
}

const SecondStep: React.FC<SecondStepProps> = ({
    onNextStep,
    handleData,
    amountTransfer,
    dataTransaction,
    associatedName,
    associatedDocumentNumber,
    associatedDocumentType,
}) => {
    const history = useHistory()
    const methods = useForm()
    const { formState, setError, clearErrors, register, getValues } = methods
    const { errors } = formState

    const matchMedia = useMediaQuery('(min-width: 767px)')
    const isMobile = useMediaQuery('(max-width: 767px)')
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false)
    const [passIVR, setPassIVR] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [isUpdatingLayout, setIsUpdatingLayout] = useState(false)
    const [keyboardLayout, setKeyboardLayout] = useState<string[]>([])
    const [isLocked, setIsLocked] = useState<boolean>(false)

    const updateKeyboardLayout = (): void => {
        if (isUpdatingLayout) return
        setIsUpdatingLayout(true)

        const basicLayout = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const layoutWithAsterisks = ['* * *', '* * *', '* * *', ' * ', '{bksp}']
        const shuffledLayout = shuffleArrayKeyboard([...basicLayout])

        const layout = [
            `${shuffledLayout[0]} ${shuffledLayout[1]} ${shuffledLayout[2]}`,
            `${shuffledLayout[3]} ${shuffledLayout[4]} ${shuffledLayout[5]}`,
            `${shuffledLayout[6]} ${shuffledLayout[7]} ${shuffledLayout[8]}`,
            ` ${shuffledLayout[9]} `,
            '{bksp}',
        ]

        setIsLocked(true)
        setKeyboardLayout(layoutWithAsterisks)
        setTimeout(() => {
            setIsLocked(false)
            setKeyboardLayout(layout)
            setIsUpdatingLayout(false)
        }, 600)
    }

    const handleKeyPress = (button: string): void => {
        if (button === '{bksp}') {
            setPassIVR((prevPass) => prevPass.slice(0, -1))
        } else {
            /* setPassIVR((prevPass) => prevPass + button) */

            updateKeyboardLayout()
        }
    }

    const onFocusInput = (): void => {
        if (matchMedia) {
            setShowKeyboard(true)
        }
        updateKeyboardLayout()
    }

    const handleContainerMouseLeave = (): void => {
        setShowKeyboard(false)
    }

    const handleSeeKeyboard = (event: React.MouseEvent<HTMLDivElement>): void => {
        setShowKeyboard(false)
    }

    const handleOnChangeDigit = (inputChanged: string): void => {
        setPassIVR(inputChanged)
        ValidateIVRInputs(inputChanged)
    }

    const handleOnChangeDigitMobile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        if (value.length === 0) {
            setError('PassIVR', {
                message: 'Este campo es obligatorio',
            })
        } else if (/^[0-9\b]*$/.test(value)) {
            setPassIVR(value)
        } else {
            setError('PassIVR', {
                message: 'Ingrese solo dígitos numéricos.',
            })
        }
    }

    const ValidateIVR = (): void => {
        setIsLoading(true)
        handleContainerMouseLeave()
        useValidateIVR(tokenSave, passIVR)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response

                if (responseCode === '200') {
                    PostTransaction()
                } else {
                    setIsLoading(false)
                    setError('PassIVR', {
                        message: res.Message,
                    })
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setError('PassIVR', {
                    message: 'Error al validar clave IVR',
                })
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    const PostTransaction = (): void => {
        setIsLoading(true)
        const dataSend = {
            TipoNipRte: associatedDocumentType,
            NipRte: associatedDocumentNumber,
            NombRte: associatedName,
            TipoNipDno: dataTransaction?.typeDocumentId,
            NipDno: dataTransaction?.destinationIdentification,
            NombDno: dataTransaction?.nameAddreess,
            TipoCtaDno: dataTransaction?.typeAccountAddress,
            CodeBank: parseInt(dataTransaction?.bankAddressId || '0', 10),
            NombBank: dataTransaction?.bankAddressName,
            CtaDno: dataTransaction?.accountNumberAddressee,
            VrAch: parseInt(dataTransaction?.amountTransfer || '0', 10),
            AchConcep: dataTransaction?.description,
            CodePlaza: parseInt(dataTransaction?.cityAddressId || '0', 10),
            NombPlaza: dataTransaction?.cityAddressName,
        }

        useTransaction(tokenSave, dataSend)
            .then((res: any) => {
                setIsLoading(false)

                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                const responseResponse = res.data?.Response || res.Response
                const dateTransaction = moment().format('YYYY-MM-DD hh:mm:ss a')

                if (responseIsSuccess && responseResponse === '200') {
                    const voucher = res.data.Data.Consecutive
                    const message = res.data.Data.Message
                    handleData(dateTransaction, message, voucher, responseResponse)
                    onNextStep()
                } else {
                    setErrorMessage(
                        'No ha sido posible procesar su transacción, por favor intenta de nuevo.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage(
                    'No se ha podido conectar con el servidor, por favor intente más tarde.'
                )
                setShowError(true)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    const ValidateIVRInputs = (passIVRValue: string): void => {
        if (passIVRValue.length === 0) {
            setError('PassIVR', {
                message: 'Este campo es obligatorio',
            })
        } else if (passIVRValue.length < 4) {
            setError('PassIVR', {
                message: 'Ingresa cuatro caracteres',
            })
        } else {
            clearErrors('PassIVR')
        }
    }

    return (
        <Wrapper onMouseLeave={handleContainerMouseLeave}>
            <Content>
                <HeadTransactionStep>
                    <TitleTransaction>
                        Transferencias <strong>&nbsp;interbancarias&nbsp;</strong>
                    </TitleTransaction>
                    <SubtitleTransaction>Ingresa tu Clave IVR para continuar</SubtitleTransaction>
                </HeadTransactionStep>

                <div style={{ height: '210px', marginTop: '40px' }}>
                    <Row>
                        <Col md={4} className="border-option-header">
                            <TextP>
                                <span>Estás a punto de realizar una transacción interbancaria</span>
                            </TextP>
                            <ParagraphStep>
                                $ {formatValue(amountTransfer, 1)}
                                <sup>{formatDecimalValue(amountTransfer, 1)}</sup>
                            </ParagraphStep>
                        </Col>
                        <Col md={1}>
                            <div className="vertical-hr"></div>
                        </Col>
                        <Col md={5}>
                            <TextP>Clave IVR</TextP>
                            <OverlayTrigger
                                show={showKeyboard}
                                placement="right"
                                overlay={
                                    <Popover noSpace>
                                        <SectionKeyboard>
                                            <Keyboard
                                                onChange={(input) => handleOnChangeDigit(input)}
                                                onMouseLeave={handleSeeKeyboard}
                                                onKeyPress={handleKeyPress}
                                                display={{ '{bksp}': 'BORRAR' }}
                                                stopMouseDownPropagation={isLocked}
                                                stopMouseUpPropagation={isLocked}
                                                stopKeyPressPropagation={isLocked}
                                                layout={{
                                                    default: keyboardLayout,
                                                }}
                                                theme={'hg-theme-default myTheme'}
                                                inputPattern={/^[^*]+$/}
                                                defaultValue={getValues('PassIVR')}
                                            />
                                        </SectionKeyboard>
                                    </Popover>
                                }
                            >
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="input-ivr-icon"
                                        type="password"
                                        {...register('PassIVR', {
                                            required: true,
                                        })}
                                        readOnly={matchMedia && !isMobile}
                                        onChange={handleOnChangeDigitMobile}
                                        value={passIVR}
                                        required
                                        isInvalid={!!errors['PassIVR']}
                                        onFocus={onFocusInput}
                                    />

                                    {errors['PassIVR'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['PassIVR']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </OverlayTrigger>
                            {showKeyboard && (
                                <div className="label-floating">
                                    Por tu seguridad utiliza el teclado virtual
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
                <ButtonsContainer>
                    <ModalCancelButton
                        variant="primary"
                        onClick={() => history.push('/home-wallet')}
                    >
                        Cancelar
                    </ModalCancelButton>
                    <ModalButton
                        disabled={passIVR === '' || passIVR.length < 4}
                        type="button"
                        onClick={ValidateIVR}
                        extend
                    >
                        Siguiente
                    </ModalButton>
                </ButtonsContainer>
            </Content>

            <ModalGeneric
                show={showError}
                img={ExclamationShieldSVG}
                textTitle={'Ha ocurrido un error'}
                textBody={errorMessage}
                handleButton={handleClose}
                handleClose={handleClose}
                textButton="Aceptar"
            />
            <ModalLoading
                isLoading={isLoading}
                textInfo="Aguarda un momento, estamos validando la información"
                textLoading={'Estamos procesando tu solicitud'}
            />
        </Wrapper>
    )
}

export default SecondStep
