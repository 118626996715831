import { FC } from 'react'
import {
    AceptButton,
    Content,
    PpalTitle,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
} from '../../UserCreation-styles'

interface SuccessProps {
    currentDateTime: string
    document: string
    userName: string
    wsMessage: string
    voucherNumber: string
    redirection: (value: string) => void
}

const SuccessfulForm: FC<SuccessProps> = ({
    currentDateTime,
    document,
    userName,
    wsMessage,
    voucherNumber,
    redirection,
}): JSX.Element => {
    return (
        <Content>
            <PpalTitle>Información del usuario</PpalTitle>
            <TableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Fecha y hora</th>
                        <th>Cédula</th>
                        <th>Usuario</th>
                        <th>Respuesta</th>
                        <th>Número de comprobante</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    <RowTable>
                        <td>{currentDateTime}</td>
                        <td>{document}</td>
                        <td>{userName}</td>
                        <td>{wsMessage}</td>
                        <td>{voucherNumber}</td>
                    </RowTable>
                </TBody>
            </TableContainer>

            <div>
                <AceptButton
                    type="button"
                    onClick={() => {
                        redirection('/login')
                    }}
                >
                    Terminar
                </AceptButton>
            </div>
        </Content>
    )
}

export default SuccessfulForm
