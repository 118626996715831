// styles
import { ContainerTitle, Paragraph, Title } from './titleModule-styles'

interface TitleModuleProps {
    customTitle?: string
    showParagraph?: boolean
}
const TitleModule: React.FC<TitleModuleProps> = ({ customTitle, showParagraph }): JSX.Element => {
    return (
        <ContainerTitle>
            <Title>
                {/* <img width="40" alt="home" src={GraphSVG} /> */}
                Simulador <span>{customTitle || 'Titulo de Ahorro Recreativo - TAR'}</span>
            </Title>
            <Paragraph>
                {showParagraph && (
                    <Paragraph>
                        Por favor, ingresa los valores en los campos, cuándo termines haz clic en
                        "Calcular"
                    </Paragraph>
                )}
            </Paragraph>
            <Paragraph>
                <span>NOTA:</span> Los valores que arroja el simulador son aproximados y de carácter
                informativo; todos los montos son calculados con base en la actual tasa de interés
                fijada para el producto. La tasa de interés cambia dependiendo de las condiciones de
                mercado y las cuotas no incluyen los montos por seguro de vida.
            </Paragraph>
            <Paragraph>
                Recuerde que usted puede consultar el cupo de su TAR a través de la opción de
                Servicios en Línea.
            </Paragraph>
            <Paragraph>
                Esta información no compromete ni obliga a Cavipetrol frente a sus potenciales
                usuarios.
            </Paragraph>
        </ContainerTitle>
    )
}

export default TitleModule
