// styles
import { GraphSVG } from '../../../../utils/getIcons'
import { ContainerTitle, Title } from './titleModule-styles'

const TitleModule = (): JSX.Element => {
    return (
        <ContainerTitle>
            <Title>
                <img width="40" alt="home" src={GraphSVG} />
                Puntajes y <span>cupos</span>
            </Title>
        </ContainerTitle>
    )
}

export default TitleModule
