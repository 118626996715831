import { scoresquotasService } from '../../../../../domain/services/User.service'

export async function useGetScorequotaData(tokenSave: string) {
    try {
        return await scoresquotasService
            .getScorequotasData(
                'api/Products/GetPointsAndQuotasDto/GetPointsAndQuotasDto',
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
