import React, { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { ButtonContent, ButtonsContainer, ModalButton, ModalSecondStep } from './SecondStep-Styled'
import { ClockMoneySVG } from '../../../../utils/getIcons'
import { Button } from '../../../../components'
import ModalConfirmation from '../ModalConfirmation'
import { useHistory } from 'react-router-dom'
import { useCancelScheduledPayment } from '../../hooks/usePaymentScheduleCancellationTC'
import { useSelector } from 'react-redux'
import ModalError from '../ModalError'

interface SecondAppProps {
    showModalCancellationPay?: boolean
    handleCloseModalCancellationPay: () => void
    lastsFourNumCard?: string
    cardNumber?: string
}

const SecondStep: React.FC<SecondAppProps> = ({
    showModalCancellationPay,
    handleCloseModalCancellationPay,
    lastsFourNumCard,
    cardNumber,
}): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false)
    const [showModalOpenConfirmation, setShowModalOpenConfirmation] = useState(false)
    const history = useHistory()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [titleModal, setTitleModal] = useState<string>('')
    const [textModal, setTextModal] = useState<string>('')

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const closeModalConfirmation = (): void => {
        setShowModalOpenConfirmation(false)
    }

    const openModalConfirmation = (): void => {
        setShowModalOpenConfirmation(true)
        handleCloseModalCancellationPay()
    }

    const openModalError = (): void => {
        setShowModal(true)
        handleCloseModalCancellationPay()
    }

    const handleCloseModal = (): void => {
        handleCloseModalCancellationPay()
    }

    const PaymentScheduleCancellationTC = (): void => {
        const dataBody = {
            CardNumber: cardNumber,
        }
        setIsLoading(true)
        useCancelScheduledPayment(tokenSave, dataBody)
            .then((res: any) => {
                if (res['status'] === 200) {
                    setIsLoading(false)
                    openModalConfirmation()
                } else if (res['Response'] === '400') {
                    openModalError()
                    setTitleModal('¡Lo sentimos, no hemos podido procesar tu solicitud!')
                    setTextModal(
                        'No hay inscripciones activas para la combinación de número de identificación y dígitos de tarjeta proporcionados.'
                    )
                    setIsLoading(false)
                } else {
                    openModalError()
                    setTitleModal('¡Ha ocurrido un error!')
                    setTextModal(
                        'Durante el proceso de inscripción de pago automático, por favor intente nuevamente.'
                    )
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                openModalError()
                setTitleModal('¡Ha ocurrido un error!')
                setTextModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
        setShowModal(false)
    }

    return (
        <>
            <ModalSecondStep
                show={showModalCancellationPay}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <img src={ClockMoneySVG} alt="ClockMoneySVG" width={114} />
                    <h6 className="title-modal-login">
                        ¿Estás seguro que deseas cancelar el pago automático?
                    </h6>
                    <p>
                        Al cancelar el pago automático deberás realizar el pago de forma manual de
                        tu tarjeta mes a mes.
                    </p>
                    <ButtonsContainer>
                        <ButtonContent>
                            <Button
                                type="button"
                                className="styled-button"
                                onClick={PaymentScheduleCancellationTC}
                                isLoading={isLoading}
                                extend
                                disabled={isLoading}
                            >
                                Cancelar Pago Automático
                            </Button>
                        </ButtonContent>
                        <ModalButton variant="primary" onClick={handleCloseModal}>
                            Mantener Activo
                        </ModalButton>
                    </ButtonsContainer>
                </Modal.Body>
            </ModalSecondStep>

            <ModalConfirmation
                showModalOpenConfirmation={showModalOpenConfirmation}
                closeModalConfirmation={closeModalConfirmation}
                lastsFourNumCard={lastsFourNumCard}
            />

            <ModalError
                showModalOpenError={showModal}
                titleError={titleModal}
                textError={textModal}
                closeModalError={handleClose}
            />
        </>
    )
}

export default SecondStep
