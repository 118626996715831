import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import TitleModule from './inc/TitleModule'
import { OptionsContainerDetail, ContainerPossessions } from './CupsTermsQuotas-styles'
import ImageCupsTermsQuotas from './inc/ImageCupsTermsQuotas'

const CupsTermsQuotas = (): JSX.Element => {
    const history = useHistory()

    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Tasas, plazos y cupos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <>
            <LayoutContent>
                <ContainerPossessions>
                    <br />
                    <BreadcrumbApp
                        breadcrumbs={breadcrumbs}
                        onBack={() => {
                            redirection('/home-wallet')
                        }}
                    />
                    <TitleModule />

                    <OptionsContainerDetail>
                        <ImageCupsTermsQuotas />
                    </OptionsContainerDetail>
                </ContainerPossessions>
            </LayoutContent>
        </>
    )
}

export default CupsTermsQuotas
