import { FC } from 'react'
// styles
import { TextP, TextPBold, UserTextP } from '../../BalancesExtracts-styles'
import { Row, Col } from 'react-bootstrap'

interface UserProps {
    Nombre: string
    Nit: string
    Registro: string
    FechaAfiliacion: string
    ProductDocumentNumber: number
    Period: string
    CurrentDate: string
    finishDocumentNumber?: number
}

const UserInfo: FC<UserProps> = ({
    Nombre,
    Nit,
    Registro,
    FechaAfiliacion,
    ProductDocumentNumber,
    Period,
    CurrentDate,
    finishDocumentNumber,
}): JSX.Element => {
    return (
        <Row>
            <Col>
                <TextPBold>Apreciado(a)</TextPBold>
                <TextP>{Nombre}</TextP>
                <TextP>Reg. {Registro}</TextP>
                <TextP>Nit. {Nit}</TextP>
            </Col>
            <Col>
                <Row>
                    <UserTextP>
                        <span>Cuenta No.:</span> {ProductDocumentNumber}-{finishDocumentNumber}
                    </UserTextP>
                </Row>
                <Row>
                    <UserTextP>
                        <span>Periodo:</span> {Period}
                    </UserTextP>
                </Row>
                <Row>
                    <UserTextP>
                        <span>Fecha:</span> {CurrentDate}
                    </UserTextP>
                </Row>
            </Col>
        </Row>
    )
}

export default UserInfo
