import tw, { styled } from 'twin.macro'

export const ContainerProducts = styled.section`
    ${tw`mt-20 py-[15px] px-0`}
    ${tw`lg:pt-5 lg:pb-10 lg:px-0 lg:min-h-[600px]`}
`

export const Section = styled.section`
    ${tw`flex`}
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`
