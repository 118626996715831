import { Modal } from 'react-bootstrap'

// icons
import { CheckOperationSVG } from '../../../../utils/getIcons'

// styles
import {
    ButtonsContainer,
    InfoModal,
    ModalButton,
    ModalTitle,
    ModalText,
} from '../ModalInfo/ModalInfo-Styled'

import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    textTitle?: string
    textBody?: string
}

const ConfirmationModal: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
}): JSX.Element => {
    const history = useHistory()
    const handleClick = () => {
        history.push('/')
    }

    return (
        <InfoModal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CheckOperationSVG} alt="CreditCardFlowSVG" className="" />
                <ModalTitle>Todo listo</ModalTitle>
                <ModalText>
                    Entregaremos tu tarjeta de crédito en el lugar elegido.
                    <br />
                    -Si la dirección de la residencia no es la actual se debe contactar a servicio
                    al asociado por ende en el alistamiento se actualiza la dirección.
                    <br />- Se debe realizar la consulta de estados de la tarjeta para asegurar que
                    se encuentra bloqueada y actualizar el estado en el portal.
                </ModalText>
                <ButtonsContainer>
                    <ModalButton
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleClick}
                    >
                        Aceptar
                    </ModalButton>
                </ButtonsContainer>
            </Modal.Body>
        </InfoModal>
    )
}

export default ConfirmationModal
