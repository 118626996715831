import { onboardingService } from '../../../../../domain/services/User.service'

export async function useGetOnboardingState(tokenSave: string) {
    try {
        return await onboardingService
            .getOnboardingState('api/Onboarding/GetOnboardingState', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useSaveOnboardingState(tourData: any, tokenSave: string) {
    try {
        return await onboardingService
            .saveOnboardingState('api/Onboarding/SaveOnboardingState', tokenSave, tourData)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
