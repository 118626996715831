import tw, { styled } from 'twin.macro'

export const PagePdf = styled.section`
    ${tw`flex flex-wrap mt-2 py-6 px-2 w-full h-full`}
`

export const DocumentContent = styled.section`
    padding: 0% 5% 0% 5%;
    padding-top: 20%;

    background-repeat: no-repeat;
    background-size: 100%;
    background-image: var(--url-certified-watermark-image);

    @media (min-width: 601px) and (max-width: 700px) {
        font-size: 85%;
        padding-top: 20%;
    }
    @media (min-width: 501px) and (max-width: 601px) {
        font-size: 60%;
        padding-top: 25%;
    }
    @media (min-width: 451px) and (max-width: 501px) {
        font-size: 50%;
        padding-top: 15%;
    }
    @media (min-width: 401px) and (max-width: 451px) {
        font-size: 45%;
        padding-top: 20%;
    }
    @media (min-width: 376px) and (max-width: 401px) {
        font-size: 39%;
        padding-top: 20%;
    }
    @media (min-width: 331px) and (max-width: 376px) {
        font-size: 35%;
        padding-top: 20%;
    }

    @media (min-width: 301px) and (max-width: 331px) {
        font-size: 30%;
        padding-top: 20%;
    }

    @media (min-width: 275px) and (max-width: 301px) {
        font-size: 26%;
        padding-top: 20%;
    }

    @media (min-width: 200px) and (max-width: 275px) {
        font-size: 24%;
        padding-top: 20%;
    }
`

export const RemittentText = styled.p`
    padding-bottom: 8%;

    @media (min-width: 441px) and (max-width: 700px) {
        padding-bottom: 20%;
    }
    @media (min-width: 200px) and (max-width: 441px) {
        padding-bottom: 40%;
    }
`

export const Text = styled.p`
    ${tw`text-gray-custom-300 `}
    p {
        padding-bottom: 2%;
    }
`

export const HeaderText = styled.p`
    font-weight: 600;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
    p {
        padding-bottom: 0% !important;
        margin-bottom: 0% !important;
    }
`
export const SubHeaderText = styled.p`
    font-weight: 300;
    p {
        padding-bottom: 2%;
    }
`
