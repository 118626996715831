import { Col, Form, Row } from 'react-bootstrap'
import { Button, ModalGeneric } from '../../../../components'
import { LayoutContent } from '../../../../transverse'
import {
    ButtonCanceled,
    ContainerBtn,
    ContentForm,
    DivContainer,
    FormOptions,
    Label,
    SectionTitle,
    SpaceHr,
    TitlePage,
} from '../../UserOperations-styled'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLockUnlock } from '../../hooks/UseUserOperations'
import { useSelector } from 'react-redux'
import { NotProductSVG } from '../../../../utils/getIcons'
import ModalOperation from '../ModalOperation/ModalOperation'

interface UserLockToggleProps {
    name: string
    userLogin: string
    identificationNumber: string
    failedAttempts: string
    controlDate: string
    controlDescription: string
    lockStatus: string
    onPrevStep: (step: number) => void
}

interface OptionLock {
    key: number
    value: string | number
    label: string
    validate: string
}

const optionsLock: OptionLock[] = [
    { key: 1, value: '1', label: 'Sí', validate: 'Usuario No Bloqueado' },
    { key: 2, value: '0', label: 'No', validate: 'Usuario Bloqueado' },
]

const UserLockToggle: React.FC<UserLockToggleProps> = ({
    name,
    userLogin,
    identificationNumber,
    failedAttempts,
    controlDate,
    controlDescription,
    lockStatus,
    onPrevStep,
}): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [lockToggle, setLockToggle] = useState<string>('')
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorMessageModal, setErrorMessageModal] = useState<string>('')
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const methodsForm = useForm()
    const { control, formState, setError, clearErrors, handleSubmit } = methodsForm
    const { errors } = formState

    const history = useHistory()

    const HandleOperationSubmit = (): void => {
        setIsLoading(true)
        useLockUnlock(tokenSave, userLogin, identificationNumber, lockToggle)
            .then((res: any) => {
                setIsLoading(false)

                const responseRes = res.data?.Response || res.Response
                if (responseRes === '200') {
                    setShowModal(true)
                } else {
                    const messageInfo = res.data?.Message || res.Message
                    setErrorMessageModal(messageInfo)
                    setShowErrorModal(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setErrorMessageModal('No se ha podido conectar con el servidor')
                setShowErrorModal(true)
            })
    }

    const handleCloseModal = (): void => {
        setShowErrorModal(false)
        history.push('/home-wallet')
    }

    const handleCloseModalSuccess = (): void => {
        setShowModal(false)
        onPrevStep(1)
    }

    return (
        <LayoutContent>
            <SpaceHr />
            <FormProvider {...methodsForm}>
                <SectionTitle>
                    <TitlePage>Bloquear y desbloquear usuario</TitlePage>
                </SectionTitle>
                <ContentForm>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Nombre</Label>

                                <Form.Control type="text" disabled value={name} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Login usuario</Label>
                                <Form.Control type="text" disabled value={userLogin} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Cédula</Label>

                                <Form.Control type="text" disabled value={identificationNumber} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>No. intentos fallidos</Label>
                                <Form.Control type="text" disabled value={failedAttempts} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Fecha control</Label>

                                <Form.Control type="text" disabled value={controlDate} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Descripción control</Label>
                                <Form.Control type="text" disabled value={controlDescription} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Estado de bloqueo</Label>
                                <Form.Control type="text" disabled value={lockStatus} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Bloquear</Label>
                                <Controller
                                    control={control}
                                    name={'lockToggle'}
                                    rules={{
                                        required:
                                            lockToggle === '' ? 'Este campo es requerido' : false,
                                    }}
                                    render={({ field: { onChange, onBlur } }) => (
                                        <Form.Select
                                            className="selectFromControl"
                                            value={lockToggle}
                                            onChange={(event) => {
                                                const selectLock = event.target.value
                                                const selectedOption = optionsLock.find(
                                                    (option) => option.value === selectLock
                                                )

                                                if (selectedOption) {
                                                    if (
                                                        selectedOption.value === '1' &&
                                                        selectedOption.validate !== lockStatus &&
                                                        !lockStatus.includes('No Definido')
                                                    ) {
                                                        setError('lockToggle', {
                                                            message:
                                                                'Error: El usuario ya está bloqueado',
                                                        })
                                                        setIsDisabled(true)
                                                    } else if (
                                                        selectedOption.value === '0' &&
                                                        selectedOption.validate !== lockStatus &&
                                                        !lockStatus.includes('No Definido')
                                                    ) {
                                                        setError('lockToggle', {
                                                            message:
                                                                'Error: El usuario ya está desbloqueado..',
                                                        })
                                                        setIsDisabled(true)
                                                    } else {
                                                        clearErrors('lockToggle')
                                                        setIsDisabled(false)
                                                    }
                                                    setLockToggle(selectLock)
                                                }

                                                onChange(event)
                                            }}
                                            onBlur={(event) => {
                                                if (
                                                    event.target.value === '' ||
                                                    event.target.value === null
                                                ) {
                                                    setError('lockToggle', {
                                                        message: 'Este campo es requerido',
                                                    })
                                                }
                                                onBlur()
                                            }}
                                            isInvalid={!!errors['lockToggle']}
                                        >
                                            <FormOptions disabled value="">
                                                Seleccione una opción
                                            </FormOptions>

                                            {optionsLock.map((option) => (
                                                <FormOptions key={option.key} value={option.value}>
                                                    {option.label}
                                                </FormOptions>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                {errors['lockToggle'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['lockToggle']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <SpaceHr />

                    <DivContainer>
                        <ButtonCanceled variant="primary" onClick={() => onPrevStep(1)}>
                            Volver
                        </ButtonCanceled>
                        <form onSubmit={handleSubmit(HandleOperationSubmit)}>
                            <ContainerBtn>
                                <Button
                                    id="btnSubmit"
                                    className="button-block"
                                    variant="sub-dominant"
                                    type="button"
                                    onClick={handleSubmit(HandleOperationSubmit)}
                                    isLoading={isLoading}
                                    extend
                                    disabled={isDisabled}
                                >
                                    Continuar
                                </Button>
                            </ContainerBtn>
                        </form>
                    </DivContainer>
                </ContentForm>
            </FormProvider>
            <ModalOperation
                label="Bloqueo y desbloqueo de usuario"
                userName={userLogin}
                showModal={showModal}
                handleCloseModal={handleCloseModalSuccess}
                labelInput="Login"
                identificationCard={identificationNumber}
                shouldInitializeUser={false}
                state={lockToggle === '1' ? 'Usuario Bloqueado' : 'Usuario No Bloqueado'}
            />
            <ModalGeneric
                show={showErrorModal}
                handleClose={handleCloseModal}
                img={NotProductSVG}
                textTitle=""
                textBody={errorMessageModal}
                handleButton={handleCloseModal}
                textButton="Aceptar"
            />
        </LayoutContent>
    )
}

export default UserLockToggle
