import { useSelector } from 'react-redux'
import { useState } from 'react'
import { fetchGetFilesPolicies } from './useFilesPolicies'

interface DataItem {
    Name: string
    Url: string
    Tag: string
}

interface DataResponse {
    Data: DataItem[]
}

export const useFetchListPolicies = () => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [data, setData] = useState<DataResponse>({ Data: [] })
    const [loadingPolicies, setLoadingPolicies] = useState<boolean>(false)
    const [errorPolicies, setErrorPolicies] = useState<string | null>(null)

    const fetchListPolicies = async () => {
        setLoadingPolicies(true)
        setErrorPolicies(null)

        try {
            const res = await fetchGetFilesPolicies(tokenSave)
            if (res && res.status === 200 && res.data.IsSuccess) {
                setData(res.data)
            } else {
                setErrorPolicies('Error al consumir el servicio de polizas')
            }
        } catch (err) {
            setErrorPolicies('Problemas al conectar con el servidor')
        } finally {
            setLoadingPolicies(false)
        }
    }

    return { data, fetchListPolicies, loadingPolicies, errorPolicies }
}
