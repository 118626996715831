import tw, { styled } from 'twin.macro'

export const TitleComponent = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[16px] font-montserrat text-dominant-dark left-0`}
`
export const SectionImage = styled.div`
    ${tw`flex justify-center items-center py-2`}
`

export const Image = styled.img`
    ${tw`w-28 mr-5`}
`

export const SectionTextImage = styled.div`
    ${tw`flex justify-center items-center py-2 font-montserrat`}

    p {
        font-size: 16px;
    }
`

export const SectionText = styled.div`
    ${tw`left-0 py-2 font-montserrat`}

    p {
        font-size: 16px;
    }

    a {
        text-decoration: none;
        color: #f5a50b;
    }
`
