import { useEffect, useState } from 'react'
import { LayoutContent } from '../../../../transverse'
import { useDispatch, useSelector } from 'react-redux'
import { CreditCardSelector } from '../../../../../selectors'
import { StoreApp } from '../../../../../redux/store/store.interface'
import useQueryId from '../../../../hooks/useQueryId'
import { portfolioPurchaseAction } from '../../../../../redux/portfolioPurchaseTC'
import FirstStep from './inc/FirstStep'
import SecondStep from './inc/SecondStep'
import ThirdStep from './inc/ThirdStep'
import { RedirectContainer } from './CardRedirect-styles'
import NavigationStep from './inc/NavigationStep'
import TitleModule from './inc/TitleModule'
import { useHistory } from 'react-router-dom'
import { getRates } from '../../../../../redux/products'
import { RatesTypes } from '../../../../../../domain/models'

const CardRedirect = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [period, setPeriod] = useState<number>(0)
    const [ratesEA, setRatesEA] = useState<number>(0)
    const [ratesEM, setRatesEM] = useState<number>(0)
    const [minPayment, setMinPayment] = useState<number>(0)
    const { id } = useQueryId()
    const { cardData: infoCard } = useSelector((store: StoreApp) =>
        CreditCardSelector(store, String(id))
    )
    const [step, setStep] = useState(0)
    const [infoRates, setInfoRates] = useState<RatesTypes>()
    const [lastRates, setLastRates] = useState<number>(0)

    useEffect(() => {
        dispatch(portfolioPurchaseAction())
    }, [])

    useEffect(() => {
        dispatch(
            getRates((res) => {
                setInfoRates(res)
                const rate = res.objectValue[res.objectValue.length - 1]
                setLastRates(rate.period)
            })
        )
    }, [dispatch])

    const handleNextStep = (step: number) => {
        setStep(step)
    }
    const handlePrevStep = (step: number) => {
        setStep(step)
    }

    const handleData = (period: number, rateEA: number, rateEM: number, minPayment: number) => {
        setPeriod(period)
        setRatesEA(rateEA)
        setRatesEM(rateEM)
        setMinPayment(minPayment)
    }

    const onCancel = (): void => {
        history.push('/home-wallet')
    }

    //Implementación de redirección para el paso 1
    const customBackFunction = (): void => {
        if (step == 0) {
            if (infoCard) {
                history.push(`/credit-card-detail?id=${infoCard.lastFourDigitsEncryptedCC}`)
            } else {
                history.push('/home-wallet')
            }
        } else {
            setStep(step - 1)
        }
    }

    return (
        <LayoutContent>
            {infoCard && (
                <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <NavigationStep onSave={onCancel} onBack={customBackFunction} />
                    <TitleModule />
                    <RedirectContainer>
                        <div>
                            {step === 0 && (
                                <FirstStep
                                    handleNextStep={() => handleNextStep(1)}
                                    lastRates={lastRates}
                                />
                            )}
                        </div>
                        <div>
                            {step === 1 && infoRates && (
                                <SecondStep
                                    handleNextStep={() => handleNextStep(2)}
                                    handlePrevStep={() => handlePrevStep(0)}
                                    handleData={handleData}
                                    infoRates={infoRates}
                                />
                            )}
                        </div>
                        <div>
                            {step === 2 && (
                                <ThirdStep
                                    handlePrevStep={() => handlePrevStep(1)}
                                    handlePeriod={period}
                                    handleRatesEA={ratesEA}
                                    handleRatesEM={ratesEM}
                                    handleMinPayment={minPayment}
                                />
                            )}
                        </div>
                    </RedirectContainer>
                </>
            )}
        </LayoutContent>
    )
}
export default CardRedirect
