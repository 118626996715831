import { useHistory } from 'react-router-dom'
import { CardStates } from '../../../../../../domain/models'
import {
    useSelector,
    portfolioPurchaseProcessSelector,
    featureEnableFunctionsSelector,
} from '../../../../../selectors'
import {
    IconAssignMobileSVG,
    IconFileMobileSVG,
    IconReferQuotasMobileSVG,
    ListMenu,
} from '../../../../utils/getIcons'
import {
    Dropdown,
    MenuOptionsContainer,
    OptionButton,
    OptionsContainer,
} from './menuOptions-styles'
import BlockCard from '../../../CreditDetail/inc/BlockCard/BlockCard'
import { useEffect, useState } from 'react'
import UnBlockCard from '../../../CreditDetail/inc/UnBlockCard'
import BlockCardTheftModal from '../../../CreditDetail/inc/BlockCardTheftModal/BlockCardTheftModal'
import { parseStringToBoolean } from '../../../../utils/misc'

const enablePortfolioPurchase = process.env.REACT_APP_PORTFOLIO_PURCHASE_ENABLE as 'false' | 'true'

const enableOnOffFunctionTcRedeemQuotas = process.env.REACT_APP_ON_OFF_FUNCTION_TC_REDEEM_QUOTAS as
    | 'false'
    | 'true'
const enableOnOffFunctionAssignProgressKey = process.env
    .REACT_APP_ON_OFF_FUNCTION_ASSIGN_PROGRESS_KEY as 'false' | 'true'
const OnOffFunctionKeyChangeForward = process.env.REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD as
    | 'false'
    | 'true'
const enableOnOffFunctionTheftLock = process.env.REACT_APP_ON_OFF_FUNCTION_THEFT_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionTemporaryLock = process.env.REACT_APP_ON_OFF_FUNCTION_TEMPORARY_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionUnLock = process.env.REACT_APP_ON_OFF_FUNCTION_UNLOCK as 'false' | 'true'
const enableOnOffFunctionProductCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_PRODUCT_CERTIFICATE as 'false' | 'true'

interface MenuOptionsProps {
    stateCard: string
    quotaAvailable: number
    lastsFourNumCard: string
    dateExpiredCard?: string
    cardNumber?: string
    lastFourDigitsEncryptedCC?: string
}

const MenuOptions: React.FC<MenuOptionsProps> = ({
    stateCard,
    quotaAvailable,
    lastsFourNumCard,
    dateExpiredCard,
    cardNumber,
    lastFourDigitsEncryptedCC,
}): JSX.Element => {
    const history = useHistory()
    const claveAvances = true
    const { portfolioPurchaseProcess } = useSelector(portfolioPurchaseProcessSelector)

    // events handlers BlockCardTemp
    const [showModalLockTemp, setShowModalLockTemp] = useState(false)
    const handleCloseModalLockTemp = (): void => {
        setShowModalLockTemp(false)
    }

    const handleShowLockTemp = (): void => {
        setShowModalLockTemp(true)
    }
    // events handlers UnBlockCardTemp
    const [showModalUnLock, setShowModalUnLockTemp] = useState(false)
    const handleCloseModalULockTemp = (): void => {
        setShowModalUnLockTemp(false)
    }

    const handleShowUnLockTemp = (): void => {
        setShowModalUnLockTemp(true)
    }
    const [showModalTheft, setModalTheft] = useState(false)
    const handleClose = () => {
        setModalTheft(false)
        console.log('handleClose: ' + showModalTheft)
    }
    const handleShow = () => {
        setModalTheft(true)
        console.log('handleShow: ' + showModalTheft)
    }

    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (functionalityQuery.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            functionalityQuery.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
        }
    }, [functionalityQuery])

    return (
        <MenuOptionsContainer data-tour="detail-tc-options-mobile">
            <OptionsContainer>
                {parseStringToBoolean(enableOnOffFunctionTcRedeemQuotas) &&
                    enableFuntions.OnOffFunctionTcRedeemQuotas && (
                        <OptionButton isDisable={stateCard !== CardStates.ACTIVA}>
                            <div
                                onClick={() => {
                                    if (stateCard === CardStates.ACTIVA) {
                                        history.push(
                                            `/card-redirect?id=${lastFourDigitsEncryptedCC}`
                                        )
                                    }
                                }}
                            >
                                <img className="normal" src={IconReferQuotasMobileSVG} alt="" />
                            </div>
                            <h3>Rediferir cuotas</h3>
                        </OptionButton>
                    )}
                {parseStringToBoolean(enableOnOffFunctionAssignProgressKey) &&
                    enableFuntions.OnOffFunctionAssignProgressKey && (
                        <>
                            {claveAvances && (
                                <OptionButton isDisable={stateCard !== CardStates.ACTIVA}>
                                    <div
                                        onClick={() => {
                                            if (stateCard === CardStates.ACTIVA) {
                                                history.push(
                                                    `/advance-key-steps?id=${lastFourDigitsEncryptedCC}`
                                                )
                                            }
                                        }}
                                    >
                                        <img className="normal" src={IconAssignMobileSVG} alt="" />
                                    </div>
                                    <h3>Asignar clave avances</h3>
                                </OptionButton>
                            )}
                        </>
                    )}
                {parseStringToBoolean(OnOffFunctionKeyChangeForward) &&
                    enableFuntions.OnOffFunctionKeyChangeForward && (
                        <>
                            {claveAvances && (
                                <OptionButton isDisable={stateCard !== CardStates.ACTIVA}>
                                    <div
                                        onClick={() => {
                                            if (stateCard === CardStates.ACTIVA) {
                                                history.push(
                                                    `/change-key-advances?id=${lastFourDigitsEncryptedCC}`
                                                )
                                            }
                                        }}
                                    >
                                        <img className="normal" src={IconAssignMobileSVG} alt="" />
                                    </div>
                                    <h3>Cambiar clave avances</h3>
                                </OptionButton>
                            )}
                        </>
                    )}
                {parseStringToBoolean(enableOnOffFunctionProductCertificate) &&
                    enableFuntions.OnOffFunctionProductCertificate && (
                        <OptionButton>
                            <div
                                onClick={() =>
                                    history.push(`/Certificate-pdf?id=${lastFourDigitsEncryptedCC}`)
                                }
                            >
                                <img className="small" src={IconFileMobileSVG} alt="" />
                            </div>
                            <h3>Certificado</h3>
                        </OptionButton>
                    )}
                <OptionButton>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <div>
                                <img className="normal" src={ListMenu} alt="" />
                                <h3>Más</h3>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {parseStringToBoolean(enableOnOffFunctionTheftLock) &&
                                enableFuntions.OnOffFunctionTheftLock && (
                                    <>
                                        {stateCard !== CardStates.BLOQUEO_TEMPORAL && (
                                            <Dropdown.Item
                                                disabled={stateCard === CardStates.BLOQUEO_TEMPORAL}
                                            >
                                                <div onClick={handleShow}>Bloqueo por hurto</div>
                                                <BlockCardTheftModal
                                                    showModal={showModalTheft}
                                                    handleClose={handleClose}
                                                    lastsFourEncrypted={lastsFourNumCard}
                                                />
                                            </Dropdown.Item>
                                        )}{' '}
                                    </>
                                )}
                            <Dropdown.Divider />
                            {parseStringToBoolean(enableOnOffFunctionTemporaryLock) &&
                                enableFuntions.OnOffFunctionTemporaryLock && (
                                    <>
                                        {stateCard !== CardStates.BLOQUEO_TEMPORAL && (
                                            <Dropdown.Item
                                                disabled={
                                                    stateCard === CardStates.BLOQUEO_PERDIDA_O_HURTO
                                                }
                                            >
                                                <div onClick={handleShowLockTemp}>
                                                    Bloquear temporalmente
                                                </div>
                                                <BlockCard
                                                    showModalLockTemp={showModalLockTemp}
                                                    handleCloseModalLockTemp={
                                                        handleCloseModalLockTemp
                                                    }
                                                    lastsFourNumCard={lastsFourNumCard}
                                                    dateExpiredCard={dateExpiredCard}
                                                    cardNumber={cardNumber}
                                                />
                                            </Dropdown.Item>
                                        )}
                                    </>
                                )}
                            {parseStringToBoolean(enableOnOffFunctionUnLock) &&
                                enableFuntions.OnOffFunctionUnLock && (
                                    <>
                                        {stateCard === CardStates.BLOQUEO_TEMPORAL && (
                                            <Dropdown.Item>
                                                <div onClick={handleShowUnLockTemp}>
                                                    Desbloquear
                                                </div>
                                                <UnBlockCard
                                                    showModalUnLock={showModalUnLock}
                                                    handleCloseModalULockTemp={
                                                        handleCloseModalULockTemp
                                                    }
                                                    lastsFourNumCard={lastsFourNumCard}
                                                    dateExpiredCard={dateExpiredCard}
                                                    cardNumber={cardNumber}
                                                />
                                            </Dropdown.Item>
                                        )}
                                    </>
                                )}
                            <Dropdown.Divider />
                            {parseStringToBoolean(enablePortfolioPurchase) && (
                                <Dropdown.Item
                                    disabled={
                                        stateCard !== CardStates.ACTIVA ||
                                        (!!portfolioPurchaseProcess &&
                                            portfolioPurchaseProcess.length > 0 &&
                                            portfolioPurchaseProcess.some(
                                                (e) => e.isCompletedInCore === true
                                            ))
                                    }
                                    onClick={() =>
                                        history.push('/portfolio-purchase', {
                                            lastFrom: 'CCD',
                                            quotaAvailable,
                                        })
                                    }
                                >
                                    Comprar Cartera
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </OptionButton>
            </OptionsContainer>
        </MenuOptionsContainer>
    )
}
export default MenuOptions
