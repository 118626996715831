import { useEffect, useState } from 'react'
import { ArrowYellowSVG, CardGlobalPurpleSVG } from '../../utils/getIcons'
import {
    AlertCreditCard,
    ContentInfo,
    Image,
    ImageArrow,
    TextPBold,
} from './AlertCreditCardRequests-Styled'
import { useHistory } from 'react-router-dom'
import ModalRequestCampaign from './inc/ModalRequestCampaign'
import ModalRequestTC from './inc/ModalRequestTC/ModalRequestTC'
import {
    useDeleteCreditCardRequest,
    useGetAlertsNotificationsTC,
    useGetAlertsTC,
    useGetCreditCardRequest,
} from './hooks/useAlertCreditRequests'
import { useDispatch, useSelector } from 'react-redux'
import ModalLoading from './inc/Modals'
import ModalError from './inc/Modals/ModalError'
import { cleanDataPerStepAction, validateUserStateAction } from '../../../redux/openingTC'

interface Request {
    Id: number
    RequestSourceType: string
    RequestDate: string
    Type: string
    State: string
    Details: {
        RequestCreated: boolean
        RequestDate: string | null
        PendingDelivery: boolean
        PendingActivation: boolean
        LastFourDigits: string | null
    }
}

const AlertCreditCardRequests: React.FC = () => {
    const history = useHistory()
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showModalCampaign, setShowModalCampaign] = useState<boolean>(false)
    const [showModalTC, setShowModalTC] = useState<boolean>(false)
    const [isNewRequest, setIsNewRequest] = useState<boolean>(false)

    const [requestSourceType, setRequestSourceType] = useState<string>('')
    const [stateRequest, setStateRequest] = useState<string>('')

    const [requestData, setRequestData] = useState<Request | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const [isVisible, setIsVisible] = useState<boolean>(false)

    const dispatch = useDispatch()

    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }

    const handleNewRequest = (): void => {
        DeleteRequestTC()
        setShowModalTC(false)
        setShowModalCampaign(false)
    }

    const handleContinueRequest = (): void => {
        if (showModalCampaign) {
            redirection('/campaign-card-opening', 'PO')
        } else if (showModalTC) {
            redirection('/credit-card-opening', 'PO')
        }
    }

    const handleFormSubmit = (): void => {
        if (requestSourceType === 'Campaign' && stateRequest === 'Nuevo') {
            setShowModalCampaign(true)
        } else if (requestSourceType === 'Standard' && stateRequest === 'Nuevo') {
            setShowModalTC(true)
        } else {
            history.push({
                pathname: '/credit-card-list-request',
                state: { requestData, isNewRequest },
            })
        }
    }

    const handleClose = (): void => {
        setShowError(false)
        history.push('/home-wallet')
    }

    useEffect(() => {
        GetAlertsNotificationsTC()
        GetAlertsTC()
    }, [tokenSave])

    const GetAlertsNotificationsTC = (): void => {
        useGetAlertsNotificationsTC(tokenSave)
            .then((res: any) => {
                if (res['data']['Data'] === true) {
                    setIsNewRequest(true)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const GetAlertsTC = (): void => {
        useGetAlertsTC(tokenSave)
            .then((res: any) => {
                const responseCode = res.data?.Response || res.Response
                if (responseCode === '200') {
                    const resRequestSourceType = res.data?.Data.RequestSourceType
                    const state = res.data?.Data.State
                    setRequestSourceType(resRequestSourceType)
                    setStateRequest(state)
                    setRequestData(res.data?.Data)
                    setIsVisible(true)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const DeleteRequestTC = (): void => {
        setIsLoading(true)
        const id = requestData?.Id ?? 0
        useDeleteCreditCardRequest(tokenSave, id)
            .then((res: any) => {
                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                if (responseIsSuccess) {
                    GetCreditCardRequest()
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'Lo sentimos, en estos momentos no hemos podido procesar tu solicitud. Por favor, intenta más tarde'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorMessage('No se ha podido conectar con el servidor')
            })
    }

    const GetCreditCardRequest = (): void => {
        useGetCreditCardRequest(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                if (responseIsSuccess) {
                    redirectionFrom()
                } else {
                    setShowError(true)
                    setErrorMessage(
                        'Lo sentimos, en estos momentos no hemos podido procesar tu solicitud. Por favor, intenta más tarde'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowError(true)
                setErrorMessage('No se ha podido conectar con el servidor')
            })
    }

    const redirectionFrom = (): void => {
        if (requestSourceType === 'Campaign') {
            dispatch(cleanDataPerStepAction())
            dispatch(
                validateUserStateAction(({ data }) => {
                    if (data === null) {
                        setTimeout(() => {
                            redirection('/campaign-card-opening', 'PO')
                        }, 200)
                    }
                })
            )
        } else if (requestSourceType === 'Standard') {
            dispatch(cleanDataPerStepAction())
            dispatch(
                validateUserStateAction(({ data }) => {
                    if (data === null) {
                        setTimeout(() => {
                            redirection('/credit-card-opening', 'PO')
                        }, 200)
                    }
                })
            )
        }
    }
    const handleModalClose = (): void => {
        setShowModalCampaign(false)
        setShowModalTC(false)
    }

    return (
        <>
            {isVisible && (
                <>
                    <AlertCreditCard onClick={handleFormSubmit}>
                        <div className="card-content">
                            {isNewRequest && <div className="tag">1 nueva notificación</div>}

                            <div>
                                <ContentInfo>
                                    <Image src={CardGlobalPurpleSVG} alt="CardGlobalPurpleSVG" />
                                    <div>
                                        <TextPBold>Tus solicitudes</TextPBold>
                                        <p className="subtitle-card">
                                            Conoce tus solicitudes que tienes y el estado de cada
                                            una.
                                        </p>
                                    </div>
                                    <ImageArrow src={ArrowYellowSVG} alt="ArrowYellowSVG" />
                                </ContentInfo>
                            </div>
                        </div>
                    </AlertCreditCard>
                    <ModalRequestCampaign
                        showModal={showModalCampaign}
                        handleNewRequest={handleNewRequest}
                        handleContinueRequest={handleContinueRequest}
                        onHide={handleModalClose} // Pasamos la función para cerrar el modal
                    />
                    <ModalRequestTC
                        showModal={showModalTC}
                        handleNewRequest={handleNewRequest}
                        handleContinueRequest={handleContinueRequest}
                        onHide={handleModalClose} // Pasamos la función para cerrar el modal
                    />

                    <ModalLoading
                        isLoading={isLoading}
                        textInfo="Aguarda un momento"
                        textLoading={'Estamos procesando tu solicitud.'}
                    />

                    <ModalError
                        showModal={showError}
                        handleClose={handleClose}
                        titleMessage={'Ha ocurrido un error'}
                        errorMessage={errorMessage}
                    />
                </>
            )}
        </>
    )
}

export default AlertCreditCardRequests
