import { balancesExtractsService } from '../../../../../domain/services/User.service'

const useGetAgreementCertificateClient = (tokenSave: string) => {
    const getAgreementCertificateClient = async () => {
        try {
            const response = await balancesExtractsService.getAgreementCertificateClient(
                'api/Client/GetAgreementCertificateClient',
                tokenSave
            )

            return response
        } catch (error) {
            console.error('Error en la llamada al endpoint:', error)
            throw error
        }
    }

    return { getAgreementCertificateClient }
}

const useGetBalancesAndStatements = (tokenSave: string) => {
    const getBalancesAndStatements = async () => {
        try {
            const response = await balancesExtractsService.getBalancesAndStatements(
                'api/Products/GetBalancesAndStatements/GetBalancesAndStatements',
                tokenSave
            )

            return response
        } catch (error) {
            console.error('Error en la llamada al endpoint:', error)
            throw error
        }
    }

    return { getBalancesAndStatements }
}

export { useGetAgreementCertificateClient, useGetBalancesAndStatements }
