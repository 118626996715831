import { FC, useEffect, useRef } from 'react'
import { Form, Container, Row, Col, Pagination, PageItem } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import {
    ButtonPagination,
    ContainerForm,
    PaginationContent,
    Title,
} from './PersonalInformation-styles'
import { ListDataCrm, UserData } from '../../../../../../domain/models/crm'
import { yupResolver } from '@hookform/resolvers/yup'
import { personalInformationSchema } from '../../validations/ValidationShema'

interface PersonalInformationProps {
    userData: UserData
    onUpdate: (data: Partial<UserData>) => void
    educationList: ListDataCrm[]
    maritalStatusList: ListDataCrm[]
    occupationList: ListDataCrm[]
    contractTypesList: ListDataCrm[]
    laborRegimeList: ListDataCrm[]
    handleNextStep: (value: string) => void
}

const PersonalInformation: FC<PersonalInformationProps> = ({
    userData,
    onUpdate,
    educationList,
    maritalStatusList,
    occupationList,
    contractTypesList,
    laborRegimeList,
    handleNextStep,
}): JSX.Element => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<UserData>({
        resolver: yupResolver(personalInformationSchema),
        mode: 'all',
        defaultValues: userData,
    })

    const onSubmit = (data: Partial<UserData>): void => {
        const processedData = {
            ...data,
            LevelEducation: Number(data.LevelEducation),
            Occupation: Number(data.Occupation),
            MaritalStatus: Number(data.MaritalStatus),
            TypeContract: data.TypeContract ? Number(data.TypeContract) : null,
            DocumentNumber: Number(data.DocumentNumber),
        }
        onUpdate(processedData)
        handleNextStep('contactInfo')
    }

    const occupation = watch('Occupation')
    const initialOccupationRef = useRef(userData.Occupation)

    useEffect(() => {
        if (occupation !== 100000001 || occupation !== initialOccupationRef.current) {
            setValue('TypeContract', null)
        }
    }, [occupation, setValue])

    const handleIntChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        field: any
    ): void => {
        const { value } = event.target
        const cleanedValue = value.replace(/[^0-9]/g, '').substring(0, 20)
        setValue(field.name, parseInt(cleanedValue, 10))
        field.onChange(parseInt(cleanedValue, 10))
    }

    return (
        <Container>
            <Title>Datos personales</Title>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ContainerForm>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Nombres</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Name"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={200}
                                            {...field}
                                            isInvalid={!!errors.Name}
                                            disabled={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Name?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Primer Apellido</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="FirstLastName"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={200}
                                            {...field}
                                            isInvalid={!!errors.FirstLastName}
                                            disabled={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.FirstLastName?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Segundo Apellido</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="SecondLastName"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={200}
                                            {...field}
                                            isInvalid={!!errors.SecondLastName}
                                            disabled={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.SecondLastName?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Número de Identificación</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="DocumentNumber"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.DocumentNumber}
                                            onChange={(event) => handleIntChange(event, field)}
                                            type="number"
                                            disabled={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.DocumentNumber?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Nivel de Escolaridad</Form.Label>
                        </Col>
                        <Col>
                            <Controller
                                name="LevelEducation"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.LevelEducation}
                                            onChange={(event) => handleIntChange(event, field)}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {educationList.map((education) => (
                                                <option key={education.Id} value={education.Code}>
                                                    {education.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.LevelEducation?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Estado Civil</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="MaritalStatus"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.MaritalStatus}
                                            onChange={(event) => handleIntChange(event, field)}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {maritalStatusList.map((maritalStatus) => (
                                                <option
                                                    key={maritalStatus.Id}
                                                    value={maritalStatus.Code}
                                                >
                                                    {maritalStatus.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.MaritalStatus?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Ocupación</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Occupation"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.Occupation}
                                            onChange={(event) => handleIntChange(event, field)}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {occupationList.map((occupation) => (
                                                <option
                                                    key={occupation.Id}
                                                    value={parseInt(occupation.Code)}
                                                >
                                                    {occupation.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Occupation?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Tipo de Contrato</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="TypeContract"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.TypeContract}
                                            disabled={occupation !== 100000001}
                                            onChange={(event) => handleIntChange(event, field)}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {contractTypesList.map((contractType) => (
                                                <option
                                                    key={contractType.Id}
                                                    value={contractType.Code}
                                                >
                                                    {contractType.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.TypeContract?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        <Col md={2}>
                            <Form.Label>Régimen Laboral</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="LaborRegime"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.LaborRegime}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {laborRegimeList.map((laborRegime) => (
                                                <option
                                                    key={laborRegime.Id}
                                                    value={laborRegime.Code}
                                                >
                                                    {laborRegime.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.LaborRegime?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                </ContainerForm>
                <PaginationContent>
                    <Pagination>
                        <ButtonPagination>
                            <PageItem className="disableFm" onClick={handleSubmit(onSubmit)}>
                                Continuar {'>'}
                            </PageItem>
                        </ButtonPagination>
                    </Pagination>
                </PaginationContent>
            </form>
        </Container>
    )
}

export default PersonalInformation
