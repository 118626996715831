import React, { FC, useState, useEffect, useRef } from 'react'
import { DynamicKeyInputStyle, DynamicKeyInputWrap, LinkService } from './DynamicKeyInput-Styled'
import { Col, Container, Row } from 'react-bootstrap'
import ProgressTimer from '../ProgressTimer'

import { useHistory } from 'react-router-dom'
import { ArrowSVG, NotProductSVG } from '../../../../utils/getIcons'
import { ModalGeneric } from '../../../../components'
import { useValidateOTP } from '../../../CreditDetail/hooks/useCreditDetail'

interface DynamicKeyInputProps {
    tokenSave?: any
    handleLoading: (value: boolean) => void
    handleValidate: (value: boolean) => void
}

const DynamicKeyInput: FC<DynamicKeyInputProps> = ({
    tokenSave,
    handleLoading,
    handleValidate,
}): JSX.Element => {
    const [inputs, setInputs] = useState<string[]>(['', '', '', '', '', ''])
    const [error, setError] = useState<boolean>(false)
    const [messageError, setMessageError] = useState('')
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const [failedAttempts, setFailedAttempts] = useState<number>(0)
    const history = useHistory()

    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    useEffect(() => {
        inputRefs.current[0]?.focus()
    }, [])

    const OtpValidation = async (otpToValidate: string): Promise<boolean> => {
        try {
            const res: any = await useValidateOTP(tokenSave, otpToValidate)
            if (res['status'] === 200) {
                handleLoading(false)
                handleValidate(false)
                return true
            } else if (res['Response'] === '403') {
                handleLoading(true)
                handleValidate(false)
                setError(true)
                setMessageError('Verifique los datos e intente de nuevo')
                return false
            } else if (res['Response'] === '429') {
                handleLoading(true)
                handleValidate(false)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'Su Clave dinámica ha sido bloqueado por exceder intentos. Intenta mas tarde o comunícate con servicio al asociado.'
                )
                return false
            } else {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('Al validar la clave dinámica, por favor intente de nuevo.')
                handleLoading(true)
                handleValidate(false)
                return false
            }
        } catch (error) {
            handleLoading(true)
            handleValidate(false)
            console.error(error)
            setShowModal(true)
            setTextTitleModal('¡Ha ocurrido un error!')
            setTextBodyModal('No se ha podido conectar con el servidor')
            return false
        }
    }

    const handleValidateOtp = (enteredNumber: string): void => {
        OtpValidation(enteredNumber)
            .then((isValidCode) => {
                if (!isValidCode) {
                    setFailedAttempts(failedAttempts + 1)

                    if (failedAttempts + 1 >= 3) {
                        setError(true)
                        setMessageError(
                            'Su código ha sido bloqueado por exceder intentos. Intenta más tarde o comunícate con servicio al asociado'
                        )
                        handleLoading(true)
                    }
                } else {
                    handleLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const validateSequence = (value: string): boolean => {
        if (value.length !== 6) {
            handleLoading(true)
            return true
        } else {
            return false
        }
    }

    const handleInputChange = (index: number, value: string): void => {
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newInputs = [...inputs]
            newInputs[index] = value
            setInputs(newInputs)
            setError(false)
            if (value && index < inputs.length - 1) {
                inputRefs.current[index + 1]?.focus()
            }

            if (index === inputs.length - 1) {
                const enteredNumber = newInputs.join('')
                if (enteredNumber.length === 6) {
                    handleValidate(true)
                    handleValidateOtp(enteredNumber)
                }
            }
            validateSequence(newInputs.join(''))
        } else if (value === '') {
            const newInputs = [...inputs]
            newInputs[index] = ''
            setInputs(newInputs)
            setError(false)

            if (index > 0) {
                inputRefs.current[index - 1]?.focus()
            }
            validateSequence(newInputs.join(''))
        } else {
            setError(true)
            setMessageError('Ingrese solo dígitos numéricos.')
        }
    }

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Backspace' && !inputs[index]) {
            const newInputs = [...inputs]
            newInputs[index] = ''
            setInputs(newInputs)
            setError(false)

            if (index > 0) {
                inputRefs.current[index - 1]?.focus()
            }
            validateSequence(newInputs.join(''))
        }
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }

    return (
        <DynamicKeyInputWrap>
            <Container>
                <Row>
                    <Col md={12}>
                        {inputs.map((value, index) => (
                            <DynamicKeyInputStyle
                                key={index}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                                type="text"
                                value={value}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                pattern="[0-9]*"
                                inputMode="numeric"
                                maxLength={1}
                                style={{ borderColor: error ? '#dc3545' : '' }}
                                disabled={failedAttempts >= 3}
                            />
                        ))}
                    </Col>
                </Row>
                <Row style={{ textAlign: 'justify' }}>
                    <Col md={12}>
                        {error && (
                            <p style={{ color: '#dc3545', fontSize: '14px' }}>{messageError}</p>
                        )}
                    </Col>
                </Row>
                {failedAttempts >= 3 ? (
                    <Row>
                        <Col style={{ textAlign: 'initial' }}>
                            <LinkService onClick={() => history.push('/support-private')}>
                                {' '}
                                <img src={ArrowSVG} alt="ArrowSVG" />
                                {'   '} Contactar con servicio al asociado
                            </LinkService>
                        </Col>
                    </Row>
                ) : (
                    <ProgressTimer tokenSave={tokenSave}></ProgressTimer>
                )}
            </Container>
            <ModalGeneric
                show={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleButton={handleModalCloseInfo}
                handleClose={handleModalCloseInfo}
                textButton="Aceptar"
            />
        </DynamicKeyInputWrap>
    )
}

export default DynamicKeyInput
