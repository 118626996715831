import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { CertifiedWatermark } from '../../../../utils/getImages'
interface PDFGeneratorProps {
    Footer?: string
    ProductName: string
    ProductNumber: string
    OpeningDate: string
    AssociatedName: string
    DocumentNumber: string
    DocumentType: string
    RegistrationNumber: string
}

const PDFGenerator: React.FC<PDFGeneratorProps> = ({
    Footer,
    ProductName,
    ProductNumber,
    OpeningDate,
    AssociatedName,
    DocumentType,
    DocumentNumber,
    RegistrationNumber,
}) => {
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            padding: 30,
            fontWeight: 'bold',
            position: 'relative',
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 600, // Usar el ancho de página proporcionado
            height: 840, // Usar la altura de página proporcionada
            zIndex: -1, // Coloca la imagen de fondo detrás de otros elementos
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        header: {
            fontFamily: 'Helvetica-Bold',
            marginBottom: 5,
            marginTop: 100,
            textAlign: 'justify',
            fontSize: 15,
        },
        subHeader: {
            marginBottom: 20,
            textAlign: 'justify',
            fontSize: 15,
        },
        body: {
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },
        footer: {
            marginTop: 20,
            marginBottom: 5,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },
        Valediction: {
            marginTop: 20,
            textAlign: 'justify',
            fontSize: 12,
            color: '#425453',
        },

        certifica: {
            marginTop: 20,
            marginBottom: 20,
            fontFamily: 'Helvetica-Bold',
            fontSize: 13,
        },
        table: {
            width: '100%',
            border: 0,
        },
        tableRow: {
            width: '100%',
            flexDirection: 'row',
            border: 0,
        },
        tableCol: {
            width: '37%',
            padding: 5,
            border: 0,
        },
        tableCellCol1: {
            fontSize: 12,
            fontFamily: 'Helvetica-Bold',
            color: '#425453',
        },
        tableCellCol2: {
            fontSize: 12,
            color: '#425453',
        },
    })

    const tableData = [
        { column1: 'NOMBRE PRODUCTO:', column2: ProductName },
        { column1: 'NÚMERO DEL PRODUCTO:', column2: ProductNumber },
        { column1: 'FECHA APERTURA:', column2: OpeningDate },
    ]

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <Image src={CertifiedWatermark} style={styles.backgroundImage} fixed />
                <View style={styles.section} wrap={false}>
                    <Text style={styles.header}>
                        {
                            'Fondo de Empleados de los Trabajadores \ny Pensionados de Ecopetrol S.A CAVIPETROL'
                        }
                    </Text>
                    <Text style={styles.subHeader}>{'Identificado con NIT 860.006.773-2'}</Text>
                    <View style={styles.body}>
                        <Text style={styles.certifica}>CERTIFICA</Text>
                        <Text>{`\n\nQue el Señor(a) ${AssociatedName}, identificado(a) con ${DocumentType}  ${DocumentNumber}, y con registro número ${RegistrationNumber}, presenta la siguiente información a la fecha.`}</Text>
                    </View>
                    <View style={styles.table} wrap={false}>
                        {tableData.map((row, index) => (
                            <View key={index} style={styles.tableRow} wrap={false}>
                                <View style={styles.tableCol} wrap={false}>
                                    <Text style={styles.tableCellCol1}>{row.column1}</Text>
                                </View>
                                <View style={styles.tableCol} wrap={false}>
                                    <Text style={styles.tableCellCol2}>{row.column2}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <Text style={styles.footer}>{Footer}</Text>
                    <Text style={styles.Valediction}>{'\nAtentamente, Cavipetrol'}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default PDFGenerator
