import tw, { styled, css } from 'twin.macro'
import { Form, Button } from 'react-bootstrap'

import {
    FormLabel as FormLabelApp,
    FormGroup as FormGroupApp,
} from '../../../../components/includes/index'

export interface StyledWidthProps {
    width?: 'w-full' | 'w-1/2' | 'w-1/4' | 'w-11/12' | 'w-4/5'
}

export const StyledWidth = css<StyledWidthProps>`
    ${({ width }) => {
        switch (width) {
            case 'w-full':
                return tw`w-full`
            case 'w-1/2':
                return tw`w-1/2`
            case 'w-1/4':
                return tw`w-1/4`
            case 'w-11/12':
                return tw`w-11/12`
            case 'w-4/5':
                return tw`w-4/5`
            default:
                return tw`w-1/2`
        }
    }}
`

export const PossesionsFormWrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const PossesionsFormContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[550px] lg:max-w-[924px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0 my-2`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}
`
export const FormSelect = styled(Form.Select)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-10 border-none cursor-pointer`}
    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}
    background-image: var(--url-arrow-down-yellow-icon)!important;
    &:disabled {
        ${tw`bg-transparent`}
    }
`

interface FormOptionProps {
    show?: boolean
}

export const FormOption = styled.option<FormOptionProps>`
    ${tw`text-base cursor-pointer h-12.5`}
    display: ${(props) => (props.show ? 'none' : 'block')};
`
export const ButtonConsignar = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border: 2px solid transparent !important;
    color: #ffffff;

    &:hover {
        background-color: var(--sub-dominant-color);
        border: 2px solid transparent;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

ButtonConsignar.displayName = 'ButtonConsignar'
