import { ReactElement } from 'react'

// components
import FirstStep from '../FirstStep'
import SecondStep from '../SecondStep'
import ThirdStep from '../ThirdStep'
import FourthStep from '../FourthStep'
import FifthStep from '../FifthStep'
import SixthStep from '../SixthStep'
import ConnectionErrorView from '../ConnectionErrorView'
import EndFlow from '../EndFlow'
import CardQuotaRejected from '../CardQuotaRejected'

// hooks
import TwelfthStep from '../TwelfthStep'
import FifteenthStep from '../FifteenthStep'
import SixteenthStep from '../SixteenthStep'

import ThirteenthStep from '../ThirteenthStep'
import { useEntryModule } from '../../hooks/useEntryModule'
import ThirdStepExceptions from '../ThirdStepExceptions'

const EntryModule = (): ReactElement => {
    const { step } = useEntryModule()
    //const [step] = useReducerStep()
    return (
        <>
            {
                {
                    1: <FirstStep />,
                    2: <SecondStep />,
                    3: <ThirdStep />,
                    4: <FourthStep />,
                    5: <FifthStep />,
                    6: <SixthStep />,
                    7: <TwelfthStep />,
                    8: <FifteenthStep />,
                    9: <SixteenthStep />,
                    10: <ThirteenthStep />,
                    11: <EndFlow />,
                    12: <ThirdStepExceptions />,
                    18: <ConnectionErrorView />,
                    19: <CardQuotaRejected />,
                }[step.currentStep]
            }
        </>
    )
}

export default EntryModule
