import { FC } from 'react'
import {
    CodeptorBorderChild,
    CodeptorChild,
    CodeptorSection,
    Content,
    CodeptorInfo,
    TextP,
    TextPBold,
    Wrapper,
    CodeptorChildTitle,
} from '../../Codebtors-styles'
import { TicketMoneySVG } from '../../../../utils/getIcons'
import useMediaQuery from 'usehooks-ts/dist/esm/useMediaQuery/useMediaQuery'
import { Cobebtor } from '../../hooks/modelCodebtors'

interface CodebtorProps {
    infoCodebtor: Cobebtor[]
}

const CodebtorInfo: FC<CodebtorProps> = ({ infoCodebtor }): JSX.Element => {
    const matchMedia = useMediaQuery('(max-width: 460px)')
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    return (
        <>
            <TextP>Usted es codeudor de: </TextP>
            {infoCodebtor.map((index: Cobebtor) => (
                <Wrapper>
                    <Content>
                        <CodeptorInfo>
                            <CodeptorSection>
                                <CodeptorChild>
                                    <TextPBold className="largeInfo">{index.FullName}</TextPBold>
                                    <TextP>Nombre</TextP>
                                </CodeptorChild>
                                <CodeptorChild>
                                    <TextPBold>{index.Document}</TextPBold>
                                    <TextP className="largeInfo">Documento de identificación</TextP>
                                </CodeptorChild>
                                <CodeptorChild>
                                    <CodeptorChildTitle>
                                        {matchMedia ? (
                                            <CodeptorChildTitle>
                                                <div>
                                                    <TextPBold>{index.CreditNumber}</TextPBold>
                                                    <TextP>{index.CreditType}</TextP>
                                                </div>
                                            </CodeptorChildTitle>
                                        ) : (
                                            <CodeptorChildTitle>
                                                <img src={TicketMoneySVG} alt="credit" />
                                                <div>
                                                    <TextPBold>{index.CreditNumber}</TextPBold>
                                                    <TextP>{index.CreditType}</TextP>
                                                </div>
                                            </CodeptorChildTitle>
                                        )}
                                    </CodeptorChildTitle>
                                </CodeptorChild>
                                <CodeptorChild>
                                    <TextPBold>{formatter.format(index.TotalCapital)}</TextPBold>
                                    <TextP>Capital total</TextP>
                                </CodeptorChild>
                            </CodeptorSection>
                            <br></br>
                            <CodeptorSection>
                                <CodeptorBorderChild>
                                    <TextP>
                                        <span>Número de registro</span>
                                    </TextP>
                                    <TextP>{index.RegistryNumber}</TextP>
                                </CodeptorBorderChild>
                                <CodeptorBorderChild>
                                    <TextP>
                                        <span>Saldo en mora</span>
                                    </TextP>
                                    <TextP>{formatter.format(index.ArrearsBalance)}</TextP>
                                </CodeptorBorderChild>
                                <CodeptorBorderChild>
                                    <TextP>
                                        <span>Valor total</span>
                                    </TextP>
                                    <TextP>{formatter.format(index.TotalValue)}</TextP>
                                </CodeptorBorderChild>
                            </CodeptorSection>
                        </CodeptorInfo>
                    </Content>
                </Wrapper>
            ))}
        </>
    )
}

export default CodebtorInfo
