/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Loading } from '../../components/includes'
import {
    CardsProducts,
    ConsultProducts,
    CreditsProducts,
    EcopetrolCredit,
    GetProducts,
    Insurance,
    SavingProducts,
} from './components'
import { LayoutContent } from '../../transverse'

// Styles
import {
    ContainerHW,
    TopSection,
    SectionCards,
    SectionHW3,
    SectionHW4,
    TitleCredit,
    SectionCredits,
    CardError,
    CardErrorParagraph,
    CardErrorImage,
} from './homeWallet-styles'

import { EcopetrolCreditsContent } from './components/EcopetrolCredit/ecopetrolCredit-styles'
import { CreditsProductsContent } from './components/CreditsProducts/creditsProducts-styles'

// custom hooks
import { useAppTour } from '../../hooks'
import { useHomeWallet } from './hooks'

// utils
import { decryptKey, parseStringToBoolean } from '../../utils/misc'

// selectors
import { CreditCardsSelector } from '../../../selectors'

// actions
import { getCreditCardsAction } from '../../../redux/tc/tc.actions'

// models
import { CreditCard } from '../../../../domain/models'

// icons
import { ConnectionErrorSVG } from '../../utils/getIcons'
import {
    AdminCardCreditProducts,
    AdminCardEcopetrolCredit,
    AdminCardProducts,
    AdminCardSavingProducts,
} from './components/CardsProductsAdmin'
import eventBus from '../../utils/eventBus'

const enableCreditCard = process.env.REACT_APP_CREDIT_CARD_ENABLE as 'false' | 'true'

const findMessage = (message: string): string => message.split('|')[1]

const HomeWallet = (): JSX.Element => {
    const dispatch = useDispatch()

    // selectors
    const { data } = useSelector(CreditCardsSelector)

    // hooks
    const { credits, loadingCredits, cards, loadingCards, errorCards, errorCredits } =
        useHomeWallet()
    useAppTour()

    const encryptedKey = localStorage.getItem('isUserRol')
    let loggedUser = ''

    if (encryptedKey) {
        loggedUser = decryptKey(encryptedKey)
    }
    // listeners
    useEffect(() => {
        dispatch(getCreditCardsAction())
    }, [])

    const sectionDestinationRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleScrollToCards = ({ id }: { id: string }): void => {
            if (sectionDestinationRef.current && sectionDestinationRef.current.id === id) {
                sectionDestinationRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }

        eventBus.on('scrollToDestination', handleScrollToCards)

        return () => {
            eventBus.off('scrollToDestination', handleScrollToCards)
        }
    }, [])

    return (
        <LayoutContent>
            <ContainerHW>
                <TopSection>
                    <GetProducts />
                </TopSection>

                <SectionCards>
                    {loadingCards ? (
                        <>
                            {[1, 2].map((card) => (
                                <CreditsProductsContent key={card}>
                                    <div className="card-content">
                                        <Loading text="Aguarda un momento, estamos consultando la información" />
                                    </div>
                                </CreditsProductsContent>
                            ))}
                        </>
                    ) : (
                        <>
                            {loggedUser === 'Asociado' ? (
                                <>
                                    {' '}
                                    {errorCards.errorData && errorCredits.errorData?.message && (
                                        <CardError>
                                            <CardErrorImage
                                                src={ConnectionErrorSVG}
                                                alt="Logo Error"
                                            />
                                            <CardErrorParagraph>
                                                {findMessage(errorCredits.errorData?.message)}
                                            </CardErrorParagraph>
                                        </CardError>
                                    )}
                                    {cards
                                        .filter((card) => card.title === 'Créditos disponibles')
                                        .map((card) => (
                                            <CreditsProducts
                                                key={`${card.title}-${card.quotaspayable}-available-credits`}
                                                card={card}
                                            />
                                        ))}
                                    {cards
                                        .filter((card) => card.title !== 'Créditos disponibles')
                                        .map((card) => (
                                            <SavingProducts
                                                key={`${card.title}-${card.quotaspayable}-savings-products`}
                                                card={card}
                                            />
                                        ))}
                                </>
                            ) : (
                                <>
                                    <AdminCardCreditProducts rol={loggedUser} />
                                    {loggedUser === 'Administrador' && <AdminCardSavingProducts />}
                                </>
                            )}
                        </>
                    )}
                </SectionCards>

                <SectionHW3 id="section-cards" ref={sectionDestinationRef}>
                    {parseStringToBoolean(enableCreditCard) && (
                        <>
                            {loggedUser === 'Administrador' ? (
                                <>
                                    <AdminCardProducts />
                                </>
                            ) : (
                                <>
                                    {loggedUser === 'Asociado' && (
                                        <>
                                            {data?.cards.map((card: CreditCard) => (
                                                <CardsProducts
                                                    info={card}
                                                    key={card.lastFourDigitsEncryptedCC}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {parseStringToBoolean(enableCreditCard) && loggedUser === 'Asociado' && (
                        <Insurance />
                    )}
                </SectionHW3>
                {(loggedUser === 'Administrador' || loggedUser === 'Asociado') && (
                    <>
                        <SectionHW4>
                            <ConsultProducts />
                        </SectionHW4>

                        {credits.length > 0 && <TitleCredit>Créditos Ecopetrol</TitleCredit>}
                    </>
                )}

                <SectionCredits>
                    {loadingCredits ? (
                        <EcopetrolCreditsContent>
                            <div className="card-content">
                                <Loading text="Aguarda un momento, estamos consultando la información" />
                            </div>
                        </EcopetrolCreditsContent>
                    ) : (
                        <>
                            {loggedUser === 'Administrador' ? (
                                <>
                                    <AdminCardEcopetrolCredit />
                                </>
                            ) : (
                                <>
                                    {loggedUser === 'Asociado' && (
                                        <>
                                            {errorCredits.errorData &&
                                                errorCredits.errorData?.message && (
                                                    <CardError>
                                                        <CardErrorImage
                                                            src={ConnectionErrorSVG}
                                                            alt="Logo Error"
                                                        />
                                                        <CardErrorParagraph>
                                                            {findMessage(
                                                                errorCredits.errorData?.message
                                                            )}
                                                        </CardErrorParagraph>
                                                    </CardError>
                                                )}
                                            {credits.map((credit) => (
                                                <EcopetrolCredit
                                                    key={`${credit.creditNumber}-${credit.finishedNumber}`}
                                                    credit={credit}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </SectionCredits>
            </ContainerHW>
        </LayoutContent>
    )
}
export default HomeWallet
