import { AxiosResponse } from 'axios'
import { certificateService } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function fetchFilesRegulations(tokenSave: string): Promise<Result> {
    try {
        return await certificateService
            .getData('/api/ManagedContent/ListBlobFilesRegulations', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
