import { useSelector } from 'react-redux'
import { ButtonContent, ButtonsContainer, ModalButton, ModalRol } from './ModalFormAssignRol-styles'
import { Modal, Form } from 'react-bootstrap'
import { Button } from '../../../../components'
import { useState } from 'react'
import { useUpdateUserRole } from '../../hooks/useAssignRoles'
import { ContainerTitle, SpaceStep, Title } from '../../AssignRoles-styles'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import ModalError from '../ModalError'
interface Rol {
    Id: number
    RoleName: string
    Part: string
}

interface ModalFormAssignRolProps {
    showModal?: boolean
    handleCloseModal: () => void
    documentUser: string
    rolName: string
    idRol: number
    roles: Rol[]
    handleRefreshUser: () => void
}

const ModalFormAssignRol: React.FC<ModalFormAssignRolProps> = ({
    showModal,
    handleCloseModal,
    documentUser,
    rolName,
    idRol,
    roles,
    handleRefreshUser,
}): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [rolAssign, setRolAssign] = useState<string>(rolName)
    const [rolIdAssign, setRolIdAssign] = useState<number>(idRol)
    const methods = useForm()
    const { control, formState, setError, clearErrors } = methods
    const { errors } = formState
    const [textBodyModal, setTextBodyModal] = useState('')
    const [showModalError, setShowModalError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(rolAssign === rolName)

    const HandleFormSubmit = (): void => {
        setIsLoading(true)

        useUpdateUserRole(tokenSave, documentUser, rolIdAssign)
            .then((res: any) => {
                setIsLoading(false)
                if (res['status'] === 200) {
                    handleRefreshUser()
                    setRolAssign(rolName)
                } else {
                    setShowModalError(true)
                    setTextBodyModal(
                        'No se ha podido procesar tu solicitud, por favor intenta de nuevo.'
                    )
                }
                handleCloseModal()
            })
            .catch((error) => {
                console.error(error)
                setShowModalError(true)
                setTextBodyModal('No se ha podido conectar con el servidor')
                handleCloseModal()
            })
    }

    const handleClose = (): void => {
        setShowModalError(false)
    }

    const onCancel = (): void => {
        handleCloseModal()
        setRolAssign(rolName)
    }

    return (
        <>
            <ModalRol
                show={showModal}
                onHide={onCancel}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Title>
                    <ContainerTitle>
                        <Title>Asignar rol</Title>
                    </ContainerTitle>
                </Modal.Title>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Controller
                            control={control}
                            name={'rolAssign'}
                            rules={{
                                required: rolAssign === '' ? 'Este campo es requerido' : false,
                            }}
                            render={({ field: { onChange, onBlur } }) => (
                                <Form.Select
                                    className="selectFromControl"
                                    value={rolAssign}
                                    onChange={(event) => {
                                        const selectedRole = event.target.value
                                        const selectedRol = roles.find(
                                            (rol: Rol) => rol.RoleName === selectedRole
                                        )
                                        setRolAssign(selectedRole)
                                        if (selectedRol) {
                                            const { Id } = selectedRol
                                            setRolIdAssign(Id)
                                        }
                                        if (rolName === selectedRole) {
                                            setIsDisabled(true)
                                        } else {
                                            setIsDisabled(false)
                                            clearErrors('rolAssign')
                                        }
                                        onChange(event)
                                    }}
                                    onBlur={() => {
                                        if (rolAssign === rolName) {
                                            setError('rolAssign', {
                                                message:
                                                    'Para asignar un rol, selecciona uno distinto al actual',
                                            })
                                        } else {
                                            clearErrors('rolAssign')
                                        }
                                        onBlur()
                                    }}
                                    isInvalid={!!errors['rolAssign']}
                                >
                                    {roles.map((rol: Rol) => (
                                        <option
                                            key={rol.Id}
                                            value={rol.RoleName}
                                            selected={rol.RoleName === rolAssign}
                                        >
                                            {rol.RoleName}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                        />
                        {errors['rolAssign'] && (
                            <Form.Control.Feedback type="invalid">
                                {errors['rolAssign']?.message}
                            </Form.Control.Feedback>
                        )}
                        <SpaceStep />
                        <ButtonsContainer>
                            <ModalButton variant="primary" onClick={onCancel}>
                                Cancelar
                            </ModalButton>

                            <ButtonContent>
                                <Button
                                    id="btnSubmit"
                                    className="button-block"
                                    variant="sub-dominant"
                                    type="button"
                                    onClick={HandleFormSubmit}
                                    isLoading={isLoading}
                                    extend
                                    disabled={isLoading || isDisabled}
                                >
                                    Guardar
                                </Button>
                            </ButtonContent>
                        </ButtonsContainer>
                    </FormProvider>
                </Modal.Body>
            </ModalRol>
            <ModalError
                showModal={showModalError}
                handleClose={handleClose}
                titleMessage={'¡Ha ocurrido un error!'}
                errorMessage={textBodyModal}
            />
        </>
    )
}

export default ModalFormAssignRol
