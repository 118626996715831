import { Col, Row } from 'react-bootstrap'
import { ContainerTitle, Title, Wrapper } from '../../CavifacilTransaction-Styled'

import {
    ContainerButtons,
    Content,
    TextInfo,
    TextSimpleDate,
    TextSimpleInfo,
    TextState,
    TextTitle,
    UserDate,
} from './TransactionResponse-Styled'
import { Button } from '../../../../components'
import { useHistory } from 'react-router-dom'
import { CreditUmbrellaSVG, PDFFileIcon } from '../../../../utils/getIcons'
import moment from 'moment'
import {
    formatDecimalValue,
    formatValue,
} from '../../../../components/GlobalFuntions/globalFunction'
import PDFGenerator from '../PDFGenerator'
import { pdf } from '@react-pdf/renderer'
import 'moment/locale/es'

interface TransactionResponseProps {
    associatedName: string
    dateTransaction: string
    creditNumber: string
    documentNumber: string
    value: string
}
const TransactionResponse: React.FC<TransactionResponseProps> = ({
    associatedName,
    dateTransaction,
    creditNumber,
    documentNumber,
    value,
}) => {
    const history = useHistory()
    const date = moment().format('YYYY-MM-DD')

    const handlePrintPDF = async () => {
        try {
            const pdfBlob = await pdf(
                <PDFGenerator
                    associatedName={associatedName}
                    dateTransaction={moment(dateTransaction).format('DD/MM/YYYY hh:mm:ss a')}
                    creditNumber={creditNumber}
                    documentNumber={documentNumber}
                    value={value}
                />
            ).toBlob()

            const url = URL.createObjectURL(pdfBlob)

            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = `Comprobante CaviFácil_${date}.pdf`

            downloadLink.click()

            URL.revokeObjectURL(url)
        } catch (error) {
            console.error('Error al generar el PDF:', error)
        }
    }

    return (
        <>
            <ContainerTitle>
                <Title>
                    <span>Respuesta</span>
                </Title>
            </ContainerTitle>
            <Wrapper>
                <Content>
                    <UserDate>
                        {' '}
                        Sr(a): <span>{associatedName}</span>
                    </UserDate>
                    <TextInfo>
                        A continuación te presentamos la información de la transacción de CaviFácil.
                    </TextInfo>
                </Content>
            </Wrapper>
            <Wrapper>
                <Content className="second-content">
                    <TextTitle>Transacción exitosa</TextTitle>
                    <TextState>Estado</TextState>
                    <Row className="row-transaction">
                        <Col md={3}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <img src={CreditUmbrellaSVG} alt="" />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                    <TextSimpleInfo>CaviFácil</TextSimpleInfo>
                                    <TextSimpleDate>{creditNumber}</TextSimpleDate>
                                </div>
                            </div>
                        </Col>

                        <Col md={3}>
                            <TextSimpleInfo>{documentNumber}</TextSimpleInfo>
                            <TextSimpleDate>Cédula</TextSimpleDate>
                        </Col>
                        <Col md={3}>
                            <TextSimpleInfo>
                                {' '}
                                $ {formatValue(value, 1)}
                                <sup>{formatDecimalValue(value, 1)}</sup>
                            </TextSimpleInfo>
                            <TextSimpleDate>Valor</TextSimpleDate>
                        </Col>
                        <Col md={3}>
                            {' '}
                            <TextSimpleInfo>
                                {moment(dateTransaction).format('DD/MM/YYYY hh:mm:ss a')}
                            </TextSimpleInfo>
                            <TextSimpleDate>Fecha y Hora</TextSimpleDate>
                        </Col>
                    </Row>

                    <Row className="row-pdf">
                        <Content className="download">
                            <img src={PDFFileIcon} alt="" width={52} /> Descarga el comprobante de
                            tu CaviFácil en PDF haciendo clic{' '}
                            <button className="button-download" onClick={handlePrintPDF}>
                                aquí
                            </button>
                        </Content>
                    </Row>

                    <ContainerButtons>
                        <Button
                            variant="sub-dominant"
                            type="submit"
                            extend
                            onClick={() => history.push('/home-wallet')}
                        >
                            Terminar
                        </Button>
                    </ContainerButtons>
                </Content>
            </Wrapper>
        </>
    )
}

export default TransactionResponse
