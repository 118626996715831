import { FC } from 'react'
import { AirplaneGlobalSVG } from '../../../../utils/getIcons'
// styles
import {
    Wrapper,
    Content,
    ActivateInternationalTitle,
    YellowTitle,
    AlignContent,
    TextP,
    TextPBold,
    ButtonsContainer,
    ModalButton,
    ModalCancelButton,
} from '../../activateInternationalCard-Styles'

interface StepProps {
    tokenSave: string
    handleNextStep: () => void
    redirections: (value: string) => void
}

const InitialInfo: FC<StepProps> = ({ tokenSave, handleNextStep, redirections }): JSX.Element => {
    return (
        <>
            <ActivateInternationalTitle>
                Solicita la activación de tu tarjeta de crédito <span>fuera de Colombia</span>
            </ActivateInternationalTitle>

            <Wrapper>
                <Content>
                    <YellowTitle>Activar tarjeta fuera de Colombia</YellowTitle>
                    <AlignContent>
                        <img src={AirplaneGlobalSVG} alt="black" />
                        <TextP className="pInfo-center">
                            Diligencia este formulario si deseas utilizar una de tus tarjetas de
                            crédito fuera del país.
                        </TextP>
                        <TextP className="pInfo-center">
                            El diligenciamiento de esta información es responsabilidad del
                            tarjetahabiente,
                            <TextPBold>
                                el no diligenciamiento de toda la información ocasionará el bloqueo
                                de la tarjeta para uso internacional.
                            </TextPBold>
                        </TextP>
                    </AlignContent>
                    <ButtonsContainer>
                        <ModalCancelButton variant="primary" onClick={() => redirections('/')}>
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton type="button" onClick={handleNextStep}>
                            Continuar
                        </ModalButton>
                    </ButtonsContainer>
                </Content>
            </Wrapper>
        </>
    )
}

export default InitialInfo
