/* eslint-disable prettier/prettier */
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormState } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import * as yup from 'yup'

// components
import {
    Form,
    FormControl,
    FormMessage,
    InputGroup,
    InputGroupText,
    InputMask,
    InputSelectGroup,
} from '../../../../components'
import {
    ControlsButtons,
    FileAndProgressBar,
    HeadStep,
    SaveSuccessModal,
} from '../../../OpeningCreditCard/inc'
import NavigationStep from '../NavigationStep'

// styles
import {
    SixteenthStepWrapper,
    SixteenthStepContent,
    UploadLabel,
    UploadInput,
    UploadSpan,
    CtrRowField,
    UploadFileContainer,
    FileUploadContainer,
    FileUploadPrompt,
    UploaderFileImg,
    UploaderFileText,
    UploadFileRight,
    TextInstruction,
    UploaderFileH4,
} from './sixteenthStep-styles'
import {
    FormGroup,
    FormLabel,
    FormMessageSelect,
    FormOption,
    FormSelect,
    Image,
    ParagraphStep,
} from '../../campaignCard-styles'

// Icons
import { BankSVG, MiniCardSVG, MoneySVG, UploadFileSVG } from '../../../../utils/getIcons'

// hooks
import {
    useSecondStepForm,
    secondStepSchema,
    SecondStepType,
    useStatusTCHook,
    useStatusUpdate,
} from '../../hooks/useSixteenthStepForm'
import { useReducerStep } from '../../hooks'

// actions
import { getAllBanksAction } from '../../../../../redux/tc'
import {
    getPurchaseExtractFileAction,
    getCleanPortfolioPurchaseAction,
} from '../../../../../redux/portfolioPurchaseTC'

// selectors
import {
    useSelector,
    getAllBanksSelector,
    createBuyWalletSelector,
    getAllBuyWalletSelector,
} from '../../../../../selectors'

// utils
import { decryptKey, encryptKey, getBase64 } from '../../../../utils/misc'
import { formatValue } from '../../../../components/GlobalFuntions/globalFunction'
import ModalInfo from '../../../CreditDetail/inc/ModalInfo/ModalInfo'
import { useGetCampaignClient } from '../../hooks/useFifteenthStepForm'
import ModalLoading from '../../../ActivateInternationalCard/inc/ModalInfo/ModalLoading'

const SecondStep = (): ReactElement => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [{ secondStep, isEdit, idPurchase }, dispatchStep] = useReducerStep()
    const [isLoading, setIsLoading] = useState(false)

    const MAX_SIZE = 15000000

    // initial states
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)
    const [quotaAvailable, setQuotaAvailable] = useState(0)

    const QUOTE_AVAILABLE = quotaAvailable
    console.log(QUOTE_AVAILABLE, 'monto disponible')

    /* const QUOTE_AVAILABLE = state?.quotaAvailable ? state?.quotaAvailable : 0 */
    // selectors
    const { buyWallet, count } = useSelector(getAllBuyWalletSelector)
    const { banks } = useSelector(getAllBanksSelector)
    const { loading: loadingSave } = useSelector(createBuyWalletSelector)

    const defaultNameBank = secondStep.nameBank
        ? banks.find((item) => item.tcbId.toString() === secondStep.nameBank)?.tcbNombre
        : ''

    const creditCardNumber = secondStep.creditCardNumber
        ? decryptKey(secondStep.creditCardNumber)
        : ''

    const newSecondStepSchema = secondStepSchema.concat(
        yup.object({
            amountBuy: yup
                .string()
                .test(
                    'lessThan',
                    `Debe ser mayor a $0 y menor a $${isEdit
                        ? formatValue(
                            QUOTE_AVAILABLE - (count - parseInt(secondStep.amountBuy)),
                            1
                        )
                        : formatValue(QUOTE_AVAILABLE - count, 1)
                    }`,
                    (v) => {
                        if (isEdit) {
                            return (
                                parseInt(v ?? '') <=
                                QUOTE_AVAILABLE - (count - parseInt(secondStep.amountBuy))
                            )
                        }
                        return parseInt(v ?? '') <= QUOTE_AVAILABLE - count
                    }
                )
                .required('Campo obligatorio'),

        })
    )

    const defaultValuesRef = useRef({
        nameBank: defaultNameBank ?? '',
        otherNameBank: secondStep.otherNameBank,
        creditCardNumber: creditCardNumber,
        amountBuy: secondStep.amountBuy,
        formatFile: null,
    })
    const state1 = useSelector((stateRef: any) => stateRef)
    const tokenSave = state1.auth.token
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [showModal, setShowModal] = useState(false)
    // hooks
    const {
        handleSubmit,
        watch,
        control,
        register,
        setValue,
        getValues,
        cleanSecondStep,
    } = useSecondStepForm({
        validationSchema: yupResolver(newSecondStepSchema),
        defaultValues: defaultValuesRef.current,
    })
    const { isValid, errors } = useFormState({ control })
    watch()

    // listeners
    useEffect(() => {
        dispatch(getAllBanksAction())
        GetCampaignClient()
        setResponseData()
        return () => {
            cleanSecondStep()
        }
    }, [])
    const GetCampaignClient = (): void => {
        setIsLoading(true)
        useGetCampaignClient(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    setIsLoading(false)
                    setQuotaAvailable(res.data.Data.AssignedFee)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }
    const [nameFiles, setNameFiles] = useState<{ name: string }[]>([])

    const OnSubmit = async (dataToSend: SecondStepType): Promise<void> => {
        const idBank = banks.find((bank) => bank.tcbNombre === dataToSend.nameBank)?.tcbId

        if (dataToSend && idBank) {
            if (!isEdit) {
                RegisterCampaign(dataToSend, idBank)
            } else {
                UpdateCampaign(dataToSend, idBank)
            }
        }
    }

    const RegisterCampaign = (dataToSend: SecondStepType, idBank: number): void => {
        console.log('Metodo registrar')
        const dataSend = {
            CardNumber: encryptKey(dataToSend.creditCardNumber),
            PurchaseAmount: parseInt(dataToSend.amountBuy),
            BankEntityId: idBank,
            AlternativeBankName: dataToSend.otherNameBank ? dataToSend.otherNameBank : '',
        }
        // Construye el FormData
        const formDataToSend = new FormData()
        formDataToSend.append('body', JSON.stringify(dataSend))

        useStatusTCHook(formDataToSend, tokenSave, files)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setShowSuccessModal(true)
                } else {
                    console.log('NO FUNCIONA')
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('intente de nuevo')
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('intente de nuevo')
            })
    }

    const UpdateCampaign = (dataToSend: SecondStepType, idBank: number): void => {
        const dataSend = {
            CardNumber: encryptKey(dataToSend.creditCardNumber),
            PurchaseAmount: parseInt(dataToSend.amountBuy),
            BankEntityId: idBank,
            Id: idPurchase,
            AlternativeBankName: dataToSend.otherNameBank ? dataToSend.otherNameBank : '',
        }
        // Construye el FormData
        const formDataToSend = new FormData()
        formDataToSend.append('body', JSON.stringify(dataSend))
        setIsLoadingUpdate(true)
        useStatusUpdate(formDataToSend, tokenSave, files)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setShowSuccessModal(true)
                } else {
                    console.log('NO FUNCIONA')
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('intente de nuevo')
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('intente de nuevo')
            })
    }

    const onCloseModal = (): void => {
        setShowSuccessModal(false)
        dispatchStep({
            type: 'GO_TO_STEP',
            payload: {
                step: 8,
            },
        })
    }

    const onLoadingCheck = (): boolean => (!isEdit ? loadingSave : isLoadingUpdate)

    const setResponseData = (): void => {
        if (isEdit && buyWallet) {
            const file = buyWallet.find((i) => i.cardNumber === secondStep.creditCardNumber)
            const fileName = file?.debtCertificateUrl
                ? file.debtCertificateUrl.split('/')[4]
                : 'fileName'
            const containerName = file?.debtCertificateUrl
                ? file.debtCertificateUrl.split('/')[3]
                : 'debtpurchases'

            dispatch(
                getPurchaseExtractFileAction(fileName, containerName, (resp) => {
                    const blob = new Blob([resp], { type: 'application/pdf' })
                    getBase64(blob).then((fileBlob) => {
                        setValue('formatFile', fileName, {
                            shouldValidate: true,
                        })

                        setNameFiles([
                            {
                                name: fileName,
                            },
                        ])
                    })
                })
            )
        }
    }

    const [files, setFiles] = useState<File[]>([])

    const fileDropzone = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png', '.bmp'],
            'application/pdf': ['.pdf'],
        },
        multiple: false,
        maxSize: MAX_SIZE,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles)
            setValue('formatFile', acceptedFiles, {
                shouldValidate: true,
            })
            if (isEdit) {
                setNameFiles([])
            }
        },
    })

    const onDeleteFile = (nameToDelete: string): void => {
        const updatedFiles = files.filter((files) => files.name !== nameToDelete)
        setFiles(updatedFiles)
        if (isEdit) {
            const updatedFiles = nameFiles.filter((nameFiles) => nameFiles.name !== nameToDelete)
            setNameFiles(updatedFiles)
        }
    }
    const isValidForm = (): boolean => {
        if (!isEdit) {
            return !isValid
        }

        const validForm =
            errors.amountBuy || errors.creditCardNumber || errors.nameBank || errors.otherNameBank

        if (getValues('nameBank') === 'OTROS') {
            return validForm === undefined && files.length > 0 && getValues('otherNameBank') !== ''
                ? false
                : true
        }
        return validForm === undefined && files.length > 0 ? false : true
    }

    const onCancel = (): void => {
        dispatch(getCleanPortfolioPurchaseAction())
        history.push('/home-wallet')
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }

    return (
        <>
            <NavigationStep />
            <SixteenthStepWrapper>
                <SixteenthStepContent>
                    <Form onSubmit={handleSubmit(OnSubmit)}>
                        <HeadStep
                            title='Solicitud / Compra de cartera'
                            paragraph="Agrega la información de la tarjeta de crédito que deseas comprar"
                        />

                        <FormGroup $heightSize="105px">
                            <FormLabel>Banco de destino</FormLabel>
                            <InputSelectGroup $isError={!!errors.nameBank} $haveImg>
                                <Image src={BankSVG} alt="clave" />
                                <FormSelect size="lg" {...register('nameBank')}>
                                    <FormOption disabled value="" show>
                                        Selecciona una opción
                                    </FormOption>
                                    {banks.map((bank) => (
                                        <FormOption key={bank.tcbId}>{bank.tcbNombre}</FormOption>
                                    ))}
                                </FormSelect>
                            </InputSelectGroup>
                            <FormMessageSelect>
                                {errors.nameBank && errors.nameBank.message}
                            </FormMessageSelect>
                        </FormGroup>

                        {getValues('nameBank') === 'OTROS' && (
                            <FormGroup>
                                <FormLabel>Especifica que banco</FormLabel>
                                <InputGroup hasValidation>
                                    <InputGroupText $inputError={!!errors.otherNameBank}>
                                        <Image src={BankSVG} alt="clave" />
                                    </InputGroupText>
                                    <FormControl
                                        {...register('otherNameBank')}
                                        placeholder="Ej. Cavipetrol"
                                        isInvalid={!!errors.otherNameBank}
                                        max={16}
                                        maxLength={16}
                                    />
                                    <FormMessage type="invalid">
                                        {errors.otherNameBank && errors.otherNameBank.message}
                                    </FormMessage>
                                </InputGroup>
                            </FormGroup>
                        )}

                        <ParagraphStep>Datos de la tarjeta a comprar</ParagraphStep>

                        <CtrRowField>
                            <FormGroup>
                                <FormLabel>Numero de tarjeta</FormLabel>
                                <InputGroup hasValidation>
                                    <InputGroupText $inputError={!!errors.creditCardNumber}>
                                        <Image src={MiniCardSVG} alt="icono" />
                                    </InputGroupText>
                                    <InputMask
                                        {...register('creditCardNumber')}
                                        mask="num"
                                        blocks={{
                                            num: {
                                                mask: '0000  0000  0000  0000',
                                            },
                                        }}
                                        unmask={true}
                                        onAccept={(value) => {
                                            setValue('creditCardNumber', value.toString(), {
                                                shouldValidate: true,
                                            })
                                        }}
                                        defaultValue={defaultValuesRef.current.creditCardNumber}
                                        placeholder="Ej. 1234 5678 9101 1234"
                                        isInvalid={!!errors.creditCardNumber}
                                    />
                                    <FormMessage type="invalid">
                                        {errors.creditCardNumber && errors.creditCardNumber.message}
                                    </FormMessage>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Valor a comprar</FormLabel>
                                <InputGroup hasValidation>
                                    <InputGroupText $inputError={!!errors.amountBuy}>
                                        <Image src={MoneySVG} alt="icono" />
                                    </InputGroupText>
                                    <InputMask
                                        {...register('amountBuy')}
                                        mask="num"
                                        blocks={{
                                            num: {
                                                mask: Number,
                                                thousandsSeparator: '.',
                                                signed: false,
                                                scale: 0,
                                            },
                                        }}
                                        unmask={true}
                                        onAccept={(value) => {
                                            if (value !== '0') {
                                                setValue('amountBuy', value.toString(), {
                                                    shouldValidate: true,
                                                })
                                            }
                                        }}
                                        defaultValue={defaultValuesRef.current.amountBuy}
                                        placeholder="Ej. $1.500.000"
                                        isInvalid={!!errors.amountBuy}
                                        maxLength={10}
                                    />
                                    <FormMessage type="invalid">
                                        {errors.amountBuy && errors.amountBuy.message}
                                    </FormMessage>
                                </InputGroup>
                            </FormGroup>
                        </CtrRowField>
                        {isEdit && nameFiles.length > 0 && (
                            <>
                                {nameFiles.map((file) => (
                                    <FileAndProgressBar
                                        key={file.name}
                                        fileName={file.name}
                                        onDelete={onDeleteFile}
                                    />
                                ))}
                            </>
                        )}
                        {files.length > 0 && (
                            <>
                                {files.map((file) => (
                                    <FileAndProgressBar
                                        key={file.name}
                                        fileName={file.name}
                                        onDelete={onDeleteFile}
                                    />
                                ))}
                            </>
                        )}
                        <UploadFileContainer>
                            <FileUploadContainer {...fileDropzone.getRootProps()}>
                                <input {...fileDropzone.getInputProps()} />
                                <FileUploadPrompt>
                                    <UploaderFileImg src={UploadFileSVG} alt="Icon" />
                                    <UploaderFileH4>
                                        Arrastra y suelta los archivos sobre esta área delimitada
                                    </UploaderFileH4>
                                    <UploaderFileText>
                                        JPG, PNG, PDF de máximo 15mb
                                    </UploaderFileText>
                                </FileUploadPrompt>
                            </FileUploadContainer>
                            <UploadFileRight>
                                <TextInstruction>
                                    O si prefieres busca tus archivos desde tu dispositivo
                                </TextInstruction>
                                <UploadLabel htmlFor="upload">
                                    <UploadInput
                                        type="file"
                                        id="upload"
                                        accept=".pdf,.jpeg,.jpg,.png,.bmp,.svg"
                                        name="file"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const { files } = e.target
                                            const accFiles = files as unknown as File[]

                                            const newFiles = Array.from(accFiles)

                                            if (newFiles[0].size < MAX_SIZE) {
                                                setFiles(newFiles)
                                                setValue('formatFile', accFiles, {
                                                    shouldValidate: true,
                                                })
                                            }
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    <UploadSpan>Elegir archivo</UploadSpan>
                                </UploadLabel>
                            </UploadFileRight>
                        </UploadFileContainer>

                        <ControlsButtons
                            disable={isEdit ? false : isValidForm()}
                            nextText="Guardar"
                            isSave={false}
                            isLoading={onLoadingCheck()}
                            onCancel={onCancel}
                        />
                    </Form>
                </SixteenthStepContent>
                <ModalLoading isLoading={isLoading} />
            </SixteenthStepWrapper>
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleModalCloseInfo}
            />
            <SaveSuccessModal show={showSuccessModal} onClose={onCloseModal} />
        </>
    )
}

export default SecondStep
