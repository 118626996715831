import { ReactElement } from 'react'

import {
    CaviCardLogo,
    ChipLogo,
    ClassicCardBase,
    GoldCardBase,
    PlatinoCardBase,
    SignatureCardBase,
    VisaLogo,
} from '../../../../../../utils/getIcons'
import { getNameCreditCard } from '../../../../../../components/GlobalFuntions/globalFunction'
import { CardBody, CardContainer, CardFooter, CreditCardContainer } from './ImageCard-Styled'

interface ImageCardProps {
    lastFourDigits?: string
    nameCard?: string
}

const creditCardConfig: { [key: string]: { img: string; text: string } } = {
    Classic: {
        img: ClassicCardBase,
        text: 'Classic',
    },
    Platinum: {
        img: PlatinoCardBase,
        text: 'Platino',
    },
    Platino: {
        img: PlatinoCardBase,
        text: 'Platino',
    },
    Gold: {
        img: GoldCardBase,
        text: 'Gold',
    },
    Signature: {
        img: SignatureCardBase,
        text: 'Signature',
    },
}

const ImageCard: React.FC<ImageCardProps> = ({ lastFourDigits, nameCard = '' }): ReactElement => {
    return (
        <CreditCardContainer>
            <picture>
                <img src={creditCardConfig[getNameCreditCard(nameCard)].img} alt="" />
            </picture>
            <CardContainer>
                <picture>
                    <img src={CaviCardLogo} alt="" />
                </picture>
                <CardBody>
                    <picture>
                        <img src={ChipLogo} alt="" />
                    </picture>
                    <p>**** **** **** {lastFourDigits}</p>
                </CardBody>
                <CardFooter>
                    <div></div>
                    <div>
                        <img src={VisaLogo} alt="" />
                        <p>{creditCardConfig[getNameCreditCard(nameCard)].text}</p>
                    </div>
                </CardFooter>
            </CardContainer>
        </CreditCardContainer>
    )
}
export default ImageCard
