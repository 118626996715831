import { Col, Form, Row } from 'react-bootstrap'
import { LayoutContent } from '../../../../transverse'
import {
    ButtonCanceled,
    ContentForm,
    DivContainerStart,
    Label,
    SectionTitle,
    SpaceHr,
    TitlePage,
} from '../../UserOperations-styled'

interface SearchResultProps {
    userName: string
    identificationCard: string
    receipt: string
    onNextStep: (step: number) => void
    onPrevStep: (step: number) => void
}

const SearchResult: React.FC<SearchResultProps> = ({
    userName,
    identificationCard,
    receipt,
    onPrevStep,
}): JSX.Element => {
    return (
        <LayoutContent>
            <SpaceHr />
            <SectionTitle>
                <TitlePage>Resultado búsqueda</TitlePage>
            </SectionTitle>
            <ContentForm>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Cédula</Label>

                            <Form.Control type="number" disabled value={identificationCard} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Nombre de usuario</Label>
                            <Form.Control type="text" disabled value={userName} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Label>Comprobante</Label>
                            <Form.Control type="text" disabled value={receipt} />
                        </Form.Group>
                    </Col>
                </Row>
                <SpaceHr />

                <DivContainerStart>
                    <ButtonCanceled variant="primary" onClick={() => onPrevStep(1)}>
                        Volver
                    </ButtonCanceled>
                </DivContainerStart>
            </ContentForm>
        </LayoutContent>
    )
}

export default SearchResult
