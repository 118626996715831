import tw, { styled } from 'twin.macro'

export const Content = styled.div`
    ${tw`w-full`}
    .form-select {
        background-image: var(--url-arrow-down-yellow-icon) !important;
        height: 47px !important;
        color: #425453 !important;
        border: 1px solid #c4c2c2 !important;
        border-radius: 0.5rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
        border-color: #c4c2c2 !important;
        font-weight: 500 !important;
    }

    .form-select:disabled {
        background-color: #e9ecef61 !important;
    }
`
