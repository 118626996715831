import { Button, Modal } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const AlertCreditCard = styled.div`
    .card-content {
        ${tw`cursor-pointer  m-auto grid relative !h-28 px-[4%] pt-[2%] pb-[2%] rounded-[10px] lg:w-[453px] w-full max-w-[453px] `}
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        flex-direction: row;

        @media (max-width: 500px) {
            flex-direction: column;
            height: auto !important;
        }
    }

    .tag {
        ${tw`absolute text-[12px] pt-0 pb-[3px] px-[4%] top-[-23px] right-[5%] text-white bg-danger`}
        border-radius: 15px 15px 0 0;
    }

    .img-section {
        ${tw`w-[73%] grid py-[2%] px-[0]`}
        grid-template-columns: 23% 77%;
    }

    .subtitle-card {
        ${tw`m-0 font-normal font-helvetica text-[14px] text-black`}
    }

    @media (min-width: 1180px) {
        .tag {
            ${tw`text-sm`}
        }

        .subtitle-card {
            ${tw`text-base`}
        }
    }
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const Image = styled.img`
    width: 47px;
    margin-right: 5%;
`

export const ImageArrow = styled.img`
    width: 30px;
    rotate: 270deg;
    margin-left: 7px;
`

export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base m-2`}
`

export const ModalInfoRequest = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }

        .modal-body {
            padding: 0 !important;
            ${tw`flex flex-col items-center`}

            img {
                ${tw`m-2.5`}
            }

            h6 {
                ${tw`font-montserrat text-center text-base font-semibold color[var(--header-botton-color)]`}
            }

            p {
                ${tw`my-2.5 mx-0 text-center text-sm font-helvetica font-normal color[var(--header-botton-color)]`}
            }
        }
    }
`
export const ButtonContent = styled.div`
    ${tw`my-2.5 mx-1 w-[220px] h-[50px] rounded-[10px] font-normal font-montserrat`}

    .button-block {
        border-radius: 10px !important;
    }

    @media (min-width: 1180px) {
        ${tw`w-56`}
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-1 w-[220px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[330px]`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col gap-1 items-center p-0`}
    ${tw`lg:flex-row py-0 px-1`}
`

export const Container = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`
