import { useHistory } from 'react-router-dom'
import { BreadcrumbApp } from '../../../../../../components'
import { NavigationCtr } from './BreadcrumbAdvanceKey-Styled'

interface BreadcrumbAdvanceKeyProps {
    lastFourDigits?: string
}

const BreadcrumbAdvanceKey: React.FC<BreadcrumbAdvanceKeyProps> = ({
    lastFourDigits,
}): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Tarjeta de crédito',
            active: false,
        },
        {
            text: `Tarjeta ${lastFourDigits ?? ''}`,
            active: true,
        },
    ]
    return (
        <NavigationCtr>
            <BreadcrumbApp
                breadcrumbs={breadcrumbs}
                onBack={() => {
                    redirection('/home-wallet')
                }}
            />
        </NavigationCtr>
    )
}

export default BreadcrumbAdvanceKey
