import { useHistory } from 'react-router-dom'
import { ClockMoneySVG, WalletFai } from '../../../../utils/getIcons'
import {
    ButtonContinue,
    ButtonsContainer,
    CancelButton,
    Content,
    Image,
    SectionImage,
    SectionText,
    SectionTextImage,
    TitleComponent,
    Wrapper,
} from '../../PaymentScheduleRegistrationTC-Styled'
import { GetFaiAccountBalance } from '../../../../../../domain/models'

interface FirstStepProps {
    onNextStep: () => void
    activeFAI: GetFaiAccountBalance
    isAccountFai: boolean
}

const FirstStep: React.FC<FirstStepProps> = ({ onNextStep, activeFAI, isAccountFai }) => {
    const history = useHistory()
    return (
        <>
            {isAccountFai ? (
                <Wrapper>
                    <Content>
                        <div>
                            <TitleComponent>Pago Automático</TitleComponent>
                        </div>

                        <SectionImage>
                            <Image src={ClockMoneySVG} alt="ClockMoneySVG" width={144} />
                        </SectionImage>
                        <SectionTextImage>
                            <p>Activa el débito automático</p>
                        </SectionTextImage>

                        <SectionText>
                            <p>
                                Si tienes cuenta FAI podrás activar el débito automático de tus
                                tarjetas de crédito para siempre estar al día en el pago de tus
                                productos.
                            </p>
                        </SectionText>

                        <ButtonsContainer>
                            <ButtonContinue onClick={() => onNextStep()}>Continuar</ButtonContinue>
                        </ButtonsContainer>
                    </Content>
                </Wrapper>
            ) : (
                <>
                    <Wrapper>
                        <Content>
                            <div>
                                <TitleComponent>Pago Automático</TitleComponent>
                            </div>

                            <SectionImage>
                                <Image src={WalletFai} alt="WalletFai" width={144} />
                            </SectionImage>
                            <SectionTextImage>
                                <p>Solicita tu cuenta FAI</p>
                            </SectionTextImage>

                            <SectionText>
                                <p>
                                    Para activar el pago automático debes tener una cuenta FAI, para
                                    solicitarla visita una de nuestras{' '}
                                    <a
                                        id="Where-are-we"
                                        href="https://www.cavipetrol.com/web/donde-estamos/"
                                    >
                                        oficinas
                                    </a>
                                    .
                                </p>
                            </SectionText>

                            <ButtonsContainer>
                                <CancelButton onClick={() => history.push('./home-wallet')}>
                                    Finalizar
                                </CancelButton>
                            </ButtonsContainer>
                        </Content>
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default FirstStep
