import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { useSelector } from 'react-redux'
import CalculateCredit from './inc/CalculateCredit'
import PaymentPlan from './inc/PaymentPlan'
import { PpalTitle, PrintDiv, SpacerContent } from './CreditSimulation-styles'
import { PrintSVG } from '../../utils/getIcons'
import { MessageContent } from '../PossessionsInsert/inc/CommonFields/CommonFields-Styles'
import { BreadcrumbApp } from '../../components'
import PDFGenerator from './inc/PDFGenerator'
import { pdf } from '@react-pdf/renderer'

interface LoanDataValuesProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
    valor_prestamo: number
    tasaN: number
}

interface PaymentPlanProps {
    Cuota: number
    ValorCuota: number
    AbonoCapital: number
    Interes: number
    Saldo: number
}

const CreditSimulation = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [step, setStep] = useState(0)
    const [rowsCount, setRowsCount] = useState(0)
    const [loanData, setLoanData] = useState<LoanDataValuesProps[]>([])
    const [paymentPlan, setPaymentPlan] = useState<PaymentPlanProps[][]>([])

    const goToPaymentPlan = (loanValues: any, planValues: any, rowsCount: number): void => {
        setRowsCount(rowsCount)
        setLoanData(loanValues)
        setPaymentPlan(planValues)
        setStep(1)
    }

    const formatter = (value: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value)
    }

    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Simuladores',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Créditos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    const handlePrintPDF = async () => {
        try {
            const pdfBlob = await pdf(
                <PDFGenerator
                    formatter={formatter}
                    loanDataValues={loanData}
                    paymentsPlanValues={paymentPlan}
                    rowsCount={rowsCount}
                />
            ).toBlob()

            const url = URL.createObjectURL(pdfBlob)

            const printWindow = window.open(url)

            // Esperar a que la ventana de impresión esté cargada y luego imprimir
            printWindow?.addEventListener('load', () => {
                printWindow.document.title = 'CavPrint'

                const downloadLink = document.createElement('a')
                downloadLink.href = url
                downloadLink.download = `CavPrint.pdf`
                downloadLink.click()
                URL.revokeObjectURL(url)
            })
        } catch (error) {
            console.error('Error al generar el PDF:', error)
        }
    }

    return (
        <LayoutContent>
            <SpacerContent>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />

                {step === 0 && (
                    <CalculateCredit
                        tokenSave={tokenSave}
                        redirection={redirection}
                        formatter={formatter}
                        goToPaymentPlan={goToPaymentPlan}
                    />
                )}
                {step === 1 && (
                    <>
                        <PpalTitle>
                            <span>Plan de pago</span>
                        </PpalTitle>
                        <PrintDiv onClick={handlePrintPDF}>
                            <img src={PrintSVG} alt="imprimir" />
                            <p>Imprimir</p>
                        </PrintDiv>
                        {Array.from({ length: rowsCount }).map((_, index) => (
                            <div key={index}>
                                <PaymentPlan
                                    redirection={redirection}
                                    formatter={formatter}
                                    loanData={loanData[index]}
                                    paymentsPlan={paymentPlan[index]}
                                    rowIndex={index}
                                />
                            </div>
                        ))}
                        <MessageContent>
                            <p>
                                Nota: Los valores que arroja el simulador son aproximados y de
                                caracter informativo; todos los montos son calculados con base en la
                                actual tasa de interés fijada para el producto. La tasa de interés
                                cambia dependiendo de las condiciones del mercado y las cuotas no
                                incluyen los montos por seguro de vida. Recuerde que usted puede
                                consultar el cupo de sus créditos a través de la opción de Servicios
                                en Línea. Esta información no compromete ni obliga a Cavipetrol
                                frente a sus potenciales usuarios.
                            </p>
                        </MessageContent>
                    </>
                )}
            </SpacerContent>
        </LayoutContent>
    )
}

export default CreditSimulation
