import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetInfoAssociate } from './hooks/useCaviFacil'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import { Container, ContainerTitle, Title } from './CavifacilTransaction-Styled'
import InitialInfo from './inc/InitialInfo'
import FirstStep from './inc/FirstStep/FirstStep'
import SecondStep from './inc/SecondStep'
import TransactionResponse from './inc/TransactionResponse'
import { useSelector } from 'react-redux'

const CavifacilTransaction = (): JSX.Element => {
    const history = useHistory()
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [step, setStep] = useState<number>(1)

    const [associatedName, setAssociatedName] = useState<string>('')
    const [associatedDocumentNumber, setAssociatedDocumentNumber] = useState<string>('')
    const [amountTransfer, setAmountTransfer] = useState<string>('')

    const [dateTransaction, setDateTransaction] = useState<string>('')
    const [creditNumber, setCreditNumber] = useState<string>('')
    const [documentNumber, setDocumentNumber] = useState<string>('')
    const [value, setValue] = useState<string>('')

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Transacciones y Pagos',
            active: false,
        },
        {
            text: 'CaviFácil',
            active: true,
        },
    ]

    const GetInfoAssociate = (): void => {
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setAssociatedName(res.data.Data[0].Name)
                    setAssociatedDocumentNumber(res.data.Data[0].Document)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        GetInfoAssociate()
    }, [tokenSave])

    const handleNextStepChange = (): void => {
        setStep(step + 1)
    }

    const handlePrevStepChange = (): void => {
        setStep(step - 1)
    }

    const saveDataTransfer = (amountTransfer: string): void => {
        setAmountTransfer(amountTransfer)
    }

    const handleData = (
        dateTransaction: string,
        creditNumber: string,
        documentNumber: string,
        value: string
    ): void => {
        setDateTransaction(dateTransaction)
        setDocumentNumber(documentNumber)
        setCreditNumber(creditNumber)
        setValue(value)
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (step === 2) {
                            handlePrevStepChange()
                        } else if (step === 3) {
                            setStep(1)
                            setAmountTransfer('')
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />

                {step === 1 && (
                    <>
                        <ContainerTitle>
                            <Title>
                                <span>CaviFácil</span>
                            </Title>
                        </ContainerTitle>
                        <InitialInfo associatedName={associatedName} />
                        <FirstStep
                            associatedName={associatedName}
                            onNextStep={handleNextStepChange}
                            saveDataTransfer={saveDataTransfer}
                            associatedDocumentNumber={associatedDocumentNumber}
                            amountTransferValue={amountTransfer}
                        />
                    </>
                )}

                {step === 2 && (
                    <SecondStep
                        handleData={handleData}
                        onNextStep={handleNextStepChange}
                        amountTransfer={amountTransfer}
                    />
                )}

                {step === 3 && (
                    <TransactionResponse
                        associatedName={associatedName}
                        dateTransaction={dateTransaction}
                        creditNumber={creditNumber}
                        documentNumber={documentNumber}
                        value={value}
                    />
                )}
            </Container>
        </LayoutContent>
    )
}

export default CavifacilTransaction
