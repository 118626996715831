import { useDispatch, useSelector } from 'react-redux'
import useQueryId from '../../../../../../hooks/useQueryId'
import { LayoutContent } from '../../../../../../transverse'
import { StoreApp } from '../../../../../../../redux/store/store.interface'
import { CreditCardSelector } from '../../../../../../../selectors'
import { portfolioPurchaseAction } from '../../../../../../../redux/portfolioPurchaseTC'
import { useEffect, useState } from 'react'
import BreadcrumbAdvanceKey from '../BreadcrumbAdvanceKey/BreadcrumbAdvanceKey'
import { Container, ContainerTitle, Title } from './AssignAdvanceKey-Styled'
import NewAdvanceKey from '../NewAdvanceKey/NewAdvanceKey'
import DynamicKeyContainer from '../DynamicKeyContainer/DynamicKeyContainer'
import { CardStates } from '../../../../../../../../domain/models'
import { useHistory } from 'react-router-dom'
import { Loading, ModalGeneric } from '../../../../../../components'
import { NotProductSVG } from '../../../../../../utils/getIcons'
import { useUserInfo } from '../../../../hooks/useCreditDetail'
import { LoadingContainer } from '../../../../creditDetail-styles'

const AssignAdvanceKey: React.FC = (): JSX.Element => {
    const { id } = useQueryId()
    const dispatch = useDispatch()
    const history = useHistory()
    const { cardData: infoCard } = useSelector((store: StoreApp) =>
        CreditCardSelector(store, String(id))
    )

    const [step, setStep] = useState<number>(1)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [textTitleModal, setTextTitleModal] = useState<string>('')
    const [textBodyModal, setTextBodyModal] = useState<string>('')
    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [urlToBack, setUrlToBack] = useState('')

    const handleStepChange = (stepNumber: number): void => {
        setStep(stepNumber)
    }

    useEffect(() => {
        initialValidations()
        dispatch(portfolioPurchaseAction())
    }, [])

    useEffect(() => {
        GetUserInfo()
    }, [tokenSave])

    const initialValidations = (): void => {
        if (id === undefined || !infoCard) {
            setShowModal(true)
            setTextTitleModal('Lo sentimos')
            setTextBodyModal('No es posible procesar tu solicitud.')
        } else if (infoCard.lockType !== CardStates.ACTIVA) {
            setShowModal(true)
            setTextTitleModal('Lo sentimos')
            setTextBodyModal(
                'Tu tarjeta no está activa, por esta razón no es posible procesar tu solicitud.'
            )
        } else {
            setUrlToBack(`/credit-card-detail?id=${infoCard.lastFourDigitsEncryptedCC}`)
        }
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const GetUserInfo = (): void => {
        useUserInfo(tokenSave)
            .then((res: any) => {
                if (res['Response'] === '200') {
                    setCellphone(res['Data']['CellPhone'])
                    setEmail(res['Data']['Email'])
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbAdvanceKey
                    lastFourDigits={infoCard?.lastFourDigits}
                ></BreadcrumbAdvanceKey>
                <ContainerTitle>
                    <Title>
                        Asigna tu
                        <span>clave para avances</span>
                    </Title>
                </ContainerTitle>
                {infoCard && !showModal && (
                    <>
                        {!cellphone ? (
                            <LoadingContainer>
                                <Loading text="Por favor, aguarda un momento estamos consultando tu información." />
                            </LoadingContainer>
                        ) : (
                            <>
                                {step === 1 && (
                                    <DynamicKeyContainer
                                        stepNumber="1"
                                        nextStepNumber="2"
                                        onNextStep={() => handleStepChange(2)}
                                        cellphone={cellphone}
                                        email={email}
                                        buttonText="Validar"
                                        IsForgetAdvanceKey={false}
                                    ></DynamicKeyContainer>
                                )}
                            </>
                        )}
                    </>
                )}
                {step === 2 && (
                    <NewAdvanceKey
                        stepNumber="2"
                        nextStepNumber="2"
                        showValidThru={false}
                        lastFourDigits={infoCard?.lastFourDigits}
                        keyNew={true}
                        numberCard={infoCard?.cardNumber || ''}
                        tokenSave={tokenSave}
                        urlToBack={urlToBack}
                        lastFourDigitsEncryptedCC={infoCard?.lastFourDigitsEncryptedCC}
                        buttonText="Validar"
                    ></NewAdvanceKey>
                )}

                <ModalGeneric
                    show={showModal}
                    img={NotProductSVG}
                    textTitle={textTitleModal}
                    textBody={textBodyModal}
                    handleButton={handleModalCloseInfo}
                    handleClose={handleModalCloseInfo}
                    textButton="Aceptar"
                />
            </Container>
        </LayoutContent>
    )
}

export default AssignAdvanceKey
