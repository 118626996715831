import { useState } from 'react'
import { Button, InputGroup, Loading, Modal } from '../../../../components'

import {
    FirstStepContent,
    SpaceStep,
    FormGroup,
    FormLabel,
    FormLabel2,
    ContainerSimulator,
    ButtonsContainer,
    CancelButton,
    ButtonContent,
} from './Certificate-Styles'
import { useHistory } from 'react-router-dom'
import { Form, ModalBody } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useStatusTC } from '../../hooks/useCertificate'
import ModalInfo from '../../../CreditDetail/inc/ModalInfo/ModalInfo'
import moment from 'moment'

interface DynamicKeyContainerProps {
    onNextStep: (
        body: string,
        header: string,
        footer: string,
        valediction: string,
        cardNumber: string,
        dateExpiredCard: string,
        totalLimit: string,
        quotaAvailable: string,
        showPDF: boolean
    ) => void
    cardNumber?: string
}
const Certificate: React.FC<DynamicKeyContainerProps> = ({
    onNextStep,
    cardNumber,
}): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const history = useHistory()
    const [selectedOption, setSelectedOption] = useState('option1') // Estado para almacenar la opción seleccionada
    const [showPDF, setShowPDF] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [showModal, setShowModal] = useState(false)
    const date = moment().format('YYYY-MM-DD')
    const day = moment(date).format('DD')
    const nameMonth = moment(date).format('MMMM')
    const year = moment(date).format('YYYY')
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value) // Actualiza el estado con la opción seleccionada
    }
    const handleNext = () => {
        HandleFormSubmit()
        setIsLoading(true)
    }
    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }
    const HandleFormSubmit = (): void => {
        setIsLoading(true)

        // Construye el FormData
        const formDataToSend = new FormData()
        formDataToSend.append('body', JSON.stringify(cardNumber))

        useStatusTC(formDataToSend, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setIsLoading(false)
                    const body = res['data']['Data']['Body']
                    const header = res['data']['Data']['Header']
                    const footer = `\nLa presente certificación se expide a solicitud del interesado, a los ${day} días del mes de ${nameMonth} de ${year}`
                    const valediction = res['data']['Data']['Valediction']
                    const cardNumber = res['data']['Data']['CardNumber']
                    const dateExpiredCard = res['data']['Data']['DateExpiredCard']
                    const totalLimit = res['data']['Data']['TotalLimit']
                    const quotaAvailable = res['data']['Data']['QuotaAvailable']
                    onNextStep(
                        body,
                        header,
                        footer,
                        valediction,
                        cardNumber,
                        dateExpiredCard,
                        totalLimit,
                        quotaAvailable,
                        showPDF
                    )
                } else {
                    setIsLoading(false)
                    console.log('NO FUNCIONA')
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('No se pudo generar el pdf, intente de nuevo')
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se pudo generar el pdf, intente de nuevo')
            })
    }
    return (
        <ContainerSimulator>
            <FirstStepContent>
                <Form>
                    <FormLabel2>Elige que tipo de certificado deseas generar</FormLabel2>
                    <SpaceStep />
                    <FormGroup>
                        <FormLabel>Tipo de certificado</FormLabel>
                        <InputGroup>
                            <Form.Check
                                className="radio"
                                value="CertificadoSaldo"
                                type="radio"
                                label="Certificado saldo de producto"
                                onChange={handleOptionChange}
                                checked={true}
                                id="certificadoradio"
                            />

                            {/* <Form.Check
                                className="radio"
                                value="CertificadoTributario"
                                type="radio"
                                aria-label="radio 2"
                                label="Certificado tributario"
                                onChange={handleOptionChange}
                                checked={selectedOption === 'CertificadoTributario'}
                            /> */}
                        </InputGroup>
                    </FormGroup>
                    <SpaceStep />
                    <SpaceStep />
                    <ButtonsContainer>
                        <CancelButton onClick={() => history.push('/home-wallet')}>
                            Cancelar
                        </CancelButton>
                        <ButtonContent>
                            <Button
                                id="btnSubmit"
                                className="button-block"
                                variant="sub-dominant"
                                type="button"
                                onClick={handleNext}
                                isLoading={isLoading}
                                extend
                                disabled={isLoading}
                            >
                                Continuar
                            </Button>
                        </ButtonContent>
                    </ButtonsContainer>
                </Form>
                <Modal
                    className="loading-screen"
                    show={isLoading}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <ModalBody>
                        <Loading text="Estamos creando tu documento" />
                        <p style={{ textAlign: 'center' }}>
                            Espera, no actualices o cierres esta página
                        </p>
                    </ModalBody>
                </Modal>
                <ModalInfo
                    showModal={showModal}
                    textTitle={textTitleModal}
                    textBody={textBodyModal}
                    handleClose={handleModalCloseInfo}
                />
            </FirstStepContent>
        </ContainerSimulator>
    )
}

export default Certificate
