import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { useSelector } from 'react-redux'
import KeyChangeInfo from './inc/KeyChangeInfo'
import CurrentPass from './inc/CurrentPass'
import NewPass from './inc/NewPass'
import IvrPass from './inc/IvrPass'

const KeyChange = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const userName = state.auth.name

    const [step, setStep] = useState(0)
    const [currentPassValue, setCurrentPassValue] = useState('')

    const handleNextStep = (stepValue: string): void => {
        if (stepValue === 'info') {
            setStep(1)
        } else if (stepValue === 'ivr') {
            setStep(2)
        } else {
            const partes = stepValue.split('-')
            const value = partes[1]
            setCurrentPassValue(value)
            setStep(3)
        }
    }

    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }

    return (
        <LayoutContent>
            {step === 0 && (
                <KeyChangeInfo
                    handleNextStep={handleNextStep}
                    userName={userName}
                    redirection={redirection}
                />
            )}
            {step === 1 && (
                <IvrPass
                    handleNextStep={handleNextStep}
                    redirection={redirection}
                    tokenSave={tokenSave}
                />
            )}
            {step === 2 && (
                <CurrentPass
                    handleNextStep={handleNextStep}
                    redirection={redirection}
                    tokenSave={tokenSave}
                />
            )}
            {step === 3 && (
                <NewPass
                    currentPassValue={currentPassValue}
                    redirection={redirection}
                    tokenSave={tokenSave}
                />
            )}
        </LayoutContent>
    )
}

export default KeyChange
