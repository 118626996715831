import { useHistory } from 'react-router-dom'
import { Container, ContainerTitle, Title } from './FaiAccountTransfer-Styled'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp, Loading } from '../../components'
import CommonInfo from './inc/CommonInfo'
import FirsStep from './inc/FirsStep'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useGetInfoAssociate } from './hooks/useFaiAccountTransfer'
import SecondStep from './inc/SecondStep'
import SuccesfulTransaction from './inc/SuccesfulTransaction'
import { faiAccountExistSelector } from '../../../selectors'
import { getFaiAccountBalanceAction } from '../../../redux/products'
import { LoadingContainer } from '../CreditDetail/creditDetail-styles'
import NoFAIAccountAlert from './inc/NoFAIAccountAlert'
import ModalError from './inc/Modals/ModalError'
import { NotProductSVG } from '../../utils/getIcons'

const FaiAccountTransfer = (): JSX.Element => {
    const history = useHistory()
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const dispatch = useDispatch()

    const [associatedName, setAssociatedName] = useState<string>('')
    const [associatedIdentification, setAssociatedIdentification] = useState<string>('')
    const [faiOriginAccount, setFaiOriginAccount] = useState<string>('')

    const [amountTransfer, setAmountTransfer] = useState<string>('')
    const [destinationId, setDestinationId] = useState<string>('')
    const [destinationName, setDestinationName] = useState<string>('')
    const [faiDestinationAccount, setFaiDestinationAccount] = useState<string>('')
    const [dateTransaction, setDateTransaction] = useState<string>('')
    const [isEdit, setIstEdit] = useState<boolean>(false)

    const [messageResponse, setMessageResponse] = useState<string>('')
    const [voucherNumber, setVoucherNumber] = useState<string>('')

    const [step, setStep] = useState<number>(1)

    const { isAccountFai, faiAccount, loading, error } = useSelector(faiAccountExistSelector)

    useEffect(() => {
        dispatch(getFaiAccountBalanceAction())
    }, [dispatch])

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Transacciones y Pagos',
            active: false,
        },
        {
            text: 'Transferencias',
            active: false,
        },
        {
            text: 'Entre Cuentas FAI',
            active: true,
        },
    ]

    const GetInfoAssociate = (): void => {
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setAssociatedName(res.data.Data[0].Name)
                    setAssociatedIdentification(res.data.Data[0].Document)
                } else {
                    console.log('Error')
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (isAccountFai) {
            GetInfoAssociate()
        }
    }, [tokenSave])

    const handleNextStepChange = (): void => {
        setStep(step + 1)
    }

    const handlePrevStepChange = (): void => {
        setStep(step - 1)
    }

    const saveDataTransfer = (
        destinationId: string,
        destinationName: string,
        faiDestinationAccount: string,
        amountTransfer: string
    ): void => {
        setDestinationId(destinationId)
        setDestinationName(destinationName)
        setFaiDestinationAccount(faiDestinationAccount)
        setAmountTransfer(amountTransfer)
    }

    const handleData = (
        dateTransaction: string,
        messageResponse: string,
        voucherNumber: string
    ): void => {
        setDateTransaction(dateTransaction)
        setMessageResponse(messageResponse)
        setVoucherNumber(voucherNumber)
        const typeProduct = faiAccount.typeDocumentProduct
        const documentNumber = faiAccount.productDocumentNumber
        const finishedNumber = faiAccount.finishedNumber
        setFaiOriginAccount(typeProduct + '-' + documentNumber + '-' + finishedNumber + '-')
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (step > 1 && step <= 2) {
                            handlePrevStepChange()
                            setIstEdit(true)
                        } else if (step === 3) {
                            setStep(1)
                            setIstEdit(false)
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />
                {step !== 3 && (
                    <ContainerTitle>
                        <Title>
                            Transferencias entre <span>Cuentas de Ahorro FAI</span>
                        </Title>
                    </ContainerTitle>
                )}
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {isAccountFai ? (
                            <>
                                {step === 1 && (
                                    <>
                                        <CommonInfo associatedName={associatedName} />
                                        <FirsStep
                                            associatedName={associatedName}
                                            onNextStep={handleNextStepChange}
                                            saveDataTransfer={saveDataTransfer}
                                            destinationIdEdit={destinationId}
                                            faiDestinationAccountEdit={faiDestinationAccount}
                                            amountTransferEdit={amountTransfer}
                                            isEdit={isEdit}
                                        />
                                    </>
                                )}
                                {step === 2 && (
                                    <SecondStep
                                        associatedName={associatedName}
                                        onNextStep={handleNextStepChange}
                                        amountTransfer={amountTransfer}
                                        faiDestinationAccount={faiDestinationAccount}
                                        faiAssociateAccount={faiOriginAccount}
                                        identificationOrigin={associatedIdentification}
                                        identificationDestination={destinationId}
                                        handleData={handleData}
                                    />
                                )}
                                {step === 3 && (
                                    <SuccesfulTransaction
                                        associatedName={associatedName}
                                        amountTransfer={amountTransfer}
                                        destinationId={destinationId}
                                        destinationName={destinationName}
                                        faiDestinationAccount={faiDestinationAccount}
                                        dateTransaction={dateTransaction}
                                        messageResponse={messageResponse}
                                        voucherNumber={voucherNumber}
                                    />
                                )}
                            </>
                        ) : (
                            <NoFAIAccountAlert />
                        )}
                    </>
                )}

                {Object.keys(error).length > 0 && (
                    <ModalError
                        showModal={true}
                        img={NotProductSVG}
                        titleMessage={'¡Ha ocurrido un error!'}
                        errorMessage={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleClose={handleClose}
                    />
                )}
            </Container>
        </LayoutContent>
    )
}

export default FaiAccountTransfer
