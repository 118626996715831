import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import PossessionsTable from './inc/PossessionsTable/index'
import { ContainerPossessionsConsult, SpaceStep } from './PossessionsConsult-styles'
import { BreadcrumbApp } from '../../components'
import ImageConsult from './inc/ImageConsult/ImageConsult'
import useData from './hooks/useDataList'
import { ContainerTitle2, Title, Title2 } from './inc/TitleModuleConsult/titleModule-styles'

const PossessionsConsult = (): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [stateInitial, setStateInitial] = useState(false)
    const { typeProperty, actions, listProperty } = useData(stateInitial)
    console.log('listproperty', listProperty)
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Consulta de inmueble',
            active: true,
        },
    ]
    const refresh = (): void => {
        setStateInitial(!stateInitial)
    }

    return (
        <LayoutContent>
            <ContainerPossessionsConsult>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <SpaceStep />
                <ImageConsult />
                <ContainerTitle2>
                    <Title>Listado de inmuebles</Title>
                    <Title2 onClick={refresh}>Refrescar</Title2>
                </ContainerTitle2>
                {listProperty && actions && typeProperty && (
                    <PossessionsTable
                        listProperty={listProperty}
                        typeProperty={typeProperty}
                        actions={actions}
                        estado={stateInitial}
                    />
                )}
            </ContainerPossessionsConsult>
        </LayoutContent>
    )
}

export default PossessionsConsult
