import { FC } from 'react'
// styles
import {
    PpalTitle,
    ProductTableContainer,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContentDetail,
} from '../../BalancesExtracts-styles'

type MovementsDataType = {
    TypeOperation: string
    VariableDescripcion: string
    ConsecutiveOperacion: number
    FechaMovimiento: string
    Valor: number
}

interface MovementsProps {
    movementsData: MovementsDataType[]
    formatter: (value: number) => string
}

const MovementsDetail: FC<MovementsProps> = ({ movementsData, formatter }): JSX.Element => {
    return (
        <TableContentDetail>
            <PpalTitle>
                <span>Detalle de Movimiento</span>
            </PpalTitle>
            <ProductTableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Fecha</th>
                        <th>Oficina</th>
                        <th>Comprobante</th>
                        <th>Clase movimiento</th>
                        <th>Valor</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    {movementsData.map((movement) => (
                        <RowTable>
                            <td>{movement.FechaMovimiento}</td>
                            <td>{movement.VariableDescripcion}</td>
                            <td>{movement.ConsecutiveOperacion}</td>
                            <td>{movement.TypeOperation}</td>
                            <td style={{ textAlign: 'right', paddingRight: '30px' }}>
                                {formatter(movement.Valor)}
                            </td>
                        </RowTable>
                    ))}
                </TBody>
            </ProductTableContainer>
        </TableContentDetail>
    )
}

export default MovementsDetail
