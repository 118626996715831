import { Col, Row } from 'react-bootstrap'
import {
    ContentData,
    DocumentContent,
    HeaderText,
    PagePdf,
    RemittentText,
    Text,
    TextInfo,
    SubHeaderText,
} from './PDFViewerMobile-Styled'

interface PDFViewerMobileProps {
    Footer: string
    productName: string
    productNumber: string
    openingDate: string
    associatedName: string
    documentNumber: string
    documentType: string
    registrationNumber: string
    availableBalance: string
}
const PDFViewerMobile: React.FC<PDFViewerMobileProps> = ({
    Footer,
    productName,
    productNumber,
    openingDate,
    associatedName,
    documentType,
    documentNumber,
    registrationNumber,
    availableBalance,
}) => {
    return (
        <PagePdf>
            <DocumentContent>
                <HeaderText>
                    Fondo de Empleados de los Trabajadores <br />y Pensionados de Ecopetrol S.A
                    CAVIPETROL
                </HeaderText>
                <SubHeaderText>Identificado con NIT 860.006.773-2</SubHeaderText>
                <br></br>
                <Text>
                    Que el Señor(a) {associatedName}, identificado(a) con {documentType}{' '}
                    {documentNumber}, y con registro número {registrationNumber}, presenta el
                    siguiente saldo a la fecha.
                </Text>
                <ContentData>
                    <Row className="rowStyle">
                        <Col md={5} xs={7}>
                            <TextInfo>NOMBRE PRODUCTO: </TextInfo>
                        </Col>
                        <Col md={7} xs={5}>
                            <Text>{productName}</Text>
                        </Col>
                    </Row>
                    <Row className="rowStyle">
                        <Col md={5} xs={7}>
                            <TextInfo>NÚMERO DEL PRODUCTO: </TextInfo>
                        </Col>
                        <Col md={7} xs={5}>
                            <Text>{productNumber}</Text>
                        </Col>
                    </Row>
                    <Row className="rowStyle">
                        <Col md={5} xs={7}>
                            <TextInfo>FECHA APERTURA: </TextInfo>
                        </Col>
                        <Col md={7} xs={5}>
                            <Text>{openingDate}</Text>
                        </Col>
                    </Row>
                    <Row className="rowStyle">
                        <Col md={5} xs={7}>
                            <TextInfo>SALDO: </TextInfo>
                        </Col>
                        <Col md={7} xs={5}>
                            <Text>{availableBalance}</Text>
                        </Col>
                    </Row>
                </ContentData>
                <Text>{Footer}</Text>
                <RemittentText>Atentamente, Cavipetrol</RemittentText>
            </DocumentContent>
        </PagePdf>
    )
}

export default PDFViewerMobile
