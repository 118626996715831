import { Switch, Route, Redirect } from 'react-router-dom'

// route components
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'

// entry pages components
import { ProblemsUser } from '../components'

import {
    TarDetail,
    Login,
    PaymentsFAI,
    PaymentFaiResult,
    NotFound,
    InternalServerError,
    HomeWallet,
    Support,
    SavingsGroup,
    SavingsDetail,
    CreditGroup,
    CreditDetail,
    Payments,
    PaymentsResult,
    ProductsOpening,
    TdcOpening,
    MultiPaymentsTDC,
    OpeningCreditCard,
    ForgetPassword,
    CreditCardDetail,
    ActivateCard,
    PortfolioPurchase,
    CardRedirect,
    PossessionsInsert,
} from '../pages'

// utils
import { decryptKey, parseStringToBoolean } from '../utils/misc'

// contexts
import createStepProvider from '../pages/PaymentsFAI/context/StepProvider'
import createStepMultiTDCProvider from '../pages/MultiPaymentsTDC/context/StepProvider'
import createStateProvider from '../pages/ForgetPassword/context/StateProvider'
import { LoginStateProvider } from '../pages/Login/context/LoginState'
import createPaymentsProvider from '../pages/Payments/context/PaymentsState'
import { CreditDetailStateProvider } from '../pages/CreditDetail/context/CreditDetailState'
import createActivateCardProvider from '../pages/ActivateCard/context/ActivateCardState'
import PossessionsConsult from '../pages/PossessionsConsult/PossesssionsConsult'
import BlockCardSteps from '../pages/CreditDetail/inc/BlockCardTheftModal/BlockCardSteps'
import AssignAdvanceKey from '../pages/CreditDetail/inc/AdvanceKeyCard/inc/AssignAdvanceKey/AssignAdvanceKey'
import ForgetAdvanceKey from '../pages/CreditDetail/inc/AdvanceKeyCard/inc/ForgetAdvanceKey/ForgetAdvanceKey'
import AdvanceKeyChange from '../pages/CreditDetail/inc/AdvanceKeyCard/inc/AdvanceKeyChange/AdvanceKeyChange'
import ActivateInternationalCard from '../pages/ActivateInternationalCard/ActivateInternationalCard'
import CertificateProduct from '../pages/CertificateProduct'
import Mortgages from '../pages/Mortgages'
import ReportACH from '../pages/ReportACH'
import PaymentScheduleRegistrationTC from '../pages/PaymentScheduleRegistrationTC/PaymentScheduleRegistrationTC'
import PaymentScheduleCancellationTC from '../pages/PaymentScheduleCancellationTC/PaymentScheduleCancellationTC'
import { useFeatureEnableFunctionsArray } from '../pages/FeatureEnableFunctions/FeatureEnableFunctions'

import { useEffect, useState } from 'react'
import Codebtors from '../pages/Codebtors'
import ScoresQuotas from '../pages/ScoresQuotas'
import BalancesExtracts from '../pages/BalancesExtracts'
import UserCreation from '../pages/UserCreation'
import OpeningCampaignCard from '../pages/CampaignCard'
import Benefits from '../pages/Benefits'
import KeyChange from '../pages/KeyChange'
import CreditSimulation from '../pages/CreditSimulation/CreditSimulation'
import Pledge from '../pages/Pledge'
import CupsTermsQuotas from '../pages/CupsTermsQuotas'
import Certificate from '../pages/CertificateRetention'
import AdvanceToFAIAccount from '../pages/AdvanceToFAIAccount'
import CertificationForAgreements from '../pages/CertificationForAgreements'
import CertificateOfAffiliation from '../pages/CertificateOfAffiliation'
import CertificateFAI from '../pages/CertificateFAI'
import CertificateOfFAIAccountOpening from '../pages/CertificateOfFAIAccountOpening'
import FaiAccountTransfer from '../pages/FaiAccountTransfer'
import DataUpdate from '../pages/DataUpdate'
import CavifacilTransaction from '../pages/CavifacilTransaction'
import InterbankTransfer from '../pages/InterbankTransfer'
import RecreationalTermTitle from '../pages/RecreationalTermTitle'
import DetailCreditCardRequests from '../pages/AlertCreditCardRequests/inc/DetailCreditCardRequests'
import ListOfRequest from '../pages/AlertCreditCardRequests/inc/ListOfRequest/ListOfRequest'
import UserManagement from '../pages/UserManagement/UserManagement'
import AssignRoles from '../pages/AssignRoles'
import ManageableContent from '../pages/ManageableContent'
import UserOperations from '../pages/UserOperations/UserOperations'
import InternalProvisionalError from '../pages/InternalServerError/InternalProvisionalError'

const FaiStepProvider = createStepProvider()
const MultiPaymentsTDCStepProvider = createStepMultiTDCProvider()
const PaymentsProvider = createPaymentsProvider()
const ForgetStateProvider = createStateProvider()
const ActivateCardProvider = createActivateCardProvider()

// -- enableTDC --
const enableTDC = process.env.REACT_APP_TDC_ENABLE as 'false' | 'true'
const enableCreditCard = process.env.REACT_APP_CREDIT_CARD_ENABLE as 'false' | 'true'
const enableM2GetRealStates = process.env.REACT_APP_M2_GET_REAL_STATES_ENABLE as 'false' | 'true'
const enableM2PublishRealState = process.env.REACT_APP_M2_PUBLISH_REAL_STATE_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionMortgages = process.env.REACT_APP_ON_OFF_FUNCTION_MORTGAGES as
    | 'false'
    | 'true'
const enableOnOffFunctionScoresAndQuotas = process.env.REACT_APP_SCORES_AND_QUOTAS_ENABLE as
    | 'false'
    | 'true'
const enableOnOffFunctionPaymentRegistration = process.env.REACT_APP_ON_OFF_PAYMENT_REGISTRATION as
    | 'false'
    | 'true'
const enableOnOffFunctionPaymentCancellation = process.env.REACT_APP_ON_OFF_PAYMENT_CANCELLATION as
    | 'false'
    | 'true'
const enableOnOffFunctionCodebtor = process.env.REACT_APP_ON_OFF_FUNCTION_CODEBTOR as
    | 'false'
    | 'true'
const enableOnOffFunctionBenefits = process.env.REACT_APP_ON_OFF_FUNCTION_BENEFITS as
    | 'false'
    | 'true'
const enableOnOffFunctionPledge = process.env.REACT_APP_ON_OFF_FUNCTION_PLEDGE as 'false' | 'true'
const enableOnOffFunctionCreateUser = process.env.REACT_APP_ON_OFF_FUNCTION_CREATE_USER as
    | 'false'
    | 'true'
const enableOnOffFunctionAgreementCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_AGREEMENT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionFaiAccountOpenCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_FAI_ACCOUNT_OPEN_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionBondingCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_BONDING_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionCupsTermsQuotas = process.env
    .REACT_APP_ON_OFF_FUNCTION_CUPS_TERMS_QUOTAS as 'false' | 'true'
const enableOnOffFunctionChangePassword = process.env.REACT_APP_ON_OFF_FUNCTION_CHANGE_PASSWORD as
    | 'false'
    | 'true'

const enableOnOffFunctionKeyChangeForwardToForget = process.env
    .REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD_TO_FORGET as 'false' | 'true'
const enableOnOffFunctionTravelMemos = process.env.REACT_APP_ON_OFF_FUNCTION_TRAVEL_MEMOS as
    | 'false'
    | 'true'
const enableOnOffFunctionAdvanceFaiAccount = process.env
    .REACT_APP_ON_OFF_FUNCTION_ADVANCE_FAI_ACCOUNT as 'false' | 'true'

const enableOnOffFunctionRetentionCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_RETENTION_CERTIFICATE as 'false' | 'true'

const enableOnOffFunctionBetweenAccountsFAI = process.env
    .REACT_APP_ON_OFF_FUNCTION_BETWEEN_ACCOUNTS_FAI as 'false' | 'true'
const enableOnOffFunctionACHReport = process.env.REACT_APP_ON_OFF_FUNCTION_ACH_REPORT as
    | 'false'
    | 'true'
const enableOnOffFunctionTcRedeemQuotas = process.env.REACT_APP_ON_OFF_FUNCTION_TC_REDEEM_QUOTAS as
    | 'false'
    | 'true'

const enableOnOffFunctionAssignProgressKey = process.env
    .REACT_APP_ON_OFF_FUNCTION_ASSIGN_PROGRESS_KEY as 'false' | 'true'

const OnOffFunctionKeyChangeForward = process.env.REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD as
    | 'false'
    | 'true'
const enableOnOffFunctionTheftLock = process.env.REACT_APP_ON_OFF_FUNCTION_THEFT_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionProductCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_PRODUCT_CERTIFICATE as 'false' | 'true'
const enableOnOffFunctionCampaignRequest = process.env
    .REACT_APP_ON_OFF_FUNCTION_CAMPAIGN_REQUEST as 'false' | 'true'
const enableOnOffFunctionCaviFacil = process.env.REACT_APP_ON_OFF_FUNCTION_CAVIFACIL as
    | 'false'
    | 'true'
const enableOnOffFunctionACH = process.env.REACT_APP_ON_OFF_FUNCTION_ACH as 'false' | 'true'
const enableOnOffFunctionRecreationalTermTitleTar = process.env
    .REACT_APP_ON_OFF_FUNCTION_RECREATIONAL_TERM_TITLE as 'false' | 'true'
const enableOnOffFunctionAlertTC = process.env.REACT_APP_ON_OFF_FUNCTION_ALERT_TC as
    | 'false'
    | 'true'
const enableOnOffFunctionUpdateAssociate = process.env
    .REACT_APP_ON_OFF_FUNCTION_UPDATE_ASSOCIATE as 'false' | 'true'

const encryptedKey = localStorage.getItem('isUserRol')
let isAuthorizedString = ''

if (encryptedKey) {
    isAuthorizedString = decryptKey(encryptedKey)
}

// Render functions
const ActivateCardComponent = (): JSX.Element => (
    <ActivateCardProvider>
        <ActivateCard />
    </ActivateCardProvider>
)

const PaymentsComponent = (): JSX.Element => (
    <PaymentsProvider>
        <Payments />
    </PaymentsProvider>
)

const CreditDetailComponent = (): JSX.Element => (
    <CreditDetailStateProvider>
        <CreditDetail />
    </CreditDetailStateProvider>
)

const ForgetPasswordComponent = (): JSX.Element => (
    <ForgetStateProvider>
        <ForgetPassword />
    </ForgetStateProvider>
)

const LoginComponent = (): JSX.Element => (
    <LoginStateProvider>
        <Login />
    </LoginStateProvider>
)

const PaymentsFAIComponent = (): JSX.Element => (
    <FaiStepProvider>
        <PaymentsFAI />
    </FaiStepProvider>
)

const MultiPaymentsTDCComponent = (): JSX.Element => (
    <MultiPaymentsTDCStepProvider>
        <MultiPaymentsTDC />
    </MultiPaymentsTDCStepProvider>
)

export const RouterApp = (): JSX.Element => {
    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const arrayEnableFuntions = useFeatureEnableFunctionsArray()

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (arrayEnableFuntions.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            arrayEnableFuntions.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
            setLoading(false)
        }
    }, [arrayEnableFuntions])

    if (loading) {
        return <PublicRoute path="/login" component={LoginComponent} />
    }

    return (
        <Switch>
            {/* Public Routes */}
            <PublicRoute path="/login" component={LoginComponent} />
            <PublicRoute exact path="/problems-user" component={ProblemsUser} />
            <PublicRoute exact path="/forget-password" component={ForgetPasswordComponent} />
            {parseStringToBoolean(enableOnOffFunctionCreateUser) &&
                enableFuntions.OnOffFunctionCreateUser && (
                    <PublicRoute exact path="/user-creation" component={UserCreation} />
                )}
            <PublicRoute exact path="/support" component={Support} />
            <Route exact path="/not-response" component={InternalServerError} />
            <Route exact path="/not-response-provisional" component={InternalProvisionalError} />

            {/* Private Routes */}
            <PrivateRoute exact path="/home-wallet" component={HomeWallet} />
            <PrivateRoute exact path="/savings-group" component={SavingsGroup} />
            <PrivateRoute exact path="/savings-detail" component={SavingsDetail} />
            <PrivateRoute exact path="/credits-group" component={CreditGroup} />
            <PrivateRoute exact path="/credits-detail" component={CreditDetailComponent} />
            <PrivateRoute exact path="/tar-detail" component={TarDetail} />
            <PrivateRoute exact path="/product-opening" component={ProductsOpening} />

            {parseStringToBoolean(enableOnOffFunctionRecreationalTermTitleTar) &&
                enableFuntions.OnOffFunctionRecreationalTermTitleTar && (
                    <PrivateRoute
                        exact
                        path="/recreational-termtitle"
                        component={RecreationalTermTitle}
                    />
                )}
            {parseStringToBoolean(enableOnOffFunctionChangePassword) &&
                enableFuntions.OnOffFunctionChangePassword && (
                    <PrivateRoute exact path="/key-change" component={KeyChange} />
                )}
            <PrivateRoute exact path="/calculate-credit" component={CreditSimulation} />
            {parseStringToBoolean(enableOnOffFunctionTravelMemos) &&
                enableFuntions.OnOffFunctionTravelMemos && (
                    <PrivateRoute
                        exact
                        path="/activate-international-card"
                        component={ActivateInternationalCard}
                    />
                )}
            {parseStringToBoolean(enableM2PublishRealState) &&
                enableFuntions.OnOffFunctionM2PublishRealState && (
                    <PrivateRoute exact path="/possessions-insert" component={PossessionsInsert} />
                )}

            {parseStringToBoolean(enableM2GetRealStates) &&
                enableFuntions.OnOffFunctionM2GetRealStates && (
                    <PrivateRoute
                        exact
                        path="/possessions-consult"
                        component={PossessionsConsult}
                    />
                )}
            {parseStringToBoolean(enableOnOffFunctionCodebtor) &&
                enableFuntions.OnOffFunctionCodebtor && (
                    <PrivateRoute exact path="/codebtors" component={Codebtors} />
                )}
            <PrivateRoute exact path="/balances-extracts" component={BalancesExtracts} />
            {parseStringToBoolean(enableOnOffFunctionTheftLock) &&
                enableFuntions.OnOffFunctionTheftLock && (
                    <PrivateRoute exact path="/block-card-steps" component={BlockCardSteps} />
                )}

            {parseStringToBoolean(enableTDC) && (
                <PrivateRoute exact path="/tdc-opening" component={TdcOpening} />
            )}
            <PrivateRoute exact path="/support-private" component={Support} />
            <PrivateRoute exact path="/payments" component={PaymentsComponent} />
            <PrivateRoute exact path="/payments-result" component={PaymentsResult} />
            <PrivateRoute exact path="/multi-payments-tdc" component={MultiPaymentsTDCComponent} />
            <PrivateRoute exact path="/paymentsfai" component={PaymentsFAIComponent} />
            <PrivateRoute exact path="/paymentsfai-result" component={PaymentFaiResult} />

            {parseStringToBoolean(enableOnOffFunctionCupsTermsQuotas) &&
                enableFuntions.OnOffFunctionTermQuotaRate && (
                    <PrivateRoute exact path="/cups-terms-quotas" component={CupsTermsQuotas} />
                )}
            {parseStringToBoolean(enableOnOffFunctionRetentionCertificate) &&
                enableFuntions.OnOffFunctionRetentionCertificate && (
                    <PrivateRoute exact path="/Certificate-retention" component={Certificate} />
                )}

            {parseStringToBoolean(enableOnOffFunctionMortgages) &&
                enableFuntions.OnOffFunctionMortgageClient && (
                    <PrivateRoute exact path="/mortgages" component={Mortgages} />
                )}
            {parseStringToBoolean(enableOnOffFunctionBenefits) &&
                enableFuntions.OnOffFunctionBenefits && (
                    <PrivateRoute exact path="/benefits" component={Benefits} />
                )}
            {parseStringToBoolean(enableOnOffFunctionPledge) &&
                enableFuntions.OnOffFunctionSeveranceQuote && (
                    <PrivateRoute exact path="/pledge" component={Pledge} />
                )}
            {parseStringToBoolean(enableOnOffFunctionScoresAndQuotas) &&
                enableFuntions.OnOffFunctionScoresAndQuotas && (
                    <PrivateRoute exact path="/scorequotas" component={ScoresQuotas} />
                )}
            {parseStringToBoolean(enableOnOffFunctionACHReport) &&
                enableFuntions.OnOffFunctionACHReport && (
                    <PrivateRoute exact path="/reportACH" component={ReportACH} />
                )}
            {parseStringToBoolean(enableCreditCard) && (
                <PrivateRoute exact path="/credit-card-opening" component={OpeningCreditCard} />
            )}
            {parseStringToBoolean(enableOnOffFunctionCampaignRequest) &&
                enableFuntions.OnOffFunctionCampaignRequest && (
                    <PrivateRoute
                        exact
                        path="/campaign-card-opening"
                        component={OpeningCampaignCard}
                    />
                )}
            {parseStringToBoolean(enableOnOffFunctionProductCertificate) &&
                enableFuntions.OnOffFunctionProductCertificate && (
                    <PrivateRoute exact path="/Certificate-pdf" component={CertificateProduct} />
                )}
            {parseStringToBoolean(enableCreditCard) && (
                <PrivateRoute exact path="/portfolio-purchase" component={PortfolioPurchase} />
            )}
            {parseStringToBoolean(enableOnOffFunctionTcRedeemQuotas) &&
                enableFuntions.OnOffFunctionTcRedeemQuotas && (
                    <PrivateRoute exact path="/card-redirect" component={CardRedirect} />
                )}
            {parseStringToBoolean(enableCreditCard) && (
                <PrivateRoute exact path="/credit-card-detail" component={CreditCardDetail} />
            )}
            {parseStringToBoolean(enableCreditCard) && (
                <PrivateRoute exact path="/activate-card" component={ActivateCardComponent} />
            )}

            {parseStringToBoolean(enableOnOffFunctionAssignProgressKey) &&
                enableFuntions.OnOffFunctionAssignProgressKey && (
                    <PrivateRoute exact path="/advance-key-steps" component={AssignAdvanceKey} />
                )}
            {parseStringToBoolean(enableOnOffFunctionKeyChangeForwardToForget) &&
                enableFuntions.OnOffFunctionKeyChangeForwardtoForget && (
                    <PrivateRoute exact path="/reset-key-advances" component={ForgetAdvanceKey} />
                )}

            {parseStringToBoolean(OnOffFunctionKeyChangeForward) &&
                enableFuntions.OnOffFunctionKeyChangeForward && (
                    <PrivateRoute exact path="/change-key-advances" component={AdvanceKeyChange} />
                )}
            {parseStringToBoolean(enableOnOffFunctionPaymentRegistration) &&
                enableFuntions.OnOffFunctionPaymentRegistration && (
                    <PrivateRoute
                        exact
                        path="/payment-schedule-registration"
                        component={PaymentScheduleRegistrationTC}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionPaymentCancellation) &&
                enableFuntions.OnOffFunctionPaymentCancellation && (
                    <PrivateRoute
                        exact
                        path="/payment-schedule-cancellation"
                        component={PaymentScheduleCancellationTC}
                    />
                )}
            {parseStringToBoolean(enableOnOffFunctionAdvanceFaiAccount) &&
                enableFuntions.OnOffFunctionAdvanceFaiAccount && (
                    <PrivateRoute
                        exact
                        path="/advance-to-fai-account"
                        component={AdvanceToFAIAccount}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionAgreementCertificate) &&
                enableFuntions.OnOffFunctionAgreementCertificate && (
                    <PrivateRoute
                        exact
                        path="/certification-for-agreements"
                        component={CertificationForAgreements}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionBondingCertificate) &&
                enableFuntions.OnOffFunctionBondingCertificate && (
                    <PrivateRoute
                        exact
                        path="/certification-for-affiliation"
                        component={CertificateOfAffiliation}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionFaiAccountCertificate) &&
                enableFuntions.OnOffFunctionFaiAccountCertificate && (
                    <PrivateRoute exact path="/certification-fai" component={CertificateFAI} />
                )}

            {parseStringToBoolean(enableOnOffFunctionFaiAccountOpenCertificate) &&
                enableFuntions.OnOffFunctionFaiAcountOpenCertificate && (
                    <PrivateRoute
                        exact
                        path="/certification-fai-account-opening"
                        component={CertificateOfFAIAccountOpening}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionBetweenAccountsFAI) &&
                enableFuntions.OnOffFunctionBetweenAccountsFAI && (
                    <PrivateRoute
                        exact
                        path="/fai-account-transfer"
                        component={FaiAccountTransfer}
                    />
                )}
            {parseStringToBoolean(enableOnOffFunctionUpdateAssociate) &&
                enableFuntions.OnOffFunctionUpdateAssociate && (
                    <PrivateRoute path="/data-update" component={DataUpdate} />
                )}
            {parseStringToBoolean(enableOnOffFunctionCaviFacil) &&
                enableFuntions.OnOffFunctionCaviFacil && (
                    <PrivateRoute
                        exact
                        path="/cavifacil-transaction"
                        component={CavifacilTransaction}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionACH) && enableFuntions.OnOffFunctionACH && (
                <PrivateRoute exact path="/interbankTransfer-ACH" component={InterbankTransfer} />
            )}

            {parseStringToBoolean(enableOnOffFunctionAlertTC) &&
                enableFuntions.OnOffFunctionAlertsTC && (
                    <PrivateRoute
                        exact
                        path="/credit-card-list-request"
                        component={ListOfRequest}
                    />
                )}

            {parseStringToBoolean(enableOnOffFunctionAlertTC) &&
                enableFuntions.OnOffFunctionAlertsTC && (
                    <PrivateRoute
                        exact
                        path="/details-credit-card-request"
                        component={DetailCreditCardRequests}
                    />
                )}

            {isAuthorizedString === 'Administrador' && (
                <PrivateRoute exact path="/userManagement" component={UserManagement} />
            )}

            {isAuthorizedString === 'Administrador' && (
                <PrivateRoute exact path="/rolManagement" component={AssignRoles} />
            )}
            {isAuthorizedString === 'Administrador' && (
                <PrivateRoute exact path="/manageableContent" component={ManageableContent} />
            )}
            {(isAuthorizedString === 'Administrador' || isAuthorizedString === 'ADMCall') && (
                <PrivateRoute exact path="/userOperations" component={UserOperations} />
            )}

            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact children={<Redirect to="/login" />} />
            <Route path="/*" exact children={<Redirect to="/not-found" />} />
        </Switch>
    )
}
