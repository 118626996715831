import { balancesExtractsService } from '../../../../../domain/services/User.service'

export async function useGetCreditSaving(tokenSave: string) {
    try {
        return await balancesExtractsService
            .getCreditSaving(
                'api/Products/GetCreditAndSavingsProducts/GetCreditAndSavingsProducts',
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function useGetBenefits(tokenSave: string) {
    try {
        return await balancesExtractsService
            .getBenefits('api/Products/GetProductsProfit/GetProductsProfit', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetDataUser(tokenSave: string) {
    try {
        return await balancesExtractsService
            .getDataUser('api/Products/GetDataUser/GetDataUser', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function usePostPreviousBalance(tokenSave: string, body: any) {
    try {
        return await balancesExtractsService
            .postDetails('api/Products/GetPreviousBalance/GetPreviousBalance', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function usePostFinalBalance(tokenSave: string, body: any) {
    try {
        return await balancesExtractsService
            .postDetails(
                'api/Products/GetTotalConceptBalance/GetTotalConceptBalance',
                tokenSave,
                body
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function usePostMovementDetail(tokenSave: string, body: any) {
    try {
        return await balancesExtractsService
            .postDetails('api/Products/MovementsAmounts/MovementsAmounts', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function usePostMovementCxC(tokenSave: string, body: any) {
    try {
        return await balancesExtractsService
            .postDetails('api/Products/GetAcountsReceivable/GetAcountsReceivable', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function usePostLoanDetail(tokenSave: string, body: any) {
    try {
        return await balancesExtractsService
            .postDetails('api/Products/GetLoanDetail/GetLoanDetail', tokenSave, body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

//PDF
export async function useGetAgreementCertificateClient(tokenSave: string) {
    try {
        return await balancesExtractsService
            .getAgreementCertificateClient('api/Client/GetAgreementCertificateClient', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
export async function useGetBalancesAndStatements(tokenSave: string) {
    try {
        return await balancesExtractsService
            .getBalancesAndStatements(
                'api/Products/GetBalancesAndStatements/GetBalancesAndStatements',
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
