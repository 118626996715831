import { Button } from 'react-bootstrap'
import tw, { styled } from 'twin.macro'

export const Container = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-8 w-full max-w-[600px] lg:max-w-[640px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export const TitleComponent = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[16px] font-montserrat text-dominant-dark left-0`}
`

export const SectionImage = styled.div`
    ${tw`flex justify-center items-center py-2`}
`

export const Image = styled.img`
    ${tw`w-28 mr-5`}
`

export const SectionTextImage = styled.div`
    ${tw`flex justify-center items-center py-2 font-montserrat`}

    p {
        font-size: 16px;
    }
`

export const SectionText = styled.div`
    ${tw`left-0 py-2 font-montserrat`}

    p {
        font-size: 16px;
    }

    a {
        text-decoration: none;
        color: #f5a50b;
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-4 lg:px-1`}
`

export const CancelButton = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #fff !important;
    border-color: var(--sub-dominant-color) !important;
    color: var(--sub-dominant-color) !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ButtonContinue = styled(Button)`
    ${tw`my-auto mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;

    &:hover {
        background-color: var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (min-width: 350px) and (max-width: 550px) {
        ${tw`w-[100%]`}
    }
`
