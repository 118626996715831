import { creditSimulationService } from '../../../../../domain/services/User.service'

const useGetCreditSimulator = async (tokenSave: string) => {
    try {
        return await creditSimulationService
            .getCreditSimulator('api/Simulator/GetCreditSimulator/GetCreditSimulator', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

const usePostRateFeeSimulator = (tokenSave: string) => {
    const postRateFeeSimulator = async (body: any): Promise<any> => {
        try {
            const response = await creditSimulationService.postRateFeeSimulator(
                'api/Simulator/GetRateFeeSimulator/GetRateFeeSimulator',
                tokenSave,
                body
            )
            return response
        } catch (error) {
            console.error('Error:', error)
            throw error
        }
    }

    return { postRateFeeSimulator }
}

const usePostPaymentPlanSimulator = (tokenSave: string) => {
    const postPaymentPlanSimulator = async (newData: any) => {
        try {
            const response = await creditSimulationService.postPaymentPlanSimulator(
                'api/Simulator/PaymentPlanSimulator/PaymentPlanSimulator',
                tokenSave,
                newData
            )

            return response
        } catch (error) {
            console.error('Error en la llamada al endpoint:', error)
            throw error
        }
    }

    return { postPaymentPlanSimulator }
}

export { useGetCreditSimulator, usePostRateFeeSimulator, usePostPaymentPlanSimulator }
