import { AxiosResponse } from 'axios'
import { paymentScheduleRegistrationTC } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useScheduledPaymentFAI(tokenSave: string, data: any): Promise<Result> {
    try {
        return await paymentScheduleRegistrationTC
            .postInscriptionScheduledPayment(
                '/api/Products/ScheduledPaymentFAI/ScheduledPaymentFAI',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
