import { FC, useEffect, useState } from 'react'
import { Form, Container, Row, Col, Pagination, PageItem } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { ButtonPagination, PaginationContent, Title } from './ContactInformation-styles'
import {
    Cities,
    Country,
    ListDataCrm,
    Options,
    UserData,
} from '../../../../../../domain/models/crm'
import { contactInformationSchema } from '../../validations/ValidationShema'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContainerForm } from '../PersonalInformation/PersonalInformation-styles'
import Select from 'react-select'
import { NoteText } from '../../DataUpdate-styles'
import { normalizeText } from '../../../../utils/misc'

interface ContactInformationProps {
    userData: UserData
    onUpdate: (data: Partial<UserData>) => void
    handleNextStep: (value: string) => void
    stratumList: ListDataCrm[]
    patronalList: ListDataCrm[]
    departmentList: Options[]
    fetchCities: (departmentName: string) => Cities[]
    countriesList: Country[]
}

const ContactInformation: FC<ContactInformationProps> = ({
    userData,
    onUpdate,
    handleNextStep,
    countriesList,
    stratumList,
    patronalList,
    departmentList,
    fetchCities,
}): JSX.Element => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<UserData>({
        resolver: yupResolver(contactInformationSchema),
        mode: 'all',
        defaultValues: userData,
    })

    const [cityOptions, setCityOptions] = useState<Cities[]>([])
    const [cityOfficeOptions, setCityOfficeOptions] = useState<Cities[]>([])

    const onSubmit = (data: Partial<UserData>): void => {
        const processedData = {
            ...data,
            Stratum: Number(data.Stratum),
            Patronal: Number(data.Patronal),
        }
        onUpdate(processedData)
        handleNextStep('financialInfo')
    }

    const selectedCountry = watch('Country')

    useEffect(() => {
        loadCityAndDepartmentData()
        loadCityAndDepartmentOfficeData()
    }, [])

    const loadCityAndDepartmentData = async (): Promise<void> => {
        try {
            if (userData.City) {
                fetchCitiesResidence(userData.City.Deparment || '')
                setValue('DeparmentName', userData.City.Deparment)
                setValue('CityName', userData.City?.NewName)
            }
        } catch (error) {
            console.error('Error fetching cities or setting values:', error)
        }
    }

    const loadCityAndDepartmentOfficeData = async (): Promise<void> => {
        try {
            if (userData.OfficeCity?.Deparment) {
                fetchListCitiesOffices(userData.OfficeCity?.Deparment)
                setValue('OfficeDeparmentName', userData.OfficeCity?.Deparment || '')
                setValue('OfficeCityName', userData.OfficeCity?.NewName || '')
            }
        } catch (error) {
            console.error('Error fetching cities or setting values:', error)
        }
    }

    const countryOptions = countriesList
        .map((country) => ({
            value: country.Country,
            label: country.Country,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

    const fetchCitiesResidence = (departmentName: string): void => {
        const citiesList = fetchCities(departmentName)
        setCityOptions(citiesList)
    }

    const clearDataCityResidence = (): void => {
        setValue(
            'City',
            { NewName: '', Deparment: '' },
            {
                shouldValidate: true,
            }
        )
        setValue('CityName', '')
        setCityOptions([])
    }

    const fetchListCitiesOffices = (departmentNameOffice: string): void => {
        const citiesList = fetchCities(departmentNameOffice)
        setCityOfficeOptions(citiesList)
    }

    const clearDataCityOffice = (): void => {
        setValue(
            'OfficeCity',
            { NewName: '', Deparment: '' },
            {
                shouldValidate: true,
            }
        )
        setValue('OfficeCityName', '')
        setCityOfficeOptions([])
    }

    const clearDataDeparment = (): void => {
        clearDataCityResidence()
        setValue('DeparmentName', '')
        setValue('new_otraciudadpasresid', '', { shouldValidate: true })
    }

    const onChangeCountry = (selectedCountry: string): void => {
        if (selectedCountry !== 'Colombia') {
            const otherCity = 'Otra ciudad'
            setValue('DeparmentName', 'País')
            setValue('CityName', otherCity)
            setValue('new_otraciudadpasresid', otherCity)
        }
    }

    return (
        <Container>
            <Title>Datos Contacto</Title>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ContainerForm>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Dirección de Domicilio</Form.Label>
                        </Col>
                        <Col md={10}>
                            <Controller
                                control={control}
                                name={'HomeAddress'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={190}
                                            {...field}
                                            isInvalid={!!errors.HomeAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.HomeAddress?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Barrio</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'Neighborhood'}
                                    render={({ field }) => (
                                        <Form.Group>
                                            <Form.Control
                                                maxLength={190}
                                                {...field}
                                                isInvalid={!!errors.Neighborhood}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.Neighborhood?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Label>País</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Country"
                                control={control}
                                render={({ field }) => {
                                    const matchedCity = countryOptions.find(
                                        (option) =>
                                            normalizeText(option.label) ===
                                            normalizeText(field.value?.NewName ?? '')
                                    )

                                    if (!matchedCity && field.value?.NewName) {
                                        setTimeout(() => {
                                            field.onChange({ NewName: '' })
                                        }, 0)
                                    }

                                    return (
                                        <Form.Group>
                                            <Select
                                                className="selectFromControl"
                                                placeholder="Seleccione"
                                                options={countryOptions}
                                                value={matchedCity || undefined}
                                                onChange={(selectedOption) => {
                                                    clearDataDeparment()
                                                    if (selectedOption) {
                                                        field.onChange({
                                                            NewName: selectedOption.value,
                                                        })
                                                        setValue(
                                                            'CountryName',
                                                            selectedOption.value
                                                        )
                                                        onChangeCountry(selectedOption.value)
                                                    } else {
                                                        field.onChange({ NewName: '' })
                                                        setValue('CountryName', '')
                                                    }
                                                }}
                                                isClearable
                                            />

                                            <Form.Control.Feedback
                                                type="invalid"
                                                style={{ display: 'block' }}
                                            >
                                                {errors.Country?.NewName?.message ||
                                                    errors.Country?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        {selectedCountry?.NewName === 'Colombia' && (
                            <>
                                <Col md={2}>
                                    <Form.Label>Departamento</Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Controller
                                        control={control}
                                        name={'DeparmentName'}
                                        render={({ field }) => (
                                            <Form.Group>
                                                <Select
                                                    className={'selectFromControl'}
                                                    placeholder="Seleccione"
                                                    options={departmentList}
                                                    value={
                                                        departmentList.find(
                                                            (option) =>
                                                                option.value === (field.value ?? '')
                                                        ) || null
                                                    }
                                                    onChange={(selectedOption) => {
                                                        clearDataCityResidence()
                                                        if (selectedOption) {
                                                            field.onChange(
                                                                selectedOption
                                                                    ? selectedOption.value
                                                                    : null
                                                            )
                                                            fetchCitiesResidence(
                                                                selectedOption.value
                                                            )
                                                        } else {
                                                            field.onChange('')
                                                        }
                                                    }}
                                                    isClearable
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.DeparmentName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    />
                                </Col>
                            </>
                        )}
                        <Col md={2}>
                            <Form.Label>Ciudad</Form.Label>
                        </Col>
                        <Col md={4}>
                            {selectedCountry?.NewName === 'Colombia' ? (
                                <Controller
                                    control={control}
                                    name={'CityName'}
                                    render={({ field }) => (
                                        <Form.Group>
                                            <Select
                                                className={'selectFromControl'}
                                                placeholder="Seleccione"
                                                options={cityOptions}
                                                value={
                                                    cityOptions.find(
                                                        (option) =>
                                                            option.value === (field.value ?? '')
                                                    ) || null
                                                }
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        field.onChange(selectedOption.value)
                                                        setValue('City', {
                                                            NewName: selectedOption.value,
                                                            Deparment: selectedOption.department,
                                                        })
                                                    } else {
                                                        field.onChange('')
                                                    }
                                                }}
                                                isClearable
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.CityName?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                />
                            ) : (
                                <Controller
                                    name="new_otraciudadpasresid"
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Group>
                                            <Form.Control
                                                maxLength={50}
                                                {...field}
                                                isInvalid={!!errors.new_otraciudadpasresid}
                                                value={field.value}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.new_otraciudadpasresid?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        <Col md={2}>
                            <Form.Label>Estrato</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Stratum"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.Stratum}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {stratumList.map((stratum) => (
                                                <option key={stratum.Id} value={stratum.Code}>
                                                    {stratum.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Stratum?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Teléfono Fijo</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Landline"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.Landline}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Landline?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center mb-3 mt-5">
                        <Col md={8}>
                            <Form.Label>
                                Nombre de la empresa que genera el vínculo de asociación
                            </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Patronal"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.Patronal}
                                            value={field.value ?? ''}
                                        >
                                            <option value="">Seleccione...</option>
                                            {patronalList.map((patronal) => (
                                                <option key={patronal.Id} value={patronal.Code}>
                                                    {patronal.Description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Patronal?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Cargo</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="Charge"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.Charge}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.Charge?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Dirección Oficina</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="OfficeAddress"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.OfficeAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.OfficeAddress?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={2}>
                            <Form.Label>Departamento Oficina</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'OfficeDeparmentName'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Select
                                            className={'selectFromControl'}
                                            placeholder="Seleccione"
                                            options={departmentList}
                                            value={
                                                departmentList.find(
                                                    (option) => option.value === (field.value ?? '')
                                                ) || null
                                            }
                                            onChange={(selectedOption) => {
                                                clearDataCityOffice()
                                                if (selectedOption?.value) {
                                                    field.onChange(
                                                        selectedOption ? selectedOption.value : null
                                                    )

                                                    fetchListCitiesOffices(selectedOption?.value)
                                                } else {
                                                    field.onChange('')
                                                }
                                            }}
                                            isClearable
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.OfficeDeparmentName?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Label>Cuidad Oficina</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name={'OfficeCityName'}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Select
                                            className={'selectFromControl'}
                                            placeholder="Seleccione"
                                            options={cityOfficeOptions}
                                            value={
                                                cityOfficeOptions.find(
                                                    (option) => option.value === (field.value ?? '')
                                                ) || null
                                            }
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    field.onChange(selectedOption.value)
                                                    setValue('OfficeCity', {
                                                        NewName: selectedOption.value,
                                                        Deparment: selectedOption.department,
                                                    })
                                                } else {
                                                    field.onChange('')
                                                }
                                            }}
                                            isClearable
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.OfficeCityName?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        <Col md={2}>
                            <Form.Label>Teléfono Oficina</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="OfficeTelephone"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.OfficeTelephone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.OfficeTelephone?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Form.Label>Correo Corporativo</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Controller
                                name="CorporateEmail"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Control
                                            maxLength={50}
                                            {...field}
                                            isInvalid={!!errors.CorporateEmail}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.CorporateEmail?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-start mb-3">
                        <Col md={12}>
                            <NoteText>
                                Información importante: Si necesita, actualizar su número de
                                teléfono celular y correo electrónico personal. Deberá ponerse en
                                contacto con el servicio al asociado.
                            </NoteText>
                        </Col>
                    </Row>
                </ContainerForm>

                <PaginationContent>
                    <Pagination>
                        <ButtonPagination>
                            <PageItem
                                className="disableFm"
                                onClick={() => handleNextStep('personalInfo')}
                            >
                                {'<'} Anterior
                            </PageItem>
                        </ButtonPagination>
                        <p className="symbol"> {'/'} </p>
                        <ButtonPagination>
                            <PageItem className="disableFm" onClick={handleSubmit(onSubmit)}>
                                Continuar {'>'}
                            </PageItem>
                        </ButtonPagination>
                    </Pagination>
                </PaginationContent>
            </form>
        </Container>
    )
}

export default ContactInformation
