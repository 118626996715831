import { FC, useEffect, useState } from 'react'
import { LayoutContent } from '../../../../transverse'
import LockConfirmation from './LockConfirmation'
import LockCode from './LockCode'
import { useSelector } from 'react-redux'
import {
    useUserInfo,
    useStatusTC,
    useOfficeInfo,
    useReissueCard,
} from '../../hooks/useCreditDetail'
import moment from 'moment'
import ModalInfo from '../ModalInfo/ModalInfo'
import { CreditCardsSelector } from '../../../../../selectors'
import { useLocation } from 'react-router-dom'
import ModalLoading from '../../../ActivateInternationalCard/inc/ModalInfo/ModalLoading'

const BlockCardSteps: FC = (): JSX.Element => {
    const [step, setStep] = useState(0)
    //User data
    const [cellphone, setCellphone] = useState('')
    const [email, setEmail] = useState('')
    const [residenceAddress, setResidenceAddress] = useState('')
    //Office data
    const [officeName, setOfficeName] = useState('')
    const [officeAddress, setOfficeAddress] = useState('')
    const [officePhone, setOfficePhone] = useState('')
    const [officeDay, setOfficeDay] = useState('')
    const [officeHour, setOfficeHour] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    const [lastsFourEncrypted, setLastsFourEncrypted] = useState<string | null>('')
    const [lastsFourNumCard, setLastsFourNumCard] = useState('')
    const [dateExpiredCard, setDateExpiredCard] = useState('')
    const [cardNumber, setCardNumber] = useState('')

    const [valorTcVariablePortal, setValorTcVariablePortal] = useState('')

    // selectors
    const { data } = useSelector(CreditCardsSelector)
    const location = useLocation()

    useEffect(() => {
        const locationText = location.search
        const idEncrypted = locationText.replace('?id=', '')

        data?.cards.find((card) => {
            if (card.lastFourDigitsEncryptedCC === idEncrypted) {
                setLastsFourEncrypted(idEncrypted)
                setLastsFourNumCard(card.lastFourDigits)
                setDateExpiredCard(card.dateExpiredCard)
                setCardNumber(card.cardNumber)
            }
        })
    }, [])

    const handleClose = () => {
        setShowModal(false)
    }

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        GetUserInfo()
        GetOfficeInfo()
        GetReissueCard()
    }, [tokenSave])

    const GetUserInfo = (): void => {
        useUserInfo(tokenSave).then((res: any) => {
            if (res.Response === '200') {
                setCellphone(res.Data.CellPhone)
                setEmail(res.Data.Email)
                setResidenceAddress(res.Data.ResidenceAdress)
            }
        })
    }

    const GetOfficeInfo = (): void => {
        useOfficeInfo(tokenSave).then((res: any) => {
            if (res.Response === '200') {
                setOfficeName(res.Data.ofC_Name)
                setOfficeAddress(res.Data.ofC_address)
                setOfficePhone(res.Data.ofC_Phone)
                const matchResult = res.Data.ofC_Attention_Hours
                    .split('|')
                    .map((item: string) => item.trim())

                if (matchResult.length > 0) {
                    const [ofDay, ofStartHour, ofEndHour] = matchResult
                    const ofHour = `${ofStartHour} ${ofEndHour}`
                    setOfficeDay(ofDay)
                    setOfficeHour(
                        ofHour
                            .replace(/\|/g, ' ')
                            .replace(/(\d:\d{2} [ap]m) (\d:\d{2} [ap]m)/, '$1 - $2')
                    )
                }
            }
        })
    }

    const GetReissueCard = (): void => {
        useReissueCard(tokenSave).then((res: any) => {
            if (res.StateCode === '200') {
                setValorTcVariablePortal(formatter.format(res.ObjectValue.ValorTcVariablePortal))
            }
        })
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    const HandleFormSubmit = () => {
        const dataSend = {
            TransactionDate: moment().format('YYYY-MM-DD'),
            TransactionTime: moment().format('YYYY-MM-DD'),
            EffectiveDate: moment().format('YYYY-MM-DD'),
            Consecutive: '10',
            NumberCard: cardNumber,
            Filler2: dateExpiredCard,
            Status: '3', //3 Bloqueo Permanente
        }

        // Construye el FormData
        const formDataToSend = new FormData()
        formDataToSend.append('body', JSON.stringify(dataSend))

        // console.log('formDataToSend: ' + JSON.stringify(dataSend))

        setIsLoading(true)

        useStatusTC(formDataToSend, tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res['status'] === 200) {
                    setStep(step + 1)
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'No se ha podido bloquear la tarjeta, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    return (
        <LayoutContent>
            {step === 0 ? (
                <LockCode
                    handleFormSubmit={HandleFormSubmit}
                    tokenSave={tokenSave}
                    cellphone={cellphone}
                    email={email}
                    lastsFourNumCard={lastsFourNumCard}
                    lastsFourEncrypted={lastsFourEncrypted?.toString()}
                />
            ) : (
                <LockConfirmation
                    lastsFourNumCard={lastsFourNumCard}
                    residenceAddress={residenceAddress}
                    officeName={officeName}
                    officeAddress={officeAddress}
                    officePhone={officePhone}
                    officeDay={officeDay}
                    officeHour={officeHour}
                    valorTcVariablePortal={valorTcVariablePortal}
                />
            )}
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleClose}
            />
            <ModalLoading isLoading={isLoading} />
        </LayoutContent>
    )
}

export default BlockCardSteps
