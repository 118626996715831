import { Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import {
    ButtonsContainer,
    ModalButton,
    ModalButtonConfirmation,
    ModalConfirmation,
} from '../ConfirmationModal/ConfirmationModal-Styled'

interface ErrorModalAppProps {
    showModal?: boolean
    handleCloseModal: () => void
    textBody?: string
    textTitle?: string
    img?: string
    link?: boolean
    isChangeKeyButton?: boolean
    lastFourDigitsEncryptedCC?: string
}

const ErrorModal: React.FC<ErrorModalAppProps> = ({
    showModal,
    handleCloseModal,
    textBody,
    textTitle,
    img,
    link,
    isChangeKeyButton,
    lastFourDigitsEncryptedCC,
}): JSX.Element => {
    const history = useHistory()
    return (
        <>
            <ModalConfirmation
                show={showModal}
                onHide={handleCloseModal}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    {img && <img src={img} alt="Editar logo" className="" />}
                    {textTitle && <h6>{textTitle}</h6>}
                    {textBody && <p>{textBody}</p>}
                    {link && (
                        <>
                            <p>
                                Si no recuerdas tu clave, haz clic en el siguiente enlace para
                                restablecerla:{' '}
                                <Link to="/reset-key-advances">Olvidé mi clave para avances</Link>
                            </p>
                        </>
                    )}
                    {!isChangeKeyButton && (
                        <ButtonsContainer>
                            <ModalButtonConfirmation variant="primary" onClick={handleCloseModal}>
                                Aceptar
                            </ModalButtonConfirmation>
                        </ButtonsContainer>
                    )}
                    {isChangeKeyButton && (
                        <ButtonsContainer>
                            <ModalButton
                                variant="primary"
                                className="change-key"
                                onClick={() =>
                                    history.push(
                                        `/change-key-advances?id=${lastFourDigitsEncryptedCC}`
                                    )
                                }
                            >
                                Ir a Cambio de Clave de Avance
                            </ModalButton>
                        </ButtonsContainer>
                    )}
                </Modal.Body>
            </ModalConfirmation>
        </>
    )
}

export default ErrorModal
