import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { featureEnableFunctionsSelector } from '../../../selectors'
import { getFeatureEnableFunctionsAction } from '../../../redux/featureEnableFunctions/featureEnableFunctions.actions'
import { parseStringToBoolean } from '../../utils/misc'

export function useFeatureEnableFunctions(functionName: string): boolean {
    const dispatch = useDispatch()
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    useEffect(() => {
        dispatch(getFeatureEnableFunctionsAction())
    }, [dispatch])

    const functionality =
        functionalityQuery &&
        (functionalityQuery.find((item) => item.name === functionName)?.value as 'false' | 'true')

    return parseStringToBoolean(functionality)
}

export function useFeatureEnableFunctionsArray(): any[] {
    const dispatch = useDispatch()
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    useEffect(() => {
        dispatch(getFeatureEnableFunctionsAction())
    }, [dispatch])

    return functionalityQuery || []
}
