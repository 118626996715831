import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../../transverse'
import {
    ButtonCanceled,
    ContainerBtn,
    ContentForm,
    DivContainer,
    FormOptions,
    Label,
    LinkSearchUser,
    SectionTitle,
    SpaceHr,
    TitlePage,
} from '../UserOperations-styled'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Form, Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import { Button } from '../../../components'

interface Option {
    key: number
    value: string | number
    label: string
}

const options: Option[] = [
    { key: 0, value: 'Bloqueo/Desbloqueo', label: 'Bloqueo y Desbloqueo de Usuario' },
    { key: 1, value: 'InicializarUsuario', label: 'Inicializar Usuario' },
]

interface OperationManagerProps {
    onNextStep: (step: number) => void
    handleSubmitted: (
        isSearchUserName: boolean,
        userName: string,
        documentNumber: string,
        selectService: string
    ) => void
}

const OperationManager: React.FC<OperationManagerProps> = ({
    onNextStep,
    handleSubmitted,
}): JSX.Element => {
    const [userName, setUserName] = useState<string>('')
    const [identificationCard, setIdentificationCard] = useState<string>('')
    const [selectService, setSelectService] = useState<string>('')
    const requiredField = 'Este campo es requerido'

    const history = useHistory()

    const methods = useForm()
    const { control, formState, setError, clearErrors, register, handleSubmit } = methods
    const { errors } = formState

    const HandleFormSubmit = (): void => {
        onNextStep(4)
        handleSubmitted(false, userName, identificationCard, selectService)
    }

    const handleSearchUser = (): void => {
        clearErrors('selectService')
        clearErrors('userName')
        clearErrors('identificationCard')

        handleSubmitted(true, userName, identificationCard, selectService)
        onNextStep(2)
    }

    return (
        <LayoutContent>
            <SpaceHr />

            <SectionTitle>
                <TitlePage>Manejo usuarios</TitlePage>
            </SectionTitle>
            <ContentForm>
                <FormProvider {...methods}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Login asociado</Label>
                                <Controller
                                    control={control}
                                    name={'userName'}
                                    rules={{
                                        required: userName === '' ? requiredField : false,
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            type="text"
                                            maxLength={16}
                                            minLength={1}
                                            {...register('userName', {
                                                required: userName === '',
                                            })}
                                            onChange={(e) => {
                                                setUserName(e.target.value)
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('userName', {
                                                        message: requiredField,
                                                    })
                                                } else {
                                                    clearErrors('userName')
                                                }
                                                onBlur()
                                            }}
                                            isInvalid={!!errors['userName']}
                                            value={userName}
                                        />
                                    )}
                                />
                                {errors['userName'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['userName']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Cédula asociado</Label>
                                <Controller
                                    control={control}
                                    name={'identificationCard'}
                                    rules={{
                                        required: identificationCard === '' ? requiredField : false,
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            type="number"
                                            maxLength={16}
                                            minLength={1}
                                            {...register('identificationCard', {
                                                required: identificationCard === '',
                                            })}
                                            onChange={(e) => {
                                                setIdentificationCard(e.target.value)
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('identificationCard', {
                                                        message: requiredField,
                                                    })
                                                } else {
                                                    clearErrors('identificationCard')
                                                }
                                                onBlur()
                                            }}
                                            isInvalid={!!errors['identificationCard']}
                                            value={identificationCard}
                                            required
                                        />
                                    )}
                                />
                                {errors['identificationCard'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['identificationCard']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Label>Servicio</Label>
                                <Controller
                                    control={control}
                                    name={'selectService'}
                                    rules={{
                                        required: selectService === '' ? requiredField : false,
                                    }}
                                    render={({ field: { onChange, onBlur } }) => (
                                        <Form.Select
                                            className="selectFromControl"
                                            value={selectService}
                                            onChange={(event) => {
                                                const selectedService = event.target.value
                                                setSelectService(selectedService)
                                                onChange(event)
                                            }}
                                            onBlur={(event) => {
                                                if (
                                                    event.target.value === '' ||
                                                    event.target.value === null
                                                ) {
                                                    setError('selectService', {
                                                        message: requiredField,
                                                    })
                                                } else {
                                                    clearErrors('selectService')
                                                }
                                                onBlur()
                                            }}
                                            isInvalid={!!errors['selectService']}
                                            required
                                        >
                                            <FormOptions disabled value="">
                                                Seleccione una opción
                                            </FormOptions>

                                            {options.map((option) => (
                                                <FormOptions key={option.key} value={option.value}>
                                                    {option.label}
                                                </FormOptions>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                {errors['selectService'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['selectService']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <SpaceHr />

                    <DivContainer>
                        <LinkSearchUser onClick={() => handleSearchUser()}>
                            Buscar Nombre de Usuario
                        </LinkSearchUser>
                        <ButtonCanceled
                            variant="primary"
                            onClick={() => history.push('/home-wallet')}
                        >
                            Volver
                        </ButtonCanceled>

                        <form onSubmit={handleSubmit(HandleFormSubmit)}>
                            <ContainerBtn>
                                <Button
                                    id="btnSubmit"
                                    className="button-block"
                                    variant="sub-dominant"
                                    type="button"
                                    onClick={handleSubmit(HandleFormSubmit)}
                                    isLoading={false}
                                    extend
                                    disabled={false}
                                >
                                    Continuar
                                </Button>
                            </ContainerBtn>
                        </form>
                    </DivContainer>
                </FormProvider>
            </ContentForm>
        </LayoutContent>
    )
}

export default OperationManager
