/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LayoutContent } from '../../transverse'
import { BankIcon, DownloadDoc } from '../../utils/getIcons'
import {
    CancelButton,
    CaviImage,
    ContainerCaviImage,
    MessageContainer,
    PDFButton,
    PDFMessageContainer,
    PDFText,
    PpalTitle,
    SpacerContent,
    TextP,
} from './BalancesExtracts-styles'
import ModalLoading from '../ActivateInternationalCard/inc/ModalInfo/ModalLoading'
import NoProduct from './inc/NoProduct'
import SavingsTable from './inc/SavingsTable/SavingsTable'
import CreditTable from './inc/CreditsTable/CreditsTable'
import BenefitsTable from './inc/BenefitsTable'
import BalanceExtractsByProduct from './inc/BalanceExtractsByProduct'
import FinalBalance from './inc/FinalBalance'
import MovementsDetail from './inc/MovementsDetail'
import MovementsReceivable from './inc/MovementsReceivable'
import {
    useGetBenefits,
    useGetCreditSaving,
    usePostPreviousBalance,
    usePostLoanDetail,
    usePostFinalBalance,
    usePostMovementDetail,
    usePostMovementCxC,
    useGetDataUser,
} from './hooks/useBalancesExtracts'
import ProductDetail from './inc/ProductDetail'
import UserInfo from './inc/UserInfo'
import { Row } from 'react-bootstrap'
import moment from 'moment'
import { useGetAgreementCertificateClient, useGetBalancesAndStatements } from './hooks/useExportPDF'
import { CierreOperativo } from '../../utils/getImages'
import { BreadcrumbApp } from '../../components'
import { getSecurity } from '../../../http/axiosHttp'
import ButtonScreenshot from '../../components/ButtonScreenshot/ButtonScreenshot'


//PDF Types
interface EntiretieType {
    CumulativeRecognition: number
    DirectResources: number
    ECPResources: number
    ExtraordinaryResources: number
    FAITotalBalance: number
    RecreationalBalance: number
    RecreationalBenefitBalance: number
    RevaluationContributions1: number
    RevaluationContributions2: number
    SocialBenefitBalance: number
    SyPReturn: number
    TDCTotalBalance: number
    TotalResources: number
}
interface OtherTaxType {
    PaidAmount: number
    Percentage: number
    Retention: number
}

interface ProductLines {
    Periodicities: {
        Periodicity: string
        BorrowedValue: string
        Agreedfees: string
        Missingfees: string
        PercentagePaid: string
        Fixedfee: string
        VariableFee: string
        StartDate: string
    }[]
    Number: {
        Number: string
        Capital: string
        Interest: string
        EnforceablePortfolio: string
        Insurance: string
        LegalCollection: string
        repayment: string
        InternalBusiness: string
    }[]
}
interface ProductTableType {
    Description: string
    Product: string
    Rate: string
    lines: ProductLines[]
    TotalProductBalance: number
}

interface ApiResponse {
    IsSuccess: boolean;
    Data?: string;
}

const BalancesExtracts = (): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [isLoading, setIsLoading] = useState(false)
    const [balancesExtractsView, setBalancesExtracts] = useState('Home')

    const [savingData, setSavingData] = useState([])
    const [creditData, setCreditData] = useState([])
    const [benefitData, setBenefitData] = useState([])

    const [showDetailBtn, setShowDetailBtn] = useState(false)
    const [productValue, setProductValue] = useState([])
    const [documentCC, setDocumentCC] = useState('')

    const [name, setName] = useState('')
    const [nit, setNit] = useState('')
    const [register, setRegister] = useState('')
    const [membershipDate, setMembershipDate] = useState('')

    const [proDocumentNumber, setProDocumentNumber] = useState(0)
    const [finishDocumentNumber, setFinishDocumentNumber] = useState(0)
    const [period, setPeriod] = useState('')
    const [currentDate, setCurrentDate] = useState('')

    const [previousBalance, setPreviousBalance] = useState(0)
    const [totalBalance, setTotalBalance] = useState(0)
    const [finalBalance, setFinalBalance] = useState([])
    const [movementDetail, setMovementDetail] = useState([])
    const [movementCxC, setMovementCxC] = useState([])

    const [loanValue, setLoanValue] = useState([])

    //PDF VARIABLES & FUNCTIONS
    const { getAgreementCertificateClient } = useGetAgreementCertificateClient(tokenSave)
    const { getBalancesAndStatements } = useGetBalancesAndStatements(tokenSave)

    moment.locale('es')
    const pdfCurrentDate = moment().format('DD/MM/YYYY')
    const lastYear = moment().subtract(1, 'year').year()
    //Agreement Certificate Client
    const [pdfDocument, setPdfDocument] = useState('')
    const [pdfName, setPdfName] = useState('')
    const [pdfRegister, setPdfRegister] = useState('')
    const [affiliationDate, setAffiliationDate] = useState('')
    //END VARIABLES & FUNCTIONS

    //Balances And Statements
    const [entiretie, setEntiretie] = useState<EntiretieType>({
        CumulativeRecognition: 0,
        DirectResources: 0,
        ECPResources: 0,
        ExtraordinaryResources: 0,
        FAITotalBalance: 0,
        RecreationalBalance: 0,
        RecreationalBenefitBalance: 0,
        RevaluationContributions1: 0,
        RevaluationContributions2: 0,
        SocialBenefitBalance: 0,
        SyPReturn: 0,
        TDCTotalBalance: 0,
        TotalResources: 0,
    })
    const [otherTaxableIncome, setOtherTaxableIncome] = useState<OtherTaxType>({
        PaidAmount: 0,
        Percentage: 0,
        Retention: 0,
    })
    const [productsTables, setProductsTables] = useState<ProductTableType[]>([])

    useEffect(() => {
        //PDF
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const agreementCertificateResponse = await getAgreementCertificateClient();
                const balancesAndStatementsResponse = await getBalancesAndStatements();

                if ((agreementCertificateResponse as any)?.data?.Data?.length > 0) {
                    const agreementData = (agreementCertificateResponse as any).data.Data;
                    setPdfDocument(agreementData[0].Document);
                    setPdfName(agreementData[0].Name);
                    setPdfRegister(agreementData[0].Register);

                    const affiliationFormatDate = moment(agreementData.AffiliationDate)
                        .format('DD/MMM/YYYY')
                        .replace(/\./g, '');
                    setAffiliationDate(affiliationFormatDate);
                }

                if ((balancesAndStatementsResponse as any)?.data?.Data) {
                    const balancesAndStatementsData = (balancesAndStatementsResponse as any).data.Data;
                    setEntiretie(balancesAndStatementsData.Entiretie)
                    setOtherTaxableIncome(balancesAndStatementsData.OtherTaxableIncome)
                    setProductsTables(balancesAndStatementsData.ProductsTables)
                }

                setIsLoading(false)

                //Balances&Extracts
                GetCreditSaving()
                GetBenefits()
                GetDataUser()

                const formattedDate = getCurrentFormattedDate()
                setCurrentDate(formattedDate)

            } catch (error) {
                setIsLoading(false)
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [])

    const getCurrentFormattedDate = () => {
        const currentDate = new Date()

        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
        const day = currentDate.getDate().toString().padStart(2, '0')
        const year = currentDate.getFullYear()

        return `${month}/${day}/${year}`
    }

    //balancesExtractsView = Home
    const GetCreditSaving = (): void => {
        setIsLoading(true)
        useGetCreditSaving(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const savings = res.data.Data.Savings
                    const credits = res.data.Data.Credits
                    setSavingData(savings)
                    setCreditData(credits)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }
    const GetBenefits = (): void => {
        setIsLoading(true)
        useGetBenefits(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const benefits = res.data.Data
                    setDocumentCC(res.data.Data[0].Documento)
                    setBenefitData(benefits)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }
    const GetDataUser = (): void => {
        setIsLoading(true)
        useGetDataUser(tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    setName(res.data.Data.Nombre)
                    setNit(res.data.Data.Nit)
                    setRegister(res.data.Data.Registro)
                    setMembershipDate(res.data.Data.FechaAfiliacion)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    //balancesExtractsView = MovementDetail
    const GetPreviousBalance = (data: any): void => {
        setIsLoading(true)
        usePostPreviousBalance(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const pBalance = res.data.Data
                    console.log('pbalance', pBalance)
                    setPreviousBalance(pBalance)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }
    const GetFinalBalance = (data: any): void => {
        setIsLoading(true)
        usePostFinalBalance(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const fBalance = res.data.Data.Concepts
                    setTotalBalance(res.data.Data.TotalBalance)
                    setFinalBalance(fBalance)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }
    const GetMovementDetail = (data: any): void => {
        setIsLoading(true)
        usePostMovementDetail(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const movDetail = res.data.Data
                    setMovementDetail(movDetail)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }
    const GetMovementCxC = (data: any): void => {
        setIsLoading(true)
        usePostMovementCxC(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const movCxC = res.data.Data
                    setMovementCxC(movCxC)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    //balancesExtractsView = LoanDetail
    const GetLoanDetail = (data: any): void => {
        setIsLoading(true)
        usePostLoanDetail(tokenSave, data)
            .then((res: any) => {
                setIsLoading(false)
                if (res.data.Response === '200') {
                    const loanV = res.data.Data
                    setLoanValue(loanV)
                    setBalancesExtracts('LoanDetail')
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const formatter = (value: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value)
    }

    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }

    const changeStep = (value: string) => {
        setBalancesExtracts(value)
    }

    const showSavingDetail = (savingValue: any): void => {
        setProductValue(savingValue)
        setShowDetailBtn(false)
        setBalancesExtracts('BalanceByProduct')
    }

    const showCreditDetail = (creditValue: any): void => {
        setProductValue(creditValue)
        setShowDetailBtn(true)
        setBalancesExtracts('BalanceByProduct')
    }

    const showMovements = (
        previous: any,
        finalBalance: any,
        movDetail: any,
        movCxC: any,
        periodDates: string,
        prodNumber: number,
        finishNumber: number
    ): void => {
        GetPreviousBalance(previous)
        GetFinalBalance(finalBalance)
        GetMovementDetail(movDetail)
        GetMovementCxC(movCxC)

        setPeriod(periodDates)
        setProDocumentNumber(prodNumber)
        setFinishDocumentNumber(finishNumber)

        setBalancesExtracts('MovementDetail')
    }

    const showLoanDetail = (productValue: any): void => {
        GetLoanDetail(productValue)
    }

    const handlePrintPDF = async () => {
        setIsLoading(true)
        try {
            const response = await getSecurity<ApiResponse>(
                "/api/PdfGenerate/GenerateBalancesExtractPdf",
                tokenSave
            );

            if (response?.IsSuccess && typeof response.Data === "string") {
                const base64String = response.Data; // Ajustado para acceder a `Data`
                const binaryString = atob(base64String);
                const byteNumbers = new Uint8Array(binaryString.length);

                for (let i = 0; i < binaryString.length; i++) {
                    byteNumbers[i] = binaryString.charCodeAt(i);
                }

                const blob = new Blob([byteNumbers], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = "ExtractosResumidos.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.log("PDF generado correctamente.");
            } else {
                console.error("La respuesta no contiene el string en Base64 o no fue exitosa.");
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error al generar el PDF:", error);
            setIsLoading(false)
        }
    };



    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en línea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Saldos y extractos',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>

            <SpacerContent>
                {balancesExtractsView === 'Home' && (
                    <>
                        <BreadcrumbApp
                            breadcrumbs={breadcrumbs}
                            onBack={() => {
                                redirection('/home-wallet')
                            }}
                        />
                        <br></br>
                        <Row>
                            <PpalTitle>
                                <img src={BankIcon} alt="bankIcon" className="bank_icon" /> <span>Saldos y extractos</span>
                            </PpalTitle>
                        </Row>
                        {savingData.length === 0 &&
                            creditData.length === 0 &&
                            benefitData.length === 0 ? (
                            <NoProduct redirection={redirection} />
                        ) : (
                            <>
                                {savingData.length > 0 && (
                                    <SavingsTable
                                        savingData={savingData}
                                        formatter={formatter}
                                        showSavingDetail={showSavingDetail}
                                    />
                                )}
                                {creditData.length > 0 && (
                                    <CreditTable
                                        creditData={creditData}
                                        formatter={formatter}
                                        showCreditDetail={showCreditDetail}
                                    />
                                )}
                                {benefitData.length > 0 && (
                                    <BenefitsTable
                                        benefitData={benefitData}
                                        formatter={formatter}
                                    />
                                )}
                                <br></br>
                            </>
                        )}
                        <PDFMessageContainer>
                            <PDFButton onClick={handlePrintPDF} disabled={isLoading}>
                                <Row>
                                    <PDFText>
                                        {isLoading ? (
                                            <span>Cargando...</span>
                                        ) : (
                                            <>
                                                <img src={DownloadDoc} alt="downloadDoc" className="icon_size" /> Consulte aquí el estado de cuenta resumido de sus productos financieros
                                            </>
                                        )}
                                    </PDFText>
                                </Row>
                            </PDFButton>
                        </PDFMessageContainer>
                        <ContainerCaviImage>
                            <CaviImage src={CierreOperativo} alt="noServices" />
                        </ContainerCaviImage>
                    </>
                )}
                {balancesExtractsView === 'BalanceByProduct' && (
                    <>
                        <BreadcrumbApp
                            breadcrumbs={breadcrumbs}
                            onBack={() => {
                                changeStep('Home')
                            }}
                        />
                        <br></br>
                        <div>
                            <PpalTitle>
                                <span>Saldos y extractos</span>
                            </PpalTitle>
                        </div>
                        <BalanceExtractsByProduct
                            productData={productValue}
                            formatter={formatter}
                            showDetailBtn={showDetailBtn}
                            showLoanDetail={showLoanDetail}
                            showMovements={showMovements}
                            document={documentCC}
                        />
                    </>
                )}
                {balancesExtractsView === 'MovementDetail' && (
                    <>
                        <BreadcrumbApp
                            breadcrumbs={breadcrumbs}
                            onBack={() => {
                                changeStep('BalanceByProduct')
                            }}
                        />
                        <br></br>
                        <UserInfo
                            Nombre={name}
                            Nit={nit}
                            Registro={register}
                            FechaAfiliacion={membershipDate}
                            ProductDocumentNumber={proDocumentNumber}
                            Period={period}
                            CurrentDate={currentDate}
                            finishDocumentNumber={finishDocumentNumber}
                        />
                        <br></br>
                        <ButtonScreenshot
                            userData={[name, register, nit]}
                            ProductData={{
                                Cuenta: `${proDocumentNumber}-${finishDocumentNumber}`,
                                Periodo: period,
                                Fecha: currentDate,
                            }}
                            PreviuosBalanceData={previousBalance.toString()}
                            finalBalance={finalBalance}
                            movementsDetail={movementDetail}
                            movementsReceivable={movementCxC}
                            totalBalance={totalBalance}
                        />
                        <br></br>
                        <FinalBalance
                            finalBalanceData={finalBalance}
                            previousBalance={previousBalance}
                            totalBalance={totalBalance}
                            formatter={formatter}
                        />
                        {movementDetail.length > 0 && (
                            <MovementsDetail movementsData={movementDetail} formatter={formatter} />
                        )}
                        {movementCxC.length > 0 && (
                            <MovementsReceivable
                                movementsData={movementCxC}
                                formatter={formatter}
                            />
                        )}

                        <br></br>

                        <MessageContainer>
                            <TextP>
                                AHORRO EN CAVIPETROL Y CON MI APORTE CONSTRUCTIVO A NUESTRO
                                DESARROLLO
                            </TextP>
                            <TextP>
                                Cualquier inconsistencias o inquietud, favor comunicarlo a la
                                oficina de Cavipetrol más cercana o comunicarse al call center.
                            </TextP>
                        </MessageContainer>

                        <br></br>
                        <CancelButton
                            variant="primary"
                            onClick={() => changeStep('BalanceByProduct')}
                        >
                            Regresar
                        </CancelButton>
                    </>
                )}
                {balancesExtractsView === 'LoanDetail' && (
                    <>
                        <BreadcrumbApp
                            breadcrumbs={breadcrumbs}
                            onBack={() => {
                                changeStep('BalanceByProduct')
                            }}
                        />
                        <br></br>
                        <ButtonScreenshot
                            userData={[name, register, nit]}
                            ProductData={{
                                Cuenta: proDocumentNumber.toString(),
                                Periodo: period,
                                Fecha: membershipDate,
                            }}
                            productData={loanValue}
                            isProduct={true}
                        />
                        <ProductDetail
                            productData={loanValue}
                            formatter={formatter}
                            redirection={redirection}
                            handlePrintPDF={handlePrintPDF}
                            changeStep={changeStep}
                        />
                    </>
                )}
            </SpacerContent>
            <ModalLoading isLoading={isLoading} />
        </LayoutContent>
    )
}

export default BalancesExtracts
