import { ModalBody } from 'react-bootstrap'
import { Loading, Modal } from '../../../../components'

interface ModalLoadingProps {
    isLoading: boolean
    textLoading: string
    textInfo: string
}

const ModalLoading: React.FC<ModalLoadingProps> = ({
    isLoading,
    textLoading,
    textInfo,
}): JSX.Element => {
    return (
        <Modal
            className="loading-screen"
            show={isLoading}
            backdrop="static"
            keyboard={false}
            centered
        >
            <ModalBody>
                <Loading text={textLoading} />
                <p style={{ textAlign: 'center' }}>{textInfo}</p>
            </ModalBody>
        </Modal>
    )
}

export default ModalLoading
