import {
    ButtonContent,
    ButtonsContainer,
    ModalButton,
    ModalForm,
} from '../ModalForm/ModalForm-styles'
import { Modal, Form, Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { ContainerTitle, SpaceStep, Title } from '../../ManageableContent-styled'
import { Button } from '../../../../components'
import { Label } from '../../../UserOperations/UserOperations-styled'

interface ValidateNameImageProps {
    showModalValidateName?: boolean
    isLoading: boolean
    closed: () => void
    verifyNameStorage: (name: string, extension: string) => void
}

const ValidateNameImage: React.FC<ValidateNameImageProps> = ({
    showModalValidateName,
    isLoading,
    closed,
    verifyNameStorage,
}): JSX.Element => {
    const [imageName, setImageName] = useState<string>('')
    const [extension, setExtension] = useState<string>('')
    const methods = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = methods

    const onSubmit = (): void => {
        verifyNameStorage(imageName, extension)
    }

    const onCancel = (): void => {
        closed()
    }

    return (
        <>
            <ModalForm
                show={showModalValidateName}
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Title>
                    <ContainerTitle>
                        <Title>Actualización de Imágenes</Title>
                    </ContainerTitle>
                </Modal.Title>
                <Modal.Body>
                    <p>
                        Para continuar con el proceso de actualización, por favor confirma el nombre
                        y la extensión a subir de la imagen
                    </p>
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col md={6}>
                                    <Label>Nombre imagen</Label>
                                    <Controller
                                        name="imageName"
                                        rules={{
                                            required: 'Este campo es requerido',
                                            pattern: {
                                                value: /^[a-zA-Z0-9_-]+$/,
                                                message:
                                                    'El nombre de la imagen solo puede contener letras, números, guiones bajos y guiones medios',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <Form.Control
                                                type="text"
                                                {...field}
                                                onChange={(e) => {
                                                    setImageName(e.target.value)
                                                    field.onChange(e)
                                                }}
                                                onBlur={field.onBlur}
                                                isInvalid={!!errors.imageName}
                                                value={imageName}
                                            />
                                        )}
                                    />
                                    {errors.imageName && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.imageName.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                <Col md={6}>
                                    <Label>Extensión</Label>
                                    <Controller
                                        name={'extension'}
                                        rules={{
                                            required: 'Este campo es requerido',
                                            validate: (value) => {
                                                const validExtensions = [
                                                    'jpg',
                                                    'jpeg',
                                                    'png',
                                                    'gif',
                                                    'svg',
                                                ]
                                                const extension = value.toLowerCase()
                                                if (!extension) return 'Este campo es requerido'
                                                if (!validExtensions.includes(extension))
                                                    return 'La extensión no es válida'
                                                return true
                                            },
                                        }}
                                        render={({ field }) => (
                                            <Form.Control
                                                type="text"
                                                {...field}
                                                onChange={(e) => {
                                                    setExtension(e.target.value)
                                                    field.onChange(e)
                                                }}
                                                onBlur={field.onBlur}
                                                isInvalid={!!errors['extension']}
                                                value={extension}
                                            />
                                        )}
                                    />
                                    {errors['extension'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['extension']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>

                            <SpaceStep />
                            <ButtonsContainer>
                                <ModalButton variant="primary" onClick={onCancel}>
                                    Cancelar
                                </ModalButton>

                                <ButtonContent>
                                    <Button
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        isLoading={isLoading}
                                        extend
                                        disabled={extension === '' && imageName === ''}
                                    >
                                        Guardar
                                    </Button>
                                </ButtonContent>
                            </ButtonsContainer>
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </ModalForm>
        </>
    )
}

export default ValidateNameImage
