import { FC } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import {
    Wrapper,
    Content,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
    TextP,
} from '../../CreditSimulation-styles'
import '../../CreditSimulation.css'

interface LoanDataProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
    valor_prestamo: number
    tasaN: number
}

interface PaymentPlanProps {
    Cuota: number
    ValorCuota: number
    AbonoCapital: number
    Interes: number
    Saldo: number
}

interface StepProps {
    redirection: (value: string) => void
    formatter: (value: number) => string
    loanData: LoanDataProps
    paymentsPlan: PaymentPlanProps[]
    rowIndex: number
}

const PaymentPlan: FC<StepProps> = ({
    formatter,
    loanData,
    paymentsPlan,
    rowIndex,
}): JSX.Element => {
    return (
        <div id={`section-print${rowIndex}`}>
            <Wrapper className="printable">
                <Content className="printable">
                    <TableContent className="printable">
                        <div className="form-container">
                            <Col className="printable">
                                <Row className="printable">
                                    <Col md={6} xs={6} className="printable">
                                        <TextP className="printable">Tasa efectiva anual</TextP>
                                    </Col>
                                    <Col md={6} xs={6} className="printable">
                                        <TextP className="printable">Valor de préstamo</TextP>
                                    </Col>
                                    <Col md={6} xs={6} className="printable">
                                        <Form.Control
                                            className="printable"
                                            value={`${loanData.tasaEM}%`}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col md={6} xs={6} className="printable">
                                        <Form.Control
                                            className="printable form-width"
                                            value={formatter(loanData.valor_prestamo)}
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row className="printable">
                                    <Col md={6} className="printable">
                                        <TextP className="printable">Monto:</TextP>
                                    </Col>
                                    <Col md={6} className="printable">
                                        <Form.Group className=" printable mb-3">
                                            <Form.Control
                                                className="printable"
                                                value={formatter(loanData.monto)}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="printable">
                                    <Col md={6} className="printable">
                                        <TextP className="printable">Periodicidad:</TextP>
                                    </Col>
                                    <Col md={6} className="printable">
                                        {loanData.periocidad === 24 ? (
                                            <Form.Group className=" printable mb-3">
                                                <Form.Control
                                                    className="printable"
                                                    value={'Quincenal'}
                                                    readOnly={true}
                                                />
                                            </Form.Group>
                                        ) : (
                                            <Form.Group className=" printable mb-3">
                                                <Form.Control
                                                    className="printable"
                                                    value={'Mensual'}
                                                    readOnly={true}
                                                />
                                            </Form.Group>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="printable">
                                    <Col md={6} className="printable">
                                        <TextP className="printable">
                                            Número de periodos a definir:
                                        </TextP>
                                    </Col>
                                    <Col md={6} className="printable">
                                        <Form.Group className=" printable mb-3">
                                            <Form.Control
                                                className="printable"
                                                value={loanData.numero_coutas}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="printable">
                                    <Col md={6} className="printable">
                                        <TextP className="printable">
                                            Tasa de interés nominal:
                                        </TextP>
                                    </Col>
                                    <Col md={6} className="printable">
                                        <Form.Group className=" printable mb-3">
                                            <Form.Control
                                                className="printable"
                                                value={`${loanData.tasaN}%`}
                                                readOnly={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </div>

                        <TableContainer className="printable">
                            <THead className="printable">
                                <RowTableTitle className="printable">
                                    <th className="printable">Número cuota</th>
                                    <th className="printable">Valor cuota</th>
                                    <th className="printable">Abono a capital</th>
                                    <th className="printable">Interés</th>
                                    <th className="printable">Saldo</th>
                                </RowTableTitle>
                            </THead>
                            <TBody className="printable">
                                {paymentsPlan.map((plan) => (
                                    <RowTable className="printable">
                                        <td className="td-field printable">{plan.Cuota}</td>
                                        <td className="td-field printable">
                                            {formatter(plan.ValorCuota)}
                                        </td>
                                        <td className="td-field printable">
                                            {formatter(plan.AbonoCapital)}
                                        </td>
                                        <td className="td-field printable">
                                            {formatter(plan.Interes)}
                                        </td>
                                        <td className="td-field printable">
                                            {formatter(plan.Saldo)}
                                        </td>
                                    </RowTable>
                                ))}
                            </TBody>
                        </TableContainer>
                    </TableContent>
                </Content>
            </Wrapper>
        </div>
    )
}

export default PaymentPlan
