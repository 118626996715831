import { FC } from 'react'
import {
    ButtonsContainer,
    Content,
    ContentInfo,
    Image,
    ModalCancelButton,
    TextPBold,
    Wrapper,
} from '../../Codebtors-styles'
import { ForgotSVG } from '../../../../utils/getIcons'
// styles
interface NoCodebtorProps {
    redirection: (value: string) => void
}

const NoCodebtor: FC<NoCodebtorProps> = ({ redirection }): JSX.Element => {
    return (
        <Wrapper>
            <Content>
                <ContentInfo>
                    <Image src={ForgotSVG} alt="black" />
                    <TextPBold>
                        Actualmente <span>no eres codeudor</span> de ningún crédito en cavipetrol.
                    </TextPBold>
                </ContentInfo>
                <ButtonsContainer>
                    <ModalCancelButton
                        variant="primary"
                        onClick={() => redirection('/home-wallet')}
                    >
                        Regresar al home
                    </ModalCancelButton>
                </ButtonsContainer>
            </Content>
        </Wrapper>
    )
}

export default NoCodebtor
