// styles
import { ContainerTitle, Title } from './titleModule-styles'

const TitleModule = (): JSX.Element => {
    return (
        <ContainerTitle>
            <Title>Usuarios</Title>
        </ContainerTitle>
    )
}

export default TitleModule
