import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

// components
import { Button } from '../../../../components'

// styles
import { Modal, ModalImage, CtrButtons, TitleModal, TextModal } from './errorCRMModal-styles'

// icons
import { CreditCardFlowSVG } from '../../../../utils/getIcons'

interface ErrorCRMModalProps {
    show: boolean
    onClose: () => void
}

const ErrorCRMModal: FC<ErrorCRMModalProps> = ({ show, onClose }): ReactElement => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ModalImage src={CreditCardFlowSVG} alt="credit card" />
                <TitleModal>{'Parece que los datos no coinciden'}</TitleModal>
                <TextModal>
                    {
                        'Los datos ingresados no concuerdan con los registrados en nuestro sistema, por favor comunícate con '
                    }
                    <Link to="/support-private">{'servicio al asociado'}</Link>
                </TextModal>

                <CtrButtons>
                    <Button variant="sub-dominant" type="submit" extend onClick={onClose}>
                        Modifica los datos
                    </Button>
                </CtrButtons>
            </Modal.Body>
        </Modal>
    )
}

export default ErrorCRMModal
