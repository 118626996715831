import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp, Loading, Modal } from '../../components'
import {
    ContainerSimulator,
    ContainerTitle,
    Title,
    ContainerSimulator1,
    SpaceStep,
} from './CertificateFAI-Styled'

import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer'
import { DownloadSVG, PrintSVG } from '../../utils/getIcons'
import { useMediaQuery } from 'usehooks-ts'
import { useEffect, useState } from 'react'

import moment from 'moment'

import { useSelector } from 'react-redux'
import {
    useGetFaiAcountOpenClient,
    useGetInfoAssociate,
    useGetInfoFai,
} from './hooks/useCertificateFAI'
import { ModalBody } from 'react-bootstrap'
import ModalInfo from '../CreditDetail/inc/ModalInfo/ModalInfo'
import 'moment/locale/es'
import PDFGenerator from './inc/PDFGenerator'
import PDFViewerMobile from './inc/PDFViewerMobile'
import { handleGenerateLogs } from '../CertificationForAgreements/hooks/useLogsServices'

const CertificateFAI = (): JSX.Element => {
    moment.locale('es')
    const date = moment().format('YYYY-MM-DD')
    const day = moment(date).format('DD')
    const nameMonth = moment(date).format('MMMM')
    const year = moment(date).format('YYYY')
    const fileName = `Certificado FAI_${date}.pdf`
    const history = useHistory()
    const matchMedia = useMediaQuery('(min-width: 700px)')

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showPDF, setShowPDF] = useState(false) // Agregar estado para controlar la visualización del PDF
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [textTitleModal, setTextTitleModal] = useState<string>('')
    const [textBodyModal, setTextBodyModal] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)

    const [footer, setFooter] = useState<string>('')
    const [productName, setProductName] = useState<string>('')
    const [openingDate, setOpeningDate] = useState<string>('')
    const [productNumber, setProductNumber] = useState<string>('')
    const [documentNumber, setDocumentNumber] = useState<string>('')
    const [documentType, setDocumentType] = useState<string>('')
    const [associatedName, setAssociatedName] = useState<string>('')
    const [registrationNumber, setRegistrationNumber] = useState<string>('')
    const [availableBalance, setAvailableBalance] = useState<string>('')

    const nameCertificate = 'FAI'

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Servicios en Línea',
            active: false,
        },
        {
            text: 'Servicios',
            active: false,
        },
        {
            text: 'Certificado FAI',
            active: true,
        },
    ]

    // Calcular el tamaño de la página basado en el contenido
    const contentWidth = 750
    const contentHeight = 800
    const pageWidth = contentWidth - 50
    const pageHeight = contentHeight - 50

    const handlePrintPDF = async () => {
        const pdfBlob = await pdf(
            <PDFGenerator
                Footer={footer}
                ProductName={productName}
                OpeningDate={openingDate}
                ProductNumber={productNumber}
                DocumentNumber={documentNumber}
                DocumentType={documentType}
                AssociatedName={associatedName}
                RegistrationNumber={registrationNumber}
                AvailableBalance={availableBalance}
            />
        ).toBlob()
        const url = URL.createObjectURL(pdfBlob)

        const printWindow = window.open(url)

        // Esperar a que la ventana de impresión esté cargada y luego imprimir
        printWindow?.addEventListener('load', () => {
            printWindow.document.title = 'Certificado FAI'
            printWindow?.print()
        })
    }

    const GetInfoAssociate = (): void => {
        setIsLoading(true)
        useGetInfoAssociate(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setDocumentNumber(res.data.Data[0].Document)
                    setAssociatedName(res.data.Data[0].Name)
                    setRegistrationNumber(res.data.Data[0].Register)
                    GetInfoFai()
                } else if (res['data']['Response'] === '204') {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Lo sentimos!')
                    setTextBodyModal('La consulta no generó ningún resultado')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                } else {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('No se pudo generar el pdf, por favor intenta de nuevo')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
                handleGenerateLogs(nameCertificate, false, tokenSave)
            })
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    useEffect(() => {
        GetInfoAssociate()
    }, [tokenSave])

    const GetInfoFai = (): void => {
        useGetInfoFai(tokenSave)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    setDocumentType(res.data.Data[0].DocumentType)
                    setProductNumber(res.data.Data[0].DocumentProduct)
                    /* setOpeningDate(res.data.Data[0].Register']) */
                    setProductName(res.data.Data[0].Equivalence)
                    setFooter(
                        `\nLa presente certificación se expide a solicitud del interesado a los ${day} días del mes de ${nameMonth} de ${year}`
                    )
                    GetFaiAcountOpenClient(res.data.Data[0].DocumentProduct)
                    const value = parseFloat(res.data.Data[0].AvailableBalance)

                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                    })

                    setAvailableBalance(formatter.format(value))
                } else if (res['data']['Response'] === '204') {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Lo sentimos!')
                    setTextBodyModal('La consulta no generó ningún resultado')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                } else {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('No se pudo generar el pdf, por favor intenta de nuevo')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
                handleGenerateLogs(nameCertificate, false, tokenSave)
            })
    }

    const GetFaiAcountOpenClient = (documentProduct: string): void => {
        useGetFaiAcountOpenClient(tokenSave, documentProduct)
            .then((res: any) => {
                if (res['data']['Response'] === '200') {
                    const dateOpening = moment(res.data.Data[0].From, 'YYYY-MM-DDTHH:mm:ss')
                    const dateOpeningFormatt = dateOpening.format('DD MMMM YYYY').toUpperCase()
                    setOpeningDate(dateOpeningFormatt)
                    setIsLoading(false)
                    setShowPDF(true)
                    handleGenerateLogs(nameCertificate, true, tokenSave)
                } else if (res['data']['Response'] === '204') {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Lo sentimos!')
                    setTextBodyModal('La consulta no generó ningún resultado')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                } else {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('No se pudo generar el pdf, por favor intenta de nuevo')
                    handleGenerateLogs(nameCertificate, false, tokenSave)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
                handleGenerateLogs(nameCertificate, false, tokenSave)
            })
    }

    return (
        <LayoutContent>
            <ContainerSimulator>
                <SpaceStep />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        history.push('/home-wallet')
                    }}
                />
                <ContainerTitle>
                    <Title>
                        Certificación <span>Saldos Cuenta FAI</span>
                    </Title>
                </ContainerTitle>
                {showPDF && (
                    <>
                        {!matchMedia ? (
                            <ContainerSimulator1>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: '5px',
                                        height: '40px',
                                        background: '#F2F2F2',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <PDFDownloadLink
                                        document={
                                            <PDFGenerator
                                                Footer={footer}
                                                ProductName={productName}
                                                OpeningDate={openingDate}
                                                ProductNumber={productNumber}
                                                DocumentNumber={documentNumber}
                                                DocumentType={documentType}
                                                AssociatedName={associatedName}
                                                RegistrationNumber={registrationNumber}
                                                AvailableBalance={availableBalance}
                                            />
                                        }
                                        fileName={fileName}
                                    >
                                        <img
                                            src={DownloadSVG}
                                            className="options-img"
                                            style={{
                                                width: '26px',
                                                height: '20px',
                                            }}
                                            alt="download"
                                        />
                                        <button
                                            style={{
                                                marginRight: '10px',
                                                fontFamily: 'Montserrat',
                                                background: 'transparent',
                                                border: 'none',
                                                color: '#512F8B',
                                                margin: '8px',
                                            }}
                                        >
                                            Descargar
                                        </button>
                                    </PDFDownloadLink>
                                </div>
                                <PDFViewerMobile
                                    Footer={footer}
                                    productName={productName}
                                    openingDate={openingDate}
                                    productNumber={productNumber}
                                    documentNumber={documentNumber}
                                    documentType={documentType}
                                    associatedName={associatedName}
                                    registrationNumber={registrationNumber}
                                    availableBalance={availableBalance}
                                />
                            </ContainerSimulator1>
                        ) : (
                            <ContainerSimulator1>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: '5px',
                                        height: '40px',
                                        background: '#F2F2F2',
                                        borderRadius: '5px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PDFDownloadLink
                                        document={
                                            <PDFGenerator
                                                Footer={footer}
                                                ProductName={productName}
                                                OpeningDate={openingDate}
                                                ProductNumber={productNumber}
                                                DocumentNumber={documentNumber}
                                                DocumentType={documentType}
                                                AssociatedName={associatedName}
                                                RegistrationNumber={registrationNumber}
                                                AvailableBalance={availableBalance}
                                            />
                                        }
                                        fileName={fileName}
                                    >
                                        <img
                                            src={DownloadSVG}
                                            className="options-img"
                                            style={{
                                                width: '26px',
                                                height: '20px',
                                            }}
                                            alt="download"
                                        />
                                        <button
                                            style={{
                                                marginRight: '10px',
                                                fontFamily: 'Montserrat',
                                                background: 'transparent',
                                                border: 'none',
                                                color: '#512F8B',
                                                margin: '8px',
                                            }}
                                        >
                                            Descargar
                                        </button>
                                    </PDFDownloadLink>

                                    <img
                                        src={PrintSVG}
                                        className="options-img"
                                        style={{ width: '26px', height: '21px' }}
                                        alt="print"
                                    />
                                    <button
                                        onClick={handlePrintPDF}
                                        style={{
                                            fontFamily: 'Montserrat-Regular',
                                            background: 'transparent',
                                            border: 'none',
                                            color: '#512F8B',
                                            margin: '9px',
                                        }}
                                    >
                                        Imprimir
                                    </button>
                                </div>

                                <PDFViewer
                                    width={pageWidth - 29}
                                    height={pageHeight - 29}
                                    showToolbar={false}
                                    style={{ marginLeft: '20%' }}
                                >
                                    <PDFGenerator
                                        Footer={footer}
                                        ProductName={productName}
                                        OpeningDate={openingDate}
                                        DocumentNumber={documentNumber}
                                        DocumentType={documentType}
                                        AssociatedName={associatedName}
                                        RegistrationNumber={registrationNumber}
                                        AvailableBalance={availableBalance}
                                        ProductNumber={productNumber}
                                    />
                                </PDFViewer>
                            </ContainerSimulator1>
                        )}
                    </>
                )}
            </ContainerSimulator>
            <Modal
                className="loading-screen"
                show={isLoading}
                backdrop="static"
                keyboard={false}
                centered
            >
                <ModalBody>
                    <Loading text="Estamos creando tu documento" />
                    <p style={{ textAlign: 'center' }}>
                        Espera, no actualices o cierres esta página
                    </p>
                </ModalBody>
            </Modal>
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleModalCloseInfo}
            />
        </LayoutContent>
    )
}

export default CertificateFAI
