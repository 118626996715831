/* eslint-disable prettier/prettier */
import { useHistory, useLocation } from 'react-router-dom'
import { BreadcrumbApp } from '../../../../components'
import { LayoutContent } from '../../../../transverse'
import { Container, ContainerTitle, Title } from '../../AlertCreditCardRequests-Styled'
import {
    Content,
    SubTitleContent,
    TitleContent,
    Wrapper,
    Text,
    SubText,
    SectionText,
    ProcessLine,
    ContentTitle,
} from './DetailCreditCardRequests-Styled'
import { CreditCardSVG } from '../../../../utils/getIcons'
import { useEffect } from 'react'

interface RequestData {
    Id: number
    RequestSourceType: string
    RequestDate: string
    Type: string
    State: string
    Details: {
        RequestCreated: boolean
        RequestDate: string
        PendingDelivery: boolean
        PendingActivation: boolean
        LastFourDigits: string
    }
}

const DetailCreditCardRequests = (): JSX.Element => {
    const location = useLocation()
    const requestData = location.state ? (location.state.requestData as RequestData) : null

    const history = useHistory()
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Solicitud productos',
            active: false,
        },
        {
            text: `Detalle de solicitud ${requestData?.Id}`,
            active: true,
        },
    ]

    const isRequestCreated = requestData?.Details.RequestCreated
    const isPendingDelivery = requestData?.Details.PendingDelivery

    const isPendingActive = requestData?.Details.PendingActivation

    const lastFourDigits = requestData?.Details.LastFourDigits

    const isStateDetail = requestData?.State


    useEffect(() => {
        if (!requestData) {
            history.push('/home-wallet')
        }
    }, [history, requestData])

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        history.push('/home-wallet')
                    }}
                ></BreadcrumbApp>
                <ContainerTitle>
                    <Title>Tus solicitudes</Title>
                </ContainerTitle>
                <Wrapper>
                    <Content style={{ display: 'flex' }} className='title-content'>
                        <img
                            src={CreditCardSVG}
                            alt="CreditCardSVG"
                            style={{ width: '65px' }}
                        />
                        <ContentTitle>
                            <TitleContent>Solicitud tarjeta de crédito</TitleContent>
                            <SubTitleContent>ID: {requestData?.Id}</SubTitleContent>
                        </ContentTitle>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <SectionText>
                        <Text>Detalle de tu solicitud</Text>
                        <SubText>
                            Conoce el cambio de estado y detalles del proceso de tu solicitud.
                        </SubText>
                    </SectionText>
                </Wrapper>
                <Wrapper>
                    <ProcessLine>
                        {(isStateDetail?.includes('Rechazado') || isStateDetail?.includes('Rechazada')) ? (
                            <div className="process-line">
                                <div className="state">
                                    <div className="state">
                                        <h3>Tu solicitud ha sido rechazada</h3>
                                        <div className="description">
                                            Cavipetrol te informa que no podemos continuar con tu
                                            solicitud de tarjeta de crédito.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    className={`process-line ${isPendingActive ? '' : 'progress-idle'
                                        }`}
                                >
                                    <div
                                        className={`state ${!isPendingActive ? 'idle-state' : ''}`}
                                    >
                                        <div
                                            className={`state ${!isPendingActive ? 'idle-state' : ''
                                                }`}
                                        >
                                            <h3>Tu tarjeta está pendiente de activación</h3>
                                            <div
                                                className={`description ${isPendingActive ? '' : 'idle'
                                                    }`}
                                            >
                                                Tu tarjeta de crédito ***{lastFourDigits} se encuentra en estado
                                                "Entregada". Por favor realiza el proceso de
                                                activación para acceder a todos los beneficios.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`process-line ${isPendingDelivery ? '' : 'progress-idle'
                                        }`}
                                >
                                    <div
                                        className={`state ${!isPendingDelivery ? 'idle-state' : ''
                                            }`}
                                    >
                                        <h3 className={isPendingDelivery ? '' : 'idle'}>
                                            Tu tarjeta está pendiente de entrega
                                        </h3>
                                        <div
                                            className={`description ${isPendingDelivery ? '' : 'idle'
                                                }`}
                                        >
                                            Tu tarjeta de crédito se encuentra distribución pronto
                                            la recibirás en la dirección registrada.
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {(!isPendingActive || !isPendingDelivery) && (
                            <div
                                className={`process-line ${isRequestCreated ? '' : 'progress-idle'
                                    }`}
                            >
                                <div className={`state ${!isRequestCreated ? 'idle-state' : ''}`}>
                                    <h3 className={isRequestCreated ? '' : 'idle'}>
                                        Tu solicitud está en revisión
                                    </h3>
                                    <div
                                        className={`description ${isRequestCreated ? '' : 'idle'}`}
                                    >
                                        Uno de nuestros asesores de crédito está evaluando tu
                                        solicitud, pronto recibirás una respuesta.
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={`process-line ${isRequestCreated ? '' : 'progress-idle'}`}>
                            <div className={`state ${!isRequestCreated ? 'idle-state' : ''}`}>
                                <h3 className={isRequestCreated ? '' : 'idle'}>
                                    Tu solicitud ha sido creada
                                </h3>
                                <div className={`description ${isRequestCreated ? '' : 'idle'}`}>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {requestData?.Details.RequestDate}
                                    </span>{' '}
                                    - Hemos recibido tu solicitud para una tarjeta de crédito
                                    Cavipetrol, nuestros asesores revisarán la información y pronto
                                    te contactarán.
                                </div>
                            </div>
                        </div>
                    </ProcessLine>
                </Wrapper>
            </Container>
        </LayoutContent>
    )
}

export default DetailCreditCardRequests
