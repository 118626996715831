import { FC, useEffect, useState } from 'react'
// styles
import {
    ButtonsContainer,
    ModalButton,
    BLockCardTheftTitle,
    ModalButtonBack,
    LockTitle,
    CodeText,
    BlockCardWrapper,
    BlockCardContent,
    BlockTitleContent,
    LockTextBold,
    ArrowBlack,
} from './BlockCardTheft-Styled'
import CountdownTimer from './CountdownTimer'
import OtpInput from './OtpInput'
import { LayoutContent } from '../../../../transverse'
import { useSendOTP } from '../../hooks/useCreditDetail'
import ModalInfo from '../ModalInfo/ModalInfo'
import { useHistory } from 'react-router-dom'
import { ArrowCircleSVG } from '../../../../utils/getIcons'

interface LockCodeProps {
    handleFormSubmit: () => void
    tokenSave: string
    cellphone: string
    email: string
    lastsFourNumCard: string
    lastsFourEncrypted?: string
}

const LockCode: FC<LockCodeProps> = ({
    handleFormSubmit,
    tokenSave,
    cellphone,
    email,
    lastsFourNumCard,
    lastsFourEncrypted,
}): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [urlToBack, setUrlToBack] = useState('')

    const handleClose = () => {
        setShowModal(false)
    }

    const handleLoading = (value: boolean) => {
        setIsLoading(value)
    }

    const SendOtp = () => {
        useSendOTP(tokenSave).then((res: any) => {
            if (res['status'] === 200) {
                console.log('otp enviado')
            } else if (res['Response'] === '401') {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'Tu solicitud fue negada por superar el número máximo de intentos por favor intente nuevamente en una hora'
                )
            } else {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                )
            }
        })
    }

    useEffect(() => {
        setUrlToBack(`/credit-card-detail?id=${lastsFourEncrypted}`)
        SendOtp()
    }, [tokenSave])

    const history = useHistory()
    const redirections = (url: string): void => {
        history.push(url)
    }

    return (
        <LayoutContent>
            <BlockTitleContent>
                <ArrowBlack onClick={() => redirections(urlToBack)}>
                    <img src={ArrowCircleSVG} alt="black" />
                    <p>Volver</p>
                </ArrowBlack>
                <BLockCardTheftTitle>Bloquea tu tarjeta por pérdida o hurto</BLockCardTheftTitle>
            </BlockTitleContent>
            <BlockCardWrapper>
                <BlockCardContent>
                    <LockTitle>Bloqueo por pérdida o hurto</LockTitle>
                    <LockTextBold>
                        Ingresa el código de bloqueo SMS que recibiste al celular terminado en{' '}
                        {cellphone} y a tu correo electrónico via {email}
                    </LockTextBold>
                    <LockTitle>Código de bloqueo</LockTitle>
                    <OtpInput tokenSave={tokenSave} handleLoading={handleLoading} />
                    <CountdownTimer tokenSave={tokenSave} />
                    <LockTextBold>
                        Si no puedes acceder a tu correo electrónico o celular,{' '}
                        <CodeText>
                            comunícate a cualquiera de los teléfonos del banco Coopcentral
                        </CodeText>
                    </LockTextBold>
                    <ButtonsContainer>
                        <ModalButtonBack
                            variant="primary"
                            /*  onClick={() =>
                                history.push(`/credit-card-detail?id=${lastsFourEncrypted}`)
                            } */
                            onClick={() =>
                                redirections(`/credit-card-detail?id=${lastsFourEncrypted}`)
                            }
                        >
                            Cancelar
                        </ModalButtonBack>
                        <ModalButton
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={handleFormSubmit}
                            isLoading={isLoading}
                            disabled={isLoading}
                        >
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </BlockCardContent>
                <ModalInfo
                    showModal={showModal}
                    textTitle={textTitleModal}
                    textBody={textBodyModal}
                    handleClose={handleClose}
                />
            </BlockCardWrapper>
        </LayoutContent>
    )
}

export default LockCode
