import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

// components
import { Form } from '../../../../../../components'
import HeadStep from '../HeadStep'

// styles
import { FirstStepContent } from './firstStep-styles'
import { CreditCardSelector } from '../../../../../../../selectors'
import useQueryId from '../../../../../../hooks/useQueryId'
import { StoreApp } from '../../../../../../../redux/store/store.interface'
import { ButtonsContainer, ModalButton, ModalCancelButton } from '../SecondStep/secondStep-styles'

interface StepProps {
    handleNextStep: () => void
    lastRates: number
}

const FirstStep: FC<StepProps> = ({ handleNextStep, lastRates }): JSX.Element => {
    const methods = useForm()
    const { handleSubmit } = methods
    const history = useHistory()
    const { id } = useQueryId()
    const { cardData: infoCard } = useSelector((store: StoreApp) =>
        CreditCardSelector(store, String(id))
    )

    const onCancel = (): void => {
        history.push('/home-wallet')
    }

    return (
        <>
            <FirstStepContent>
                <Form>
                    {infoCard && (
                        <HeadStep
                            title={`Paso 1 de 3`}
                            subtitle="Alivia el pago de tu tarjeta de crédito"
                            paragraph={
                                'Reduce el pago minimo mensual de la deuda que tienes en tu tarjeta de crédito *' +
                                infoCard.lastFourDigits +
                                ' con un nuevo plazo de hasta ' +
                                lastRates +
                                ' meses'
                            }
                        />
                    )}
                    <ButtonsContainer>
                        <ModalCancelButton variant="outline-cancel" extend onClick={onCancel}>
                            Cancelar
                        </ModalCancelButton>
                        <ModalButton
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={handleSubmit(handleNextStep)}
                        >
                            Siguiente
                        </ModalButton>
                    </ButtonsContainer>
                </Form>
            </FirstStepContent>
        </>
    )
}

export default FirstStep
