import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp, Loading, ModalGeneric } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { faiAccountExistSelector } from '../../../selectors'
import { useEffect, useState } from 'react'
import { getFaiAccountBalanceAction } from '../../../redux/products'
import { LoadingContainer } from '../CreditDetail/creditDetail-styles'
import { NotProductSVG } from '../../utils/getIcons'
import { Container, ContainerTitle, Title } from './PaymentScheduleCancellationTC-Styled'
import FirstStep from '../PaymentScheduleCancellationTC/inc/FirstStep'
import { useGetScheduledPaymentFAI } from './hooks/usePaymentScheduleCancellationTC'

const PaymentScheduleRegistrationTC = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { faiAccount, loading, error } = useSelector(faiAccountExistSelector)
    const [isGoBack, setIsGoBack] = useState<boolean>(false)
    const [isTcSelect, setIsTcSelect] = useState<boolean>(false)
    const [activeCardsSchedule, setActiveCardsSchedule] = useState<string[]>([])
    const [loadingScheduledPaymentTC, setLoadingScheduledPaymentTC] = useState<boolean>(false)
    const [isScheduledPaymentTC, setIsScheduledPaymentTC] = useState<boolean>(false)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        dispatch(getFaiAccountBalanceAction())
    }, [dispatch])

    useEffect(() => {
        if (!loading) {
            GetScheduledPaymentFAIData()
            setLoadingScheduledPaymentTC(true)
        }
    }, [loading])

    const GetScheduledPaymentFAIData = (): void => {
        const process = true
        useGetScheduledPaymentFAI(faiAccount.productDocumentNumber, process, tokenSave)
            .then((res: any) => {
                if (res.data.Response === '200') {
                    const data = res.data.Data
                    if (Array.isArray(data) && data.length > 0) {
                        setActiveCardsSchedule(data)
                        setLoadingScheduledPaymentTC(false)
                        setIsScheduledPaymentTC(true)
                    } else {
                        setLoadingScheduledPaymentTC(false)
                        setIsScheduledPaymentTC(false)
                    }
                } else {
                    setLoadingScheduledPaymentTC(false)
                    setIsScheduledPaymentTC(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setLoadingScheduledPaymentTC(false)
            })
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                history.push('/home-wallet')
            },
        },
        {
            text: 'Solicitud de productos',
            active: true,
        },
    ]

    const handleClose = (): void => {
        history.push('/home-wallet')
    }

    const handleGoBack = (value: boolean): void => {
        setIsTcSelect(value)
        if (value) {
            setIsGoBack(false)
        }
    }

    return (
        <LayoutContent>
            <Container>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        if (isTcSelect) {
                            setIsGoBack(true)
                        } else {
                            history.push('/home-wallet')
                        }
                    }}
                />
                <ContainerTitle>
                    <Title>
                        Cancela el <span>pago automático</span> de tu tarjeta de crédito
                    </Title>
                </ContainerTitle>
                {loading ? (
                    <LoadingContainer>
                        <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                    </LoadingContainer>
                ) : (
                    <>
                        {loadingScheduledPaymentTC ? (
                            <LoadingContainer>
                                <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                            </LoadingContainer>
                        ) : (
                            <>
                                {isScheduledPaymentTC ? (
                                    <FirstStep
                                        isGoBack={isGoBack}
                                        activeCardsSchedule={activeCardsSchedule}
                                        handleGoBack={handleGoBack}
                                        isScheduledPaymentTC={isScheduledPaymentTC}
                                    />
                                ) : (
                                    <ModalGeneric
                                        show={!isScheduledPaymentTC}
                                        img={NotProductSVG}
                                        textTitle={'Lo sentimos'}
                                        textBody={
                                            'No puedes continuar con el proceso en este momento, ya que no tienes tarjetas inscritas para el pago programado.'
                                        }
                                        handleButton={handleClose}
                                        handleClose={handleClose}
                                        textButton="Aceptar"
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {Object.keys(error).length > 0 && (
                    <ModalGeneric
                        show={true}
                        img={NotProductSVG}
                        textTitle={'¡Ha ocurrido un error!'}
                        textBody={
                            'Se ha presentado un inconveniente al consultar sus productos, por favor inténtelo más tarde.'
                        }
                        handleButton={handleClose}
                        handleClose={handleClose}
                        textButton="Aceptar"
                    />
                )}
            </Container>
        </LayoutContent>
    )
}

export default PaymentScheduleRegistrationTC
