import { Col, Row } from 'react-bootstrap'
import { ContainerTitle, Title, Wrapper } from '../../FaiAccountTransfer-Styled'
import {
    Content,
    TextInfo,
    TextSimple,
    TextSimpleInfo,
    TextTitle,
    TextSimpleDate,
    UserDate,
    ContainerButtons,
} from './SuccesfulTransaction-Styled'
import { Button } from '../../../../components'
import { useHistory } from 'react-router-dom'

interface SuccesfulTransactionProps {
    associatedName: string
    amountTransfer: string
    destinationId: string
    destinationName: string
    faiDestinationAccount: string
    dateTransaction: string
    messageResponse: string
    voucherNumber: string
}
const SuccesfulTransaction: React.FC<SuccesfulTransactionProps> = ({
    associatedName,
    amountTransfer,
    destinationId,
    destinationName,
    faiDestinationAccount,
    dateTransaction,
    messageResponse,
    voucherNumber,
}) => {
    const history = useHistory()
    const value = parseFloat(amountTransfer)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })

    return (
        <>
            <ContainerTitle>
                <Title>
                    <span>Respuesta</span>
                </Title>
            </ContainerTitle>
            <Wrapper>
                <Content>
                    <UserDate>
                        {' '}
                        Sr(a): <span>{associatedName}</span>
                    </UserDate>
                    <TextInfo>
                        A continuación, te presentamos la información de tu transferencia.
                    </TextInfo>
                </Content>
            </Wrapper>
            <Wrapper>
                <Content className="second-content">
                    {messageResponse.includes('insuficiente') || messageResponse.includes('Sin') ? (
                        <TextTitle className="text-danger">{messageResponse}</TextTitle>
                    ) : (
                        <TextTitle>{messageResponse}</TextTitle>
                    )}

                    <TextSimple>Comprobante: {voucherNumber}</TextSimple>

                    <Row>
                        <Col md={3}>
                            <TextSimpleInfo>{destinationName}</TextSimpleInfo>
                        </Col>

                        <Col md={3}>
                            <TextSimpleInfo>{destinationId}</TextSimpleInfo>
                        </Col>
                        <Col md={3}>
                            <TextSimpleInfo>{faiDestinationAccount}</TextSimpleInfo>
                        </Col>
                        <Col md={3}>
                            <TextSimpleInfo>{dateTransaction}</TextSimpleInfo>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <TextSimpleDate>Nombre del destinatario</TextSimpleDate>
                        </Col>

                        <Col md={3}>
                            <TextSimpleDate>Identificación del destinatario</TextSimpleDate>
                        </Col>
                        <Col md={3}>
                            <TextSimpleDate>Cuenta de destinatario</TextSimpleDate>
                        </Col>
                        <Col md={3}>
                            <TextSimpleDate>Fecha y Hora</TextSimpleDate>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextSimpleInfo>Valor de la transacción</TextSimpleInfo>
                            <TextSimpleDate>{formatter.format(value)}</TextSimpleDate>
                        </Col>
                    </Row>
                    <ContainerButtons>
                        <Button
                            variant="sub-dominant"
                            type="submit"
                            extend
                            onClick={() => history.push('/home-wallet')}
                        >
                            Terminar
                        </Button>
                    </ContainerButtons>
                </Content>
            </Wrapper>
        </>
    )
}

export default SuccesfulTransaction
