import tw, { styled } from 'twin.macro'

export const ContainerSimulator = styled.section`
    ${tw`mt-15 py-[15px] px-0`}
    ${tw`lg:pt-0 lg:pb-5 lg:px-3 lg:min-h-[600px]`}
`
export const SpaceStep = styled.div`
    ${tw`w-full h-1.5`}
`
export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: space-between !important;
`
export const ContainerSimulator1 = styled.section`
    ${tw`w-[1100px] h-full rounded-[10px] shadow-[0px 10px 6px #00000029] `}
    @media (min-width: 340px) and (max-width: 500px) {
        ${tw`w-[100%]`}
    }
`
