import { useState, useRef } from 'react'
import type { MutableRefObject } from 'react'
import Keyboard, { KeyboardOptions } from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

// custom hooks
import { useShuffleItems } from '../../hooks'
import { reduceByCountNDelimiter } from '../../utils/misc'

export interface DigitKeyboardProps extends KeyboardOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyboardRef?: MutableRefObject<any | null>
    shuffle?: boolean
    locked?: boolean
    backspaceText?: string
    onKeyPress?: (button: string) => void
    onChange?: (input: string) => void
    value?: string
}

const DigitKeyboard = ({
    keyboardRef,
    shuffle,
    locked,
    backspaceText = 'BORRAR',
    onKeyPress,
    onChange,
    theme,
    value = '',
    ...props
}: DigitKeyboardProps): JSX.Element => {
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const lockLayout = ['* * *', '* * *', '* * *', ' * ', '{bksp}']
    const shuffledOrNot = useRef(digits)

    const [isLocked, setIsLocked] = useState(!!locked)
    const [shuffled, setShuffled] = useShuffleItems(digits)
    const [inputValue, setInputValue] = useState(value)

    if (shuffle) {
        shuffledOrNot.current = shuffled
    }

    const defaultLayout = reduceByCountNDelimiter(shuffledOrNot.current, 3, ' ')
    ;[defaultLayout[defaultLayout.length - 1]] = [` ${defaultLayout.at(-1)} `]
    defaultLayout.push('{bksp}')

    const isProcessing = useRef(false)

    const handleKeyPress = (button: string): void => {
        if (isProcessing.current) return

        console.log('Click en:', button)
        isProcessing.current = true

        let newInputValue = inputValue
        if (button === '{bksp}') {
            newInputValue = newInputValue.slice(0, -1)
        } else if (button.match(/^\d$/)) {
            newInputValue += button
        }

        setInputValue(newInputValue)

        if (typeof onChange === 'function') {
            onChange(newInputValue)
        }

        if (onChange) {
            onChange(newInputValue)
        }

        if (shuffle) {
            setShuffled()
            setIsLocked(true)

            setTimeout(() => {
                setIsLocked(false)
            }, 600)
        }

        setTimeout(() => {
            isProcessing.current = false
        }, 100)
    }

    return (
        <Keyboard
            {...props}
            keyboardRef={(r) => {
                if (keyboardRef) {
                    keyboardRef.current = r
                }
            }}
            stopMouseDownPropagation={isLocked}
            stopMouseUpPropagation={isLocked}
            stopKeyPressPropagation={isLocked}
            disableCaretPositioning
            disableButtonHold
            layoutName={isLocked ? 'lock' : 'default'}
            theme={`hg-theme-default hg-layout-numeric numeric-theme${theme ? ` ${theme}` : ''}`}
            display={{ '{bksp}': backspaceText }}
            layout={{
                default: defaultLayout,
                lock: lockLayout,
            }}
            onKeyPress={handleKeyPress}
            inputPattern={/^[^*]+$/}
            inputValue={inputValue}
        />
    )
}

export default DigitKeyboard
