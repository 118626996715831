import React, { useState } from 'react'
import {
    TermsAndCondContainer,
    Header,
    Footer,
    CheckTermsCond,
    ContainerCheck,
    TermText,
    PaginationContent,
    ButtonPagination,
    AcceptContent,
} from './DeclarationsAuthorizations-Styles'
import { Pagination, PageItem } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import ModalConfirmation from '../ModalConfirmation'
import ModalError from '../ModalError'
import { postUpdateUserCrm } from '../../hooks/useDataUpdate'
import { Button } from '../../../../components'
import { UserData } from '../../../../../../domain/models/crm'

interface DeclarationsAuthorizationsProps {
    handleNextStep: (value: string) => void
    userData: UserData
}

const DeclarationsAuthorizations: React.FC<DeclarationsAuthorizationsProps> = ({
    handleNextStep,
    userData,
}) => {
    const history = useHistory()
    const [checked, setChecked] = useState(false)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const methods = useForm()
    const { handleSubmit } = methods
    const [showModalOpenConfirmation, setShowModalOpenConfirmation] = useState(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')

    const closeModalConfirmation = (): void => {
        setShowModalOpenConfirmation(false)
    }

    const handleCheckboxChange = () => {
        setChecked(!checked)
    }

    const handleClose = (): void => {
        history.push('/home-wallet')
        setShowModal(false)
    }

    const isButtonEnabled = checked

    const saveFormData = (): void => {
        setIsLoading(true)
        PostUpdateUserCrm()
    }

    const PostUpdateUserCrm = (): void => {
        setIsLoading(true)
        const processedData = {
            ...userData,
            City: null,
            Country: null,
            OfficeCity: null,
            InternationalCountry: null,
        }
        postUpdateUserCrm(tokenSave, processedData)
            .then((res: any) => {
                setIsLoading(false)
                if (res['status'] === 200) {
                    setShowModalOpenConfirmation(true)
                } else {
                    const messageInfo = res.data?.Message || res.Message
                    setShowModal(true)
                    setMessageError(messageInfo)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setMessageError('Error, no se ha podido conectar con el servidor')
            })
    }

    return (
        <>
            <TermsAndCondContainer>
                <Header>
                    <h3>Declaraciones y Autorizaciones</h3>
                </Header>
                <p style={{ textAlign: 'justify' }}>
                    Manifiesto que todos los datos aquí consignados son ciertos, que la información
                    que adjunto es veraz y verificable y autorizo su verificación ante cualquier
                    persona natural o jurídica, pública o privada, sin limitación alguna, y me
                    obligo a actualizar o confirmar la información una vez al año o cada vez que un
                    producto o servicio lo amerite. 1. Protección de datos: De conformidad por lo
                    dispuesto por la Ley Estatutaria 1581 del año 2012, de Protección de datos
                    personales, autorizo que mis datos, facilitados en la actualización de datos,
                    como consecuencia del goce de los servicios y beneficios facilitados por
                    Cavipetrol, sean incorporados a una base de datos responsabilidad del fondo de
                    empleados. Los datos serán tratados con la finalidad de mantener, desarrollar y
                    controlar la presente relación que nos une y con base a los criterios de
                    seguridad definidos en la política de tratamiento desarrollada por Cavipetrol
                    cuya consulta puede llevarse a cabo a través de nuestra página web:
                    www.cavipetrol.com Del mismo modo, autorizo la transferencia de mis datos a
                    terceras entidades, cuya comunicación sea necesaria para atender los servicios o
                    beneficios que como asociado deseo contratar. La comunicación de datos de mis
                    beneficiarios no podrá realizarse salvo que, con carácter previo, haya obtenido
                    la autorización de los titulares en los mismos términos que los recogidos en la
                    presente cláusula. Así mismo, declaro mi deseo de ejercer los derechos de
                    consulta y reclamo sobre mis datos mediante escrito dirigido a Cavipetrol en la
                    siguiente dirección: servicio.cliente@cavipetrol.com. Obrando en nombre propio y
                    de manera voluntaria y dando certeza de que todo lo aquí consignado es cierto,
                    realizo la siguiente declaración: 1. Tanto mi actividad, profesión u oficio es
                    lícita y la ejerzo dentro del marco legal, los recursos que poseo no provienen
                    de actividades ilícitas de las contempladas en el Código Penal Colombiano. 2. La
                    información que he suministrado en este formulario es veraz y verificable y me
                    obligo a actualizarla anualmente. 3. Autorizo a Cavipetrol para tomar las
                    medidas correspondientes, en caso de detectar cualquier inconsistencia en la
                    información consignada en este formulario, eximiendo a Cavipetrol de toda
                    responsabilidad que se derive de ello. 4. Los recursos que se derivan de esta
                    autorización no se destinarán a la financiación del terrorismo, grupos
                    terroristas o actividades terroristas.
                </p>
            </TermsAndCondContainer>
            <br />
            <FormProvider {...methods}>
                <PaginationContent>
                    <Pagination>
                        <ButtonPagination>
                            <PageItem
                                className="disableFm"
                                onClick={() => handleNextStep('declarationOfPoliticalExposed')}
                            >
                                {'<'} Anterior
                            </PageItem>
                        </ButtonPagination>
                        <p className="symbol"> {'/'} </p>
                    </Pagination>
                </PaginationContent>
            </FormProvider>
            <ContainerCheck>
                <CheckTermsCond type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                <TermText>
                    He leído y acepto a Cavipetrol a consultar y reportar en las centrales de
                    información financiera
                </TermText>
            </ContainerCheck>
            <Footer>
                <AcceptContent>
                    <Button
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleSubmit(saveFormData)}
                        isLoading={isLoading}
                        extend
                        disabled={!isButtonEnabled}
                    >
                        Enviar
                    </Button>
                </AcceptContent>
            </Footer>

            <ModalConfirmation
                showModalOpenConfirmation={showModalOpenConfirmation}
                closeModalConfirmation={closeModalConfirmation}
            />

            <ModalError
                showModalOpenError={showModal}
                titleError={'Error'}
                textError={messageError}
                closeModalError={handleClose}
            />
        </>
    )
}

export default DeclarationsAuthorizations
