// styles
import { ContainerTitle, Title } from './titleModule-styles'

const TitleModule = (): JSX.Element => {
    return (
        <>
            <ContainerTitle>
                <Title>
                    Genera tu<span>certificado</span>
                </Title>
            </ContainerTitle>
        </>
    )
}

export default TitleModule
