import tw, { styled } from 'twin.macro'

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}

    .logo-table {
        width: 100%;
        cursor: pointer;
        margin: 30px;
    }
    @media (max-width: 500px) {
        .logo-table {
            width: 100%;
            cursor: pointer;
            margin: 10px;
        }
    }
`
