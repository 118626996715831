import tw, { styled } from 'twin.macro'

export const Container = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const ContainerTitle = styled.div`
    ${tw`mt-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-8 w-full rounded-[10px] shadow-[0px 3px 6px #00000029] mt-10`}
    ul {
        list-style: none;
        padding-left: 1rem;
    }
    ul li {
        padding-left: 2rem;
        background-image: var(--url-money-dollar-coin);
        background-position: 0 0;
        background-size: 1.6rem 1.6rem;
        background-repeat: no-repeat;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: transparent;
        opacity: 1;
    }
    .input-ivr-icon {
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/lock.svg);
        background-size: calc(0.4em + 0.25rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    input.input-ivr-icon.form-control.is-invalid {
        padding-right: 4.125rem;
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/lock.svg),
            var(--url-exclamation-mark-icon);

        background-position: 10px center, center right 0.75rem;
        /* background-size: 16px 12px, calc(0.75em + 0.375rem); */
        background-size: calc(0.4em + 0.25rem), 16px;
    }

    .border-option-header {
        border-right: 3px solid #dee2e6 !important;
        border-top: none;
    }
`
