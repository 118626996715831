import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import { Pagination, PageItem } from 'react-bootstrap'
import { ContainerPossessions } from '../PossessionsInsert/PossessionsInsert-styles'
import TitleModule from './inc/TitleModule'
import {
    ButtonPagination,
    ButtonsContainer,
    Content,
    ContentInfo,
    ModalCancelButton,
    OptionsContainer,
    OptionsContainerDetail,
    OptionType,
    PaginationContent,
    TextPBold,
    Image,
} from './Mortgages-styles'
import { getMortgageClient } from '../../../redux/products'
import { useDispatch } from 'react-redux'
import { ForgotSVG, NotProductSVG } from '../../utils/getIcons'
import moment from 'moment'

const Mortgages = (): JSX.Element => {
    const history = useHistory()
    const [infoMortgages, setInfoMortgages] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(
            getMortgageClient((res) => {
                setInfoMortgages(res)
                if (res) {
                    setTotalPages(res.data.length)
                    setCurrentPage(0)
                }
            })
        )
    }, [])

    const handleNextPage = async () => {
        setCurrentPage(currentPage + 1)
    }

    const handlePreviousPage = async () => {
        setCurrentPage(currentPage - 1)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Servicios en linea',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Consultas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Hipotecas',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]

    return (
        <LayoutContent>
            <ContainerPossessions>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <TitleModule />
                {infoMortgages?.response === '204' && (
                    <Content>
                        <ContentInfo>
                            <Image src={ForgotSVG} alt="black" />
                            <TextPBold>
                                Actualmente <span>no tienes hipotecas</span> con cavipetrol
                            </TextPBold>
                        </ContentInfo>
                        <ButtonsContainer>
                            <ModalCancelButton
                                variant="primary"
                                onClick={() => redirection('/home-wallet')}
                            >
                                Regresar al home
                            </ModalCancelButton>
                        </ButtonsContainer>
                    </Content>
                )}
                {infoMortgages?.response === '500' && (
                    <Content>
                        <ContentInfo>
                            <Image src={NotProductSVG} alt="black" />
                            <TextPBold>
                                En este momento no podemos procesar tu consulta por favor intenta
                                más tarde
                            </TextPBold>
                        </ContentInfo>
                        <ButtonsContainer>
                            <ModalCancelButton
                                variant="primary"
                                onClick={() => redirection('/home-wallet')}
                            >
                                Regresar al home
                            </ModalCancelButton>
                        </ButtonsContainer>
                    </Content>
                )}
                {infoMortgages?.response === '200' && (
                    <OptionsContainer>
                        <div className="title">Información sobre tu hipoteca</div>
                        <OptionType>
                            <div className="text">Tipo de pagaré</div>
                            <div className="text1">
                                {infoMortgages?.data[currentPage].pAGPromissoryNoteType}
                            </div>
                        </OptionType>
                        <OptionType>
                            <div className="text">Tipo de documento</div>
                            <div className="text1">
                                {infoMortgages?.data[currentPage].rPDDocumentType}
                            </div>
                        </OptionType>
                        <OptionType>
                            <div className="text">Número de pagaré</div>
                            <div className="text1">
                                {infoMortgages?.data[currentPage].pAGPromissoryNoteNumber}
                            </div>
                        </OptionType>
                        <OptionType>
                            <div className="text">Número de documento</div>
                            <div className="text1">
                                {infoMortgages?.data[currentPage].rPDDocumentNumber}
                            </div>
                        </OptionType>
                    </OptionsContainer>
                )}
                {infoMortgages?.response === '200' && (
                    <>
                        <OptionsContainerDetail>
                            <div className="title">
                                A continuación encontrarás información detallada de tu
                                <span> hipoteca.</span>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="text">Fecha</td>
                                        <td className="left-colored">
                                            {moment(
                                                infoMortgages?.data[currentPage].gAHDeedDate
                                            ).format('DD/MM/YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Oficina de instrumentos publicos</td>
                                        <td className="left-colored">
                                            {
                                                infoMortgages?.data[currentPage]
                                                    .gAHPublicInstrumentOffice
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Matrícula</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHDeedNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Dirección</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHRealEstateAddress}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Ciudad</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHRealEstateCity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Valor total del avalúo</td>
                                        <td className="left-colored">
                                            {formatter.format(
                                                infoMortgages.data[currentPage]
                                                    .gAHTotalAppraisedValue
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Valor construcción</td>
                                        <td className="left-colored">
                                            {formatter.format(
                                                infoMortgages.data[currentPage]
                                                    .gAHConstructionAppraisalValue
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Tipo de hipoteca</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHTypeOfMortgage}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Número de la escritura</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHDeedNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Notaría</td>
                                        <td className="left-colored">
                                            {infoMortgages?.data[currentPage].gAHNotaryRegistration}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Fecha de escritura</td>
                                        <td className="left-colored">
                                            {moment(
                                                infoMortgages?.data[currentPage].pAGDateGenerated
                                            ).format('DD/MM/YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text">Ciudad de Notaría</td>
                                        <td className="left-colored">
                                            {
                                                infoMortgages?.data[currentPage]
                                                    .gAHNotaryRegistrationCity
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {infoMortgages && (
                                <PaginationContent>
                                    <Pagination>
                                        <ButtonPagination>
                                            {currentPage !== 0 && (
                                                <PageItem onClick={handlePreviousPage}>
                                                    {'<'} Volver
                                                </PageItem>
                                            )}
                                        </ButtonPagination>
                                        {currentPage > 1 && currentPage < totalPages && (
                                            <p className="symbol"> {'/'} </p>
                                        )}
                                        <ButtonPagination>
                                            {currentPage + 1 !== totalPages && (
                                                <PageItem
                                                    className="disableFm"
                                                    onClick={handleNextPage}
                                                >
                                                    Continuar {'>'}
                                                </PageItem>
                                            )}
                                        </ButtonPagination>
                                    </Pagination>
                                </PaginationContent>
                            )}
                        </OptionsContainerDetail>
                    </>
                )}
            </ContainerPossessions>
        </LayoutContent>
    )
}

export default Mortgages
