import axios from 'axios'
import SaveLog from '../UI/libraries/saveLog'

export const Axios = axios.create({
    headers: { 'Content-Type': 'application/json' },
    baseURL: process.env.REACT_APP_API_URL,
})

export const Axios2 = axios.create({
    headers: { 'Content-Type': 'application/json' },
    baseURL: process.env.REACT_APP_API_URL2,
})

const get = async <T>(endpoint: string) => {
    try {
        const { data } = await Axios.get(endpoint)
        return data as unknown as T
    } catch (error) {
        SaveLog(error)
    }
}

export const getSecurity = async <T>(endpoint: string, token: string) => {
    try {
        const AxiosGet = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //IpAddressUser: ip.address(),
            },
            baseURL: process.env.REACT_APP_API_URL,
        })
        const { data } = await AxiosGet.get(endpoint)
        return data as unknown as T
    } catch (error) {
        SaveLog(error)
    }
}

const getSecurityC = async <T>(endpoint: string, token: string, body: any) => {
    try {
        const AxiosGet = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                //IpAddressUser: ip.address(),
            },
            baseURL: process.env.REACT_APP_API_URL,
        })
        const { data } = await AxiosGet.get(endpoint, body)
        return data as unknown as T
    } catch (error) {
        SaveLog(error)
    }
}

const post = async <T>(endpoint: string, body: any) => {
    const data = await Axios.post(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

export const postSecurity = async <T>(endpoint: string, token: string, body: any) => {
    const AxiosSecurity = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecurity.post(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const postSecurityLigth = async <T>(endpoint: string, token: string) => {
    const AxiosSecLight = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecLight.post(endpoint, '')
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}
const postLight = async <T>(endpoint: string) => {
    const data = await Axios.post(endpoint, '')
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const putSecurity = async <T>(endpoint: string, token: string, body: any) => {
    const AxiosPut = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosPut.put(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const deleteSecurity = async <T>(endpoint: string, token: string) => {
    const AxiosDelete = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosDelete.delete(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const get2 = async <T>(endpoint: string, params: any) => {
    try {
        let data
        if (params) data = await Axios2.get(endpoint, { params })
        else data = await Axios2.get(endpoint)
        return data as unknown as T
    } catch (error) {
        SaveLog(error)
    }
}

const postWithFiles = async <T>(endpoint: string, body: any, files: File[], token: string) => {
    const formData = new FormData()

    // Agregar el JSON como una cadena
    formData.append('dataJson', JSON.stringify(body))

    // Agregar cada archivo con la clave "files"
    files.forEach((file) => {
        formData.append('files', file)
    })

    // Configuración de Axios
    const AxiosWithFiles = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })

    console.log('La dirección a donde se va a hacer el almacenamiento es')
    console.log(process.env.REACT_APP_API_URL)
    console.log('endPoint para hacer solicitud')
    console.log(endpoint)

    // Realizar la petición POST
    const data = await AxiosWithFiles.post(endpoint, formData)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            // Manejo de errores
        })

    return data as unknown as T
}

const postWithFilesCampaign = async <T>(
    endpoint: string,
    body: any,
    files: File[],
    token: string
) => {
    const formData = new FormData()

    // Agregar el JSON como una cadena
    formData.append('dataJson', body)

    // Agregar cada archivo con la clave "files"
    files.forEach((file) => {
        formData.append('files', file)
    })

    // Configuración de Axios
    const AxiosWithFiles = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })

    // Realizar la petición POST
    const data = await AxiosWithFiles.post(endpoint, formData)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            // Manejo de errores
        })

    return data as unknown as T
}
const putWithFilesCampaign = async <T>(
    endpoint: string,
    body: any,
    files: File[],
    token: string
) => {
    const formData = new FormData()

    // Agregar el JSON como una cadena
    formData.append('dataJson', body)

    // Agregar cada archivo con la clave "files"
    files.forEach((file) => {
        formData.append('files', file)
    })

    // Configuración de Axios
    const AxiosWithFiles = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })

    // Realizar la petición POST
    const data = await AxiosWithFiles.put(endpoint, formData)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            // Manejo de errores
        })

    return data as unknown as T
}

const getSecurityTC = async <T>(endpoint: string, token: string) => {
    const AxiosSecurity = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecurity.get(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}
const postUploadFilesCampaign = async <T>(endpoint: string, files: File[], token: string) => {
    const formData = new FormData()

    // Agregar cada archivo con la clave "files"
    files.forEach((file) => {
        formData.append('files', file)
    })

    // Configuración de Axios
    const AxiosWithFiles = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })

    console.log('La dirección a donde se va a hacer el almacenamiento es')
    console.log(process.env.REACT_APP_API_URL)
    console.log('endPoint para hacer solicitud')
    console.log(endpoint)

    // Realizar la petición POST
    const data = await AxiosWithFiles.post(endpoint, formData)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            // Manejo de errores
        })

    return data as unknown as T
}
const postPdf = async <T>(endpoint: string, token: string) => {
    // Configuración de Axios
    const AxiosWithFiles = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })

    console.log('La dirección a donde se va a hacer el almacenamiento es')
    console.log(process.env.REACT_APP_API_URL)
    console.log('endPoint para hacer solicitud')
    console.log(endpoint)

    // Realizar la petición POST
    const data = await AxiosWithFiles.post(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            // Manejo de errores
        })

    return data as unknown as T
}

const getDepartment = async <T>(endpoint: string, token: string) => {
    const DataUpdateAssociate = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await DataUpdateAssociate.post(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const getCities = async <T>(endpoint: string, token: string) => {
    const DataUpdateAssociate = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await DataUpdateAssociate.post(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const getListUpdateData = async <T>(endpoint: string, token: string) => {
    const ListUpdateData = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await ListUpdateData.get(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const getListCitiesByCountriesUser = async <T>(endpoint: string, token: string, body: any) => {
    const DataUpdateAssociate = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await DataUpdateAssociate.post(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const getUpdateDataAssociate = async <T>(endpoint: string, token: string) => {
    const UpdateData = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await UpdateData.get(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const postUpdateDataAssociate = async <T>(endpoint: string, token: string, body: any) => {
    const DataUpdateAssociate = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await DataUpdateAssociate.post(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const putSecurityLight = async <T>(endpoint: string, token: string) => {
    const AxiosSecurity = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecurity.put(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const patchSecurityLight = async <T>(endpoint: string, token: string) => {
    const AxiosSecurity = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecurity.patch(endpoint)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

const patchSecurity = async <T>(endpoint: string, token: string, body: any) => {
    const AxiosSecurity = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        baseURL: process.env.REACT_APP_API_URL,
    })
    const data = await AxiosSecurity.patch(endpoint, body)
        .then((res) => {
            return res as unknown as T
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data as unknown as T
            } else if (error.request) {
                console.log('console desde el CATCH request')
            } else if (error.message) {
                console.log('console desde el CATCH message')
            }
        })
    return data as unknown as T
}

export const http = {
    get,
    get2,
    post,
    getSecurity,
    getSecurityC,
    postSecurity,
    postSecurityLigth,
    postLight,
    putSecurity,
    deleteSecurity,
    postWithFiles,
    getSecurityTC,
    postWithFilesCampaign,
    putWithFilesCampaign,
    postUploadFilesCampaign,
    getDepartment,
    getCities,
    getListUpdateData,
    getListCitiesByCountriesUser,
    getUpdateDataAssociate,
    postUpdateDataAssociate,
    postPdf,
    putSecurityLight,
    patchSecurityLight,
    patchSecurity,
}
