import { Link } from 'react-router-dom'
import { Credit1SVG } from '../../../../../utils/getIcons'
import { CreditsProductsContent } from '../../CreditsProducts/creditsProducts-styles'

interface AdminCardCreditProductsProps {
    rol: string
}

export const AdminCardCreditProducts: React.FC<AdminCardCreditProductsProps> = ({
    rol,
}): JSX.Element => {
    return (
        <CreditsProductsContent>
            <p className="title">Créditos</p>
            <div className="card-content" data-tour="card-credits-available">
                <div className="img-section">
                    <img src={Credit1SVG} alt="crédito" className="" />
                    <p className="title-card">Créditos disponibles</p>
                </div>
                <p className="subtitle-card">Gestiona tus créditos y conoce sus detalles</p>
                {rol === 'Administrador' ? (
                    <p className="link-btn" style={{ color: '#E70000' }}>
                        No se encontraron datos para mostrar
                    </p>
                ) : (
                    <Link className="link-btn" to="/credits-group">
                        Conoce tus créditos
                    </Link>
                )}
            </div>
        </CreditsProductsContent>
    )
}
