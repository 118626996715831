import { Modal } from 'react-bootstrap'
import { CreditCardFlowSVG } from '../../../../utils/getIcons'
import {
    ButtonContent,
    ButtonsContainer,
    ModalButton,
    ModalInfoRequest,
} from './../../AlertCreditCardRequests-Styled'
import { Button } from '../../../../components'

interface ModalRequestCampaignAppProps {
    showModal?: boolean
    handleNewRequest: () => void
    handleContinueRequest: () => void
    onHide: () => void
}

const ModalRequestCampaign: React.FC<ModalRequestCampaignAppProps> = ({
    showModal,
    handleNewRequest,
    handleContinueRequest,
    onHide,
}): JSX.Element => {
    return (
        <ModalInfoRequest
            show={showModal}
            onHide={onHide} // Usamos la función para manejar el cierre del modal
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <img src={CreditCardFlowSVG} alt="CreditCardFlowSVG" />
                <h6 className="title-modal-login">
                    Tienes una solicitud pre-aprobada de tarjeta de crédito
                </h6>
                <p>
                    Deseas realizar una nueva solicitud de tarjeta de crédito o continuar tu
                    solicitud pre-aprobada.
                </p>
                <ButtonsContainer>
                    <ModalButton variant="primary" onClick={handleNewRequest}>
                        Iniciar nuevo formulario
                    </ModalButton>

                    <ButtonContent>
                        <Button
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={handleContinueRequest}
                            extend
                        >
                            Continuar
                        </Button>
                    </ButtonContent>
                </ButtonsContainer>
            </Modal.Body>
        </ModalInfoRequest>
    )
}

export default ModalRequestCampaign
