import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const Wrapper = styled.section`
    ${tw`mb-[20px] py-[10px] px-0 flex justify-center items-center`}

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[100%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const CodeptorInfo = styled.div`
    display: flex;
    flex-direction: column;
`

export const CodeptorSection = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const CodeptorChildTitle = styled.div`
    display: flex;
    align-items: start;
`
export const CodeptorChild = styled.div`
    width: 25%;
    height: 50%;
    margin: 10px 50px;

    .largeInfo {
        white-space: nowrap;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin: 10px 0;
    }
`
export const CodeptorBorderChild = styled.div`
    width: 25%;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 50px;

    @media (max-width: 768px) {
        width: 100%;
        margin: 10px 0;
    }
    @media (max-width: 460px) {
        text-align: center;
    }
`

export const ImageContainer = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 10px;
    }

    img {
        width: 10%;
    }
    @media (max-width: 768px) {
        width: 100%;
        padding-top: 20px;
    }
`

export const SpacerContent = styled.div`
    ${tw`mt-[10%]`}

    .hide-number-arrows::-webkit-inner-spin-button,
    .hide-number-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .hide-number-arrows {
        -moz-appearance: textfield;
    }
`

export const PpalTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }

    img {
        margin-bottom: 3px;
    }
`
export const YellowTitle = styled.h2`
    ${tw`font-montserrat text-base my-[20px]`}

    color: #F5A50B;
`

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}

    span {
        color: #f5a50b;
    }
`

export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}

    margin: 0;
    padding: 0;

    span {
        color: #f5a50b;
    }
`
export const CodeText = styled.span`
    ${tw`font-montserrat font-bold text-base`}

    color: #512f8b;
`

export const Image = styled.img`
    width: 30%;
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}
`

export const ArrowBlack = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    @media (max-width: 500px) {
        ${tw`w-40`}
        & p {
            ${tw`text-sm`}
        }
    }

    @media (min-width: 1024px) {
        ${tw`w-12`}
    }

    @media (max-width: 460px) {
        margin-top: 100px;
    }
`

export const ArrowYellow = styled.a`
    display: flex;
    gap: 12px;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        transform: rotate(-90deg);
    }

    span {
        color: #f2a510;
        font-weight: bold;
    }
`
