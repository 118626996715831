import { AxiosResponse } from 'axios'
import { advanceToFAIAccount } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetRates(tokenSave: string): Promise<Result> {
    try {
        return await advanceToFAIAccount
            .getRates('/api/CreditCardProcess/GetRates/GetRates', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetTotalBalanceArrears(tokenSave: string): Promise<Result> {
    try {
        return await advanceToFAIAccount
            .getRates('/api/Products/GetTotalBalanceArrears/GetTotalBalanceArrears', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useCreditAdvanceFai(tokenSave: string, data: any): Promise<Result> {
    try {
        return await advanceToFAIAccount
            .creditAdvanceFai(
                '/api/CreditCardTransaction/AdvanceFai/CreditAdvanceFai',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
