import { AxiosResponse } from 'axios'
import { userManagement } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetConfiguration(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('/api/RolUsers/GetConfiguration', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateConfiguration(
    tokenSave: string,
    idConfiguration: number,
    valueConfiguration: string
): Promise<Result> {
    try {
        return await userManagement
            .patchLight(
                `/api/RolUsers/UpdateConfiguration?id=${idConfiguration}&value=${valueConfiguration}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetMessages(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('/api/RolUsers/GetMessages', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateMessages(
    tokenSave: string,
    idMessages: number,
    textMessages: string
): Promise<Result> {
    try {
        return await userManagement
            .patchLight(
                `api/RolUsers/UpdateMessages?id=${idMessages}&text=${textMessages}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetTemplatesNotification(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('/api/RolUsers/GetTemplatesNotification', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateTemplatesNotification(
    tokenSave: string,
    data: any
): Promise<Result> {
    try {
        return await userManagement
            .patch('/api/RolUsers/UpdateTemplatesNotification', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetTemplateCertificate(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('/api/RolUsers/GetTemplateCertificates', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateTemplateCertificate(tokenSave: string, data: any): Promise<Result> {
    try {
        return await userManagement
            .patch('/api/RolUsers/UpdateTemplatesCertification', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetImages(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('/api/RolUsers/GetImages', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateImages(tokenSave: string, data: any): Promise<Result> {
    try {
        return await userManagement
            .patch('/api/RolUsers/UpdateImages', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export const usePostPaymentPlanSimulator = (tokenSave: string) => {
    const postPaymentPlanSimulator = async (newData: any) => {
        try {
            const response = await userManagement.postBody(
                'api/Simulator/PaymentPlanSimulator/PaymentPlanSimulator',
                tokenSave,
                newData
            )

            return response
        } catch (error) {
            console.error('Error en la llamada al endpoint:', error)
            throw error
        }
    }

    return { postPaymentPlanSimulator }
}

export async function useVerifyUrlStorage(tokenSave: string, data: any): Promise<Result> {
    try {
        return await userManagement
            .postBody('/api/RolUsers/VerifyUrlStorage', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useVerifyNamesStorage(tokenSave: string, data: any): Promise<Result> {
    try {
        return await userManagement
            .postBody('/api/RolUsers/VerifyNamesStorage', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
