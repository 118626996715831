import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const Wrapper = styled.section`
    ${tw`mb-[20px] py-[15px] px-0 min-h-[50vh] flex justify-center items-center py-2`}

    width: 100%;

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`

export const TableContent = styled.div`
    ${tw`mb-[20px] py-[15px] px-0 flex justify-center items-center py-2`}

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .form-container {
        width: 90%;
    }

    .invalid {
        color: red;
    }

    .form-width {
        width: 125%;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef2e;
        opacity: 1;
    }

    .form-width {
        width: 100%;
    }

    @media screen and (max-width: 450px) {
        align-items: stretch;
        overflow-x: scroll;

        .form-width {
            width: 125%;
        }
    }
`

export const ProductContent = styled.div`
    ${tw`mb-[20px] py-[15px] px-0 flex  py-2`}

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .v-align {
        display: flex;
        align-items: center;
    }

    .v-align div {
        margin: 0 !important;
    }
`

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[100%] rounded-[10px]`}

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .fom-simulator {
        width: 100%;
    }

    @media screen and (max-width: 450px) {
        align-items: stretch;
    }
`

export const ContentInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const SpacerContent = styled.div`
    ${tw`mt-[10%]`}
    margin-top:80px;

    .hide-number-arrows::-webkit-inner-spin-button,
    .hide-number-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .hide-number-arrows {
        -moz-appearance: textfield;
    }
`

export const PpalTitle = styled.p`
    ${tw`font-montserrat font-bold  lg:text-xl text-gray-custom-900`}
    margin-top:40px;
    font-size: 26px;
    margin-left: 15px;

    .sub-title {
        ${tw`text-dominant-dark`}
    }

    span {
        color: #f5a50b;
    }
`
export const YellowTitle = styled.h2`
    ${tw`font-montserrat text-base my-[20px]`}

    color: #F5A50B;
`

export const TextP = styled.p`
    ${tw`font-montserrat text-base`}

    span {
        font-weight: bold;
    }
`

export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}

    span {
        color: #f5a50b;
    }
`

export const LabelBold = styled.label`
    ${tw`font-montserrat font-bold text-base`}
`

export const LabelBase = styled.label`
    ${tw`font-montserrat text-base`}
`

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`

export const Image = styled.img`
    width: 30%;
`

export const ModalButton = styled(Button)`
    ${tw`my-1.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }

    @media (max-width: 410px) {
        ${tw` w-[300px] h-[50px] color[#000000] rounded-[10px] `}
    }

    @media (min-width: 411px) and (max-width: 460px) {
        ${tw` w-[330px] h-[50px] color[#000000] rounded-[10px] `}
    }
`

export const CancelButton = styled(Button)`
    ${tw`my-1.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;

    @media (max-width: 410px) {
        ${tw` w-[300px] h-[50px] color[#000000] rounded-[10px]`}
    }

    @media (min-width: 411px) and (max-width: 460px) {
        ${tw` w-[330px] h-[50px] color[#000000] rounded-[10px]`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ArrowBlack = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    @media (max-width: 500px) {
        ${tw`w-40`}
        & p {
            ${tw`text-sm`}
        }
    }

    @media (min-width: 1024px) {
        ${tw`w-12`}
    }
`

export const ArrowYellow = styled.a`
    display: flex;
    gap: 12px;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        transform: rotate(-90deg);
    }

    span {
        color: #f2a510;
        font-weight: bold;
    }
`
export const TableContainer = styled.table`
    ${tw`w-full h-16`}

    .td-field {
        width: 20%;
        padding: 10px;
    }
`

export const THead = styled.thead`
    ${tw`border-b border-solid border-[#00000029] w-full`}

    & tr {
        ${tw`h-16`}

        & th {
            & h4 {
                ${tw`m-0 font-helvetica font-normal text-base text-gray-custom-500`}
            }
        }
    }
`
export const TBody = styled.tbody`
    ${tw`w-full`}

    & tr {
        ${tw`h-[52px] border-b border-solid border-[#00000029]`}

        & td {
            &:first-child {
                & p {
                    ${tw`font-normal font-helvetica py-1`}
                }
                & span {
                    ${tw`font-bold font-montserrat py-1`}
                }
            }
            & p {
                ${tw`m-0 font-montserrat font-bold text-base text-gray-custom-500`}
            }
        }
    }

    .wrapText {
        max-width: 200px;
        word-wrap: break-word;
    }
`
export const RowTableTitle = styled.tr`
    background-color: var(--dominant-color-dark);
    ${tw`font-montserrat text-white text-center `}

    .realEstateOffer {
        width: 15% !important;
    }

    .realEstateType {
        width: 20% !important;
    }
`

export const RowTable = styled.tr`
    ${tw`font-montserrat text-center text-base text-black`}
`

export const TextDecorationTable = styled.td`
    text-decoration: underline;
`
export const FormSelect = styled.div`
    ${tw``}
    select.selectFromControl {
        background-image: var(--url-arrow-down-yellow-icon) !important;
    }
`

export const PrintDiv = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    justify-content: flex-end !important;
    margin-top: -5rem;
    /* margin-right: 5rem; */
`
export const PdfHr = styled.hr`
    border: none; /* Elimina el borde por defecto */
    height: 1px; /* Altura de la línea */
    background: repeating-linear-gradient(
        to right,
        black,
        black 10px,
        transparent 10px,
        transparent 20px /* Ancho y espaciado de los guiones */
    );
`
export const PdfPpalTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}
    font-size: 30px !important;
`
export const ProductTablePdfContainer = styled.table`
    ${tw`w-full h-16`}
`
export const RowTablePdfTitle = styled.tr`
    ${tw`font-montserrat text-black text-center `}
`
export const TextLBold = styled.label`
    ${tw`font-montserrat font-bold text-base`}
`
export const TextPpfBold = styled.p`
    ${tw`font-montserrat font-bold text-base`}

    margin: 0;
`
