import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import UserCreationTerms from './inc/UserCreationTerms/UserCreationTerms'
import UserCreationForm from './inc/UserCreationForm/UserCreationForm'

const UserCreation = (): JSX.Element => {
    const [acceptedTerms, setAcceptedTerms] = useState(false)

    const history = useHistory()
    const redirection = (url: string): void => {
        history.push(url)
    }

    const handleAcceptTerms = (): void => {
        setAcceptedTerms(true)
    }

    const handleRejectTerms = (): void => {
        console.log('User did not accept terms and conditions.')
    }

    if (!acceptedTerms) {
        return <UserCreationTerms onAccept={handleAcceptTerms} onReject={handleRejectTerms} />
    }

    return (
        <LayoutContent>
            {acceptedTerms && <UserCreationForm redirection={redirection} />}
        </LayoutContent>
    )
}

export default UserCreation
