import tw, { styled } from 'twin.macro'
import { FormLabel as FormLabelApp, FormGroup as FormGroupApp } from '../../../../components'

export interface FormGroupProps {
    $heightSize?: string
}
export const FormGroup = styled(FormGroupApp)<FormGroupProps>`
    ${tw`p-0`}

    height: ${(props) => props.$heightSize};
`
export interface FormLabelProps {
    $colorMode?: 'light' | 'dark'
}

export const FormLabel = styled(FormLabelApp)<FormLabelProps>`
    ${tw`font-helvetica font-normal block text-dominant-dark text-sm my-3`}

    ${({ $colorMode }) => ($colorMode === 'dark' ? tw`text-black` : tw`text-dominant-dark`)}

    span {
        color: #000000;
    }
`

export const TextP = styled.p`
    ${tw`font-helvetica text-base`}

    span {
        color: #f5a50b;
    }
`

export const FromFai = styled.div`
    ${tw`font-montserrat text-base`}

    .input-icon-user {
        ${tw`font-helvetica`}
        text-transform: capitalize;
        background-image: url(https://storageaccountcavportal.blob.core.windows.net/assets/Icons/user-alt.svg);
        background-size: 13px;
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
        color: #53595a;
    }

    input.input-icon-user.form-control:disabled {
        background-color: transparent;
    }

    .input-icon-account-fai {
        background-image: var(--url-mini-payment-grey);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-account-fai.form-control.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-payment-grey), var(--url-exclamation-mark-icon);

        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .input-icon-destination-id {
        background-image: var(--url-mini-identification);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-destination-id.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-mini-identification), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .input-icon-amount-transfer {
        background-image: var(--url-money-bill);
        background-size: calc(0.75em + 0.375rem);
        background-position: 10px center;
        background-repeat: no-repeat;
        padding-left: 40px;
    }
    input.input-icon-amount-transfer.is-invalid {
        padding-right: 4.125rem;
        background-image: var(--url-money-bill), var(--url-exclamation-mark-icon);
        background-position: 10px center, center right 0.75rem;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
`

export const ContainerButtons = styled.div`
    ${tw`gap-1 my-5 flex flex-col justify-end items-end`}

    & button {
        ${tw`lg:!w-[25%]`}
    }
`
