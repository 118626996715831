import { ReactElement } from 'react'
// styled components
import { StepContent, StepTitle } from '../../appTour-styles'

const DetailCardQuotaStep = (): ReactElement => {
    return (
        <StepContent>
            <StepTitle>
                Aquí encontrarás los <span className="sub-title">detalles</span> sobre tu tarjeta
            </StepTitle>

            <p>El cupo que tienes, tasas y el tipo de tarjeta, todo en el mismo lugar.</p>
        </StepContent>
    )
}

export default DetailCardQuotaStep
