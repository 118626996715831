import { FC, useState } from 'react'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { Form } from 'react-bootstrap'
// styles
import {
    Wrapper,
    Content,
    YellowTitle,
    TextP,
    ButtonsContainer,
    ModalButton,
    ModalCancelButton,
    ActivateInternationalTitle,
} from '../../activateInternationalCard-Styles'
import { useValidateCardNumber } from '../../hooks/useInternationalCard'
import ModalLoading from '../ModalInfo/ModalLoading'

interface StepProps {
    tokenSave: string
    handleNextStep: () => void
    redirections: (value: string) => void
    saveCurrentData: (value: any) => void
    saveLastFourNumbers: (value: any) => void
}

const FirstStep: FC<StepProps> = ({
    tokenSave,
    handleNextStep,
    redirections,
    saveCurrentData,
    saveLastFourNumbers,
}): JSX.Element => {
    const methods = useForm()
    const { handleSubmit, getValues, control, formState, setError, clearErrors, register } = methods
    const { errors } = formState
    const [CardNumber, setCardNumber] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const HandleFormSubmit = () => {
        const formData = getValues()

        setIsLoading(true)

        useValidateCardNumber(CardNumber, tokenSave)
            .then((res: any) => {
                console.log(res)
                setIsLoading(false)
                if (res['status'] === 200) {
                    console.log('tarjeta válida')
                    const lastNumbers = CardNumber.substring(CardNumber.length - 4)
                    saveLastFourNumbers(lastNumbers)
                    saveCurrentData(formData)
                    handleNextStep()
                } else {
                    setError('CardNumber', {
                        message: 'Número de tarjeta inválido',
                    })
                }
            })
            .catch((error) => {
                console.error(error)
                setError('CardNumber', {
                    message: 'Número de tarjeta inválido',
                })
            })
    }

    return (
        <>
            <ActivateInternationalTitle>
                Solicita la activación de tu tarjeta de crédito <span>fuera de Colombia</span>
            </ActivateInternationalTitle>
            <Wrapper>
                <FormProvider {...methods}>
                    <Content>
                        <YellowTitle>Paso 1 de 3</YellowTitle>
                        <TextP>
                            Confirma la información de la tarjeta que vas a utilizar durante tu
                            viaje.
                        </TextP>
                        <YellowTitle>Número de tarjeta</YellowTitle>

                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'CardNumber'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        maxLength={16}
                                        className="hide-number-arrows"
                                        type="number"
                                        {...register('CardNumber', { required: true })}
                                        value={CardNumber}
                                        placeholder="Ej. 1234 1234 1234 8532"
                                        required
                                        isInvalid={!!errors['CardNumber']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('CardNumber')
                                                if (e.target.value.length <= 16) {
                                                    setCardNumber(e.target.value)
                                                }
                                            } else {
                                                setCardNumber(e.target.value)
                                            }
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('CardNumber', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('CardNumber')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['CardNumber'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['CardNumber']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(HandleFormSubmit)}>
                                <ButtonsContainer>
                                    <ModalCancelButton
                                        variant="primary"
                                        onClick={() => redirections('/')}
                                    >
                                        Cancelar
                                    </ModalCancelButton>
                                    <ModalButton
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleSubmit(HandleFormSubmit)}
                                    >
                                        Continuar
                                    </ModalButton>
                                </ButtonsContainer>
                            </form>
                        </FormProvider>
                    </Content>
                </FormProvider>
            </Wrapper>
            <ModalLoading isLoading={isLoading} />
        </>
    )
}

export default FirstStep
