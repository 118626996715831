import { FC } from 'react'
// styles
import moment from 'moment'
//GENERATE PDF
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer'

interface LoanDataProps {
    tasaEM: number
    periocidad: number
    numero_coutas: number
    monto: number
    valor_prestamo: number
    tasaN: number
}

interface PaymentPlanProps {
    Cuota: number
    ValorCuota: number
    AbonoCapital: number
    Interes: number
    Saldo: number
}

interface PDFProps {
    formatter: (value: number) => string
    loanDataValues: LoanDataProps[]
    paymentsPlanValues: PaymentPlanProps[][]
    rowsCount: number
}

const PDFPage: FC<PDFProps> = ({
    formatter,
    loanDataValues,
    paymentsPlanValues,
    rowsCount,
}): JSX.Element => {
    const formatDate = (value: string) => {
        const formattedDate = moment(value, 'DD/MM/YYYY hh:mm:ss a')
            .format('DD/MMM/YYYY')
            .replace(/\./g, '')
            .toLowerCase()

        return formattedDate
    }

    const styles = StyleSheet.create({
        page: {
            padding: 10,
            position: 'relative',
            fontFamily: 'Helvetica',
        },
        section: {
            flexGrow: 1,
        },
        header: {
            marginTop: 10,
            textAlign: 'justify',
            fontSize: '9px',
            color: '#000',
        },
        textH: {
            fontSize: '9px',
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: 5,
        },
        body: {
            marginTop: 10,
            textAlign: 'justify',
            color: '#000',
        },
        container: {
            flexDirection: 'row',
        },
        headerColumnContainer: {
            width: '100%',
            textAlign: 'center',
            marginBottom: 10,
        },
        column2H: {
            width: '50%',
            textAlign: 'left',
        },
        column2L: {
            width: '70%',
            textAlign: 'left',
        },
        column2R: {
            width: '30%',
            textAlign: 'right',
        },
        column3: {
            width: '33.33%',
            padding: '5px',
        },
        column4: {
            width: '25%',
            padding: '5px',
        },
        row: {
            flexDirection: 'row',
        },
        label: {
            width: '100%',
        },
        value: {
            width: '100%',
        },
        tableContainer: {
            width: '100%',
            margin: 'auto',
            textAlign: 'center',
        },
        table: {
            width: '100%',
            marginLeft: '17%',
            border: 0,
        },
        tableTH: {
            fontSize: '7px',
            fontWeight: 'bold',
        },
        tableText: {
            fontSize: '7px',
        },
        tableRow: {
            width: '100%',
            flexDirection: 'row',
            border: 0,
        },
        tableCol: {
            width: '12.5%',
            padding: 5,
            border: 1,
            textAlign: 'center',
        },
        tableCellCol1: {
            fontSize: '7px',
            fontWeight: 'bold',
            color: '#000',
        },
        tableCellCol2: {
            fontSize: '7px',
            fontWeight: 'normal',
            color: '#000',
        },
        hr: {
            borderBottom: '1px solid black',
            marginTop: 5,
            marginBottom: 5,
        },
        otherTax: {
            width: '100%',
            fontSize: '7px',
            fontWeight: 'bold',
            textAlign: 'left',
            paddingLeft: '20px',
        },
    })

    return (
        <Document>
            {Array.from({ length: rowsCount }).map((_, index) => (
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section} wrap={false} key={index}>
                        <View style={styles.header}>
                            <View style={styles.headerColumnContainer}>
                                <View style={styles.row}>
                                    <View style={styles.column2H}>
                                        <View style={styles.textH}>
                                            <Text>Tasa efectiva anual</Text>
                                        </View>
                                    </View>
                                    <View style={styles.column2H}>
                                        <View style={styles.textH}>
                                            <Text>Valor de préstamo</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.column2H}>
                                        <View style={styles.textH}>
                                            <Text>{loanDataValues[index].tasaEM}%</Text>
                                        </View>
                                    </View>
                                    <View style={styles.column2H}>
                                        <View style={styles.textH}>
                                            <Text>
                                                {formatter(loanDataValues[index].valor_prestamo)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>Monto:</Text>
                                    </View>
                                </View>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>{formatter(loanDataValues[index].monto)}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>Periodicidad:</Text>
                                    </View>
                                </View>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        {loanDataValues[index].periocidad === 24 ? (
                                            <Text>Quincenal</Text>
                                        ) : (
                                            <Text>Mensual</Text>
                                        )}
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>Número de periodos a definir:</Text>
                                    </View>
                                </View>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>{loanDataValues[index].numero_coutas}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>Tasa de interés nominal:</Text>
                                    </View>
                                </View>
                                <View style={styles.column2H}>
                                    <View style={styles.textH}>
                                        <Text>{loanDataValues[index].tasaN}%</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={styles.body} wrap={false}>
                            <View style={styles.tableContainer}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <View style={styles.tableCellCol1}>
                                                <Text style={styles.tableTH}>Número cuota</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <View style={styles.tableCellCol1}>
                                                <Text style={styles.tableTH}>Valor cuota</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <View style={styles.tableCellCol1}>
                                                <Text style={styles.tableTH}>Abono a capital</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <View style={styles.tableCellCol1}>
                                                <Text style={styles.tableTH}>Interés</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <View style={styles.tableCellCol1}>
                                                <Text style={styles.tableTH}>Saldo</Text>
                                            </View>
                                        </View>
                                    </View>
                                    {paymentsPlanValues[index].map((plan, index) => (
                                        <View style={styles.tableRow} key={index}>
                                            <View style={styles.tableCol}>
                                                <View style={styles.tableCellCol1}>
                                                    <Text style={styles.tableText}>
                                                        {plan.Cuota}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <View style={styles.tableCellCol1}>
                                                    <Text style={styles.tableText}>
                                                        {formatter(plan.ValorCuota)}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <View style={styles.tableCellCol1}>
                                                    <Text style={styles.tableText}>
                                                        {formatter(plan.AbonoCapital)}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <View style={styles.tableCellCol1}>
                                                    <Text style={styles.tableText}>
                                                        {formatter(plan.Interes)}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <View style={styles.tableCellCol1}>
                                                    <Text style={styles.tableText}>
                                                        {formatter(plan.Saldo)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    )
}

export default PDFPage
