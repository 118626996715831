import tw, { styled } from 'twin.macro'

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`
export const Title1 = styled.p`
    ${tw`mx-0 font-montserrat font-bold text-black text-right`}
    cursor: pointer;
`
export const Title2 = styled.p`
    ${tw`mx-0 font-montserrat text-black text-right`}
    cursor: pointer;
`
