import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const ContainerACH = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const InputGroupText = styled.input`
    ${tw`font-montserrat text-base text-black`}
    border: 2px solid var(--place-holder-color);
    outline: none;
    padding: 5px;
    margin: 5px;
    width: 100%;
    color: #333; /* Color de texto opcional */
    border-radius: 0.375rem; /* Añade un borde redondeado */
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05); /* Agrega una sombra suave */
`

export const OptionsContainerDetail = styled.div`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] h-[980px] w-[100%]`}
    h1,
    p {
        font-family: Lato;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        position: relative;
    }

    thead tr th {
        font-weight: 500;
        text-align: left;
        background-color: #fafafa;
    }

    tbody tr:nth-child(even) {
        background-color: #fafafa;
    }

    th,
    td {
        padding: 8px;
        overflow-wrap: break-word;
    }

    .pagination-bar {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`

export const ContainerFilters = styled.div`
    ${tw`rounded-[10px] shadow-[0px 3px 6px var(--shadow-color)] h-[240px] w-[100%]`}
    h1,
    p {
        font-family: Lato;
        margin-left: 10px;
        font-size: 18px;
    }
    tbody tr:nth-child(even) {
        background-color: #fafafa;
    }
    td {
        margin-right: 5px;
        padding: 8px;
        overflow-wrap: break-word;
    }
    .text {
        ${tw`lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
    }
    @media (max-width: 600px) {
        ${tw`h-[100%] w-[100%]`}
        tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
`

export const PaginationContent = styled.section`
    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        color: var(--button-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: #000000 !important;
        background: none !important;
        font-weight: 500;
    }

    .pagination {
        justify-content: center;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #000000;
        /* background-color: #0d6efd; */
        border-bottom-color: var(--dominant-color-dark);
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        /* border: 4px; */
        font-weight: 700;
    }
`

export const ButtonPagination = styled.div`
    ${tw`w-[200px] h-[40px] justify-center items-center text-center rounded-[10px] font-normal font-montserrat`}

    color: var(--dominant-color-dark) !important;
    width: auto !important;

    .button-pagination {
        color: var(--dominant-color-dark) !important;
    }

    li {
        list-style: none !important;
    }

    li.page-item.disableFm.disabled {
        display: none !important;
    }

    span.page-link {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    a {
        border: none !important;
        color: var(--dominant-color-dark) !important;
        background: none !important;
        font-weight: bold;
    }

    li.page-item.active {
        background-color: var(--dominant-color-dark) !important;
        color: #fff;
    }

    .page-link:focus {
        z-index: 3;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }

    .page-link:hover {
        z-index: 2;
        color: #fff !important;
        background-color: var(--dominant-color-dark) !important;
        outline: 0;
        box-shadow: 0 0 0 0.25rem var(--dominant-color-dark);
        border-radius: 50%;
    }
`

export const RefButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[250px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`w-[300px]`}
    }
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ContainerTableACH = styled.table`
    ${tw`flex overflow-x-scroll`}
    thead {
        background: var(--header-botton-color);
        color: var(--header-color);
        text-align: center;
    }
    .tableACH {
        margin-left: 20px;
        font-size: 15px;
        width: 96%;
    }
    .th {
        ${tw`lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
    }
`
export const Image = styled.img`
    width: 20%;
`

export const TextPBoldD = styled.p`
    margin-right: 925px;
`

export const TextPBold = styled.p`
    ${tw`text-base justify-between`}
    padding: 0;
    margin: auto;

    span {
        ${tw`text-base w-full`}
        font-weight: 600;
        color: var(---text-opacity10);
    }
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 250px) and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`
export const ContentButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;

    @media (min-width: 250px) and (max-width: 500px) {
        justify-content: center;
    }
`
