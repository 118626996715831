import { internationalCardService } from '../../../../../domain/services/User.service'

export async function useValidateCardNumber(cardNumber: string, tokenSave: string) {
    try {
        return await internationalCardService
            .validateCardNumber(
                'api/CreditCardProcess/CardDetailsByCardNumber/CardDetailsByCardNumber',
                tokenSave,
                cardNumber
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useValidateDate(date: string, tokenSave: string) {
    try {
        return await internationalCardService
            .validateDate(
                `api/CreditCardProcess/ValidateDates/ValidateDates?departureDate=${date}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useActivateInternationalCard(tokenSave: string, data: any) {
    try {
        return await internationalCardService
            .activateCard('api/CreditCardProcess/TravelMemo/TravelMemo', tokenSave, data)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
