import tw, { styled } from 'twin.macro'

export const Container = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`

export const Wrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const Content = styled.div`
    ${tw`p-5 lg:py-8 px-8 w-full max-w-[600px] lg:max-w-[640px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export const TitleComponent = styled.span`
    ${tw`mt-5 py-2 px-1 pl-1 text-[16px] font-montserrat text-dominant-dark left-0`}
`

export const ContainerSimulator = styled.section`
    ${tw`mt-15 py-[15px] px-0`}
    ${tw`lg:pt-0 lg:pb-5 lg:px-3 lg:min-h-[600px]`}
`
export const SpaceStep = styled.div`
    ${tw`w-full h-6`}
`
export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 p-0 justify-center items-center`}
    ${tw`lg:flex-row lg:py-6 lg:px-1`}
    justify-content: space-between !important;
`
export const ContainerSimulator1 = styled.section`
    /* ${tw`flex justify-center h-full rounded-[10px] shadow-[0px 10px 6px #00000029] `}
    @media (max-width: 500px) {
        ${tw`w-[100%]`}
    } */
    ${tw`w-full relative`}
    ${tw`lg:max-w-[1100px] rounded-[10px] shadow-[0px 3px 6px #00000029] h-full`}
    ${tw`flex gap-1 flex-col`}

    @media (max-width: 600px) {
        ${tw`!w-[100%]`}
    }
`
