import { useState } from 'react'
import { useSelector } from 'react-redux'

// components
import {
    Form,
    FormControl,
    FormMessage,
    InputGroupText,
    InputGroup,
    InputSelectGroup,
    Button,
    Modal,
    Loading,
} from '../../../../components'

// Icons
import { MoneySVG, TimeSVG } from '../../../../utils/getIcons'

import {
    FormGroup,
    FormLabel,
    FormOption,
    FormSelect,
    SpaceStep,
    Image,
    ButtonContent,
    ButtonsContainer,
} from './FormTerm-styles'

import {
    FirstStepContent,
    FirstStepWrapper,
} from '../../../CampaignCard/inc/FirstStep/firstStep-styles'
import { useServiceTerm } from '../../hooks/useDataList'
import { ModalBody } from 'react-bootstrap'
import ModalInfo from '../../../CreditDetail/inc/ModalInfo/ModalInfo'

interface DynamicKeyContainerProps {
    onNextStep: (
        selectedPagoIntereses: string,
        plazoValue: string,
        cuotaValue: string,
        RateEM: string,
        TotalSaving: string,
        Retention: string
    ) => void
}

const FormTerm: React.FC<DynamicKeyContainerProps> = ({ onNextStep }): JSX.Element => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    /* const [cuotasA, setCuotas] = useState([]) */
    const [selectedPagoIntereses, setSelectedPagoIntereses] = useState('')
    const [cuotaValue, setCuotaValue] = useState('')
    const [plazoValue, setPlazoValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [cuotaError, setCuotaError] = useState('')
    /*    useEffect(() => {
        GetSecurity()
    }, []) */

    const handleNext = () => {
        HandleFormSubmit()
        setIsLoading(true)
    }

    const handlePagoInteresesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        setSelectedPagoIntereses(value)
        console.log(value)
    }

    const handleCuotaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        // Validar la entrada: permitir solo números y limitar a 13 caracteres
        const isValidInput = /^\d{0,13}$/.test(value)

        if (!value.trim()) {
            setCuotaError('La cuota no puede estar vacía.')
        } else if (!isValidInput) {
            setCuotaError('El valor de la cuota a simular no es válido')
        } else {
            setCuotaError('')
        }

        setCuotaValue(value)
        console.log('esta es la cuota', value)
    }

    const handlePlazoChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setPlazoValue(e.target.value)
        console.log('este es el plazo', e.target.value)
    }

    const HandleFormSubmit = (): void => {
        setIsLoading(true)

        useServiceTerm(tokenSave, selectedPagoIntereses, plazoValue, cuotaValue)
            .then((res: any) => {
                if (res['Response'] === '200') {
                    const RateEM = res['Data']['RateEM']
                    const TotalSaving = res['Data']['TotalSaving']
                    const Retention = res['Data']['Retention']
                    // Formatear a pesos colombianos solo para mostrar en la interfaz de usuario
                    const formattedTotalSaving = new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        maximumFractionDigits: 0,
                    }).format(TotalSaving)

                    const formattedRetention = new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        maximumFractionDigits: 0,
                    }).format(Retention)

                    // Formatear cuotaValue
                    const cuotaValueAsNumber = Number(cuotaValue)
                    const formattedCuotaValue = new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        maximumFractionDigits: 0,
                    }).format(cuotaValueAsNumber)

                    onNextStep(
                        selectedPagoIntereses,
                        plazoValue,
                        formattedCuotaValue,
                        RateEM,
                        formattedTotalSaving,
                        formattedRetention
                    )
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal('Intente de nuevo')
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se pudo generar la simulación, intente de nuevo')
            })
    }

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
    }
    const cuotasA = [
        { Id: 1, InstallmentsCount: '6' },
        { Id: 2, InstallmentsCount: '12' },
        { Id: 3, InstallmentsCount: '18' },
        { Id: 4, InstallmentsCount: '24' },
        { Id: 5, InstallmentsCount: '36' },
    ]

    return (
        <>
            <FirstStepWrapper>
                <FirstStepContent>
                    <Form>
                        <FormGroup>
                            <FormLabel>Pago de intereses</FormLabel>
                            <InputSelectGroup>
                                {/* <Image src={MiniRingSVG} alt="clave" /> */}
                                <Image src={TimeSVG} alt="icono" />
                                <FormSelect
                                    size="lg"
                                    value={selectedPagoIntereses}
                                    onChange={handlePagoInteresesChange}
                                >
                                    <FormOption disabled value="">
                                        Selecciona una opción
                                    </FormOption>
                                    <FormOption value="12">Mensual</FormOption>
                                    <FormOption value="24">Quincenal</FormOption>
                                </FormSelect>
                            </InputSelectGroup>
                        </FormGroup>
                        <SpaceStep />
                        <FormGroup>
                            <FormLabel style={{ textAlign: 'center' }}>Cuota</FormLabel>
                            <InputGroup hasValidation>
                                <InputGroupText>
                                    {/* <Image src={PickerMapSVG} alt="icono" /> */}
                                    <Image src={MoneySVG} alt="icono" />
                                </InputGroupText>
                                <FormControl
                                    placeholder="Escribe el valor de la cuota"
                                    onChange={handleCuotaChange}
                                    isInvalid={cuotaError !== ''}
                                    maxLength={13}
                                />
                                {cuotaError && (
                                    <FormMessage type="invalid">{cuotaError}</FormMessage>
                                )}
                            </InputGroup>
                        </FormGroup>

                        <SpaceStep />
                        <FormLabel style={{ textAlign: 'center' }}>Plazo(meses)</FormLabel>
                        <FormGroup>
                            <InputSelectGroup>
                                <Image src={TimeSVG} alt="clave" />
                                <FormSelect
                                    size="lg"
                                    value={plazoValue}
                                    onChange={handlePlazoChange}
                                >
                                    <FormOption disabled value="">
                                        Selecciona una opción
                                    </FormOption>
                                    {cuotasA.map((cuota: any) => (
                                        <FormOption key={cuota.Id} value={cuota.InstallmentsCount}>
                                            {cuota.InstallmentsCount}
                                        </FormOption>
                                    ))}
                                </FormSelect>
                            </InputSelectGroup>
                        </FormGroup>
                    </Form>
                </FirstStepContent>
            </FirstStepWrapper>
            <ButtonsContainer>
                <ButtonContent>
                    <Button
                        id="btnSubmit"
                        className="button-block"
                        variant="sub-dominant"
                        type="button"
                        onClick={handleNext}
                        isLoading={isLoading}
                        extend
                        disabled={isLoading}
                    >
                        Calcular
                    </Button>
                </ButtonContent>
            </ButtonsContainer>
            <Modal
                className="loading-screen"
                show={isLoading}
                backdrop="static"
                keyboard={false}
                centered
            >
                <ModalBody>
                    <Loading text="Estamos generando la simulación" />
                    <p style={{ textAlign: 'center' }}>
                        Espera, no actualices o cierres esta página
                    </p>
                </ModalBody>
            </Modal>
            <ModalInfo
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleModalCloseInfo}
            />
        </>
    )
}

export default FormTerm
