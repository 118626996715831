import { AppDispatch } from '../store/store'
import { LOGGED_USER_ROLES } from './rolUsers.types'
import { roleUserServices } from '../../../domain/services/rolUsers'

export const getLoggedInUserRoleAction = () => (dispatch: AppDispatch) => {
    dispatch(
        roleUserServices.getLoggedInUserRole([
            LOGGED_USER_ROLES.REQUEST,
            LOGGED_USER_ROLES.SUCCESS,
            LOGGED_USER_ROLES.FAILURE,
        ])()
    )
}

export const loggedInUserRoleAction =
    (
        onSuccess?: (response: {
            data: string
            message: string | null
            response: string | null
        }) => void,
        onError?: (e: unknown) => void
    ) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await dispatch(
                roleUserServices.getLoggedInUserRole([
                    LOGGED_USER_ROLES.REQUEST,
                    LOGGED_USER_ROLES.SUCCESS,
                    LOGGED_USER_ROLES.FAILURE,
                ])()
            )

            if (onSuccess) {
                onSuccess(
                    response as {
                        data: string
                        message: string | null
                        response: string | null
                    }
                )
            }
        } catch (error) {
            if (onError) {
                onError(error)
            }
        }
    }
