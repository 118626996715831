import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CardStates } from '../../../../../../domain/models'
import {
    useSelector,
    portfolioPurchaseProcessSelector,
    featureEnableFunctionsSelector,
} from '../../../../../selectors'
import {
    IconAssignSVG,
    IconBuyPortfolioSVG,
    IconChangePassSVG,
    IconFileCertificateSVG,
    IconLockLightSVG,
    IconLockOpenSVG,
    IconLockSVG,
    IconReferQuotasSVG,
} from '../../../../utils/getIcons'
import { CardOptionsContainer, OptionButton } from './cardOptions-styles'
import BlockCard from '../../../CreditDetail/inc/BlockCard/BlockCard'
import UnBlockCard from '../../../CreditDetail/inc/UnBlockCard/UnBlockCard'
import BlockCardTheftModal from '../../../CreditDetail/inc/BlockCardTheftModal/BlockCardTheftModal'
import { parseStringToBoolean } from '../../../../utils/misc'

const enablePortfolioPurchase = process.env.REACT_APP_PORTFOLIO_PURCHASE_ENABLE as 'false' | 'true'

const enableOnOffFunctionTcRedeemQuotas = process.env.REACT_APP_ON_OFF_FUNCTION_TC_REDEEM_QUOTAS as
    | 'false'
    | 'true'
const enableOnOffFunctionAssignProgressKey = process.env
    .REACT_APP_ON_OFF_FUNCTION_ASSIGN_PROGRESS_KEY as 'false' | 'true'
const OnOffFunctionKeyChangeForward = process.env.REACT_APP_ON_OFF_FUNCTION_KEY_CHANGE_FORWARD as
    | 'false'
    | 'true'
const enableOnOffFunctionTheftLock = process.env.REACT_APP_ON_OFF_FUNCTION_THEFT_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionTemporaryLock = process.env.REACT_APP_ON_OFF_FUNCTION_TEMPORARY_LOCK as
    | 'false'
    | 'true'
const enableOnOffFunctionUnLock = process.env.REACT_APP_ON_OFF_FUNCTION_UNLOCK as 'false' | 'true'
const enableOnOffFunctionProductCertificate = process.env
    .REACT_APP_ON_OFF_FUNCTION_PRODUCT_CERTIFICATE as 'false' | 'true'

interface CardOptionsProps {
    stateCard: string
    quotaAvailable: number
    lastsFourNumCard?: string
    dateExpiredCard?: string
    cardNumber?: string
    lastFourDigitsEncryptedCC?: string
    lastsFourEncrypted: string
}

const CardOptions: React.FC<CardOptionsProps> = ({
    stateCard,
    quotaAvailable,
    lastsFourNumCard,
    dateExpiredCard,
    cardNumber,
    lastFourDigitsEncryptedCC,
    lastsFourEncrypted,
}): JSX.Element => {
    const history = useHistory()
    const claveAvances = true
    const { portfolioPurchaseProcess } = useSelector(portfolioPurchaseProcessSelector)
    const [showModalTheft, setModalTheft] = useState(false)

    const handleClose = (): void => {
        setModalTheft(false)
    }

    const handleShow = (): void => {
        setModalTheft(true)
    }

    // events handlers BlockCardTemp
    const [showModalLockTemp, setShowModalLockTemp] = useState(false)
    const handleCloseModalLockTemp = (): void => {
        setShowModalLockTemp(false)
    }

    const handleShowLockTemp = (): void => {
        setShowModalLockTemp(true)
    }

    // events handlers UnBlockCardTemp
    const [showModalUnLock, setShowModalUnLockTemp] = useState(false)
    const handleCloseModalULockTemp = (): void => {
        setShowModalUnLockTemp(false)
    }

    const handleShowUnLockTemp = (): void => {
        setShowModalUnLockTemp(true)
    }

    // realiza el llamado de la función para obtener el objeto del servicio apagado de funcionalidades
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const [enableFuntions, setEnableFuntions] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (functionalityQuery.length > 0) {
            const enableFuntionsObj: Record<string, boolean> = {}
            functionalityQuery.forEach((func) => {
                // Asigna el estado de cada funcionalidad al objeto utilizando su nombre como clave.
                enableFuntionsObj[func.name] = func.value === 'true'
            })
            setEnableFuntions(enableFuntionsObj)
        }
    }, [functionalityQuery])

    return (
        <CardOptionsContainer data-tour="detail-tc-options">
            {parseStringToBoolean(enableOnOffFunctionProductCertificate) &&
                enableFuntions.OnOffFunctionProductCertificate && (
                    <OptionButton
                        onClick={() =>
                            history.push(`/Certificate-pdf?id=${lastFourDigitsEncryptedCC}`)
                        }
                    >
                        <div>
                            <img className="small" src={IconFileCertificateSVG} alt="" />
                        </div>
                        <h3>Certificado</h3>
                    </OptionButton>
                )}
            {stateCard !== CardStates.BLOQUEO_PERDIDA_O_HURTO && (
                <>
                    {parseStringToBoolean(enablePortfolioPurchase) && (
                        <OptionButton
                            isDisable={
                                ![
                                    CardStates.ACTIVA.toString(),
                                    CardStates.EN_MORA.toString(),
                                ].includes(stateCard) ||
                                (!!portfolioPurchaseProcess &&
                                    portfolioPurchaseProcess.length > 0 &&
                                    portfolioPurchaseProcess.some(
                                        (e) => e.isCompletedInCore === true
                                    ))
                            }
                            onClick={() => {
                                if (
                                    stateCard === CardStates.ACTIVA &&
                                    !(
                                        !!portfolioPurchaseProcess &&
                                        portfolioPurchaseProcess.length > 0 &&
                                        portfolioPurchaseProcess.some(
                                            (e) => e.isCompletedInCore === true
                                        )
                                    )
                                ) {
                                    history.push(`/portfolio-purchase`, {
                                        lastFrom: 'CCD',
                                        quotaAvailable,
                                    })
                                }
                            }}
                        >
                            <div>
                                <img className="normal" src={IconBuyPortfolioSVG} alt="" />
                            </div>
                            <h3>Comprar cartera</h3>
                        </OptionButton>
                    )}
                    {parseStringToBoolean(enableOnOffFunctionTcRedeemQuotas) &&
                        enableFuntions.OnOffFunctionTcRedeemQuotas && (
                            <OptionButton
                                isDisable={
                                    ![
                                        CardStates.ACTIVA.toString(),
                                        CardStates.EN_MORA.toString(),
                                    ].includes(stateCard)
                                }
                                onClick={() => {
                                    if (stateCard === CardStates.ACTIVA) {
                                        history.push(
                                            `/card-redirect?id=${lastFourDigitsEncryptedCC}`
                                        )
                                    }
                                }}
                            >
                                <div>
                                    <img className="normal" src={IconReferQuotasSVG} alt="" />
                                </div>
                                <h3>Rediferir cuotas</h3>
                            </OptionButton>
                        )}
                    {parseStringToBoolean(enableOnOffFunctionAssignProgressKey) &&
                        enableFuntions.OnOffFunctionAssignProgressKey && (
                            <>
                                {claveAvances && (
                                    <OptionButton
                                        isDisable={
                                            ![
                                                CardStates.ACTIVA.toString(),
                                                CardStates.EN_MORA.toString(),
                                            ].includes(stateCard)
                                        }
                                        onClick={() => {
                                            if (stateCard === CardStates.ACTIVA) {
                                                history.push(
                                                    `/advance-key-steps?id=${lastFourDigitsEncryptedCC}`
                                                )
                                            }
                                        }}
                                    >
                                        <div>
                                            <img className="normal" src={IconAssignSVG} alt="" />
                                        </div>
                                        <h3>Asignar clave avances</h3>
                                    </OptionButton>
                                )}{' '}
                            </>
                        )}
                    {parseStringToBoolean(OnOffFunctionKeyChangeForward) &&
                        enableFuntions.OnOffFunctionKeyChangeForward && (
                            <>
                                {claveAvances && (
                                    <OptionButton
                                        isDisable={
                                            ![
                                                CardStates.ACTIVA.toString(),
                                                CardStates.EN_MORA.toString(),
                                            ].includes(stateCard)
                                        }
                                        onClick={() => {
                                            if (stateCard === CardStates.ACTIVA) {
                                                history.push(
                                                    `/change-key-advances?id=${lastFourDigitsEncryptedCC}`
                                                )
                                            }
                                        }}
                                    >
                                        <div>
                                            <img
                                                className="normal"
                                                src={IconChangePassSVG}
                                                alt=""
                                            />
                                        </div>
                                        <h3>Cambiar clave avances</h3>
                                    </OptionButton>
                                )}
                            </>
                        )}
                    {parseStringToBoolean(enableOnOffFunctionTemporaryLock) &&
                        enableFuntions.OnOffFunctionTemporaryLock && (
                            <>
                                {stateCard !== CardStates.BLOQUEO_TEMPORAL && (
                                    <OptionButton
                                        isDisable={
                                            stateCard === CardStates.BLOQUEO_TEMPORAL_POR_MORA
                                        }
                                    >
                                        <div onClick={handleShowLockTemp}>
                                            <img className="small" src={IconLockLightSVG} alt="" />
                                        </div>
                                        <h3>Bloquear temporalmente</h3>
                                        <BlockCard
                                            showModalLockTemp={showModalLockTemp}
                                            handleCloseModalLockTemp={handleCloseModalLockTemp}
                                            lastsFourNumCard={lastsFourNumCard}
                                            dateExpiredCard={dateExpiredCard}
                                            cardNumber={cardNumber}
                                        />
                                    </OptionButton>
                                )}
                            </>
                        )}
                    {parseStringToBoolean(enableOnOffFunctionUnLock) &&
                        enableFuntions.OnOffFunctionUnLock && (
                            <>
                                {stateCard === CardStates.BLOQUEO_TEMPORAL && (
                                    <OptionButton>
                                        <div onClick={handleShowUnLockTemp}>
                                            <img className="normal" src={IconLockOpenSVG} alt="" />
                                        </div>
                                        <h3>Desbloquear</h3>
                                        <UnBlockCard
                                            showModalUnLock={showModalUnLock}
                                            handleCloseModalULockTemp={handleCloseModalULockTemp}
                                            lastsFourNumCard={lastsFourNumCard}
                                            dateExpiredCard={dateExpiredCard}
                                            cardNumber={cardNumber}
                                        />
                                    </OptionButton>
                                )}
                            </>
                        )}
                    {parseStringToBoolean(enableOnOffFunctionTheftLock) &&
                        enableFuntions.OnOffFunctionTheftLock && (
                            <>
                                <OptionButton onClick={handleShow}>
                                    <div>
                                        <img className="small" src={IconLockSVG} alt="" />
                                    </div>
                                    <h3>Bloqueo por hurto</h3>
                                </OptionButton>
                                <BlockCardTheftModal
                                    showModal={showModalTheft}
                                    handleClose={handleClose}
                                    lastsFourEncrypted={lastsFourEncrypted}
                                />
                            </>
                        )}
                </>
            )}
        </CardOptionsContainer>
    )
}
export default CardOptions
