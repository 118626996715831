import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const Content = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[100%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`
export const ContentInfo1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 460px) {
        width: 350px;
    }

    @media (max-width: 350px) {
        width: 200px;
    }
`
export const Image = styled.img`
    width: 100%;
`
export const ImageCash = styled.img`
    width: 15%;
`
export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`
export const TextPBold = styled.p`
    ${tw`text-base justify-between`}
    padding: 0;
    margin: 60px;

    span {
        ${tw`text-base w-full`}
        font-weight: 600;
        color: var(---text-opacity10);
    }
    @media (max-width: 460px) {
        ${tw`text-base justify-between`}
        padding: 0;
        margin: 0;
        margin-left: 100px;
        margin-top: 60px;
        font-size: 10px;
    }
`

export const TextPCash = styled.p`
    ${tw`text-base justify-between`}
    padding: 0;
    margin-left: 30%;

    span {
        ${tw`text-base w-full`}
        font-weight: 600;
        color: var(---text-opacity10);
    }
    @media (max-width: 460px) {
        padding: 0;
        font-size: 13px;
        margin-top: 60px;
    }
`

export const ButtonsContainer = styled.div`
    ${tw`justify-end gap-1 p-0`}
    ${tw`lg:py-0 lg:px-1`}
`

export const ButtonHome = styled(Button)`
    ${tw`my-0.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: var(--shadow-color) !important;
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;
`
