import { AxiosResponse } from 'axios'
import { userManagement } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useGetRoles(tokenSave: string): Promise<Result> {
    try {
        return await userManagement.get('api/RolUsers/GetRols', tokenSave).then((response: any) => {
            return response
        })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetUsers(tokenSave: string): Promise<Result> {
    try {
        return await userManagement
            .get('api/RolUsers/GetUsers', tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useUpdateUserRole(
    tokenSave: string,
    document: string,
    roleId: number
): Promise<Result> {
    try {
        return await userManagement
            .put(`/api/RolUsers/UpdateUserRole?DocNumber=${document}&roleId=${roleId}`, tokenSave)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
