import { FC } from 'react'
// styles
import {
    PpalTitle,
    PreviousBalanceText,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
} from '../../BalancesExtracts-styles'

type ConceptsType = {
    Concepto: string
    DocumentoNumero: number
    DocumentoTipo: string
    NumeroTermino: number
    Saldo: number
}

interface MovementsProps {
    finalBalanceData: ConceptsType[]
    previousBalance: number
    totalBalance: number
    formatter: (value: number) => string
}

const FinalBalance: FC<MovementsProps> = ({
    finalBalanceData,
    previousBalance,
    totalBalance,
    formatter,
}): JSX.Element => {
    return (
        <>
            <PpalTitle>
                <span>Información Saldo</span>
            </PpalTitle>
            <PreviousBalanceText>Saldo anterior {formatter(previousBalance)}</PreviousBalanceText>
            <TableContent>
                <TableContainer>
                    <THead>
                        <RowTableTitle>
                            <th>Saldo final</th>
                            <th>{formatter(totalBalance)}</th>
                        </RowTableTitle>
                    </THead>
                    <TBody>
                        {finalBalanceData.map((finalData) => (
                            <RowTable>
                                <td>{finalData.Concepto}</td>
                                <td style={{ textAlign: 'right', paddingRight: '30px' }}>
                                    {formatter(finalData.Saldo)}
                                </td>
                            </RowTable>
                        ))}
                    </TBody>
                </TableContainer>
            </TableContent>
        </>
    )
}

export default FinalBalance
