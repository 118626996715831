import { FC } from 'react'
// styles
import {
    PpalTitle,
    RowTable,
    RowTableTitle,
    TBody,
    THead,
    TableContainer,
    TableContent,
    TextDecorationTable,
} from '../../BalancesExtracts-styles'

type Products = {
    Description: string
    ProductDocumentType: string
    Concept: string
    ProductDocumentNumber: number
    FinishedNumber: number
    Acount: string
    Balance: number
}

type SavingDataType = {
    Description: string
    TotalBalance: number
    ProductDocumentNumber: number
    Products: Products[]
}

interface SavingsProps {
    savingData: SavingDataType[]
    formatter: (value: number) => string
    showSavingDetail: (products: Products[], description: string) => void
}

const SavingsTable: FC<SavingsProps> = ({
    savingData,
    formatter,
    showSavingDetail,
}): JSX.Element => {
    const handleClick = (products: Products[], description: string) => {
        showSavingDetail(products, description)
    }

    // Agrupar productos por ProductDocumentNumber y sumar sus Balance
    const groupedProducts = savingData
        .flatMap((saving) => saving.Products)
        .reduce((acc, product) => {
            const { ProductDocumentNumber, Description, Balance } = product

            if (!acc[ProductDocumentNumber]) {
                acc[ProductDocumentNumber] = {
                    Description,
                    ProductDocumentNumber,
                    Balance: 0, // Inicializa el balance acumulado en 0
                    Products: [], // Lista para almacenar los productos originales
                }
            }

            acc[ProductDocumentNumber].Balance += Balance
            acc[ProductDocumentNumber].Products.push(product)

            return acc
        }, {} as Record<number, { Description: string; ProductDocumentNumber: number; Balance: number; Products: Products[] }>)

    // Convertir el objeto agrupado a un array
    const groupedProductsArray = Object.values(groupedProducts)

    return (
        <TableContent>
            <PpalTitle>Ahorros</PpalTitle>
            <TableContainer>
                <THead>
                    <RowTableTitle>
                        <th>Producto</th>
                        <th>Número</th>
                        <th>Saldo</th>
                    </RowTableTitle>
                </THead>
                <TBody>
                    {groupedProductsArray.map((group, index) => (
                        <RowTable key={index}>
                            <TextDecorationTable>
                                <a onClick={() => handleClick(group.Products, group.Description)}>
                                    {group.Description}
                                </a>
                            </TextDecorationTable>
                            <td>{group.ProductDocumentNumber}</td>
                            <td>{formatter(group.Balance)}</td>
                        </RowTable>
                    ))}
                </TBody>
            </TableContainer>
        </TableContent>
    )
}

export default SavingsTable
