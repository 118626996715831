import { blockCardService } from '../../../../../domain/services/User.service'

export async function useStatusTC(dataToSend: any, tokenSave: string) {
    try {
        const formData = new FormData()
        formData.append('body', JSON.stringify(dataToSend))

        const sendData = dataToSend.get('body')
        const dataToSendService = sendData

        return await blockCardService
            .postTransactionStatusTC(
                '/api/CreditCardProcess/TemplateCert/TemplateCert',
                tokenSave,
                dataToSendService
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
