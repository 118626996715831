import { useState } from 'react'

import {
    InputGroupText,
    ContainerFilters,
    PaginationContent,
    ButtonPagination,
    ContainerTableUser,
    TextPBold,
    ContentInfo,
    Image,
    TextP,
} from './UpdateRolUser-styles'
import { Col, Row, Pagination, PageItem } from 'react-bootstrap'
import { ButtonUnstyled, ContainerTitle, Title } from '../../AssignRoles-styles'
import { NotProductSVG, Edit } from '../../../../utils/getIcons'

interface UpdateRolUserProps {
    usersData: any
    rolName: string
    userSelect: (idRol: number, documentUser: string, rolName: string) => void
}

const UpdateRolUser: React.FC<UpdateRolUserProps> = ({ usersData, rolName, userSelect }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }

    const [filters, setFilters] = useState<{ [key: string]: string }>({
        FullName: '',
        DocNumber: '',
        UserName: '',
        RoleName: '',
    })

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCurrentPage(1)
        const { name, value } = e.target
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
    }

    const filteredData = usersData.filter((infoUpdateRolUser: any) =>
        Object.keys(filters).every(
            (key) =>
                !filters[key] ||
                String(infoUpdateRolUser[key]).toLowerCase().includes(filters[key].toLowerCase())
        )
    )
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const assignRolUser = (idRol: number, documentUser: string, rolName: string): void => {
        userSelect(idRol, documentUser, rolName)
    }

    return (
        <>
            {usersData.length !== 0 ? (
                <>
                    <ContainerTitle>
                        <Title>{rolName}</Title>
                        <TextP>Usuarios asignados al rol</TextP>
                    </ContainerTitle>
                    <ContainerFilters>
                        <Row className="row-transaction">
                            <Col md={3}>
                                <p className="text">Nombre Completo</p>
                                <InputGroupText
                                    type="text"
                                    name="FullName"
                                    value={filters.FullName}
                                    onChange={handleFilterChange}
                                />
                            </Col>
                            <Col md={2}>
                                <p className="text">Cédula</p>
                                <InputGroupText
                                    type="number"
                                    name="DocNumber"
                                    value={filters.DocNumber}
                                    onChange={handleFilterChange}
                                />
                            </Col>
                            <Col md={2}>
                                <p className="text">Usuario</p>
                                <InputGroupText
                                    type="text"
                                    name="UserName"
                                    value={filters.UserName}
                                    onChange={handleFilterChange}
                                />
                            </Col>
                        </Row>
                    </ContainerFilters>

                    <ContainerTableUser striped bordered responsive>
                        <thead>
                            <tr>
                                <th>Nombre Completo</th>
                                <th>Cédula</th>
                                <th>Usuario</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((infoUpdateRolUser: any) => (
                                <tr key={infoUpdateRolUser.DocNumber}>
                                    <td>{infoUpdateRolUser.FullName}</td>
                                    <td>{infoUpdateRolUser.DocNumber}</td>
                                    <td>{infoUpdateRolUser.UserName}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <ButtonUnstyled
                                            variant="primary"
                                            onClick={() =>
                                                assignRolUser(
                                                    infoUpdateRolUser.RoleId,
                                                    infoUpdateRolUser.DocNumber,
                                                    infoUpdateRolUser.RoleName
                                                )
                                            }
                                        >
                                            <img src={Edit} alt="IconEdit" width={25} />
                                        </ButtonUnstyled>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ContainerTableUser>

                    <PaginationContent>
                        <Pagination>
                            {currentPage !== 1 && (
                                <ButtonPagination>
                                    <PageItem
                                        onClick={() => handlePageClick(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        {'<'}
                                    </PageItem>
                                </ButtonPagination>
                            )}
                            {[...Array(totalPages)].map((_, index) => (
                                <PageItem
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageClick(index + 1)}
                                >
                                    {index + 1}
                                </PageItem>
                            ))}
                            {currentPage !== totalPages && totalPages > 1 && (
                                <ButtonPagination>
                                    <PageItem
                                        onClick={() => handlePageClick(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        {'>'}
                                    </PageItem>
                                </ButtonPagination>
                            )}
                        </Pagination>
                    </PaginationContent>
                </>
            ) : (
                <ContentInfo>
                    <div style={{ display: 'flex', margin: '40px' }}>
                        <Image src={NotProductSVG} alt="icono" />
                        <TextPBold>
                            <span>No hay usuarios disponibles para este rol</span>
                        </TextPBold>
                    </div>
                </ContentInfo>
            )}
        </>
    )
}

export default UpdateRolUser
