/* eslint-disable prettier/prettier */
import MoraCard from '../MoraCard'

import { Credit1SVG, CreditCardFlowSVG } from '../../../../utils/getIcons'
import { DelinquentProductShowcaseContent, ExceptionCtr, ExceptionImg, ExceptionParagraph, ExceptionTitle } from './DelinquentProductShowcase-Styled'
import { Wrapper } from '../../AdvanceToFAIAccount-Styled'

interface DelinquentProductShowcaseProps {
    delinquentBalanceCredit: number
    delinquentBalanceSaving: number
    delinquentBalanceTC: number
}
const DelinquentProductShowcase: React.FC<DelinquentProductShowcaseProps> = ({
    delinquentBalanceCredit,
    delinquentBalanceSaving,
    delinquentBalanceTC,
}) => {
    return (
        <Wrapper>
            <DelinquentProductShowcaseContent>
                <ExceptionCtr>
                    <ExceptionImg src={CreditCardFlowSVG} alt="Logo" />

                    <ExceptionTitle>Estás en Mora con alguno de tus productos de Cavipetrol</ExceptionTitle>
                    <ExceptionParagraph >Debes estar al día en tus productos para solicitar el avance a tu cuenta FAI.</ExceptionParagraph>

                    {delinquentBalanceSaving > 0 && (
                        <MoraCard
                            image={Credit1SVG}
                            title="Saldo en mora en ahorros"
                            value={delinquentBalanceSaving.toString()}
                            url="/savings-group"
                            type="saving"
                        />)}
                    {delinquentBalanceCredit > 0 && (
                        <MoraCard
                            image={Credit1SVG}
                            title="Saldo en mora en créditos"
                            value={delinquentBalanceCredit.toString()}
                            url="/credits-group"
                            type="credit"
                        />)}
                    {delinquentBalanceTC > 0 && (
                        <MoraCard
                            image={Credit1SVG}
                            title="Saldo en mora en tarjeta de crédito"
                            value={delinquentBalanceTC.toString()}
                            url="/home-wallet"
                            type="tc"
                        />)}
                </ExceptionCtr>
            </DelinquentProductShowcaseContent >
        </Wrapper>
    )
}

export default DelinquentProductShowcase
