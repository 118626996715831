import { useEffect, useState } from 'react'
import {
    Wrapper,
    Content,
    TextSteps,
    TextSubtitle,
    TextInfo,
    CancelButton,
    ButtonsContainer,
    ButtonContent,
} from './ThirdStep-Styled'
import { useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import { useSendOTP } from '../../../CreditDetail/hooks/useCreditDetail'
import { Button, Loading, ModalGeneric } from '../../../../components'
import { NotProductSVG } from '../../../../utils/getIcons'
import DynamicKeyInput from '../DynamicKeyInput'
import { useCreditAdvanceFai } from '../../hooks/useAdvanceToFAIAccount'

interface ThirdStepProps {
    onNextStep: (step: number) => void
    cellphone: string
    email: string
    cardNumber: string
    expiredDateCard: string
    cvvValue: string
    numberFee: string
    transactionValue: string
}

const ThirdStep: React.FC<ThirdStepProps> = ({
    onNextStep,
    cellphone,
    email,
    cardNumber,
    expiredDateCard,
    cvvValue,
    numberFee,
    transactionValue,
}) => {
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [isLoadingTime, setIsLoadingTime] = useState(true)
    const [isValidateCode, setValidateCode] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        SendOtp()
    }, [tokenSave])

    const handleModalCloseInfo = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const SendOtp = (): void => {
        useSendOTP(tokenSave)
            .then((res: any) => {
                if (res['status'] === 200) {
                    console.log('otp enviado')
                } else if (res['Response'] === '401') {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res['Message'])
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El código de bloqueo no ha sido enviado, por favor intente de nuevo'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor')
            })
    }

    const handleLoading = (value: boolean): void => {
        setIsLoadingTime(value)
    }

    const handleValidate = (value: boolean): void => {
        setValidateCode(value)
    }

    const handleContinue = (): void => {
        TransactionAdvanceFai()
    }

    const TransactionAdvanceFai = (): void => {
        setIsLoading(true)
        const dataBody = {
            NumberCard: cardNumber,
            ExpiredDateCard: expiredDateCard,
            CVV2Value: cvvValue,
            TransactionValue: transactionValue,
            NumberFee: numberFee,
        }
        useCreditAdvanceFai(tokenSave, dataBody)
            .then((res: any) => {
                if (res['status'] === 200 && res['data']['Data'] !== null) {
                    onNextStep(4)
                } else {
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(res.Data.ResponseDescription)
                    setShowModal(true)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    return (
        <Wrapper>
            <Content>
                <TextSteps>Paso 3 de 3</TextSteps>

                <TextInfo>
                    Ingresa la clave dinámica que recibiste al celular terminado en {cellphone} y a
                    tu correo electrónico {email}
                </TextInfo>

                <TextSubtitle>Clave dinámica</TextSubtitle>
                <DynamicKeyInput
                    tw="text-left justify-start"
                    tokenSave={tokenSave}
                    handleLoading={handleLoading}
                    handleValidate={handleValidate}
                />
                {isValidateCode && <Loading text="Validando clave dinámica." />}
                <ButtonsContainer>
                    <CancelButton onClick={() => history.push('/home-wallet')}>
                        Cancelar
                    </CancelButton>
                    {/* <ButtonContinue disabled={isLoadingTime} onClick={handleContinue}>
                        Realizar Avance
                    </ButtonContinue> */}
                    <ButtonContent>
                        <Button
                            id="btnSubmit"
                            className="button-block"
                            variant="sub-dominant"
                            type="button"
                            onClick={handleContinue}
                            isLoading={isLoading}
                            extend
                            disabled={isLoadingTime}
                        >
                            Realizar Avance
                        </Button>
                    </ButtonContent>
                </ButtonsContainer>
            </Content>

            <ModalGeneric
                show={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleButton={handleModalCloseInfo}
                handleClose={handleModalCloseInfo}
                textButton="Aceptar"
            />
        </Wrapper>
    )
}

export default ThirdStep
