import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'
import TitleModule from './inc/TitleModule'
import {
    InputGroupText,
    ContainerFilters,
    PaginationContent,
    ButtonPagination,
    ContainerTableUser,
    TextPBold,
    ContentInfo,
    Image,
    SpaceStep,
} from './UserManagement-styles'
import { useSelector } from 'react-redux'
import { Col, Row, Pagination, PageItem } from 'react-bootstrap'
import { NotProductSVG } from '../../utils/getIcons'
import Loading from './inc/Loading'
import { useGetUsers } from './hooks/UseUserManagement'
import ModalError from './inc/Modals/ModalError'

const UserManagement = (): JSX.Element => {
    const data = [
        {
            FullName: '',
            DocNumber: '',
            UserName: '',
            RoleName: '',
        },
    ]
    const [loading, setLoading] = useState<boolean>(false)
    const [cantItems, setCantItems] = useState<number>(0)
    const [infoUserManagement, setInfoUserManagement] = useState<any>(data)
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const handlePageClick = (pageNumber: number): void => {
        setCurrentPage(pageNumber)
    }
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [filters, setFilters] = useState<{ [key: string]: string }>({
        id: '',
        FullName: '',
        DocNumber: '',
        UserName: '',
        RoleName: '',
    })

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCurrentPage(1)
        const { name, value } = e.target
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
    }

    const filteredData = infoUserManagement.filter((infoUserManagement: any) =>
        Object.keys(filters).every(
            (key) =>
                !filters[key] ||
                String(infoUserManagement[key]).toLowerCase().includes(filters[key].toLowerCase())
        )
    )
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Administración',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: 'Usuarios',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
    ]
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const GetUsers = (): void => {
        setLoading(true)
        useGetUsers(tokenSave)
            .then((res: any) => {
                const responseRes = res.data?.Response || res.Response
                if (responseRes === '200') {
                    setInfoUserManagement(res['data']['Data'])
                    setCantItems(res['data']['Data'].length)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorMessage(
                        'Lo sentimos, en este momento no ha sido posible consultar tu información, por favor intenta más tarde.'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage('No se ha podido conectar con el servidor')
                setShowError(true)
            })
    }

    useEffect(() => {
        GetUsers()
    }, [])

    const handleClose = (): void => {
        setShowError(false)
        history.push('/home-wallet')
    }

    return (
        <LayoutContent>
            <SpaceStep />
            <BreadcrumbApp
                breadcrumbs={breadcrumbs}
                onBack={() => {
                    redirection('/home-wallet')
                }}
            />
            <TitleModule />
            <ContainerFilters>
                <Row className="row-transaction">
                    <Col md={3}>
                        <p className="text">Nombre Completo</p>
                        <InputGroupText
                            type="text"
                            name="FullName"
                            value={filters.FullName}
                            onChange={handleFilterChange}
                        />
                    </Col>
                    <Col md={2}>
                        <p className="text">Cédula</p>
                        <InputGroupText
                            type="number"
                            name="DocNumber"
                            value={filters.DocNumber}
                            onChange={handleFilterChange}
                        />
                    </Col>
                    <Col md={2}>
                        <p className="text">Usuario</p>
                        <InputGroupText
                            type="text"
                            name="UserName"
                            value={filters.UserName}
                            onChange={handleFilterChange}
                        />
                    </Col>
                    <Col md={2}>
                        <p className="text">Rol</p>
                        <InputGroupText
                            type="text"
                            name="RoleName"
                            value={filters.RoleName}
                            onChange={handleFilterChange}
                        />
                    </Col>
                </Row>
            </ContainerFilters>

            {loading ? (
                <Loading />
            ) : (
                <ContainerTableUser striped bordered responsive>
                    <thead>
                        <tr>
                            <th>Nombre Completo</th>
                            <th>Cédula</th>
                            <th>Usuario</th>
                            <th>Rol</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((infoUserManagement: any, index: number) => (
                            <tr key={`${index}-${infoUserManagement.UserName}`}>
                                <td>{infoUserManagement.FullName}</td>
                                <td>{infoUserManagement.DocNumber}</td>
                                <td>{infoUserManagement.UserName}</td>
                                <td>{infoUserManagement.RoleName}</td>
                            </tr>
                        ))}
                    </tbody>
                </ContainerTableUser>
            )}
            <PaginationContent>
                <Pagination>
                    {currentPage !== 1 && (
                        <ButtonPagination>
                            <PageItem
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                {'<'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                    {[...Array(totalPages)].map((_, page) => (
                        <PageItem
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageClick(page + 1)}
                        >
                            {page + 1}
                        </PageItem>
                    ))}
                    {currentPage !== totalPages && totalPages > 1 && (
                        <ButtonPagination>
                            <PageItem
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                {'>'}
                            </PageItem>
                        </ButtonPagination>
                    )}
                </Pagination>
                <ContentInfo>
                    {cantItems === 0 && showError && (
                        <div style={{ display: 'flex' }}>
                            <Image src={NotProductSVG} alt="icono" />
                            <TextPBold>
                                <span>No hay información disponible</span>
                            </TextPBold>
                        </div>
                    )}
                </ContentInfo>
            </PaginationContent>
            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
        </LayoutContent>
    )
}

export default UserManagement
