import { ReactElement } from 'react'
// styled components
import { StepContent, StepTitle } from '../../appTour-styles'

const DetailCardOptionsStep = (): ReactElement => {
    return (
        <StepContent>
            <StepTitle>
                Todo lo que necesitas <span className="sub-title">en un mismo lugar</span>
            </StepTitle>

            <p>
                Desde aquí podrás solicitar extractos, certificados e incluso bloquear tu tarjeta.
            </p>
        </StepContent>
    )
}

export default DetailCardOptionsStep
