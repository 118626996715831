const storageUrl = process.env.REACT_APP_STORAGE_URL as string
const storage = `${storageUrl}/assets/Icons`
const storageAssets = `${storageUrl}/assets`

//creditCardStart
export const DotsMenu = `${storage}/dots-menu.svg`
export const ListMenu = `${storage}/icon-menu.svg`
export const ChevronDown = `${storage}/chevron-down.svg`
export const ChevronUp = `${storage}/chevron-up.svg`
export const IconLockSVG = `${storage}/icon-lock.svg`
export const IconLockLightSVG = `${storage}/icon-lock-light.svg`
export const IconLockOpenSVG = `${storage}/icon-lock-open.svg`
export const IconAssignSVG = `${storage}/icon-assign.svg`
export const IconAssignMobileSVG = `${storage}/icon-assign-mobile.svg`
export const IconChangePassSVG = `${storage}/icon-change-pass.svg`
export const IconReferQuotasSVG = `${storage}/icon-refer-quotas.svg`
export const IconReferQuotasMobileSVG = `${storage}/icon-refer-quotas-mobile.svg`
export const IconBuyPortfolioSVG = `${storage}/icon-buy-portfolio.svg`
export const IconFileExtractSVG = `${storage}/icon-file-extract.svg`
export const IconFileMobileSVG = `${storage}/icon-file-extract-mobile.svg`
export const IconFileCertificateSVG = `${storage}/icon-file-certificate.svg`
export const IconBan = `${storage}/icon-ban-solid.svg`
export const IconReported = `${storage}/icon-reported.svg`
export const IconSuccessfulProcess = `${storage}/successful-process.svg`
export const SignatureCardBase = `${storage}/signature-card-base.png`
export const ClassicCardBase = `${storage}/classic-card-base.png`
export const GoldCardBase = `${storage}/gold-card-base.png`
export const PlatinoCardBase = `${storage}/platino-card-base.png`
export const ChipLogo = `${storage}/chip.png`
export const VisaLogo = `${storage}/visa-logo.png`
export const CaviCardLogo = `${storage}/cavi-card-logo.png`
//creditCardEnd

export const UserSVG = `${storage}/user-alt.svg`
export const ForgotSVG = `${storage}/forgot.svg`
export const InactivitySVG = `${storage}/inactivity-color.svg`
export const LockSVG = `${storage}/lock.svg`
export const EyeSVG = `${storage}/eye-slash.svg`
export const EyeCleanPNG = `${storage}/eye-clean.png`
export const IconInstagramSVG = `${storage}/instagram.svg`
export const IconFacebookSVG = `${storage}/facebook.svg`
export const IconTwitterSVG = `${storage}/twitter.svg`
export const IconLinkedinSVG = `${storage}/linkedin.svg`
export const Cavipetrol = `${storage}/cavipetrol.svg`
export const IconHeadsetSVG = `${storage}/headset-white.svg`
export const IconLogOutSVG = `${storage}/log-out.svg`
export const PaymentSVG = `${storage}/credit-card-white.svg`
export const ProductSVG = `${storage}/product-white.svg`
export const ProductYellowSVG = `${storage}/product.svg`
export const ChartSVG = `${storage}/chart.svg`
export const IconUsercogSVG = `${storage}/users-cog.svg`
export const IconUnlockSVG = `${storage}/unlock.svg`
export const ImgTitleMobSVG = `${storage}/wallet-purple.svg`
export const ImgTitleSVG = `${storage}/wallet-purple-circle.svg`
export const Credit1SVG = `${storage}/credit1.svg`
export const HeadsetSVG = `${storage}/headset.svg`
export const HeadsetColorSVG = `${storage}/headset-color.svg`
export const LogOutSVG = `${storage}/log-out-color.svg`
export const PaymentSideSVG = `${storage}/payment.svg`
export const ProductSideSVG = `${storage}/product-sidebar.svg`
export const InactiveSVG = `${storage}/inactivity.svg`
export const NotProductSVG = `${storage}/not-product.svg`
export const ArrowSVG = `${storage}/arrow-right.svg`
export const ArrowCircleSVG = `${storage}/arrow-alt-circle-left.svg`
export const MessageSVG = `${storage}/message-text.svg`
export const EmailSVG = `${storage}/open-text.svg`
export const MessageSupportSVG = `${storage}/dialogue-ballons.svg`
export const WhatsappSVG = `${storage}/whatsapp.svg`
export const EmailSupportSVG = `${storage}/email.svg`
export const PhoneSVG = `${storage}/phone-message.svg`
export const Big404SVG = `${storage}/big-404.svg`
export const Big500SVG = `${storage}/big-500.svg`
export const ArrowYellowSVG = `${storage}/arrow-down-yellow.svg`
export const RequestSVG = `${storage}/request-products.svg`
export const CarGifSVG = `${storage}/gif-car.svg`
export const CreditCardSVG = `${storage}/card-global.svg`
export const ACFSVG = `${storage}/ACF.svg`
export const IconFaiSVG = `${storage}/icon-fai.svg`
export const IconSavingSVG = `${storage}/icon-savings.svg`
export const IconContributionsSVG = `${storage}/icon-contributions.svg`
export const IconEditSVG = `${storage}/edit.svg`
export const IconAlertSVG = `${storage}/alert-red.svg`
export const AlertSVG = `${storage}/alert-blue.svg`
export const IconCreditSVG = `${storage}/home-realty.svg`
export const IconModalSVG = `${storage}/payment-fai.svg`
export const IconAlertYellowSVG = `${storage}/alert-yellow.svg`
export const SharedSVG = `${storage}/icon-share.svg`
export const PdfSVG = `${storage}/file-pdf.svg`
export const PrintSVG = `${storage}/icon-print.svg`
export const CircleSVG = `${storage}/circle-yellow.svg`
export const CheckTransactionSVG = `${storage}/check-transaction.svg`
export const CloseSVG = `${storage}/icon-close.svg`
export const EmptySVG = `${storage}/icon-empty.svg`
export const CreditUmbrellaSVG = `${storage}/credit-umbrella.svg`
export const TimeSVG = `${storage}/md-time.svg`
export const MetroSVG = `${storage}/metro-stackoverflow.svg`
export const CashSVG = `${storage}/ios-cash.svg`
export const RisingStatisticsSVG = `${storage}/rising-statistics-big.svg`
export const GraphSVG = `${storage}/rising-statistics.svg`
export const CardSVG = `${storage}/card-global.svg`
export const IconPayFaiSVG = `${storage}/icon-pay-fai.svg`
export const CreditPlantSVG = `${storage}/credit-plant.svg`
export const RemoveSVG = `${storage}/remove-circle.svg`
export const AirplaneGlobalSVG = `${storage}/airplane-global.svg`
export const BigStainSVG = `${storage}/stain.svg`
export const CashCheckSVG = `${storage}/cash-check.svg`
export const ClockManSVG = `${storage}/clock-man.svg`
export const ClockMoneySVG = `${storage}/clock-money.svg`
export const CoinHandSVG = `${storage}/coin-hand.svg`
export const CoinHadsSVG = `${storage}/coin-hands.svg`
export const CoinPlantSVG = `${storage}/coin-plant.svg`
export const CreditCardCheckSVG = `${storage}/credit-card-check.svg`
export const CreditCardHandSVG = `${storage}/credit-card-hand.svg`
export const CreditCardLockSVG = `${storage}/credit-card-lock.svg`
export const CreditCardOutlineSVG = `${storage}/credit-card-outline.svg`
export const CreditCardStartsSVG = `${storage}/credit-card-stars.svg`
export const CreditCardUnlockSVG = `${storage}/credit-card-unlock.svg`
export const CreditCardValidSVG = `${storage}/credit-card-valid.svg`
export const CreditCardYellowSVG = `${storage}/credit-card.svg`
export const EllipsisCheckSVG = `${storage}/ellipsis-check.svg`
export const ExclamationShieldSVG = `${storage}/exclamation-shield.svg`
export const IconPSESVG = `${storage}/icon-pse.svg`
export const PadlockOtpCheckSVG = `${storage}/padlock-otp-check.svg`
export const PadlockOtpSVG = `${storage}/padlock-otp.svg`
export const PhoneCallSVG = `${storage}/phone-call.svg`
export const SecurityColorsSVG = `${storage}/security-color.svg`
export const SmallStainSVG = `${storage}/small-stain.svg`
export const TicketMoneySVG = `${storage}/ticket-money.svg`
export const WalletBlackSVG = `${storage}/wallet-black.svg`
export const WalletGroundSVG = `${storage}/wallet-ground.svg`
export const WalletYellowCircleSVG = `${storage}/wallet-yellow-circle.svg`
export const WalletSVG = `${storage}/wallet.svg`
export const DollarSVG = `${storage}/dollar.svg`
export const DollarDarkSVG = `${storage}/dollar-dark.svg`
export const WelcomeSVG = `${storage}/welcome.svg`
export const TDCIconFormSVG = `${storage}/icon-tdc.svg`

//icons png
const storagePng = `${storageUrl}/assets/iconsProductspng`

export const ProductIconDefault = `${storagePng}/defaultIcon.png`

export const IconMultiPNG = `${storageAssets}/multi-option.png`
export const ElipseGreenPNG = `${storageAssets}/mini-elipse-green.png`
export const ElipseYellowPNG = `${storageAssets}/mini-elipse-yellow.png`
export const ElipseBluePNG = `${storageAssets}/mini-elipse-blue.png`
export const ArrowPurplePNG = `${storageAssets}/arrow-purple.png`
export const SharePNG = `${storageAssets}/share.png`
export const CheckTransactionPNG = `${storageAssets}/check-transaction.png`
export const DeclinedTransactionPNG = `${storageAssets}/circle-yellow.png`
export const DownloadSVG = `${storageAssets}/mini-download.svg`
export const MailOutlineSVG = `${storageAssets}/mail-outline.svg`
export const EmailSendSVG = `${storageAssets}/email-send.svg`
export const ReadySVG = `${storageAssets}/ready.svg`
export const BlockerSVG = `${storageAssets}/blocker.svg`
export const MiniSaveSVG = `${storageAssets}/mini-save.svg`
export const CreditCardFlowSVG = `${storageAssets}/credit-card-flow.svg`
export const PickerMapSVG = `${storageAssets}/map-location.svg`
export const MiniCitySVG = `${storageAssets}/mini-city.svg`
export const MiniStateSVG = `${storageAssets}/mini-state.svg`
export const MiniRingSVG = `${storageAssets}/mini-ring.svg`
export const CreditCardPNG = `${storageAssets}/credit-card.png`
export const CreditCardGoldenPNG = `${storageAssets}/credit-card-golden.png`
export const CreditCardPlatinumPNG = `${storageAssets}/credit-card-platinum.png`
export const UploadFileSVG = `${storageAssets}/upload-file-icon.svg`
export const MiniCellPhoneSVG = `${storageAssets}/mini-cell-phone.svg`
export const MiniPhoneSVG = `${storageAssets}/mini-phone.svg`
export const ConnectionErrorSVG = `${storageAssets}/connection-error.svg`
export const MiniIdentificationSVG = `${storageAssets}/mini-identification.svg`
export const MiniBagSVG = `${storageAssets}/mini-bag.svg`
export const MiniSearchSVG = `${storageAssets}/mini-search.svg`
export const MiniMailSVG = `${storageAssets}/mini-mail.svg`
export const MiniAddPlusSVG = `${storageAssets}/icon-add.svg`
export const EditInfoSVG = `${storageAssets}/icon-edit-info.svg`
export const ServiceSVG = `${storageAssets}/icon-service-bell-white.svg`
export const ServiceYellowSVG = `${storageAssets}/icon-service-bell-yellow.svg`
export const ServiceSideBarBell = `${storageAssets}/icon-bell-service-sidebar.svg`
export const UpdatePNG = `${storageAssets}/update.png`
export const PostPNG = `${storageAssets}/post.png`
export const UnPublishPNG = `${storageAssets}/unpublish.png`
export const CheckOperationSVG = `${storageAssets}/check-operation.svg`
export const WalletFai = `${storageAssets}/wallet-fai.svg`
export const HomeSVG = `${storageAssets}/icon-home.svg`
export const CardGlobalPurpleSVG = `${storageAssets}/card-global-purple.svg`
export const CircleGreenSVG = `${storageAssets}/icon-circle-green.svg`
export const CircleRedSVG = `${storageAssets}/icon-circle-red.svg`
export const CircleYellowSVG = `${storageAssets}/icon-circle-yellow.svg`
export const CircleBlueSVG = `${storageAssets}/icon-circle-blue.svg`
export const FileSignatureSVG = `${storageAssets}/icon-file-signature-orange.svg`
export const TruckBlackSVG = `${storageAssets}/icon-truck-black.svg`
export const ArrowNextPurpleSVG = `${storageAssets}/icon-arrow-next-purple.svg`
export const IconUserStar = `${storageAssets}/icon-user-star.png`

export const TransactionPaymentSVG = `${storageAssets}/icon-service-payment-white.svg`
export const TransactionPaymentYellowSVG = `${storageAssets}/icon-service-payment-yellow.svg`
export const PaymentTransactionSideSVG = `${storageAssets}/icon-payment-sidebar.png`
export const MoneyDollarCoin = `${storageAssets}/icon-money-dollar-coin.svg`
export const PDFFileIcon = `${storageAssets}/icon-pdf-file.svg`
export const Cavifacil = `${storageAssets}/cavifacil-image.png`
export const DownloadDoc = `${storageAssets}/download_doc.svg`
export const BankIcon = `${storageAssets}/bank-icon.svg`
export const AdminPanelYellowIcon = `${storageAssets}/admin-panel-settings-yellow.png`
export const AdminPanelIcon = `${storageAssets}/admin-panel-settings.png`
export const AdminPanel2Icon = `${storageAssets}/admin-panel-mobile.png`
export const CardId = `${storageAssets}/card-identification.svg`
export const Edit = `${storageAssets}/editIcon.svg`
export const EditWhite = `${storageAssets}/editIconWhite.svg`
export const CancelWhite = `${storageAssets}/cancelWhite.svg`
export const SupervisorWhite = `${storageAssets}/supervisor-account-white.svg`
export const SupervisorYellow = `${storageAssets}/supervisor-account-yellow.svg`
export const SupervisorSideBar = `${storageAssets}/supervisor-account-slider.png`
export const ExclusionSVG = `${storageAssets}/exclusion.svg`
export const SaveSVG = `${storageAssets}/save.svg`
export const CloseCircleSVG = `${storageAssets}/close-circle.svg`
export const PenSVG = `${storageAssets}/pen.svg`
export const BankSVG = `${storageAssets}/icon-bank.svg`
export const MoneySVG = `${storageAssets}/icon-money.svg`
export const MiniCardSVG = `${storageAssets}/icon-card.svg`
export const IconWalletCashSVG = `${storageAssets}/icon-wallet-cash.png`
