import tw, { styled } from 'twin.macro'
import { Modal, Button } from 'react-bootstrap'

export const BlockCardWrapper = styled.section`
    ${tw`mb-[20px] py-[15px] px-0 min-h-[50vh] flex justify-center items-center py-2`}
`

export const BlockCardContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[60%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    @media (max-width: 768px) {
        ${tw`max-w-[100%]`}
    }
`

export const LockConfirmationWrapper = styled.section`
    ${tw`mt-[10%] mb-[20px] py-[15px] px-0 min-h-[50vh] flex justify-center items-center py-2`}

    @media (max-width: 768px) {
        ${tw`mt-[30%]`}

        .address-field {
            margin: 0 !important;
        }
    }
`
export const LockConfirmationContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[60%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        ${tw`max-w-[100%]`}

        .address-field {
            margin: 0 !important;
        }
    }
`

export const BLockCardTheftContent = styled.section`
    ${tw`py-0 px-0 lg:px-5`}

    p {
        ${tw`font-helvetica font-normal text-base lg:text-xl text-gray-custom-900`}
    }

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`

export const BlockTitleContent = styled.div`
    ${tw`mt-[10%]`}
`

export const BLockCardTheftTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }
`
export const LockTitle = styled.h2`
    ${tw`font-montserrat font-bold text-base my-[20px]`}

    color: #F5A50B;
`

export const LockSubTitle = styled.h2`
    ${tw`font-montserrat font-bold text-base`}
`
export const LockInfo = styled.label`
    ${tw`font-montserrat text-base`}

    width: 100%;
`

export const LockTextInfo = styled.label`
    ${tw`font-montserrat text-base`}

    width: 100%;
    text-align: center;
`

export const LockText = styled.p`
    ${tw`font-montserrat text-base`}
`
export const LockTextBold = styled.label`
    ${tw`font-montserrat font-bold text-base`}

    width: 100%;
`
export const CodeText = styled.span`
    ${tw`font-montserrat font-bold text-base`}

    color: #512f8b;
`

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`

export const CreditModal = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }

        .modal-body {
            ${tw`flex flex-col items-center`}

            img {
                ${tw`m-2.5`}
            }

            h6 {
                ${tw`font-montserrat text-center text-base font-semibold color[var(--header-botton-color)]`}
            }

            p {
                ${tw`my-2.5 mx-0 text-center text-sm font-helvetica font-normal color[var(--header-botton-color)]`}
            }
        }
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[200px]`}
    }

    /* &:hover {
        background-color: var(--sub-dominant-color) !important;
        border-color: transparent !important;
        color: #fff !important;
    } */

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const ModalButtonBack = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
    @media (min-width: 320px) and (max-width: 550px) {
        ${tw`w-[200px]`}
    }
`

export const ResetTimerButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[100%] h-[40px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover, &:focus {
        background-color: #ffffff;
        color: #000;
        border-color: transparent !important;
    }

    background-color: #ffffff;
    text-decoration: underline;
    border-color: transparent !important;
`

export const InputOtp = styled.input`
    ${tw`w-12 h-12 border-gray-300/80 rounded bg-transparent outline-none text-center font-semibold text-xl focus:border-gray-400 focus:text-gray-700 text-gray-400 transition`}

    border-width: 1px;
    margin: 10px;
    @media (min-width: 352px) and (max-width: 400px) {
        ${tw`w-8 h-9 m-0.5 p-0 text-xl`}
    }

    @media (min-width: 400px) and (max-width: 550px) {
        ${tw`w-10 h-10 m-0.5 p-0 text-xl`}
    }

    @media (max-width: 352px) {
        ${tw`w-6 h-9 m-0.5 p-0 text-xl`}
    }
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}

    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ExpirationPayment = styled.div`
    ${tw`flex justify-center items-center mt-[4%] py-0 px-0`}
    ${tw`lg:mt-[4%] lg:justify-start lg:p-0`}

    p {
        ${tw`font-montserrat font-normal text-sm text-gray-custom-700 m-0`}
        ${tw`lg:text-base`}
    }

    .text-percentage {
        ${tw`flex`}
    }
`
export const ArrowBlack = styled.div`
    ${tw`flex p-0 !text-decoration[none] gap-3.5 cursor-pointer  color[var(--footer-color)] mb-[20px]`}

    & p {
        ${tw`my-auto text-base text-gray-custom-600 font-montserrat font-normal`}
    }

    @media (max-width: 500px) {
        ${tw`w-40`}
        & p {
            ${tw`text-sm`}
        }
    }

    @media (min-width: 1024px) {
        ${tw`w-12`}
    }
`
export const ArrowYellow = styled.a`
    display: flex;
    gap: 12px;
    padding: 5px 20px 5px 5px;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        transform: rotate(-90deg);
    }

    span {
        color: #f2a510;
        font-weight: bold;
    }
`
