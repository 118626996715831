import { AxiosResponse } from 'axios'
import { paymentScheduleCancellationTC } from '../../../../../domain/services/User.service'

type Result = AxiosResponse | null

export async function useCancelScheduledPayment(tokenSave: string, data: any): Promise<Result> {
    try {
        return await paymentScheduleCancellationTC
            .postCancellationScheduledPayment(
                '/api/Products/CancelScheduledPayment/CancelScheduledPayment',
                tokenSave,
                data
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useGetScheduledPaymentFAI(
    faiAccountNumber: string,
    process: boolean,
    tokenSave: string
): Promise<Result> {
    try {
        return await paymentScheduleCancellationTC
            .getScheduledPaymentFAI(
                `/api/Products/GetScheduledPaymentFAI/GetScheduledPaymentFAI?faiAccountNumber=${faiAccountNumber}&process=${process}`,
                tokenSave
            )
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
