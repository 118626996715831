import { Content, Wrapper } from '../../CavifacilTransaction-Styled'
import { TextInfo, TextP, UserDate } from './InitialInfo-Styled'

interface InitialInfoProps {
    associatedName: string
}

const InitialInfo: React.FC<InitialInfoProps> = ({ associatedName }) => {
    return (
        <Wrapper>
            <Content>
                <UserDate>
                    {' '}
                    Apreciado(a): <span>{associatedName}</span>
                </UserDate>
                <TextInfo>
                    Este servicio te permitirá realizar el desembolso del CaviFácil, por favor ten
                    en cuenta las siguientes recomendaciones.
                </TextInfo>
                <ul>
                    <li className="item-1">
                        <TextP>El monto mínimo es de $100.000</TextP>
                    </li>
                    <li className="item-2">
                        <TextP>Escribe el valor a utilizar.</TextP>
                    </li>
                    <li className="item-3">
                        <TextP>Utiliza el teclado virtual para ingresar la clave IVR</TextP>
                    </li>
                </ul>
            </Content>
        </Wrapper>
    )
}

export default InitialInfo
