import * as yup from 'yup'

const requiredMessage = 'Este campo es requerido'
const numericMessage = 'Por favor ingresar solo dígitos numéricos'
const max50Characters = 'Este campo debe contener un máximo de 50 caracteres'
const max20Characters = 'Este campo debe contener un máximo de 20 caracteres'
const max200Characters = 'Este campo debe contener un máximo de 200 caracteres'
const max90Characters = 'Este campo debe contener un máximo de 90 caracteres'
const max100Characters = 'Este campo debe contener un máximo de 100 caracteres'
const max190Characters = 'Este campo debe contener un máximo de 190 caracteres'
const onlyLetters = 'Por favor solo ingrese letras'
const emailValid = 'Ingrese un correo electrónico válido'
const alphabeticRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/
const numericRegex = /^\d?\d+$/
const alphanumericRegex = /^[a-zA-Z0-9\s]+$/
const valueNotAllowed = 'Valor no permitido'
const numberAllowed = 999999999999999
const betweenNit = 'Este campo debe contener mínimo de 1 y un máximo de 20000000'

const ensureNumber = (schema: yup.NumberSchema) => {
    return schema.transform((value, originalValue) => {
        return originalValue === null ? 0 : value
    })
}

const ensureString = (schema: yup.StringSchema) => {
    return schema.transform((value, originalValue) => {
        return originalValue === null ? '' : value
    })
}

export const personalInformationSchema = yup.object().shape({
    Name: ensureString(yup.string()).required(requiredMessage).max(200, max200Characters),
    FirstLastName: ensureString(yup.string()).required(requiredMessage).max(200, max200Characters),
    SecondLastName: ensureString(yup.string()).required(requiredMessage).max(200, max200Characters),
    DocumentNumber: ensureString(yup.string())
        .required(requiredMessage)
        .matches(numericRegex, numericMessage)
        .max(50, max50Characters),
    LevelEducation: ensureString(yup.string()).required(requiredMessage),
    MaritalStatus: ensureString(yup.string()).required(requiredMessage),
    Occupation: ensureString(yup.string()).required(requiredMessage),
    TypeContract: ensureString(yup.string()).when('Occupation', {
        is: (Occupation: string) => Occupation === '100000001',
        then: ensureString(yup.string())
            .required(requiredMessage)
            .matches(numericRegex, requiredMessage)
            .min(2, requiredMessage),
        otherwise: ensureString(yup.string()).nullable(),
    }),
})

export const contactInformationSchema = yup.object().shape({
    HomeAddress: ensureString(yup.string())
        .required(requiredMessage)
        .max(190, max190Characters)
        .nullable(),
    Neighborhood: ensureString(yup.string())
        .required(requiredMessage)
        .matches(alphanumericRegex, 'Este campo solo puede contener letras y números')
        .nullable(),
    Country: yup
        .object({
            NewName: yup.string().required(requiredMessage),
        })
        .nullable()
        .required(requiredMessage)
        .typeError('Debes seleccionar un país válido'),
    Stratum: ensureString(yup.string()).required(requiredMessage).nullable(),
    Landline: ensureString(yup.string())
        .required(requiredMessage)
        .matches(numericRegex, numericMessage)
        .max(50, max50Characters)
        .nullable(),
    Patronal: ensureString(yup.string()).required(requiredMessage).nullable(),
    Charge: ensureString(yup.string())
        .required(requiredMessage)
        .matches(alphabeticRegex, onlyLetters)
        .max(50, max50Characters)
        .nullable(),
    OfficeAddress: ensureString(yup.string())
        .required(requiredMessage)
        .max(50, max50Characters)
        .nullable(),
    OfficeCityName: ensureString(yup.string())
        .required(requiredMessage)
        .nullable()
        .test('is-not-empty', requiredMessage, (value) => {
            return value !== null && value !== undefined && value.trim() !== ''
        })
        .typeError('Debes seleccionar una ciudad válida'),
    OfficeTelephone: ensureString(yup.string())
        .required(requiredMessage)
        .matches(numericRegex, numericMessage)
        .nullable(),
    CorporateEmail: ensureString(yup.string())
        .email(emailValid)
        .required(requiredMessage)
        .max(50, max50Characters)
        .nullable(),
    DeparmentName: ensureString(yup.string())
        .nullable()
        .when('Country', {
            is: (country: any) => country?.NewName === 'Colombia',
            then: ensureString(yup.string())
                .required(requiredMessage)
                .nullable()
                .test('is-not-empty', requiredMessage, (value) => {
                    return value !== null && value !== undefined && value.trim() !== ''
                }),
            otherwise: ensureString(yup.string()).nullable(),
        })
        .typeError('Debes seleccionar un departamento válido'),
    CityName: ensureString(yup.string())
        .nullable()
        .when('Country', {
            is: (country: any) => country?.NewName === 'Colombia',
            then: ensureString(yup.string())
                .required(requiredMessage)
                .nullable()
                .test('is-not-empty', requiredMessage, (value) => {
                    return value !== null && value !== undefined && value.trim() !== ''
                }),
            otherwise: ensureString(yup.string()).nullable(),
        })
        .typeError('Debes seleccionar una ciudad válida'),
    OfficeDeparmentName: ensureString(yup.string())
        .required(requiredMessage)
        .nullable()
        .test('is-not-empty', requiredMessage, (value) => {
            return value !== null && value !== undefined && value.trim() !== ''
        })
        .typeError('Debes seleccionar un departamento válido'),
})

export const financialInformationSchema = yup.object().shape({
    BasicSalaryOrPension: ensureNumber(yup.number())
        .required(requiredMessage)
        .typeError(numericMessage)
        .positive(valueNotAllowed)
        .max(numberAllowed, max20Characters),
    ValueOtherIncome: ensureNumber(yup.number()).when('HaveOtherIncome', {
        is: true,
        then: ensureNumber(yup.number())
            .required(requiredMessage)
            .typeError(numericMessage)
            .positive(valueNotAllowed)
            .max(numberAllowed, max20Characters),
        otherwise: yup.number().nullable(),
    }),
    TotalExpenditures: ensureNumber(yup.number())
        .required(requiredMessage)
        .typeError(numericMessage)
        .positive(valueNotAllowed)
        .max(numberAllowed, max20Characters),

    TotalAssets: ensureNumber(yup.number())
        .required(requiredMessage)
        .typeError(numericMessage)
        .positive(valueNotAllowed)
        .max(numberAllowed, max20Characters),
    TotalLiabilities: ensureNumber(yup.number())
        .required(requiredMessage)
        .typeError(numericMessage)
        .positive(valueNotAllowed)
        .max(numberAllowed, max20Characters),
    YouEntrepreneurOrOwnCompany: yup.boolean().required(requiredMessage),
    CompanyNIT: ensureNumber(yup.number()).when('YouEntrepreneurOrOwnCompany', {
        is: true,
        then: yup
            .number()
            .required(requiredMessage)
            .typeError(numericMessage)
            .positive(valueNotAllowed)
            .max(20000000, betweenNit)
            .min(1, betweenNit),
        otherwise: ensureNumber(yup.number()).nullable(),
    }),
    CompanyActivity: ensureString(yup.string()).when('YouEntrepreneurOrOwnCompany', {
        is: true,
        then: ensureString(yup.string()).required(requiredMessage).max(50, max100Characters),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    CIIUCode: ensureString(yup.string())
        .required(requiredMessage)
        .min(1, requiredMessage)
        .max(20, max20Characters),
    WherePutSalaryOrPension: ensureString(yup.string())
        .required(requiredMessage)
        .max(90, max90Characters),
    BankingInstitutionsFinancialProducts: ensureString(yup.string())
        .required(requiredMessage)
        .max(90, max90Characters),
    ConductForeignCurrencyTransactions: yup.boolean().required(requiredMessage),
    HasFinancialProductsAbroad: ensureString(yup.string()).when(
        'ConductForeignCurrencyTransactions',
        {
            is: true,
            then: ensureString(yup.string()).required(requiredMessage),
            otherwise: ensureString(yup.string()).nullable(),
        }
    ),
    HasAccountsForeignCurrency: ensureString(yup.string()).when(
        'ConductForeignCurrencyTransactions',
        {
            is: true,
            then: ensureString(yup.string()).required(requiredMessage),
            otherwise: ensureString(yup.string()).nullable(),
        }
    ),
    ProductType: ensureString(yup.string()).when('ConductForeignCurrencyTransactions', {
        is: true,
        then: ensureString(yup.string()).required(requiredMessage),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    Entity: ensureString(yup.string()).when('ConductForeignCurrencyTransactions', {
        is: true,
        then: ensureString(yup.string()).required(requiredMessage),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    Amount: ensureString(yup.string()).when('ConductForeignCurrencyTransactions', {
        is: true,
        then: ensureString(yup.string())
            .required(requiredMessage)
            .matches(numericRegex, numericMessage)
            .max(20, max20Characters),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    InternationalCountry: yup
        .object({
            NewName: yup
                .string()
                .required(requiredMessage)
                .nullable()
                .typeError(requiredMessage)
                .test('is-not-empty', requiredMessage, (value) => {
                    return value !== null && value !== undefined && value.trim() !== ''
                }),
        })
        .nullable()
        .when('ConductForeignCurrencyTransactions', {
            is: true,
            then: yup.object({
                NewName: yup
                    .string()
                    .required(requiredMessage)
                    .nullable()
                    .typeError(requiredMessage)
                    .test('is-not-empty', requiredMessage, (value) => {
                        return value !== null && value !== undefined && value.trim() !== ''
                    }),
            }),
            otherwise: yup.object().nullable(),
        })
        .typeError('Debes seleccionar un país valido'),
    InternationalCity: ensureString(yup.string()).when('ConductForeignCurrencyTransactions', {
        is: true,
        then: ensureString(yup.string()).required(requiredMessage),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    InternationalMoney: ensureString(yup.string()).when('ConductForeignCurrencyTransactions', {
        is: true,
        then: ensureString(yup.string()).required(requiredMessage),
        otherwise: ensureString(yup.string()).nullable(),
    }),
    InternationalIdentificationProductNumber: ensureString(yup.string()).when(
        'ConductForeignCurrencyTransactions',
        {
            is: true,
            then: ensureString(yup.string()).required(requiredMessage),
            otherwise: ensureString(yup.string()).nullable(),
        }
    ),
})

export const declarationOfPoliticalExposedPerson = yup.object().shape({
    ManagesPublicResources: yup.boolean().required(requiredMessage).typeError(requiredMessage),
    YouEnjoyGeneralPublicRecognition: yup
        .boolean()
        .required(requiredMessage)
        .typeError(requiredMessage),
    ExercisesSomeDegreePublicPower: yup
        .boolean()
        .required(requiredMessage)
        .typeError(requiredMessage),
    SecondDegreeRelativesInScenarios: yup
        .boolean()
        .required(requiredMessage)
        .typeError(requiredMessage),
})
