import { FC, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
// styles
import {
    Wrapper,
    Content,
    YellowTitle,
    TextP,
    ButtonsContainer,
    ModalButton,
    ModalCancelButton,
    ActivateInternationalTitle,
} from '../../activateInternationalCard-Styles'
import { useValidateDate } from '../../hooks/useInternationalCard'
import ModalLoading from '../ModalInfo/ModalLoading'

interface StepProps {
    tokenSave: string
    handleNextStep: () => void
    redirections: (value: string) => void
    saveCurrentData: (value: any) => void
}

const SecondStep: FC<StepProps> = ({
    tokenSave,
    handleNextStep,
    redirections,
    saveCurrentData,
}): JSX.Element => {
    const methods = useForm()
    const { handleSubmit, getValues, control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    const [Destination, setDestination] = useState('')
    const [DepartureDate, setDepartureDate] = useState('')
    const [ReturnDate, setReturnDate] = useState('')

    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 8)
    const minDate = currentDate.toISOString().split('T')[0]
    const [isLoading, setIsLoading] = useState(false)

    const HandleFormSubmit = () => {
        const formData = getValues()

        setIsLoading(true)

        const returnDate = new Date(ReturnDate)
        const departureDate = new Date(DepartureDate)

        useValidateDate(DepartureDate, tokenSave)
            .then((res: any) => {
                setIsLoading(false)

                if (res['data']['Response'] === '200' && returnDate > departureDate) {
                    saveCurrentData(formData)
                    handleNextStep()
                } else {
                    setError('ReturnDate', {
                        message: 'La fecha de regreso no puede ser menor a la fecha de salida',
                    })
                }
            })
            .catch((error) => {
                console.error(error)
                setError('DepartureDate', {
                    message: 'No se ha podido conectar con el servidor ',
                })
            })
    }

    return (
        <>
            <ActivateInternationalTitle>
                Solicita la activación de tu tarjeta de crédito <span>fuera de Colombia</span>
            </ActivateInternationalTitle>
            <Wrapper>
                <FormProvider {...methods}>
                    <Content>
                        <YellowTitle>Paso 2 de 3</YellowTitle>
                        <TextP>Cuéntanos sobre tu viaje</TextP>
                        <YellowTitle>Destino</YellowTitle>
                        <Form.Group className="mb-3">
                            <Controller
                                control={control}
                                name={'Destination'}
                                rules={{ required: 'Este campo es requerido' }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Form.Control
                                        type="text"
                                        {...register('Destination', { required: true })}
                                        maxLength={20}
                                        value={Destination}
                                        placeholder="Ej. Bora bora - Italia - España"
                                        required
                                        isInvalid={!!errors['Destination']}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('Destination')
                                            }
                                            setDestination(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('Destination', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('Destination')
                                            }
                                            onBlur()
                                        }}
                                    />
                                )}
                            />
                            {errors['Destination'] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors['Destination']?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <YellowTitle>Fecha de salida</YellowTitle>
                                <Form.Group className="mb-3">
                                    <Controller
                                        control={control}
                                        name={'DepartureDate'}
                                        rules={{ required: 'Este campo es requerido' }}
                                        render={({ field: { onChange, value, onBlur } }) => (
                                            <Form.Control
                                                type="date"
                                                {...register('DepartureDate', {
                                                    required: true,
                                                })}
                                                value={DepartureDate}
                                                required
                                                min={minDate}
                                                isInvalid={!!errors['DepartureDate']}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value !== '' ||
                                                        e.target.value === null
                                                    ) {
                                                        clearErrors('DepartureDate')
                                                    }
                                                    setDepartureDate(e.target.value)
                                                    onChange(e)
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        e.target.value === null
                                                    ) {
                                                        setError('DepartureDate', {
                                                            message: 'Este campo es obligatorio',
                                                        })
                                                    } else {
                                                        clearErrors('DepartureDate')
                                                    }
                                                    onBlur()
                                                }}
                                            />
                                        )}
                                    />
                                    {errors['DepartureDate'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['DepartureDate']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <YellowTitle>Fecha de regreso</YellowTitle>
                                <Form.Group className="mb-3">
                                    <Controller
                                        control={control}
                                        name={'ReturnDate'}
                                        rules={{ required: 'Este campo es requerido' }}
                                        render={({ field: { onChange, value, onBlur } }) => (
                                            <Form.Control
                                                type="date"
                                                {...register('ReturnDate', { required: true })}
                                                value={ReturnDate}
                                                required
                                                min={minDate}
                                                isInvalid={!!errors['ReturnDate']}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value !== '' ||
                                                        e.target.value === null
                                                    ) {
                                                        clearErrors('ReturnDate')
                                                    }
                                                    setReturnDate(e.target.value)
                                                    onChange(e)
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value === '' ||
                                                        e.target.value === null
                                                    ) {
                                                        setError('ReturnDate', {
                                                            message: 'Este campo es obligatorio',
                                                        })
                                                    } else {
                                                        clearErrors('ReturnDate')
                                                    }
                                                    onBlur()
                                                }}
                                            />
                                        )}
                                    />
                                    {errors['ReturnDate'] && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors['ReturnDate']?.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(HandleFormSubmit)}>
                                <ButtonsContainer>
                                    <ModalCancelButton
                                        variant="primary"
                                        onClick={() => redirections('/')}
                                    >
                                        Cancelar
                                    </ModalCancelButton>
                                    <ModalButton
                                        id="btnSubmit"
                                        className="button-block"
                                        variant="sub-dominant"
                                        type="button"
                                        onClick={handleSubmit(HandleFormSubmit)}
                                    >
                                        Continuar
                                    </ModalButton>
                                </ButtonsContainer>
                            </form>
                        </FormProvider>
                    </Content>
                </FormProvider>
            </Wrapper>
            <ModalLoading isLoading={isLoading} />
        </>
    )
}

export default SecondStep
