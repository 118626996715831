import tw, { styled } from 'twin.macro'
import { Button, Modal } from 'react-bootstrap'
import { CheckInput } from '../../../../../../components/includes'

export const SecondStepContainer = styled.div`
    ${tw`lg:box-shadow[0px 3px 6px #00000029] lg:rounded-[10px] self-center lg:w-[570px] px-[40px] py-[30px]`}

    ${tw`flex flex-col`}
`

export const ThirdStepContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[550px] lg:max-w-[924px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export const Buttons = styled.div`
    ${tw`w-full flex flex-col gap-[15px] pt-[10px]`}
    ${tw`lg:flex-row lg:gap-[35px]`}
`
export const TextStep = styled.p`
    ${tw`font-helvetica font-normal block text-black text-base my-3`}
`
export const ImgCard = styled.div`
    ${tw`self-center`}
    ${tw`lg:w-[250px] w-[230px] mb-4 lg:static top-[-105px]`}
`

export const InfoCard = styled.p`
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`flex flex-nowrap text-sm justify-between`}
    }
    .link-btn {
        text-align: center;
        font-size: 14px;
        color: var(--text-opacity9);
        font-family: var(--font-main-regular);
        text-decoration: none;
        padding: 1% 0 1% 0 !important;
        border-top: 1px solid #e6e7e8;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .title {
        ${tw`font-helvetica font-medium lg:text-base text-[14px] lg:color[var(--dominant-color-dark)] color[var(--text-opacity7)]`}
        @media (min-width: 300px) and (max-width: 500px) {
            ${tw`color[var(--dominant-color-dark)]`}
        }
    }
    .sub-title {
        ${tw`font-montserrat lg:font-bold lg:text-black lg:text-base font-medium text-[20px] color[var(--text-opacity8)]`}
    }
    .text {
        font-size: 18px;
    }
    .contentText {
        @media (min-width: 300px) and (max-width: 500px) {
            ${tw`flex-nowrap`}
        }
    }
`
export const ButtonsContainer = styled.div`
    ${tw`w-full flex flex-col-reverse gap-[15px] pt-[10px]`}
    ${tw`lg:flex-row lg:gap-[35px]`}

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[250px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`w-[300px]`}
    }

    &:hover {
        background-color: #ffffff;
        color: var(--sub-dominant-color) !important;
        border-color: var(--sub-dominant-color) !important;
    }
    background-color: #ffffff !important;
    border-color: #000;
    color: #000;
`
export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[250px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`w-[300px]`}
    }
    background-color: var(--sub-dominant-color) !important;
    border-color: transparent !important;
    color: #fff !important;

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

export const InfoModal = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5 lg:w-[850px]`}
        max-height: 900px;
        overflow-y: scroll;
        overflow-x: hidden;

        .modal-header {
            ${tw`border-none`}
        }

        .modal-body {
            ${tw`flex flex-col items-center`}

            img {
                ${tw`m-2.5`}
            }

            h6 {
                ${tw`font-montserrat text-center text-base font-semibold color[var(--header-botton-color)]`}
            }

            p {
                ${tw`my-2.5 mx-0 text-sm font-helvetica font-normal color[var(--header-botton-color)]`}
            }
        }
    }
`

export const AlertAddPaymentContainer = styled.div`
    ${tw`my-1 mx-0 items-center py-0 px-[15px] rounded-[10px] min-h-[80px] gap-2.5`}
    background-color: var(--alert-second);

    .textAc {
        ${tw`m-0 font-normal font-helvetica text-sm`}
        color: var(--text-modal1);
    }

    @media (min-width: 1180px) {
        p {
            ${tw`lg:text-base`}
        }
    }
`

export const ContainerCheck = styled.div`
    ${tw`flex justify-start mt-4`}
    .textTc {
        color: var(--sub-dominant-color-href);
        font-size: 15px;
    }
`

export const CheckTermsCond = styled(CheckInput)`
    ${tw`block !w-6 !h-6 mr-3 cursor-pointer`}
    border-color: var(--sub-dominant-color) !important;
    @media (min-width: 300px) and (max-width: 500px) {
        ${tw`block !w-12 !h-6 mr-3 cursor-pointer`}
    }
`
export const TermText = styled.p`
    font-family: 'Helvetica', sans-serif;
    color: #53595a;
`
