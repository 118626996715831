import React from 'react'

import { WalletFai } from '../../../../utils/getIcons'
import { Content, Wrapper } from '../../FaiAccountTransfer-Styled'
import {
    Image,
    SectionImage,
    SectionText,
    SectionTextImage,
    TitleComponent,
} from './NoFaiAccountAlert-Styled'

const NoFAIAccountAlert: React.FC = () => {
    return (
        <Wrapper>
            <Content>
                <div>
                    <TitleComponent>Cuenta FAI</TitleComponent>
                </div>

                <SectionImage>
                    <Image src={WalletFai} alt="WalletFai" width={144} />
                </SectionImage>
                <SectionTextImage>
                    <p>Solicita tu cuenta FAI</p>
                </SectionTextImage>

                <SectionText>
                    <p>
                        Para realizar una transferencia entre cuentas FAI debes tener cuenta FAI,
                        para solicitarla visita una de nuestras{' '}
                        <a id="Where-are-we" href="https://www.cavipetrol.com/web/donde-estamos/">
                            oficinas.
                        </a>
                    </p>
                </SectionText>
            </Content>
        </Wrapper>
    )
}

export default NoFAIAccountAlert
