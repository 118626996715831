import { ACFSVG } from '../../../../../utils/getIcons'
import { EcopetrolCreditsContent } from '../../EcopetrolCredit/ecopetrolCredit-styles'

export const AdminCardEcopetrolCredit = (): JSX.Element => {
    return (
        <EcopetrolCreditsContent>
            <div className="card-content">
                <div className="img-container">
                    <img src={ACFSVG} className="" alt="" />
                    <p className="title">
                        Crédito ACF
                        <br></br>
                    </p>
                </div>
                <br></br>
                <div className="link-section">
                    <p className="link-btn" style={{ color: '#E70000' }}>
                        No se encontraron datos para mostrar
                    </p>
                </div>
            </div>
        </EcopetrolCreditsContent>
    )
}
