import { BannerCavipetrol } from '../../../../utils/getImages'
import { ImgPossessionsForm } from './ImagePossesionsForm-styles'

const ImagePossessionsForm = (): JSX.Element => {
    return (
        <>
            <ImgPossessionsForm>
                <img src={BannerCavipetrol} className="image-from " alt="BannerCavipetrol" />
            </ImgPossessionsForm>
        </>
    )
}

export default ImagePossessionsForm
