import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const Title = styled.h1`
    ${tw`font-montserrat font-bold text-black text-2xl`}

    span {
        ${tw`pl-1 text-dominant-dark`}
        margin: 20px;
    }
`
export const ContainerPossessions = styled.section`
    ${tw`mt-10 py-6 px-4`}
`

export const LayoutContent = styled.div`
    ${tw`px-5 md:px-4 xl:px-0 mx-auto`}
    max-width: ${(props) => props.theme['content-max-width']};
    .benefit {
        margin-top: 3rem;
    }
`

export const Wrapper = styled.section`
    ${tw`py-8 flex justify-center items-center`}

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`
export const Content = styled.div`
    ${tw`p-10 lg:px-10 w-full max-w-[100%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`

export const ContentW = styled.div`
    ${tw`p-10 lg:px-10 w-full max-w-[80%] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
    .contentName {
        width: 600px !important;
    }
`

export const AlignContent = styled.div`
    display: flex;
    flex-direction: column;
`
export const TextP = styled.p`
    ${tw`font-montserrat text-base`}
`
export const TextPBold = styled.p`
    ${tw`font-montserrat font-bold text-3xl`}
    span {
        ${tw`pl-1 text-dominant-dark`}
    }
    a {
        text-decoration-color: var(--dominant-color-dark);
    }
`
export const Image = styled.img`
    width: 11%;

    @media (max-width: 499px) {
        width: 50%;
    }

    @media (min-width: 500px) and (max-width: 700px) {
        width: 25%;
    }
`

export const ImageStar = styled.img`
    width: 25%;
    padding-right: 5%;
`

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const ModalCancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`
