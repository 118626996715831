import {
    Wrapper,
    Content,
    TextSteps,
    TextSubtitle,
    TextInfo,
    CancelButton,
    ButtonsContainer,
} from '../DynamicKeyContainer/DynamicKeyContainer-Styled'

import React, { useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import {
    SectionKeyboard,
    InputContainer,
    ButtonContent,
    MessageError,
} from './NewAdvanceKey-Styled'
import { useMediaQuery } from 'usehooks-ts'
import { OverlayTrigger } from 'react-bootstrap'
import { Button, Loading, Popover } from '../../../../../../components'
import { useHistory } from 'react-router-dom'
import ConfirmationModal from '../ConfirmationModal'
import { SectionText, CodeInputStyle } from '../CurrentAdvanceKey/CurrentAdvanceKey-Styled'
import {
    usePasswordAssignment,
    usePasswordChange,
    usePasswordChangeByForgot,
    useValidateTriviality,
} from '../../hooks/useAdvanceKeyCard'
import MonthYearSelector from '../MonthYearSelector'
import { NotProductSVG } from '../../../../../../utils/getIcons'
import ErrorModal from '../ErrorModal'
import { shuffleArrayKeyboard } from '../../../../../../utils/misc'

interface NewAdvanceKeyProps {
    stepNumber: string
    nextStepNumber: string
    showValidThru: boolean
    lastFourDigits?: string
    data?: string
    keyNew?: boolean
    numberCard: string
    actuallyPin?: string
    tokenSave?: any
    urlToBack?: any
    buttonText?: string
    lastFourDigitsEncryptedCC?: string
}

const NewAdvanceKey: React.FC<NewAdvanceKeyProps> = ({
    stepNumber,
    nextStepNumber,
    showValidThru,
    lastFourDigits,
    keyNew,
    numberCard,
    actuallyPin,
    tokenSave,
    urlToBack,
    buttonText,
    lastFourDigitsEncryptedCC,
}) => {
    const matchMedia = useMediaQuery('(min-width: 767px)')
    const [inputs, setInputs] = useState<string[]>(['', '', '', ''])
    const [activeInputIndex, setActiveInputIndex] = useState<number>(-1)
    const [sequenceValue, setSequenceValue] = useState<string>('')
    const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false)
    const [messageError, setMessageError] = useState('')
    const [invalidSequenceDetected, setInvalidSequenceDetected] = useState<boolean>(false)

    const [confirmationInputs, setConfirmationInputs] = useState<string[]>(['', '', '', ''])
    const [activeConfirmationIndex, setActiveConfirmationIndex] = useState<number>(-1)
    const [sequenceConfirmationValue, setSequenceConfirmationValue] = useState<string>('')
    const [isConfirmationKeyboardVisible, setIsConfirmationKeyboardVisible] =
        useState<boolean>(false)
    const [messageErrorConfirmation, setMessageErrorConfirmation] = useState('')
    const [invalidConfirmationDetected, setInvalidConfirmationDetected] = useState<boolean>(false)

    const [isLocked, setIsLocked] = useState<boolean>(false)
    const [isFirstInputFilled, setIsFirstInputFilled] = useState<boolean>(false)
    const [keyboardLayout, setKeyboardLayout] = useState<string[]>([])
    const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [textTitleModal, setTextTitleModal] = useState<string>('')
    const [textBodyModal, setTextBodyModal] = useState<string>('')
    const [selectedMonth, setSelectedMonth] = useState<string>('')
    const [selectedYear, setSelectedYear] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [msg, setMsg] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [isShowLink, setIsShowLink] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(false)
    const [isChangeKeyButton, setIsChangeKeyButton] = useState<boolean>(false)
    const history = useHistory()

    const handleCloseModal = (): void => {
        setShowModalConfirmation(false)
        history.push('/home-wallet')
    }

    const handleClose = (): void => {
        setShowModal(false)
        history.push('/home-wallet')
    }

    const handleValidateTriviality = (fullSequence: string): void => {
        ValidateTriviality(fullSequence)
            .then((isTrivial) => {
                if (isTrivial) {
                    setInvalidSequenceDetected(true)
                    setMessageError('La clave no es válida')
                } else {
                    setInvalidSequenceDetected(false)
                    setSequenceValue(fullSequence)
                    setActiveInputIndex(-1)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //Método Handle cuando la entrada de los datos es por teclado físico
    const handleInput = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if ((/^[0-9]$/.test(value) || value === '') && value.length <= 1) {
            const newInputs = [...inputs]
            newInputs[index] = value
            setInputs(newInputs)
            setInvalidSequenceDetected(false)
            if (value === '' && index > 0) {
                setActiveInputIndex(index - 1)
                setInvalidSequenceDetected(false)
            } else if (value.length === 1 && index < 3) {
                setActiveInputIndex(index + 1)
            } else if (value.length === 0 && index > 0) {
                setActiveInputIndex(index - 1)
            } else if (value.length === 1 && index === 3) {
                setIsKeyboardVisible(false)
                const fullSequence = newInputs.join('')
                setLoading(true)
                handleValidateTriviality(fullSequence)
            }

            if (index === 0 && !isNaN(Number(value))) {
                setIsFirstInputFilled(true)
            }
        } else {
            setInvalidSequenceDetected(true)
            setMessageError('Ingrese solo dígitos numéricos.')
        }
    }

    //Método Handle cuando la entrada de los datos es por teclado virtual
    const handleKeyboardInput = (input: string): void => {
        const newSequenceValue = input
        if (newSequenceValue.length >= 1 && !isNaN(Number(newSequenceValue[0]))) {
            setIsFirstInputFilled(true)
            if (newSequenceValue.length >= 4) {
                handleValidateTriviality(newSequenceValue)
            }
        } else {
            setIsFirstInputFilled(false)
        }
    }

    const handleInputFocus = (index: number): void => {
        setActiveInputIndex(index)
        updateKeyboardLayout()
        if (matchMedia) {
            setIsKeyboardVisible(true)
            setIsConfirmationKeyboardVisible(false)
        }
    }

    const handleInputBlur = (index: number): void => {
        if (inputs[index]) {
            setActiveInputIndex(-1)
        }
    }

    const [isUpdatingLayoutBoard, setIsUpdatingLayoutBoard] = useState(false)

    const updateKeyboardLayout = (): void => {
        if (isUpdatingLayoutBoard) return
        setIsUpdatingLayoutBoard(true)

        const initialLayout = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const layoutWithAsterisks = ['* * *', '* * *', '* * *', ' * ', '{bksp}']
        const shuffledLayout = shuffleArrayKeyboard([...initialLayout])

        const layout = [
            `${shuffledLayout[0]} ${shuffledLayout[1]} ${shuffledLayout[2]}`,
            `${shuffledLayout[3]} ${shuffledLayout[4]} ${shuffledLayout[5]}`,
            `${shuffledLayout[6]} ${shuffledLayout[7]} ${shuffledLayout[8]}`,
            ` ${shuffledLayout[9]} `,
            '{bksp}',
        ]

        setIsLocked(true)
        setKeyboardLayout(layoutWithAsterisks)
        setTimeout(() => {
            setIsLocked(false)
            setKeyboardLayout(layout)
            setIsUpdatingLayoutBoard(false)
        }, 600)
    }

    const handleKeyboardKeyPress = (button: string): void => {
        if (button === '{bksp}') {
            const newInputs = [...inputs]
            setInvalidSequenceDetected(false)
            if (newInputs.every((value) => value !== '')) {
                newInputs.fill('')
                setInputs(newInputs)
                setSequenceValue('')
                setActiveInputIndex(-1)

                handleInputFocus(0)
                setIsKeyboardVisible(true)
                setIsFirstInputFilled(false)
            } else {
                for (let i = newInputs.length - 1; i >= 0; i--) {
                    if (newInputs[i] !== '') {
                        newInputs[i] = ''
                        setInputs(newInputs)
                        setActiveInputIndex(i)

                        break
                    }
                }
            }
        } else if (!isNaN(Number(button)) && button !== '') {
            const emptyIndex = inputs.findIndex((input) => input === '')

            if (emptyIndex !== -1) {
                const newInputs = [...inputs]
                newInputs[emptyIndex] = button
                setInputs(newInputs)
                const nextEmptyIndex = newInputs.findIndex((input) => input === '')

                if (nextEmptyIndex !== -1) {
                    setActiveInputIndex(nextEmptyIndex)

                    if (nextEmptyIndex === inputs.length) {
                        setIsKeyboardVisible(false)
                    }
                }
            }
        }
    }

    const handleValidateConfirmationTriviality = (fullSequence: string): void => {
        ValidateTriviality(fullSequence)
            .then((isTrivial) => {
                if (isTrivial) {
                    setInvalidConfirmationDetected(true)
                    setMessageErrorConfirmation('La clave no es válida')
                } else {
                    setIsButtonDisabled(!(fullSequence.length >= 4 && sequenceValue.length >= 4))
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleConfirmationInput =
        (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value

            if ((/^[0-9]$/.test(value) || value === '') && value.length <= 1) {
                const newConfirmationInputs = [...confirmationInputs]
                newConfirmationInputs[index] = value
                setConfirmationInputs(newConfirmationInputs)
                setInvalidConfirmationDetected(false)

                if (value === '' && index > 0) {
                    setActiveConfirmationIndex(index - 1)
                    setInvalidConfirmationDetected(false)
                } else if (value.length === 1 && index < 3) {
                    setActiveConfirmationIndex(index + 1)
                } else if (value.length === 0 && index > 0) {
                    setActiveConfirmationIndex(index - 1)
                } else if (value.length === 1 && index === 3) {
                    setIsConfirmationKeyboardVisible(false)
                    const fullConfirmationSequence = newConfirmationInputs.join('')
                    setSequenceConfirmationValue(fullConfirmationSequence)
                    handleValidateConfirmationTriviality(fullConfirmationSequence)
                }
            } else {
                setInvalidConfirmationDetected(true)
                setMessageErrorConfirmation('Ingrese solo dígitos numéricos.')
            }
        }

    const handleKeyboardInputConfirmation = (input: string): void => {
        const newSequenceConfirmationValue = input
        if (
            newSequenceConfirmationValue.length >= 1 &&
            !isNaN(Number(newSequenceConfirmationValue[0]))
        ) {
            setIsFirstInputFilled(true)
            if (newSequenceConfirmationValue.length >= 4) {
                setSequenceConfirmationValue(newSequenceConfirmationValue)
                handleValidateConfirmationTriviality(newSequenceConfirmationValue)
            }
        } else {
            setIsFirstInputFilled(false)
        }
    }

    const handleConfirmationFocus = (index: number): void => {
        setActiveConfirmationIndex(index)
        setActiveInputIndex(-1)
        updateKeyboardLayout()
        if (matchMedia) {
            setIsConfirmationKeyboardVisible(true)
            setIsKeyboardVisible(false)
        }
    }

    const handleConfirmationBlur = (index: number): void => {
        if (confirmationInputs[index]) {
            setActiveConfirmationIndex(-1)
        }
    }

    const handleKeyboardKeyPressConfirmation = (button: string): void => {
        if (button === '{bksp}') {
            const newInputs = [...confirmationInputs]
            if (newInputs.every((value) => value !== '')) {
                newInputs.fill('')
                setConfirmationInputs(newInputs)
                setSequenceConfirmationValue('')
                setIsFirstInputFilled(false)
                resetStateInputs()
            } else {
                for (let i = newInputs.length - 1; i >= 0; i--) {
                    if (newInputs[i] !== '') {
                        newInputs[i] = ''
                        setConfirmationInputs(newInputs)
                        setActiveConfirmationIndex(i)
                        break
                    }
                }
            }
        } else if (!isNaN(Number(button)) && button !== '') {
            const emptyIndex = confirmationInputs.findIndex((input) => input === '')

            if (emptyIndex !== -1) {
                const newInputs = [...confirmationInputs]
                newInputs[emptyIndex] = button
                setConfirmationInputs(newInputs)
                const nextEmptyIndex = newInputs.findIndex((input) => input === '')
                if (nextEmptyIndex !== -1) {
                    setActiveConfirmationIndex(nextEmptyIndex)
                    if (nextEmptyIndex === confirmationInputs.length) {
                        setIsConfirmationKeyboardVisible(false)
                    }
                }
            }
        }
    }

    const resetStateInputs = (): void => {
        if (invalidConfirmationDetected) {
            setInvalidConfirmationDetected(false)
            setIsConfirmationKeyboardVisible(false)
            setIsKeyboardVisible(false)
            setActiveConfirmationIndex(-1)
            setActiveInputIndex(3)
            handleInputFocus(4)
            setIsButtonDisabled(true)
        } else {
            setActiveConfirmationIndex(0)
            handleConfirmationFocus(0)
            setIsConfirmationKeyboardVisible(true)
        }
    }

    const validateConfirmation = (): boolean => {
        if (sequenceConfirmationValue !== sequenceValue) {
            setInvalidConfirmationDetected(true)
            setMessageErrorConfirmation(
                sequenceConfirmationValue.length < 4
                    ? 'Campo obligatorio'
                    : 'Las claves no coinciden'
            )
            setIsLoading(false)
            return false
        }
        return validateSequenceAndLoading()
    }

    const validateSequenceAndLoading = (): boolean => {
        if (actuallyPin === sequenceValue && !keyNew) {
            setInvalidSequenceDetected(true)
            setMessageError('La clave que se ingresó no puede ser igual a la anterior.')
            setIsLoading(false)
            return false
        } else if (!invalidSequenceDetected) {
            setIsLoading(true)
            setInvalidConfirmationDetected(false)
            return true
        }
        return false
    }

    const handleFormSubmit = (): void => {
        setIsLoading(true)
        if (validateConfirmation()) {
            if (showValidThru) {
                if (selectedMonth !== '' && selectedYear !== '') {
                    if (isValid) {
                        ValidatePasswordChangeByForgot()
                    } else {
                        setIsLoading(false)
                    }
                } else {
                    setError(true)
                    setMsg('Los campos Mes y Año son obligatorios')
                    setIsLoading(false)
                }
            } else if (keyNew) {
                ValidatePasswordAssignment()
            } else {
                ValidatePasswordChange()
            }
        }
    }

    const ValidatePasswordChange = (): void => {
        const dataBody = {
            NumberCard: numberCard,
            ActuallyPinBlock: actuallyPin,
            NewPinBlock: sequenceValue,
        }
        usePasswordChange(dataBody, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res['data']['Data'] !== null) {
                    setShowModalConfirmation(true)
                    setTextBodyModal(
                        'Tu clave de avances ha sido cambiada con éxito para la tarjeta de crédito '
                    )
                    setIsLoading(false)
                } else if (res['Message'].includes('ERROR GENERAL ADMON CLAVES')) {
                    setShowModal(true)
                    setTextTitleModal('¡Clave inválida!')
                    setTextBodyModal(
                        'La clave actual ingresada no es válida. Por favor, inicia el proceso nuevamente.'
                    )
                    setIsShowLink(true)
                    setIsLoading(false)
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'Durante el proceso de cambio de clave de avance, por favor inicie el proceso nuevamente.'
                    )
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    const ValidatePasswordChangeByForgot = (): void => {
        const dataBody = {
            NumberCard: numberCard,
            NewPinBlock: sequenceValue,
        }
        usePasswordChangeByForgot(dataBody, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess && res['data']['Data'] !== null) {
                    setShowModalConfirmation(true)
                    setTextBodyModal(
                        'La clave para avances ha sido restituida para la tarjeta de crédito '
                    )
                    setIsLoading(false)
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'Durante el proceso de cambio de clave de avance, por favor inicie el proceso nuevamente.'
                    )
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    const ValidateTriviality = async (sequence: string): Promise<boolean> => {
        setLoading(true)
        try {
            const res: any = await useValidateTriviality(sequence, tokenSave)

            if (res['status'] === 200 && res['data']['Message'] === 'Es una contraseña trivial') {
                setLoading(false)
                return true
            } else if (res['data']['Message'] === 'No es una contraseña trivial') {
                setLoading(false)
                return false
            } else {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'Durante el proceso de validación de la clave ingresada, por favor intente nuevamente.'
                )
                setLoading(false)
                return false
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            return false
        }
    }

    const ValidatePasswordAssignment = (): void => {
        const dataBody = {
            NumberCard: numberCard,
            NewPinBlock: sequenceValue,
        }
        usePasswordAssignment(dataBody, tokenSave)
            .then((res: any) => {
                if (res['status'] === 200 && res.data.IsSuccess) {
                    setShowModalConfirmation(true)
                    setIsLoading(false)
                    setTextBodyModal(
                        'Tu clave de avances ha sido asignada con éxito para la tarjeta de crédito '
                    )
                } else if (res['Message'].includes('LA ASIGNACION DE CLAVE')) {
                    setShowModal(true)
                    setTextTitleModal('¡Información Importante!')
                    setTextBodyModal(
                        'No es posible continuar con el proceso en este momento, ya que tu tarjeta tiene una clave de avance asignada. Te recomendamos dirigirte a la opción de cambio de clave para actualizarla.'
                    )
                    setIsLoading(false)
                    setIsChangeKeyButton(true)
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'Durante el proceso de asignación de clave de avance, por favor inicie el proceso nuevamente.'
                    )
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('No se ha podido conectar con el servidor.')
                setIsLoading(false)
            })
    }

    const handleMonthYearChange = (selectedMonth: string, selectedYear: string): void => {
        setSelectedMonth(selectedMonth)
        setSelectedYear(selectedYear)
        setError(false)
    }

    const handleValidateDate = (value: boolean): void => {
        setIsValid(value)
    }

    return (
        <div>
            <Wrapper>
                <Content>
                    <TextSteps>
                        Paso {stepNumber} de {nextStepNumber}
                    </TextSteps>
                    <TextInfo>
                        Ingresa tu nueva clave la debes ingresar en el cajero para realizar avances.{' '}
                        <span>
                            Recuerde que los 4 dígitos que asignará como clave no debe tener números
                            repetidos ni consecutivos.{' '}
                        </span>
                    </TextInfo>
                    <TextSubtitle>Clave para avances</TextSubtitle>

                    <OverlayTrigger
                        show={isKeyboardVisible && activeInputIndex >= 0}
                        placement="right"
                        overlay={
                            <Popover noSpace>
                                <SectionKeyboard>
                                    <Keyboard
                                        inputName="keyboardInput"
                                        onChange={handleKeyboardInput}
                                        onKeyPress={handleKeyboardKeyPress}
                                        stopMouseDownPropagation={isLocked}
                                        stopMouseUpPropagation={isLocked}
                                        stopKeyPressPropagation={isLocked}
                                        display={{ '{bksp}': 'BORRAR' }}
                                        layout={{
                                            default: keyboardLayout,
                                        }}
                                        theme={'hg-theme-default myTheme'}
                                        inputPattern={/^[^*]+$/}
                                    />
                                </SectionKeyboard>
                            </Popover>
                        }
                    >
                        <InputContainer style={{ marginBottom: '10px' }}>
                            {inputs.map((input, index) => (
                                <CodeInputStyle
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={input ? '*' : ''}
                                    onFocus={() => handleInputFocus(index)}
                                    onBlur={() => handleInputBlur(index)}
                                    onChange={handleInput(index)}
                                    style={{
                                        borderColor:
                                            invalidConfirmationDetected || invalidSequenceDetected
                                                ? '#dc3545'
                                                : '',
                                    }}
                                    ref={(inputRef) => {
                                        if (index === activeInputIndex && inputRef) {
                                            inputRef.focus()
                                        }
                                    }}
                                    disabled={index > 0 && !isFirstInputFilled}
                                    readOnly={matchMedia}
                                />
                            ))}
                            <SectionText>
                                {invalidSequenceDetected && (
                                    <p style={{ color: '#dc3545' }}>{messageError}</p>
                                )}
                            </SectionText>
                        </InputContainer>
                    </OverlayTrigger>
                    <TextSubtitle>Confirma tu clave para avances </TextSubtitle>
                    <OverlayTrigger
                        show={isConfirmationKeyboardVisible && activeConfirmationIndex >= 0}
                        placement="right"
                        overlay={
                            <Popover noSpace>
                                <SectionKeyboard>
                                    <Keyboard
                                        inputName="confirmationKeyboardInput"
                                        onChange={handleKeyboardInputConfirmation}
                                        onKeyPress={handleKeyboardKeyPressConfirmation}
                                        stopMouseDownPropagation={isLocked}
                                        stopMouseUpPropagation={isLocked}
                                        stopKeyPressPropagation={isLocked}
                                        display={{ '{bksp}': 'BORRAR' }}
                                        layout={{
                                            default: keyboardLayout,
                                        }}
                                        theme={'hg-theme-default myTheme'}
                                        inputPattern={/^[^*]+$/}
                                    />
                                </SectionKeyboard>
                            </Popover>
                        }
                    >
                        <InputContainer style={{ marginBottom: '10px' }}>
                            {confirmationInputs.map((input, index) => (
                                <CodeInputStyle
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={input ? '*' : ''}
                                    onFocus={() => handleConfirmationFocus(index)}
                                    onBlur={() => handleConfirmationBlur(index)}
                                    onChange={handleConfirmationInput(index)}
                                    style={{
                                        borderColor: invalidConfirmationDetected ? '#dc3545' : '',
                                    }}
                                    ref={(inputRef) => {
                                        if (index === activeConfirmationIndex && inputRef) {
                                            inputRef.focus()
                                        }
                                    }}
                                    disabled={inputs.every((input) => input === '')}
                                    readOnly={matchMedia}
                                />
                            ))}
                            <SectionText>
                                {invalidConfirmationDetected && (
                                    <p style={{ color: '#dc3545' }}>{messageErrorConfirmation}</p>
                                )}
                            </SectionText>
                        </InputContainer>
                    </OverlayTrigger>
                    {showValidThru && (
                        <>
                            <MonthYearSelector
                                numberCard={numberCard}
                                tokenSave={tokenSave}
                                onMonthYearChange={handleMonthYearChange}
                                handleValidDate={handleValidateDate}
                            />
                            <MessageError>{error && <p>{msg}</p>}</MessageError>
                        </>
                    )}
                    {loading && <Loading text="Validando si la clave ingresada es valida." />}
                    <ButtonsContainer>
                        <CancelButton
                            onClick={() => {
                                history.push(urlToBack)
                            }}
                        >
                            Cancelar
                        </CancelButton>
                        <ButtonContent>
                            <Button
                                id="btnSubmit"
                                className="button-block"
                                variant="sub-dominant"
                                type="button"
                                onClick={handleFormSubmit}
                                isLoading={isLoading}
                                extend
                                disabled={isButtonDisabled}
                            >
                                {buttonText}
                            </Button>
                        </ButtonContent>
                    </ButtonsContainer>
                </Content>
            </Wrapper>

            <ConfirmationModal
                showModal={showModalConfirmation}
                handleCloseModal={handleCloseModal}
                lastsFourNumCard={lastFourDigits}
                textBodyConfirmation={textBodyModal}
            />
            <ErrorModal
                showModal={showModal}
                img={NotProductSVG}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                link={isShowLink}
                isChangeKeyButton={isChangeKeyButton}
                handleCloseModal={handleClose}
                lastFourDigitsEncryptedCC={lastFourDigitsEncryptedCC}
            />
        </div>
    )
}

export default NewAdvanceKey
