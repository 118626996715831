import tw, { styled } from 'twin.macro'

export const ContainerTitle = styled.div`
    ${tw`my-10`}
`

export const Title = styled.h1`
    ${tw`m-0 font-montserrat font-bold text-black text-2xl`}
    margin-bottom: 50px;
    span {
        ${tw`pl-1 text-dominant-dark`}
    }
`
export const Paragraph = styled.h6`
    ${tw`m-0 font-montserrat  text-black`}
    margin-bottom: 20px;
    font-size: 18px;

    span {
        ${tw`m-0 font-montserrat font-bold text-black`}
        font-size:20px;
        margin-right: 5px;
    }
`
