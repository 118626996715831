import { userService } from '../../../../../domain/services/User.service'

export async function useGetSecurityCode() {
    try {
        return await userService
            .getCreateSecurity('api/Authorization/SecurityData')
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export async function useCreateUser(body: any) {
    try {
        return await userService
            .createUser('api/Authorization/CreateLogin', body)
            .then((response: any) => {
                return response
            })
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}
