import { Controller, useForm } from 'react-hook-form'
import { Content, Wrapper } from '../../FaiAccountTransfer-Styled'
import { Form, Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { ContainerButtons, FormLabel, FromFai, TextP } from './FirsStep-Styled'
import { Button } from '../../../../components'
import ModalLoading from '../Modals/ModalLoading'
import { useGetValidateAccountFai } from '../../hooks/useFaiAccountTransfer'
import { useSelector } from 'react-redux'
import ModalError from '../Modals/ModalError'

interface FirsStepProps {
    associatedName: string
    onNextStep: () => void
    saveDataTransfer: (
        destinationId: string,
        destinationName: string,
        faiDestinationAccount: string,
        amountTransfer: string
    ) => void
    destinationIdEdit: string
    faiDestinationAccountEdit: string
    amountTransferEdit: string
    isEdit: boolean
}
const FirsStep: React.FC<FirsStepProps> = ({
    associatedName,
    onNextStep,
    saveDataTransfer,
    destinationIdEdit,
    faiDestinationAccountEdit,
    amountTransferEdit,
    isEdit,
}) => {
    const methods = useForm()
    const { control, formState, setError, clearErrors, register } = methods
    const { errors } = formState

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const [amountTransfer, setAmountTransfer] = useState<string>('')
    const [amountTransferFormat, setAmountTransferFormat] = useState<string>('')
    const [destinationId, setDestinationId] = useState<string>('')
    const [faiDestinationAccount, setFaiDestinationAccount] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        })
        if (isEdit) {
            const value = parseFloat(amountTransferEdit)
            setAmountTransfer(amountTransferEdit)
            setDestinationId(destinationIdEdit)
            setFaiDestinationAccount(faiDestinationAccountEdit)
            setAmountTransferFormat(formatter.format(value))
        }
    }, [isEdit])

    const GetValidateAccountFai = (): void => {
        setIsLoading(true)

        const formatAccountFai = 'Afai-' + faiDestinationAccount + '-'
        useGetValidateAccountFai(destinationId, formatAccountFai, tokenSave)
            .then((res: any) => {
                setIsLoading(false)
                const responseIsSuccess = res.data?.IsSuccess || res.IsSuccess
                const responseStatus = res.data?.Response || res.Response

                if (responseIsSuccess) {
                    const associateDestination = res.data?.Data.DestinationName
                    onNextStep()
                    saveDataTransfer(
                        destinationId,
                        associateDestination,
                        faiDestinationAccount,
                        amountTransfer
                    )
                } else if (responseStatus === '500') {
                    const responseMessage = res.data?.Data.Message || res.Message
                    setErrorMessage(responseMessage.toLowerCase())
                    setShowError(true)
                } else {
                    setErrorMessage(
                        'No ha sido posible validar la información ingresada, por favor intenta más tarde'
                    )
                    setShowError(true)
                }
            })
            .catch((error) => {
                console.error(error)
                setErrorMessage(error)
                setShowError(true)
            })
    }

    const handleClose = (): void => {
        setShowError(false)
    }

    return (
        <Wrapper>
            <Content>
                <FormLabel>Solicitud</FormLabel>
                <TextP>
                    Te invitamos a diligenciar el siguiente formulario para continuar con la
                    solicitud.
                </TextP>
                <FromFai>
                    <Row>
                        <Col md={5} xs={10} lg={5}>
                            <FormLabel>Nombre</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'nameUser'}
                                    render={({ field: { onChange, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-user"
                                            type="text"
                                            value={associatedName.toLocaleLowerCase()}
                                            disabled
                                        />
                                    )}
                                />
                                {errors['nameUser'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['nameUser']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Monto a Transferir</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'amountTransferFormat'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-amount-transfer"
                                            type="text"
                                            value={amountTransferFormat}
                                            placeholder="Ingresa el valor que deseas utilizar"
                                            isInvalid={!!errors['amountTransferFormat']}
                                            onChange={(e) => {
                                                const min = 10000
                                                const max = 12000000
                                                const rawValue = e.target.value
                                                    .replace(',00', '')
                                                    .replace(/[^0-9]/g, '')

                                                setAmountTransfer(rawValue)

                                                if (rawValue) {
                                                    clearErrors('amountTransferFormat')
                                                    const numericValue = parseInt(rawValue, 10) || 0

                                                    const integerFormatted = new Intl.NumberFormat(
                                                        'es-ES',
                                                        {
                                                            style: 'decimal',
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }
                                                    )
                                                    const formatted =
                                                        integerFormatted.format(numericValue)

                                                    if (numericValue < min || numericValue > max) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'El monto mínimo son $10.000 y el máximo son $12.000.000',
                                                        })
                                                    }
                                                    if (numericValue.toString().length > 8) {
                                                        setError('amountTransferFormat', {
                                                            message:
                                                                'Este campo debe contener un  máximo de 8 caracteres',
                                                        })
                                                    }
                                                    setAmountTransferFormat(`$${formatted}`)
                                                } else {
                                                    setAmountTransferFormat('')
                                                }

                                                onChange(e)
                                            }}
                                            onBlur={() => {
                                                if (!amountTransfer) {
                                                    setError('amountTransferFormat', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } else if (!amountTransferFormat.endsWith(',00')) {
                                                    setAmountTransferFormat(
                                                        `${amountTransferFormat},00`
                                                    )
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />

                                {errors['amountTransferFormat'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['amountTransferFormat']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Cédula de destino</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'destinationId'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-destination-id"
                                            type="text"
                                            {...register('destinationId', {
                                                required: true,
                                            })}
                                            value={destinationId}
                                            placeholder="Ej. 123123123"
                                            required
                                            isInvalid={!!errors['destinationId']}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9.]/g, '')
                                                if (e.target.value !== '') {
                                                    if (value.length > 20) {
                                                        setError('destinationId', {
                                                            message:
                                                                'Este campo debe contener un  máximo de 20 caracteres',
                                                        })
                                                    } else {
                                                        clearErrors('destinationId')
                                                    }

                                                    setDestinationId(value)
                                                } else {
                                                    setDestinationId('')
                                                    setError('destinationId', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                setDestinationId(value)
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('destinationId', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                }
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['destinationId'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['destinationId']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12} lg={6}>
                            <FormLabel>Cuenta FAI destino</FormLabel>
                            <Form.Group className="mb-3">
                                <Controller
                                    control={control}
                                    name={'faiDestinationAccount'}
                                    rules={{
                                        required: 'Este campo es requerido',
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Form.Control
                                            className="input-icon-account-fai"
                                            type="text"
                                            {...register('faiDestinationAccount', {
                                                required: true,
                                            })}
                                            value={faiDestinationAccount}
                                            placeholder="Ej. 12345678-9"
                                            required
                                            isInvalid={!!errors['faiDestinationAccount']}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(
                                                    /[^0-9.-]/g,
                                                    ''
                                                )
                                                if (e.target.value !== '') {
                                                    if (
                                                        value.toString().length < 5 ||
                                                        value.toString().length > 20
                                                    ) {
                                                        setError('faiDestinationAccount', {
                                                            message:
                                                                'Ingresa mínimo 5 caracteres y máximo 20 caracteres',
                                                        })
                                                    } else {
                                                        clearErrors('faiDestinationAccount')
                                                    }
                                                }
                                                setFaiDestinationAccount(value)
                                                onChange(e)
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setError('faiDestinationAccount', {
                                                        message: 'Este campo es obligatorio',
                                                    })
                                                } /* else {
                                            clearErrors('faiDestinationAccount')
                                        } */
                                                onBlur()
                                            }}
                                        />
                                    )}
                                />
                                {errors['faiDestinationAccount'] && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors['faiDestinationAccount']?.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </FromFai>
                <ContainerButtons>
                    <Button
                        variant="sub-dominant"
                        type="submit"
                        extend
                        disabled={
                            amountTransfer === '' ||
                            destinationId === '' ||
                            faiDestinationAccount === ''
                        }
                        onClick={GetValidateAccountFai}
                    >
                        Continuar
                    </Button>
                </ContainerButtons>
            </Content>
            <ModalLoading
                isLoading={isLoading}
                textInfo="Aguarda un momento, estamos validando la información"
                textLoading={'Estamos procesando tu solicitud'}
            />

            <ModalError
                showModal={showError}
                handleClose={handleClose}
                titleMessage={'Ha ocurrido un error'}
                errorMessage={errorMessage}
            />
        </Wrapper>
    )
}

export default FirsStep
