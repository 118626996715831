import { FC } from 'react'
import { Form, Container, Row, Col, Pagination, PageItem } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'

import { UserData } from '../../../../../../domain/models/crm'
import { yupResolver } from '@hookform/resolvers/yup'
import { declarationOfPoliticalExposedPerson } from '../../validations/ValidationShema'
import {
    ButtonPagination,
    ContainerForm,
    PaginationContent,
    Title,
} from '../PersonalInformation/PersonalInformation-styles'

interface DeclarationOfPoliticalExposedPersonProps {
    userData: UserData
    onUpdate: (data: Partial<UserData>) => void
    handleNextStep: (value: string) => void
}

const DeclarationOfPoliticalExposedPerson: FC<DeclarationOfPoliticalExposedPersonProps> = ({
    userData,
    onUpdate,
    handleNextStep,
}): JSX.Element => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<UserData>({
        resolver: yupResolver(declarationOfPoliticalExposedPerson),
        mode: 'onChange',
        defaultValues: userData,
    })

    const onSubmit = (data: Partial<UserData>): void => {
        onUpdate(data)
        console.log(data)
        handleNextStep('declarationsAuthorizations')
    }

    const handleOnChangeManagesPublicResources = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('ManagesPublicResources', value)
    }

    const handleOnChangeYouEnjoyGeneralPublicRecognition = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('YouEnjoyGeneralPublicRecognition', value)
    }

    const handleOnChangeExercisesSomeDegreePublicPower = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('ExercisesSomeDegreePublicPower', value)
    }

    const handleOnChangeSecondDegreeRelativesInScenarios = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const value = event.target.value === 'true' ? true : false
        setValue('SecondDegreeRelativesInScenarios', value)
    }

    return (
        <Container>
            <Title>Declaración de Persona Expuesta Políticamente (PEP)</Title>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ContainerForm>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={6}>
                            <Form.Label>
                                ¿Por su cargo o actividad maneja recursos públicos?
                            </Form.Label>
                        </Col>
                        <Col md={6}>
                            <Controller
                                name="ManagesPublicResources"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.ManagesPublicResources}
                                            onChange={(e) => {
                                                handleOnChangeManagesPublicResources(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ManagesPublicResources?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={6}>
                            <Form.Label>
                                ¿Por su actividad u oficio, goza usted de reconocimiento público
                                general?
                            </Form.Label>
                        </Col>
                        <Col md={6}>
                            <Controller
                                name="YouEnjoyGeneralPublicRecognition"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.YouEnjoyGeneralPublicRecognition}
                                            onChange={(e) => {
                                                handleOnChangeYouEnjoyGeneralPublicRecognition(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.YouEnjoyGeneralPublicRecognition?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={6}>
                            <Form.Label>
                                ¿Por su cargo o actividad ejerce algún grado de poder público?
                            </Form.Label>
                        </Col>
                        <Col md={6}>
                            <Controller
                                name="ExercisesSomeDegreePublicPower"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.ExercisesSomeDegreePublicPower}
                                            onChange={(e) => {
                                                handleOnChangeExercisesSomeDegreePublicPower(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ExercisesSomeDegreePublicPower?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3">
                        <Col md={6}>
                            <Form.Label>
                                ¿Tiene familiares hasta de segundo grado de consanguinidad y
                                afinidad que encajen en los escenarios descritos anteriormente?
                            </Form.Label>
                        </Col>
                        <Col md={6}>
                            <Controller
                                name="SecondDegreeRelativesInScenarios"
                                control={control}
                                render={({ field }) => (
                                    <Form.Group>
                                        <Form.Select
                                            className="selectFromControl"
                                            {...field}
                                            isInvalid={!!errors.SecondDegreeRelativesInScenarios}
                                            onChange={(e) => {
                                                handleOnChangeSecondDegreeRelativesInScenarios(e)
                                            }}
                                            value={field.value?.toString() ?? ''}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="true">Sí</option>
                                            <option value="false">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.SecondDegreeRelativesInScenarios?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            />
                        </Col>
                    </Row>
                </ContainerForm>
                <PaginationContent>
                    <Pagination>
                        <ButtonPagination>
                            <PageItem
                                className="disableFm"
                                onClick={() => handleNextStep('financialInfo')}
                            >
                                {'<'} Anterior
                            </PageItem>
                        </ButtonPagination>
                        <p className="symbol"> {'/'} </p>
                        <ButtonPagination>
                            <PageItem className="disableFm" onClick={handleSubmit(onSubmit)}>
                                Continuar {'>'}
                            </PageItem>
                        </ButtonPagination>
                    </Pagination>
                </PaginationContent>
            </form>
        </Container>
    )
}

export default DeclarationOfPoliticalExposedPerson
