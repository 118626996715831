import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { CreditCard } from '../../../../../../components'
import { useHistory } from 'react-router-dom'
import {
    FormContainer,
    OptionPay,
    InfoCard,
    OptionsContainer,
    ContentOptionRedirect,
    SecondStepContent,
    ImgCard,
    ContainerInfoCard,
    ButtonsContainer,
    ModalCancelButton,
    ModalButton,
} from './secondStep-styles'
import HeadStep from '../HeadStep'
import useQueryId from '../../../../../../hooks/useQueryId'
import { CreditCardSelector, useSelector } from '../../../../../../../selectors'
import { StoreApp } from '../../../../../../../redux/store/store.interface'
import { CardStates, RatesTypes, RatesTypesObject } from '../../../../../../../../domain/models'
import ModalInfo from '../ModalInfo/ModalInfo'
import { useDispatch } from 'react-redux'
import { minimumPayment } from '../../../../../../../redux/products'
import { LoadingContainer } from '../../../../../CreditDetail/creditDetail-styles'
import Loading from '../Loading'
import {
    formatValue,
    formatDecimalValue,
} from '../../../../../../components/GlobalFuntions/globalFunction'
import ModalError from '../ModalInfo/ModalError'

interface CardRedirectFormProps {
    handleNextStep: () => void
    handlePrevStep: () => void
    handleData: any
    infoRates: RatesTypes
}

const CardRedirectForm: React.FC<CardRedirectFormProps> = ({
    handleNextStep,
    handlePrevStep,
    handleData,
    infoRates,
}): JSX.Element => {
    const { id } = useQueryId()
    const { cardData: infoCard } = useSelector((store: StoreApp) =>
        CreditCardSelector(store, String(id))
    )
    const dispatch = useDispatch()
    const history = useHistory()
    const [checked, setChecked] = useState(false)
    const [totalPayment, setTotalPayment] = useState<number>(0)
    const [minPaymentNow, setMinPaymentNow] = useState<number>(0)
    const [minPaymentView, setMinPaymentView] = useState<number>(0)
    const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
    const [differenceView, setDifferenceView] = useState<number>(0)
    const [period, setPeriod] = useState<number>(0)
    const [ratesEA, setRatesEA] = useState<number>()
    const [ratesEM, setRatesEM] = useState<number>()

    const [showModal, setShowModal] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')

    useEffect(() => {
        if (infoCard) {
            setTotalPayment(infoCard.availableWingDate)
            setMinPaymentNow(infoCard.minimumPayment)
        }
    }, [])

    const HandleFormSubmitMra = () => {
        setShowModal(true)
        setTextTitleModal('Posible Mora')
        setTextBodyModal(
            `No es posible realizar la transacción seleccionada por mora en la tarjeta de crédito. Si tienes alguna duda por favor comunícate con servicio al asociado.`
        )
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleChange = (rates: RatesTypesObject, e?: ChangeEvent<HTMLInputElement>): void => {
        console.log(rates.period)
        setLoadingPayment(true)
        setChecked(false)
        setPeriod(rates.period)
        const principalDebt: number = infoCard?.totalPayment ?? 0
        const requestMinimumPayment = {
            Capital: Math.abs(Math.round(principalDebt)),
            Period: rates.period,
        }
        setRatesEA(rates.rateEA)
        setRatesEM(rates.rateEA)
        dispatch(
            minimumPayment(
                requestMinimumPayment,
                (res) => {
                    const response = res.objectValue.monthlyFee
                    setLoadingPayment(false)
                    if (response) {
                        setMinPaymentView(res.objectValue.monthlyFee)
                        setChecked(true)
                        if (infoCard) {
                            setDifferenceView(infoCard?.minimumPayment - res.objectValue.monthlyFee)
                        }
                    }
                },
                (e) => {
                    setShowModalError(true)
                    setLoadingPayment(false)
                    setTextTitleModal('Ha ocurrido un error')
                    setTextBodyModal(
                        `En este momento no podemos procesar tu consulta, por favor intenta más
                        tarde.`
                    )
                }
            )
        )
    }

    const handleDataP = () => {
        handleData(period, ratesEM, ratesEA, minPaymentView)
    }

    const onCancel = (): void => {
        history.push('/home-wallet')
    }

    const isButtonEnabled = checked
    return (
        <Form>
            {infoCard && (
                <SecondStepContent>
                    <HeadStep
                        title={`Paso 2 de 3`}
                        paragraph="Confirma la información de tu producto"
                    />
                    <FormContainer>
                        <div className="content-card-redirect-open">
                            <div>
                                <ImgCard>
                                    {
                                        <CreditCard
                                            isDisabled={
                                                infoCard.lockType !== CardStates.ACTIVA &&
                                                infoCard.lockType !== CardStates.EN_MORA
                                            }
                                            lastFourDigits={infoCard.lastFourDigits}
                                            dateExpired={infoCard.dateExpiredCard}
                                            nameCard={infoCard.nameCard}
                                        />
                                    }
                                </ImgCard>
                            </div>
                            <InfoCard>
                                <ContainerInfoCard>
                                    <p className="title">Deuda a capital</p>
                                    <p className="number">
                                        $ {formatValue(totalPayment, 1)}
                                        <sup>{formatDecimalValue(totalPayment, 1)}</sup>
                                    </p>
                                </ContainerInfoCard>
                                <br></br>
                                <ContainerInfoCard>
                                    <p className="title">Nuevo pago mínimo</p>
                                    <p className="number">
                                        $ {formatValue(minPaymentNow, 1)}
                                        <sup>{formatDecimalValue(minPaymentNow, 1)}</sup>
                                    </p>
                                </ContainerInfoCard>
                            </InfoCard>
                        </div>
                        <div className="text">Selecciona el nuevo plazo</div>
                        {!infoRates ? (
                            <LoadingContainer>
                                <Loading text="Por favor, aguarda un momento mientras consultamos tu información." />
                            </LoadingContainer>
                        ) : (
                            <>
                                <OptionsContainer>
                                    {infoRates.objectValue.map((rate: RatesTypesObject) => (
                                        <OptionPay className="option-desk">
                                            <div className="radio-btn">
                                                <Form.Check
                                                    className="radio"
                                                    value="totalPay"
                                                    type="radio"
                                                    label=""
                                                    name="option"
                                                    onChange={() => handleChange(rate)}
                                                />
                                            </div>
                                            <ContentOptionRedirect>
                                                <div className="content-r">
                                                    <p className="title">{rate.period} Meses</p>
                                                    <p className="text">{rate.rateEM}% MV</p>
                                                </div>
                                                <div className="content-ea">
                                                    <p className="textEA">{rate.rateEA}% EA</p>
                                                </div>
                                            </ContentOptionRedirect>
                                        </OptionPay>
                                    ))}
                                    <SecondStepContent>
                                        <InfoCard>
                                            <Row className="rowname">
                                                <Col xs={6} md={9}>
                                                    <div className="text">
                                                        Pago mínimo anterior{' '}
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className="text">
                                                        $ {formatValue(minPaymentNow, 1)}
                                                        <sup>
                                                            {formatDecimalValue(minPaymentNow, 1)}
                                                        </sup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rowname">
                                                <Col xs={6} md={9}>
                                                    <div className="title">
                                                        Nuevo pago mínimo aproximado
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    {loadingPayment ? (
                                                        <Loading />
                                                    ) : (
                                                        <div
                                                            className="text"
                                                            style={{
                                                                color: '#55B948',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            $ {formatValue(minPaymentView, 1)}
                                                            <sup>
                                                                {formatDecimalValue(
                                                                    minPaymentView,
                                                                    1
                                                                )}
                                                            </sup>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="rowname">
                                                <Col md={12}>
                                                    <div className="text">
                                                        La diferencia entre tu pago mínimo actual y
                                                        tu pago será de ${' '}
                                                        {formatValue(differenceView, 1)}
                                                        <sup>
                                                            {formatDecimalValue(differenceView, 1)}
                                                        </sup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="link-btn"></div>
                                            <Row>
                                                <Col xs={6} md={9}>
                                                    <div className="title">
                                                        Tasa de interés mes vencido
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className="text">{ratesEM}% EA</div>
                                                </Col>
                                            </Row>
                                            <Row className="rowname">
                                                <Col xs={6} md={9}>
                                                    <div className="title">
                                                        Tasa de interés efectivo anual{' '}
                                                    </div>
                                                </Col>
                                                <Col xs={6} className="text" md={3}>
                                                    {ratesEA}% MV
                                                </Col>
                                            </Row>
                                            <div className="link-btn"></div>
                                        </InfoCard>
                                    </SecondStepContent>
                                </OptionsContainer>
                            </>
                        )}
                        <ButtonsContainer>
                            <ModalCancelButton variant="primary" extend onClick={onCancel}>
                                Cancelar
                            </ModalCancelButton>
                            <ModalButton
                                id="btnSubmit"
                                className="button-block"
                                variant="sub-dominant"
                                type="button"
                                extend
                                disabled={!isButtonEnabled}
                                onClick={() => {
                                    if (infoCard.lockType === CardStates.EN_MORA) {
                                        HandleFormSubmitMra()
                                    } else {
                                        handleNextStep()
                                        handleDataP()
                                    }
                                }}
                            >
                                Siguiente
                            </ModalButton>
                        </ButtonsContainer>
                    </FormContainer>
                    {showModal && (
                        <ModalInfo
                            showModal={showModal}
                            handleClose={handleClose}
                            textTitle={textTitleModal}
                            textBody={textBodyModal}
                        />
                    )}
                    <ModalError
                        showModal={showModalError}
                        handleClose={handleClose}
                        textTitle={textTitleModal}
                        textBody={textBodyModal}
                    />
                </SecondStepContent>
            )}
        </Form>
    )
}
export default CardRedirectForm
